export const JUNI_LINKS = {
  MARKETING:
    process.env.NODE_ENV === 'production'
      ? 'https://junilearning.com'
      : 'http://localhost:8000',
  APP:
    process.env.NODE_ENV === 'production'
      ? 'https://app.junilearning.com'
      : 'http://localhost:3000',
};

export const MARKETING_SITE_LINKS = {
  TUITION_PAGE: `${JUNI_LINKS.MARKETING}/courses/`,
  PATHS_PAGE: `${JUNI_LINKS.MARKETING}/courses/`,
};

export const DCF_DEFAULT_ENTRY_POINT = `${JUNI_LINKS.APP}/signup?subjects=Computer%20Science`;

export const WEBSITE_COURSES = `${JUNI_LINKS.MARKETING}/courses/`;
