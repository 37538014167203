// from https://github.com/odynvolk/omit-deep-lodash/blob/master/src/index.js
// initial use case: dropping `__typename` when using a gql result as input to a mutation

import omit from 'lodash/omit';

function omitDeepLodash(input, props) {
  function omitDeepOnOwnProps(obj) {
    if (typeof input === 'undefined') {
      return input;
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitDeepLodash(obj, props);
    }

    const o = {};
    for (const [key, value] of Object.entries(obj)) {
      o[key] = !isNil(value) ? omitDeepLodash(value, props) : value;
    }

    return omit(o, props);
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProps);
  }

  return omitDeepOnOwnProps(input);
}

function isNil(value) {
  return value === null || value === undefined;
}

function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export default omitDeepLodash;
