import React from 'react';
import { JuniLogo } from 'components/brand-assets';
import DiscountBanner from '../DiscountBanner';

interface Props {
  children: React.ReactNode;
}

const FlowWrapper = ({ children }: Props) => (
  <div className="min-h-screen flex flex-col">
    <div
      className="flex items-center justify-center py-4 z-20 bg-juni-gray-100 sm:bg-white"
      style={{ borderBottom: '1px solid #E5EDF4' }}
    >
      <div className="w-18">
        <a href="https://junilearning.com" target="_blank" rel="noreferrer">
          <JuniLogo color="dark" />
        </a>
      </div>
    </div>
    <DiscountBanner />
    <div className="bg-juni-light-100 pb-8 max-h-full">
      <div className="mx-auto container max-w-screen-xl sm:py-8 z-1">{children}</div>
    </div>
  </div>
);
export default FlowWrapper;
