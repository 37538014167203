import React, { FC } from 'react';
import { QueryResult } from '@apollo/client';
import { LoadDefaultPaymentInformationByParentIdQuery } from 'generated/graphql';

interface Props {
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
}

const PaymentInformationSection: FC<Props> = ({ cardQuery }) => {
  const card = cardQuery?.data?.defaultPaymentInformationByParentId;
  return (
    <div className="flex flex-col gap-2">
      <div className="text-base font-medium">Payment Information</div>
      <div className="flex flex-row">
        <div className="mr-1">We will use your primary payment method: </div>
        <div className="font-semibold">
          <span className="text-capitalized">{card?.brand}</span>-${card?.last4}
        </div>
      </div>
    </div>
  );
};

export default PaymentInformationSection;
