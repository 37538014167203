import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';
import {
  CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME,
  CORE_PRICES,
  ASYNC_PLAN_PRODUCT,
} from 'constants/subscription_plans';
import { useRecommendedAges } from 'hooks/course';
import { Icon, NewButton, Select } from 'core-components';
import _ from 'lodash';
import { useState } from 'react';
import { getShortDisplayName } from 'utils/courses';
import {
  getSubscriptionMetaData,
  getLearnerEnrollableCourses,
} from 'app/learner/LearnerAccountV2/utils';
import { extractItemsFromMetadataNew, hasAsync } from 'utils/stripe';
import SpinnerV2 from 'components/SpinnerV2';
import LearnerPortalModal from '../LearnerPortalModal';

const PricingDisplay = ({ metadataNew, formValues, hasAsyncProduct }) => {
  const items = extractItemsFromMetadataNew(metadataNew);
  if (hasAsyncProduct || formValues.isAsyncAdded) {
    items.push(_.pick(ASYNC_PLAN_PRODUCT, ['key', 'price']));
  }
  const totalPrice = items.reduce((total, item) => total + item.price, 0);
  return (
    <div className="flex flex-col">
      <div className="w-3/5 text-lg text-j-dark-600 font-medium mb-2">Pricing</div>
      {items.map(({ key, frequency, price }) => {
        let displayName = '';
        if (key === ASYNC_PLAN_PRODUCT.key) {
          displayName = ASYNC_PLAN_PRODUCT.displayName;
        } else {
          const category =
            key !== 'core_weeklyFrequency'
              ? CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[key]
              : '';
          const frequencyStr =
            frequency === 0.5 ? '2x per month' : `${frequency}x per week`;
          displayName = `${category} ${frequencyStr}`;
        }
        return (
          <div
            key={`pricing-${key}`}
            className={`w-full flex flex-row justify-between text-sm ${
              items.length === 1 ? 'text-j-dark-600' : 'text-j-dark-400'
            } mb-1`}
          >
            <div>{displayName}</div>
            <div className="w-2/5">{`$${price.toLocaleString()}/mo`}</div>
          </div>
        );
      })}

      {items.length > 1 && (
        <div className="w-full flex flex-row justify-between text-sm font-medium text-j-dark-600 ">
          <div>Total</div>
          <div className="w-2/5">{`$${totalPrice.toLocaleString()}/mo`}</div>
        </div>
      )}
    </div>
  );
};

/**
 * Get parent-facing course options. Notes:
 * 1. first perform filtering that can be done just by looking at the course
 * 2. then, remove all courses in a subject if the user has selected or is
 *    enrolled in a course in that subject
 */
const getAvailableCoursesAndSubjects = (courses, unavailableCourses) => {
  const enrollableCourses = getLearnerEnrollableCourses(courses);
  const unavailableSubjects = _.uniq(
    unavailableCourses.map(course => course.subject.name),
  );
  const availableCourses = enrollableCourses.filter(
    course => !unavailableSubjects.includes(course.subject.name),
  );
  const availableCoursesBySubject = _.groupBy(
    availableCourses,
    course => course.subject.name,
  );
  const availableSubjects = Object.values(availableCoursesBySubject).map(
    availableCoursesInSubject => availableCoursesInSubject[0].subject,
  );

  return { availableCoursesBySubject, availableSubjects };
};

const CourseAddComponent = ({
  courses,
  metadataOld,
  metadataNew,
  updateMetadataNew,
  formValues,
  updateFormValue,
  hasAsyncProduct,
}) => {
  const recommendedAges = useRecommendedAges();
  const [isAddingSubject, setIsAddingSubject] = useState(false);

  const unavailableCourses = [
    ...Object.values(metadataOld),
    ...Object.values(metadataNew),
  ]
    .flatMap(meta => meta.courses || [])
    .map(courseName => courses.find(course => course.name === courseName));

  const {
    availableCoursesBySubject,
    availableSubjects,
  } = getAvailableCoursesAndSubjects(courses, unavailableCourses);

  const courseOptions = availableSubjects.flatMap(subject => {
    const header = {
      label: `${subject.displayName} Courses`,
      value: subject.name,
      disabled: true,
    };
    const coursesForSubject = availableCoursesBySubject[subject.name]
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map(course => ({
        value: `${subject.name}%${course.name}`,
        label: recommendedAges[course.name]?.displayString || course.name,
      }));
    return [header, ...coursesForSubject];
  });

  if (!hasAsyncProduct && !formValues.isAsyncAdded) {
    courseOptions.push(
      {
        label: 'On Demand',
        value: 'on_demand_label',
        disabled: true,
      },
      {
        label: ASYNC_PLAN_PRODUCT.displayName,
        value: ASYNC_PLAN_PRODUCT.key,
      },
    );
  }

  const maxWeeklyFrequency = _.max(_.keys(CORE_PRICES).map(Number));
  const totalSelectedFrequency = _.sumBy(_.values(metadataNew), m =>
    _.floor(m.frequency),
  );
  const addCourseButtonsEnabled =
    totalSelectedFrequency < maxWeeklyFrequency && courseOptions.length > 0;

  return (
    <div className="w-3/5 mb-5">
      {isAddingSubject ? (
        <Select
          disabled={!addCourseButtonsEnabled}
          size="small"
          placeholder="Select a new course"
          options={courseOptions}
          fullWidth
          onChange={value => {
            setIsAddingSubject(false);
            if (value === ASYNC_PLAN_PRODUCT.key) {
              updateFormValue(true, 'isAsyncAdded');
            } else {
              const [subject, course] = value.split('%');
              const { frequency = 0, courses: currentCourses = [] } =
                metadataOld[SUBJECT_TO_METADATA_KEY[subject]] || {};
              updateMetadataNew({
                ...metadataNew,
                [SUBJECT_TO_METADATA_KEY[subject]]: {
                  frequency: Math.floor(frequency + 1),
                  courses: [...currentCourses, course],
                },
              });
            }
          }}
        />
      ) : (
        <NewButton
          disabled={!addCourseButtonsEnabled}
          onClick={() => {
            setIsAddingSubject(true);
          }}
          renderIconLeft={props => <Icon.Plus {...props} />}
        >
          Add a course
        </NewButton>
      )}
    </div>
  );
};

const CourseFrequencyUpdater = ({
  updateMetadataNew,
  metadataNew,
  metadataOld,
  courses,
}) =>
  _.map(_.toPairs(metadataNew), ([key, newState]) => {
    const selectedCourses = courses.filter(course =>
      newState.courses.includes(course.name),
    );
    const maxWeeklyFrequency = _.max(_.map(_.keys(CORE_PRICES), key => Number(key)));
    // for the purposes of selection, ignore any biweekly frequency
    const totalSelectedFrequency = _.sum(
      _.map(_.values(metadataNew), metadata =>
        metadata.frequency >= 1 ? metadata.frequency : 0,
      ),
    );
    const incrementButtonEnabled = totalSelectedFrequency < maxWeeklyFrequency;
    const decrementButtonEnabled =
      !metadataOld[key] || metadataNew[key].frequency > metadataOld[key].frequency;
    return (
      <div className="flex flex-row w-full mb-2" key={key}>
        <div className="flex w-3/5 items-center">
          <div className="pr-6">
            {`${
              CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[key]
            }: ${selectedCourses
              .map(course => getShortDisplayName(course))
              .join(', ')}`}
          </div>
        </div>
        <div className="flex flex-row w-2/5">
          <div className="flex mr-3 w-5/12 items-center">
            {newState.frequency === 0.5
              ? '2x per month'
              : `${Number(newState.frequency)}x per week`}
          </div>
          <div className="flex w-7/12 items-center">
            <NewButton
              variant="minimal"
              size="small"
              disabled={!incrementButtonEnabled}
              onClick={() => {
                const newFrequency =
                  metadataNew[key].frequency >= 1
                    ? metadataNew[key].frequency + 1
                    : 1;
                updateMetadataNew({
                  ...metadataNew,
                  [key]: {
                    ...metadataNew[key],
                    frequency: newFrequency,
                  },
                });
              }}
            >
              +
            </NewButton>
            /
            <NewButton
              variant="minimal"
              size="small"
              disabled={!decrementButtonEnabled}
              onClick={() => {
                if (metadataNew[key].frequency === 0.5) {
                  updateMetadataNew(_.omit(metadataNew, key));
                  return;
                }
                const newFrequency =
                  metadataNew[key].frequency > 1
                    ? metadataNew[key].frequency - 1
                    : 0.5;
                updateMetadataNew({
                  ...metadataNew,
                  [key]: {
                    ...metadataNew[key],
                    frequency: newFrequency,
                  },
                });
              }}
            >
              -
            </NewButton>
          </div>
        </div>
      </div>
    );
  });

const CourseSelectorModalV2 = ({
  formValues,
  formState,
  updateFormState,
  updateFormValue,
  coursesQuery,
}) => {
  const { currentStudent, subscription, isAsyncAdded } = formValues;
  const hasAsyncProduct = hasAsync(subscription);

  const courses = coursesQuery?.data?.getCourses;

  const studentCurrentCourses = currentStudent?.hasMultipleTracks
    ? currentStudent?.tracks
    : [currentStudent?.track];

  // e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
  const courseMetadataObj = getSubscriptionMetaData(subscription);

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const [metadataNew, updateMetadataNew] = useState(_.cloneDeep(metadataOld));

  const subscriptionHasChanged = _.some(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );
  const nextButtonDisabled = !isAsyncAdded && !subscriptionHasChanged;

  if (!courses) return <SpinnerV2 />;

  return (
    <LearnerPortalModal
      title={formState.updateFlow}
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            updateFormValue(metadataNew, 'metadataNew');
            // skip to payment check modal for async-only changes
            const nextModal = subscriptionHasChanged
              ? 'schedule_select'
              : 'payment_check';
            updateFormState(nextModal, 'modal');
          }}
          disabled={nextButtonDisabled}
        >
          <div className="font-medium">Next</div>
        </NewButton>
      }
    >
      <div className="flex w-full justify-center">
        <div className="flex flex-col w-full gap-2">
          <div className="w-full flex flex-row justify-between text-lg text-j-dark-600">
            <div className="w-3/5 font-medium">Subject: Course(s)</div>
            <div className="w-2/5 font-medium">Frequency</div>
          </div>
          <div className="flex flex-col justify-between text-sm text-j-dark-600">
            <CourseFrequencyUpdater
              courses={courses}
              metadataOld={metadataOld}
              metadataNew={metadataNew}
              updateMetadataNew={updateMetadataNew}
            />
            {(hasAsyncProduct || isAsyncAdded) && (
              <div className="flex flex-row w-full">
                <div className="flex w-3/5">{ASYNC_PLAN_PRODUCT.displayName}</div>
                <div className="flex flex-row w-1/6">N/A</div>
              </div>
            )}
          </div>
          <div>
            <CourseAddComponent
              courses={courses}
              metadataOld={metadataOld}
              metadataNew={metadataNew}
              formValues={formValues}
              updateFormValue={updateFormValue}
              updateMetadataNew={updateMetadataNew}
              hasAsyncProduct={hasAsyncProduct}
            />
          </div>
          <div>
            <PricingDisplay
              hasAsyncProduct={hasAsyncProduct}
              formValues={formValues}
              metadataNew={metadataNew}
            />
          </div>
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default CourseSelectorModalV2;
