import React, { FC } from 'react';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { Icon, Message, NewButton } from 'core-components';
import { QueryResult } from '@apollo/client';
import {
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  useApplyCouponToStripeSubscriptionMutation,
} from 'generated/graphql';
import { formatPrice } from 'constants/subscription_plans';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import { format, fromUnixTime } from 'date-fns';
import SpinnerV2 from 'components/SpinnerV2';
import { getStudentNamesFromStudents } from 'app/learner/LearnerAccountV2/utils';
import LearnerPortalModal from '../LearnerPortalModal';
import { FormState, FormValues } from '../types';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
}

const OptionToSelectDiscountModal: FC<Props> = ({
  formState,
  formValues,
  updateFormState,
  studentsQuery,
  subscriptionsQuery,
}) => {
  const [
    applyCouponToStripeSubscription,
    { loading, error },
  ] = useApplyCouponToStripeSubscriptionMutation();

  const { subscription } = formValues;
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );

  // baseCost is the price before any discount
  const baseCost =
    subscription?.items.data.reduce(
      (total: number, subscriptionItem) =>
        total + (subscriptionItem.plan.amount || 0),
      0,
    ) || 0;
  // let monthlyCost = baseCost;
  // if (subscription?.discount && subscription?.discount.coupon) {
  //   const { coupon } = subscription.discount;
  //   monthlyCost =
  //     coupon.amount_off !== null
  //       ? baseCost - (coupon.amount_off || 0)
  //       : (1 - (coupon.percent_off || 0) / 100) * baseCost;
  // }

  const handleApplyCoupon = async () => {
    const applyCouponToSubscriptionInput = {
      subscriptionId: subscription?.id || '',
      couponId: 'STAY20',
    };
    try {
      await applyCouponToStripeSubscription({
        variables: {
          input: applyCouponToSubscriptionInput,
        },
      });
      updateFormState('cancel_discount_confirmation', 'modal');
      await subscriptionsQuery.refetch();
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.toString());
      }
    }
  };

  return (
    <LearnerPortalModal
      formState={formState}
      title=""
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          variant="secondary"
          onClick={() => {
            const modalName = 'cancel_feedback';
            JuniAnalytics.track(`${formState.modal}_button_clicked`, {
              funnel: 'subscription_upgrade_downgrade',
              sourceModal: formState.modal,
              destinationModal: modalName,
              actionFlow: formState.updateFlow,
            });
            updateFormState(modalName, 'modal');
          }}
        >
          No thanks, finish pausing/canceling
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            updateFormState('cancel_check', 'modal');
          }}
          variant="secondary"
          renderIconLeft={props => <Icon.ChevronLeft {...props} />}
        >
          Back
        </NewButton>
      }
    >
      <div className="flex flex-col items-center text-j-dark-600 leading-5 gap-2 text-center w-full">
        <div className="font-semibold text-2xl py-3">
          We would love to make Juni work!
        </div>
        <div className="text-j-dark-400 text-base leading-8">
          Here is a special offer just for you
        </div>
        <FontAwesomeIcon icon={faPiggyBank} size="4x" className="pt-4" />
        <div className="font-semibold text-xl pb-4">Keep learning for less!</div>
        <div className="text-j-dark-400 text-base w-4/">
          <div>
            For the next two months, get{' '}
            <span className="font-semibold">20% off</span>{' '}
            {getStudentNamesFromStudents(currentStudents)}'s entire subscription.
          </div>
          <div>
            {formatPrice((baseCost / 100) * 0.8)}/month for the next two months, then{' '}
            {formatPrice(baseCost / 100)} thereafter.
          </div>
          <div>
            Your next paid month begins{' '}
            {subscription &&
              format(fromUnixTime(subscription.current_period_end), 'MMM d, yyyy')}
            .
          </div>
        </div>

        <div className="my-3 w-full">
          {error && (
            <Message status="error" className="mb-2">
              We are unable to apply the discount, please refresh the page and try
              again, or contact Juni HQ if the error persists.
            </Message>
          )}
          <NewButton className="w-full" onClick={handleApplyCoupon}>
            {loading ? (
              <SpinnerV2 />
            ) : (
              <div className="font-medium"> Stay & Apply Discount</div>
            )}
          </NewButton>
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default OptionToSelectDiscountModal;
