import React, { FC, useEffect, useRef, useState } from 'react';
import _, { chunk, flatten } from 'lodash';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Icon, Message, NewButton, NewModalWindow } from 'core-components';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import { timezoneToDisplayName } from 'constants/timezones';
import { JUNI_LINKS } from 'constants/links';
import { LinkSharer } from 'app/miscellaneous/ReferralHome/LinkSharer';
import { ReferralCode } from 'models';
import calendlyLogo from 'images/signup_sessions/icons/calendly.svg';
import { CALENDLY_LINKS } from 'constants/signup_sessions';
import { BundleSelection, SignupSessionProps } from 'app/signup_session/types';
import {
  countIncompleteCourseSelections,
  countIncompleteSchedulingPreferences,
  findStudentById,
  pickAllOnboardingStatuses,
} from 'app/signup_session/lib';
import {
  useCreateStripeSubscriptionMutation,
  useGetCoursesQuery,
  useGetReferralCodeQuery,
  useLoadDefaultPaymentInformationByParentIdQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
} from 'generated/graphql';
import { useUserContext } from 'modules/UserContext';

import giftCard from 'app/learner/ReferralContent/assets/gift-card.svg';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import classNames from 'classnames';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import RingSpinner from 'components/RingSpinner';
import formatNumberAsCurrency from 'utils/formatNumberAsCurrency';
import { calculateDiscountedPriceWithCoupon } from 'app/signup_session/lib/calculateDiscountedPriceWithCoupon';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import SpinnerV2 from 'components/SpinnerV2';
import {
  isSubscriptionForStudentId,
  extractMetadataFromSubscription,
  hasAsync,
} from 'utils/stripe';
import { Link } from 'react-router-dom';
import { DateTime } from 'components/SchedulingSelection/types';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import SchedulingSummary from './components/SchedulingSummary';
import ParentStudentInfoSummary from './components/ParentStudentInfoSummary';
import HowDidYouHearAboutUsSection from './components/HowDidYouHearAboutUsSection';

const USE_HALF_OFF_BOOTCAMP = true;
const CALENDLY_PARENT_ADVISOR_URL =
  'https://calendly.com/juniadvisors/juni-onboarding-and-welcome-call';

const Confirmation: FC<SignupSessionProps> = ({ history }) => {
  const [referralCode, setReferralCode] = useState<ReferralCode | undefined>();
  const [onDemandUpsellSuccess, setOnDemandUpsellSuccess] = useState(false);
  const [isOnboardingCallBooked, setIsOnboardingCallBooked] = useState<boolean>(
    false,
  );
  const [isBookACallSkipped, setIsBookACallSkipped] = useState<boolean>(false);

  const [
    createSubscription,
    { loading: isAddingSubscription, error: subscriptionError },
  ] = useCreateStripeSubscriptionMutation();
  const referral = useGetReferralCodeQuery();
  const { signupData, activeStudentId, flags } = useSignupContext();
  const { mongoParentId } = signupData;
  const { user } = useUserContext();
  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: mongoParentId },
    skip: !mongoParentId,
  });
  const card = cardQuery?.data?.defaultPaymentInformationByParentId || {};

  const subscriptionsQuery = useLoadStripeSubscriptionsByParentIdQuery({
    variables: { id: mongoParentId },
    skip: !mongoParentId,
  });
  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];

  const student = findStudentById(activeStudentId, signupData);
  const [activeSubscription] = _.filter(subscriptions, subscription =>
    isSubscriptionForStudentId(subscription, student?.mongoStudentId),
  );

  const reviewAnswersElementRef = useRef<HTMLDivElement>(null);
  const selectedDiscount = signupData?.discountCodes?.find(code => code.isSelected);

  const isFromCourseExplorer =
    signupData.coursePlacement?.method === 'course-explorer';

  const subjects = getStudentBundleSelections(student?.bundle)
    .filter((selection): selection is BundleSelection => selection !== undefined)
    .sort((a, b) => a.subject.localeCompare(b.subject));
  const selectedCourseNames = subjects
    .map(bundleSelection => bundleSelection.courseName)
    .filter((courseName): courseName is string => courseName !== undefined);

  const { data, loading } = useGetCoursesQuery({
    variables: { input: { names: selectedCourseNames } },
  });

  const allCourses = data?.getCourses;

  useEffect(() => {
    if (referral.loading) return;
    if (!referral.data?.getReferralCode) return;
    setReferralCode({
      ...referral.data.getReferralCode,
      name: referral.data.getReferralCode.name ?? '',
    });
  }, [referral.data, referral.loading]);

  const isComplete = () => {
    const bundle = student?.bundle ?? {};
    if (!student || !student.bundle) return false;
    if (!signupData.accountCreated) return false;
    if (countIncompleteCourseSelections(bundle) !== 0) return false;
    if (flags.isBootcampSignup) return true;
    if (countIncompleteSchedulingPreferences(bundle) !== 0) return false;
    if (
      !pickAllOnboardingStatuses(signupData).every(
        status => status === 'ready_to_onboard',
      )
    ) {
      return false;
    }
    return true;
  };

  const navigateHome = async () => {
    history.push(`/learner/${student?.mongoStudentId}/home`);
    window.scrollTo({ top: 0 });
  };

  const navigateToOnDemandLibrary = async () => {
    history.push(`/learner/${student?.mongoStudentId}/on_demand`);
    window.scrollTo({ top: 0 });
  };

  const getBootcampOfferingTimes = (
    courseName?: string,
    courseOfferingId?: string,
  ) => (
    <ul className="list-disc pl-4 my-0">
      {allCourses
        ?.find(course => course.name === courseName)
        ?.offerings?.find(offering => offering._id === courseOfferingId)
        ?.schedule?.map(slot =>
          slot?.startTime ? (
            <li key={slot?.startTime}>
              {format(
                utcToZonedTime(
                  new Date(slot?.startTime),
                  student?.timezone ?? guessTimezoneValue(),
                ),
                `eee, LLL d 'at' haaa zzz`,
                { timeZone: student?.timezone ?? guessTimezoneValue() },
              )}
            </li>
          ) : null,
        )}
    </ul>
  );

  const handleAddOnDemandToSubscription = async () => {
    JuniAnalytics.track('dcf_on_demand_upsell_button_clicked');
    try {
      await createSubscription({
        variables: {
          input: {
            subscriptionUpdateType: 'NEW_CLASS',
            studentId: student?.mongoStudentId || '',
            metadataNew: extractMetadataFromSubscription(activeSubscription, '', ''),
            addAsync: true,
          },
        },
      });
      setOnDemandUpsellSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Calendly Book Call Event & Params
  useCalendlyEventListener({
    onEventScheduled: () => {
      setIsOnboardingCallBooked(true);
      window.localStorage.setItem(
        `signUpSessionOnboardingCallBooked`,
        `${signupData._id}`,
      );
    },
  });

  const bookedCallLocalStorage = localStorage.getItem(
    `signUpSessionOnboardingCallBooked`,
  );

  const containerStyles =
    'flex flex-col gap-6 bg-white max-w-lg mx-auto mt-6 p-8 rounded-xl shadow-1';

  const listItemStyle =
    'rounded-lg bg-j-gray-200 py-4 px-6 text-sm text-j-dark-600 flex space-x-4 mt-3 items-center';

  const subtitleTextStyle = 'm-0 pb-4 font-medium text-j-dark-600 text-xl';

  return (
    <>
      <div className={containerStyles}>
        <header className="flex flex-col items-center">
          {!isOnboardingCallBooked &&
          bookedCallLocalStorage !== signupData._id &&
          !isBookACallSkipped &&
          bookedCallLocalStorage !== 'skipped' ? (
            <>
              <div className="text-j-blue-400 mb-4">
                <Icon.Info height={78} width={78} />
              </div>
              <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2 text-center">
                One last thing...
              </h1>
              <p className="text-j-dark-300 m-0 pb-2 text-base text-center">
                Before we let you go, we'd love to speak with you to discuss in
                detail your child's learning style, personality, and learning goals.
                We've hopefully provided all of the information you need in our
                welcome email, but we can also answer any additional questions you
                have!
              </p>
              <InlineWidget
                pageSettings={{
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: true,
                }}
                url={CALENDLY_PARENT_ADVISOR_URL}
                prefill={{
                  email: signupData.email,
                  firstName: signupData.firstName,
                  lastName: signupData.lastName,
                  name: `${signupData.firstName} ${signupData.lastName}`,
                  customAnswers: {
                    a1: signupData.phone,
                    a2: student?.learningStyle ?? '--',
                  },
                }}
              />
              <NewButton
                variant="minimal"
                className="mt-4"
                onClick={() => {
                  setIsBookACallSkipped(true);
                  window.localStorage.setItem(
                    `signUpSessionOnboardingCallBooked`,
                    'skipped',
                  );
                }}
                renderIconRight={props => <Icon.ArrowRight {...props} />}
              >
                No thanks, I am all set!
              </NewButton>
            </>
          ) : (
            <>
              {isComplete() ? (
                <>
                  <div className="text-j-green-400 mb-4">
                    <Icon.Success height={78} width={78} />
                  </div>
                  <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2 text-center">
                    You're all set!
                    {!flags.isBootcampSignup ? ' Leave the rest to us.' : ''}
                  </h1>
                  <p className="text-j-dark-300 m-0 pb-2 text-base text-center">
                    What happens next?
                  </p>
                  <ul className="list-none m-0 p-0 text-j-dark-600 w-full">
                    {flags.isBootcampSignup ? (
                      <li className={listItemStyle}>
                        <Icon.FileText width="24" height="24" />
                        <span>Explore your Learner Portal</span>
                      </li>
                    ) : (
                      <li className={listItemStyle}>
                        <span className="w-6">
                          <Icon.Email width="24" height="24" />
                        </span>
                        <span>
                          We’ll send you easy steps to get ready for class, and a
                          class schedule confirmation.
                        </span>
                      </li>
                    )}
                    <li className={listItemStyle}>
                      <Icon.CalendarDates width="24" height="24" />
                      <span>Meet your Instructor in the 1st class</span>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <div className="text-j-yellow-400 mb-4">
                    <Icon.Warning height={78} width={78} />
                  </div>
                  <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2 text-center">
                    We still need more information to set up your classes
                  </h1>
                  <p className="text-j-dark-300 m-0 text-base text-center">
                    What happens next?
                  </p>
                  <ul className="list-none m-0 p-0 text-j-dark-600">
                    <li className={listItemStyle}>
                      <Icon.Email width="24" height="24" />
                      <span>
                        We’ll contact you to finish setting up your first class.
                      </span>
                    </li>
                  </ul>
                </>
              )}
              <div className="mt-4">
                {_.isEmpty(card) ||
                hasAsync(activeSubscription) ||
                user?.userType !== 'parent' ? (
                  <NewButton fullWidth intent="success" onClick={navigateHome}>
                    Go to my Juni Homepage
                  </NewButton>
                ) : (
                  <div className="w-full flex flex-col gap-1 items-center">
                    <div className="text-j-dark-400 text-sm text-center ">
                      <span className="font-medium">
                        Want to start the learning now?
                      </span>{' '}
                      Add on unlimited access to our self-guided On Demand Library
                      for only $29/mo additional (your first week is free!)
                    </div>
                    <div className="w-full space-y-1">
                      {subscriptionError && (
                        <Message status="error">
                          There was an error adding on your On Demand subscription,
                          please try again. If the error persists, please contact
                          Juni HQ.
                        </Message>
                      )}
                      <NewButton fullWidth onClick={handleAddOnDemandToSubscription}>
                        {isAddingSubscription ? (
                          <SpinnerV2 size={12} />
                        ) : (
                          'Add On Demand Unlimited to my membership'
                        )}
                      </NewButton>
                    </div>
                    <Link
                      className="text-sm"
                      to={`/learner/${student?.mongoStudentId}/home`}
                    >
                      No thanks, go to my Juni Homepage
                    </Link>
                  </div>
                )}
              </div>
            </>
          )}
        </header>
      </div>
      <HowDidYouHearAboutUsSection
        signupDataId={signupData._id}
        containerStyles={containerStyles}
      />
      {/* REFERRAL BANNER */}
      {referralCode && (
        <div className={containerStyles}>
          <div className="flex flex-col items-center">
            <img src={giftCard} alt="Referral Money" className="w-32 h-32" />
            <div className="w-full">
              <LinkSharer
                referralUrl={`${JUNI_LINKS.APP}/referral/${referralCode.code}`}
                title={
                  <div className="text-center font-medium text-lg py-2">
                    Refer friends to give them $50 off their first month of Juni.
                    <br />
                    You’ll get $100, too!
                  </div>
                }
                smallPrint={
                  <div className="text-center">
                    Must use your unique referral code for the offer to be valid.
                  </div>
                }
                userType="parent"
              />
            </div>
          </div>
        </div>
      )}
      <div className={containerStyles} ref={reviewAnswersElementRef}>
        <header className="border-0 border-b border-solid border-j-purple-200 pb-6">
          <h1 className="text-j-dark-600 m-0 text-3xl font-medium">
            Review your answers
          </h1>
        </header>
        <ParentStudentInfoSummary />
        {!flags.isBootcampSignup && (
          <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
            <h2
              className={classNames(
                'flex justify-between items-center flex-wrap',
                subtitleTextStyle,
              )}
            >
              Class schedule preferences
              {student?.timezone && (
                <span className="text-j-dark-300 text-sm font-normal">
                  {timezoneToDisplayName(student.timezone)}
                </span>
              )}
            </h2>

            <div className="space-y-4">
              {flatten(
                subjects.map(({ schedulingPreferences, weeklyClasses, subject }) => {
                  const prefChunks: DateTime[][] = chunk(schedulingPreferences, 3);
                  return prefChunks.map((selections, i) => {
                    const title = `${subject}, Class ${
                      i + 1
                    } of ${weeklyClasses} (${weeklyClasses} class${
                      weeklyClasses === 1 ? '' : 'es'
                    } / week)`;
                    return (
                      <div key={title} className="bg-j-gray-200 rounded-lg p-4">
                        <h3 className="text-sm text-j-dark-600 font-medium m-0 pb-3">
                          {title}
                        </h3>
                        <div className="space-y-2">
                          {selections.map((selection, index) => (
                            <SchedulingSummary
                              key={selection.datetime}
                              index={index}
                              datetime={selection.datetime}
                              timezone={student?.timezone}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  });
                }),
              )}
            </div>
          </section>
        )}
        <section>
          <h2 className={subtitleTextStyle}>
            Course {flags.isBootcampSignup ? 'details' : 'placement'}
          </h2>
          <div className="space-y-4">
            {subjects.map(({ subject, courseName, price, courseOfferingId }) => (
              <div key={subject} className="bg-j-gray-200 rounded-lg p-4">
                {!flags.isBootcampSignup && (
                  <h3 className="text-sm text-j-dark-600 font-medium m-0 pb-2">
                    {subject}
                  </h3>
                )}
                <div className="text-sm text-j-dark-600">
                  {flags.isBootcampSignup && (
                    <p className="my-0">Selected Course:</p>
                  )}
                  {loading && <RingSpinner />}
                  {!loading && (
                    <p
                      className={classNames('my-0', {
                        'font-medium': flags.isBootcampSignup,
                      })}
                    >
                      {!isFromCourseExplorer && flags.isPrivateOneOnOne
                        ? `We will select the most appropriate ${subject} starter course for your student based on the answers you provided.`
                        : allCourses?.find(course => course.name === courseName)
                            ?.displayName ?? subject}
                    </p>
                  )}
                  {flags.isBootcampSignup && price && !loading && (
                    <>
                      {getBootcampOfferingTimes(courseName, courseOfferingId)}
                      <p className="mb-0">Course Fee:</p>
                      <div className="my-0 font-medium">
                        {selectedDiscount || USE_HALF_OFF_BOOTCAMP ? (
                          <>
                            {selectedDiscount && (
                              <p className="my-0 text-j-green-600">
                                {selectedDiscount.discountType === 'percent_off'
                                  ? `${selectedDiscount.discount}% off`
                                  : `${formatNumberAsCurrency(
                                      selectedDiscount.discount / 100,
                                    )} off`}{' '}
                              </p>
                            )}
                            <span className="line-through font-normal">
                              {formatNumberAsCurrency(price / 100)}
                            </span>{' '}
                            {formatNumberAsCurrency(
                              calculateDiscountedPriceWithCoupon(
                                USE_HALF_OFF_BOOTCAMP ? price / 2 : price,
                                selectedDiscount,
                              ) / 100,
                            )}
                          </>
                        ) : (
                          `$${price}`
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>

          {signupData.coursePlacement?.callDetails?.isBooked && (
            <div className="mt-4 bg-j-gray-200 rounded-lg p-4 flex space-x-4 items-center">
              <div className="bg-juni-blue-400 rounded-lg p-2 flex items-center justify-center w-12">
                <img src={calendlyLogo} alt="calendly" />
              </div>
              <p className="m-0 text-j-dark-600 text-sm">
                You’ve chosen to{' '}
                <a
                  href={CALENDLY_LINKS.PETeam}
                  className="text-j-dark-600 underline"
                >
                  book a course placement call
                </a>{' '}
                with a Juni Advisor. Calendly will email you a link to manage your
                call.
              </p>
            </div>
          )}
        </section>
      </div>

      <NewModalWindow
        isOpen={onDemandUpsellSuccess}
        closeModal={() => setOnDemandUpsellSuccess(false)}
      >
        <div className="flex flex-col gap-4 items-center p-6">
          <Icon.CheckboxCircle className="text-j-green-600 h-20 w-20" />
          <div className="text-2xl text-j-dark-600 font-medium">
            Great job, start learning!
          </div>
          <div className="text-base text-j-dark-600">
            You now have Juni's On Demand Library available to you at your
            fingertips!
          </div>
          <NewButton
            fullWidth
            intent="success"
            size="large"
            onClick={navigateToOnDemandLibrary}
          >
            Start using Juni's On Demand Library
          </NewButton>
        </div>
      </NewModalWindow>
    </>
  );
};

export default Confirmation;
