const studentAges = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18+',
];

export const AGES_TAUGHT = {
  max: 18,
  min: 7,
};

// Min and Max ages we will accept to be input into birth date for enrollment,
// warning to use to show when ages fall outside of AGE_TAUGHT
export const AGES_SELECTOR = {
  max: 40,
  min: 5,
};

export const STUDENT_AGES = studentAges.reduce(
  (agg, cur) => [
    ...agg,
    {
      value: cur,
      label: cur,
    },
  ],
  [],
);
