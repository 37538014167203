import { StepName, STEP_NAMES } from './constants';

interface Step {
  isShowCouponBanner: boolean;
}

// This could just as easily be defined along with all the other
// step metadata in ./constants...but splitting it up may help keep
// separate concepts separate.
export const STEP_COUPONS: Record<StepName, Step> = {
  [STEP_NAMES.parentInfo]: {
    isShowCouponBanner: true,
  },
  [STEP_NAMES.studentInfo]: {
    isShowCouponBanner: true,
  },
  [STEP_NAMES.courseSelection]: {
    isShowCouponBanner: true,
  },
  [STEP_NAMES.scheduling]: {
    isShowCouponBanner: true,
  },
  [STEP_NAMES.payment]: {
    isShowCouponBanner: false,
  },
  [STEP_NAMES.createAccount]: {
    isShowCouponBanner: false,
  },
  [STEP_NAMES.confirmation]: {
    isShowCouponBanner: false,
  },
} as const;
