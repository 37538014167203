import React, { FC, useState } from 'react';
import { QueryResult } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import {
  LoadDefaultPaymentInformationByParentIdQuery,
  useCreateSetupIntentForUpdatingMyDefaultPaymentMethodMutation,
  useSetMyDefaultPaymentMethodMutation,
} from 'generated/graphql';
import { Parent } from 'models';
import { Icon, Message, NewButton, NewModalWindow } from 'core-components';
import { AmexIcon, DiscoverIcon, MastercardIcon, VisaIcon } from 'components/Icons';
import SpinnerV2 from 'components/SpinnerV2';
import _ from 'lodash';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#ff0000',
    },
  },
};
const DEFAULT_CARD_ERROR_MSG =
  'An error occurred while processing your card. Please refresh the page and try again.';
interface Props {
  parent: Parent;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  isEditPaymentModalOpen: boolean;
  setIsEditPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentUpdateModal: FC<Props> = ({
  parent,
  cardQuery,
  isEditPaymentModalOpen,
  setIsEditPaymentModalOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stripeError, setStripeError] = useState<undefined | string>(undefined);

  const stripe = useStripe();
  const elements = useElements();

  const [
    createMySetupIntent,
  ] = useCreateSetupIntentForUpdatingMyDefaultPaymentMethodMutation();
  const [updateMyPaymentMethod] = useSetMyDefaultPaymentMethodMutation();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    if (isLoading) return;
    e.preventDefault();
    setIsLoading(true);

    let stripeSetupIntentClientSecret;
    try {
      const { data } = await createMySetupIntent();
      stripeSetupIntentClientSecret =
        data?.createSetupIntentForUpdatingMyDefaultPaymentMethod.clientSecret;
    } catch (err) {
      setStripeError(DEFAULT_CARD_ERROR_MSG);
      setIsLoading(false);
      return;
    }

    if (!stripeSetupIntentClientSecret) {
      setStripeError(DEFAULT_CARD_ERROR_MSG);
      setIsLoading(false);
      return;
    }

    if (!stripe || !elements) {
      setStripeError(DEFAULT_CARD_ERROR_MSG);
      setIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setStripeError(DEFAULT_CARD_ERROR_MSG);
      setIsLoading(false);
      return;
    }

    const {
      setupIntent,
      error: confirmCardSetupError,
    } = await stripe.confirmCardSetup(stripeSetupIntentClientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${parent.firstName} ${parent.lastName}`,
        },
      },
    });
    if (confirmCardSetupError) {
      setStripeError(confirmCardSetupError.message);
      setIsLoading(false);
      return;
    }
    if (!setupIntent?.payment_method) {
      setStripeError('Please refresh the page and try again.');
      setIsLoading(false);
      return;
    }
    if (typeof setupIntent.payment_method !== 'string') {
      setStripeError('There is an error with your payment details');
      setIsLoading(false);
      return;
    }

    try {
      await updateMyPaymentMethod({
        variables: {
          input: {
            paymentMethodId: setupIntent.payment_method,
          },
        },
      });
      cardQuery.refetch();
    } catch (err) {
      console.error(err);
      setStripeError(DEFAULT_CARD_ERROR_MSG);
      setIsLoading(false);
      return;
    } finally {
      setIsEditPaymentModalOpen(false);
    }
  };

  return (
    <NewModalWindow
      isOpen={isEditPaymentModalOpen}
      closeModal={() => {
        setIsEditPaymentModalOpen(false);
      }}
      title="Edit your payment information"
      contentPadding="6"
    >
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col gap-3 items-center"
      >
        {_.isEmpty(cardQuery.data?.defaultPaymentInformationByParentId) ? (
          <div className="text-j-pink-700 text-sm font-medium">
            We noticed that you do not have a credit card on file with us. Please
            enter a valid payment method. If your billing address is outside the US,
            enter 00000 for the zip code.
          </div>
        ) : (
          <div className="text-j-dark-400 text-sm font-medium">
            If you'd like to change your primary form of payment, please enter your
            new payment information. If your billing address is outside the US, enter
            00000 for the zip code.
          </div>
        )}
        <div className="w-full">
          <div className="flex items-center space-x-4">
            <h3 className="flex-none font-medium text-j-dark-600 text-sm">
              Card Number
            </h3>
            <div className="w-full flex justify-end items-center space-x-1">
              <div className="w-5 h-5 text-j-dark-300 bg-j-gray-100 rounded border border-j-gray-300 border-solid flex justify-center items-center">
                <Icon.LockSolid />
              </div>
              <VisaIcon />
              <MastercardIcon />
              <AmexIcon />
              <DiscoverIcon />
            </div>
          </div>
          <div className="border border-solid border-blue-gray-200 p-3 rounded-lg">
            {window.Stripe && <CardElement options={CARD_ELEMENT_OPTIONS} />}
          </div>
          {isLoading && (
            <Message className="mt-2" status="warning">
              Please don't refresh or navigate away from this page while we update
              your payment information.
            </Message>
          )}
          {!isLoading && stripeError && (
            <Message className="mt-2" status="error">
              {stripeError}
            </Message>
          )}
        </div>
        <NewButton variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? (
            <div className="h-5 flex items-center">
              <SpinnerV2 />
            </div>
          ) : (
            'Submit'
          )}
        </NewButton>
      </form>
    </NewModalWindow>
  );
};

export default PaymentUpdateModal;
