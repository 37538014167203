import React, { FC, useState } from 'react';
import { timezoneToDisplayName } from 'constants/timezones';
import { NewButton, NewModalWindow } from 'core-components';
import { GetCoursesReturnFragment } from 'generated/graphql';
import { SUBJECT_TO_METADATA_KEY, SUBJECT_TYPE } from 'constants/subjects';
import { DateTime } from 'components/SchedulingSelection/types';
import SchedulingTimeSelection from 'components/SchedulingSelection/SchedulingTimeSelection';
import SelectCourse from './SelectCourse';
import { SubscriptionMetaDataWithCourse } from '../../../types';

interface Props {
  updatedSubscription: SubscriptionMetaDataWithCourse;
  setUpdatedSubscription: React.Dispatch<
    React.SetStateAction<SubscriptionMetaDataWithCourse>
  >;
  courses: GetCoursesReturnFragment[];
  isAddingSubject: boolean;
  setIsAddingSubject: React.Dispatch<React.SetStateAction<boolean>>;
  timezone: string;
}

const AddCourseModal: FC<Props> = ({
  updatedSubscription,
  setUpdatedSubscription,
  courses,
  isAddingSubject,
  setIsAddingSubject,
  timezone,
}) => {
  const [selectedCourse, setSelectCourse] = useState<
    GetCoursesReturnFragment | undefined
  >(undefined);
  const [classTimePreferences, setClassTimePreferences] = useState<DateTime[][]>([
    [],
  ]);
  const setScheduleModal = () => {
    // can only add a course per subject
    const weeklyFrequency = 1;
    const subject = selectedCourse?.subject?.name;
    if (subject) {
      setUpdatedSubscription({
        ...updatedSubscription,
        [SUBJECT_TO_METADATA_KEY[subject as SUBJECT_TYPE]]: {
          weeklyFrequency,
          courses: [selectedCourse.name],
          timePreferences: classTimePreferences,
        },
      });
    }
    closeModal();
  };

  const closeModal = () => {
    setClassTimePreferences([[]]);
    setSelectCourse(undefined);
    setIsAddingSubject(false);
  };

  return !selectedCourse ? (
    <NewModalWindow
      title="Add a course"
      closeModal={closeModal}
      isOpen={isAddingSubject}
      showCloseIcon
    >
      <SelectCourse
        updatedSubscription={updatedSubscription}
        courses={courses}
        setSelectCourse={setSelectCourse}
      />
    </NewModalWindow>
  ) : (
    <NewModalWindow
      title="Schedule Options"
      description={timezoneToDisplayName(timezone)}
      closeModal={closeModal}
      isOpen={isAddingSubject}
      showCloseIcon
      renderFooter={() =>
        selectedCourse && (
          <div className="text-right w-full">
            <NewButton
              onClick={setScheduleModal}
              disabled={
                classTimePreferences[0].filter(date => date.datetime).length !== 3
              }
            >
              Continue
            </NewButton>
          </div>
        )
      }
    >
      <SchedulingTimeSelection
        title={selectedCourse.displayName}
        timezone={timezone}
        selectedDates={classTimePreferences[0]}
        onChangeSelections={newSelections => {
          if (newSelections) {
            setClassTimePreferences([newSelections]);
          }
        }}
      />
    </NewModalWindow>
  );
};
export default AddCourseModal;
