import { FC, MouseEvent } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import * as R from 'ramda';
import {
  eachMinuteOfInterval,
  eachDayOfInterval,
  getHours,
  getMinutes,
  Interval,
  isBefore,
  addHours,
  isWithinInterval,
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Maybe } from 'generated/graphql';
import { Message } from 'core-components';
import { isSchedulingRestricted } from '../isSchedulingRestricted';

export interface MaskedBlock extends Interval {
  description: string;
}

interface TimeSlotPickerProps {
  availableSlots?: Maybe<string[]>;
  windowStart: Date;
  windowEnd: Date;
  timezone: string;
  maskedBlocks?: MaskedBlock[];
  setSelectedDatetime: (time: Date) => void;
}

interface TableHeaderDateProps {
  day: string;
  date: string;
}

interface TimeButtonProps {
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  value: string;
  title?: string;
}

const TableHeaderDate: FC<TableHeaderDateProps> = ({ day, date }) => (
  <div className="flex flex-col items-center flex-1 flex-shrink-0 text-center">
    <div className="w-20 mr-4 ">
      <div className={classNames('font-graphik text-j-dark-600 text-sm')}>{day}</div>
      <div className="text-j-dark-300 text-xs mt-3 ">{date}</div>
    </div>
  </div>
);

const TimeButton: FC<TimeButtonProps> = ({
  disabled,
  onClick,
  children,
  value,
  title,
}) => (
  <button
    className={classNames(
      disabled
        ? 'bg-j-gray-100 text-j-dark-300 cursor-auto'
        : 'bg-j-gray-200 text-j-dark-600',
      'rounded-md',
      'font-graphik text-xs tracking-normal',
      'w-20',
      'hover:shadow-none',
      'ignore-juni-globals',
      'py-1 px-2',
    )}
    title={title}
    onClick={disabled ? noop : onClick}
    value={value}
  >
    {children}
  </button>
);

const TimeSlotPicker: FC<TimeSlotPickerProps> = ({
  availableSlots,
  windowStart,
  windowEnd,
  timezone,
  maskedBlocks,
  setSelectedDatetime,
}) => {
  const visibleInterval = { start: windowStart, end: windowEnd };
  const tableHeaderDates = eachDayOfInterval(visibleInterval);
  const allTimes = eachMinuteOfInterval(visibleInterval, { step: 30 });
  const availability = availableSlots
    ? R.fromPairs(availableSlots.map(hour => [new Date(hour).toISOString(), true]))
    : {};
  const isTimeSlotAvailable = (time: Date) => {
    const disabled =
      !availability[time.toISOString()] ||
      isBefore(time, addHours(new Date(), 22)) ||
      isSchedulingRestricted(time);
    const maskedBlock = maskedBlocks?.find(
      interval => isWithinInterval(time, interval) && interval,
    );
    return !disabled && !maskedBlock;
  };
  const getCellContents = (time: Date) => {
    const disabled =
      !availability[time.toISOString()] ||
      isBefore(time, addHours(new Date(), 22)) ||
      isSchedulingRestricted(time);

    const maskedBlock = maskedBlocks?.find(
      interval => isWithinInterval(time, interval) && interval,
    );
    const title = maskedBlock
      ? maskedBlock.description
      : disabled
      ? 'Unavailable'
      : 'Available';
    return (
      <div
        key={format(utcToZonedTime(time, timezone), 'yyyy-MM-dd')}
        className="flex flex-1 mr-4 justify-center min-w-20"
      >
        {disabled || maskedBlock ? null : (
          <TimeButton
            disabled={disabled || !!maskedBlock}
            title={title}
            value={time.toISOString()}
            onClick={() => setSelectedDatetime(time)}
          >
            {format(utcToZonedTime(time, timezone), 'h:mm a')}
          </TimeButton>
        )}
      </div>
    );
  };
  const timesSortedByHourAndMinute = R.sort(
    (a, b) => (getHours(a) - getHours(b)) * 60 + (getMinutes(a) - getMinutes(b)),
    allTimes,
  );
  const groupedTimes = R.groupWith(
    (a: Date, b: Date) =>
      Boolean(getHours(a) === getHours(b) && getMinutes(a) === getMinutes(b)),
    timesSortedByHourAndMinute,
  );

  const rows = groupedTimes
    .map(times =>
      times.some(isTimeSlotAvailable) ? (
        <div
          key={`row-${format(utcToZonedTime(times[0], timezone), 'h:mm a')}`}
          className="flex mb-2"
        >
          {times.map(getCellContents)}{' '}
        </div>
      ) : null,
    )
    .filter(row => !!row);
  return (
    <>
      <div className={classNames('flex mb-4')}>
        {tableHeaderDates.map(d => (
          <TableHeaderDate
            key={format(d, 'yyyy-MM-dd')}
            day={format(d, 'EEEE')}
            date={format(d, 'MMMM dd')}
          />
        ))}
      </div>
      {rows.length > 0 ? (
        <div className="flex flex-col">{rows}</div>
      ) : (
        <div className="p-5">
          <Message status="info">No available times for these dates.</Message>
        </div>
      )}
    </>
  );
};

export default TimeSlotPicker;
