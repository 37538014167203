import DiscountCodeEditor from 'app/signup_session/components/DiscountCodeEditor';
import useCheckoutContext from 'app/signup_session/hooks/useCheckoutContext';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import findStudentById from 'app/signup_session/lib/findStudentById';
import makeDiscountedPrice from 'app/signup_session/lib/makeDiscountedPrice';
import makePriceBeforeCoupon from 'app/signup_session/lib/makePriceBeforeCoupon';
import updateSelectedDiscountData from 'app/signup_session/lib/updateSelectedDiscountData';
import { DiscountData } from 'app/signup_session/types';
import { NewModalWindow, Icon } from 'core-components';
import * as R from 'ramda';
import React, { FC, useState } from 'react';
import formatNumberAsCurrency from 'utils/formatNumberAsCurrency';
import juniCoupon from 'images/signup_sessions/juni_coupon.svg';
import useIsValidCoupon from 'app/signup_session/hooks/useValidCoupon';

const CouponEditor: FC<{ showCouponEditor: boolean }> = ({ showCouponEditor }) => {
  const {
    signupData,
    setSignupSession,
    activeStudentId,
    flags,
  } = useSignupContext();
  const { setDiscountIsValid } = useCheckoutContext();
  const [couponEditorIsOpen, setCouponEditorIsOpen] = useState(false);
  const activeStudent = findStudentById(activeStudentId, signupData)!;
  const { bundle } = activeStudent;
  const price = bundle ? makePriceBeforeCoupon(bundle.selections) : 0;
  const selectedDiscount = signupData.discountCodes?.find(code => code.isSelected);
  const isSelectedDiscountValid = useIsValidCoupon(
    selectedDiscount?.discountCode || '',
    flags?.isPrivateOneOnOne,
    flags?.isOnDemandSignup,
  ).isCouponValid;
  const priceAfterDiscount = selectedDiscount
    ? R.pipe(
        makeDiscountedPrice(price),
        formatNumberAsCurrency,
        // why does ramda say "expected 0 arguments but got 1"???
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      )(selectedDiscount)
    : undefined;

  const addValidatedDiscountToSignupSession = (discountToAdd: DiscountData) =>
    setSignupSession?.({
      discountCodes: signupData.discountCodes?.find(
        code => code.discountCode === discountToAdd.discountCode,
      )
        ? updateSelectedDiscountData(discountToAdd, signupData.discountCodes)
        : updateSelectedDiscountData(discountToAdd, [
            ...(signupData?.discountCodes ?? []),
            { ...discountToAdd, isSelected: true },
          ]),
    });

  const discountExplanation = selectedDiscount && selectedDiscount.discount > 0 && (
    <div className="flex items-start space-x-4">
      <img src={juniCoupon} alt="coupon" />
      <div className="text-xs flex flex-col space-between items-start">
        <p className="font-medium my-0 uppercase text-j-dark-600">
          You got{' '}
          {selectedDiscount.discountType === 'amount_off'
            ? `${formatNumberAsCurrency(selectedDiscount.discount / 100)}`
            : `${selectedDiscount.discount}%`}{' '}
          off{' '}
          {flags.isBootcampSignup ? (
            'your purchase'
          ) : (
            <>
              {selectedDiscount.duration === 'repeating' && (
                <span>
                  {' '}
                  {selectedDiscount.durationInMonths}{' '}
                  {selectedDiscount.durationInMonths === 1 ? 'month' : 'months'}
                </span>
              )}
              {selectedDiscount.duration === 'once' && <span> 1 month</span>}
              {selectedDiscount.duration === 'forever' && <span> for life</span>}
            </>
          )}
          !
        </p>{' '}
        {!flags.isBootcampSignup && (
          <p className="my-0 text-j-dark-600">
            Pay{' '}
            <span data-cy="discounted-price" className="font-medium">
              {makeDiscountedPrice(price, selectedDiscount) < 0
                ? '$0'
                : priceAfterDiscount}
            </span>
            {selectedDiscount.duration === 'repeating' && (
              <span>
                {' '}
                for your first{' '}
                {selectedDiscount?.durationInMonths &&
                  selectedDiscount.durationInMonths > 1 &&
                  selectedDiscount.durationInMonths}{' '}
                {selectedDiscount.durationInMonths === 1 ? 'month' : 'months'}
              </span>
            )}
            {selectedDiscount.duration === 'once' && (
              <span> for your first month</span>
            )}
            {selectedDiscount.duration === 'forever' && '/mo every month'}.
          </p>
        )}
        <button
          className="p-0 m-0 tracking-normal text-sm normal-case ignore-juni-globals bg-transparent text-j-purple-800 font-bold"
          type="button"
          data-cy="open-coupon-editor"
          onClick={() => {
            setCouponEditorIsOpen(true);
            setDiscountIsValid?.(true);
          }}
        >
          Change Coupon Code
        </button>
      </div>
    </div>
  );

  let couponSection;
  if (!selectedDiscount?.discountCode) {
    couponSection = (
      <button
        type="button"
        data-cy="open-coupon-editor"
        onClick={() => {
          setCouponEditorIsOpen(true);
          setDiscountIsValid?.(true);
        }}
        className="p-0 m-0 tracking-normal text-sm normal-case ignore-juni-globals text-j-purple-800 hover:text-j-purple-600 bg-transparent font-medium flex items-center"
      >
        <Icon.Tags2 className="pr-2" width={15} height={15} />
        Enter Coupon Code
      </button>
    );
  } else {
    couponSection = isSelectedDiscountValid ? (
      <div className="bg-j-gray-200 rounded-xl px-4 py-2">{discountExplanation}</div>
    ) : (
      <div className="bg-j-gray-200 rounded-xl px-4 py-2">
        <div className="flex items-center justify-start space-x-4">
          <img src={juniCoupon} alt="coupon" />
          <div>
            <p className="text-xs font-medium my-0 text-j-pink-600">
              The coupon you have selected is not valid.
            </p>
            <button
              className="p-0 m-0 tracking-normal text-sm normal-case ignore-juni-globals bg-transparent text-j-purple-800 font-bold"
              type="button"
              data-cy="open-coupon-editor"
              onClick={() => {
                setCouponEditorIsOpen(true);
                setDiscountIsValid?.(true);
              }}
            >
              Change Coupon Code
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-2">
      {showCouponEditor && couponSection}
      <NewModalWindow
        isOpen={couponEditorIsOpen}
        closeModal={() => setCouponEditorIsOpen(false)}
        maxWidth="475px"
        contentMaxHeight="80vh"
      >
        <DiscountCodeEditor
          closeCouponEditor={() => setCouponEditorIsOpen(false)}
          addValidatedDiscountToSignupSession={addValidatedDiscountToSignupSession}
        />
      </NewModalWindow>
    </div>
  );
};

export default CouponEditor;
