import { Dispatch, FC, SetStateAction } from 'react';
import { InstructorUnavailablePreference } from 'generated/graphql';
import { MutationResult } from '@apollo/client';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import SpinnerV2 from 'components/SpinnerV2';
import { PRIORITY_LEVELS } from 'utils/enrollment';
import { Divider, Message } from 'core-components';
import { AppointmentTypeData } from 'models';
import { AvailabilityPreferences } from '../types';

const daysAtTime = "EEEE's' 'at' h:mm a zzz";
const startingMDY = "'starting' MMM dd, yyyy";

interface InstructorUnavailablePreferenceStepProps {
  studentFirstName: string;
  appointmentDatetime: string;
  appointmentTypeData: AppointmentTypeData;
  selectedDatetime?: Date;
  isCustomTimeSelection: boolean;
  customAvailabilities: AvailabilityPreferences;
  results: MutationResult;
  timezone: string;
  instructorUnavailablePreference?: InstructorUnavailablePreference;
  setInstructorUnavailablePreference: Dispatch<
    SetStateAction<InstructorUnavailablePreference | undefined>
  >;
}

const InstructorUnavailablePreferenceStep: FC<InstructorUnavailablePreferenceStepProps> = ({
  studentFirstName,
  appointmentDatetime,
  appointmentTypeData,
  selectedDatetime,
  isCustomTimeSelection,
  customAvailabilities,
  results,
  timezone,
  instructorUnavailablePreference,
  setInstructorUnavailablePreference,
}) => {
  const currentSchedule = format(
    utcToZonedTime(parseISO(appointmentDatetime), timezone),
    daysAtTime,
    {
      timeZone: timezone,
    },
  );

  const requestedSchedule = isCustomTimeSelection ? (
    <div className="flex flex-col gap-2">
      {Object.entries(customAvailabilities).map(
        ([priority, availability]) =>
          availability.startDate && (
            <div
              className="flex gap-1"
              key={`${availability.day}${availability.time}`}
            >
              <span>{PRIORITY_LEVELS[Number(priority)]} Choice:</span>
              <span className="font-medium">
                {format(
                  utcToZonedTime(availability.startDate, timezone),
                  `${daysAtTime} ${startingMDY}`,
                  {
                    timeZone: timezone,
                  },
                )}
              </span>
            </div>
          ),
      )}
    </div>
  ) : selectedDatetime ? (
    <span className="font-semibold">
      {format(
        utcToZonedTime(selectedDatetime, timezone),
        `${daysAtTime} ${startingMDY}`,
        {
          timeZone: timezone,
        },
      )}
    </span>
  ) : (
    <Message status="error">
      Something went wrong. Please go back and try again.
    </Message>
  );

  return (
    <div className="w-full text-sm">
      {results.loading ? (
        <SpinnerV2 />
      ) : (
        <div className="flex flex-col gap-4 text-j-dark-600">
          <div className="text-j-dark-400">
            Please confirm your request to change the recurring time of{' '}
            {studentFirstName}'s {appointmentTypeData.displayName}s below.
          </div>

          <div>
            <span>Your current schedule is on </span>
            <span className="font-medium">{currentSchedule}</span>
          </div>
          <Divider />
          <div className={`flex ${isCustomTimeSelection ? 'flex-col' : ''} gap-2`}>
            <span className="font-medium text-j-dark-400">
              Your Requested New Schedule:
            </span>
            {requestedSchedule}
          </div>

          <div className="text-xs italic">
            If you need to move a class prior to this date, please use the one-time
            reschedule option.
          </div>
          <Divider />

          <div className="flex flex-col gap-2">
            <div className="font-medium">Important Note</div>
            <div className="text-j-dark-400">
              In the event that {studentFirstName}'s instructor no longer has this
              time free:
            </div>
            <div className="flex flex-col gap-2">
              <label className="flex gap-2">
                <input
                  className="my-auto"
                  type="radio"
                  checked={
                    instructorUnavailablePreference ===
                    InstructorUnavailablePreference.NewInstructor
                  }
                  onChange={() =>
                    setInstructorUnavailablePreference(
                      InstructorUnavailablePreference.NewInstructor,
                    )
                  }
                />
                <span className="text-xs">
                  I would like to switch instructors if {studentFirstName}'s current
                  instructor is no longer available at the selected time.
                </span>
              </label>
              <label className="flex gap-2">
                <input
                  className="my-auto"
                  type="radio"
                  checked={
                    instructorUnavailablePreference ===
                    InstructorUnavailablePreference.ExploreOtherTimes
                  }
                  onChange={() =>
                    setInstructorUnavailablePreference(
                      InstructorUnavailablePreference.ExploreOtherTimes,
                    )
                  }
                />
                <span className="text-xs">
                  If {studentFirstName}'s current instructor is no longer available,
                  I'd like to explore additional times that might work.
                </span>
              </label>
            </div>
          </div>
          {results.error && (
            <Message status="error">
              Something went wrong. Please go back and try again.
            </Message>
          )}
        </div>
      )}
    </div>
  );
};

export default InstructorUnavailablePreferenceStep;
