import { TUTORING_COURSE_OPTIONS } from '../constants/tutoring_courses';

export const getTutoringCourseInfo = (name: string) => {
  const flattenedOptions = TUTORING_COURSE_OPTIONS.flatMap(subject =>
    subject.options.map(option => ({ ...option, subjectLabel: subject.label })),
  );
  return flattenedOptions.find(o => o.value === name);
};

export const isTutoringCourseRequiresMoreInfo = (name: string) =>
  getTutoringCourseInfo(name)?.subjectLabel === 'Coding';
