import { useSignupCouponQuery } from 'generated/graphql';
import { useSignupData } from 'app/signup_2023/hooks';

const useSignupCoupon = () => {
  const { signupData, error: errorSignup, loading: loadingSignup } = useSignupData();
  const couponId = signupData?.payment.couponId;
  const signupProductName = signupData?.students?.[0]?.enrollments?.[0]?.productName;

  const {
    data: coupon,
    error: errorCoupon,
    loading: loadingCoupon,
  } = useSignupCouponQuery({
    variables: { id: couponId!, signupProductName },
    skip: !couponId,
  });

  return {
    couponId,
    coupon: coupon?.signupCoupon,
    error: errorCoupon || errorSignup,
    loading: loadingCoupon || loadingSignup,
  };
};

export default useSignupCoupon;
