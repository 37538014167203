import { SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import { SubjectName } from '../types';

interface TicketNoteParams {
  _id: string;
  planFrequency: string;
  subject: SubjectName;
  learningStyle: string;
  isFromCourseExplorer: boolean;
}

const makeOnboardingTicketNote = ({
  _id,
  planFrequency,
  subject,
  learningStyle,
  isFromCourseExplorer,
}: Partial<
  TicketNoteParams
>) => `Recovery link: https://app.junilearning.com/onboarding/${_id}/confirmation
    Plan frequency: ${planFrequency ?? ''}
    Subject for this ticket: ${subject ?? ''}
    Learning style: ${learningStyle ?? ''}
    ${
      !isFromCourseExplorer && subject === SUBJECT_DISPLAY_NAMES.computer_science
        ? '*parent did not select a course, needs contact for course placement*'
        : ''
    }
    `;

export default makeOnboardingTicketNote;
