import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AsYouType } from 'libphonenumber-js';

import InputField from 'app/signup_session/components/InputField';
import WarningBlock from 'app/signup_session/components/WarningBlock';

import { CALENDLY_LINKS } from 'constants/signup_sessions';

import UserContext from 'modules/UserContext';
import { JUNI_EMAILS } from 'constants/contact_information';
import { UserType } from 'app/signup_session/types';
import { getLoggedInParent } from 'services/learner/parent';
import { Message } from 'core-components';
import { TEST_SIGNUP_TAG } from 'constants/test_signup_cleanup';
import { LocalParentState } from '../ParentInfo';

const TestSignupWarning = ({ email }: { email: string }) => {
  const showWarning =
    (email.includes('@learnwithjuni') || email.includes('@junilearning')) &&
    !email.includes(TEST_SIGNUP_TAG);
  return showWarning ? (
    <Message status="warning">
      If this is a test signup, please add +testsignup to your email
    </Message>
  ) : null;
};

export interface BaseMembershipInfoForm {
  touchedFields: string[];
  addToTouchedFields: (keyName: string) => void;
  setTouchedFields: (fields: string[]) => void;
  existingUserType: UserType;
  setExistingUserType: (userType: UserType) => void;
}

interface ParentFormProps extends BaseMembershipInfoForm {
  localParentState: LocalParentState;
  onLocalParentChange: (newData: Partial<LocalParentState>) => void;
  loginScreenURL: string;
  nameIsValid?: boolean;
  phoneIsValid?: boolean;
  emailIsValid?: boolean;
  hideTitle?: boolean;
  promoOffer?: string;
  location: any;
}

export const HEADER_CLASSES = `font-semibold my-2`;

const ParentForm: FC<ParentFormProps> = ({
  touchedFields,
  localParentState,
  onLocalParentChange,
  addToTouchedFields,
  setTouchedFields,
  existingUserType,
  setExistingUserType,
  loginScreenURL,
  nameIsValid,
  phoneIsValid,
  emailIsValid,
  hideTitle,
  location,
}) => {
  const { user } = useContext(UserContext);
  const [existingStudentId, setExistingStudentId] = useState<string | undefined>();
  const labelTextStyles = 'text-j-dark-600 whitespace-nowrap font-medium text-sm';

  const getLoggedInStudentId = async () => {
    const data = await getLoggedInParent(true);
    const studentId = data?.parent?.studentId;
    if (studentId) {
      setExistingStudentId(studentId);
    }
  };

  useEffect(() => {
    if (user && !user?.roles?.includes('admin') && !user?.isGuest) {
      getLoggedInStudentId();
    }
  }, [user]);

  return (
    <>
      {!hideTitle && <h3 className={HEADER_CLASSES}>Parent Details</h3>}
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        value={localParentState.name}
        id="parent-name"
        label="Parent Full Name"
        onBlur={() => addToTouchedFields('parent-name')}
        onChange={e => onLocalParentChange({ name: e.target.value })}
        validationError={
          touchedFields.includes('parent-name') && !nameIsValid
            ? 'Please enter a full name including first and last name'
            : undefined
        }
      />
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        placeholder="juni@example.com"
        type="email"
        id="email"
        label="Email"
        value={localParentState.email}
        onBlur={() => addToTouchedFields('email')}
        onChange={e => {
          setExistingUserType(undefined);
          setTouchedFields(touchedFields.filter(x => x !== 'email'));
          onLocalParentChange({ email: e.target.value });
        }}
        validationError={
          touchedFields.includes('email') && !emailIsValid
            ? 'Please enter a valid email'
            : undefined
        }
      />
      <TestSignupWarning email={localParentState.email} />
      {existingUserType && existingUserType !== 'guest' && (
        <WarningBlock>
          It seems like you already have an account with us. Please{' '}
          <Link to={`/?redirectTo=/signup2/redirect${location.search}`}>log in</Link>{' '}
          to complete this transaction. If you believe this is a mistake or need more
          help, please{' '}
          <a href={CALENDLY_LINKS.salesTeam} target="_blank" rel="noreferrer">
            contact our team.
          </a>
        </WarningBlock>
      )}
      {user && !user?.roles?.includes('admin') && !user?.isGuest && (
        <WarningBlock>
          Hi {user.firstName}, you can add a course in your{' '}
          <Link
            to={
              existingStudentId
                ? `/learner/${existingStudentId}/account`
                : '/learner'
            }
          >
            Account Settings
          </Link>
          , or <a href={`mailto:${JUNI_EMAILS.support}`}>contact our team</a> for
          more help.
        </WarningBlock>
      )}
      {existingUserType === 'guest' && !user && (
        <WarningBlock>
          We recognize this email! Please <Link to={loginScreenURL}>log in</Link> to
          upgrade this guest account.
        </WarningBlock>
      )}
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        placeholder="000-000-0000"
        type="tel"
        id="phone"
        label="Phone Number"
        value={new AsYouType('US').input(localParentState.phone)}
        onChange={e => {
          const newFormattedValue = new AsYouType('US').input(e.target.value);

          // If the user presses backspace in a string like "(702)", attempting to
          // remove the final ")", the automatic formatter will re-insert that character.
          // So we handle that edge case here by manually erasing it.
          const isDeleting = e.target.value.length < localParentState.phone.length;
          if (isDeleting && newFormattedValue === localParentState.phone) {
            onLocalParentChange({ phone: e.target.value.slice(0, -1) });
          } else {
            onLocalParentChange({ phone: newFormattedValue });
          }
        }}
        onBlur={() => addToTouchedFields('phone')}
        validationError={
          touchedFields.includes('phone') && !phoneIsValid
            ? 'Please enter a valid phone number'
            : undefined
        }
      />
    </>
  );
};

export default ParentForm;
