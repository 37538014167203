import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { jDark } from 'theme/colors';

// TO CHANGE TO TAILWIND
const StyledTableWrapper = styled.div`
  .ui-table {
    margin-top: 1rem;
    border: 1px solid ${jDark[200]};
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 0.5rem;
    text-align: left;
    width: 100%;
    background-color: #fff;
  }

  .ui-table.ui-fixed {
    table-layout: fixed;
  }

  .ui-table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0.5rem;
  }

  .ui-table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0.5rem;
  }

  .ui-table > tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  .ui-table > tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  .ui-table th:first-child {
    border-left: none;
  }

  .ui-table th {
    border-left: 1px solid ${jDark[200]};
    padding: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    color: ${jDark[600]};
  }

  .ui-table td:first-child {
    border-left: none;
  }

  .ui-table td {
    border-left: 1px solid ${jDark[200]};
    border-top: 1px solid ${jDark[200]};
    padding: 0.75rem;
  }
`;

interface Props {
  className?: string;
  fixed?: boolean;
  children: React.ReactNode;
}

/**
 * Wraps a simple JSX table element with custom ui-table styles.
 */
const Table: React.FC<Props> = ({ className, fixed, children }) => (
  <StyledTableWrapper>
    <table
      className={classNames({ 'ui-table': true, 'ui-fixed': fixed }, className)}
    >
      {children}
    </table>
  </StyledTableWrapper>
);

export default Table;
