import React, { FC, useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as R from 'ramda';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

import { CHECKOUT_FLOW_STEPS, CHECKOUT_PATHS } from 'constants/signup_sessions';
import findLastPath from 'utils/findLastPath';
import { StripeWrapper } from 'components/StripeWrapper';
import CheckoutFlowWrapper from '../components/CheckoutFlowWrapper';
import useNavRouter from '../hooks/useNavRouter';

import { CheckoutFlowPaths, SignupSessionProps } from '../types';
import CourseFrequencyPage from './pages/CourseFrequency/CourseFrequencyPage';
import Checkout from './pages/Checkout';
import navStates from '../navigation/states';
import { ROUTE_EVENT } from '../navigation/types';
import ParentInfo from './pages/ParentInfo';
import StudentInfo from './pages/StudentInfo';
import MoreStudentInfo from './pages/MoreStudentInfo';
import StarterCourse from './pages/StarterCourse';
import useParseSignupQueryParams from '../hooks/useParseSignupQueryParams';
import useSignupContext from '../hooks/useSignupContext';
import SchedulingPreferences from './pages/SchedulingPreferences';

declare global {
  interface Window {
    // reddit
    rdt?(type: 'track', name: string): void;
    // quora
    qp?(type: 'track', name: string): void;
    // tiktok
    ttq?: {
      track: (name: string) => void;
    };
    // nextdoor
    ndp?(type: 'track', name: string): void;
    // linkedin
    lintrk?(type: 'track', args: { conversion_id: number }): void;
    // FirstPromoter
    fpr?(type: 'referral', args: { email: string; uid?: string }): void;
  }
}

const CheckoutFlow: FC<SignupSessionProps> = ({ match, location, history }) => {
  const { getNextPage, hasNextPage } = useNavRouter();
  const { flags } = useSignupContext();

  useParseSignupQueryParams({ match, location, history });

  // manually defined event for ad conversions
  // prevent duplicates from redirects and reverse navigation
  const calledOnce = useRef(false);
  useEffect(() => {
    if (
      !calledOnce.current &&
      R.equals(findLastPath(location.pathname), CHECKOUT_PATHS.parentInfo)
    ) {
      JuniAnalytics.track('load_membership_info');
      window.rdt?.('track', 'AddToCart');
      window.qp?.('track', 'AddToCart');
      window.ttq?.track('AddToCart');
      window.ndp?.('track', 'CONVERSION');
      window.lintrk?.('track', { conversion_id: 5692060 });
      calledOnce.current = true;
    }
  }, [location.pathname]);

  const isOnApprovedRoute = R.includes(
    findLastPath(location.pathname),
    R.pluck('path', CHECKOUT_FLOW_STEPS),
  );
  if (
    hasNextPage(navStates.signup.default, ROUTE_EVENT.LOAD, {
      isNotApprovedRoute: !isOnApprovedRoute,
      signupSessionId: match.params.signupSessionId,
      search: location.search,
      locationPath: location.pathname,
    })
  ) {
    return (
      <Redirect
        to={getNextPage(navStates.signup.default, ROUTE_EVENT.LOAD, {
          isNotApprovedRoute: !isOnApprovedRoute,
          signupSessionId: match.params.signupSessionId,
          search: location.search,
        })}
      />
    );
  }

  const activeStep = findLastPath(location.pathname) as CheckoutFlowPaths;

  return (
    <CheckoutFlowWrapper
      activeStep={activeStep}
      theme="light"
      hideBanner={false}
      hideStepper={activeStep === CHECKOUT_PATHS.parentInfo}
      hideMobileStepper={false}
      flags={flags}
    >
      <Switch>
        <Route
          path={`${match.path}/course-frequency`}
          render={props => (
            <CourseFrequencyPage
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/payment`}
          render={props => (
            <StripeWrapper>
              <Checkout
                {...props}
                // @ts-expect-error match.signupSessionId is nullable
                match={props.match}
              />
            </StripeWrapper>
          )}
        />
        <Route
          path={`${match.path}/parent-info`}
          render={props => (
            <ParentInfo
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/student-info`}
          render={props => (
            <StudentInfo
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/starter-course`}
          render={props => (
            <StarterCourse
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/scheduling-preferences`}
          render={props => (
            <SchedulingPreferences
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/more-student-info`}
          render={props => (
            <MoreStudentInfo
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
      </Switch>
    </CheckoutFlowWrapper>
  );
};

export default CheckoutFlow;
