import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Divider, Icon, NewButton, Popover } from 'core-components';
import { useMarkOnDemandModuleSectionAsCompleteMutation } from 'generated/graphql';
import { checkIfSectionCanBeMarkedAsComplete } from 'utils/course_progress';
import JideNextSection from './JideNextSection';

interface JideNextWidgetProps {
  studentId: string;
  sectionId: string;
  moduleId: string;
  courseCurriculumId: string;
  idLookup: { [key: string]: any };
  nextSection?: React.ComponentProps<typeof JideNextSection>['nextSection'];
}

const JideNextWidget: FC<JideNextWidgetProps> = ({
  nextSection,
  studentId,
  moduleId,
  sectionId,
  courseCurriculumId,
  idLookup,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [markSectionAsComplete] = useMarkOnDemandModuleSectionAsCompleteMutation();

  const courseName = idLookup[courseCurriculumId]?.displayName;

  const togglePopover = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      const markSectionAsCompleteParams = {
        studentId,
        moduleId,
        sectionId,
        courseCurriculumId,
      };
      const sectionShouldBeMarkedAsComplete = checkIfSectionCanBeMarkedAsComplete({
        ...markSectionAsCompleteParams,
        idLookup,
      });
      if (sectionShouldBeMarkedAsComplete) {
        markSectionAsComplete({
          variables: { input: { ...markSectionAsCompleteParams } },
        });
      }
    }
  };

  return (
    <>
      <NewButton
        variant="secondary"
        size="xsmall"
        renderIconLeft={props => <Icon.ArrowUpRight {...props} />}
        className="px-1"
        onClick={togglePopover}
      >
        Next
      </NewButton>
      {isOpen && (
        <Popover
          className={classNames(
            'absolute bottom-20 right-2 flex flex-col w-96 px-4 pt-3',
            'space-y-2 text-sm border border-solid border-j-dark-100 overflow-y-auto',
          )}
          style={{ maxHeight: 'calc(100vh - 180px)' }}
          onClose={togglePopover}
        >
          <div className="flex justify-between py-1 text-sm text-j-dark-600">
            {nextSection ? (
              <div>Your progress is updated. Move on to the next section!</div>
            ) : (
              <div className="p-2">
                Congratulations, you’ve completed {courseName}! Find your next course
                in the{' '}
                <a
                  href={`/learner/${studentId}/on_demand`}
                  className="text-j-dark-600 no-underline font-semibold"
                >
                  On Demand Library!
                </a>
              </div>
            )}
            <div
              className="flex flex-shrink-0 cursor-pointer"
              onClick={togglePopover}
            >
              <Icon.Times height={12} width={12} />
            </div>
          </div>
          {nextSection && (
            <>
              <Divider />
              <JideNextSection nextSection={nextSection} />
            </>
          )}
        </Popover>
      )}
    </>
  );
};

export default JideNextWidget;
