import React, { FormEvent, useState } from 'react';
import parsePhoneNumberFromString, { AsYouType } from 'libphonenumber-js';
import { EMAIL_REGEX } from 'constants/signup_sessions';
import { NewButton as Button, Card } from 'core-components';
import InputField from 'app/signup_session/components/InputField';
import { useSignupData } from 'app/signup_2023/hooks';
import { useIsEmailInUseQuery } from 'generated/graphql';
import { ExistingEmailMessage, TermsOfUse, TestSignupWarning } from './components';
import { labelTextStyles } from '../constants';

interface LocalState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const ParentInfo = () => {
  const { signupData, updateSignup, loading } = useSignupData();
  const { parent } = signupData || {};
  const {
    data: isEmailInUseData,
    loading: loadingIsEmailInUse,
    refetch: refetchIsEmailInUse,
  } = useIsEmailInUseQuery({
    variables: { email: parent?.email || '' },
    notifyOnNetworkStatusChange: true,
  });
  const isEmailInUse = isEmailInUseData?.isEmailInUse;

  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const addToTouchedFields = (keyName: string) =>
    setTouchedFields(
      touchedFields.includes(keyName)
        ? [...touchedFields]
        : [...touchedFields, keyName],
    );

  const [localState, setLocalState] = useState<LocalState>({
    firstName: parent?.firstName || '',
    lastName: parent?.lastName || '',
    email: parent?.email || '',
    phone: parent?.phone || '',
  });

  const onLocalChange = (newData: Partial<typeof localState>) => {
    setLocalState({ ...localState, ...newData });
    if (Object.keys(newData).includes('email')) {
      refetchIsEmailInUse({ email: newData.email });
    }
  };

  const isPhoneValid = parsePhoneNumberFromString(localState.phone, 'US')?.isValid();
  const isEmailValid = EMAIL_REGEX.test(localState.email);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!localState.email || !localState.phone) {
      return;
    }

    updateSignup({
      parent: {
        firstName: localState.firstName.trim(),
        lastName: localState.lastName.trim(),
        email: localState.email.trim(),
        phone: parsePhoneNumberFromString(localState.phone, 'US')?.number as string,
      },
    });
  };

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Card
        borderWidth="0"
        className="w-full sm:max-w-screen-xs sm:w-3/5 sm:rounded-lg"
        noRounding
      >
        <div>
          <form noValidate onSubmit={onSubmit}>
            <>
              <InputField
                labelTextStyles={labelTextStyles}
                value={localState.firstName}
                id="firstName"
                label="Parent First Name"
                onBlur={() => addToTouchedFields('firstName')}
                onChange={e => onLocalChange({ firstName: e.target.value })}
                validationError={
                  touchedFields.includes('firstName') && !localState.firstName
                    ? 'This field is required'
                    : undefined
                }
              />
              <InputField
                labelTextStyles={labelTextStyles}
                value={localState.lastName}
                id="lastName"
                label="Parent Last Name"
                onBlur={() => addToTouchedFields('lastName')}
                onChange={e => onLocalChange({ lastName: e.target.value })}
                validationError={
                  touchedFields.includes('lastName') && !localState.lastName
                    ? 'This field is required'
                    : undefined
                }
              />
              <InputField
                labelTextStyles={labelTextStyles}
                placeholder="juni@example.com"
                type="email"
                id="email"
                label="Email"
                value={localState.email}
                onBlur={() => addToTouchedFields('email')}
                onChange={e => {
                  setTouchedFields(touchedFields.filter(x => x !== 'email'));
                  onLocalChange({ email: e.target.value });
                }}
                validationError={
                  touchedFields.includes('email') && !isEmailValid
                    ? 'Please enter a valid email'
                    : undefined
                }
              />
              <TestSignupWarning email={localState.email} />
              {isEmailInUse && <ExistingEmailMessage />}
              <InputField
                labelTextStyles={labelTextStyles}
                placeholder="000-000-0000"
                type="tel"
                id="phone"
                label="Phone Number"
                value={new AsYouType('US').input(localState.phone)}
                onChange={e => {
                  const newFormattedValue = new AsYouType('US').input(
                    e.target.value,
                  );

                  // If the user presses backspace in a string like "(702)", attempting to
                  // remove the final ")", the automatic formatter will re-insert that character.
                  // So we handle that edge case here by manually erasing it.
                  const isDeleting = e.target.value.length < localState.phone.length;
                  if (isDeleting && newFormattedValue === localState.phone) {
                    onLocalChange({ phone: e.target.value.slice(0, -1) });
                  } else {
                    onLocalChange({ phone: newFormattedValue });
                  }
                }}
                onBlur={() => addToTouchedFields('phone')}
                validationError={
                  touchedFields.includes('phone') && !isPhoneValid
                    ? 'Please enter a valid phone number'
                    : undefined
                }
              />
            </>
            <TermsOfUse />
            <Button
              variant="primary"
              className="w-full"
              disabled={
                Object.values(localState).some(val => !val) ||
                !isPhoneValid ||
                !isEmailValid ||
                loading ||
                loadingIsEmailInUse ||
                isEmailInUse
              }
              type="submit"
            >
              Continue
            </Button>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default ParentInfo;
