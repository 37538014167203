import React, { FC } from 'react';
import PublicPage, { PublicPageError } from 'app/learner/PublicPage';
import {
  StudentProject,
  useGetFeaturedProjectsQuery,
  StudentProjectFeaturedType,
} from 'generated/graphql';
import { Loading } from 'components/ui';
import HeroHeader from './components/HeroHeader/HeroHeader';
import TrendingProjectsList from './components/TrendingProjectsList/TrendingProjectsList';
import ProjectsWeLove from './components/ProjectsWeLove/ProjectsWeLove';

interface Props {
  activeStudentId?: string;
}

const StudentProjectsHome: FC<Props> = ({ activeStudentId }) => {
  const { data, loading, error } = useGetFeaturedProjectsQuery({
    variables: { featuredType: StudentProjectFeaturedType.Juniverse },
  });

  const studentProjects = data?.featuredStudentProjects?.items || [];
  const trendingProjects = studentProjects.filter(project =>
    project?.juniverseTags?.includes('featured-juniverse-trending'),
  );
  const heroProjects = studentProjects.filter(project =>
    project?.juniverseTags?.includes('featured-juniverse'),
  );
  const heroData = {
    title: `Welcome to 
    Student Projects`,
    description:
      'Explore featured projects created by Juni students and instructors around the world!',
  };

  if (loading) return <Loading />;

  if (error || !data || !studentProjects) return <PublicPageError />;

  return (
    <PublicPage>
      {!activeStudentId && <PublicPage.NavBar />}
      <PublicPage.MainContent>
        <HeroHeader data={heroData} projects={heroProjects as StudentProject[]} />
        <div className="flex flex-col items-center gap-20 pt-96 lg:pt-60 pb-20 px-6">
          <TrendingProjectsList data={trendingProjects as StudentProject[]} />
          <ProjectsWeLove data={studentProjects as StudentProject[]} />
        </div>
      </PublicPage.MainContent>
      {!activeStudentId && <PublicPage.JuniverseFooter />}
    </PublicPage>
  );
};

export default StudentProjectsHome;
