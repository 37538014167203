import { ICourse } from 'generated/graphql';
import React from 'react';
import completedCourseBadge from 'images/purple-certificate-check-badge.svg';
import { WEBSITE_COURSES } from 'constants/links';
import { useCourseNameToBasicMetadata } from 'hooks/course';

interface CompletedCourseProps {
  course: ICourse;
}

const CompletedCourse: React.FC<CompletedCourseProps> = ({ course }) => {
  const { courseNameToBasicMetadata } = useCourseNameToBasicMetadata();
  const { slug, isAcceptingEnrollment, subject } = courseNameToBasicMetadata(
    course.name,
  );
  const href = isAcceptingEnrollment ? WEBSITE_COURSES + slug : subject.websiteUrl;

  return (
    <a href={href} target="_blank" rel="noreferrer" className="no-underline">
      <div className="flex items-center">
        <img
          src={completedCourseBadge}
          alt="Completed Course Badge"
          className="h-10 w-10"
        />
        <div className="ml-3 font-graphik">
          <div className="text-xs text-j-dark-300 uppercase font-medium">
            {course.displayNameMinor}
          </div>
          <div className="text-sm leading-6 text-j-dark-600 font-medium">
            {course.displayNameMajor}
          </div>
        </div>
      </div>
    </a>
  );
};

export default CompletedCourse;
