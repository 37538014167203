import React, { FC } from 'react';

import SchedulingSummary from 'app/signup_session/Onboarding/pages/Confirmation/components/SchedulingSummary';
import { DateTime } from 'components/SchedulingSelection/types';

interface Props {
  timePreferences: DateTime[][];
  existingSubject: boolean;
  timezone: string;
}

const SelectedClassTimePreference: FC<Props> = ({
  timePreferences,
  existingSubject,
  timezone,
}) => (
  <div className="w-full flex flex-wrap gap-2">
    {timePreferences.map((classSelection, i) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className="flex-auto bg-j-gray-200 rounded-lg p-4 space-y-2"
      >
        <label className="text-sm font-medium">
          {!existingSubject ? 'New' : 'Additional'} Class Time Preference
        </label>
        {classSelection.map((selection, index) => (
          <SchedulingSummary
            key={selection.datetime}
            index={index}
            datetime={selection.datetime}
            timezone={timezone}
          />
        ))}
      </div>
    ))}
  </div>
);

export default SelectedClassTimePreference;
