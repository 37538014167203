import { FC, useCallback, useState } from 'react';
import { Parent } from 'models';
import SmsConsentCheckbox from 'components/SmsConsentCheckbox';
import { NewCard } from 'core-components';
import { useGetSubOptInStatusesByParentIdsQuery } from 'generated/graphql';
import RingSpinner from 'components/RingSpinner';
import AutoSubCheckbox from './AutoSubCheckbox';

const SMSNotificationsCard: FC = () => (
  <NewCard padding="0">
    <div className="text-xl font-semibold px-6 py-4">Notifications</div>
    <NewCard.Divider />
    <div className="p-6">
      <SmsConsentCheckbox userType="parent" />
    </div>
  </NewCard>
);

interface SubOptInCardProps {
  parent: Parent;
}
const SubOptInCard: FC<SubOptInCardProps> = ({ parent }) => {
  const [mutationErrorMsg, setMutationErrorMsg] = useState('');
  const { data, loading, error, refetch } = useGetSubOptInStatusesByParentIdsQuery({
    variables: { ids: [parent._id] },
  });
  const students = data?.studentsByParentIds.items || [];

  const reloadData = useCallback(() => {
    refetch({ ids: [parent._id] });
  }, [parent._id, refetch]);

  return (
    <NewCard padding="0">
      <div className="text-xl font-semibold px-6 py-4 flex">
        <div className="flex-1">Instructor Auto-Substitution</div>
        {loading ? <RingSpinner size={26} className="flex-grow-0" /> : null}
      </div>

      <NewCard.Divider />

      {error?.message ? (
        <div className="px-6 py-1 text-red-700 font-semibold">{error?.message}</div>
      ) : null}
      {mutationErrorMsg ? (
        <div className="px-6 py-1 text-red-700 font-semibold">
          {mutationErrorMsg}
        </div>
      ) : null}

      <div className="p-6 pb-0">
        If your student's primary instructor is unable to attend class due to an
        emergency or unavoidable scheduling conflict, by default the student will be
        <b>&nbsp;automatically</b> paired with a highly qualified and tenured
        substitute instructor whenever possible. This ensures the best possible
        scheduling continuity for your student.
      </div>
      <div className="p-6 pb-0">
        However, you may opt out of this service by unchecking the box below. If you
        opt out, you still have the option to opt in to working with a substitute
        instructor on a one-off basis.
      </div>
      <div className="p-6">
        {students.map(student => (
          <AutoSubCheckbox
            key={student._id}
            student={student}
            reloadData={reloadData}
            setMutationErrorMsg={setMutationErrorMsg}
          />
        ))}
      </div>
    </NewCard>
  );
};

interface PreferencesSectionProps {
  parent: Parent;
}
const PreferencesSection: FC<PreferencesSectionProps> = ({ parent }) => (
  <div className="w-full flex flex-col lg:flex-row gap-6">
    <div className="w-full">
      <SMSNotificationsCard />
    </div>
    <div className="w-full">
      <SubOptInCard parent={parent} />
    </div>
  </div>
);

export default PreferencesSection;
