import { SourceOption } from 'generated/graphql';

export const HOW_DID_YOU_HEAR_ABOUT_US_SOURCE_OPTIONS = [
  {
    value: SourceOption.InstagramFacebook,
    label: 'Instagram or Facebook',
  },
  {
    value: SourceOption.EmailNewsletter,
    label: 'Email or Newsletter',
  },
  {
    value: SourceOption.GoogleSearchEngine,
    label: 'Google / Search Engine',
  },
  {
    value: SourceOption.Tiktok,
    label: 'Tik Tok',
  },
  {
    value: SourceOption.WordOfMouth,
    label: 'Word of Mouth',
  },
  {
    value: SourceOption.Other,
    label: 'Other',
  },
] as const;

export const HOW_DID_YOU_HEAR_ABOUT_US_BY_VALUE = HOW_DID_YOU_HEAR_ABOUT_US_SOURCE_OPTIONS.reduce(
  (agg, cur) => ({
    ...agg,
    [cur.value]: cur,
  }),
  {},
);
