// prefix section displayName with section's module name
export const getQualifiedDisplayName = (
  sectionDisplayName: string,
  moduleName: string,
  sectionType = '',
) => {
  if (!sectionDisplayName || sectionDisplayName === '--') return '--';
  if (!moduleName) return sectionDisplayName;

  const sectionTypesToQualify = [
    'concept',
    'problem_set',
    'activity',
    'reading_project',
    'writing_project',
    'question_set',
  ];
  if (sectionTypesToQualify.includes(sectionType)) {
    if (sectionDisplayName.startsWith(moduleName)) return sectionDisplayName;

    return `${moduleName} ${sectionDisplayName}`;
  }

  return sectionDisplayName;
};
