import React, { FC } from 'react';
import * as R from 'ramda';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SignupSessionProps } from 'app/signup_session/types';
import { useUserContext } from 'modules/UserContext';
import findLastPath from 'utils/findLastPath';
import { COURSE_FORMAT, ONBOARDING_FLOW_STEPS } from 'constants/signup_sessions';
import { pickAllOnboardingStatuses } from '../lib';
import useSignupContext from '../hooks/useSignupContext';
import useNavRouter from '../hooks/useNavRouter';
import OnboardingLayout from './components/OnboardingLayout';
import navStates from '../navigation/states';
import * as Page from './pages';
import { ROUTE_EVENT } from '../navigation/types';

const Onboarding: FC<SignupSessionProps> = props => {
  const { signupData, flags } = useSignupContext();
  const { user } = useUserContext();
  const { getNextPage, hasNextPage } = useNavRouter();

  const activeStudent = signupData.students?.[0];

  const onboardingIsComplete =
    // if on demand bundle skip onboarding and go directly to confirmation page
    activeStudent?.bundle?.bundleName === COURSE_FORMAT.onDemand ||
    flags.isBootcampSignup ||
    pickAllOnboardingStatuses(signupData).every(
      status => status === 'ready_to_onboard',
    );

  const isOnApprovedRoute = R.includes(
    findLastPath(props.location.pathname),
    R.pluck('path', ONBOARDING_FLOW_STEPS),
  );
  if (
    hasNextPage(navStates.onboarding.default, ROUTE_EVENT.LOAD, {
      isNotApprovedRoute: !isOnApprovedRoute,
      signupData,
      user,
      onboardingIsComplete,
      search: undefined,
      locationPath: props.location.pathname,
    })
  ) {
    return (
      <Redirect
        to={getNextPage(navStates.onboarding.default, ROUTE_EVENT.LOAD, {
          isNotApprovedRoute: !isOnApprovedRoute,
          signupData,
          user,
          onboardingIsComplete,
          search: undefined,
        })}
      />
    );
  }

  return (
    <OnboardingLayout
      activeStep={findLastPath(props.location.pathname) as string}
      flags={flags}
    >
      <Switch>
        <Route
          path={`${props.match.path}/create-account`}
          render={() => <Page.CreateAccount {...props} />}
        />
        <Route
          path={`${props.match.path}/scheduling-preferences`}
          render={() => <Page.SchedulingPreferences {...props} />}
        />
        <Route
          path={`${props.match.path}/course-placement`}
          render={() => <Page.CoursePlacement {...props} />}
        />
        <Route
          path={`${props.match.path}/student-info`}
          render={() => <Page.StudentInfo {...props} />}
        />
        <Route
          path={`${props.match.path}/confirmation`}
          render={() => <Page.Confirmation {...props} />}
        />
      </Switch>
    </OnboardingLayout>
  );
};

export default Onboarding;
