import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Button from 'core-components/NewButton';
import { getSingleConferenceRoom } from 'services/instructorZoomAccounts/instructorZoomAccountsService';
import {
  ACUITY_APPOINTMENT_TYPES,
  ACUITY_SHADOW_INSTRUCTOR_CALENDAR_FORM_FIELD_ID,
} from 'constants/acuity';

import { Message } from 'core-components';

class JoinSessionButton extends Component {
  state = {
    conferenceRoom: {},
    isLoading: true,
  };
  componentDidMount() {
    this._isMounted = true;
    this.getVideoconferenceRoom();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.session.id !== this.props.session.id) {
      this.getVideoconferenceRoom();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getCalendarID() {
    if (
      this.props.session.appointmentTypeID ===
      ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION
    ) {
      return this.getInstructorCalendarIdFromShadowSession();
    }
    return this.props.session.calendarID;
  }
  getInstructorCalendarIdFromShadowSession() {
    const { forms } = this.props.session;
    const form = find(
      forms || [],
      f => f.id === ACUITY_SHADOW_INSTRUCTOR_CALENDAR_FORM_FIELD_ID,
    );
    if (form && form.values[0]) {
      return form.values[0].value;
    }
  }
  async getVideoconferenceRoom() {
    const { zoomUrl, zoomMeetingId } = await getSingleConferenceRoom({
      calendarId: this.getCalendarID(),
      lessonDatetime: this.props.session.datetime,
      isTrial: this.props.isTrial,
    });
    this.setState({
      zoomMeetingId,
      zoomUrl,
      isLoading: false,
    });
  }
  render() {
    if (this.state.isLoading) {
      return <Message status="info">Loading... Please wait</Message>;
    }

    const { zoomMeetingId, zoomUrl } = this.state;
    const { bannerButton } = this.props;
    if (zoomMeetingId && zoomUrl) {
      return (
        <Button variant={bannerButton ? 'secondary' : 'primary'} href={zoomUrl}>
          <div
            className={
              bannerButton ? 'font-medium text-j-purple-500' : 'text-j-purple-200'
            }
          >
            Join session <span className="pl-1"> ID: {zoomMeetingId}</span>
          </div>
        </Button>
      );
    }

    return (
      <Message status="error">
        Error: Please text (650) 263-4306 or email us at support@learnwithjuni.com
      </Message>
    );
  }
}
JoinSessionButton.defaultProps = {
  isTrial: false,
};
JoinSessionButton.propTypes = {
  session: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    calendarID: PropTypes.number.isRequired,
  }).isRequired,
  isTrial: PropTypes.bool,
};

export default JoinSessionButton;
