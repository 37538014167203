import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Auth from 'modules/Auth';
import { somePathMatches } from 'utils/paths';

import { Home, Login, Signup, GuestSignup } from 'authentication';
import JuniSpinner from 'components/JuniSpinner';

import { DefaultNav } from 'components/Nav';

import UserContext from 'modules/UserContext';

import 'modules/importFontAwesome';
import './app.css';
import { parseError } from 'utils/errors';
import { PageError } from 'components/ui';
import ObjectID from 'bson-objectid';
import { ImpersonationBanner } from 'app/miscellaneous/ImpersonationBanner';
import {
  JuniverseProject,
  JuniverseUserProfile,
  ZoomMeeting,
  ParentHelpCenter,
  StudentProjectsHome,
} from './miscellaneous';
import { AuthenticatedRoute, allowAdminOrInstructorWithPermission } from './routing';
import SignupSession from './signup_session';
import Signup2023 from './signup_2023';
import { PublicProfile } from './profiles/PublicProfile';
import StudentRecap from './learner/StudentRecap';
import { LEARNER_ROOT_PATH } from './learner/LearnerApp';

const AdminApp = React.lazy(() => import('./admin/AdminApp'));
const InstructorApp = React.lazy(() => import('./instructor/InstructorApp'));
const LearnerApp = React.lazy(() => import('./learner/LearnerApp'));
const CompletedCourseBase = React.lazy(() =>
  import('./learner/CompletedCourseBase'),
);
const CompletedBundleCertificate = React.lazy(() =>
  import('./learner/CompletedBundleCertificate'),
);
const UnlistedProject = React.lazy(() => import('./miscellaneous/UnlistedProject'));

// Paths that do not show any navbar
const NO_NAV_PATHS = [
  '/',
  '/teacher/login',
  '/teacher/signup',
  '/learner/signup',
  '/learner/login',
  /\/juniverse/,
  /\/learner/, // learner views handle their own nav in LearnerApp
  /\/teacher/, // teacher views handle their own nav in InstructorApp
  /\/admin/, // admin views handle their own nav in AdminApp
];

class App extends Component {
  state = { hideNavBar: false };

  setHideNavBar = val => {
    this.setState({ hideNavBar: val });
  };

  render() {
    const {
      user: currentUser,
      isLoadingUser,
      isLoadingUserError,
      loadUserData,
    } = this.context;

    if (isLoadingUserError) {
      return <PageError>{parseError(isLoadingUserError)}</PageError>;
    }
    if (isLoadingUser) {
      return <JuniSpinner />;
    }

    const searchParams = new URLSearchParams(this.props.location.search);

    return (
      <div
        className={`${
          this.props.location.pathname.startsWith('/learner')
            ? 'learner-app'
            : this.props.location.pathname.startsWith('/juniverse')
            ? 'juniverse-app'
            : ''
        }`}
      >
        {!somePathMatches(this.props.location.pathname, NO_NAV_PATHS) && (
          <DefaultNav
            pathname={this.props.location.pathname}
            userIsAuthenticated={Auth.isUserAuthenticated()}
          />
        )}
        <Suspense fallback={<JuniSpinner />}>
          {currentUser && <ImpersonationBanner />}

          <Switch>
            {/* these are public routes (ie. do not require authentication) */}
            <Route
              path="/guest/signup/:clubInvitationId"
              render={props => (
                <GuestSignup
                  {...props}
                  userType="parent"
                  loadUserData={loadUserData}
                />
              )}
            />
            <Route
              path="/teacher/signup"
              render={() => (
                <Signup checkAuth={this.loadUserData} userType="teacher" />
              )}
            />
            <Route
              path="/teacher/login"
              render={() => <Login checkAuth={loadUserData} userType="teacher" />}
            />
            <Route
              path="/learner/signup"
              render={() => (
                <Signup checkAuth={this.loadUserData} userType="parent" />
              )}
            />
            <Route
              path="/learner/login"
              render={() => <Login userType="parent" checkAuth={loadUserData} />}
            />
            {process.env.NODE_ENV !== 'production' && (
              <Route
                path="/profile/:username"
                render={props => <PublicProfile {...props} />}
              />
            )}
            <Route
              path="/recap/:userReportId"
              render={props =>
                ObjectID.isValid(props.match.params.userReportId) ? (
                  <StudentRecap userReportId={props.match.params.userReportId} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/course-certificate/:encryptedParams"
              render={props => (
                <CompletedCourseBase
                  encryptedParams={props.match.params.encryptedParams}
                />
              )}
            />
            <Route
              path="/bundle-certificate/:encryptedParams"
              render={props => (
                <CompletedBundleCertificate
                  encryptedParams={props.match.params.encryptedParams}
                />
              )}
            />
            <Route
              path="/view-project/:encryptedParams"
              render={props => (
                <UnlistedProject
                  encryptedParams={props.match.params.encryptedParams}
                />
              )}
            />
            <Route
              path="/juniverse/projects/:studentProjectId"
              render={props => <JuniverseProject {...props} />}
            />
            <Route
              path="/juniverse/student_project/:studentProjectId"
              render={props => (
                <Redirect
                  to={`/juniverse/projects/${props.match.params.studentProjectId}`}
                />
              )}
            />
            <Route
              path="/join/:meetingId"
              render={props => <ZoomMeeting {...props} />}
            />
            <Route
              path={[
                '/signup/guest/:invitationLookupId',
                '/signup/:signupSessionId?',
                '/signup2',
                '/onboarding/:signupSessionId?',
                '/create-account/',
                '/referral/:referralCode?',
                '/sign-up',
              ]}
              render={props => <SignupSession {...props} />}
            />
            <Route
              path={['/signup2023']}
              render={props => <Signup2023 {...props} />}
            />
            <Route path="/refer" render={() => <Redirect to="/learner/refer" />} />
            {currentUser?.userType === 'parent' && (
              <Route
                path="/help"
                render={props => (
                  <Redirect to={`/learner${props.location.pathname}`} />
                )}
              />
            )}
            {['admin', 'instructor'].includes(currentUser?.userType) && (
              <Route
                path="/help"
                render={props => (
                  <Redirect to={`/teacher${props.location.pathname}`} />
                )}
              />
            )}
            <Route path="/help" render={props => <ParentHelpCenter {...props} />} />
            {currentUser && (
              <AuthenticatedRoute
                path="/admin"
                userPermissions={currentUser.permissions}
                userType={currentUser.userType}
                userRoles={currentUser.roles}
                isAuthorized={allowAdminOrInstructorWithPermission}
                render={props => (
                  <AdminApp
                    {...props}
                    userPermissions={currentUser.permissions}
                    userType={currentUser.userType}
                    userId={currentUser._id}
                    currentUser={currentUser}
                    hideNavBar={this.state.hideNavBar}
                    setHideNavBar={this.setHideNavBar}
                  />
                )}
              />
            )}
            {currentUser && searchParams.get('preview') !== 'true' && (
              <AuthenticatedRoute
                path={
                  currentUser.userType === 'parent'
                    ? [
                        LEARNER_ROOT_PATH,
                        '/juniverse/users/:username',
                        '/student-projects',
                      ] // "embedded pages" in the LearnerApp go in this array
                    : LEARNER_ROOT_PATH
                }
                userType={currentUser.userType}
                allowParent
                render={props => (
                  <LearnerApp
                    {...props}
                    userId={currentUser._id}
                    hideNavBar={this.state.hideNavBar}
                    setHideNavBar={this.setHideNavBar}
                  />
                )}
              />
            )}
            {currentUser && (
              <AuthenticatedRoute
                path="/teacher"
                userPermissions={currentUser.permissions}
                userType={currentUser.userType}
                allowTeacher
                allowAdmin
                render={props => (
                  <InstructorApp
                    {...props}
                    userId={currentUser._id}
                    userPermissions={currentUser.permissions}
                    userType={currentUser.userType}
                    roles={currentUser.roles}
                    email={currentUser.email}
                    instructor={currentUser.instructor}
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    hideNavBar={this.state.hideNavBar}
                    setHideNavBar={this.setHideNavBar}
                  />
                )}
              />
            )}
            <Route
              path="/juniverse/users/:username"
              render={props => (
                <JuniverseUserProfile
                  username={decodeURIComponent(props.match.params.username)}
                />
              )}
            />
            <Route
              path="/student-projects/"
              render={props => <StudentProjectsHome {...props} />}
            />
            <Route
              path="/"
              render={() => (
                <Home userType={currentUser ? currentUser.userType : ''} />
              )}
            />
          </Switch>
        </Suspense>
      </div>
    );
  }
}
App.contextType = UserContext;

export default App;
