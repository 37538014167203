export const CANCELLATION_REASONS = {
  pausingSubscription: 'pausing_subscription',
  otherExtracurriculars: 'other_extracurriculars',
  instructorIssues: 'instructor_issues',
  lossOfInterest: 'loss_of_interest',
  focusOnAcademics: 'focus_on_academics',
  financialReasons: 'financial_reasons',
  classroomReasons: 'classroom_reasons',
  other: 'other',
};

export const CANCELLATION_REASONS_OPTIONS = [
  {
    value: CANCELLATION_REASONS.pausingSubscription,
    label: 'Pausing my subscription',
  },
  {
    value: CANCELLATION_REASONS.otherExtracurriculars,
    label: 'Other extracurricular activities',
  },
  {
    value: CANCELLATION_REASONS.instructorIssues,
    label: 'Instructor issues',
  },
  {
    value: CANCELLATION_REASONS.lossOfInterest,
    label: 'Loss of interest',
  },
  {
    value: CANCELLATION_REASONS.focusOnAcademics,
    label: 'Focus on academics',
  },
  {
    value: CANCELLATION_REASONS.financialReasons,
    label: 'Financial reasons',
  },
  {
    value: CANCELLATION_REASONS.classroomReasons,
    label: 'Classroom reasons',
  },
  {
    value: CANCELLATION_REASONS.other,
    label: 'Other',
  },
];
