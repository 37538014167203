import React from 'react';
import { NewButton } from 'core-components';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';
import { getSubscriptionMetaData } from 'app/learner/LearnerAccountV2/utils';
import _ from 'lodash';
import LearnerPortalModal from '../LearnerPortalModal';

const PaymentConfirmationModal = ({
  parent,
  formState,
  formValues,
  updateFormState,
  coursesQuery,
}) => {
  const { subscription, currentStudent, metadataNew, isAsyncAdded } = formValues;

  const courses = coursesQuery?.data?.getCourses;

  const studentCurrentCourses = currentStudent.hasMultipleTracks
    ? currentStudent.tracks
    : [currentStudent.track];

  const courseMetadataObj = getSubscriptionMetaData(subscription);

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const subscriptionHasChanged = _.some(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );
  return (
    <LearnerPortalModal
      title="You're all set!"
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            updateFormState('', 'modal');
          }}
        >
          <div className="font-medium">Close</div>
        </NewButton>
      }
    >
      {subscriptionHasChanged ? (
        <div className="flex leading-5 flex-col text-j-dark-600 gap-4">
          <div>
            We're currently processing your enrollment, and here's what happens next:{' '}
          </div>
          <div>
            • We will review the information you submitted to make sure everything is
            filled out correctly.
          </div>
          <div>
            • Our team will email you the week before your classes begin with the
            finalized schedule.
          </div>
          <div>
            {`• We've sent you an email at ${parent.email} to confirm your changes.`}{' '}
          </div>
          {isAsyncAdded && (
            <div>
              {'• Start exploring our '}
              <a href={`/learner/${currentStudent._id.toString()}/on_demand/`}>
                On Demand Library
              </a>
              {' now and get learning!'}
            </div>
          )}
          <div>
            {'If you have any questions please '}
            <a href="https://junilearning.com/contact/">contact us</a>.
          </div>
        </div>
      ) : (
        <div className="flex leading-5 flex-col text-j-dark-600 gap-4">
          <div>
            {' '}
            {`We've sent you an email at ${parent.email} to confirm your changes.`}{' '}
          </div>
          <div>
            {'Start exploring our '}
            <a href={`/learner/${currentStudent._id.toString()}/on_demand/`}>
              On Demand Library
            </a>
            {' now and get learning!'}
          </div>
          <div>
            {'If you have any questions please '}
            <a href="https://junilearning.com/contact/">contact us</a>.
          </div>
        </div>
      )}
    </LearnerPortalModal>
  );
};
export default PaymentConfirmationModal;
