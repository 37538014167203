/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import './jide_nav_bar.css';
import { useCourseNameToBasicMetadata } from 'hooks/course';
import CreateCustomProjectModal from './CreateCustomProjectModal';
import JideNav from './JideNav';

const COURSES_ACCESSIBLE_BY_ADMIN = [
  'python_graphics_superstar',
  'web_app_master',
  'web_superstar',
  'game_superstar',
  'scratch_level_1',
  'scratch_level_2',
];

class JideNavBar extends PureComponent {
  state = {
    newNav: {},
    expanded: {},
    createProjectModalVisible: false,
  };
  videosCountByCourseId = {};

  componentDidMount() {
    document.addEventListener('click', this.closeDropdownsOnClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropdownsOnClickOutside, false);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState.newNav !== this.state.newNav &&
      Object.keys(prevState.newNav).length > 0
    ) {
      this.setState({ prevNav: prevState.newNav });
    }
  }

  closeDropdownsOnClickOutside = e => {
    const container = document.getElementById('jide-nb');
    if (container && container !== e.target && !container.contains(e.target)) {
      this.closeDropdowns();
    }
  };

  closeDropdowns = () => {
    this.setState({ newNav: {}, expanded: {} });
  };

  onHeaderClickHandler = inputSectionType => {
    // preload newNav with ids for headers to the left of the one clicked
    const tempNewNav = {};
    const index = this.props.navBarSections.indexOf(inputSectionType);
    for (let i = 0; i < index; i += 1) {
      const sectionType = this.props.navBarSections[i];
      if (
        this.props.onlyTrialCourses.length === 0 &&
        !(sectionType in this.props.activeNav)
      )
        return;
      if (
        this.props.onlyTrialCourses.length === 0 &&
        i !== 0 &&
        !(sectionType in this.props.activeNav)
      )
        return;
      tempNewNav[sectionType] = this.props.activeNav[sectionType];
    }

    // close all dropdowns, but open the one clicked
    const tempExpanded = {};
    tempExpanded[inputSectionType] = true;
    this.setState({ newNav: tempNewNav, expanded: tempExpanded });
  };

  onOptionClickHandler = async (inputSectionType, option) => {
    if (
      !this.props.modalViewMode &&
      this.props.jideUser &&
      this.props.jideUser.type === 'student'
    ) {
      if (!option.isUnlocked && ['module', 'project'].includes(inputSectionType)) {
        // if in learner mode and clicked option is a locked module whose course has at least one video,
        // navigate to that module's home page
        if (option.courseHasVideos) {
          this.props.history.push(
            `/learner/${this.props.activeNav.student}/my_courses/module/${option.id}`,
          );
          this.setState({ newNav: {}, expanded: {} });
        }

        // if in learner mode and clicked option is a locked project or a locked module whose course has no videos, do nothing
        return;
      }

      if (inputSectionType === 'course') {
        // navigate to course home page when a course level option is clicked
        this.props.history.push(
          `/learner/${this.props.activeNav.student}/my_courses/${option.properties.name}`,
        );
        this.setState({ newNav: {}, expanded: {} });
        return;
      }
    }

    const optionId = option.id;
    if (inputSectionType === 'student') {
      // deprecating fetchstudent for now
      // await this.props.fetchStudent(optionId);
    }

    // preserve newNav and expanded states for sections
    // to the left of the one clicked
    const tempNewNav = {};
    const tempExpanded = {};
    const index = this.props.navBarSections.indexOf(inputSectionType);
    for (let i = 0; i < index; i += 1) {
      const sectionType = this.props.navBarSections[i];
      tempNewNav[sectionType] = this.state.newNav[sectionType];
      tempExpanded[sectionType] = this.state.expanded[sectionType];
    }

    // set newNav id for clicked section and keep dropdown expanded
    tempNewNav[inputSectionType] = optionId;
    tempExpanded[inputSectionType] = true;

    // if it was a project that was clicked, close dropdowns and clear newNav
    // and execute openNewTab callback, then end
    if (index === this.props.navBarSections.length - 1) {
      this.setState({ newNav: {}, expanded: {} }, () => {
        this.props.openNewTab(tempNewNav);
        const { location } = this.props;
        // if project ids are deep linked in url, need to update it when new project is opened
        let index = location.pathname.indexOf('/project/');
        if (index !== -1) {
          index += '/project/'.length;
          const newLocation =
            location.pathname.substring(0, index) + tempNewNav.project;
          this.props.history.replace(newLocation);
        }
      });
      return;
    }

    // if it's not the last dropdown, expand the next dropdown to the right
    const nextSectionType = this.props.navBarSections[index + 1];
    tempExpanded[nextSectionType] = true;
    this.setState({ newNav: tempNewNav, expanded: tempExpanded });
  };

  getStudentOptionsHandler = async () => [];
  // if (!query || query.length < 3) return [];
  // const config = {
  //   headers: {
  //     Authorization: `bearer ${Auth.getToken()}`,
  //   },
  // };
  // const res = await axios.get(
  //   `${apiUrl}/api_admin/jide_search_students/${query}`,
  //   config,
  // );
  // return res.data;

  getOptionsHandler = inputSectionType => {
    // get id of previous section. check newNav first, then activeNav
    const index = this.props.navBarSections.indexOf(inputSectionType);
    const prevSectionType = this.props.navBarSections[index - 1];
    const { trialCourses } = this.props;
    const inTrialMode = trialCourses.length > 0;
    let options = [];
    if (inTrialMode && inputSectionType === 'course') {
      options = trialCourses.map(c => ({
        id: c.id,
        type: c.type,
        displayName: c.displayName,
        properties: c.properties,
        isCompleted: false,
        isInProgress: false,
        isUnlocked: true,
      }));
    } else {
      const id = this.state.newNav[prevSectionType]
        ? this.state.newNav[prevSectionType]
        : this.props.activeNav[prevSectionType];
      // get id of student currently selected. check newNav then activeNav
      const studentId = this.state.newNav.student
        ? this.state.newNav.student
        : this.props.activeNav.student;

      const isAdminUser =
        this.props.jideUser && this.props.jideUser.type === 'admin';

      // then get the object from idLookup, reconcile with student progress,
      // and return its children as options
      const res = this.props.idLookup[id];
      if (!res) return;
      const student = this.props.idLookup[studentId];
      options = res.children
        .filter(
          c =>
            inputSectionType !== 'course' ||
            // if in /admin/my_code_sandbox_jide, only allow Python L1, Scratch, and Web type courses
            (isAdminUser &&
              COURSES_ACCESSIBLE_BY_ADMIN.includes(c.properties.name) &&
              this.props.courseTypes.includes(c.properties.courseType)) ||
            (!isAdminUser &&
              this.props.courseTypes.includes(c.properties.courseType)),
        )
        .map(c => {
          const option = {
            id: c.id,
            type: c.type,
            displayName: c.displayName,
            properties: c.properties,
            isCompleted: inTrialMode ? false : c.id in student.completed,
            isInProgress: inTrialMode ? false : c.id in student.inProgress,
            isUnlocked: inTrialMode
              ? inputSectionType === 'module'
                ? c.properties.isTrialModule
                : true
              : inputSectionType === 'course' ||
                isAdminUser ||
                c.id in student.unlocked,
          };
          if (inputSectionType === 'module') {
            if (
              !Object.prototype.hasOwnProperty.call(
                this.videosCountByCourseId,
                c.properties.courseId,
              )
            ) {
              const course = this.props.idLookup[c.properties.courseId];
              const videos =
                course && course.children && course.children.length > 0
                  ? course.children.reduce(
                      (agg, cur) => [...agg, ...(cur.properties.videos || [])],
                      [],
                    )
                  : [];
              this.videosCountByCourseId[c.properties.courseId] = videos.length;
            }
            option.courseHasVideos =
              this.videosCountByCourseId[c.properties.courseId] > 0;
          }
          return option;
        });

      if (inputSectionType === 'project') {
        options = options.filter(option =>
          [
            'project',
            'problem_set',
            'concept',
            'activity',
            'reading_project',
            'writing_project',
            'question_set',
          ].includes(option.type),
        );
      }
      if (!inTrialMode && !this.props.includeTrialModules) {
        options = options.filter(o => !o.properties.isTrialModule);
      }
    }
    return options;
  };

  onCustomProjectModalClosed = () => {
    this.setState({ createProjectModalVisible: false });
    if (typeof this.props.createProjectModalClosed === 'function')
      this.props.createProjectModalClosed();
  };

  onCustomProjectCreated = project => {
    const { _id, studentId, displayName, moduleId } = project;

    this.setState(prevState => ({
      newNav: {
        student: studentId,
        course: prevState.prevNav?.course || this.props.activeNav.course,
        module: moduleId,
        project: _id,
      },
    }));

    this.onOptionClickHandler('project', {
      id: _id,
      isUnlocked: true,
      displayName,
      properties: project,
      type: 'project',
    });
  };

  render() {
    let navBarSectionComponents = this.props.navBarSections.map(s => {
      if (s !== 'student' && this.props.isLoading) {
        return null;
      }
      return (
        <JideNav
          jideUser={this.props.jideUser}
          key={s}
          sectionType={s}
          courseTypes={this.props.courseTypes}
          idLookup={this.props.idLookup}
          activeNav={this.props.activeNav}
          newNav={this.state.newNav}
          expanded={this.state.expanded}
          onHeaderClick={this.onHeaderClickHandler}
          onOptionClick={this.onOptionClickHandler}
          getOptions={this.getOptionsHandler}
          getStudentOptions={this.getStudentOptionsHandler}
          closeDropdowns={this.closeDropdowns}
          onCreateCustomProjectClick={() =>
            this.setState({ createProjectModalVisible: true })
          }
        />
      );
    });
    const isAdminUser = this.props.jideUser && this.props.jideUser.type === 'admin';
    if (!isAdminUser) {
      navBarSectionComponents = navBarSectionComponents.slice(1);
    }

    const courseId = this.state.prevNav?.course || this.props.activeNav.course;
    const courseName = this.props.idLookup[courseId]?.properties?.name;
    const projectType = this.props.courseNameToBasicMetadata(courseName)
      .defaultJideEnv;
    return (
      <>
        <div id="jide-nb">{navBarSectionComponents}</div>

        <CreateCustomProjectModal
          isVisible={
            this.state.createProjectModalVisible ||
            this.props.createProjectModalVisible
          }
          idLookup={this.props.idLookup}
          reloadIdLookup={this.props.reloadIdLookup}
          studentId={this.state.prevNav?.student || this.props.activeNav.student}
          moduleId={this.state.prevNav?.module || this.props.activeNav.module}
          closeModal={this.onCustomProjectModalClosed}
          onSuccess={this.onCustomProjectCreated}
          projectType={projectType}
        />
      </>
    );
  }
}
JideNavBar.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  courseTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeNav: PropTypes.shape({
    student: PropTypes.string,
    course: PropTypes.string,
    module: PropTypes.string,
    project: PropTypes.string,
  }).isRequired,
  trialCourses: PropTypes.array,
  onlyTrialCourses: PropTypes.array,
  navBarSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  includeTrialModules: PropTypes.bool,
  idLookup: PropTypes.shape({}).isRequired,
  reloadIdLookup: PropTypes.func.isRequired,
  openNewTab: PropTypes.func.isRequired,
  createProjectModalVisible: PropTypes.bool,
  createProjectModalClosed: PropTypes.func,
  courseNameToBasicMetadata: PropTypes.func,
  history: PropTypes.any,
  location: PropTypes.any,
};

const JideNavBarWrapper = props => {
  const { courseNameToBasicMetadata } = useCourseNameToBasicMetadata();
  const history = useHistory();
  const location = useLocation();
  return (
    <JideNavBar
      courseNameToBasicMetadata={courseNameToBasicMetadata}
      history={history}
      location={location}
      {...props}
    />
  );
};
export default JideNavBarWrapper;
