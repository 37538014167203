import React, { FC } from 'react';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { NewButton as Button, Message, NewCard as Card } from 'core-components';
import { RecentProject } from 'generated/graphql';
import { projectTypeToRenderInfo } from 'constants/project_types';

import { openInNewTab } from 'utils/routing';
import { ApolloError } from '@apollo/client';
import {
  shareableProjectTypes,
  environmentLookupBySubjectNameAndSectionType,
} from 'services/juni_ide';
import { useCourseNameToBasicMetadata } from 'hooks/course';
import { LatestProjCardContent } from './styles';
import LatestProjectZeroState from './zero-states/LatestProjectZeroState';

interface LatestProjectCardProps {
  studentFirstName: string;
  projects?: RecentProject[] | null;
  error?: ApolloError;
}

const CardRow: FC<RecentProject> = ({
  projectName,
  publicProjectUrl,
  courseName,
  updatedAt,
  sectionType,
  subjectName,
}) => {
  const { courseNameToBasicMetadata } = useCourseNameToBasicMetadata();

  const projectType = sectionType
    ? environmentLookupBySubjectNameAndSectionType(subjectName, sectionType)
    : courseNameToBasicMetadata(courseName).defaultJideEnv;
  const isShareable = shareableProjectTypes.includes(projectType);
  const courseInfo = projectTypeToRenderInfo(projectType);

  return (
    <div key={publicProjectUrl}>
      <div className="flex flex-col sm:flex-row p-6 w-full box-border font-graphik ">
        <LatestProjCardContent>
          <div
            className={classNames(
              `flex text-white items-center justify-center`,
              `rounded-lg bg-${courseInfo.color} w-full h-32 sm:w-44 sm:h-28 text-xs p-4 box-border`,
            )}
          >
            {React.createElement(courseInfo.icon, { className: 'w-full h-full' })}
          </div>
          <div className="flex flex-col mt-6 sm:mt-0 sm:ml-6 lg:flex-1">
            <div className="font-medium text-lg text-j-dark-600">{projectName}</div>
            <div className="flex sm:flex-col lg:flex-row text-j-dark-300 text-sm mt-2">
              <div className="flex">
                {React.createElement(courseInfo.icon, { className: 'w-4 h-4' })}

                <div className="ml-2">{courseInfo.displayName}</div>
              </div>
              {/* {viewCount && viewCount > 0 && (
                <div className="ml-4 flex text-j-dark-300 sm:ml-0 lg:ml-6">
                  <Eye className="w-4 h-4" />
                  <div className="ml-2">{`${viewCount} view${
                    viewCount > 1 ? 's' : ''
                  }`}</div>
                </div>
              )} */}
            </div>
            {isShareable && (
              <div className="mt-auto">
                <Button
                  size="small"
                  onClick={() => openInNewTab(`/view-project/${publicProjectUrl}`)}
                >
                  View this Project
                </Button>
              </div>
            )}
          </div>
        </LatestProjCardContent>
        {updatedAt && (
          <div className="text-j-dark-300 text-sm mt-2 sm:mt-0 md:text-right flex-1">
            updated {formatDistanceToNow(new Date(updatedAt))} ago
          </div>
        )}
      </div>
    </div>
  );
};

const LatestProjectsCard: FC<LatestProjectCardProps> = ({
  studentFirstName,
  projects,
  error,
}) => (
  <Card padding="0" shadow="1">
    <div className="font-graphik text-xl text-j-dark-600 font-medium p-6">
      {`${studentFirstName}'s Latest Projects`}
    </div>
    <Card.Divider />
    {error ? (
      <div className="p-6">
        <Message status="error">{error.message}</Message>
      </div>
    ) : projects && projects?.length > 0 ? (
      projects
        ?.filter(project => project)
        .map((project, i) => (
          <>
            <CardRow
              projectName={project.projectName}
              courseName={project.courseName}
              publicProjectUrl={project.publicProjectUrl}
              updatedAt={project.updatedAt}
              key={project.publicProjectUrl}
              sectionType={project.sectionType}
              subjectName={project.subjectName}
            />{' '}
            {i !== projects.length - 1 && <Card.Divider />}
          </>
        ))
    ) : (
      <LatestProjectZeroState studentName={studentFirstName} />
    )}
  </Card>
);

export default LatestProjectsCard;
