import React from 'react';
import { Divider } from 'core-components';
import { ASYNC_PLAN_PRODUCT, formatPrice } from 'constants/subscription_plans';
import { getSubscriptionDisplayName, hasAsync } from 'utils/stripe';
import { format, fromUnixTime } from 'date-fns';

import { StripeSubscription } from 'generated/graphql';

export interface Props {
  subscription: StripeSubscription;
}

const CurrentSubscriptionDetails: React.FC<Props> = ({ subscription }) => {
  const hasAsyncProduct = hasAsync(subscription);
  const productName = getSubscriptionDisplayName(subscription);
  const baseCost = subscription?.items.data.reduce(
    (total: number, subscriptionItem) => total + (subscriptionItem.plan.amount || 0),
    0,
  );
  let monthlyCost = baseCost;
  if (subscription?.discount && subscription?.discount.coupon && baseCost) {
    const { coupon } = subscription.discount;
    monthlyCost =
      coupon.amount_off !== null
        ? baseCost - (coupon.amount_off || 0)
        : (1 - (coupon.percent_off || 0) / 100) * baseCost;
  }

  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="text-j-dark-300">Subscription</div>
        {productName && <div>{productName}</div>}
        {hasAsyncProduct && <div>{ASYNC_PLAN_PRODUCT.displayName}</div>}
      </div>
      <Divider className="my-4" />
      <div className="flex flex-row w-full justify-start">
        <div className="flex flex-col w-1/2 gap-2">
          <div className="text-j-dark-300">
            {subscription.status !== 'canceled'
              ? 'Next Bill Date'
              : 'Expiration Date'}
          </div>
          <div>
            {format(fromUnixTime(subscription.current_period_end), 'MMM d, yyyy')}
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-2">
          <div className="text-j-dark-300">Monthly Price</div>
          <div>{`${formatPrice(monthlyCost / 100)}/month`}</div>
        </div>
      </div>
    </div>
  );
};

export default CurrentSubscriptionDetails;
