import React from 'react';
import { useSignupData } from 'app/signup_2023/hooks';
import { SignupProduct, useGetStarterCurriculumQuery } from 'generated/graphql';
import { PRODUCT_METADATA } from 'app/signup_2023/constants/products';
import { ErrorableLoading } from 'components/ui';
import { getTutoringCourseInfo } from 'app/signup_2023/utils/tutoring_courses';
import { keyBy } from 'lodash';
import { subtitleTextStyle } from '../../constants';

interface CompleteEnrollment {
  productName: string;
  courseId: string;
  subjectId?: string;
  tutoringCourse: string;
  tutoringDetails: string;
}

const Course = () => {
  const { signupData, error: signupError, loading: signupLoading } = useSignupData();

  const courseIds = signupData?.students
    ?.flatMap(s => s?.enrollments)
    .flatMap(e => e?.courseId)
    .filter(Boolean);

  const {
    data,
    error: curriculumError,
    loading: curriculumLoading,
  } = useGetStarterCurriculumQuery({
    variables: { input: { ids: courseIds } },
    skip: (courseIds?.length || 0) < 1,
  });

  const subjectsById = keyBy(data?.getSubjects, '_id');
  const courses = data?.getCourses;

  if (
    !signupData ||
    signupError ||
    signupLoading ||
    curriculumError ||
    curriculumLoading
  ) {
    return <ErrorableLoading error={signupError || curriculumError} />;
  }

  const enrollments = (signupData.students?.flatMap(s => s?.enrollments) ||
    []) as CompleteEnrollment[];

  return (
    <section>
      <h2 className={subtitleTextStyle}>Course Selection</h2>
      <div className="space-y-4">
        {enrollments.map(
          (
            { productName, subjectId, courseId, tutoringCourse, tutoringDetails },
            i,
          ) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="bg-j-gray-200 rounded-lg p-4">
              <h3 className="text-sm text-j-dark-600 font-medium m-0 pb-2">
                {(subjectId && subjectsById[subjectId]?.displayName) ||
                  PRODUCT_METADATA[SignupProduct.PrivateTutoring].displayName}
              </h3>
              <div className="text-sm text-j-dark-600">
                {courseId && (
                  <div>
                    <span className="font-medium">Starter course: </span>
                    <p>
                      {courses?.find(course => course._id === courseId)?.displayName}
                    </p>
                  </div>
                )}
                {productName === SignupProduct.PrivateTutoring && (
                  <>
                    <div>
                      <span className="font-medium">Subject: </span>
                      {getTutoringCourseInfo(tutoringCourse)?.label}
                    </div>
                    <div>
                      <span className="font-medium">Additional details: </span>
                      {tutoringDetails}
                    </div>
                  </>
                )}
              </div>
            </div>
          ),
        )}
      </div>
    </section>
  );
};

export default Course;
