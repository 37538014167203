import React, { FC, useEffect } from 'react';
import { ModuleSectionProgress, Student, TeacherSession } from 'models';
import { useModuleProgressQuery } from 'generated/graphql';
import { SUBJECT_COURSE_TYPES, SUBJECT_TYPE } from 'constants/subjects';
import _ from 'lodash';
import { cleanCourseDisplayNameForLearnerAudience } from 'utils/cleanCourseDisplayNameForLearnerAudience';
import ProgressBar from './ProgressBar';
import TopicsCovered from './TopicsCovered';
import { getMemoizedTopicsCovered } from './getMemoizedTopicsCovered';

interface Props {
  teacherSession: TeacherSession;
  sessionSubject: SUBJECT_TYPE;
  idLookup: any;
  student: Student;
  teacherMode: boolean;
  moduleSectionInfo: any;
  moduleSectionProgresses: ModuleSectionProgress[];
}

const CourseProgress: FC<Props> = ({
  teacherSession,
  idLookup,
  sessionSubject,
  student,
  moduleSectionProgresses,
  moduleSectionInfo,
  teacherMode,
}) => {
  const { studentId } = teacherSession;

  const topicsCovered = getMemoizedTopicsCovered({
    moduleSectionProgresses,
    teacherSessionStart: teacherSession?.start || '',
    teacherSessionEnd: teacherSession?.end || '',
    moduleSectionInfo,
    teacherMode,
    idLookup,
  }).filter(topic => !topic.displayName.match(/for instructor/));

  const {
    data: studentModuleProgress,
    refetch: refetchStudentModuleProgress,
  } = useModuleProgressQuery({
    variables: { studentId },
  });

  // refetch moduleProgress when teacherSession.end changes
  useEffect(() => {
    refetchStudentModuleProgress();
  }, [refetchStudentModuleProgress, teacherSession.end]);

  const moduleProgressData =
    studentModuleProgress?.queryModuleProgresses.items || [];
  const moduleProgressBeforeSessionEnd = moduleProgressData?.filter(
    course =>
      course?.timestamp &&
      new Date(course?.timestamp) <= new Date(teacherSession.end),
  );
  const modulesWithProgressBeforeSessionEnd = _.keyBy(
    moduleProgressBeforeSessionEnd,
    'moduleId',
  );

  const studentCourses = student.hasMultipleTracks
    ? student.tracks || []
    : [student.track];

  // TO DO: Refactor to remove idLookup, either by lookup library or attaching session note to a specific track
  const coursesProgressedInSession = studentCourses
    //  filter for courses for the current sessionSubject
    .filter(
      course =>
        idLookup[course]?.properties.courseType ===
        SUBJECT_COURSE_TYPES[sessionSubject],
    )
    // filter for courses in which student has made some progress in current session in topics or module
    .filter(course => {
      const courseId = idLookup[course]?.id;
      return (
        topicsCovered.some(topic => {
          const moduleSectionData = idLookup[topic.moduleSectionId];
          return moduleSectionData?.properties?.courseId === courseId;
        }) ||
        Object.keys(modulesWithProgressBeforeSessionEnd).some(inProgressModuleIds =>
          _.includes(idLookup[course].children, inProgressModuleIds),
        )
      );
    });

  const progressBarsForCoursesWithProgress = coursesProgressedInSession.map(
    course => {
      const {
        displayName: courseDisplayName,
        children: allCourseModules,
      } = idLookup[course];
      const totalModuleCount = allCourseModules.length;
      const modulesCompleted = allCourseModules.reduce(
        (modulesCompleted: number, module: { id: string }) =>
          modulesCompleted +
          (modulesWithProgressBeforeSessionEnd[module.id] &&
          modulesWithProgressBeforeSessionEnd[module.id]?.isCompleted
            ? 1
            : 0),
        0,
      );

      return (
        <ProgressBar
          key={`course-progress-${courseDisplayName}`}
          modulesCompleted={modulesCompleted}
          totalModules={totalModuleCount}
          courseName={
            teacherMode
              ? courseDisplayName
              : cleanCourseDisplayNameForLearnerAudience(courseDisplayName)
          }
        />
      );
    },
  );

  if (progressBarsForCoursesWithProgress.length > 0 || topicsCovered.length > 0) {
    return (
      <div className="font-graphik">
        <div className="text-j-dark-600 font-medium text-xl pb-6">
          Course Progress
        </div>
        <div className="flex flex-col gap-6">
          {progressBarsForCoursesWithProgress}
          <TopicsCovered topics={topicsCovered} />
        </div>
      </div>
    );
  }
  return null;
};

export default CourseProgress;
