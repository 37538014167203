import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import juniAxios from 'services/axios';
import moment from 'moment';
import queryString from 'query-string';

import { NewButton, Icon } from 'core-components';
import JuniSpinner from 'components/JuniSpinner';
import AuthLayout from '../AuthLayout';
import { REFETCH_INVITE_INTERVAL } from '../constants';
import { StudentSignup, InstructorSignup } from './components';

interface Props {
  userType: 'parent' | 'teacher';
  signupData: any;
}

enum InviteStatus {
  Valid = 'inviteCodeValid',
  Expired = 'invitation_expired',
  Invalid = 'inviteCodeInvalid',
  AlreadyUsed = 'invitationAlreadyUsed',
}

const Signup: React.FC<Props> = ({ userType, signupData }) => {
  const [status, setStatus] = useState<InviteStatus>(InviteStatus.Invalid);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshingInvite, setIsRefreshingInvite] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const { invite_code } = queryString.parse(search);
  const inviteCode = invite_code || signupData?.invitationCode;

  const getInviteInfoInBackground = useCallback(async () => {
    setIsRefreshingInvite(true);
    try {
      const res = await juniAxios.get(`/auth/get_invitation/${inviteCode}`);
      const { data } = res;
      if (data && data.length === 0) {
        // if no records found with this invite code, then the invite code is invalid
        setStatus(InviteStatus.Invalid);
        setIsLoading(false);
        setIsRefreshingInvite(false);
      } else if (data.isValid === false) {
        // if the invitation has already been used, it will return with isValid: false
        setStatus(InviteStatus.AlreadyUsed);
        setEmail(data.Email);
        setIsLoading(false);
        setIsRefreshingInvite(false);
      } else if (data.userType !== userType) {
        // if the invitation is not for the right userType, then the invite code is invalid
        setStatus(InviteStatus.Invalid);
        setIsLoading(false);
        setIsRefreshingInvite(false);
      } else {
        // check that the invite code has not expired
        let expirationTimestamp = null;
        if (data.expirationTimestamp.length > 14) {
          expirationTimestamp = moment(res.data.expirationTimestamp);
        } else {
          expirationTimestamp = moment.unix(res.data.expirationTimestamp / 1000);
        }
        if (expirationTimestamp && expirationTimestamp.isAfter(moment())) {
          setStatus(InviteStatus.Valid);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.email);
          setIsLoading(false);
          setIsRefreshingInvite(false);
        } else {
          setStatus(InviteStatus.Expired);
          setIsLoading(false);
          setIsRefreshingInvite(false);
        }
      }
    } catch (err) {
      console.log(err);
      setStatus(InviteStatus.Invalid);
      setIsLoading(false);
      setIsRefreshingInvite(false);
    }
  }, [inviteCode, userType]);

  useEffect(() => {
    window.addEventListener('focus', getInviteInfoInBackground);
    getInviteInfoInBackground();
    const updateInterval = setInterval(
      getInviteInfoInBackground,
      REFETCH_INVITE_INTERVAL,
    );
    return () => {
      window.removeEventListener('focus', getInviteInfoInBackground);
      clearInterval(updateInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode]);

  const getStatusComponent = (status: InviteStatus) => {
    switch (status) {
      case InviteStatus.Expired:
        return (
          <div
            className="font-graphik text-j-dark-600 border border-j-dark-700 border-solid w-3/4 p-5 rounded m-auto"
            id="expired"
          >
            <div className="text-lg font-medium mb-2">Oops!</div>
            <div className="text-sm">
              <p>Your invitation has expired.</p>
              <p>
                For assistance, please email{' '}
                <a href="mailto:support@learnwithjuni.com">
                  support@learnwithjuni.com
                </a>
                .
              </p>
            </div>
          </div>
        );
      case InviteStatus.AlreadyUsed:
        return (
          <div className="font-graphik text-j-dark-600" id="invitation-used">
            <div className="text-xl sm:text-2xl lg:text-3xl font-medium text-j-dark-700">
              This account's password has already been set!
            </div>
            <hr className="border-0 bg-j-gray-400 h-px" />
            <div className="flex flex-col gap-4">
              <div className="text-sm w-full">
                Somebody has already set the password for{' '}
                {email ? <b>{email}</b> : 'this account'}. Please go ahead and login.
              </div>
              <div className="flex flex-row gap-2">
                <NewButton
                  variant="primary"
                  renderIconRight={props => <Icon.ArrowRight {...props} />}
                  onClick={() =>
                    history.push(
                      userType === 'teacher' ? '/teacher/login' : '/learner/login',
                    )
                  }
                >
                  Login
                </NewButton>
                <NewButton
                  variant="secondary"
                  onClick={() => history.push('/forgot_password')}
                >
                  Forgot password
                </NewButton>
              </div>
              <p className="text-sm">
                For assistance, please email{' '}
                <a href="mailto:support@learnwithjuni.com">
                  support@learnwithjuni.com
                </a>
                .
              </p>
            </div>
          </div>
        );
      case InviteStatus.Valid:
        return userType === 'parent' ? (
          <StudentSignup
            isRefreshingInvite={isRefreshingInvite}
            inviteCode={inviteCode}
            isLoading={isLoading}
            firstName={firstName}
            lastName={lastName}
            email={email}
          />
        ) : (
          <InstructorSignup
            isRefreshingInvite={isRefreshingInvite}
            inviteCode={inviteCode}
            isLoading={isLoading}
            email={email}
          />
        );
      default:
        return (
          <div
            className="font-graphik text-j-dark-600 border border-j-dark-700 border-solid w-3/4 p-5 rounded m-auto"
            id="invalid"
          >
            <div className="text-lg font-medium mb-2">Oops!</div>
            <div className="text-sm">
              <p>
                You must have a valid invitation to sign up for your Juni account.
              </p>
              <p>
                For assistance, please email{' '}
                <a href="mailto:support@learnwithjuni.com">
                  support@learnwithjuni.com
                </a>
                .
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <AuthLayout>
      <div className="auth-form auth-form-signup">
        {isLoading ? (
          <JuniSpinner size={80} />
        ) : (
          <div>{getStatusComponent(status)}</div>
        )}
      </div>
    </AuthLayout>
  );
};

export default Signup;
