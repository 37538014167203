import { useState } from 'react';

export function useTouchedFields() {
  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const isFieldTouched = (name: string) => touchedFields.includes(name);
  const addToTouchedFields = (name: string) => {
    setTouchedFields(priorTouchedFields =>
      priorTouchedFields.includes(name)
        ? [...priorTouchedFields]
        : [...priorTouchedFields, name],
    );
  };
  return { isFieldTouched, addToTouchedFields };
}
