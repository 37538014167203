import React, { useState } from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
  useFloatingNodeId,
  Placement,
} from '@floating-ui/react';
import Input, { InputProps } from './Input';
import Dropdown from '../Dropdown';

export interface Option<T> {
  value: T;
  // TODO - iconLeft: (iconRight is already taken up by the selected check mark)
  label: string;
  disabled?: boolean;
}

export interface SelectProps<T> extends Omit<InputProps, 'onChange'> {
  /**
   * All options.
   */
  options: Option<T>[];
  /**
   * The currently selected option, if any.
   */
  selected?: T;
  /**
   * Called when the selected value changes.
   */
  onChange: (item: T) => void;
  /**
   * Position of the drop-down relative to the Select.
   * Default bottom-left.
   */
  dropdownPosition?: Placement;
  /**
   * Used when no option is selected.  Use like an input placeholder.
   */
  placeholder?: string;
  /**
   * Optionally pass through inline styles to the Input element.
   * This should be used very sparingly.
   */
  width?: React.CSSProperties['width'];
}

/**
 * Functionally a Select, but an input is used to maintain consistent styling.
 */
const Select = <T extends unknown>({
  options,
  selected,
  onChange,
  dropdownPosition = 'bottom-start',
  placeholder,
  width,
  ...inputProps
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  const nodeId = useFloatingNodeId();

  const { x, y, strategy, refs, context } = useFloating({
    nodeId,
    placement: dropdownPosition,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      // removed flip so the dropdown always show on the bottom, can considering adding it back if see fit in the future
      // flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight, availableWidth }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxwidth: `${availableWidth}px`,
            width: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const listContentRef = React.useRef(
    options.map(option => option.value) as string[],
  );

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context, { bubbles: false });
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    dismiss,
    role,
    listNav,
    typeahead,
  ]);

  const handleChange = (item: T) => {
    setIsOpen(false);
    onChange(item);
  };

  return (
    <>
      <Input
        ref={refs.setReference}
        {...inputProps}
        value={options.find(option => option.value === selected)?.label || ''}
        onClick={() => setIsOpen(prev => !prev)}
        placeholder={placeholder}
        select
        selectDropdownIsOpen={isOpen}
        style={{ width: width !== undefined ? width : undefined }}
        readOnly
        {...getReferenceProps}
      />
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              // because z-index is added to modal, we need to ensure z-index for select dropdown is over modal
              // this is not needed if we removed other z-index in the app
              className="bg-white p-2 rounded-xl shadow-3 box-border min-w-fit w-full overflow-y-auto z-1004"
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                outline: 0,
                minWidth: 100,
              }}
              {...getFloatingProps()}
            >
              {options.map((option, i) => (
                <Dropdown.Option
                  ref={node => {
                    listRef.current[i] = node;
                  }}
                  key={String(option.value)}
                  value={option.value}
                  selected={option.value === selected}
                  onClick={() => handleChange(option.value)}
                  disabled={option.disabled}
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      handleChange(option.value);
                    },
                    // TO DO: fix keyboard select
                    // Handle keyboard select.
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleChange(option.value);
                      }

                      // Only if not using typeahead.
                      if (event.key === ' ' && !context.dataRef.current.typing) {
                        event.preventDefault();
                        handleChange(option.value);
                      }
                    },
                  })}
                >
                  {option.label}
                </Dropdown.Option>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default Select;
