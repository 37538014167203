import {
  CancelCheckModal,
  OptionToSelectDiscountModal,
  OptionToChangeInstructorModal,
  ConfirmStayWithDiscountModal,
  ConfirmRequestToChangeInstructorModal,
  CancelConfirmationModal,
  CancelFeedbackModal,
  CourseSelectorModal,
  PaymentCheckModal,
  PaymentConfirmationModal,
  ScheduleSelectorModal,
  StudentCreationModal,
  SubscriptionUpdateModal,
  DowngradeSelectorModal,
  DowngradeConfirmationModal,
  ReduceClassFrequencyModal,
  ReduceCourseModal,
} from '..';

const getModal = modalName => {
  switch (modalName) {
    // landing modal to update subscription - add courses, subject, or downgrade
    case 'subscription_update': {
      return SubscriptionUpdateModal;
    }
    // select different downgrade options
    case 'downgrade_select': {
      return DowngradeSelectorModal;
    }
    // downgrade confirmation screen
    case 'downgrade_confirmation': {
      return DowngradeConfirmationModal;
    }
    case 'student_create': {
      return StudentCreationModal;
    }
    // course selection
    case 'course_select': {
      return CourseSelectorModal;
    }
    case 'schedule_select': {
      return ScheduleSelectorModal;
    }
    // payment modals
    case 'payment_check': {
      return PaymentCheckModal;
    }
    case 'payment_confirmation': {
      return PaymentConfirmationModal;
    }
    // cancellation modals
    case 'cancel_check': {
      return CancelCheckModal;
    }
    case 'cancel_feedback': {
      return CancelFeedbackModal;
    }
    // Jan 17, 2023 - this is temporary hidden requested by Annie, as this functionality doesn't seem to be working for the current users, and almost all of those who click this done it by mistake. CS team is currently already engaging with churning customers.
    case 'cancel_option_new_instructor': {
      return OptionToChangeInstructorModal;
    }
    case 'switch_instructor_confirmation': {
      return ConfirmRequestToChangeInstructorModal;
    }
    case 'cancel_optional_discount': {
      return OptionToSelectDiscountModal;
    }
    case 'cancel_discount_confirmation': {
      return ConfirmStayWithDiscountModal;
    }
    case 'cancel_confirmation': {
      return CancelConfirmationModal;
    }
    case 'downgrade_course': {
      return ReduceCourseModal;
    }
    case 'downgrade_frequency': {
      return ReduceClassFrequencyModal;
    }
    default: {
      return null;
    }
  }
};

const FormStateModal = props => {
  const { formState } = props;
  const Modal = getModal(formState.modal);
  return Modal ? <Modal className="text-j-dark-600" {...props} /> : null;
};

export default FormStateModal;
