import { makeSHA1 } from '../utils/crypto';

declare global {
  function ire(
    type: 'identify',
    payload: {
      customerId?: string;
      customerEmail: string;
    },
  ): void;
}

export const useAffiliates = ({
  customerEmail,
  customerId = '',
}: {
  customerEmail: string;
  customerId: string;
}) => {
  const emailHashPromise = customerEmail
    ? makeSHA1(customerEmail)
    : Promise.resolve('');

  const identifyAffiliateCustomer = async () => {
    const emailHash = await emailHashPromise;
    window.ire('identify', { customerEmail: emailHash, customerId });
  };

  return [identifyAffiliateCustomer];
};
