import React, { FC, useState } from 'react';
import juniAxios from 'services/axios';

import { NewButton, Icon } from 'core-components';
import AuthLayout from '../AuthLayout';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newUser, setNewUser] = useState(undefined);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!email) {
      setErrorMessage('Please enter an email address.');
      return;
    }

    setErrorMessage('');

    juniAxios
      .post(
        `/auth/request_password_reset`,
        {
          email,
        },
        config,
      )
      .then(res => {
        setShowConfirmation(true);
        setNewUser(res?.data?.newUser);
      })
      .catch(err => {
        console.log(err.response);
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <AuthLayout>
      {showConfirmation ? (
        <div className="flex flex-col gap-8">
          <div className="text-xl font-semibold text-j-dark-700">
            {newUser ? 'Set your Password' : 'Forgot Password?'}
          </div>
          <div className="text-sm">
            We've sent an email to {email} with a link to{' '}
            {newUser ? 'set up' : 'reset'} your password.
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <div>
            <div className="text-xl sm:text-2xl lg:text-3xl font-medium text-j-dark-700">
              Reset your password
            </div>
            <div className="text-sm w-full sm:w-96 pt-4">
              Enter the email address associated with your account and we will send
              you a link to reset your password.
            </div>
          </div>
          <form
            className="flex items-center flex-col w-full gap-y-6"
            onSubmit={e => handleSubmit(e)}
          >
            {errorMessage ? (
              <div className="form-error-wrap">
                <div className="form-error">{errorMessage}</div>
              </div>
            ) : null}
            <div className={`form-field${errorMessage ? ' error' : ''}`}>
              <div
                className={`flex items-center justify-center px-4 ${
                  errorMessage
                    ? 'bg-j-pink-600 text-white'
                    : 'bg-j-gray-300 text-j-gray-600'
                }`}
              >
                <Icon.Email className="w-4 h-4" />
              </div>
              <input
                type="text"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <NewButton
              type="submit"
              variant="primary"
              intent="info"
              renderIconRight={props => <Icon.ArrowRight {...props} />}
              fullWidth
            >
              Submit
            </NewButton>
          </form>
        </div>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
