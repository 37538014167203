import styled from 'styled-components/macro';

/* MyCalendar Styles */
export const MyCalendarLayout = styled.div`
  display: block;
  width: 100%;
`;

export const MyCalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

/* ScheduleCard */
export const ScheduleCardContainer = styled.div`
  background-color: #f8fdff;
`;

/* ScheduleCardRow */

export const ScheduleRow = styled.div<any>`
  background-color: #f8fdff;
  padding: 0.5rem 0.75rem;
  border: var(--juni-border, 2px solid #d1eaf7);
  border-bottom: ${props => !props.isLastRow && '0px'};
  box-sizing: border-box;
  border-radius: ${props =>
    (props.isFirstRow && props.isLastRow && '8px') ||
    (props.isFirstRow && '8px 8px 0px 0px') ||
    (props.isLastRow && '0px 0px 8px 8px') ||
    '0px'};
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (min-width: 500px) {
    flex-direction: row;
  }
`;

export const ScheduleCardRowTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ScheduleCardRowTextHeader = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-size: 0.7rem;
  line-height: 20px;
  color: #78a2b3;
`;

export const CalendarButtonStyle = `  background: #ffffff;
border: 2px solid #00a5ff;
box-sizing: border-box;
border-radius: 6px;

display: flex;
justify-content: space-around;
align-items: center;
text-align: center;
padding: 4px 7px 4px 14px;
width: 100%;

> .icon {
  margin-left: 12px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  padding: 12px;
}
`;
export const ScheduleButtonContainer = styled.div`
  margin-top: 1em;
  @media only screen and (min-width: 500px) {
    margin-top: 0;
    margin-left: auto;
  }
`;

export const ScheduleButton = styled.button<any>`
  ${CalendarButtonStyle}
  &:disabled {
    border: 1px solid #d8d8d8;
  }

  &:disabled > * {
    color: unset;
  }

  &:disabled > .icon {
    background-color: #e4e2e2;
    border: 1px solid #efefef;
  }

  &:disabled > .icon * {
    fill: #cccccc;
  }
`;

export const ScheduleButtonLink = styled.a`
  ${CalendarButtonStyle}
  text-decoration: none;
  letter-spacing: 1px;
`;

export const ScheduleCardButtonText = styled.div`
  color: #00a5ff;
  font-family: Open Sans;
  font-weight: bold;
  font-size: var(--juni-body-size, 0.8rem);
  line-height: 26px;
  text-transform: capitalize;
  width: 100%;
  margin-left: 0.25rem;

  @media only screen and (min-width: 500px) {
    width: 100px;
  }
`;

export const ViewAllButton = styled.button`
  ${CalendarButtonStyle}
  background-color: #f8fdff;
  border: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  padding: 10px;
  border: var(--juni-border, 2px solid #d1eaf7);
  border-radius: 0 0 8px 8px;

  :hover {
    box-shadow: none;
  }

  @media only screen and (min-width: 500px) {
    min-width: unset;
    max-width: unset;
  }
`;
export const ViewAllButtonText = styled(ScheduleCardButtonText)`
  text-transform: uppercase;
  padding: 0;
  width: initial;
`;
