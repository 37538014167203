import React, { FC } from 'react';
import classNames from 'classnames';
import {
  useFloating,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  useId,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import { TailwindHeight, TailwindSpace } from 'theme/types';
import { CloseButton, CloseButtonIcon } from 'core-components/NewModalWindow/styles';

export interface ModalWindowProps {
  /**
   * Function that closes the modal. Not set to be manipulable in Storybook.
   */
  closeModal: () => void;
  /**
   * Variable that determines if the modal is open or not. Not set to be manipulable in Storybook.
   */
  isOpen: boolean;
  /**
   * Title for the modal header
   */
  title?: string;
  /**
   * Description for the modal header
   */
  description?: string;
  /**
   * CSS `max-width` property for modal.
   */
  maxWidth?: string;
  /**
   * Content rendered inside the footer section. Ideally should be ModalTextFooter or ModalButtonsFooter
   */
  renderFooter?: () => React.ReactNode;
  /**
   * Content inside modal
   */
  children?: React.ReactNode;
  /**
   * Padding for the modal content. Styled with Tailwind scale
   */
  contentPadding?: TailwindSpace | TailwindSpace[];
  /**
   * Styles for the modal content.
   * vh options needs to added in tailwind.config.js file
   */
  contentMaxHeight?: '50vh' | '60vh' | '80vh' | '85vh' | TailwindHeight;
  /**
   * Determines whether close icon is visible.
   * Defaults to true and only applies when Modal has a header section.
   */
  showCloseIcon?: boolean;
  /**
   * Content in a secondary header directly below the main header
   */
  secondaryHeader?: () => React.ReactNode;
}

/**
 * Divider for ModalWindow sections
 */
const Divider: FC = () => <hr className={`border-0 bg-j-purple-100 h-px my-0 `} />;

interface ModalTextFooterProps {
  title?: string;
  description?: string;
}

export const ModalTextFooter: FC<ModalTextFooterProps> = ({
  title,
  description,
}) => (
  <div className="flex flex-col flex-wrap sm:flex-row font-graphik text-base w-full">
    <div className="text-j-dark-600 font-medium mr-3">{title}</div>
    <div className="text-j-dark-300">{description}</div>
  </div>
);

interface ModalButtonsFooterProps {
  /**
   * The button used to help users move through tasks in a user flow.
   * Some examples of primary actions are: Next, Complete, and Start.
   */
  primary: React.ReactNode;
  /**
   * Alternative to the primary actions often used to help users go back to a previous state.
   * Some examples are: Back, Edit, and Cancel.
   * Always accompanied by a primary action button.
   */
  secondary?: React.ReactNode;
  /**
   * When there are two buttons, uses justify-between to place buttons on left/right sides.
   * Defaults to true. Justify-centers both buttons when false.
   */
  justifyBetweenButtons?: boolean;
}

export const ModalButtonsFooter: FC<ModalButtonsFooterProps> = ({
  primary,
  secondary,
  justifyBetweenButtons = true,
}) => {
  const doubleButton = !!primary && !!secondary;
  return (
    <div
      className={classNames(
        'flex sm:flex-row',
        'w-full',
        `${
          doubleButton && justifyBetweenButtons
            ? 'justify-between'
            : 'justify-center'
        }`,
      )}
    >
      {secondary}
      {primary}
    </div>
  );
};

interface ModalHeaderProps {
  title?: string;
  description?: string;
  showCloseIcon?: boolean;
  secondaryHeader?: () => React.ReactNode;
  closeModal: () => void;
}

const Header: FC<ModalHeaderProps> = ({
  title,
  description,
  showCloseIcon,
  secondaryHeader,
  closeModal,
}) => {
  const hasHeader = !!title || !!description;

  if (!hasHeader) {
    return null;
  }

  return (
    <>
      <div className="flex px-5 py-4 max-h-28">
        <div className="flex flex-col flex-wrap sm:flex-row sm:items-center w-full">
          <div className="text-j-dark-600 font-medium font-graphik text-xl sm:mr-4">
            {title}
          </div>
          <div className="text-base text-j-dark-300 font-graphik">{description}</div>
        </div>
        {showCloseIcon && (
          <div className="ml-auto">
            <CloseButton aria-label="Close" onClick={closeModal}>
              <CloseButtonIcon />
            </CloseButton>
          </div>
        )}
      </div>
      <Divider />
      {secondaryHeader && (
        <>
          {secondaryHeader()}
          <Divider />
        </>
      )}
    </>
  );
};

const ModalWindow: FC<ModalWindowProps> = ({
  children,
  closeModal,
  isOpen,
  title,
  description,
  maxWidth,
  renderFooter,
  contentPadding = '5',
  contentMaxHeight,
  showCloseIcon = true,
  secondaryHeader,
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: closeModal,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  const headingId = useId();
  const descriptionId = useId();

  if (!isOpen) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay
        // Z-index is added to ensure modal is on top of all z-index items such as nav header and other things
        className="grid place-items-center bg-j-dark-600 bg-opacity-20 p-8 isolate z-1003"
        lockScroll
      >
        <FloatingFocusManager context={context}>
          <div
            className={classNames(
              'm-4 bg-white p-0 rounded-xl border border-solid border-j-dark-100 inset-auto static',
              !!title || !!description || renderFooter ? 'w-full' : 'w-auto',
            )}
            ref={refs.setFloating}
            aria-labelledby={headingId}
            aria-describedby={descriptionId}
            // maxWidth to deprcate to tailwind
            style={{
              maxWidth: maxWidth || '42rem',
            }}
            {...getFloatingProps()}
          >
            <Header
              title={title}
              description={description}
              showCloseIcon={showCloseIcon}
              secondaryHeader={secondaryHeader}
              closeModal={closeModal}
            />
            <div
              className={classNames(
                'flex font-graphik overflow-auto ',
                contentMaxHeight ? `max-h-${contentMaxHeight}` : 'max-h-50vh',
                `p-${contentPadding}`,
              )}
            >
              {children}
            </div>
            {renderFooter && (
              <>
                <Divider />
                <div className="flex justify-between p-4 max-h-20">
                  {renderFooter()}
                </div>
              </>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default ModalWindow;
