import { FC, ReactNode } from 'react';

import schedule from 'images/referrals/value_prop_icons/schedule.svg';
import sliders from 'images/referrals/value_prop_icons/sliders.svg';
import user from 'images/referrals/value_prop_icons/user.svg';

const STEPS = [
  { icon: schedule, label: 'Select a subject & course.' },
  {
    icon: sliders,
    label: 'Let us know your schedule and instructor learning preferences.',
  },
  { icon: user, label: 'Start learning with Juni!' },
];

const SignupStepsSection: FC<{
  content: { callToAction: ReactNode; iconColor: string; floatingSection?: string };
}> = ({ content }) => (
  <div className="bg-white py-20 px-10 md:px-0">
    <div className="container mx-auto">
      {content.floatingSection && (
        <div
          className={`
          hidden
          lg:flex
          items-center
          text-center
          text-2xl
          font-semibold
          relative
          w-48
          h-48

          -my-28
          ml-auto
          p-4

          transform
          -rotate-12

          rounded-full
          bg-juni-green-100 
          text-juni-green-500
        `}
        >
          {content.floatingSection}
        </div>
      )}
      <h2 className="text-center text-juni-dark-900 text-4xl font-medium mb-14">
        Start your journey with Juni in 3 easy steps
      </h2>
      <div className="grid lg:grid-cols-3 gap-6">
        {STEPS.map((step, index) => (
          <div key={step.icon}>
            <div className="grid grid-cols-8 gap-x-4">
              <div className="col-span-2 flex justify-center">
                <span className={`text-9xl font-bold text-${content.iconColor}`}>
                  {index + 1}
                </span>
              </div>
              <div className="col-span-6 flex flex-col pt-4 space-y-4">
                <div className="flex w-12">
                  <img className="w-full" src={step.icon} alt={step.icon} />
                </div>
                <span className="flex text-2xl font-medium text-juni-dark-900">
                  {step.label}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-16 flex items-center justify-center">
        {content.callToAction}
      </div>
    </div>
  </div>
);

export default SignupStepsSection;
