import React from 'react';
import { Select, SelectOption } from 'core-components';
import { FOREIGN_TIMEZONES, Timezone, US_TIMEZONES } from 'constants/timezones';
import { LocalState } from '../Scheduling';
import { labelTextStyles } from '../../constants';

const TIMEZONE_OPTIONS: SelectOption<Timezone>[] = [
  ...US_TIMEZONES,
  ...FOREIGN_TIMEZONES,
].map(({ value, displayName }) => ({ value, label: displayName }));

interface Props {
  localState: LocalState;
  onLocalChange: (input: Partial<LocalState>) => void;
}

const TimezoneSelect = ({ localState: { timezone }, onLocalChange }: Props) => (
  <div className="flex flex-col gap-2">
    <label htmlFor="input-timezone">
      <div className={labelTextStyles}>Time Zone</div>
    </label>
    <Select
      fullWidth
      id="input-timezone"
      size="small"
      selected={timezone}
      onChange={value => onLocalChange({ timezone: value })}
      options={TIMEZONE_OPTIONS}
      placeholder="Select a timezone"
      data-cy="timezone"
    />
  </div>
);

export default TimezoneSelect;
