import React, { FC } from 'react';

import JuniSpinner from 'components/JuniSpinner';
import { NewCard } from 'core-components';
import _ from 'lodash';
import {
  useGetSubjectsQuery,
  useGetInstructorQualifiedCoursesQuery,
  useGetCoursesForInstructorQualificationsQuery,
} from 'generated/graphql';
import { PortalComponentLayout } from 'components/PortalLayout';
import { ErrorableLoading } from 'components/ui';
import InstructorQualifiedSubjectCard from './components/instructorQualifiedSubjectCard';

const MyQualifications: FC = () => {
  const {
    data: qualifiedCoursesData,
    loading: qualifiedCoursesLoading,
    error: qualifiedCoursesError,
  } = useGetInstructorQualifiedCoursesQuery();
  const qualifiedCourses = qualifiedCoursesData?.getInstructorQualifiedCourses.filter(
    course => course.isQualified === 2,
  );

  const { data: subjectsData, loading: subjectsLoading } = useGetSubjectsQuery();
  const subjects = subjectsData?.getSubjects;

  const {
    data: privateCourseData,
    loading: privateCourseDataLoading,
    error: privateCourseDataError,
  } = useGetCoursesForInstructorQualificationsQuery({
    variables: {
      input: {
        isAcceptingEnrollment: true,
        schedulingFormat: 'private',
      },
    },
  });

  const privateCoursesBySubject = _.groupBy(
    privateCourseData?.getCourses,
    'subjectId',
  );

  const loading =
    qualifiedCoursesLoading || privateCourseDataLoading || subjectsLoading;
  const error = privateCourseDataError || qualifiedCoursesError;

  if (error) {
    return <ErrorableLoading error={error} />;
  }

  return loading ? (
    <JuniSpinner size={null} />
  ) : (
    <PortalComponentLayout title="My Qualifications">
      <div className="flex flex-col gap-6">
        <NewCard shadow="1">
          <div className="mb-4 text-xl font-semibold text-j-dark-600">
            Qualified Subjects
          </div>
          <div className="flex flex-col gap-6">
            <NewCard.Divider />
            {subjects?.map(subject => (
              <div className="flex flex-row" key={subject._id}>
                <div className="w-3/5 font-medium text-j-dark-600">
                  {subject.displayName}
                </div>
                <div className="text-j-dark-500">
                  {privateCoursesBySubject[subject._id].filter(
                    course =>
                      course.curriculumId &&
                      _.some(qualifiedCourses, ['courseId', course?.curriculumId]),
                  ).length > 0
                    ? 'Qualified'
                    : 'Not Qualified'}
                </div>
              </div>
            ))}
          </div>
        </NewCard>

        {Object.entries(privateCoursesBySubject).map(([subjectId, courses]) => (
          <InstructorQualifiedSubjectCard
            key={subjectId}
            subjectId={subjectId}
            courses={courses}
            qualifiedCourses={qualifiedCourses}
          />
        ))}
      </div>
    </PortalComponentLayout>
  );
};

export default MyQualifications;
