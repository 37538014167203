import { Dispatch, FC, SetStateAction, useState } from 'react';
import _ from 'lodash';
import {
  setHours,
  setMinutes,
  nextDay,
  addWeeks,
  getDay,
  parseISO,
  addDays,
  Day,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import styled from 'styled-components';

import { PRIORITY_LEVELS } from 'utils/enrollment';
import { DEFAULT_TIMEZONE, Timezone } from 'constants/timezones';
import { Icon, NewButton as Button } from 'core-components';
import { SelectField } from 'components/ui';
import { AvailabilityPreferences } from '../types';

const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const START_HOUR = 6;
const END_HOUR = 22;
const NUM_FUTURE_START_DATES = 6;
const MIN_DAYS_NEEDED_FOR_PROCESSING = 5;

const createStartDatesArray = (day: Day, time: string, timezone: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const now = new Date();
  const nowPlusProcessingTime = addDays(now, MIN_DAYS_NEEDED_FOR_PROCESSING);
  const firstDate =
    getDay(nowPlusProcessingTime) === Number(day)
      ? nowPlusProcessingTime
      : nextDay(nowPlusProcessingTime, day);
  firstDate.setHours(hours, minutes, 0, 0);

  return _.range(0, NUM_FUTURE_START_DATES).map(i =>
    addWeeks(zonedTimeToUtc(firstDate, timezone), i),
  );
};

const StyledSelectField = styled(SelectField)`
  select {
    margin: 5px 0px 5px 0px;
    padding: 7px 10px;
    font-size: 13px;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans';
    color: #292563;
    min-height: 42px;
    border-radius: 6px;
    border: 1px solid #5f5c8a;
    box-shadow: inset 0px 5px 20px -10px #e2e1ed;
  }
`;

const dayOptions = _.range(0, 7).map(i => ({
  value: i as Day,
  label: `${DAY_NAMES[i]}s`,
}));

interface SchedulingPreferenceSelectorProps {
  priority: number;
  timezone: string;
  customAvailabilities: AvailabilityPreferences;
  setCustomAvailabilities: Dispatch<SetStateAction<AvailabilityPreferences>>;
}

const SchedulingPreferenceSelector: FC<SchedulingPreferenceSelectorProps> = ({
  priority,
  timezone,
  customAvailabilities,
  setCustomAvailabilities,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const timeOptions = _.range(START_HOUR, END_HOUR, 0.5).map(hour => {
    const ptHour = zonedTimeToUtc(
      setMinutes(setHours(new Date(), _.floor(hour)), hour % 1 === 0 ? 0 : 30),
      DEFAULT_TIMEZONE,
    );
    const clientHour = utcToZonedTime(ptHour, timezone);
    return {
      value: format(clientHour, 'HH:mm', { timeZone: timezone }),
      label: format(clientHour, 'h:mm a', { timeZone: timezone }),
    };
  });

  const preference = customAvailabilities[priority] || {};
  const startDatesArray =
    preference.day && preference.time
      ? createStartDatesArray(preference.day, preference.time, timezone)
      : [];

  const error =
    preference.day &&
    preference.time &&
    Object.values(customAvailabilities).filter(
      a => a.day === preference.day && a.time === preference.time,
    ).length > 1
      ? 'This day and time has already been selected'
      : '';

  return (
    <div className="flex flex-col gap-0.5 text-sm">
      <label className="text-j-dark-400 font-medium">
        {PRIORITY_LEVELS[priority]} Choice{priority !== 1 && ' (optional)'}
      </label>

      <div className="flex gap-4">
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <StyledSelectField
          className="full-width"
          error={error}
          value={preference.day}
          onChange={(value: Day) => {
            if (!value) {
              const newCustomAvailabilities = { ...customAvailabilities };
              delete newCustomAvailabilities[priority];
              return setCustomAvailabilities(newCustomAvailabilities);
            }
            setCustomAvailabilities({
              ...customAvailabilities,
              [priority]: {
                ...customAvailabilities[priority],
                day: value,
                startDate: undefined,
              },
            });
          }}
          placeholder="Select day of week"
          options={dayOptions}
        />
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <StyledSelectField
          className="full-width"
          value={preference.time}
          disabled={!preference.day}
          onChange={(value: string) =>
            setCustomAvailabilities({
              ...customAvailabilities,
              [priority]: {
                ...customAvailabilities[priority],
                time: value,
                startDate: undefined,
              },
            })
          }
          placeholder="Select time"
          options={timeOptions}
        />
      </div>

      {preference.day && preference.time && !error && (
        <div className="flex items-center gap-1">
          <div
            className={`text-xs ${
              preference.startDate ? 'text-j-dark-600' : 'text-j-pink-700'
            }  w-68`}
          >
            Please select the date you prefer to start your first class:
          </div>
          {isEditing ? (
            <div>
              {/* eslint-disable-next-line */}
                {/* @ts-ignore */}
              <StyledSelectField
                placeholder="Select a date"
                value={preference.startDate?.toISOString()}
                onChange={(value: string) => {
                  setCustomAvailabilities({
                    ...customAvailabilities,
                    [priority]: {
                      ...customAvailabilities[priority],
                      startDate: value ? parseISO(value) : undefined,
                    },
                  });
                  setIsEditing(false);
                }}
                options={startDatesArray.map(availability => ({
                  value: availability.toISOString(),
                  label: format(
                    utcToZonedTime(availability, timezone),
                    'EEEE, MMMM do, yyyy',
                    {
                      timeZone: timezone,
                    },
                  ),
                }))}
              />
            </div>
          ) : (
            <Button
              variant="minimal"
              size="small"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              {preference.startDate && (
                <span className="mr-2">
                  {format(
                    utcToZonedTime(preference.startDate, timezone),
                    'EEEE, MMMM do, yyyy',
                    {
                      timeZone: timezone,
                    },
                  )}
                </span>
              )}
              <Icon.PencilTool className="text-j-dark-400" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

interface CustomTimePickerStepProps {
  timezone: string;
  customAvailabilities: AvailabilityPreferences;
  setCustomAvailabilities: Dispatch<SetStateAction<AvailabilityPreferences>>;
}

const CustomTimePickerStep: FC<CustomTimePickerStepProps> = ({
  timezone,
  customAvailabilities,
  setCustomAvailabilities,
}) => (
  <div className="flex flex-col gap-2 w-full">
    <div className="text-sm text-j-dark-400">
      <div>
        Please provide times that would work best for you. We will work with your
        current instructor first to find a match, and if none can be made we will
        match your student with another highly skilled instructor who has
        availability during your preferred time(s).
      </div>
      <div className="mt-2">
        Make sure that these times generally work on a weekly basis for your student.
        Our team will do our best to meet these preferences, and we may reach out to
        discuss as needed.
      </div>
    </div>
    <div className="text-sm font-medium text-j-dark-600 pt-2">
      Your Selections (Timezone: {timezone}{' '}
      {format(utcToZonedTime(new Date(), timezone), 'zz', {
        timeZone: timezone,
      })}
      ):
    </div>
    <div className="flex flex-col gap-2">
      {Object.keys(PRIORITY_LEVELS).map(priority => (
        <SchedulingPreferenceSelector
          key={priority}
          priority={Number(priority)}
          timezone={timezone as Timezone}
          customAvailabilities={customAvailabilities}
          setCustomAvailabilities={setCustomAvailabilities}
        />
      ))}
    </div>
  </div>
);

export default CustomTimePickerStep;
