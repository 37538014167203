import {
  SignupEnrollment,
  SignupEnrollmentInput,
  SignupFragment as Signup,
  SignupProduct,
  SignupSchedulingPreferenceInput,
  StarterCourseFragment,
  useGetStarterCurriculumQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createEnumParam,
  DecodedValueMap,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { getProductFromCourseName } from '../constants/products';
import { isPaymentStepComplete } from '../steps/utils';
import useSignupData from './useSignupData';

/**
 * Remove params that are ingested as part of `syncParamsToSignup`.
 */
const getFilteredSearch = (search: string) => {
  const params = new URLSearchParams(search);

  Object.keys(queryConfig).forEach(key => {
    if (params.has(key)) {
      params.delete(key);
    }
  });

  return `?${params.toString()}`;
};

const queryConfig = {
  discountCode: StringParam,
  productName: createEnumParam(Object.values(SignupProduct)),
  courseName: StringParam,
};
type SignupQueryParams = DecodedValueMap<typeof queryConfig>;

/**
 * 2023-09-27: Empowerly signup is requested to have a reduced set of class times.
 * If user switches to the empowerly signup, we clear their time selections.
 */
const getUpdatedSchedulingPreferences = (
  productName: SignupProduct,
  activeEnrollment?: Partial<SignupEnrollment> | null,
): SignupSchedulingPreferenceInput[] | undefined => {
  const schedulingPreference = activeEnrollment?.schedulingPreferences?.[0];
  const isUpdate =
    productName !== activeEnrollment?.productName &&
    productName === SignupProduct.PrivateBulkCs &&
    !!schedulingPreference;
  return isUpdate ? [{ ...schedulingPreference, dates: null }] : undefined;
};

/**
 * Update enrollment based on query params. No changes if a param matches the signup.
 * If courseName is present, determine product and course info as well.
 */
const getUpdatedEnrollment = (
  signup: Signup,
  query: SignupQueryParams,
  courses: StarterCourseFragment[],
): Partial<SignupEnrollmentInput> | undefined => {
  // anything not derived from query params will be unset
  const defaultEnrollmentContent = {
    productName: null,
    courseName: null,
    courseId: null,
    subjectId: null,
    tutoringCourse: null,
    tutoringDetails: null,
  };
  const activeEnrollment = signup?.students?.[0]?.enrollments?.[0];
  const { courseName, productName } = query;
  if (courseName) {
    if (courseName === activeEnrollment?.courseName) {
      return undefined;
    }
    const product = getProductFromCourseName(courseName);
    const course = courses.find(c => c.name === courseName);
    if (product && course) {
      return {
        ...defaultEnrollmentContent,
        courseName,
        productName: product,
        courseId: course._id,
        subjectId: course.subject._id,
        schedulingPreferences: getUpdatedSchedulingPreferences(
          product,
          activeEnrollment,
        ),
      };
    }
    return undefined;
  }
  if (productName) {
    if (productName === activeEnrollment?.productName) {
      return undefined;
    }
    return {
      ...defaultEnrollmentContent,
      productName,
      schedulingPreferences: getUpdatedSchedulingPreferences(
        productName,
        activeEnrollment,
      ),
    };
  }
  return undefined;
};

const useParseQueryParams = (): { query: SignupQueryParams } => {
  const [query] = useQueryParams(queryConfig);

  const { discountCode, productName, courseName } = query;
  return { query: { discountCode, productName, courseName } };
};

/**
 * Parses signup-specific url query params. If any params are found, they are
 * applied to the signup doc and removed from the url.
 *
 * Once the url is free of any signup params, loading becomes false.
 */
const useSignupQueryParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { query } = useParseQueryParams();
  const { signupData, updateSignup } = useSignupData();
  const [loading, setLoading] = useState(true);
  const { data, loading: loadingCourses } = useGetStarterCurriculumQuery({
    variables: { input: { names: [query.courseName!] } },
    skip: !query.courseName,
  });

  useEffect(() => {
    const syncParamsToSignup = (
      signupData: Signup,
      courses: StarterCourseFragment[],
    ) => {
      const { discountCode } = query;
      const enrollment = getUpdatedEnrollment(signupData, query, courses);

      const update = {
        ...(discountCode && { payment: { couponId: discountCode } }),
        ...(enrollment && { students: [{ enrollments: [enrollment] }] }),
      };
      updateSignup(update, false);
    };

    const courses = data?.getCourses || [];
    const areParamsPresent = Object.values(query).filter(v => v).length !== 0;
    if (signupData && areParamsPresent && !loadingCourses) {
      if (!isPaymentStepComplete(signupData)) {
        syncParamsToSignup(signupData, courses);
      }
      const filteredSearch = getFilteredSearch(search);
      history.replace(`${pathname}${filteredSearch}`);
    }
    if (signupData && !areParamsPresent) {
      setLoading(false);
    }
  }, [
    query,
    signupData,
    updateSignup,
    history,
    pathname,
    search,
    data?.getCourses,
    loadingCourses,
  ]);

  return { query, loading };
};

export default useSignupQueryParams;
