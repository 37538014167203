import React, { useEffect, useRef, useState, ReactElement } from 'react';
import classNames from 'classnames';
import { Chevron } from 'components/Icons';
import { jDark } from 'theme/colors';
import { TailwindSpace } from 'theme/types';
import { getPaddingStyle } from 'utils/tailwind/spacing';
import { Badge } from 'core-components';
import Card from '../NewCard';

const ANIMATION_MS = 500;

export interface ExpandableCardProps extends React.ComponentProps<typeof Card> {
  /**
   * Content of the header, not including the right Chevron icon.
   */
  renderHeaderContent: () => React.ReactNode;
  /**
   * Optionally set the default (initial) expanded state.
   * Irrelevant if providing a value for the expanded prop.
   * Default false.
   */
  defaultExpanded?: boolean;
  /**
   * Provides a way to consume this component as a controlled component.
   */
  expanded?: boolean;
  /**
   * Optional callback fired when the expand/collapse state is changed.
   */
  onChange?: () => void;
  /**
   * Optional padding for the header that overrides the 'padding' prop.
   */
  headerPadding?: TailwindSpace | TailwindSpace[];
  /**
   * Optional label for a heading.
   */
  headerLabel?: string;
  /**
   * Displays a footer in both collapsed and expanded state
   */
  footerElement?: ReactElement;
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({
  renderHeaderContent,
  defaultExpanded = false,
  expanded: expandedProp,
  onChange,
  padding = '6',
  headerPadding,
  headerLabel,
  children,
  footerElement,
  ...cardProps
}) => {
  const paddingStyle = getPaddingStyle(padding);
  const headerPaddingStyle = getPaddingStyle(headerPadding ?? padding);
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const initialExpandedState = expandedProp ?? defaultExpanded;
  const [expandedLocal, setExpandedLocal] = useState(initialExpandedState);
  const expanded = expandedProp ?? expandedLocal;
  // Content height transition "flows":
  // Note - can only CSS transition (animate) on height with pixels, 'auto' does not work.
  // Expand flow: Starts at 0 => measured px => 'auto' ('auto' allows height to change to fit content while expanded)
  // Collapse flow: Starts at 'auto' => measured px => 0
  const [contentHeight, setContentHeight] = useState<number | 'auto'>(
    initialExpandedState ? 'auto' : 0,
  );

  // for the collapse flow after content height is set, need to allow the browser to paint,
  // and after that set it to 0 to transition the collapse.
  useEffect(() => {
    if (!expanded && Number.isFinite(contentHeight) && Number(contentHeight) > 0) {
      setContentHeight(0);
    }
  }, [expanded, contentHeight]);

  const handleHeaderClick = () => {
    // the first step on expand or collapse is to set the height to the measured pixels
    const measuredHeight = contentContainerRef?.current?.getBoundingClientRect()
      .height;
    // in practice measuredHeight should never be undefined, making TS happy
    setContentHeight(measuredHeight || 0);

    const controlledMode = typeof expandedProp === 'boolean';
    if (!controlledMode) {
      setExpandedLocal(prev => !prev);
    } else if (onChange) {
      onChange();
    }
  };

  return (
    <Card {...cardProps} padding="0">
      <div
        className={classNames(
          'flex',
          'items-center',
          'justify-between',
          'cursor-pointer',
          headerPaddingStyle,
        )}
        onClick={handleHeaderClick}
        role="button"
        tabIndex={0}
      >
        <div>{renderHeaderContent()}</div>
        <div className="flex flex-row">
          {headerLabel && (
            <Badge backgroundColor="j-green-100" color="j-green-600">
              {headerLabel}
            </Badge>
          )}
          <Chevron
            stroke={jDark[600]}
            style={{
              transform: expanded ? `rotate(180deg)` : undefined,
              transition: `transform ${ANIMATION_MS}ms ease-in`,
            }}
          />
        </div>
      </div>
      <div
        className="min-h-0 overflow-hidden"
        onTransitionEnd={() => setContentHeight(expanded ? 'auto' : 0)}
        style={{
          height: contentHeight,
          transition: `height ${ANIMATION_MS}ms ease-in`,
        }}
      >
        <div ref={contentContainerRef}>
          <Card.Divider />
          <div className={paddingStyle}>{children}</div>
        </div>
      </div>
      {footerElement && (
        <div>
          <Card.Divider />
          {footerElement}
        </div>
      )}
    </Card>
  );
};

export default ExpandableCard;
