/**
 * Cleans a course display name for displaying it to a learner (student or parent) audience by
 * removing qualifer substrings that we don't want to be surfaced to learners. For example,
 * we remove substrings such as " (deprecated)", " (NVA)", " (NVA 21)", " (NVA 24)", and
 * " (Retake)".
 * @param {string} courseDisplayName
 * @returns {string}
 */
export const cleanCourseDisplayNameForLearnerAudience = (
  courseDisplayName: string,
) =>
  courseDisplayName.replace(
    /( \(deprecated\))|( \(NVA\s*\d*\))|( \(Retake\))/gi,
    '',
  );
