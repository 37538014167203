import React, { FC } from 'react';
import _ from 'lodash';
import { format, parseISO, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { NewCard } from 'core-components';
import { PRIORITY_LEVELS } from 'utils/enrollment';
import { GetCoursesReturnFragment } from 'generated/graphql';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { FormValues } from '../../types';

interface Props {
  formValues: FormValues;
  metadataOld: any;
  courses: Array<GetCoursesReturnFragment> | undefined;
}

const SchedulingPreferencesSection: FC<Props> = ({
  formValues: { metadataNew, startingDate, timezone },
  metadataOld,
  courses,
}) => (
  <div className="flex gap-2 flex-col">
    <div className="text-base font-medium">Scheduling Preferences</div>
    {Object.entries(metadataNew)
      .filter(
        ([key, newState]) =>
          !metadataOld[key] ||
          newState.frequency !== metadataOld[key].frequency ||
          _.difference(newState.courses, metadataOld[key].courses).length > 0,
      )
      .map(([key, newState]) => (
        <div className="flex flex-col" key={key}>
          <div className="flex w-full flex-col">
            <div className="flex">
              {courses &&
                courses
                  .filter(course => newState.courses.includes(course.name))
                  .map(course => course.displayName)
                  .join(', ')}
            </div>
            <NewCard
              className="border border-j-dark-100 border-solid"
              padding="0"
              shadow="none"
            >
              {Object.values(PRIORITY_LEVELS).map((label, i) => (
                <div key={label} className="flex flex-col">
                  <div className="m-4">
                    <div className="text-j-dark-300">{`${label} Choice`}</div>
                    <div>
                      {format(
                        utcToZonedTime(
                          parseISO(newState.availabilities[i]),
                          timezone || guessTimezoneValue(),
                        ),
                        'EEEE, h:mm a',
                      )}
                    </div>
                  </div>
                  {i < 2 && <NewCard.Divider />}
                </div>
              ))}
            </NewCard>
          </div>
        </div>
      ))}
    <div>Earliest Starting Date</div>
    <NewCard
      className="border border-j-dark-100 border-solid"
      padding="0"
      shadow="none"
    >
      <div className="p-4">
        <div className="text-j-dark-300">Starting Date</div>
        <div>
          {startingDate &&
            format(parse(startingDate, 'yyyy-MM-dd', new Date()), 'MMMM do, yyyy')}
        </div>
      </div>
    </NewCard>
  </div>
);

export default SchedulingPreferencesSection;
