import { ChangeEvent, FC, useState } from 'react';
import { format, addDays, setDay } from 'date-fns';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import useMountEffect from 'hooks/useMountEffect';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import navStates from 'app/signup_session/navigation/states';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { utcToZonedTime } from 'date-fns-tz';
import { Card, Input } from 'core-components';
import {
  BundleSelection,
  SignupSessionProps,
  BundleData,
} from 'app/signup_session/types';
import {
  countEmptySchedulingPreferences,
  countIncompleteSchedulingPreferences,
  initializeEmptySchedulingPreferences,
  findStudentById,
  updateStudentById,
} from 'app/signup_session/lib';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import SignupFlowFooter from 'app/signup_session/components/SignupFlowFooter';
import { ONBOARDING_START_DATE_BUFFER_DAYS } from 'constants/onboarding_handoff_buffer';
import TimezoneSelect from '../../Onboarding/pages/SchedulingPreferences/components/TimezoneSelect';
import DraggableScheduleSections from '../../Onboarding/pages/SchedulingPreferences/components/DraggableScheduleSections';

function adjustStartingDates(
  subjectBundle: BundleSelection,
  firstAvailableDate: Date,
) {
  return (subjectBundle.schedulingPreferences ?? [])
    .filter(({ datetime }) => !!datetime)
    .map(({ datetime }) => {
      const start = new Date(firstAvailableDate); // this date is our starting point
      const original = new Date(datetime!); // from this we retain just the weekday (eg. Tuesday) and hours:minutes
      const newDate = setDay(start, original.getDay()); // note: setDay does not mutate the original date
      newDate.setHours(original.getHours(), original.getMinutes(), 0, 0);
      return {
        datetime:
          newDate.getTime() < start.getTime()
            ? addDays(newDate, 7).toJSON()
            : newDate.toJSON(),
      };
    });
}

function dateToShortIsoString(date: Date | string | undefined) {
  if (!date) return;
  return new Date(date).toISOString().split('T')[0];
}

const SchedulingPreferences: FC<SignupSessionProps> = ({ history, location }) => {
  const {
    signupData,
    activeStudentId,
    setSignupSession,
    flags,
  } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData);
  const { getNextPage } = useNavRouter();

  const firstAvailableDate = new Date(new Date().setHours(0, 0, 0, 0));
  firstAvailableDate.setDate(
    firstAvailableDate.getDate() + ONBOARDING_START_DATE_BUFFER_DAYS,
  );
  const firstAvailableDateString = dateToShortIsoString(firstAvailableDate) ?? '';

  const [startingDate, setStartingDate] = useState(
    dateToShortIsoString(student?.bundle?.startingDate) ?? firstAvailableDateString,
  );
  const [dateError, setDateError] = useState('');

  const isScheduleValid = () =>
    countIncompleteSchedulingPreferences(student?.bundle ?? {}) === 0 && !dateError;

  const subjects = getStudentBundleSelections(student?.bundle)
    .filter((selection): selection is BundleSelection => selection !== undefined)
    .sort((a, b) => a.subject.localeCompare(b.subject));

  const onSubmit = async () => {
    if (!student?.bundle) return;
    const newSelections = subjects.reduce(
      (selections: BundleData['selections'], subjectBundle: BundleSelection) => {
        selections[subjectBundle.subject] = {
          ...subjectBundle,
          schedulingPreferences: adjustStartingDates(
            subjectBundle,
            utcToZonedTime(startingDate, student.timezone ?? guessTimezoneValue()),
          ),
        };
        return selections;
      },
      {},
    );

    setSignupSession(
      updateStudentById(
        activeStudentId,
        {
          bundle: {
            ...student.bundle,
            isScheduleValid: isScheduleValid(),
            selections: newSelections,
            startingDate: new Date(startingDate),
          },
          timezone: student.timezone ?? guessTimezoneValue(),
        },
        signupData,
      ),
    );
    history.push(
      getNextPage(navStates.signup.schedulingPreferences, ROUTE_EVENT.SUBMIT, {
        signupData,
        search: location.search,
        shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
      }),
    );
    window.scrollTo({ top: 0 });
  };

  useMountEffect(() => {
    if (!student?.bundle?.selections) return;
    if (Object.keys(student.bundle.selections).length === 0) return;
    if (countEmptySchedulingPreferences(student.bundle ?? {}) === 0) return;
    setSignupSession(
      updateStudentById(
        activeStudentId,
        {
          bundle: {
            ...student.bundle,
            selections: initializeEmptySchedulingPreferences(student.bundle),
          },
        },
        signupData,
      ),
    );
  });

  return (
    <div className="flex flex-col-reverse justify-center items-center">
      <Card
        borderWidth="0"
        className="w-full sm:w-3/5 sm:max-w-screen-sm sm:rounded-lg"
        noRounding
        hideOverflow={false}
      >
        <header className="border-0 border-b border-solid border-j-purple-200 pb-4 mb-8">
          <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2">
            Class Schedule Preferences
          </h1>
          <p className="text-j-dark-300 m-0 text-base">
            We’ll match {student?.firstName || 'your student'} to an instructor who
            fits their schedule.
          </p>
        </header>
        <div className="space-y-8">
          <div>
            <TimezoneSelect />
          </div>
          <DraggableScheduleSections subjects={subjects} />
          <div>
            <label>
              <h3 className="text-j-dark-600 text-sm font-medium m-0 pb-1">
                Starting Date
              </h3>
              {dateError && (
                <div className="text-j-pink-700 text-sm pb-2">{dateError}</div>
              )}
              <div className="text-j-dark-400 text-sm pb-2">
                We'll need a few days to get everything ready for you. After that,
                when you start is up to you!
              </div>
              <Input
                type="date"
                size="small"
                fullWidth
                value={startingDate}
                min={firstAvailableDateString}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const tz = guessTimezoneValue();
                  try {
                    const selectedDate = utcToZonedTime(e.target.value, tz);
                    if (selectedDate.getTime() < firstAvailableDate.getTime()) {
                      setDateError(
                        `We need at least ${ONBOARDING_START_DATE_BUFFER_DAYS} days from today to set up your classes.`,
                      );
                    } else {
                      setDateError('');
                    }
                    setStartingDate(format(selectedDate, 'yyyy-MM-dd'));
                  } catch (e) {
                    // ignore this error since users can type invalid or partial dates here
                    // we'll validate the date whatever they enter on submit
                  }
                }}
              />
            </label>
          </div>
          <SignupFlowFooter
            back={{
              handler: () =>
                history.push(
                  getNextPage(
                    navStates.signup.schedulingPreferences,
                    ROUTE_EVENT.BACK,
                    {
                      signupData,
                      search: location.search,
                      shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
                    },
                  ),
                ),
            }}
            next={{ disabled: !isScheduleValid(), handler: onSubmit }}
          />
        </div>
      </Card>
    </div>
  );
};

export default SchedulingPreferences;
