import {
  getHydratedProductMetadata,
  PRODUCT_METADATA,
  PRODUCT_METADATA_TYPE,
} from 'app/signup_2023/constants/products';
import { Coupon, SignupProduct, StarterCourseFragment } from 'generated/graphql';

export const getDiscountedPrice = (price: number, coupon?: Coupon) => {
  if (!coupon) return price;
  if (coupon.amount_off) return price - coupon.amount_off;
  if (coupon.percent_off) return price * ((100 - coupon.percent_off) / 100);
  return price;
};

export const getDiscountDurationText = (price: number, coupon: Coupon) => {
  if (coupon.duration === 'once' || coupon.duration_in_months === 1) {
    return ` for one month, then $${price}/mo`;
  }
  if (coupon.duration_in_months || 0 > 1) {
    return ` for ${coupon.duration_in_months} months, then $${price}/mo`;
  }
  return '';
};

const getRecurringProductSummary = (
  product: PRODUCT_METADATA_TYPE,
  numWeeklyClasses?: number,
  course?: StarterCourseFragment,
) => {
  const subjectDisplayName =
    product.name === SignupProduct.PrivateTutoring
      ? PRODUCT_METADATA[SignupProduct.PrivateTutoring].displayName
      : course?.subject?.displayName;
  const perWeek = numWeeklyClasses || 1;
  return `Monthly Membership (${`${subjectDisplayName} `} ${perWeek}x per week)`;
};

export const getProductSummary = (
  product: PRODUCT_METADATA_TYPE,
  numWeeklyClasses?: number,
  course?: StarterCourseFragment,
) => {
  if (product.isSubscription) {
    return getRecurringProductSummary(product, numWeeklyClasses, course);
  }
  const bulkSubproductMetadata = getHydratedProductMetadata(
    product.name,
    course?.name,
  );
  if (!bulkSubproductMetadata) {
    return 'Computer Science package';
  }
  return bulkSubproductMetadata.displayName;
};
