import React, { FC, useState } from 'react';
import { NewButton } from 'core-components';
import {
  HowDidYouHearAboutUs,
  useUpsertHowDidYouHearAboutUsMutation,
  Maybe,
  UpsertHowDidYouHearAboutUsInput,
  useGetMyHowDidYouHearAboutUsSubmissionQuery,
  SourceOption,
  GetMyHowDidYouHearAboutUsSubmissionQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { HOW_DID_YOU_HEAR_ABOUT_US_SOURCE_OPTIONS } from 'constants/how_did_you_heard_about_us';
import Radio from 'app/signup_session/components/Radio';
import SpinnerV2 from 'components/SpinnerV2';
import { useUserContext } from 'modules/UserContext';

interface HowDidYouHearAboutUsProps {
  signupDataId: string | undefined;
  containerStyles?: string;
}

interface HowDidYouHearAboutUsSectorProps {
  data: GetMyHowDidYouHearAboutUsSubmissionQuery | undefined;
  signupDataId: string | undefined;
  containerStyles?: string;
  userId: string;
}

const HowDidYouHearAboutUsSector: FC<HowDidYouHearAboutUsSectorProps> = ({
  data,
  signupDataId,
  containerStyles,
  userId,
}) => {
  const [howDidYouHearAboutUsData, setHowDidYouHearAboutUsData] = useState<
    Maybe<HowDidYouHearAboutUs> | Partial<HowDidYouHearAboutUs> | undefined
  >(undefined);
  const [
    isSubmittedHowDidYouHearAboutUs,
    setIsSubmittedHowDidYouHearAboutUs,
  ] = useState(Boolean(data?.getMyHowDidYouHearAboutUsSubmission.source));
  const showOtherExplanation =
    howDidYouHearAboutUsData?.source === SourceOption.Other &&
    !howDidYouHearAboutUsData?.other;

  const [saveHowDidYouHearAboutUs] = useUpsertHowDidYouHearAboutUsMutation();

  const handleSaveHearAboutUs = async () => {
    if (!howDidYouHearAboutUsData?.source) {
      return;
    }
    try {
      if (howDidYouHearAboutUsData?.source) {
        const inputData = _.omit(
          {
            ...howDidYouHearAboutUsData,
            userId,
            signupSessionId: signupDataId,
          },
          ['__typename'],
        );

        await saveHowDidYouHearAboutUs({
          variables: {
            input: inputData as UpsertHowDidYouHearAboutUsInput,
          },
        });
      }
      setIsSubmittedHowDidYouHearAboutUs(true);
    } catch (err) {
      console.log(`Failed to save how did you hear about us.`, err);
    }
  };

  if (isSubmittedHowDidYouHearAboutUs) {
    return <></>;
  }

  return (
    <div className={containerStyles}>
      <div className="w-full">
        <h2 className="text-j-dark-600 text-sm leading-6 m-0 pb-1 font-medium">
          How did you hear about us? (optional)
        </h2>
        <div>
          {HOW_DID_YOU_HEAR_ABOUT_US_SOURCE_OPTIONS.map(option => (
            <div key={option.value} className="flex flex-row items-center gap-1">
              <button
                key={option.value}
                className="flex space-x-3 tracking-normal bg-transparent border-none text-j-dark-600 normal-case text-sm text-left hover:shadow-none ignore-juni-globals p-0 py-2"
                onClick={() => {
                  setHowDidYouHearAboutUsData({
                    source: option.value,
                    other:
                      option.value === SourceOption.Other
                        ? howDidYouHearAboutUsData?.other
                        : null,
                  });
                }}
              >
                <Radio
                  selected={option.value === howDidYouHearAboutUsData?.source}
                />
                <span>{option.label}</span>
              </button>
              {option.value === SourceOption.Other && (
                <div className="flex flex-col items-start pl-2">
                  <input
                    value={howDidYouHearAboutUsData?.other || ''}
                    className="flex p-3 h-2 text-sm rounded-lg border text-blue-gray-800 border-blue-gray-200 border-solid shadow-none tracking-normal font-normal font-sans"
                    type="text"
                    id="other"
                    disabled={
                      howDidYouHearAboutUsData?.source !== SourceOption.Other
                    }
                    onChange={e => {
                      const res = e.target.value;
                      setHowDidYouHearAboutUsData({
                        ...howDidYouHearAboutUsData,
                        other: res,
                      });
                    }}
                  />
                  {showOtherExplanation && (
                    <span className="text-xs text-j-pink-700">
                      We really appreciate any additional details you can provide!
                    </span>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <NewButton
          className="mt-4"
          intent="success"
          onClick={handleSaveHearAboutUs}
          disabled={!howDidYouHearAboutUsData?.source}
        >
          Submit
        </NewButton>
      </div>
    </div>
  );
};

const HowDidYouHearAboutUsSection: FC<HowDidYouHearAboutUsProps> = ({
  signupDataId,
  containerStyles,
}) => {
  const { user } = useUserContext();
  const { data, loading } = useGetMyHowDidYouHearAboutUsSubmissionQuery({
    variables: { input: signupDataId },
  });

  if (!user) {
    return <></>;
  }

  if (loading) {
    return (
      <div className={containerStyles}>
        <SpinnerV2 />
      </div>
    );
  }

  return (
    <HowDidYouHearAboutUsSector
      data={data}
      signupDataId={signupDataId}
      containerStyles={containerStyles}
      userId={user._id}
    />
  );
};

export default HowDidYouHearAboutUsSection;
