import _, { keyBy } from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { Chevron } from 'components/Icons';
import SubjectIcon from 'components/SubjectIcon';
import { SUBJECT_DISPLAY_NAMES, SUBJECT_TYPE } from 'constants/subjects';
import { jDark } from 'theme/colors';

import {
  useLoadCertificationTracksByStudentQuery,
  useStudentBundleCertificateParamsQuery,
  useUpdateCertificationTracksMutation,
  CertificationTracksInput,
  useGetSubjectsQuery,
  GetSubjectsReturnFragment,
} from 'generated/graphql';
import ObjectID from 'bson-objectid';
import Banner from '../Banner';
import Wrapper from '../Wrapper';
import BundleCard from './BundleCard';
import { BundleGroup, TrackStatus, TrackStatusTypes } from './types';

/**
 * Maps courses no longer offered to courses with credit toward certifications.
 * @type {{[key: string]: string}}
 * TODO: remove this when Roadmap is refactored to not be hard coded - jan2022
 */
export const TRACK_COUNTS_TOWARD_MAP: { [key: string]: string } = {
  ap_cs: 'ap_computer_science_a',
  pre_algebra_a: 'pre_algebra_a_v2',
  pre_algebra_b: 'pre_algebra_b_v2',
  python_level_1_v2: 'python_level_1',
  python_graphics_superstar: 'python_level_1',
  python_level_1_group: 'python_level_1',
  python_level_1_async_a: 'python_level_1',
  python_data_master: 'python_level_3',
  game_superstar: 'scratch_level_1',
  investing_stock_market_async: 'investing_stock_market',
  investing_ondemand: 'investing_stock_market',
};

interface LearnerRoadmapProps {
  studentId: string;
  bundleGroups: BundleGroup[];
  roadmapLinkBase: string;
  studentCompletedTracks: string[];
  studentInProgressTracks: string[];
}

const TrackBundleHeader: React.FC<{ title?: string; subject?: SUBJECT_TYPE }> = ({
  title,
  subject,
}) => (
  <div className="flex items-center">
    {subject && (
      <div className="bg-j-green-200 text-j-green-700 rounded-lg p-1.5">
        <SubjectIcon subject={subject} className="flex h-5 w-5 p-0" />
      </div>
    )}
    <span className="leading-none text-j-dark-600 font-graphik font-medium text-2xl ml-4 mr-6">
      {title || (subject && `${SUBJECT_DISPLAY_NAMES[subject]} Tracks`)}
    </span>
  </div>
);

const ViewAllSubjectCoursesLink: React.FC<{
  subject: GetSubjectsReturnFragment;
}> = ({ subject }) => (
  <div className="flex">
    <a
      className={classNames(
        'text-j-dark-600',
        'text-sm',
        'text-right',
        'font-graphik',
        'font-medium',
        'inline-flex',
        'items-center',
        'no-underline',
        'hover:underline',
      )}
      href={subject.websiteUrl}
      target="_blank"
      rel="noreferrer"
    >
      <span className="mr-1">View all {subject.displayName} courses</span>
      <Chevron orientation="right" stroke={jDark[600]} />
    </a>
  </div>
);

const LearnerRoadmap: React.FC<LearnerRoadmapProps> = ({
  studentId,
  bundleGroups,
  roadmapLinkBase,
  studentCompletedTracks,
  studentInProgressTracks,
}) => {
  const subjectsQuery = useGetSubjectsQuery();
  const subjects = keyBy(subjectsQuery.data?.getSubjects || [], o => o.name);

  // Loads certification tracks from Student. Refetches on each favorite click.
  const { data, refetch } = useLoadCertificationTracksByStudentQuery({
    variables: { id: new ObjectID(studentId) },
  });
  // Certification tracks are {name, isFavorite, ...} and keyed by name.
  const certificationTracksByName = _.keyBy(
    data?.studentById?.certificationTracks || [],
    'name',
  );
  const [updateCertificationTracks] = useUpdateCertificationTracksMutation();

  /**
   * Toggles favorite certification tracks on favorite icon click.
   * Creates a new certification track object to mutate isFavorite.
   * Saves a new list of favorited tracks to student.
   */
  const handleFavoriteClick = (bundleName: string) => async () => {
    // Creates a new certification track that passes through other props then toggles isFavorite.
    certificationTracksByName[bundleName] = {
      ...certificationTracksByName[bundleName],
      name: bundleName,
      isFavorite: !certificationTracksByName[bundleName]?.isFavorite,
    };

    // Saves certificationTracksByName mapped to an array of input type.
    await updateCertificationTracks({
      variables: {
        input: {
          _id: studentId,
          certificationTracks: _.map(
            _.values(certificationTracksByName),
            track => _.omit(track, '__typename') as CertificationTracksInput,
          ),
        },
      },
    });
    refetch();
  };

  const certificateBundles = useStudentBundleCertificateParamsQuery({
    variables: { studentId: new ObjectID(studentId) },
  });

  const completedBundlesByName = _.keyBy(
    certificateBundles?.data?.studentCertificateParams?.items,
    'bundleName',
  );

  const trackStatus = [
    // Adds non-active courses as a credit toward newer courses.
    ...studentInProgressTracks.map(
      track =>
        TRACK_COUNTS_TOWARD_MAP[track] && {
          [TRACK_COUNTS_TOWARD_MAP[track]]: TrackStatusTypes.INPROGRESS,
        },
    ),
    ...studentCompletedTracks.map(
      track =>
        TRACK_COUNTS_TOWARD_MAP[track] && {
          [TRACK_COUNTS_TOWARD_MAP[track]]: TrackStatusTypes.COMPLETED,
        },
    ),
    // Adds all courses (active/non-active) once.
    // This overwrites non-active course progress if an active course with the same credit is in progress.
    // This also adds the original non-active course once in case a certification requires it.
    ...studentInProgressTracks.map(
      track =>
        track && {
          [track]: TrackStatusTypes.INPROGRESS,
        },
    ),
    ...studentCompletedTracks.map(
      track =>
        track && {
          [track]: TrackStatusTypes.COMPLETED,
        },
    ),
  ].reduce((acc: TrackStatus, curr) => ({ ...acc, ...curr }), {});
  return (
    <div className="h-full min-h-screen">
      <Banner title="Roadmap">
        <div className="max-w-prose m-0 mt-4">
          Use the Roadmap to explore pathways through the Juni courses and to
          discover more about the Learning Targets for each course.
        </div>
      </Banner>
      {/* Favorite Certification Tracks (favorite) */}
      {_.some(data?.studentById?.certificationTracks, { isFavorite: true }) && (
        <div className="bg-red-50">
          <Wrapper className="p-4">
            <div key="My Tracks">
              <div className="flex justify-between mt-10 mb-6">
                <TrackBundleHeader title="My Tracks" />
              </div>
              {bundleGroups.map(bundleGroup =>
                bundleGroup.bundles.map(
                  bundle =>
                    certificationTracksByName[bundle.name]?.isFavorite && (
                      <div key={`${bundle.name}`} className="my-4">
                        <BundleCard
                          bundle={bundle}
                          defaultExpanded
                          roadmapLinkBase={roadmapLinkBase}
                          subject={bundleGroups[0].subject}
                          trackStatus={trackStatus}
                          isFavorite
                          handleFavoriteClick={handleFavoriteClick(bundle.name)}
                          certificateUrl={
                            completedBundlesByName[bundle.name]?.params || ''
                          }
                          shadow="none"
                        />
                      </div>
                    ),
                ),
              )}
            </div>
          </Wrapper>
        </div>
      )}
      {/* All Tracks */}
      <Wrapper>
        {bundleGroups.map(({ subject, bundles }) => (
          <div key={subject}>
            <div className="flex justify-between mt-10 mb-6">
              <TrackBundleHeader subject={subject} />
              {subjects[subject] && (
                <ViewAllSubjectCoursesLink subject={subjects[subject]} />
              )}
            </div>
            {bundles.map(bundle => (
              <div key={`${subject}/${bundle.name}`} className="my-4">
                <BundleCard
                  bundle={bundle}
                  defaultExpanded={false}
                  roadmapLinkBase={roadmapLinkBase}
                  subject={subject}
                  trackStatus={trackStatus}
                  isFavorite={!!certificationTracksByName[bundle.name]?.isFavorite}
                  handleFavoriteClick={handleFavoriteClick(bundle.name)}
                  certificateUrl={completedBundlesByName[bundle.name]?.params || ''}
                />
              </div>
            ))}
          </div>
        ))}
      </Wrapper>
    </div>
  );
};

export default LearnerRoadmap;
