import {
  CheckoutFlowPaths,
  SubjectSignupData,
  SubjectName,
  OnboardingFlowPath,
  DiscountData,
  CourseBundleName,
  CheckoutFlowLabels,
} from 'app/signup_session/types';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';

export const BASE_PATHS = {
  checkout: 'signup',
  onboarding: 'onboarding',
};

export const CHECKOUT_PATHS = {
  courseFrequency: 'course-frequency',
  parentInfo: 'parent-info',
  studentInfo: 'student-info',
  checkout: 'payment',
  starterCourse: 'starter-course',
  schedulingPreferences: 'scheduling-preferences',
  moreStudentInfo: 'more-student-info',
};

export const CHECKOUT_FLOW_STEPS: Array<{
  path: CheckoutFlowPaths;
  label: CheckoutFlowLabels;
}> = [
  { path: 'parent-info', label: 'Parent Info' },
  { path: 'starter-course', label: 'Starter Course' },
  { path: 'student-info', label: 'Student Info' },
  { path: 'course-frequency', label: 'Plan Selection' },
  { path: 'scheduling-preferences', label: 'Scheduling Preferences' },
  { path: 'more-student-info', label: 'More Student Info' },
  { path: 'payment', label: 'Checkout' },
];

export const ONBOARDING_PATHS: {
  [key: string]: OnboardingFlowPath;
} = {
  createAccount: 'create-account',
  schedulingPreferences: 'scheduling-preferences',
  coursePlacement: 'course-placement',
  studentInfo: 'student-info',
  confirmation: 'confirmation',
};

export const ONBOARDING_FLOW_STEPS: Array<{
  path: OnboardingFlowPath;
  label:
    | 'Create your account'
    | 'Class schedule preferences'
    | 'Course Placement'
    | 'Help us to get to know'
    | 'Confirmation';
}> = [
  { path: 'create-account', label: 'Create your account' },
  { path: 'scheduling-preferences', label: 'Class schedule preferences' },
  { path: 'course-placement', label: 'Course Placement' },
  { path: 'student-info', label: 'Help us to get to know' }, // the student's name will be appended to the end
  { path: 'confirmation', label: 'Confirmation' },
];

export const REFERRAL_COOKIE_NAME = 'juni_referral_code';
export const REFERRER_COOKIE_NAME = 'juni_referrer_name';
export const SIGNUP_SESSION_COOKIE_NAME = 'juni_dcf_session_id';

export const COMPUTER_SCIENCE = SUBJECT_DISPLAY_NAMES[SUBJECTS.COMPUTER_SCIENCE];
export const MATH = SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS];
export const ENGLISH = SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH];
export const INVESTING = SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING];
export const SCIENCE = SUBJECT_DISPLAY_NAMES[SUBJECTS.SCIENCE];

export const SIGNUP_DATA_BY_SUBJECT: {
  [key in SubjectName]: SubjectSignupData;
} = {
  [COMPUTER_SCIENCE]: {
    subjectName: COMPUTER_SCIENCE,
    description:
      'Build solid coding foundations with creative games and projects. Our full curriculum covers beginning to advanced programming.',
    ageRange: [8, 18],
  },
  [MATH]: {
    subjectName: MATH,
    description:
      'Master key math concepts with real-world applications and meaningful practice. Our curriculum starts from elementary school, through high school AP Calculus.',
    ageRange: [7, 18],
  },
  [ENGLISH]: {
    subjectName: ENGLISH,
    description:
      'Develop strong creative and analytical skills in both reading and writing. Our courses span from elementary to high school levels, including project-based electives.',
    ageRange: [7, 18],
  },
  [INVESTING]: {
    subjectName: INVESTING,
    description:
      'Learn the foundational skills to build a business and achieve financial literacy. Our fun, project-based courses explore entrepreneurship, investing, and personal finance.',
    ageRange: [9, 18],
  },
  // TODO clean up and simplify, pretty sure none of this data is really used anymore except for subjectName
  [SCIENCE]: {
    subjectName: SCIENCE,
    description: '',
    ageRange: [7, 18],
  },
};

export const CALENDLY_LINKS = {
  salesTeam: `https://calendly.com/juni-admissions/orientation?utm_source=checkout`,
  PETeam: `https://calendly.com/juniaccounts/juni-learning-onboarding-and-welcome-call?utm_source=gtmpse&utm_medium=checkout&utm_campaign=freetrials`,
  PARENT_ADVISOR:
    'https://calendly.com/juniadvisors/juni-onboarding-and-welcome-call',
};

export const PRICE_LOOKUP: {
  [weeklyClasses: number]: number;
} = {
  0: 29,
  0.5: 150,
  1: 275,
  2: 475,
  3: 650,
  4: 900,
  5: 1125,
  6: 1350,
};

// keep in sync with BE course formats in server/app/courses/models/course.ts
export const COURSE_FORMAT = {
  private: 'private',
  onDemand: 'on_demand',
  group: 'group',
};

// make sure to keep in sync with the server regex in SignupSession model
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const COUPON_DISALLOW_LIST = [
  'REFERRAL_FIRST_MONTH',
  'REFERRAL_100',
  'SIBLING',
  'SIBLING_DISCOUNT',
  'SIBLINGDISCOUNT',
  'Sl7pjGGi',
];

export const LEFT_CARD_STYLES = {
  OUTER: `col-span-8 lg:col-span-5 mt-4 lg:mt-0`,
  INNER: `container w-full lg:w-4/5 mx-auto`,
};

export const BUNDLE_COMBINATIONS: Array<{
  bundleName: CourseBundleName;
  weeklyClassTotal?: number;
  weeklyClassMin?: number;
}> = [
  {
    bundleName: 'explorer',
    weeklyClassTotal: 0.5,
  },
  {
    bundleName: 'builder',
    weeklyClassTotal: 1,
  },
  {
    bundleName: 'innovator',
    weeklyClassMin: 2,
  },
];

export const REFERRAL_CODE_MESSAGE =
  'Your referral discount will be applied at checkout.';

export const REFERRAL_DISCOUNT_DATA: DiscountData = {
  discount: 5000,
  discountType: 'amount_off',
  isReferral: true,
  isSelected: true,
  duration: 'once',
};
