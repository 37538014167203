import React from 'react';
import { useSignupData } from 'app/signup_2023/hooks';
import { ErrorableLoading } from 'components/ui';
import HowDidYouHearAboutUsSection from 'app/signup_session/Onboarding/pages/Confirmation/components/HowDidYouHearAboutUsSection';
import {
  GoToHomePage,
  ReviewYourAnswers,
  ScheduleOnboardingCall,
} from './components';
import { containerStyles } from './components/constants';

const Confirmation = () => {
  const { signupData, loading, error } = useSignupData();
  const { onboardingCallUriFromSignupConfirmation: onboardingCallUri } =
    signupData?.events || {};

  if (!signupData || error || loading) {
    return <ErrorableLoading error={error} />;
  }

  return (
    <>
      {!onboardingCallUri && <ScheduleOnboardingCall />}
      {onboardingCallUri && <GoToHomePage />}
      <HowDidYouHearAboutUsSection
        signupDataId={signupData._id}
        containerStyles={containerStyles}
      />
      <ReviewYourAnswers />
    </>
  );
};

export default Confirmation;
