import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  useGetFeaturedProjectsQuery,
  StudentProjectFeaturedType,
} from 'generated/graphql';

import JuniSpinner from 'components/JuniSpinner';
import { PortalComponentLayout } from 'components/PortalLayout';
import { NewCard as Card, Message, MediaCard } from 'core-components';

import { mongoIDToDateStr } from 'utils/timeUtils';

import JuniverseProjectCard from 'app/miscellaneous/JuniverseProjectCard';
import juniverseProfile from './assets/juniverse_profile.png';
import juniverseWelcome from './assets/juniverse_welcome.png';

const MyFeed: FC<{
  username?: string;
}> = ({ username }) => {
  const { data, loading, error } = useGetFeaturedProjectsQuery({
    variables: { featuredType: StudentProjectFeaturedType.MyFeed },
  });

  const featuredProjects = data?.featuredStudentProjects?.items;

  /* Sort our project using IDs, which actually encode the date when the project was featured */
  const featuredProjectsToDisplay = featuredProjects
    ?.map(project => project)
    .sort((p1, p2) => {
      const date1 = Date.parse(mongoIDToDateStr(p1?._id));
      const date2 = Date.parse(mongoIDToDateStr(p2?._id));
      return date2 - date1;
    });

  return loading ? (
    <JuniSpinner size={null} />
  ) : (
    <PortalComponentLayout title="My Feed">
      <div>
        Get inspiration from featured projects created by Juni students and
        instructors around the world!
        <br />
        Want to see your project here? Publish a project in your Juni Portal that
        showcases your skills and creativity, and you may be featured too!
      </div>

      <div className="w-full flex flex-col lg:flex-row gap-6">
        <div className="lg:w-2/3 w-full">
          {error ? (
            <Card>
              <Message status="error">
                Oops, something went wrong! Try refreshing the page.
              </Message>
            </Card>
          ) : featuredProjectsToDisplay?.length ? (
            featuredProjectsToDisplay.map(
              (juniverseProject, index) =>
                juniverseProject && (
                  <div
                    key={juniverseProject._id}
                    className={classNames({ 'mt-6': index > 0 })}
                  >
                    <JuniverseProjectCard {...juniverseProject} />
                  </div>
                ),
            )
          ) : (
            <Card>
              <Message status="warning">
                Looks like there's nothing in your feed right now. Check back later!
              </Message>
            </Card>
          )}
        </div>

        <div className="flex flex-col gap-6 lg:w-1/3 w-full">
          <Link className="no-underline" to={`/juniverse/users/${username}`}>
            <MediaCard>
              <MediaCard.Image
                src={juniverseProfile}
                height="1/4"
                alt="People together"
              />
              <MediaCard.Content>
                <div className="text-j-dark-600 font-graphik font-medium text-sm leading-relaxed">
                  Your Juni Profile
                </div>
                <div className="text-j-dark-400 font-graphik text-sm leading-normal mt-2">
                  {username
                    ? 'Check out your Juni profile, which features all of the projects you’ve published!'
                    : 'Publish one of your projects to create your Juni profile!'}
                </div>
              </MediaCard.Content>
            </MediaCard>
          </Link>

          <a className="no-underline" href="https://junilearning.com/juniverse/">
            <MediaCard>
              <MediaCard.Image
                src={juniverseWelcome}
                height="1/4"
                alt="People together"
              />
              <MediaCard.Content>
                <div className="text-j-dark-600 font-graphik font-medium text-sm leading-relaxed">
                  The Juniverse
                </div>
                <div className="text-j-dark-400 font-graphik text-sm leading-normal mt-2">
                  See even more projects created by Juni students and instructors!
                </div>
              </MediaCard.Content>
            </MediaCard>
          </a>
        </div>
      </div>
    </PortalComponentLayout>
  );
};

export default MyFeed;
