import React, { useContext, useState } from 'react';
import PortalSection from 'components/PortalSection';
import {
  JuniClubInvitationType,
  JuniClubType,
  Student,
  useAcceptClubInvitationMutation,
  useGetClubInvitationsQuery,
  useGetJuniClubsByIdsQuery,
} from 'generated/graphql';
import UserContext from 'modules/UserContext';

import UsernameCreator from 'components/UsernameCreator';
import { ConductModal } from 'components/clubs';

import RingSpinner from 'components/RingSpinner';

import _ from 'lodash';
import { MAX_CLUB_MEMBERSHIPS_GUEST } from 'constants/clubs';
import useClubStore, { ClubStoreType } from 'app/clubs/stores/ClubStore';
import Card from 'core-components/NewCard';
import Button from 'core-components/NewButton';

interface ClubInviteCardProps {
  studentId: string;
  invite: JuniClubInvitationType;
  club: JuniClubType;
  setSelectedClubToJoin: (club: JuniClubType) => void;
  clubInvitesRefetch: () => void;
}
const ClubInviteCard: React.FC<ClubInviteCardProps> = ({
  studentId,
  invite,
  club,
  setSelectedClubToJoin,
  clubInvitesRefetch,
}) => {
  // This is legacy code from a mistake: the mutation should be called useAcceptRejectClubInviationMutation.
  const [modifyInvitation] = useAcceptClubInvitationMutation();
  return (
    <Card padding="4">
      <div className="flex mb-3">
        <img
          className="h-12 w-12 rounded-md mr-3 pl-0.5"
          src={club?.coverPhoto?.replace('_full', '_thumbnail')}
          alt=""
        />
        <div className="flex flex-col">
          <div className="text-j-dark-600 font-graphik font-medium text-sm leading-relaxed">
            {`${club.displayName}`}
          </div>
          <div className="flex-shrink-0 text-xs mb-2 text-j-dark-400">
            {`${invite.inviterName} has invited you`}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex justify-between w-full space-x-2">
          <Button
            fullWidth
            variant="primary"
            onClick={() => setSelectedClubToJoin(club)}
          >
            Accept
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={async () => {
              try {
                await modifyInvitation({
                  variables: {
                    input: {
                      studentId,
                      juniClubInvitationId: invite._id,
                      accept: false,
                    },
                  },
                });
                clubInvitesRefetch();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Decline
          </Button>
        </div>
      </div>
    </Card>
  );
};

interface ClubInvitesProps {
  student: Student;
}
const ClubInvites: React.FC<ClubInvitesProps> = ({ student }) => {
  const {
    data: clubInvites,
    loading: clubInvitesLoading,
    refetch: clubInvitesRefetch,
  } = useGetClubInvitationsQuery({
    fetchPolicy: 'no-cache',
  });

  const { username, _id: studentId } = student;
  const [newUsername, setNewUsername] = useState(null);
  const [selectedClubToJoin, setSelectedClubToJoin] = useState<JuniClubType | null>(
    null,
  );

  const { user } = useContext(UserContext);
  const juniClubsState = useClubStore((state: ClubStoreType) => state.juniClubs);

  const canJoin = !(
    user?.isGuest && _.keys(juniClubsState).length >= MAX_CLUB_MEMBERSHIPS_GUEST
  );

  const invites = clubInvites?.getClubInvitations?.items || [];
  const { data: clubData, loading: clubLoading } = useGetJuniClubsByIdsQuery({
    skip: !clubInvites,
    variables: {
      ids:
        clubInvites?.getClubInvitations?.items?.map(invite => invite.juniClubId) ||
        [],
    },
  });
  const clubs = clubData?.getJuniClubsByIds?.items || [];
  const filteredInvites = invites.filter(invite => {
    const juniClub = clubs?.find(club => club._id === invite.juniClubId);
    const inviteValid = !invite?.acceptedAt && !invite?.rejectedAt;
    const matchesParent =
      inviteValid &&
      studentId &&
      !invite?.inviteeStudentId &&
      invite?.inviteeParentId;
    const matchesStudent =
      inviteValid &&
      student &&
      student._id.toString() &&
      invite?.inviteeStudentId?.toString() === student._id.toString();
    return juniClub && (matchesStudent || matchesParent);
  });

  if (filteredInvites.length === 0) {
    return null;
  }
  return (
    <PortalSection name="Club Invites">
      {clubInvitesLoading || clubLoading ? (
        <RingSpinner />
      ) : (
        <>
          <UsernameCreator
            studentId={studentId}
            isOpen={!username && !!selectedClubToJoin}
            closeModal={() => {
              setSelectedClubToJoin(null);
              setNewUsername(null);
            }}
            usernameCallback={setNewUsername}
          />
          {selectedClubToJoin && (
            <ConductModal
              studentId={studentId}
              juniClub={selectedClubToJoin}
              canJoin={canJoin}
              requiresRefresh={username !== newUsername}
              isOpen={!!newUsername || !!username}
              handleClose={() => {
                setSelectedClubToJoin(null);
                setNewUsername(null);
                if (newUsername) {
                  window.location.reload();
                }
              }}
            />
          )}
          <div className="flex flex-col space-y-2">
            {filteredInvites.map(invite => {
              const club = clubs.find(club => club._id === invite.juniClubId);
              if (club) {
                return (
                  <ClubInviteCard
                    key={club?._id.toString()}
                    invite={invite}
                    club={club}
                    studentId={studentId}
                    setSelectedClubToJoin={setSelectedClubToJoin}
                    clubInvitesRefetch={clubInvitesRefetch}
                  />
                );
              }
              return null;
            })}
          </div>
        </>
      )}
    </PortalSection>
  );
};

export default ClubInvites;
