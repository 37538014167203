import React, { FC, useState } from 'react';
import { Select, SelectOption } from 'core-components';
import { SubjectName } from 'app/signup_session/types';
import { findStudentById, updateStudentById } from 'app/signup_session/lib';
import makeCheckoutService from 'services/signupSessions/checkoutService';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import useMountEffect from 'hooks/useMountEffect';
import { FOREIGN_TIMEZONES, Timezone, US_TIMEZONES } from 'constants/timezones';
import guessTimezoneValue from 'utils/guessTimezoneValue';

const TimezoneSelect: FC<{
  shouldUpdateOnboarding?: boolean;
  handleSetTimezone?: (timezone: string) => void;
}> = ({ shouldUpdateOnboarding = true, handleSetTimezone }) => {
  const { signupData, activeStudentId, setSignupSession } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData);
  const [localTimezoneState, setLocalTimezoneState] = useState(
    student?.timezone ?? guessTimezoneValue(),
  );

  useMountEffect(() => {
    if (student && !student.timezone) {
      setTimezone(localTimezoneState);
    }
  });

  const timezones: SelectOption<Timezone>[] = [
    ...US_TIMEZONES,
    ...FOREIGN_TIMEZONES,
  ].map(({ value, displayName }) => ({ value, label: displayName }));

  const selectedTimezone = timezones.find(
    ({ value }) => localTimezoneState === value,
  );

  const setTimezone = async (timezone: string) => {
    setLocalTimezoneState(timezone);
    if (handleSetTimezone) {
      handleSetTimezone(timezone);
    } else {
      setSignupSession(updateStudentById(activeStudentId, { timezone }, signupData));
    }
    if (shouldUpdateOnboarding) {
      const checkoutService = makeCheckoutService();
      await Promise.all(
        Object.keys(student?.bundle?.selections ?? {}).map(subject => {
          const selection = student?.bundle?.selections[subject as SubjectName];
          return checkoutService.updateOnboardingTicket(
            selection?.onboardingTicketId ?? '',
            { timezone },
          );
        }),
      );
    }
  };

  return (
    <>
      <label htmlFor="input-timezone">
        <div className="text-j-dark-600 text-sm font-medium pb-2">Time Zone</div>
      </label>
      <Select
        fullWidth
        id="input-timezone"
        size="small"
        selected={selectedTimezone?.value}
        onChange={value => setTimezone(value)}
        options={timezones}
        placeholder="Select a timezone"
        data-cy="timezone"
      />
    </>
  );
};

export default TimezoneSelect;
