import { useRef, useEffect } from 'react';
import { last } from 'lodash';
import { useLocation } from 'react-router-dom';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { STEP_NAMES, STEP_PATHS } from '../steps/constants';

declare global {
  interface Window {
    rdt?(type: 'track', name: string): void; // reddit
    qp?(type: 'track', name: string): void; // quora
    ttq?: { track: (name: string) => void }; // tiktok
    ndp?(type: 'track', name: string): void; // nextdoor
    lintrk?(type: 'track', args: { conversion_id: number }): void; // linkedin
    fpr?(type: 'referral', args: { email: string; uid?: string }): void; // FirstPromoter
  }
}

const track = () => {
  JuniAnalytics.track('load_membership_info');
  window.rdt?.('track', 'AddToCart');
  window.qp?.('track', 'AddToCart');
  window.ttq?.track('AddToCart');
  window.ndp?.('track', 'CONVERSION');
  window.lintrk?.('track', { conversion_id: 5692060 });
};

const TARGET_PATH = STEP_PATHS[STEP_NAMES.parentInfo];

const useTrackAdConversions = () => {
  const location = useLocation();
  const wasCalled = useRef(false);
  const lastPath = last(location.pathname.split('/'));

  // manually defined event for ad conversions
  // prevent duplicates from redirects and reverse navigation
  useEffect(() => {
    if (!wasCalled.current && lastPath === TARGET_PATH) {
      track();
      wasCalled.current = true;
    }
  }, [lastPath]);
};

export default useTrackAdConversions;
