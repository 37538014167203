import { FC, useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { addDays } from 'date-fns';

import SpinnerV2 from 'components/SpinnerV2';
import { DatePickerField, ErrorableLoading } from 'components/ui';
import {
  ACUITY_COMPUTER_SCIENCE_APPT_TYPES,
  ACUITY_ENGLISH_APPT_TYPES,
  ACUITY_INVESTING_APPT_TYPES,
  ACUITY_MATH_APPT_TYPES,
} from 'constants/acuity';
import {
  SUBJECTS,
  SUBJECT_DISPLAY_NAMES,
  SUBJECT_TO_METADATA_KEY,
  SUBJECT_TYPE,
} from 'constants/subjects';
import { Message, NewButton, Select, TextArea } from 'core-components';

import {
  ClassFrequency,
  StripeSubscription,
  Student,
  useDowngradeStripeSubscriptionMutation,
} from 'generated/graphql';

import {
  extractSubjectMetadata,
  hasWeeklyClassFrequenciesInMetadata,
} from 'utils/stripe';
import { getStudentNamesFromStudents } from 'app/learner/LearnerAccountV2/utils';
import { UPDATE_FLOWS } from 'app/learner/LearnerAccountV2/LearnerAccount';
import LearnerPortalModal from '../LearnerPortalModal';
import { DatePickerContainer, CheckboxWrapper, ModalCheckbox } from '../styles';

import { FormState, FormValues } from '../types';

// calculate the current subject frequencies based on the subscription metadata/products
// return value { [subject: string]: frequency: number }
const getCurrentSubjectFrequencies = (
  products: any,
  subscription: StripeSubscription,
) => {
  if (hasWeeklyClassFrequenciesInMetadata(subscription)) {
    const weeklyFrequencies = extractSubjectMetadata(subscription?.metadata);
    return _.map(_.toPairs(weeklyFrequencies), ([metadataKey, frequency]) => {
      const subject = (_.findKey(
        SUBJECT_TO_METADATA_KEY,
        value => value === metadataKey,
      ) || 'computer_science') as SUBJECT_TYPE;
      return {
        subject: SUBJECT_DISPLAY_NAMES[subject],
        frequency: Number(frequency),
      };
    });
  }
  // TODO (mhang): support multiple products
  const product = _.find(products, ['stripeId', subscription?.plan?.product]);
  return _.map(
    product?.scheduleFrequency,
    ({ appointmentTypeIDs, weeklyFrequency }) => {
      let subject = 'generic';
      if (appointmentTypeIDs?.length === 1) {
        const appointmentTypeID = appointmentTypeIDs[0];
        if (ACUITY_COMPUTER_SCIENCE_APPT_TYPES.includes(appointmentTypeID)) {
          subject = SUBJECT_DISPLAY_NAMES[SUBJECTS.COMPUTER_SCIENCE];
        } else if (ACUITY_MATH_APPT_TYPES.includes(appointmentTypeID)) {
          subject = SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS];
        } else if (ACUITY_ENGLISH_APPT_TYPES.includes(appointmentTypeID)) {
          subject = SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH];
        } else if (ACUITY_INVESTING_APPT_TYPES.includes(appointmentTypeID)) {
          subject = SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING];
        }
      }
      return {
        subject,
        frequency: weeklyFrequency,
      };
    },
  );
};

// TO DO: Refactor to better support 0.5 (2x per month)
const createClassFrequencyOptions = (
  numberOfClassesPerWeek: number,
  hasMultipleSubjects: boolean,
) => {
  const options = [];
  for (let i = 1; i <= numberOfClassesPerWeek; i += 1) {
    let option;
    if (i === numberOfClassesPerWeek) {
      option = {
        label: `2 sessions per month`,
        value: 0.5,
      };
    } else {
      option = {
        label: `${numberOfClassesPerWeek - i} session per week (remove ${i}x ${
          i === 1 ? 'session' : 'sessions'
        } per week)`,
        value: i,
      };
    }
    options.push(option);
  }
  if (
    (numberOfClassesPerWeek > 0.5 && !hasMultipleSubjects) ||
    hasMultipleSubjects
  ) {
    options.push({
      label: `Remove this subject`,
      value: 0,
    });
  }
  return options;
};

interface Props {
  formValues: FormValues;
  formState: FormState;
  updateFormState: (newValue: any, field: string) => void;
  updateFormValue: (newValue: any, field: string) => void;
  studentsQuery: any;
  productsQuery: any;
}

const ReduceClassFrequencyForm: FC<Props> = ({
  formValues,
  formState,
  updateFormState,
  updateFormValue,
  studentsQuery,
  productsQuery,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [err, setError] = useState<string | undefined>(undefined);
  const [downgradeSubscription] = useDowngradeStripeSubscriptionMutation();
  const [classFrequenciesToReduce, setClassFrequenciesToReduce] = useState<
    ClassFrequency[]
  >([]);

  const {
    subscription,
    selectedCoursesToRemove,
    effectiveDate,
    additionalNotes,
  } = formValues;
  const { updateFlow } = formState;

  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter((student: Student) =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );
  const studentNames = getStudentNamesFromStudents(currentStudents);

  const products = productsQuery?.data?.productsByStripeIds?.items || [];
  const currentFrequencies =
    subscription && getCurrentSubjectFrequencies(products, subscription);
  const hasMultipleSubjects =
    (currentFrequencies && currentFrequencies.length > 1) || false;

  // if reduce class frequency, missing effective date, notes, or remove no classes/all classes
  const formIsComplete =
    effectiveDate &&
    additionalNotes &&
    !_.some(
      classFrequenciesToReduce,
      reduceObj => reduceObj.frequency === undefined,
    );

  const handleDowngradeSubmit = async () => {
    if (isSubmitting) return;

    const isRemovingAllClasses =
      currentFrequencies &&
      currentFrequencies.length === classFrequenciesToReduce.length &&
      _.every(
        classFrequenciesToReduce,
        obj =>
          obj.frequency ===
          currentFrequencies.find(freq => freq.subject === obj.subject)?.frequency,
      );
    if (isRemovingAllClasses) {
      setError(
        "If you'd like to remove all classes, please pause or cancel your subscription",
      );
      return;
    }

    setError(undefined);
    setIsSubmitting(true);
    const downgradeSubscriptionInput = {
      subscriptionId: subscription?.id || '',
      downgradeOption: _.findKey(UPDATE_FLOWS, key => key === updateFlow),
      selectedCoursesToRemove,
      classFrequenciesToReduce,
      effectiveDate,
      additionalNotes,
    };
    try {
      await downgradeSubscription({
        variables: {
          input: downgradeSubscriptionInput,
        },
      });
      updateFormState('downgrade_confirmation', 'modal');
    } catch (err) {
      if (err instanceof Error) {
        setSubmitError(err.toString());
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LearnerPortalModal
      updateFormState={updateFormState}
      formState={formState}
      title="Downgrade Subscription"
      renderPrimaryButton={
        <NewButton onClick={handleDowngradeSubmit} disabled={!formIsComplete}>
          {isSubmitting ? <SpinnerV2 /> : <div className="font-medium">Submit</div>}
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            window.open(`mailto:support@learnwithjuni.com`, '_blank');
          }}
          variant="secondary"
        >
          Email Support
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 text-sm gap-4">
        {err && (
          <div className="text-sm w-full">
            <ErrorableLoading error={err} />
          </div>
        )}
        <div className="text-sm flex flex-col w-full gap-4">
          <div className="font-semibold">
            Please select the subject(s) you'd like to reduce courses for...
          </div>
          <div className="w-full flex flex-col">
            {currentFrequencies &&
              currentFrequencies.map(classObj => {
                const updateObj = classFrequenciesToReduce.find(
                  obj => obj.subject === classObj.subject,
                );
                return (
                  <div className="flex flex-row w-full mb-2 items-center">
                    <CheckboxWrapper className="w-1/3">
                      <ModalCheckbox
                        dark
                        spanStyle={{
                          border: '2px solid #292563',
                        }}
                        id={classObj.subject}
                        isActive
                        isChecked={updateObj !== undefined}
                        onChange={(checked: boolean) => {
                          if (checked) {
                            setClassFrequenciesToReduce([
                              ...classFrequenciesToReduce,
                              { subject: classObj.subject },
                            ]);
                          } else {
                            setClassFrequenciesToReduce(
                              classFrequenciesToReduce.filter(
                                obj => obj.subject !== classObj.subject,
                              ),
                            );
                          }
                        }}
                        size="large"
                      />
                      <label>{classObj.subject}</label>
                    </CheckboxWrapper>
                    <div className="w-7/12">
                      {updateObj && (
                        <Select
                          fullWidth
                          size="xsmall"
                          placeholder="Choose a frequency you'd like to remove"
                          selected={updateObj.frequency}
                          onChange={value => {
                            const index = classFrequenciesToReduce.findIndex(
                              obj => obj.subject === classObj.subject,
                            );
                            setClassFrequenciesToReduce([
                              ...classFrequenciesToReduce.slice(0, index),
                              { subject: updateObj.subject, frequency: value },
                              ...classFrequenciesToReduce.slice(
                                index + 1,
                                classFrequenciesToReduce.length,
                              ),
                            ]);
                          }}
                          options={createClassFrequencyOptions(
                            classObj.frequency,
                            hasMultipleSubjects,
                          )}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="text-sm">
          <span className="font-semibold">Please let us know:</span>
          <div className="mt-1 mb-0 flex flex-col gap-1">
            <div>
              • which classtime(s) you'd like to keep (e.g. keep the Tuesday 4pm PT
              session)
            </div>
            <div>
              • which classtime(s) you'd like to remove (e.g. remove the Saturday 9am
              PT session)
            </div>
            <div>
              • anything else you'd like us to know as we process this request! We
              take all your feedback to heart and use it to improve our service.
            </div>
          </div>
        </div>
        <TextArea
          placeholder="Leave your preferences here (required)"
          value={formValues.additionalNotes}
          name="additionalNotes"
          fullWidth
          onChange={e => updateFormValue(e.target.value, 'additionalNotes')}
        />
        <div className="text-sm">
          <DatePickerContainer>
            <DatePickerField
              placeholderText="MM/DD/YYYY"
              css={`
                input {
                  height: 42px;
                  color: #25546d;
                  font-size: 13px;
                  ::placeholder {
                    color: #757575;
                  }
                }
              `}
              className="full-width"
              label="Effective Downgrade Date:"
              dateFormat="MM/DD/YY"
              value={
                formValues.effectiveDate
                  ? moment(formValues.effectiveDate)
                  : undefined
              }
              name="effectiveDate"
              minDate={moment(addDays(new Date(), 1))}
              onDateChange={(newDate: Moment | undefined) => {
                if (!newDate || newDate < moment(new Date())) {
                  updateFormValue(undefined, 'effectiveDate');
                } else {
                  updateFormValue(newDate.format('MM/DD/YY'), 'effectiveDate');
                }
              }}
            />
          </DatePickerContainer>
        </div>
        <div>
          <span className="font-semibold">Pricing</span>
          <div className="mt-1">
            {`Since ${studentNames} is already taking classes with us, please reference our `}
            <a
              href="https://junilearning.com/tuition"
              target="_blank"
              rel="noopener noreferrer"
            >
              tuition page
            </a>{' '}
            {` to understand the updated monthly cost.`}
          </div>
        </div>
        <div>
          {submitError && (
            <Message status="error">
              Error downgrading your subscription. Please contact Juni HQ if this
              error persists.
            </Message>
          )}
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default ReduceClassFrequencyForm;
