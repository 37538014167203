import React from 'react';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';

import { projectTypeToRenderInfo } from 'constants/project_types';
import humanizeTimeAgo from 'utils/dates/humanizeTimeAgo';

import { ProjectTypes } from 'app/playground/models';
import {
  buildPlaygroundLink,
  buildNonPlaygroundLink,
  buildInstructorPlaygroundLink,
} from 'app/playground/utils';

import { useCourseNameToBasicMetadata } from 'hooks/course';
import { NewCard as Card, Tooltip } from 'core-components';
import { CardOrientations, IBadgeProperties } from './models';
import Camera from './badges/Camera';
import InProgress from './badges/InProgress';
import Complete from './badges/Complete';
import PlaygroundBadge from './badges/PlaygroundBadge';
import DeleteProject from './DeleteProject';

const ProjectCard: React.FC<{
  isPlayground: boolean;
  userId: string;
  projectId: string;
  orientation: CardOrientations;
  linkTo?: string;
  homeView?: boolean;
  projectType?: ProjectTypes;
  projectTitle: string;
  publishedAt: string | null;
  moduleId?: string | null;
  properties?: IBadgeProperties;
  userType?: string;
  deleteProject?: () => void;
  idLookup: any;
}> = ({
  isPlayground,
  userId,
  projectId,
  projectType,
  projectTitle,
  publishedAt,
  homeView,
  linkTo,
  properties,
  userType,
  deleteProject,
  idLookup,
}) => {
  const { courseNameToBasicMetadata } = useCourseNameToBasicMetadata();

  let isScienceCourse = false;
  try {
    const { courseId } = idLookup[projectId].properties;
    const courseName = idLookup[courseId].properties.name;
    const course = courseNameToBasicMetadata(courseName);
    isScienceCourse = course.subject.name === 'science';
  } catch (err) {
    // pass
  }

  const projectTypeKey = isScienceCourse
    ? 'science_project'
    : projectType
    ? (ProjectTypes[projectType] as string)
    : 'unknown';

  const { icon, displayName, iconBgColor } = projectTypeToRenderInfo(projectTypeKey);

  const link =
    linkTo ||
    (userType === 'teacher' && isPlayground
      ? buildInstructorPlaygroundLink({
          projectId,
          projectType: projectType!,
          projectName: projectTitle,
        })
      : isPlayground
      ? buildPlaygroundLink({
          projectId,
          projectType: projectType!,
          projectName: projectTitle,
          userId,
        })
      : buildNonPlaygroundLink({ userId, projectId }));

  const displayTitle = homeView
    ? projectTitle
    : truncate(projectTitle, { length: 20 });

  return (
    <Link to={link} className="no-underline flex self-auto items-stretch">
      <Card className="font-graphik flex items-center gap-4 w-full" padding="5">
        {/* ICON */}
        <div
          className={`rounded-lg p-3 flex items-center justify-center text-white bg-${iconBgColor}`}
        >
          {React.createElement(icon, { height: 28, width: 28 })}
        </div>

        {/* DISPLAY DETAILS */}
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-col self-center">
            <span className="text-sm text-j-dark-700 font-medium">
              {displayTitle}
            </span>
            <div className="flex items-center">
              <span className="pt-0.5 flex-nowrap whitespace-nowrap pr-2 text-xs uppercase font-semibold text-j-purple-400">
                {displayName}
              </span>
              {publishedAt && (
                <span className="text-sm text-j-dark-300 pl-2">
                  {humanizeTimeAgo(publishedAt)}
                </span>
              )}
            </div>
          </div>

          {/* BADGES */}
          <div className="flex gap-0.5 flex-end">
            {properties?.hasRecording && (
              <Tooltip title="This project has a recording">
                <Camera />
              </Tooltip>
            )}
            {properties?.inProgress && (
              <Tooltip title="In progress">
                <InProgress />
              </Tooltip>
            )}
            {properties?.completed && (
              <Tooltip title="Completed">
                <Complete />
              </Tooltip>
            )}
            {properties?.playgroundProject && (
              <>
                <Tooltip title="Playground Project">
                  <PlaygroundBadge />
                </Tooltip>
                {deleteProject && <DeleteProject deleteProject={deleteProject} />}
              </>
            )}
          </div>
        </div>
      </Card>
    </Link>
  );
};
ProjectCard.defaultProps = { userType: 'student' };

export default ProjectCard;
