import React, { FC, useEffect, useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';
import {
  setDay,
  getHours,
  setHours,
  setMinutes,
  addHours,
  addDays,
  differenceInDays,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc, toDate } from 'date-fns-tz';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { DEFAULT_TIMEZONE } from 'constants/timezones';
import { DateTime } from '../types';

export interface TimeSlotSettings {
  startHourInPT: number;
  numDailySlots: number;
}

interface SchedulingTimeSelectionProps {
  title: string;
  timezone: string;
  selectedDates: DateTime[];
  onChangeSelections: (newSelections: DateTime[]) => void;
  customTimeSlotSettings?: TimeSlotSettings;
}

interface ScheduleOption {
  label: string;
  value: number;
  day: number;
}

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const DEFAULT_TIME_SLOT_SETTINGS = {
  startHourInPT: 7,
  numDailySlots: 12,
};

const makeZonedHours = ({
  userTimezone,
  startHourInPT = DEFAULT_TIME_SLOT_SETTINGS.startHourInPT,
  numDailySlots = DEFAULT_TIME_SLOT_SETTINGS.numDailySlots,
}: {
  userTimezone: string;
  startHourInPT?: number;
  numDailySlots?: number;
}) => {
  const dateInPacificTime = setHours(new Date(), startHourInPT);
  dateInPacificTime.setSeconds(0, 0);
  const UTCDateAtClassStartTime = zonedTimeToUtc(
    dateInPacificTime,
    DEFAULT_TIMEZONE,
  );
  const localDateAtClassStartTime = utcToZonedTime(
    UTCDateAtClassStartTime,
    userTimezone,
  );

  return [...Array(numDailySlots)].map((_, index) => {
    const relativeHour = getHours(addHours(localDateAtClassStartTime, index));
    const hourLabel = (relativeHour > 12 ? relativeHour - 12 : relativeHour) || 12;
    const amOrPM = relativeHour > 11 ? 'PM' : 'AM';

    return {
      label: `${hourLabel}:00 ${amOrPM}`,
      value: relativeHour,
    };
  });
};

const makeFutureDateFromPreference = (
  hour: number,
  dayOfWeek: number,
  timeZone: string,
) => {
  const newDate = toDate(setHours(setDay(new Date(), dayOfWeek), hour), {
    timeZone,
  });
  newDate.setSeconds(0, 0);

  const dateDiff = differenceInDays(newDate, toDate(Date.now(), { timeZone }));

  if (dateDiff < 0) {
    return addDays(newDate, 14);
  }

  return addDays(newDate, 7);
};

const convertBackToLocal = (datetime: Date, timezone: string) =>
  utcToZonedTime(
    zonedTimeToUtc(setMinutes(datetime, 0), timezone),
    guessTimezoneValue(),
  );

const SchedulingTimeSelection: FC<SchedulingTimeSelectionProps> = ({
  title,
  timezone,
  selectedDates,
  onChangeSelections,
  customTimeSlotSettings,
}) => {
  const [selections, setSelections] = useState<ScheduleOption[]>([]);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scollPos, setScollPos] = useState(0);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const options = makeZonedHours({
    userTimezone: timezone,
    ...customTimeSlotSettings,
  });

  const handleChangeSelected = (selections: ScheduleOption[]) => {
    const dateTimeList = selections.length
      ? selections.map(selection => ({
          datetime: convertBackToLocal(
            makeFutureDateFromPreference(selection.value, selection.day, timezone),
            timezone,
          ),
        }))
      : selections;

    // we need to send 3 objects even if there is only one selection
    const listWithEmptyValues = [...dateTimeList, ...Array(2)].map(op => op || {});
    listWithEmptyValues.splice(3, 3); // removing any extra objects
    // if there is at least one selection we send 3 objects, if not we send an empty array to disable the continue button
    onChangeSelections(selections.length ? listWithEmptyValues : []);
  };

  const handleSelection = (selection: ScheduleOption) => {
    const isAlreadySelected = !!selections.find(
      sel => selection.label === sel.label && selection.day === sel.day,
    );

    if (isAlreadySelected) {
      return setSelections(prevState => {
        const newList = prevState.filter(
          option =>
            !(option.day === selection.day && option.label === selection.label),
        );
        handleChangeSelected(newList);
        return newList;
      });
    }

    if (selections.length === 3) {
      return setSelections(prevState => {
        const oldList = [...prevState];
        oldList.shift();
        const newList = [...oldList, selection];
        handleChangeSelected(newList);
        return newList;
      });
    }

    setSelections(prevState => {
      const newList = [...prevState, selection];
      handleChangeSelected(newList);
      return newList;
    });
  };

  useLayoutEffect(() => {
    const setOffset = () => {
      const mainContainerSize = mainContainerRef?.current?.scrollWidth;
      const contentSize = contentRef?.current?.scrollWidth;

      if (mainContainerSize && contentSize) {
        setScrollOffset(mainContainerSize - contentSize);
      }
      setScollPos(0);
    };

    window.addEventListener('resize', setOffset);
    setOffset();

    return () => {
      document.removeEventListener('resize', setOffset);
    };
  }, [mainContainerRef, contentRef]);

  useEffect(() => {
    const selections = selectedDates
      .filter(selection => !!selection.datetime)
      .map(selection => {
        const date = selection.datetime
          ? utcToZonedTime(
              zonedTimeToUtc(new Date(selection.datetime), guessTimezoneValue()),
              timezone ?? guessTimezoneValue(),
            )
          : undefined;
        if (date) date.setSeconds(0, 0);
        const time = new Date(`${date}`);
        const hours = time.getHours();
        const hourLabel = (hours > 12 ? hours - 12 : hours) || 12;
        const amOrPM = hours > 11 ? 'PM' : 'AM';
        const label = `${hourLabel}:00 ${amOrPM}`;
        return {
          day: time.getDay(),
          value: hours,
          label,
        };
      });

    setSelections(selections);
  }, [selectedDates, timezone]);

  const xScroll = (direction: 'left' | 'right') => {
    const incrementValue = 288;

    if (direction === 'left') {
      setScollPos(prevState =>
        prevState - incrementValue <= scrollOffset
          ? scrollOffset
          : prevState - incrementValue,
      );
    }
    if (direction === 'right') {
      setScollPos(prevState =>
        prevState + incrementValue >= 0 ? 0 : prevState + incrementValue,
      );
    }
  };

  return (
    <div className="w-full max-w-full" ref={mainContainerRef}>
      <header className="bg-j-blue-100 p-3 rounded-lg flex">
        <div>
          <Icon.Warning className="text-j-blue-600 w-4 h-4" />
        </div>
        <p className="m-0 text-j-blue-400 text-sm pl-2">
          Select your <strong className="font-medium">top three</strong> 50 minute
          time slots for {title}
        </p>
      </header>
      {!!scrollOffset && (
        <div className="flex space-x-3 pt-6">
          <button
            className="bg-white ignore-juni-globals w-8 h-8 rounded-lg border-2 border-solid border-j-purple-100 flex justify-center items-center hover:border-j-purple-600"
            onClick={() => xScroll('right')}
            style={{ touchAction: 'manipulation' }}
          >
            <Icon.ArrowLeft className="text-j-dark-600 w-5" />
          </button>
          <button
            className="bg-white ignore-juni-globals w-8 h-8 rounded-lg border-2 border-solid border-j-purple-100 flex justify-center items-center hover:border-j-purple-600"
            onClick={() => xScroll('left')}
            style={{ touchAction: 'manipulation' }}
          >
            <Icon.ArrowRight className="text-j-dark-600 w-5" />
          </button>
        </div>
      )}
      <div className="overflow-hidden overflow-x-auto">
        <div
          ref={contentRef}
          className="pr-1 pb-2 box-border relative transition-all"
          style={{ width: '632px', left: `${scollPos}px` }}
        >
          <div className="grid grid-cols-7 gap-4 pt-6">
            {DAYS_OF_WEEK.map((day, dayIndex) => {
              const itemStyles =
                'text-j-dark-600 leading-3 text-center whitespace-nowrap';
              return (
                <ul className="list-none p-0 m-0 space-y-3" key={day}>
                  <li className={`text-sm ${itemStyles}`}>{day}</li>
                  {options.map(op => {
                    const isSelected = !!selections.find(
                      option => op.value === option.value && dayIndex === option.day,
                    );
                    const optionStyles = classNames(
                      itemStyles,
                      'w-20 rounded-lg py-2 text-xs border border-solid',
                      {
                        'bg-j-gray-200 border-j-gray-200 hover:bg-j-purple-200 hover:border-j-purple-200': !isSelected,
                        'bg-j-purple-200 text-j-purple-600 border-j-purple-600': isSelected,
                      },
                    );
                    return (
                      <li key={op.label}>
                        <button
                          className={optionStyles}
                          onClick={() => handleSelection({ ...op, day: dayIndex })}
                        >
                          {op.label}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulingTimeSelection;
