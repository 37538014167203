import React, { FC, useState } from 'react';
import _ from 'lodash';
import { Badge, Icon, Message, NewButton } from 'core-components';

import {
  CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME,
  CORE_PRICES,
  CORE_PRODUCT_METADATA_KEYS,
  JUNI_JR_PRICES,
  METADATA_KEY,
  USACO_PRICES,
} from 'constants/subscription_plans';
import { getShortDisplayNameFromCourses } from 'utils/courses';
import { getTotalCoreFrequency } from 'app/learner/LearnerAccountV2/utils';

import { GetCoursesReturnFragment } from 'generated/graphql';
import { DateTime } from 'components/SchedulingSelection/types';
import SchedulingSelection from 'components/SchedulingSelection';
import { JUNI_EMAILS } from 'constants/contact_information';
import { SubscriptionData, SubscriptionMetaDataWithCourse } from '../../../types';
import EditTutoringSubjectModal from '../TutoringModals/EditTutoringSubjectModal';

const DISABLED_SUBJECT_TO_UPDATE = [METADATA_KEY.juni_jr, METADATA_KEY.usaco];

interface Props {
  subjectKey: string;
  subjectSubscriptionDetails: SubscriptionData;
  courses: GetCoursesReturnFragment[];
  updatedSubscription: SubscriptionMetaDataWithCourse;
  setUpdatedSubscription: React.Dispatch<
    React.SetStateAction<SubscriptionMetaDataWithCourse>
  >;
  currentSubscription: SubscriptionMetaDataWithCourse;
  timezone: string;
  i: number;
}

const SubjectRow: FC<Props> = ({
  subjectKey,
  subjectSubscriptionDetails,
  courses,
  updatedSubscription,
  setUpdatedSubscription,
  currentSubscription,
  timezone,
  i,
}) => {
  const [isEditingTutoringSubject, setIsEditingTutoringSubject] = useState(false);
  const subjectDisplayName = CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[subjectKey];
  const newSubscriptionFrequency =
    updatedSubscription[subjectKey]?.weeklyFrequency || 0;
  const currentSubscriptionFrequency =
    currentSubscription[subjectKey]?.weeklyFrequency || 0;

  // increment button enabled based on the max amount of class you can have per subject
  let incrementButtonEnabled = false;
  if (subjectKey === METADATA_KEY.usaco) {
    const maxUSACOClassWeeklyFrequency =
      _.max(Object.keys(USACO_PRICES).map(key => Number(key))) || 0;
    incrementButtonEnabled = newSubscriptionFrequency < maxUSACOClassWeeklyFrequency;
  }
  if (subjectKey === METADATA_KEY.juni_jr) {
    const maxJuniJrClassWeeklyFrequency =
      _.max(Object.keys(JUNI_JR_PRICES).map(key => Number(key))) || 0;
    incrementButtonEnabled =
      newSubscriptionFrequency < maxJuniJrClassWeeklyFrequency;
  }

  if (CORE_PRODUCT_METADATA_KEYS.includes(subjectKey)) {
    // currently we aggregate the total frequency of both Tutoring & Core subjects
    // for the purposes of selection, ignore any biweekly frequency
    const totalSelectedCoreFrequency = getTotalCoreFrequency(updatedSubscription);
    const maxCoreClassWeeklyFrequency =
      _.max(Object.keys(CORE_PRICES).map(key => Number(key))) || 0;
    incrementButtonEnabled =
      totalSelectedCoreFrequency < maxCoreClassWeeklyFrequency;
  }

  // TO DO FIX - decrease
  const decrementButtonEnabled =
    !currentSubscription[subjectKey] ||
    newSubscriptionFrequency > currentSubscriptionFrequency;

  const isNewSubject = !Object.keys(currentSubscription).includes(subjectKey);

  const isIncreasedFrequency =
    !currentSubscription[subjectKey] ||
    newSubscriptionFrequency > currentSubscriptionFrequency;

  const existingSubject = subjectKey in currentSubscription;

  const increasedFrequency = Math.round(
    newSubscriptionFrequency - currentSubscriptionFrequency,
  );
  // max out at 15 so it's easier for parents
  const numSelectionsRequired = increasedFrequency < 5 ? increasedFrequency * 3 : 15;

  const classTimePreference = subjectSubscriptionDetails.timePreferences?.[0];

  return (
    <div key={i} className="flex flex-col gap-1.5 w-full">
      {/* ROW */}
      {DISABLED_SUBJECT_TO_UPDATE.includes(subjectKey) && (
        <Message status="info">
          Please contact {JUNI_EMAILS.support} to update your subscription for{' '}
          {subjectDisplayName}.
        </Message>
      )}
      <div className="flex flex-row w-full justify-between items-center">
        {/* SUBJECT: COURSE */}
        <div className="flex flex-col sm:flex-row gap-1 items-start w-full">
          <div>
            <div className="flex flex-row items-center gap-1 font-medium text-sm sm:text-base">
              {existingSubject &&
                currentSubscriptionFrequency !== newSubscriptionFrequency && (
                  <Badge
                    backgroundColor="j-orange-100"
                    color="j-orange-600"
                    size="regular"
                  >
                    Updated
                  </Badge>
                )}
              {!existingSubject && (
                <Badge
                  backgroundColor="j-green-100"
                  color="j-green-600"
                  size="regular"
                >
                  New
                </Badge>
              )}
              <span>{subjectDisplayName}: </span>
            </div>
            {(isNewSubject || isIncreasedFrequency) &&
              subjectKey === METADATA_KEY.tutoring && (
                <div>
                  <span className="text-xs italic text-j-dark-400">
                    Requested Subject/Topics:{' '}
                    {subjectSubscriptionDetails.notes
                      ? subjectSubscriptionDetails.notes
                      : 'Current Tutoring Subject'}
                  </span>
                  <span
                    onClick={() => setIsEditingTutoringSubject(true)}
                    className="text-j-purple-400 text-xs uppercase pl-2 cursor-pointer"
                  >
                    Edit
                  </span>
                </div>
              )}
          </div>

          <div className="text-sm sm:text-base">
            {getShortDisplayNameFromCourses(
              courses,
              subjectSubscriptionDetails.courses,
            )}
          </div>
        </div>
        {/* FREQ ADJUSTMENTS */}
        <div className="flex flex-row items-center border border-j-dark-500">
          <NewButton
            variant="secondary"
            size="small"
            disabled={
              !decrementButtonEnabled ||
              DISABLED_SUBJECT_TO_UPDATE.includes(subjectKey)
            }
            // Currently upgrades does not support 0.5 frequency as we don't have pricing for x.5
            onClick={() => {
              if (updatedSubscription[subjectKey].weeklyFrequency === 1) {
                setUpdatedSubscription(_.omit(updatedSubscription, subjectKey));
                return;
              }
              const newWeeklyFrequency = newSubscriptionFrequency - 1;
              if (
                currentSubscription[subjectKey] &&
                newWeeklyFrequency ===
                  currentSubscription[subjectKey].weeklyFrequency
              ) {
                // remove time preference
                setUpdatedSubscription({
                  ...updatedSubscription,
                  [subjectKey]: {
                    weeklyFrequency: newWeeklyFrequency,
                    courses: updatedSubscription[subjectKey].courses,
                  },
                });
              } else {
                const newClassTimePreference = classTimePreference?.slice(
                  0,
                  (newWeeklyFrequency - currentSubscriptionFrequency) * 3,
                );
                setUpdatedSubscription({
                  ...updatedSubscription,
                  [subjectKey]: {
                    ...updatedSubscription[subjectKey],
                    weeklyFrequency: newWeeklyFrequency,
                    timePreferences:
                      increasedFrequency > 5
                        ? ([classTimePreference] as DateTime[][])
                        : ([newClassTimePreference] as DateTime[][]),
                  },
                });
              }
            }}
          >
            <span className="font-semibold text-lg h-7">–</span>
          </NewButton>
          <div className="px-1 sm:px-2 text-sm sm:text-base w-full min-w-max">
            {subjectSubscriptionDetails.weeklyFrequency === 0.5 ? (
              <div className="flex flex-row gap-1">
                <span>2x</span>
                <span className="sm:hidden block"> /mo</span>
                <span className="sm:block hidden"> per month</span>
              </div>
            ) : (
              <div className="flex flex-row gap-1">
                <span>{Number(subjectSubscriptionDetails.weeklyFrequency)}x</span>
                <span className="sm:hidden block"> /wk</span>
                <span className="sm:block hidden"> per week</span>
              </div>
            )}
          </div>
          <NewButton
            variant="secondary"
            size="small"
            disabled={
              !incrementButtonEnabled ||
              DISABLED_SUBJECT_TO_UPDATE.includes(subjectKey)
            }
            onClick={() => {
              const newWeeklyFrequency =
                newSubscriptionFrequency >= 1 ? newSubscriptionFrequency + 1 : 1;
              setUpdatedSubscription({
                ...updatedSubscription,
                [subjectKey]: {
                  ...updatedSubscription[subjectKey],
                  weeklyFrequency: newWeeklyFrequency,
                  timePreferences:
                    updatedSubscription[subjectKey].timePreferences ??
                    ([[]] as DateTime[][]),
                },
              });
            }}
          >
            <span className="font-semibold text-lg h-7">+</span>
          </NewButton>
        </div>
        {isIncreasedFrequency}
      </div>
      {/* COURSE TIME SELECTOR */}
      {(isNewSubject || isIncreasedFrequency) && classTimePreference && (
        <>
          <label className="font-medium text-sm">
            Select {isNewSubject ? 'New' : 'Additional'} Class Time Preference{' '}
            <span
              className={
                classTimePreference.length === numSelectionsRequired
                  ? 'text-j-dark-500'
                  : 'text-j-pink-600'
              }
            >
              ({classTimePreference.length}/{numSelectionsRequired})
            </span>
          </label>
          <SchedulingSelection
            timeZone={timezone}
            onSave={list => {
              const newTimePreferences = updatedSubscription[subjectKey]
                .timePreferences || [[]];
              newTimePreferences[0] = list;
              setUpdatedSubscription({
                ...updatedSubscription,
                [subjectKey]: {
                  ...updatedSubscription[subjectKey],
                  timePreferences: newTimePreferences,
                },
              });
            }}
            prefIndex={i}
            selections={classTimePreference}
            title={CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[subjectKey]}
            numSelectionsRequired={numSelectionsRequired}
          />
          {classTimePreference && classTimePreference.length < 1 ? (
            <div className="flex flex-row items-center text-sm gap-1 text-j-pink-600">
              <Icon.Info />
              Please select your class schedule preference.
            </div>
          ) : classTimePreference.length < numSelectionsRequired ? (
            <div className="flex flex-row items-center text-sm gap-1 text-j-pink-600">
              <Icon.Info />
              Please select {numSelectionsRequired - classTimePreference.length} more
              class schedule preference.
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      <EditTutoringSubjectModal
        subjectKey={subjectKey}
        updatedSubscription={updatedSubscription}
        setUpdatedSubscription={setUpdatedSubscription}
        isEditingTutoringSubject={isEditingTutoringSubject}
        setIsEditingTutoringSubject={setIsEditingTutoringSubject}
      />
    </div>
  );
};
export default SubjectRow;
