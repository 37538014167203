import { Icon } from 'core-components';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { CALENDLY_LINKS } from 'constants/signup_sessions';
import { useSignupData } from 'app/signup_2023/hooks';
import { ErrorableLoading } from 'components/ui';
import { containerStyles } from './constants';

const ScheduleOnboardingCall = () => {
  const { signupData, updateSignup, loading, error } = useSignupData();
  const { parent } = signupData || {};

  // Calendly Book Call Event & Params
  useCalendlyEventListener({
    onEventScheduled: e => {
      updateSignup(
        {
          events: {
            onboardingCallUriFromSignupConfirmation: e.data.payload.event.uri,
          },
        },
        false,
      );
    },
  });

  if (!signupData || error || loading) {
    return <ErrorableLoading error={error} />;
  }
  const { firstName, lastName, email, phone } = parent || {};
  const learningPreferences = signupData.students?.[0]?.learningPreferences;

  if (!phone || !email) return null;

  return (
    <div className={`${containerStyles} items-center`}>
      <div className="text-j-blue-400 mb-4">
        <Icon.Info height={78} width={78} />
      </div>
      <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2 text-center">
        One last thing...
      </h1>
      <p className="text-j-dark-300 m-0 pb-2 text-base text-center">
        Before we let you go, we'd love to speak with you to discuss in detail your
        child's learning style, personality, and learning goals. We've hopefully
        provided all of the information you need in our welcome email, but we can
        also answer any additional questions you have!
      </p>
      <InlineWidget
        pageSettings={{ hideEventTypeDetails: true }}
        url={CALENDLY_LINKS.PARENT_ADVISOR}
        prefill={{
          email,
          name: `${firstName} ${lastName}`,
          customAnswers: {
            a1: phone,
            a2: learningPreferences ?? '--',
          },
        }}
      />
    </div>
  );
};

export default ScheduleOnboardingCall;
