import React, { useState } from 'react';
import WarningBlock from 'components/WarningBlock/WarningBlock';
import { Icon, NewModalWindow } from 'core-components';

const DeleteProject: React.FC<{ deleteProject: () => void }> = ({
  deleteProject,
}) => {
  const stopPropagationShorthand = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault(); // prevents the click from bubbling to <Link /> component in ProjectCard
    e.stopPropagation(); // Prevents the click from triggering the close badge function on badge container
  };
  const [modalIsOpen, toggleModalIsOpen] = useState(false);

  const onDelete = () => {
    deleteProject();
    toggleModalIsOpen(false);
  };
  return (
    <div onClick={stopPropagationShorthand}>
      <div
        className="flex items-center text-j-dark-400 p-0.5"
        onClick={() => toggleModalIsOpen(true)}
      >
        <Icon.Trash />
      </div>
      {modalIsOpen && (
        <NewModalWindow
          closeModal={() => toggleModalIsOpen(false)}
          isOpen={modalIsOpen}
        >
          <div
            onClick={stopPropagationShorthand}
            className="delete-modal-body__container"
          >
            <WarningBlock
              cancelWarning={() => toggleModalIsOpen(false)}
              completeWarning={onDelete}
              headerText="Are you sure you wish to delete this project?"
              subHeaderText="This action cannot be reversed!"
              primaryActionText="Delete"
            />
          </div>
        </NewModalWindow>
      )}
    </div>
  );
};

export default DeleteProject;
