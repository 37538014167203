import PortalSection from 'components/PortalSection';
import { NewCard } from 'core-components';
import { Parent } from 'models';

interface AccountInformationSection {
  isGuest: boolean;
  parent: Parent;
}

const AccountInformationSection = ({
  isGuest,
  parent,
}: AccountInformationSection) => (
  <PortalSection name="Account Information">
    <div className="flex flex-col gap-1">
      <NewCard padding="0">
        <div className="text-xl font-semibold px-6 py-4">Personal Information</div>
        <NewCard.Divider />
        <div className="flex flex-col gap-6 p-6">
          {isGuest ? (
            <div className="flex flex-col">
              <div className="font-semibold mb-1">Email</div>
              <div className="text-j-dark-300">{parent.email}</div>
            </div>
          ) : (
            <>
              <div className="flex flex-col">
                <div className="font-semibold mb-1">Name</div>
                <div className="text-j-dark-300">
                  {parent.firstName} {parent.lastName}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-semibold mb-1">Email</div>
                <div className="text-j-dark-300">{parent.email}</div>
              </div>
              <div className="flex flex-col">
                <div className="font-semibold mb-1">Phone</div>
                <div className="text-j-dark-300">{parent.phone}</div>
              </div>
            </>
          )}
        </div>
      </NewCard>
    </div>
  </PortalSection>
);

export default AccountInformationSection;
