import { JUNI_EMAILS } from 'constants/contact_information';
import { Message } from 'core-components';
import { Link } from 'react-router-dom';

const ExistingEmailMessage = () => (
  <Message status="info">
    This email address has already been used to register your family. To add
    additional students or make any changes to your enrollment, please visit{' '}
    <Link to="/learner/account">My Account</Link> or reach out to{' '}
    {JUNI_EMAILS.support}.
  </Message>
);

export default ExistingEmailMessage;
