import memoize from 'memoize-one';
import moment from 'moment-timezone';

import { ModuleSectionProgress } from 'models';

import { COURSE_TYPES } from 'constants/subjects';

import { Topic } from './types';

interface Args {
  moduleSectionProgresses: ModuleSectionProgress[];
  teacherSessionStart: string;
  teacherSessionEnd: string;
  moduleSectionInfo: any;
  teacherMode: boolean;
  idLookup: any;
}

export const getMemoizedTopicsCovered: (args: Args) => Topic[] = memoize(args => {
  const {
    moduleSectionProgresses,
    teacherSessionStart,
    teacherSessionEnd,
    moduleSectionInfo,
    teacherMode,
    idLookup,
  } = args;

  if (!teacherSessionEnd || !teacherSessionStart) {
    return [];
  }

  const getTopicInfo = (progress: ModuleSectionProgress, completed: boolean) => {
    const { moduleSectionId, studentId } = progress;
    const {
      displayName: sectionDisplayName,
      properties,
      moduleName,
    } = moduleSectionInfo[moduleSectionId];

    const { courseId } = idLookup?.[moduleSectionId]?.properties || {};
    const sectionType =
      properties?.sectionType || moduleSectionInfo[moduleSectionId].sectionType;
    const courseType = idLookup?.[courseId]?.properties?.courseType;

    const isProblemSet = sectionType === 'problem_set';

    const showProjectLink =
      courseType &&
      (properties?.isProject ||
        ([COURSE_TYPES.ENGLISH, COURSE_TYPES.INVESTING].includes(courseType) &&
          [
            'reading_project',
            'writing_project',
            'question_set',
            'activity',
          ].includes(sectionType)) ||
        (courseType === COURSE_TYPES.MATHEMATICS && isProblemSet));

    const includeModuleName =
      isProblemSet ||
      sectionType === 'concept' ||
      [COURSE_TYPES.ENGLISH, COURSE_TYPES.INVESTING].includes(courseType);
    const displayName = includeModuleName
      ? `${moduleName} ${sectionDisplayName}`
      : sectionDisplayName;

    const getLink = () =>
      !teacherMode
        ? `/learner/${studentId}/my_courses/project/${moduleSectionId}`
        : '';

    const linkHref = showProjectLink ? getLink() : '';
    const linkText = linkHref
      ? `View ${
          courseType === COURSE_TYPES.MATHEMATICS
            ? 'Problem Set'
            : sectionType === 'activity'
            ? 'Activity'
            : 'Project'
        }`
      : '';

    return {
      completed,
      moduleSectionId,
      displayName,
      linkHref,
      linkText,
    };
  };

  const sessionStart = moment(teacherSessionStart);
  const sessionEnd = moment(teacherSessionEnd);

  const completed = moduleSectionProgresses
    .filter(m => m.isCompleted)
    .sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp)))
    .filter(
      progress =>
        moment(progress.timestamp).isAfter(sessionStart) &&
        moment(progress.timestamp).isBefore(sessionEnd) &&
        moduleSectionInfo[progress.moduleSectionId],
    );

  const inProgress = moduleSectionProgresses
    .filter(m => m.inProgressTimestamp)
    .sort((a, b) =>
      moment(a.inProgressTimestamp!).diff(moment(b.inProgressTimestamp!)),
    )
    .filter(
      progress =>
        moment(progress.inProgressTimestamp!).isAfter(sessionStart) &&
        moment(progress.inProgressTimestamp!).isBefore(sessionEnd) &&
        moduleSectionInfo[progress.moduleSectionId] &&
        completed.findIndex(
          completedProgress =>
            completedProgress.moduleSectionId === progress.moduleSectionId,
        ) === -1,
    );

  return [
    ...completed.map(progress => getTopicInfo(progress, true)),
    ...inProgress.map(progress => getTopicInfo(progress, false)),
  ];
});
