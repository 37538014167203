import React, { FC } from 'react';
import { NewButton } from 'core-components';
import { hasAsync } from 'utils/stripe';
import { Parent } from 'models/parent';
import LearnerPortalModal from '../LearnerPortalModal';
import { FormState, FormValues } from '../types';
import { CANCELLATION_REASONS } from './constants';

interface Props {
  parent: Parent;
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
}

const CancelConfirmationModal: FC<Props> = ({
  parent,
  formState,
  updateFormState,
  formValues,
}) => {
  const { subscription, cancellationReasons } = formValues;
  const productIds = subscription?.items.data.map(
    subscriptionItem => subscriptionItem.plan.product,
  );

  const studentIsOnlyTakingAsyncCourses =
    productIds && productIds.length === 1 && hasAsync(subscription);

  return (
    <LearnerPortalModal
      title={
        cancellationReasons.includes(CANCELLATION_REASONS.pausingSubscription)
          ? 'Paused Confirmed'
          : 'Cancellation Confirmed'
      }
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            updateFormState('', 'modal');
          }}
        >
          Close
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 leading-5 gap-4">
        <div>
          Your{' '}
          {cancellationReasons.includes(CANCELLATION_REASONS.pausingSubscription)
            ? 'pause'
            : 'cancellation'}{' '}
          is being processed and here's what happens next:{' '}
        </div>
        <div className="text-j-dark-400">
          • We will review the information you submitted to make sure everything was
          filled out correctly.
        </div>
        <div className="text-j-dark-400">
          {`• We've sent you an email at ${parent.email} to confirm your changes. If you have any questions, you can email us at `}
          <a href="mailto:support@learnwithjuni.com">support@learnwithjuni.com</a>
          {' or give us a call at (650) 263-4306.'}
        </div>
        <div className="text-j-dark-400">
          {' '}
          • Your subscription will be ended at the end of your next billing cycle.{' '}
        </div>
        {!studentIsOnlyTakingAsyncCourses && (
          <div className="text-j-dark-400">
            • If you selected a Last Session Date earlier than your Effective
            Subscription Cancellation Date, those sessions will be cancelled and
            credited as makeup classes.{' '}
          </div>
        )}
        <div className="text-j-dark-400">
          • If you indicated that you are pausing your subscription, we will reach
          out in advance of your chosen return date to re-initiate your subscription.
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default CancelConfirmationModal;
