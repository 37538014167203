export const SUBJECTS = {
  COMPUTER_SCIENCE: 'computer_science',
  MATHEMATICS: 'mathematics',
  ENGLISH: 'english',
  INVESTING: 'investing',
  SCIENCE: 'science',
} as const;

export const SUBJECT_DISPLAY_NAMES = {
  [SUBJECTS.COMPUTER_SCIENCE]: 'Computer Science',
  [SUBJECTS.MATHEMATICS]: 'Mathematics',
  [SUBJECTS.ENGLISH]: 'English',
  [SUBJECTS.INVESTING]: 'Investing & Entrepreneurship',
  [SUBJECTS.SCIENCE]: 'Science',
} as const;

export const ORDERED_SUBJECTS = [
  SUBJECTS.COMPUTER_SCIENCE,
  SUBJECTS.MATHEMATICS,
  SUBJECTS.ENGLISH,
  SUBJECTS.INVESTING,
  SUBJECTS.SCIENCE,
] as const;

export type SUBJECT_TYPE = typeof ORDERED_SUBJECTS[number];

// Ultimate goal is to replace all 'courseType' fields / variables with 'subject' fields which use the above subject strings as the values.
// For now, the keys in this COURSE_TYPES mapping must identically match the keys used in the SUBJECTS mapping above
export const COURSE_TYPES = {
  COMPUTER_SCIENCE: 'code',
  MATHEMATICS: 'math',
  ENGLISH: 'english',
  INVESTING: 'investing',
  SCIENCE: 'science',
} as const;

export const SUBJECT_COURSE_TYPES: { [subject in SUBJECT_TYPE]: string } = {
  [SUBJECTS.COMPUTER_SCIENCE]: COURSE_TYPES.COMPUTER_SCIENCE,
  [SUBJECTS.MATHEMATICS]: COURSE_TYPES.MATHEMATICS,
  [SUBJECTS.ENGLISH]: COURSE_TYPES.ENGLISH,
  [SUBJECTS.INVESTING]: COURSE_TYPES.INVESTING,
  [SUBJECTS.SCIENCE]: COURSE_TYPES.SCIENCE,
} as const;

export const COURSE_TYPE_SUBJECTS = {
  [COURSE_TYPES.COMPUTER_SCIENCE]: SUBJECTS.COMPUTER_SCIENCE,
  [COURSE_TYPES.MATHEMATICS]: SUBJECTS.MATHEMATICS,
  [COURSE_TYPES.ENGLISH]: SUBJECTS.ENGLISH,
  [COURSE_TYPES.INVESTING]: SUBJECTS.INVESTING,
  [COURSE_TYPES.SCIENCE]: SUBJECTS.SCIENCE,
} as const;

export const SUBJECT_TO_METADATA_KEY: {
  [subject in SUBJECT_TYPE | 'science']: string;
} = {
  [SUBJECTS.COMPUTER_SCIENCE]: 'cs_weeklyFrequency',
  [SUBJECTS.MATHEMATICS]: 'math_weeklyFrequency',
  [SUBJECTS.ENGLISH]: 'english_weeklyFrequency',
  [SUBJECTS.INVESTING]: 'investing_weeklyFrequency',
  [SUBJECTS.SCIENCE]: 'science_weeklyFrequency',
};

// Subjects that are rendered as curriculum editors in admin home
export const CURRICULUM_EDITOR_SUBJECTS = [
  SUBJECTS.COMPUTER_SCIENCE,
  SUBJECTS.MATHEMATICS,
  SUBJECTS.ENGLISH,
  SUBJECTS.INVESTING,
];
export const CURRICULUM_EDITOR_DISPLAY_NAMES = {
  ...SUBJECT_DISPLAY_NAMES,
  [SUBJECTS.INVESTING]: 'Investing & Science',
};

export const SUBJECTS_WITH_THEIR_OWN_CURRICULUM_EDITORS = [
  SUBJECTS.COMPUTER_SCIENCE,
  SUBJECTS.MATHEMATICS,
  SUBJECTS.ENGLISH,
];

export const CURRICULUM_COLLECTIONS = {
  CurriculumCourse: 'CurriculumCourse',
  MathCurriculumCourse: 'MathCurriculumCourse',
  EnglishCurriculumCourse: 'EnglishCurriculumCourse',
  InvestingCurriculumCourse: 'InvestingCurriculumCourse',
};

export const SUBJECT_TO_CURRICULUM_COLLECTION: Record<string, string> = {
  [SUBJECTS.COMPUTER_SCIENCE]: CURRICULUM_COLLECTIONS.CurriculumCourse,
  [SUBJECTS.MATHEMATICS]: CURRICULUM_COLLECTIONS.MathCurriculumCourse,
  [SUBJECTS.ENGLISH]: CURRICULUM_COLLECTIONS.EnglishCurriculumCourse,
  [SUBJECTS.INVESTING]: CURRICULUM_COLLECTIONS.InvestingCurriculumCourse,
};
