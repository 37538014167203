import React, { ChangeEvent, FC, useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { Input } from 'core-components';
import { dateToShortIsoString } from 'app/learner/LearnerAccountV2/utils';
import { format } from 'date-fns';
import { ONBOARDING_START_DATE_BUFFER_DAYS } from 'constants/onboarding_handoff_buffer';

interface Props {
  timezone: string;
  startingDate: string;
  setStartingDate: React.Dispatch<React.SetStateAction<string>>;
  firstAvailableDate: Date;
  isAsyncAdded: boolean;
}

const StartingDateInput: FC<Props> = ({
  timezone,
  startingDate,
  setStartingDate,
  firstAvailableDate,
  isAsyncAdded,
}) => {
  const [dateError, setDateError] = useState('');
  const firstAvailableDateString = dateToShortIsoString(firstAvailableDate) ?? '';

  return (
    <div className="w-full">
      <label className="text-j-dark-600 text-sm font-medium m-0 pb-1">
        Starting Date
      </label>
      {dateError && <div className="text-j-pink-700 text-sm pb-2">{dateError}</div>}
      <div className="text-j-dark-400 text-sm pb-2">
        We'll need a few days to adjust this for you. After that, when you start is
        up to you!
      </div>
      <Input
        type="date"
        size="small"
        fullWidth
        value={dateToShortIsoString(startingDate)}
        min={firstAvailableDateString}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const selectedDate = utcToZonedTime(e.target.value, timezone);
          if (selectedDate.getTime() < firstAvailableDate.getTime()) {
            setDateError(
              `We need at least ${ONBOARDING_START_DATE_BUFFER_DAYS} days from today to set up your classes.`,
            );
          } else {
            setDateError('');
          }
          setStartingDate(format(selectedDate, 'yyyy-MM-dd'));
        }}
      />
      {isAsyncAdded && (
        <span className="mt-1 font-normal text-xs italic text-j-dark-300 flex items-center">
          This date does not apply to the On Demand Library, you will get access to
          it immediately.
        </span>
      )}
    </div>
  );
};
export default StartingDateInput;
