import React, { FC, useState } from 'react';
import ModalWindow, {
  ModalButtonsFooter,
} from 'core-components/NewModalWindow/NewModalWindow';
import {
  useCancelPrivateClassLastMinuteWithoutMakeupMutation,
  useCancelClassForMakeupSufficientlyInAdvanceMutation,
} from 'generated/graphql';
import { Message, NewButton } from 'core-components';
import { JuniLogo } from 'components/brand-assets';
import SpinnerV2 from 'components/SpinnerV2';
import { parseError } from 'utils/errors';

interface Props {
  isCancelModalOpen: boolean;
  closeCancelModal: () => void;
  formattedApptDatetime: string;
  studentFirstName: string;
  studentId: string;
  acuityAppointmentId: number;
  isLastMinuteCancellation?: boolean;
  refreshUpcomingSessions: () => void;
}

const ClassCancellationModal: FC<Props> = ({
  isCancelModalOpen,
  closeCancelModal,
  formattedApptDatetime,
  studentFirstName,
  studentId,
  acuityAppointmentId,
  isLastMinuteCancellation,
  refreshUpcomingSessions,
}) => {
  const [isCancelSuccessful, setIsCancelSuccessful] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleCloseModal = () => {
    closeCancelModal();
    setIsCancelSuccessful(false);
    setError(undefined);
  };

  const [
    cancelPrivateClassLastMinuteWithoutMakeup,
    { loading },
  ] = useCancelPrivateClassLastMinuteWithoutMakeupMutation();
  const handleCancelImmediateUpcomingClassWithoutMakeup = async () => {
    if (loading) return;
    try {
      await cancelPrivateClassLastMinuteWithoutMakeup({
        variables: {
          input: {
            studentId,
            appointmentId: acuityAppointmentId,
          },
        },
      });
      refreshUpcomingSessions();
      setIsCancelSuccessful(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.toString());
      } else {
        setError(parseError(err));
        console.log('Unexpected error', err);
      }
    }
  };

  const [
    cancelClassForMakeupSufficientlyInAdvance,
    { data, loading: suffientCancelLoading },
  ] = useCancelClassForMakeupSufficientlyInAdvanceMutation();
  const handleCancelClassForMakeupSufficientlyInAdvance = async () => {
    try {
      const result = await cancelClassForMakeupSufficientlyInAdvance({
        variables: {
          input: {
            studentId,
            appointmentId: acuityAppointmentId,
          },
        },
      });
      if (!result.data?.cancelClassForMakeupSufficientlyInAdvance.success) {
        alert(
          `${studentFirstName}'s session on ${formattedApptDatetime} was canceled successfully! It may take a day for their makeup class to show up here.`,
        );
      }
      setIsCancelSuccessful(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.toString());
      } else {
        setError(parseError(err));
        console.log('Unexpected error', err);
      }
    }
  };

  const ConfirmationSelectorFooter = (
    <ModalButtonsFooter
      primary={
        <NewButton
          onClick={() =>
            isLastMinuteCancellation
              ? handleCancelImmediateUpcomingClassWithoutMakeup()
              : handleCancelClassForMakeupSufficientlyInAdvance()
          }
          disabled={loading || suffientCancelLoading}
        >
          <div className="font-medium">
            {loading || suffientCancelLoading ? (
              <SpinnerV2 size={12} />
            ) : (
              'Confirm Cancellation'
            )}
          </div>
        </NewButton>
      }
      secondary={
        <NewButton variant="secondary" onClick={handleCloseModal}>
          <div className="font-medium">Cancel</div>
        </NewButton>
      }
    />
  );

  const CompleteFooter = (
    <ModalButtonsFooter
      primary={
        <NewButton variant="secondary" onClick={handleCloseModal}>
          <div className="font-medium">Go to Calendar</div>
        </NewButton>
      }
    />
  );

  return (
    <>
      <ModalWindow
        isOpen={isCancelModalOpen}
        closeModal={handleCloseModal}
        title={
          isCancelSuccessful ? '' : `Cancel Upcoming Class for ${studentFirstName}`
        }
        contentMaxHeight="80vh"
        renderFooter={() =>
          isCancelSuccessful ? CompleteFooter : ConfirmationSelectorFooter
        }
      >
        {isCancelSuccessful ? (
          <div className="flex flex-col w-full items-center p-4">
            <div className="py-10 px-7 rounded-full bg-j-dark-100 mb-7">
              <JuniLogo size="md" />
            </div>
            <div className="text-j-dark-600 text-base font-medium mb-3 text-center">
              Class Canceled!
              {!isLastMinuteCancellation &&
                (data?.cancelClassForMakeupSufficientlyInAdvance.success ? (
                  <p>A makeup class has been granted to your account.</p>
                ) : (
                  <p>A makeup class will be granted to your account shortly.</p>
                ))}
            </div>
            <div className="text-j-dark-300 text-sm mb-3">
              <div className="mb-2">
                You've canceled a session for {studentFirstName} on:
              </div>
              <Message status="success">{formattedApptDatetime}</Message>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 text-j-dark-600 text-base">
            {error && <Message status="error">{error}</Message>}
            {isLastMinuteCancellation && (
              <Message status="warning">
                Since this class is less than 24 hours away,{' '}
                <span className="font-medium">
                  you are not eligible for a makeup session.
                </span>
              </Message>
            )}
            <p>
              Are you sure you want to proceed with canceling the upcoming class on{' '}
              {formattedApptDatetime} for {studentFirstName}?
            </p>
          </div>
        )}
      </ModalWindow>
    </>
  );
};

export default ClassCancellationModal;
