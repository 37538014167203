import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { QueryResult } from '@apollo/client';
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import {
  Course,
  CourseSchedulingFormat,
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  TagType,
  useGetCoursesQuery,
  useLoadDefaultPaymentInformationByParentIdQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
  useLoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import emptyState from 'images/empty_state.png';

import { ErrorableLoading } from 'components/ui';
import { NewButton } from 'core-components';
import {
  CourseCard,
  SelectButtonDropdown,
  SelectCheckboxDropdown,
  UpgradeModal,
} from './components';
import { sortFunctions, sortTypes, SORT_TYPE } from './Helper/sort';
import {
  ageOptions,
  constructAgeLabelText,
  defaultStringLabelText,
  filteredVariables,
  topicOptionsByCourses,
} from './Helper/filterHelpers';

interface Props {
  studentId: string;
  parentId: string;
}

const OnDemandLibrary: React.FC<Props> = ({ studentId, parentId }) => {
  const [currentCourse, setCurrentCourse] = useState<Course | undefined>(undefined);
  const [query, setQuery] = useQueryParams({
    topic: ArrayParam,
    age: ArrayParam,
    sortBy: withDefault(StringParam, sortTypes[0]),
  });
  const coursesQuery = useGetCoursesQuery({
    variables: {
      input: {
        isAcceptingEnrollment: true,
      },
    },
  });
  const {
    data: courseData,
    loading: loadingCourseData,
    error: errorCourseData,
  } = coursesQuery;

  const subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery> = useLoadStripeSubscriptionsByParentIdQuery(
    {
      variables: { id: parentId },
      notifyOnNetworkStatusChange: true,
    },
  );
  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: parentId },
  });
  const studentsQuery: QueryResult<LoadStudentsByParentIdsQuery> = useLoadStudentsByParentIdsQuery(
    {
      variables: { ids: [parentId] },
      notifyOnNetworkStatusChange: true,
    },
  );

  const { topic: rawTopics, age: rawAges, sortBy } = query;
  const courses = useMemo(
    () =>
      (courseData?.getCourses.filter(
        course => course.schedulingFormat === CourseSchedulingFormat.OnDemand,
      ) || []) as Course[],
    [courseData?.getCourses],
  );
  const student = studentsQuery.data?.studentsByParentIds?.items.find(
    student => student._id === studentId,
  );

  useEffect(() => {
    async function fetchSubscription() {
      try {
        await subscriptionsQuery.refetch();
      } catch (err) {
        console.log(err);
      }
    }
    fetchSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = useCallback(
    (name, option) => {
      setQuery({ [name]: option }, 'replaceIn');
    },
    [setQuery],
  );

  // clear all filters
  const handleClearFilters = () => {
    setQuery({ topic: undefined, age: undefined }, 'replaceIn');
  };

  const topicOptions = topicOptionsByCourses(courses);
  // query type is (string | null)[] | null | undefined so we need to convert to string[] using filter variable
  const selectedTopics = filteredVariables(rawTopics, topicOptions);
  const selectedAges = filteredVariables(rawAges, ageOptions);

  const sortOptions = sortTypes.map(value => ({
    value,
    label: value,
  }));

  const filteredCourses = useMemo(
    () =>
      courses.filter(course => {
        // filter by topics
        if (selectedTopics?.length > 0) {
          let satisfiesSubjectFilter = false;
          if (
            course.subject.name &&
            (course.tags || []).some(
              t => t.type === TagType.Topic && selectedTopics.includes(t.value),
            )
          ) {
            satisfiesSubjectFilter = true;
          }
          if (!satisfiesSubjectFilter) {
            return false;
          }
        }
        // filter by age
        if (selectedAges?.length > 0) {
          let satisfiesAgeFilter = false;
          if (
            selectedAges.some(
              value =>
                // case 1: course allows any age by not specifying bounds
                (!course.minRecommendedAge && !course.maxRecommendedAge) ||
                // case 2: course only specifies a minimum age
                (course.minRecommendedAge &&
                  !course.maxRecommendedAge &&
                  Number(value) >= course.minRecommendedAge) ||
                // case 3: course specifies both upper and lower bounds
                (course.minRecommendedAge &&
                  Number(value) >= course.minRecommendedAge &&
                  course.maxRecommendedAge &&
                  Number(value) <= course.maxRecommendedAge),
            )
          ) {
            satisfiesAgeFilter = true;
          }
          if (!satisfiesAgeFilter) {
            return false;
          }
        }
        return true;
      }),
    [courses, selectedTopics, selectedAges],
  );

  const sortedCourses = useMemo(
    () => [...filteredCourses].sort(sortFunctions[sortBy as SORT_TYPE]),
    [filteredCourses, sortBy],
  );

  return (
    <div className="h-full min-h-screen font-graphik">
      {/* HEADER */}
      <div className="bg-j-dark-600 h-24 flex items-center">
        <div className="mx-auto max-w-screen-xl px-4 w-full flex md:flex-row flex-col items-baseline md:gap-8 gap:4  text-white ">
          <h1 className="font-semibold my-2">On Demand Library</h1>
          <div className="max-w-prose my-1">
            Explore On Demand courses created by Juni
          </div>
        </div>
      </div>
      {/* FILTERS */}
      <div className="bg-white min-h-20 h-full flex items-center">
        <div className="mx-auto max-w-screen-xl p-4 w-full flex xs:flex-row flex-col gap-4 justify-between">
          <div className="flex space-x-4">
            {topicOptions && (
              <SelectButtonDropdown
                options={topicOptions}
                id="topic"
                buttonText={
                  selectedTopics && selectedTopics.length > 0
                    ? `Topic: ${defaultStringLabelText(selectedTopics)}`
                    : 'Topic'
                }
                selected={selectedTopics}
                onSelectedValuesChanged={(option: any) => {
                  handleFilterChange('topic', option);
                }}
                isSelected={selectedTopics && selectedTopics.length > 0}
                multi
              />
            )}
            {ageOptions && (
              <SelectCheckboxDropdown
                id="age"
                options={ageOptions}
                buttonText={
                  selectedAges && selectedAges.length > 0
                    ? `Age: ${constructAgeLabelText(selectedAges, '')}`
                    : 'Age'
                }
                selected={selectedAges}
                onSelectedValuesChanged={(option: any) => {
                  handleFilterChange('age', option);
                }}
                isSelected={selectedAges && selectedAges.length > 0}
                multi
              />
            )}
          </div>
          <div className="flex space-x-4">
            {/* <Input placeholder="Search" size="small" /> */}
            <SelectButtonDropdown
              options={sortOptions}
              id="sort-by"
              dropdownPlacement="bottom-end"
              buttonText={`Sort: ${sortBy}`}
              selected={[sortBy]}
              multi={false}
              onSelectedValuesChanged={(option: any) => {
                handleFilterChange('sortBy', option);
              }}
              showMenuActionButtons={false}
              className="min-w-full md:min-w-48"
              menuContainerClassName="w-full-space-12 md:w-auto md:min-w-48"
              isSelected
            />
          </div>
        </div>
      </div>

      {/* COURSE CARDS */}
      {loadingCourseData || subscriptionsQuery.loading || studentsQuery.loading ? (
        <div className="mt-24">
          <ErrorableLoading error={errorCourseData} />
        </div>
      ) : sortedCourses.length > 0 ? (
        <div className="max-w-screen-xl mx-auto px-4 pb-4 my-4 grid lg:grid-cols-2 gap-6">
          {student &&
            sortedCourses.map(course => (
              <div key={course.name}>
                <CourseCard
                  course={course}
                  student={student}
                  setCurrentCourse={setCurrentCourse}
                  subscriptionsQuery={subscriptionsQuery}
                  studentsQuery={studentsQuery}
                />
              </div>
            ))}
        </div>
      ) : (
        <div className="py-12 flex flex-col items-center gap-4">
          <div className="space-y-2">
            <div className="font-medium text-j-dark-600 md:text-8 md:leading-12 text-xl text-center">
              Sorry, no courses match these filters.
            </div>
            <div>Please try removing a filter or resetting all filters.</div>
          </div>
          <img
            className="w-60 h-60"
            src={emptyState}
            alt="No results based on filter selected"
          />
          <NewButton
            onClick={handleClearFilters}
            size="large"
            variant="primary"
            intent="error"
          >
            Reset Filters
          </NewButton>
        </div>
      )}

      {/* Upgrade Modal */}
      {currentCourse && (
        <UpgradeModal
          studentId={studentId}
          currentCourse={currentCourse}
          setCurrentCourse={setCurrentCourse}
          cardQuery={cardQuery}
          subscriptionsQuery={subscriptionsQuery}
          studentsQuery={studentsQuery}
          coursesQuery={coursesQuery}
        />
      )}
    </div>
  );
};

export default OnDemandLibrary;
