import React, { useContext } from 'react';

interface AffiliateContext {
  identifyAffiliateCustomer?: (authToken: string) => void;
}

const AffiliateContext = React.createContext<AffiliateContext>({
  identifyAffiliateCustomer: () => undefined,
});

export const useAffiliates = () => useContext(AffiliateContext);

export default AffiliateContext;
