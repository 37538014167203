import React, { FC } from 'react';
import _ from 'lodash';

import styled from 'styled-components/macro';

// I think there are some typing issues because we are using v2 of react-select.
// Relevant: https://github.com/JedWatson/react-select/issues/3592
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Async, components } from 'react-select';

import { GenericSearchSelectProps } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`;

const MIN_LEN_QUERY = 3;

const SearchSelect: FC<GenericSearchSelectProps> = ({
  callback,
  value,
  search,
  Option,
  placeholder,
}) => {
  // Note about the callback usage: debouncing react-select seems to only work with callbacks.
  // Reference: https://github.com/JedWatson/react-select/issues/2425#issuecomment-398993588
  const debouncedLoadOptions = _.debounce(
    (query, callback) =>
      search(query)
        .then(results => callback(results))
        .catch((error: any) => console.error(error)),
    250,
  );
  const loadOptions = (rawQuery: string, callback: any) => {
    const query = rawQuery.trim();
    if (query.length < MIN_LEN_QUERY) {
      return Promise.resolve([]);
    }
    debouncedLoadOptions(query, callback);
  };
  return (
    <Wrapper>
      <Async
        cacheOptions
        placeholder={placeholder}
        value={value}
        onChange={callback}
        loadOptions={loadOptions}
        noOptionsMessage={(input: { inputValue: string }) =>
          input.inputValue.trim().length < MIN_LEN_QUERY
            ? 'Type to see results'
            : 'No results found'
        }
        components={{
          Option: (props: any) => (
            <components.Option {...props}>
              <Option {...props.label} />
            </components.Option>
          ),
        }}
        styles={{
          container: (provided: any) => ({
            ...provided,
            width: '350px',
          }),
          menu: (provided: any) => ({
            ...provided,
            zIndex: 3,
          }),
        }}
      />
    </Wrapper>
  );
};

export default SearchSelect;
