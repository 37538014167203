import { useCookies } from 'react-cookie';
import { useInitSignupMutation } from 'generated/graphql';
import { useEffect } from 'react';
import { JUNI_COOKIES } from 'constants/cookies';
import queryString from 'query-string';
import { SIGNUP_ID_COOKIE_NAME } from './constants';
import { setSignupCookie } from './utils';

const useAnonymousIdCookie = () => {
  const [cookies] = useCookies([JUNI_COOKIES.NAMES.JUNI_ANONYMOUS_ID]);
  return cookies[JUNI_COOKIES.NAMES.JUNI_ANONYMOUS_ID];
};
/**
 * Responsibilities:
 * 1. if no cookie -> call initSignup and onComplete, set cookie
 * 2. if we have cookie -> do nothing
 */
const useSignupId = () => {
  const [cookies, setCookie] = useCookies([SIGNUP_ID_COOKIE_NAME]);
  const signupIdCookie = cookies[SIGNUP_ID_COOKIE_NAME];
  const anonymousUserId = useAnonymousIdCookie();

  const [initSignup, { loading, error }] = useInitSignupMutation({
    onCompleted: ({ initSignup: { signup } }) => {
      if (!signupIdCookie) {
        setSignupCookie({ setCookie, value: signup._id });
      }
    },
  });

  const impactClickId = window.location.search
    ? queryString.parse(window.location.search)?.irclickid?.toString()
    : undefined;

  useEffect(() => {
    if (!signupIdCookie) {
      initSignup({ variables: { input: { anonymousUserId, impactClickId } } });
    }
  }, [signupIdCookie, anonymousUserId, initSignup, impactClickId]);

  return { signupId: signupIdCookie, loading, error };
};
export default useSignupId;
