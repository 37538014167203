import React, { FC } from 'react';
import { NewButton } from 'core-components';
import { QueryResult } from '@apollo/client';
import {
  LoadProductsByStripeIdQuery,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import { formatPrice } from 'constants/subscription_plans';
import { format, fromUnixTime } from 'date-fns';
import { getStudentNamesFromStudents } from 'app/learner/LearnerAccountV2/utils';
import { FormState, FormValues } from '../types';
import LearnerPortalModal from '../LearnerPortalModal';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  productsQuery: QueryResult<LoadProductsByStripeIdQuery>;
}

const CancelStayWithDiscountConfirmationModal: FC<Props> = ({
  formValues,
  studentsQuery,
  formState,
  updateFormState,
}) => {
  const { subscription } = formValues;
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );
  // baseCost is the price before any discount
  const baseCost =
    subscription?.items.data.reduce(
      (total: number, subscriptionItem) =>
        total + (subscriptionItem.plan.amount || 0),
      0,
    ) || 0;
  return (
    <LearnerPortalModal
      title="Subscription Update Confirmation"
      updateFormState={updateFormState}
      formState={formState}
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            updateFormState('', 'modal');
          }}
        >
          <div className="font-medium">Close</div>
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 leading-6 gap-2">
        <div className="font-medium">Keep the learning going:</div>
        <div className="text-j-dark-400">
          • For the next two months, get 20% off has been applied to{' '}
          {getStudentNamesFromStudents(currentStudents)}'s entire subscription.
        </div>
        <div className="text-j-dark-400">
          • Your next paid month begins{' '}
          {subscription &&
            format(fromUnixTime(subscription.current_period_end), 'MMM d, yyyy')}
          . You'll pay {formatPrice((baseCost / 100) * 0.8)} for the next two months,
          then {formatPrice(baseCost / 100)} thereafter.
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default CancelStayWithDiscountConfirmationModal;
