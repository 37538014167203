import {
  Coupon,
  SignupFragment as Signup,
  SignupInput,
  SubmitSignupInput,
  useGetSignupQuery,
  useSubmitSignupMutation,
  useUpdateSignupMutation,
} from 'generated/graphql';
import { cloneDeep, merge } from 'lodash';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { userLogin } from 'services/login';
import { useUserContext } from 'modules/UserContext';
import { BASE_PATH, SIGNUP_ID_COOKIE_NAME } from './constants';
import { STEPS } from '../steps/constants';
import { getValidSteps, pathToStep } from '../steps/utils';
import { omitDeepLodash, setSignupCookie } from './utils';
import { FlowEvent, handleTracking } from '../steps/analytics';

/**
 *  Responsibilities:
 *  1. Merge existing + patched data [TODO: CONSIDER DOING THIS ON SERVER]
 *  2. Set lastSeenUrl [OMITTED FOR NOW]
 *  3. Deeply remove `__typename` [Not needed if TODO in #1 above is implemented]
 *     Related feature request: https://github.com/apollographql/apollo-feature-requests/issues/6
 */
const getMutationPayload = (signupData: Signup, updates: Partial<SignupInput>) => {
  const cloned = cloneDeep(signupData);
  const merged = merge(cloned, updates);
  return omitDeepLodash(merged, '__typename');
};

const useSignupData = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [cookies, setCookie] = useCookies([SIGNUP_ID_COOKIE_NAME]);
  const signupIdCookie = cookies[SIGNUP_ID_COOKIE_NAME];
  const { loadUserData } = useUserContext();

  const {
    data: queryResultData,
    loading: queryLoading,
    error: queryError,
  } = useGetSignupQuery({
    variables: { id: signupIdCookie },
    skip: !signupIdCookie,
    onError: error => {
      if (error.message === 'Unable to find signup') {
        setSignupCookie({ setCookie, value: '' });
      }
    },
  });

  const [
    updateSignupMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useUpdateSignupMutation();

  const [
    submitSignupMutation,
    { loading: submissionLoading, error: submissionError },
  ] = useSubmitSignupMutation();

  const signupData = queryResultData?.getSignup;

  const handleTrackingWrapper = (
    signup: Signup,
    flowEvent: FlowEvent,
    extra: {
      coupon?: Coupon;
    } = {},
  ) => {
    if (currentStep) {
      handleTracking(signup, currentStep, flowEvent, extra);
    }
  };
  const handlePostMutationRouting = (signup: Signup) => {
    if (!currentStep) return;
    const stepName = STEPS[currentStep].determineNextStep(signup);
    if (stepName) {
      history.push(`/${BASE_PATH}/${STEPS[stepName].path}${search}`);
    }
  };

  const updateSignup = (updates: Partial<SignupInput>, isPageSubmission = true) => {
    if (!signupData) {
      return;
    }
    const signup = getMutationPayload(signupData, updates);
    updateSignupMutation({ variables: { input: { signup } } })
      // apollo-client 3.5.4 supports using `onCompleted` here instead of `.then`
      // https://github.com/apollographql/apollo-client/pull/9076
      .then(({ data }) => {
        if (!data || !isPageSubmission) {
          return;
        }
        handleTrackingWrapper(data.updateSignup.signup, 'SUBMIT_STEP');
        handlePostMutationRouting(data.updateSignup.signup);
      });
  };

  const submitSignup = (
    input: SubmitSignupInput,
    extra: {
      coupon?: Coupon;
    } = {},
  ) => {
    if (!signupData) {
      return;
    }
    return (
      submitSignupMutation({ variables: { input } })
        // apollo-client 3.5.4 supports using `onCompleted` here instead of `.then`
        // https://github.com/apollographql/apollo-client/pull/9076
        .then(async res => {
          const { data } = res;
          if (!data) {
            return res;
          }
          handleTrackingWrapper(data.submitSignup.signup, 'SUBMIT_STEP', extra);
          handlePostMutationRouting(data.submitSignup.signup);
          const email = signupData.parent?.email;
          if (!email) {
            return res;
          }
          await userLogin(email, input.password, 'parent');
          loadUserData();
          return res;
        })
    );
  };

  const error = submissionError || mutationError || queryError;
  const loading = queryLoading || mutationLoading || submissionLoading;

  const currentStep = pathToStep(pathname);
  const validSteps = signupData ? getValidSteps(signupData) : [];
  const isCurrentStepValid = !signupData
    ? 'unknown'
    : !currentStep
    ? false
    : validSteps.includes(currentStep);

  const goToPrevStep = () => {
    if (!signupData || !currentStep) return;
    const prevStepName = STEPS[currentStep].determinePrevStep(signupData);
    if (prevStepName) {
      history.push(`/${BASE_PATH}/${STEPS[prevStepName].path}${search}`);
    }
  };

  return {
    signupData,
    updateSignup,
    submitSignup,
    clientData: { currentStep, validSteps, isCurrentStepValid },
    loading,
    error,
    goToPrevStep,
  };
};

export default useSignupData;
