import { SIGNUP_ID_COOKIE_NAME } from 'app/signup_2023/hooks/constants';
import { addDays } from 'date-fns';
import { CookieSetOptions } from 'universal-cookie';

interface Params {
  setCookie: (name: string, value?: any, options?: CookieSetOptions) => void;
  value: string;
}

const setSignupCookie = ({ setCookie, value }: Params) => {
  setCookie(SIGNUP_ID_COOKIE_NAME, value, {
    expires: addDays(new Date(), 3),
    domain: process.env.NODE_ENV === 'production' ? 'junilearning.com' : '',
    path: '/',
  });
};

export default setSignupCookie;
