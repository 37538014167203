/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

export interface StepperProps {
  steps: string[];
  currentStep: string;
  firstStepString?: string;
}

const Stepper: FC<StepperProps> = ({ steps, currentStep, firstStepString }) => {
  if (!steps || !steps?.length) {
    return null;
  }
  const idxCurrentStep = steps.indexOf(currentStep);
  return (
    <div className="w-full flex justify-between max-w-md xl:max-w-2xl my-4">
      {steps?.map((step, i) => {
        const isPastStep = idxCurrentStep >= i;
        const isNextStep = idxCurrentStep === i - 1;
        return (
          <React.Fragment key={`step-${i}`}>
            {/* LINE */}
            {i !== 0 && (
              <div
                className={`w-full mt-1.5 h-1 ${
                  isPastStep
                    ? 'bg-j-dark-700'
                    : isNextStep
                    ? 'bg-loader animate-loader bg-loader-gradient from-j-dark-600 to-j-dark-200'
                    : 'bg-j-dark-200'
                }`}
              />
            )}
            {/* DOT */}
            <div className="text-center w-8 flex justify-start flex-col items-center">
              <div className="h-5 box-border pt-1">
                <div
                  className={`w-1.5 h-1.5 rounded-full border-2 border-solid ${
                    isPastStep
                      ? 'border-j-dark-700 bg-j-dark-700'
                      : 'border-j-dark-200 bg-white'
                  }  flex justify-center items-center`}
                />
              </div>
              <h2 className="whitespace-pre-wrap sm:whitespace-nowrap md:whitespace-pre-wrap lg:whitespace-nowrap text-center m-0 font-medium lg:text-sm text-xs leading-4 text-j-dark-600">
                {step}
              </h2>
              {i === 0 && firstStepString && (
                <div className="whitespace-pre-wrap sm:whitespace-nowrap md:whitespace-pre-wrap lg:whitespace-nowrap text-xs">
                  {firstStepString}
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
