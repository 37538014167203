import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  useGetRecommendedAgesQuery,
  useGetCourseBasicMetadataQuery,
  useGetCourseServiceQuery,
  CourseSchedulingFormat,
} from 'generated/graphql';
import _ from 'lodash';
import { WEBSITE_COURSES } from 'constants/links';

// HELPER FUNCTIONS
export const getFormattedAgeRange = (ageRange?: {
  min?: number | null;
  max?: number | null;
}) => {
  if (!ageRange) return '';
  const { min, max } = ageRange;
  if (!min) {
    return 'by invitation only';
  }
  return `recommended for ages ${min}${max ? `-${max}` : '+'}`;
};

const coursesToRecommendedAges = (courses: any[]) => {
  const coursesByName = _.keyBy(courses, 'name');
  return _.mapValues(coursesByName, course => {
    const { displayName, minRecommendedAge: min, maxRecommendedAge: max } = course;
    const formattedAgeRange = getFormattedAgeRange({ min, max });
    const displayString = `${displayName} (${formattedAgeRange})`;
    return { displayString, min, max };
  });
};

// HOOKS
/**
 * useRecommendedAges Hook
 * @returns lookup object that maps courseName to an object with age-related fields
 * {
 *  displayString: displayName of course followed by (formatted age range string)
 *  min: minimum recommended age
 *  max: maximum recommended age
 * }
 * Will return an empty object if data isn't populated yet
 *
 * Recommended Usage:
 * const recommendedAges = useRecommendedAges()
 * const { displayString, min, max } = recommendedAges[courseName]
 * return <div> {displayString} </div>
 */

interface RecommendedAge {
  displayString: string;
  min?: number;
  max?: number;
}

export const useRecommendedAges = () => {
  // TODO: add loading/error state output
  const [recommendedAges, setRecommendedAges] = useState<
    Record<string, RecommendedAge>
  >({});
  const { data } = useGetRecommendedAgesQuery();

  useEffect(() => {
    const courses = data?.getCourses || [];
    setRecommendedAges(courses.length ? coursesToRecommendedAges(courses) : {});
  }, [data]);

  return recommendedAges;
};

// Normally we would define default values on the server side as part of the GQL Course Schema
// with objectType resolvers, but this is difficult without mass-renaming the fields due to
// this Nexus quirk: https://github.com/graphql-nexus/nexus/issues/1006
const DEFAULT_VALUES = {
  _id: undefined,
  name: undefined,
  displayName: 'displayName',
  slug: '#',
  abbreviation: 'JUNI',
  iconName: 'QuestionMark',
  primaryColor: 'j-dark-400',
  subject: {
    _id: undefined,
    name: undefined,
    displayName: 'subjectDisplayName',
    websiteUrl: WEBSITE_COURSES,
  },
  isAcceptingEnrollment: undefined,
  minRecommendedAge: 0,
  maxRecommendedAge: 99,
  schedulingFormat: CourseSchedulingFormat.Private,
  defaultJideEnv: 'public_non_renderable',
};

/**
 * useCourseBasicMetadata Hook
 * @returns two things:
 * coursesWithBasicMetadata - array of all courses with basic metadata fields only
 * courseNameToBasicMetadata - utility function courseNameToBasicMetadata which
 * allows for lookup of a course given a course name
 *
 * If field is defined in DEFAULT_VALUE, it is returned if the courseName doesn't
 * exist or the data hasn't populated yet
 *
 * Recommended Usage:
 * const { coursesWithBasicMetadata, courseNameToBasicMetadata } = useCourseBasicMetadata()
 * const displayNames = coursesWithBasicMetadata.map(c => c.displayName)
 * const defaultJideEnv = courseNameToBasicMetadata(courseName).defaultJideEnv
 */
export const useCourseNameToBasicMetadata = () => {
  const { data } = useGetCourseBasicMetadataQuery();

  const coursesWithBasicMetadata = useMemo(() => {
    const coursesWithDefaultValues = (data?.getCourses || []).map(course => {
      const {
        _id,
        name,
        displayName,
        slug,
        abbreviation,
        iconName,
        primaryColor,
        subject,
        isAcceptingEnrollment,
        minRecommendedAge,
        maxRecommendedAge,
        schedulingFormat,
        defaultJideEnv,
      } = course;
      return {
        _id: String(_id),
        name,
        displayName: displayName || DEFAULT_VALUES.displayName,
        slug: slug || DEFAULT_VALUES.slug,
        abbreviation: abbreviation || DEFAULT_VALUES.abbreviation,
        iconName: iconName || DEFAULT_VALUES.iconName,
        primaryColor: primaryColor || DEFAULT_VALUES.primaryColor,
        subject: {
          _id: subject._id,
          name: subject.name,
          displayName: subject.displayName,
          websiteUrl: subject.websiteUrl || DEFAULT_VALUES.subject.websiteUrl,
        },
        isAcceptingEnrollment,
        minRecommendedAge: minRecommendedAge || DEFAULT_VALUES.minRecommendedAge,
        maxRecommendedAge: maxRecommendedAge || DEFAULT_VALUES.maxRecommendedAge,
        schedulingFormat: schedulingFormat || DEFAULT_VALUES.schedulingFormat,
        defaultJideEnv: defaultJideEnv || DEFAULT_VALUES.defaultJideEnv,
      };
    });
    return coursesWithDefaultValues;
  }, [data]);

  const coursesByName = useMemo(() => _.keyBy(coursesWithBasicMetadata, 'name'), [
    coursesWithBasicMetadata,
  ]);

  const courseNameToBasicMetadata = useCallback(
    (courseName?: string | null) => {
      if (!courseName || !coursesByName[courseName]) {
        return DEFAULT_VALUES;
      }
      return coursesByName[courseName];
    },
    [coursesByName],
  );

  return { coursesWithBasicMetadata, courseNameToBasicMetadata };
};

interface UseCourseServiceProps {
  acceptingEnrollmentOnly: boolean;
}

/**
 * This hook is named after the generateRest service it replaces that was
 * commonly referred to as 'CourseService'.
 * @returns The results of useGetCourseServiceQuery, which is then
 * translated into the shape expected by previous clients of CourseService.
 *
 * Recommended Usage:
 * const { courses, error, loading } = useCourseService();
 */
export const useCourseService = ({
  acceptingEnrollmentOnly,
}: UseCourseServiceProps) => {
  const { data, error, loading } = useGetCourseServiceQuery({
    variables: {
      input: { ...{ isAcceptingEnrollment: acceptingEnrollmentOnly || undefined } },
    },
  });

  const courses = useMemo(
    () =>
      (data?.getCourses || [])
        .filter(c => c.curriculumId)
        .map(c => ({
          // jan2022: this is to match the previous iteration of the
          // CourseService, which queried curriculum documents
          _id: c.curriculumId,
          name: c.name,
          displayName: c.displayName,
          subject: c.subject.name,
          subjectDisplayName: c.subject.displayName,
          // temporary, for compatibility
          isActive: c.isAcceptingEnrollment,
          isAcceptingEnrollment: c.isAcceptingEnrollment,
          curriculumId: c.curriculumId,
          schedulingFormat: c.schedulingFormat,
          curriculumCollection: c.curriculumCollection,
        })),
    [data],
  );

  return { courses, error, loading };
};
