import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ErrorableLoading } from 'components/ui';
import {
  useLoggedInUserGuard,
  useSignupId,
  useSignupData,
  useSignupQueryParams,
  useValidPathGuard,
  useTrackAdConversions,
} from './hooks';
import {
  Confirmation,
  CourseSelection,
  CreateAccount,
  FlowWrapper,
  ParentInfo,
  Payment,
  Scheduling,
  StudentInfo,
} from './components';

const Signup = () => {
  const { path } = useRouteMatch();
  const { error: signupIdError, loading: signupIdLoading } = useSignupId();
  const { error: signupDataError, loading: signupDataLoading } = useSignupData();
  const { loading: loadingQueryParams } = useSignupQueryParams();
  useValidPathGuard();
  useTrackAdConversions();

  const loading = signupIdLoading || signupDataLoading || loadingQueryParams;
  if (loading || signupIdError || signupDataError) {
    return <ErrorableLoading error={signupIdError || signupDataError} pageError />;
  }

  return (
    <div className="relative">
      <FlowWrapper>
        <Switch>
          <Route path={`${path}/parent-info`} component={ParentInfo} />
          <Route path={`${path}/student-info`} component={StudentInfo} />
          <Route path={`${path}/course-selection`} component={CourseSelection} />
          <Route path={`${path}/scheduling`} component={Scheduling} />
          <Route path={`${path}/payment`} component={Payment} />
          <Route path={`${path}/create-account`} component={CreateAccount} />
          <Route path={`${path}/confirmation`} component={Confirmation} />
        </Switch>
      </FlowWrapper>
    </div>
  );
};

const LoggedInUserGuard = () => {
  const { isAllowed } = useLoggedInUserGuard();
  if (isAllowed) {
    return <Signup />;
  }
  return null;
};

export default LoggedInUserGuard;
