import React, { FC, useState } from 'react';
import { QueryResult } from '@apollo/client';

import { NewButton, NewCard } from 'core-components';
import { ErrorableLoading } from 'components/ui';
import { LoadDefaultPaymentInformationByParentIdQuery } from 'generated/graphql';
import { Parent } from 'models';
import { StripeWrapper } from 'components/StripeWrapper';
import PaymentUpdateModal from './PaymentUpdateModal';

interface PaymentInformationCardProps {
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  parent: Parent;
}

const PaymentInformationCard: FC<PaymentInformationCardProps> = ({
  cardQuery,
  parent,
}) => {
  const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState(false);
  const card = cardQuery?.data?.defaultPaymentInformationByParentId;
  const { loading, error } = cardQuery;
  return (
    <>
      <NewCard padding="0">
        <div className="flex flex-row justify-between items-center px-6 py-4">
          <div className="text-xl font-semibold">Payment Information</div>
          <div>
            <NewButton
              size="small"
              variant="secondary"
              onClick={() => setIsEditPaymentModalOpen(true)}
            >
              Edit
            </NewButton>
          </div>
        </div>
        <NewCard.Divider />
        <div className="flex flex-col gap-6 p-6">
          {loading || error ? (
            <ErrorableLoading error={error} />
          ) : card ? (
            <>
              <div className="flex flex-col">
                <div className="font-semibold mb-1">Card # (Last 4)</div>
                <div className="text-j-dark-300">...{card.last4}</div>
              </div>
              <div className="flex flex-col">
                <div className="font-semibold mb-1">Card Type</div>
                <div className="text-j-dark-300">
                  {card.brand
                    ? card.brand.charAt(0).toUpperCase() + card.brand.slice(1)
                    : ''}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-semibold mb-1">Expires</div>
                <div className="text-j-dark-300">
                  {card.exp_month
                    ? card.exp_month.toString().padStart(2, '0')
                    : 'MM'}
                  /{card.exp_year ? card.exp_year.toString().substring(2, 4) : 'YY'}
                </div>
              </div>
            </>
          ) : (
            <div>No default payment information found.</div>
          )}
        </div>
      </NewCard>
      {isEditPaymentModalOpen && (
        <StripeWrapper>
          <PaymentUpdateModal
            cardQuery={cardQuery}
            parent={parent}
            isEditPaymentModalOpen={isEditPaymentModalOpen}
            setIsEditPaymentModalOpen={setIsEditPaymentModalOpen}
          />
        </StripeWrapper>
      )}
    </>
  );
};

export default PaymentInformationCard;
