/**
 * Contains util functions for working with Acuity appointments (aka sessions)
 */
import moment from 'moment-timezone';
import memoize from 'memoize-one';
import {
  ACUITY_APPOINTMENT_TYPES,
  ACUITY_TEAM_FORMAT_APPOINTMENT_TYPE_IDS,
  MAKEUP_NOT_ALLOWED_APPOINTMENT_TYPE_IDS,
  MINUTES_JOINABLE_BEFORE_SESSION_START,
  NO_LOGGING_APPOINTMENT_TYPE_IDS,
  NON_PRIVATE_APPOINTMENT_TYPE_IDS,
  ACUITY_ASSOCIATED_STUDENT_IDS_FORM_FIELD_ID,
  ACUITY_CLUB_INFORMATION_FORM_ID,
  ACUITY_FORM_FIELDS,
  ACUITY_MATH_APPT_TYPES,
  ACUITY_ENGLISH_APPT_TYPES,
  ACUITY_INVESTING_APPT_TYPES,
  ACUITY_SCIENCE_APPT_TYPES,
  ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
  SUBJECT_TO_SESSION_APPT_TYPE_ID,
} from 'constants/acuity';
import { MIN_HOURS_IN_ADVANCE_FOR_PARENT_RESCHEDULING } from 'constants/rescheduling';
import { SUBJECTS } from 'constants/subjects';

/**
 * Takes in an Acuity appointment and returns a single student id associated with it.
 *
 * Acuity appointments have a 'forms' field which is an array of objects. Each of these form objects
 * represents some metadata on the appointment. Take a look at the ACUITY_FORM_FIELDS in /server/constants/acuity.js
 * to see some of the commonly used form field Ids and what their values mean.
 * Each form object has the following shape (field values are examples):
 * {
 *  "id": 732528
 *  "name": " All Classes with Student Name"
 *  "values": [ // array of objects, usually just one object though
 *    {
 *      "id": 1472715936
 *      "fieldID": 3556248
 *      "value": "Julia"
 *      "name": "Student's Name"
 *    }
 *   ]
 * }
 *  */
export const getStudentIdFromAcuityAppt = appt => {
  const studentIdsForm = appt.forms.find(
    form => form.id === ACUITY_ASSOCIATED_STUDENT_IDS_FORM_FIELD_ID,
  );
  if (!studentIdsForm) {
    return undefined;
  }
  const studentIdsFormValue = studentIdsForm.values?.find(
    formValueObj => formValueObj.fieldID === ACUITY_FORM_FIELDS.STUDENT_IDS,
  );
  if (!studentIdsFormValue) {
    return undefined;
  }
  const rawStudentIdsValue = studentIdsFormValue.value || '';
  /* There should only be a single student id specified per Acuity appointment, 
    so the.split(',') should almost never be necessary. Leaving it in to deal with the possibility 
    of human error when entering the studentIds metadata; if so, we take the first id inputted. */
  const studentIds = rawStudentIdsValue.split(',');
  const [firstStudentId] = studentIds;
  const studentId = firstStudentId?.trim();
  return studentId || undefined; // if studentId from the appt is an empty string, return undefined instead
};

export const isStudentNameInAcuityAppt = (
  acuityAppt,
  studentFirstName,
  hasMultipleChildren,
) => {
  if (hasMultipleChildren) {
    if (acuityAppt.forms) {
      if (acuityAppt.forms[0].values) {
        for (let j = 0; j < acuityAppt.forms[0].values.length; j += 1) {
          const formValue = acuityAppt.forms[0].values[j];
          if (
            formValue.fieldID === 3556248 ||
            formValue.fieldID === 3318078 ||
            formValue.fieldID === 6756008
          ) {
            if (formValue.value.indexOf(studentFirstName) === -1) {
              return false;
            }
          }
        }
      }
    }
  }
  return true;
};

export const isStudentIdInAcuityAppt = (
  acuityAppt,
  studentId,
  hasMultipleChildren,
) => {
  if (hasMultipleChildren) {
    if (acuityAppt.forms) {
      const studentIdsForm = acuityAppt.forms.find(
        form => form.id === ACUITY_ASSOCIATED_STUDENT_IDS_FORM_FIELD_ID,
      );
      if (!studentIdsForm) return false;
      if (studentIdsForm && studentIdsForm.values) {
        for (let j = 0; j < studentIdsForm.values.length; j += 1) {
          const formValue = studentIdsForm.values[j];
          if (formValue.fieldID === 7366323) {
            if (!formValue.value.includes(studentId)) {
              return false;
            }
          }
        }
      }
    }
  }
  return true;
};

export const getLearnerSessionActionType = (
  session,
  studentFirstName,
  hasMultipleChildren,
) => {
  // do not include session if parent has multiple children and the child's name is not in the form
  if (isStudentNameInAcuityAppt(session, studentFirstName, hasMultipleChildren)) {
    // sessions are only actionable if they haven't already ended
    if (!sessionHasEnded(session)) {
      // show active Reschedule button if session is > 22h away
      if (
        moment(session.datetime).isAfter(
          moment().add(MIN_HOURS_IN_ADVANCE_FOR_PARENT_RESCHEDULING, 'hours'),
        )
      ) {
        // do not allow group classes to be rescheduled
        if (
          session.appointmentTypeID === ACUITY_APPOINTMENT_TYPES.GROUP_SESSION ||
          session.appointmentTypeID === ACUITY_APPOINTMENT_TYPES.CLUB_MEETING
        ) {
          return 'group-upcoming';
        }
        return 'upcoming';

        // show disabled Reschedule button if session is 15 minutes to 22 hours away
      }
      if (
        moment(session.datetime).isAfter(
          moment().add(MINUTES_JOINABLE_BEFORE_SESSION_START, 'minutes'),
        )
      ) {
        return 'upcoming-imminent';
      }
      // show active Join Session Button
      return 'joinable';
    }
  }
  return null;
};

export const getSessionDurationRoundedUpToTheHalfHour = session => {
  const duration = parseInt(session.duration, 10);
  return duration ? Math.ceil(duration / 30) * 30 : 60;
};

export const sessionHasEnded = session => {
  const roundedDuration = getSessionDurationRoundedUpToTheHalfHour(session);
  const sessionEndTime = moment(session.datetime).add(roundedDuration, 'minutes');
  return !sessionEndTime.isSameOrAfter(moment());
};

export const upcomingSessionIsTeacherJoinable = session =>
  moment(session.datetime).isSameOrBefore(
    moment().add(MINUTES_JOINABLE_BEFORE_SESSION_START, 'minutes'),
  );

// Group, Team, or Camp sessions
export const isNonPrivateSessionType = session =>
  NON_PRIVATE_APPOINTMENT_TYPE_IDS.includes(session.appointmentTypeID);

export const sessionHasLoggingDisabled = session =>
  NO_LOGGING_APPOINTMENT_TYPE_IDS.includes(session.appointmentTypeID);

export const sessionHasMakeupsDisabled = session =>
  MAKEUP_NOT_ALLOWED_APPOINTMENT_TYPE_IDS.includes(session.appointmentTypeID);

export const hasTeamSessionFormat = session =>
  ACUITY_TEAM_FORMAT_APPOINTMENT_TYPE_IDS.includes(session.appointmentTypeID);

export const coalesceNonPrivateAndBootcampSessions = memoize(sessions =>
  sessions.reduce((agg, cur) => {
    if (isNonPrivateSessionType(cur)) {
      const foundGroupSessionIndex = agg.findIndex(
        element => element[0].classID === cur.classID,
      );
      if (foundGroupSessionIndex !== -1) {
        agg[foundGroupSessionIndex].push(cur);
        return [...agg];
      }
    } else if (
      ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES.includes(cur.appointmentTypeID)
    ) {
      const foundBootcampSessionIndex = agg.findIndex(
        element =>
          element[0].datetime === cur.datetime &&
          element[0].appointmentTypeID === cur.appointmentTypeID,
      );
      if (foundBootcampSessionIndex !== -1) {
        agg[foundBootcampSessionIndex].push(cur);
        return [...agg];
      }
    }
    return [...agg, [cur]];
  }, []),
);

export const getClubName = appointment => {
  if (appointment && appointment.forms) {
    const filteredForms = appointment.forms.filter(
      form => form.id === ACUITY_CLUB_INFORMATION_FORM_ID,
    );
    if (filteredForms.length) {
      const [clubInformationForm] = filteredForms;
      const [formValue] = clubInformationForm.values;
      if (formValue.fieldID === ACUITY_FORM_FIELDS.CLUB_NAME) {
        return formValue.value;
      }
    }
  }
  return '';
};

export const getSubjectNameFromAppointmentTypeID = appointmentTypeID => {
  if (ACUITY_MATH_APPT_TYPES.includes(appointmentTypeID)) {
    return SUBJECTS.MATHEMATICS;
  }
  if (ACUITY_ENGLISH_APPT_TYPES.includes(appointmentTypeID)) {
    return SUBJECTS.ENGLISH;
  }
  if (ACUITY_INVESTING_APPT_TYPES.includes(appointmentTypeID)) {
    return SUBJECTS.INVESTING;
  }
  if (ACUITY_SCIENCE_APPT_TYPES.includes(appointmentTypeID)) {
    return SUBJECTS.SCIENCE;
  }
  return SUBJECTS.COMPUTER_SCIENCE;
};

export const getClassSchedulerAppointmentTypeFromCourse = course => {
  if (course?.name === 'juni_jr') {
    return ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION;
  }
  if (course?.name.includes('usaco')) {
    return ACUITY_APPOINTMENT_TYPES.USACO_SESSION;
  }
  if (
    [
      'ai_level_1',
      'ai_level_2',
      'ai_machine_learning_deep_dive',
      'ai_machine_learning_deep_dive_hs',
    ].includes(course?.name)
  ) {
    return ACUITY_APPOINTMENT_TYPES.AI_ML_SESSION;
  }
  if (course?.name === 'python_level_1_bootcamp') {
    return ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION;
  }

  return (
    SUBJECT_TO_SESSION_APPT_TYPE_ID[course?.subject] ||
    ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION
  );
};
