import React from 'react';
import { useSignupData } from 'app/signup_2023/hooks';

import { Link } from 'react-router-dom';
import { SignupProduct } from 'generated/graphql';
import { BulkCSCourseSelection, TutoringCourseSelection } from './components';

const SHOW_PRODUCT_SWITCHER = false;

const COURSE_SELECTION_MODES = {
  [SignupProduct.PrivateTutoring]: {
    Component: TutoringCourseSelection,
    link: {
      product: SignupProduct.PrivateBulkCs,
      text: 'bulk computer science packages',
    },
  },
  [SignupProduct.PrivateBulkCs]: {
    Component: BulkCSCourseSelection,
    link: { product: SignupProduct.PrivateTutoring, text: 'tutoring offerings' },
  },
};

const CourseSelection = () => {
  const { signupData } = useSignupData();
  const productName =
    signupData?.students?.[0]?.enrollments?.[0]?.productName ||
    SignupProduct.PrivateTutoring;
  const { Component, link } = COURSE_SELECTION_MODES[productName];

  return (
    <>
      <Component />
      {SHOW_PRODUCT_SWITCHER && link && signupData && (
        <div className="text-center w-full mt-4 text-sm">
          <Link key={link.product} to={`?productName=${link.product}`} replace>
            Click here to check out our {link.text}
          </Link>
        </div>
      )}
    </>
  );
};

export default CourseSelection;
