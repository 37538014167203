import React, { FC, useState } from 'react';

import { NewButton, NewCard } from 'core-components';
import _ from 'lodash';
import {
  CourseForInstructorQualificationsViewFragment,
  InstructorQualifiedCourse,
} from 'generated/graphql';

const InstructorQualifiedSubjectCard: FC<{
  courses: CourseForInstructorQualificationsViewFragment[];
  qualifiedCourses: Partial<InstructorQualifiedCourse>[] | undefined;
  subjectId: string;
}> = ({ courses, qualifiedCourses, subjectId }) => {
  const [showAllCourses, setShowAllCourses] = useState(false);

  const qualifyCourses = courses.filter(
    course =>
      course.curriculumId &&
      _.some(qualifiedCourses, ['courseId', course?.curriculumId]),
  );

  return (
    <NewCard shadow="1" key={`instructo-qualified-${subjectId}`}>
      <div className="mb-4 text-xl font-medium text-j-dark-600 flex flex-row justify-between items-center">
        <div>
          <span className="font-semibold">{`${courses[0].subject.displayName} Courses`}</span>{' '}
          <span className="font-normal">
            ({`Qualified: ${qualifyCourses.length}/${courses.length}`})
          </span>
        </div>
        <NewButton
          size="small"
          variant="secondary"
          onClick={() => setShowAllCourses(!showAllCourses)}
        >
          {showAllCourses ? 'Hide' : 'Show all'} courses
        </NewButton>
      </div>
      <div className="flex flex-col gap-6">
        {(qualifyCourses.length > 0 || showAllCourses) && <NewCard.Divider />}
        {courses.map(course => {
          const courseIsQualified =
            course.curriculumId &&
            _.some(qualifiedCourses, ['courseId', course?.curriculumId]);
          return (
            <div
              key={course._id}
              className={`flex flex-row  ${
                showAllCourses || courseIsQualified ? 'block' : 'hidden'
              }`}
            >
              <div className="w-3/5 font-medium text-j-dark-600">
                {course.displayName}
              </div>
              <div className="space-y-10 text-j-dark-500">
                {courseIsQualified ? 'Qualified' : '-'}
              </div>
            </div>
          );
        })}
      </div>
    </NewCard>
  );
};

export default InstructorQualifiedSubjectCard;
