import { FC, useState } from 'react';
import classNames from 'classnames';
import { Card, Icon, TextArea } from 'core-components';
import Radio from 'app/signup_session/components/Radio';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import navStates from 'app/signup_session/navigation/states';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import { GENDERS } from 'constants/genders';
import {
  BundleSelection,
  CodingExperience,
  SignupSessionProps,
  StudentData,
  SubjectName,
} from 'app/signup_session/types';
import { findStudentById, updateStudentById } from 'app/signup_session/lib';
import {
  COMPUTER_SCIENCE,
  ENGLISH,
  INVESTING,
  MATH,
} from 'constants/signup_sessions';
import { Gender } from 'generated/graphql';
import { differenceInYears, format, isValid } from 'date-fns';
import InputField from 'app/signup_session/components/InputField';
import WarningBlock from 'app/signup_session/components/WarningBlock';
import isBetween from 'utils/isBetween';
import { AGES_TAUGHT } from 'constants/student_ages';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import SignupFlowFooter from 'app/signup_session/components/SignupFlowFooter';

function getSubjectValue(subject: SubjectName, student: Partial<StudentData>) {
  switch (subject) {
    case COMPUTER_SCIENCE:
      return student.codingExperience;
    case MATH:
      return student.mathLevel;
    case ENGLISH:
      return student.englishLevel;
    default:
      return '';
  }
}

export const COMPUTER_SCIENCE_OPTIONS: Array<{
  label: string;
  value: CodingExperience;
}> = [
  { label: 'No experience', value: 'None' },
  { label: '0-3 months', value: '0-3 months' },
  { label: '4-6 months', value: '4-6 months' },
  { label: '7-12 months', value: '7-12 months' },
  { label: '12+ months', value: '12+ months' },
];

const NON_CS_OPTIONS = [
  { label: 'Needs extra guidance', value: 'Needs extra guidance' },
  {
    label: 'Needs some review here and there',
    value: 'Needs some review here and there',
  },
  { label: 'On track with their peers', value: 'On track with their peers' },
  { label: 'Ahead of their class', value: 'Ahead of their class' },
  {
    label: 'Very accelerated and needs extra challenging',
    value: 'Very accelerated and needs extra challenging',
  },
];

const MoreStudentInfo: FC<SignupSessionProps> = ({ history, location }) => {
  const {
    signupData,
    activeStudentId,
    setSignupSession,
    flags,
  } = useSignupContext();
  const { getNextPage } = useNavRouter();
  const student = findStudentById(activeStudentId, signupData)!;

  const [learningStyle, setLearningStyle] = useState(student.learningStyle);

  const [birthdate, setBirthDate] = useState(
    student?.birthdate !== undefined
      ? format(new Date(student.birthdate), 'MM/dd/yyyy')
      : '',
  );
  const [birthdateError, setBirthdateError] = useState<string>('');
  const [ageIsOutsideOfRange, setAgeIsOutsideOfRange] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleAgeChange = () => {
    setBirthdateError('');
    setAgeIsOutsideOfRange(false);
    if (!birthdate || !isValid(new Date(birthdate))) {
      setBirthdateError('Please enter a valid birthdate');
      return;
    }
    const studentAge = differenceInYears(new Date(), new Date(birthdate));
    if (!isBetween(AGES_TAUGHT.min, AGES_TAUGHT.max, studentAge)) {
      setAgeIsOutsideOfRange(true);
    }

    const updatedSignupData = updateStudentById(
      activeStudentId,
      { birthdate: new Date(birthdate) },
      signupData,
    );

    setSignupSession(updatedSignupData);
  };

  const setStudentGender = (gender: Gender) => async () => {
    setSignupSession(updateStudentById(activeStudentId, { gender }, signupData));
  };

  const onSubmit = async () => {
    setError('');
    setIsLoading(true);

    try {
      history.push(
        getNextPage(navStates.signup.moreStudentInfo, ROUTE_EVENT.SUBMIT, {
          signupData,
          search: location.search,
        }),
      );
    } catch (err) {
      setError(
        'An error occurred. Please try again later, or email support@learnwithjuni.com for help.',
      );
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const subjects = getStudentBundleSelections(student?.bundle)
    .filter((selection): selection is BundleSelection => selection !== undefined)
    .sort((a, b) => a.subject.localeCompare(b.subject));

  const gridStyles = classNames('grid grid-cols-1 gap-6', {
    'md:grid-cols-2': subjects.length === 2,
    'md:grid-cols-3': subjects.length >= 3,
  });

  return (
    <>
      {error && (
        <div className="bg-white max-w-2xl mx-auto mt-6 p-8 rounded-xl shadow-1">
          <div className="text-juni-pink-900 flex flex-row items-center">
            <div className="pr-4 transform translate-y-0.5">
              <Icon.Error height={20} width={20} />
            </div>
            <div className="font-medium">{error}</div>
          </div>
        </div>
      )}
      <div className="flex flex-col-reverse justify-center items-center">
        <Card
          borderWidth="0"
          className="w-full sm:w-3/5 sm:max-w-screen-xs sm:rounded-lg"
          noRounding
          hideOverflow={false}
        >
          <header className="border-0 border-b border-solid border-j-purple-200 pb-4 mb-8">
            <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2">
              Help us get to know {student?.firstName || 'your student'}
            </h1>
            <p className="text-j-dark-300 m-0 text-base">
              These questions will help us get to know{' '}
              {student?.firstName || 'your student'} and how they learn best. We'll
              find you a Juni Instructor who's great for your child's experience
              level, age, and any learning preferences or needs.
            </p>
          </header>
          <div className="space-y-8">
            {flags.shouldSkipCourseFrequency && (
              <>
                <InputField
                  labelTextStyles="text-j-dark-600 text-sm leading-6 m-0 pb-1 w-full font-medium"
                  value={birthdate}
                  type="date"
                  id="birthdate"
                  textRight="MM/DD/YYYY"
                  label="Date of Birth"
                  onBlur={() => handleAgeChange()}
                  onChange={e => setBirthDate(e.target.value)}
                  validationError={birthdateError}
                />
                {ageIsOutsideOfRange && (
                  <WarningBlock>
                    Our courses are designed for kids ages 7-18. We may contact you
                    to discuss your child's enrollment.
                  </WarningBlock>
                )}
              </>
            )}
            {!flags.shouldSkipCourseFrequency && (
              <div>
                <h2 className="text-j-dark-600 text-sm leading-6 m-0 pb-3 font-medium">
                  Experience Level (optional)
                </h2>
                <div className={gridStyles}>
                  {subjects
                    .filter(({ subject }) => subject !== INVESTING)
                    .map(({ subject }) => (
                      <div className="rounded-lg bg-j-gray-200 p-4" key={subject}>
                        <h3 className="text-sm font-medium m-0 text-j-dark-600 pb-2">
                          {subject} Level
                        </h3>
                        {(subject === COMPUTER_SCIENCE
                          ? COMPUTER_SCIENCE_OPTIONS
                          : NON_CS_OPTIONS
                        ).map(option => (
                          <button
                            key={option.value}
                            className="flex space-x-3 tracking-normal bg-transparent border-none text-j-dark-600 normal-case text-sm text-left hover:shadow-none ignore-juni-globals p-0 py-2"
                            onClick={() =>
                              setSignupSession(
                                updateStudentById(
                                  activeStudentId,
                                  {
                                    ...(subject === COMPUTER_SCIENCE && {
                                      codingExperience: option.value as CodingExperience,
                                    }),
                                    ...(subject === ENGLISH && {
                                      englishLevel: option.value,
                                    }),
                                    ...(subject === MATH && {
                                      mathLevel: option.value,
                                    }),
                                  },
                                  signupData,
                                ),
                              )
                            }
                          >
                            <div>
                              <Radio
                                selected={
                                  option.value === getSubjectValue(subject, student)
                                }
                              />
                            </div>
                            <span>{option.label}</span>
                          </button>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div>
              <h2 className="text-j-dark-600 text-sm leading-6 m-0 pb-1 font-medium">
                Student Gender
              </h2>
              <div>
                {GENDERS.map(gender => (
                  <button
                    key={gender.value}
                    className="flex space-x-3 tracking-normal bg-transparent border-none text-j-dark-600 normal-case text-sm text-left hover:shadow-none ignore-juni-globals p-0 py-2"
                    onClick={setStudentGender(gender.value)}
                  >
                    <Radio selected={gender.value === student?.gender} />
                    <span>{gender.label}</span>
                  </button>
                ))}
              </div>
            </div>
            {!flags.shouldSkipCourseFrequency && (
              <div>
                <h2 className="text-j-dark-600 text-sm leading-6 m-0 pb-1 font-medium">
                  Learning Preferences (optional)
                </h2>
                <p className="text-xs text-j-dark-400 m-0 pb-4">
                  Feel free to share any learning style or instructor preferences
                  here. If your child has any special learning needs, we'll also do
                  our best to accommodate.
                </p>
                <TextArea
                  fullWidth
                  rows={2}
                  value={learningStyle ?? ''}
                  onChange={e => setLearningStyle(e.target.value)}
                  onBlur={() =>
                    setSignupSession(
                      updateStudentById(
                        activeStudentId,
                        { learningStyle },
                        signupData,
                      ),
                    )
                  }
                />
              </div>
            )}
          </div>
          <SignupFlowFooter
            back={{
              handler: () => {
                history.push(
                  getNextPage(navStates.signup.moreStudentInfo, ROUTE_EVENT.BACK, {
                    signupData,
                    search: location.search,
                    shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
                  }),
                );
              },
            }}
            next={{
              disabled:
                isLoading || !!birthdateError || !birthdate || !student.gender,
              handler: onSubmit,
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default MoreStudentInfo;
