import React, { FC, useState } from 'react';
import { NewButton, NewModalWindow, TextArea } from 'core-components';
import { SubscriptionMetaDataWithCourse } from '../../../types';

interface Props {
  subjectKey: string;
  updatedSubscription: SubscriptionMetaDataWithCourse;
  setUpdatedSubscription: React.Dispatch<
    React.SetStateAction<SubscriptionMetaDataWithCourse>
  >;
  isEditingTutoringSubject: boolean;
  setIsEditingTutoringSubject: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditTutoringSubjectModal: FC<Props> = ({
  subjectKey,
  updatedSubscription,
  setUpdatedSubscription,
  isEditingTutoringSubject,
  setIsEditingTutoringSubject,
}) => {
  const [tutoringNotes, setTutoringNotes] = useState<undefined | string>(
    updatedSubscription[subjectKey].notes,
  );

  return (
    <NewModalWindow
      title="Edit Tutoring Subject"
      closeModal={() => {
        setIsEditingTutoringSubject(false);
      }}
      isOpen={isEditingTutoringSubject}
      renderFooter={() => (
        <div className="text-right w-full">
          <NewButton
            onClick={() => {
              setUpdatedSubscription({
                ...updatedSubscription,
                [subjectKey]: {
                  ...updatedSubscription[subjectKey],
                  notes: tutoringNotes,
                },
              });
              setIsEditingTutoringSubject(false);
            }}
            disabled={!tutoringNotes}
          >
            Continue
          </NewButton>
        </div>
      )}
    >
      <div className="flex flex-col w-full gap-2">
        <label className="text-j-dark-700 font-base">
          What subjects would you like the tutoring sessions to cover?
        </label>
        <TextArea
          onChange={e => setTutoringNotes(e.target.value)}
          value={tutoringNotes}
          size="xsmall"
          fullWidth
          placeholder="Let us know what subject matter your student will need help with so we can find the best instructor for your tutoring session(s)."
        />
      </div>
    </NewModalWindow>
  );
};
export default EditTutoringSubjectModal;
