import React, { useState } from 'react';
import { Input, Message, NewButton } from 'core-components';
import SpinnerV2 from 'components/SpinnerV2';
import { useSignupData, useSignupCoupon } from 'app/signup_2023/hooks';
import { Loading } from 'components/ui';
import { useTouchedFields } from 'utils/useTouchedFields';
import { SmsConsentInput } from './components';
import { headerTextStyles, labelTextStyles } from '../constants';

const MIN_PASSWORD_LENGTH = 8;

const CreateAccount = () => {
  const { signupData, submitSignup, loading, error } = useSignupData();
  const { isFieldTouched, addToTouchedFields } = useTouchedFields();
  const { coupon } = useSignupCoupon();
  const [password, setPassword] = useState('');
  const [smsConsent, setSmsConsent] = useState(true);

  const isStepValid = () => password.length >= MIN_PASSWORD_LENGTH;

  const { _id: signupId, parent } = signupData || {};
  const { email } = parent || {};
  if (!email) {
    return <Loading />;
  }

  const handleSubmit = async () => {
    if (!signupData) {
      return;
    }
    try {
      await submitSignup({ signupId, password, smsConsent }, { coupon });
    } catch (err) {
      console.error(err);
    } finally {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-white max-w-lg mx-auto p-8 rounded-xl shadow-1">
      <header>
        <h1 className={headerTextStyles}>Welcome to Juni!</h1>
        <p className="text-j-dark-300 m-o text-base">
          To finish setting up your learner portal account, choose a password you and
          your child can share.
        </p>
      </header>
      {error && (
        <Message className="mb-4" status="error">
          {error.message}
        </Message>
      )}
      <div className="space-y-6">
        <label className="flex flex-col gap-2">
          <div className={labelTextStyles}>Email</div>
          <Input
            type="email"
            disabled
            fullWidth
            readOnly
            size="small"
            value={email}
          />
        </label>
        <label className="flex flex-col gap-2">
          <div className={labelTextStyles}>Password</div>
          <div className="text-sm text-j-dark-400 mb-2">
            Must be at least {MIN_PASSWORD_LENGTH} characters long
          </div>
          <Input
            type="password"
            disabled={loading}
            fullWidth
            message={
              !isFieldTouched('password') || password.length >= MIN_PASSWORD_LENGTH
                ? undefined
                : `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
            }
            minLength={MIN_PASSWORD_LENGTH}
            placeholder="Set up your password"
            required
            size="small"
            valid={
              !isFieldTouched('password')
                ? undefined
                : password.length >= MIN_PASSWORD_LENGTH
            }
            value={password}
            onBlur={() => addToTouchedFields('password')}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <SmsConsentInput
          loading={loading}
          smsConsent={smsConsent}
          toggleConsent={() => setSmsConsent(!smsConsent)}
        />
        <NewButton
          disabled={!isStepValid() || loading}
          onClick={handleSubmit} // using click handler because using Enter to submit form causes it to navitage incorrectly
          fullWidth
          type="button"
        >
          {loading ? (
            <div className="-m-1">
              <SpinnerV2 size={16} />
            </div>
          ) : (
            'Continue'
          )}
        </NewButton>
      </div>
    </div>
  );
};

export default CreateAccount;
