import { isValid } from 'date-fns';

export const getBirthDateValidationError = (
  value: string,
  touched: boolean,
): string | undefined => {
  if (!touched) {
    return undefined;
  }

  if (!value) {
    return 'This field is required';
  }

  if (!isValid(new Date(value))) {
    return 'Please input a valid date';
  }

  return undefined;
};
