import React, { useState } from 'react';
import { NewButton, NewCard } from 'core-components';
import { QueryResult } from '@apollo/client';
import {
  LoadDefaultPaymentInformationByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  Student,
} from 'generated/graphql';
import _ from 'lodash';
import { UPDATE_FLOWS } from 'app/learner/LearnerAccountV2/LearnerAccount';
import FillBirthDateModal from '../modals/FillBirthDateModal';

interface InactiveStudentCardProps {
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  resetForm: () => void;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  student: Partial<Student>;
  setIsEditPaymentModalOpen: any;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
}

const InactiveStudentCard = ({
  setIsEditPaymentModalOpen,
  cardQuery,
  updateFormState,
  updateFormValue,
  resetForm,
  student,
  studentsQuery,
}: InactiveStudentCardProps) => {
  const [isFillBirthdateModalOpen, setIsFillBirthdateModalOpen] = useState<boolean>(
    false,
  );
  const card = cardQuery?.data?.defaultPaymentInformationByParentId;

  return (
    <>
      <NewCard
        padding="0"
        shadow="none"
        className="border border-solid border-j-dark-100"
      >
        <div className="flex flex-row text-base items-center px-6 py-4 justify-between">
          <div className="font-semibold">
            {student.firstName} {student.lastName}
          </div>

          <div>
            <NewButton
              size="small"
              variant="primary"
              onClick={() => {
                if (_.isEmpty(card)) {
                  return setIsEditPaymentModalOpen(true);
                }
                if (!student.birthDate) {
                  return setIsFillBirthdateModalOpen(true);
                }
                resetForm();

                updateFormState('course_select', 'modal');
                updateFormState(UPDATE_FLOWS.REENGAGE_CHURN, 'updateFlow');
                updateFormValue(student, 'currentStudent');
              }}
            >
              Enroll
            </NewButton>
          </div>
        </div>
      </NewCard>
      {isFillBirthdateModalOpen && (
        <FillBirthDateModal
          student={student}
          updateFormState={updateFormState}
          updateFormValue={updateFormValue}
          resetForm={resetForm}
          studentsQuery={studentsQuery}
          isRefillBirthdayModalOpen={isFillBirthdateModalOpen}
          setIsRefillBirthdayModalOpen={setIsFillBirthdateModalOpen}
        />
      )}
    </>
  );
};

export default InactiveStudentCard;
