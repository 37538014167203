import * as R from 'ramda';

export const PRONOUNS_BY_VALUE = {
  he: `he/him/his`,
  she: `she/her/hers`,
  they: `they/them/theirs`,
  other: `Other`,
} as const;

// just had one of those typescript distraction moments where I really wanted this type to
// be exactly right... ping Agrippa if you see this and know the way. But dont get sucked in!
export const PRONOUNS = R.map(
  k => ({ value: k, label: PRONOUNS_BY_VALUE[k] } as const),
  R.keys(PRONOUNS_BY_VALUE),
);
