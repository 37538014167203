import React, { FC } from 'react';
import _ from 'lodash';

import { Badge, Divider, Message } from 'core-components';

import {
  CORE_PRICES,
  CORE_PRODUCT_METADATA_KEYS,
  formatPrice,
  METADATA_DISPLAY_ORDER,
} from 'constants/subscription_plans';
import { GetCoursesReturnFragment, Maybe, StripeCoupon } from 'generated/graphql';
import { getShortDisplayNameFromCourses } from 'utils/courses';
// import CouponField from 'app/signup_session/ExistingUserCheckoutFlow/pages/components/CouponField';
import {
  getDiscountDollarWithCoupon,
  getPlanMonthlyPrice,
  getPlansDisplayName,
  getSubscriptionTotalPriceBeforeDiscount,
  getTotalCoreFrequency,
} from 'app/learner/LearnerAccountV2/utils';
import { SubscriptionMetaDataWithCourse } from '../../types';

interface Props {
  updatedSubscription: SubscriptionMetaDataWithCourse;
  coupon: Maybe<StripeCoupon> | undefined;
  currentSubscriptionWithCourseAttached: SubscriptionMetaDataWithCourse;
  courseData: GetCoursesReturnFragment[];
  // setCoupon: React.Dispatch<React.SetStateAction<Maybe<StripeCoupon> | undefined>>;
}

const NewSubscriptionPricingSection: FC<Props> = ({
  updatedSubscription,
  coupon,
  currentSubscriptionWithCourseAttached,
  courseData,
  // setCoupon,
}) => {
  const totalCoreFrequency = getTotalCoreFrequency(updatedSubscription);
  const subtotal = getSubscriptionTotalPriceBeforeDiscount(updatedSubscription);
  const discountAmount = getDiscountDollarWithCoupon(coupon, subtotal);
  const total = subtotal - discountAmount;

  const showRoboticsPaymentMessage = _.difference(
    _.flatMap(_.values(updatedSubscription), value => value.courses),
    _.flatMap(
      _.values(currentSubscriptionWithCourseAttached),
      value => value.courses,
    ),
  ).includes('robotics_1');

  return (
    <div className="flex flex-col gap-2">
      <div className="sm:text-base text-sm font-medium">New Pricing</div>
      <div className="flex flex-col gap-1.5">
        {/* CORE PRODUCT PRICING */}
        {totalCoreFrequency > 0 && (
          <div className="w-full flex flex-row justify-between items-center gap-x-2 text-j-dark-400">
            <div>Core Monthly Subscription {totalCoreFrequency}x per week</div>
            <div>{formatPrice(CORE_PRICES[totalCoreFrequency])}/mo</div>
          </div>
        )}
        {/* List out subject frequency & course */}
        {Object.keys(updatedSubscription)
          .sort(
            (a, b) =>
              METADATA_DISPLAY_ORDER.indexOf(a) - METADATA_DISPLAY_ORDER.indexOf(b),
          )
          .map(subjectKey => {
            const { weeklyFrequency, courses } = updatedSubscription[subjectKey];
            const displayName = getPlansDisplayName(subjectKey, weeklyFrequency);
            const price = getPlanMonthlyPrice(subjectKey, weeklyFrequency);

            return (
              <div
                className={`w-full flex flex-row justify-between items-center gap-x-2 text-j-dark-400 ${
                  CORE_PRODUCT_METADATA_KEYS.includes(subjectKey) && 'pl-6'
                }`}
                key={subjectKey}
              >
                <div>
                  {displayName}
                  {courses && courses.length > 0 && (
                    <span className="sm:inline hidden">{` (${getShortDisplayNameFromCourses(
                      courseData,
                      courses,
                    )})`}</span>
                  )}
                </div>
                {!CORE_PRODUCT_METADATA_KEYS.includes(subjectKey) && (
                  <div>
                    {/* if price returns 0 hide show no application badge */}
                    {price === 0 ? (
                      <Badge backgroundColor="j-yellow-200" color="j-yellow-500">
                        N/A
                      </Badge>
                    ) : (
                      `${formatPrice(price)}/mo`
                    )}
                  </div>
                )}
              </div>
            );
          })}

        {/* <Divider className="my-2" />
        <div className="flex flex-row items-center gap-1 py-1.5">
          <CouponField setCoupon={setCoupon} isBootcampSignup={false} />
        </div> */}

        {coupon?.id && (
          <>
            <Divider className="my-2" />
            <div className="flex flex-row justify-between font-medium text-j-dark-400">
              <div>Subtotal</div>
              <div>{formatPrice(subtotal)}/mo</div>
            </div>
            <div className="flex flex-row justify-between text-j-dark-400">
              <div>
                {`Discount: ${coupon.id} (${
                  coupon.amount_off
                    ? `$${coupon?.amount_off / 100}`
                    : `${coupon.percent_off}%`
                } off ${
                  coupon.duration === 'forever' || coupon.duration === 'once'
                    ? coupon.duration
                    : `for ${coupon.duration}`
                }) `}
              </div>
              <div>
                {`-${formatPrice(discountAmount)}${
                  coupon.duration !== 'once' ? '/mo' : ''
                }`}
              </div>
            </div>
          </>
        )}
        <div className="w-full flex flex-row justify-between text-j-dark-600 font-medium">
          <div>Total</div>
          <div>
            {formatPrice(total)}
            {(coupon && !coupon.id) ||
            (coupon && coupon.id && coupon.duration !== 'once')
              ? '/mo'
              : ''}
          </div>
        </div>
      </div>
      {showRoboticsPaymentMessage && (
        <div className="w-full mt-4">
          <Message status="info">
            Please note, an additional charge of $95 will be added to the card on
            file to cover the cost of the robot. The robot is non-refundable.
          </Message>
        </div>
      )}
    </div>
  );
};

export default NewSubscriptionPricingSection;
