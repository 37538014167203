import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ShareMethod } from 'constants/analytics_events';
import { Icon } from 'core-components';
import React, { ReactNode, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components/macro';
import { UserType, REFERRAL_REWARD } from './constants';

interface LinkSharerProps {
  referralUrl: string;
  title?: ReactNode;
  smallPrint?: ReactNode;
  userType: UserType;
}
const SocialButton = styled.div.attrs({
  className:
    'bg-white border border-solid border-j-dark-200 hover:shadow-1 rounded-lg w-10 h-10 flex items-center justify-center cursor-pointer',
})``;
const UNCOPIED = 'Copy Link';
const COPIED = 'Copied!';

// uses the navigator API: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgent
function isMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
}

export const LinkSharer: React.FC<LinkSharerProps> = ({
  referralUrl,
  title = 'Invite Family & Friends',
  smallPrint = 'Must use your unique referral code for the offer to be valid.',
  userType,
}) => {
  const [copied, setCopied] = useState(UNCOPIED);
  useEffect(() => {
    let mounted = true;
    if (copied) {
      setTimeout(() => {
        if (mounted) setCopied(UNCOPIED);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [copied]);
  const constructUtmUrl = (baseUrl: string, src: string) =>
    `${baseUrl}/?utm_source=${src}&utm_campaign=referral`;
  // keep in sync with share_project event
  const logShareEvent = (method: string) => {
    JuniAnalytics.track('share_referral_link', {
      method,
    });
  };
  const handleShareToFacebook = () => {
    window.open(
      `https://www.facebook.com/dialog/share?app_id=858657068100830&quote=${encodeURIComponent(
        REFERRAL_REWARD[userType].facebookShareText,
      )}&href=
      ${encodeURIComponent(constructUtmUrl(referralUrl, 'facebook'))}`,
      undefined,
      'width=626,height=436,menubar=no',
    );
  };
  function handleShareToTwitter() {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        REFERRAL_REWARD[userType].twitterShareText,
      )}&url=${encodeURIComponent(constructUtmUrl(referralUrl, 'twitter'))}`,
      undefined,
      'width=626,height=436,menubar=no',
    );
  }
  // mobile sharing information
  const webShareData = {
    title: 'Check out Juni Learning',
    text: REFERRAL_REWARD[userType].twitterShareText,
    url: referralUrl,
  };
  const shareText = `${webShareData.text} ${constructUtmUrl(
    referralUrl,
    'mobile_share',
  )}`;

  // specifying navigator as any because webshare doesn't exist in type definitions yet
  const nav = navigator as any;
  const canShare = nav.canShare ? nav.canShare(webShareData) : !!nav.share;
  const handleMobileWebShare = () => {
    if (nav.share) {
      nav.share(webShareData);
    }
  };
  const mobile = isMobile();
  const mobileNonNativeShareButtons = (
    <>
      <a
        href={`sms:?&body=${encodeURIComponent(shareText)}`}
        onClick={() => {
          logShareEvent(ShareMethod.sms);
        }}
        className="text-white"
      >
        <SocialButton onClick={handleMobileWebShare}>
          <Icon.CommentText className=" text-j-purple-600 w-6 h-6" />
        </SocialButton>
      </a>
      <a
        href={`https://wa.me/?text=${encodeURIComponent(shareText)}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          logShareEvent(ShareMethod.whatsApp);
        }}
      >
        <SocialButton onClick={handleMobileWebShare}>
          <Icon.WhatsApp className=" text-j-purple-600 w-6 h-6" />
        </SocialButton>
      </a>
    </>
  );
  const desktopShareButtons = (
    <>
      <SocialButton
        onClick={() => {
          JuniAnalytics.track('share_referral_link');
          handleShareToFacebook();
          logShareEvent(ShareMethod.facebook);
        }}
      >
        <Icon.Facebook className=" text-j-purple-600 w-6 h-6" />
      </SocialButton>
      <SocialButton
        onClick={() => {
          JuniAnalytics.track('share_referral_link');
          handleShareToTwitter();
          logShareEvent(ShareMethod.twitter);
        }}
      >
        <Icon.Twitter className=" text-j-purple-600 w-6 h-6" />
      </SocialButton>
    </>
  );
  return (
    <div className="flex flex-col mb-4 font-graphik text-center md:text-left">
      <h3 className="font-3xl font-semibold text-j-dark-600 p-0 m-0 mb-4">
        {title}
      </h3>
      <div className="flex flex-col items-center md:flex-row w-full gap-4">
        <CopyToClipboard
          text={referralUrl}
          onCopy={() => {
            if (mobile && canShare) {
              handleMobileWebShare();
              logShareEvent(ShareMethod.mobileShare);
            } else {
              setCopied(COPIED);
              logShareEvent(ShareMethod.linkCopied);
            }
          }}
        >
          <div className="py-2 px-3 bg-white border border-solid rounded-lg border-j-dark-200 text-sm flex justify-between items-center flex-grow overflow-hidden gap-2 cursor-pointer hover:shadow-1 box-border w-full">
            <div className="truncate">{referralUrl.replace('https://', '')} </div>
            <div className="flex items-center whitespace-nowrap">
              <p className="text-sm text-j-dark-300 p-0 m-0 pr-2 hidden md:block">
                {copied}
              </p>
              <Icon.Copy className="w-4 h-4 text-j-dark-300" />
            </div>
          </div>
        </CopyToClipboard>

        <div className="flex items-center space-x-2">
          {mobile ? (
            <>{!canShare && mobileNonNativeShareButtons} </>
          ) : (
            <>{desktopShareButtons}</>
          )}
        </div>
      </div>
      <div className="text-sm text-j-dark-300 pt-4 hidden md:block">
        {smallPrint}
      </div>
    </div>
  );
};
