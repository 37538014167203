import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { INSTRUCTOR_SMS_CONSENT_LABEL_TEXT } from 'components/SmsConsentCheckbox/constants';
import { NewButton, Icon, Input, Select, TextArea } from 'core-components';
import JuniSpinner from 'components/JuniSpinner';
import { useTouchedFields } from 'utils/useTouchedFields';
import { PRONOUNS } from 'constants/pronouns';
import { GENDERS } from 'constants/genders';
import getYear from 'date-fns/getYear';
import {
  Gender,
  Pronouns,
  useSubmitInstructorSignupMutation,
} from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import { MIN_PASSWORD_LENGTH, BIO_PLACEHOLDER_TEXT } from '../../constants';

const genderOptions = GENDERS.map(g => ({
  label: g.label,
  value: g.value,
}));
const fiveYearsInFuture = getYear(new Date()) + 5;
const gradYearOptions = new Array(30).fill('0').map((_, i) => ({
  label: String(fiveYearsInFuture - i),
  value: fiveYearsInFuture - i,
}));
const ERR_MESSAGE = {
  TOS_NOT_SELECTED:
    'You must agree to the Juni Terms of Use and Privacy Policy in order to sign up.',
  NO_PW: 'Please enter a password.',
  PW_DO_NOT_MEET_MIN_LENGTH: `Password must be at least ${MIN_PASSWORD_LENGTH} characters`,
  MISSING_FIELD: `Please ensure you have input all the information.`,
};

interface Props {
  isRefreshingInvite: boolean;
  inviteCode: string;
  isLoading: boolean;
  email: string;
}

const InstructorSignup: React.FC<Props> = ({
  isRefreshingInvite,
  inviteCode,
  isLoading,
  email,
}) => {
  const [
    submitInstructorSignupMutation,
    { loading: submitLoading },
  ] = useSubmitInstructorSignupMutation();

  const [step, setStep] = useState<string>('account');
  const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
  // account fields
  const [password, setPassword] = useState('');
  const [smsConsentChecked, setSmsConsentChecked] = useState(false);
  const [isTosAgreed, setIsTosAgreed] = useState(false);

  // bio fields
  const [gender, setGender] = useState<Gender | undefined>();
  const [pronouns, setPronouns] = useState<Pronouns | undefined>();
  const [gradYear, setGradYear] = useState<number | undefined>();
  const [bio, setBio] = useState<string | undefined>();
  const [minIdealWeeklyHours, setMinIdealWeeklyHours] = useState<
    number | undefined
  >();
  const [maxIdealWeeklyHours, setMaxIdealWeeklyHours] = useState<
    number | undefined
  >();

  const { isFieldTouched, addToTouchedFields } = useTouchedFields();

  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');

    if (!isTosAgreed) {
      setErrorMessage(ERR_MESSAGE.TOS_NOT_SELECTED);
      return;
    }
    if (
      !gender ||
      !pronouns ||
      !gradYear ||
      !bio ||
      !maxIdealWeeklyHours ||
      !minIdealWeeklyHours
    ) {
      setErrorMessage(ERR_MESSAGE.MISSING_FIELD);
      return;
    }
    try {
      await submitInstructorSignupMutation({
        variables: {
          input: {
            inviteCode,
            password,
            smsConsent: smsConsentChecked,
            gender,
            pronouns,
            bio,
            gradYear,
            minIdealWeeklyHours,
            maxIdealWeeklyHours,
          },
        },
      });

      history.push('/teacher/login/');
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
        setErrorMessage(err.toString());
      }
    }
  };

  if (isRefreshingInvite || isLoading) {
    return <JuniSpinner size={80} />;
  }

  return (
    <div id="valid-teacher" className="max-w-2xl m-auto py-4 sm:py-8">
      <header className="text-center">
        <h1 className="text-j-dark-600 m-0 text-2xl font-medium">
          {step === 'account' ? (
            <>
              Welcome to Juni!
              <br />
              Set up your Instructor Portal Account.
            </>
          ) : (
            <>Tell us a little about you...</>
          )}
        </h1>
      </header>
      {errorMessage && (
        <p className="bg-j-pink-700 text-white rounded p-3 my-4 flex leading-4 text-sm items-center">
          <Icon.Error />
          <span className="ml-2">{errorMessage}</span>
        </p>
      )}
      <form className="pt-4 space-y-2.5" onSubmit={e => handleSubmit(e)}>
        {step === 'account' ? (
          <>
            <label className="block">
              <div className="text-sm text-j-dark-600 font-medium">Email</div>
              <Input
                type="email"
                disabled
                fullWidth
                readOnly
                size="small"
                value={email}
              />
            </label>
            <label className="block">
              <div className="text-sm text-j-dark-600 font-medium">Password</div>
              <div className="text-sm text-j-dark-400">
                Must be at least {MIN_PASSWORD_LENGTH} characters long
              </div>
              <Input
                type="password"
                disabled={isLoading}
                fullWidth
                message={
                  !isFieldTouched('password') ||
                  password.length >= MIN_PASSWORD_LENGTH
                    ? undefined
                    : `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
                }
                minLength={MIN_PASSWORD_LENGTH}
                placeholder="Set up your password"
                required
                size="small"
                valid={
                  !isFieldTouched('password')
                    ? undefined
                    : password.length >= MIN_PASSWORD_LENGTH
                }
                value={password}
                onBlur={() => addToTouchedFields('password')}
                onChange={e => {
                  setPassword(e.target.value);
                  if (e.target.value && errorMessage === ERR_MESSAGE.NO_PW) {
                    setErrorMessage(undefined);
                  }
                  if (
                    e.target.value &&
                    e.target.value.length >= MIN_PASSWORD_LENGTH &&
                    errorMessage === ERR_MESSAGE.PW_DO_NOT_MEET_MIN_LENGTH
                  ) {
                    setErrorMessage(undefined);
                  }
                }}
              />
            </label>
            <div className="text-sm text-j-dark-500">
              <input
                id="tos"
                type="checkbox"
                required
                checked={isTosAgreed}
                onChange={() => {
                  setIsTosAgreed(!isTosAgreed);
                  if (
                    !isTosAgreed &&
                    errorMessage === ERR_MESSAGE.TOS_NOT_SELECTED
                  ) {
                    setErrorMessage(undefined);
                  }
                }}
              />

              <label htmlFor="tos">
                I agree to the{' '}
                <a
                  href="https://junilearning.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tos"
                >
                  Juni Learning Terms of Use and Privacy Policy
                </a>
                .
              </label>
            </div>
            <div className="text-sm text-j-dark-500">
              <input
                id="sms-consent"
                type="checkbox"
                checked={smsConsentChecked}
                onChange={() => setSmsConsentChecked(!smsConsentChecked)}
              />
              <label htmlFor="sms-consent">
                {INSTRUCTOR_SMS_CONSENT_LABEL_TEXT}
              </label>
            </div>
            <NewButton
              variant="primary"
              disabled={!password || !isTosAgreed}
              renderIconRight={props => <Icon.ArrowRight {...props} />}
              onClick={() => {
                if (!password) {
                  setErrorMessage(ERR_MESSAGE.NO_PW);
                  return;
                }
                if (password.length < MIN_PASSWORD_LENGTH) {
                  setErrorMessage(ERR_MESSAGE.PW_DO_NOT_MEET_MIN_LENGTH);
                  return;
                }
                if (!isTosAgreed) {
                  setErrorMessage(ERR_MESSAGE.TOS_NOT_SELECTED);
                  return;
                }
                setStep('bio');
              }}
            >
              Next
            </NewButton>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-2 w-full gap-2">
                <div className="w-full">
                  <label
                    htmlFor="gender"
                    className="text-sm text-j-dark-600 font-medium mb-2"
                  >
                    Gender
                  </label>
                  <Select
                    fullWidth
                    name="gender"
                    id="gender"
                    size="small"
                    required
                    selected={gender}
                    onChange={value => setGender(value)}
                    options={genderOptions}
                    placeholder="Select your gender"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="pronouns"
                    className="text-sm text-j-dark-600 font-medium mb-2"
                  >
                    Pronouns
                  </label>
                  <Select
                    fullWidth
                    name="pronouns"
                    id="pronouns"
                    size="small"
                    required
                    selected={pronouns}
                    onChange={value => setPronouns(value as Pronouns)}
                    options={PRONOUNS}
                    placeholder="Select your pronouns"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="grad-year"
                    className="text-sm text-j-dark-600 font-medium mb-2"
                  >
                    Graduation Year
                  </label>
                  <Select
                    fullWidth
                    name="grad-year"
                    id="grad-year"
                    size="small"
                    required
                    selected={gradYear}
                    onChange={value => setGradYear(value)}
                    options={gradYearOptions}
                    placeholder="Select your graduation year"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="min-wk-hrs"
                    className="text-sm text-j-dark-600 font-medium mb-2"
                  >
                    Ideal Weekly Teaching Hours (Min/Max)
                  </label>
                  <div className="w-full flex flex-row gap-2">
                    <Input
                      type="number"
                      fullWidth
                      name="min-wk-hrs"
                      id="min-wk-hrs"
                      size="small"
                      required
                      min={1}
                      max={maxIdealWeeklyHours}
                      value={minIdealWeeklyHours ?? undefined}
                      onChange={e =>
                        setMinIdealWeeklyHours(Number(e.currentTarget.value))
                      }
                      placeholder="Min"
                    />

                    <Input
                      type="number"
                      fullWidth
                      name="max-wk-hrs"
                      id="max-wk-hrs"
                      size="small"
                      min={minIdealWeeklyHours}
                      required
                      value={maxIdealWeeklyHours ?? undefined}
                      onChange={e =>
                        setMaxIdealWeeklyHours(Number(e.currentTarget.value))
                      }
                      placeholder="Max"
                    />
                  </div>
                </div>
                <div className="w-full col-span-2">
                  <label
                    htmlFor="bio"
                    className="text-sm text-j-dark-600 font-medium mb-2"
                  >
                    Bio
                  </label>
                  <TextArea
                    fullWidth
                    name="bio"
                    id="bio"
                    size="small"
                    required
                    value={bio ?? undefined}
                    rows={6}
                    onChange={e => setBio(e.currentTarget.value)}
                    placeholder={BIO_PLACEHOLDER_TEXT}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row justify-between items-center">
              <NewButton variant="secondary" onClick={() => setStep('account')}>
                Back
              </NewButton>
              <NewButton
                intent="success"
                type="submit"
                disabled={
                  !gender ||
                  !pronouns ||
                  !gradYear ||
                  !minIdealWeeklyHours ||
                  !maxIdealWeeklyHours ||
                  !bio
                }
              >
                {submitLoading ? <SpinnerV2 /> : 'Submit'}
              </NewButton>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default InstructorSignup;
