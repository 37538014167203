import React, { FC } from 'react';
import { QueryResult } from '@apollo/client';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { NewButton, Icon } from 'core-components';
import {
  LoadProductsByStripeIdQuery,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import { checkIfStudentHasMoreThanOneWeeklyClassInSubject } from 'utils/stripe';
import LearnerPortalModal from '../LearnerPortalModal';
import { FormState, FormValues } from '../types';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  productsQuery: QueryResult<LoadProductsByStripeIdQuery>;
}

const CancelCheckModal: FC<Props> = ({
  updateFormState,
  formState,
  formValues,
  studentsQuery,
  productsQuery,
}) => {
  const { subscription } = formValues;
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );
  const products = productsQuery?.data?.productsByStripeIds?.items || [];
  const studentHasMoreThanOneWeeklyClassInSubject = checkIfStudentHasMoreThanOneWeeklyClassInSubject(
    subscription,
    products,
  );
  return (
    <LearnerPortalModal
      formState={formState}
      updateFormState={updateFormState}
      title="Pause or Cancel Subscription"
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            JuniAnalytics.track(`${formState.modal}_button_clicked`, {
              funnel: 'subscription_upgrade_downgrade',
              sourceModal: formState.modal,
              destinationModal: 'cancel_optional_discount',
              actionFlow: formState.downgradeOption || formState.updateFlow,
            });
            updateFormState('cancel_optional_discount', 'modal');
          }}
        >
          Yes, I would like to pause/cancel
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            const modalName =
              currentStudents[0].hasMultipleTracks ||
              studentHasMoreThanOneWeeklyClassInSubject
                ? 'downgrade_select'
                : 'subscription_update';
            updateFormState(modalName, 'modal');
          }}
          variant="secondary"
          renderIconLeft={props => <Icon.ChevronLeft {...props} />}
        >
          Back
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 leading-5">
        <p>
          Please{' '}
          <a className="text-j-dark-600" href="https://junilearning.com/contact/">
            contact us
          </a>{' '}
          instead of cancelling your subscription:
          <ul>
            <li>if you would like to change your instructor, or </li>
            <li>if you are experiencing learning challenges, or </li>
            <li>
              if you would like to pause your classes for <em>up to one month</em>.
            </li>
          </ul>
          We will reach out to you via email to confirm your pause or cancellation
        </p>
        <p className="font-semibold">Are you sure you would like to pause/cancel?</p>
      </div>
    </LearnerPortalModal>
  );
};

export default CancelCheckModal;
