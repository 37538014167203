import React, { useState } from 'react';
import { NewButton } from 'core-components';
import styles from './index.module.css';

interface PaneProps {
  title: string;
  children: React.ReactNode;
}

const Pane: React.FC<PaneProps> = ({ children }) => <>{children}</>;

export interface TabsProps {
  children: React.ReactElement<PaneProps>[] | React.ReactElement<PaneProps>;
}

/**
 * Implements a view structure where a user can switch between several views by clicking
 * on `Button` components above a `Tabs.Pane` component. View options must be wrapped by `Tabs.Pane`.
 *
 * The component in Storybook deliberately has no controls, as the only prop
 * that gets passed to it is `children`.
 */
function Tabs({ children }: TabsProps) {
  const [selectedPane, setSelectedPane] = useState<number>(0);
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="ui-vertical-spacing">
      <div className={`${styles.tabHeader} ui-horizontal-spacing`}>
        {React.Children.map(children, (child, index) => (
          <NewButton
            key={child.props.title}
            variant={selectedPane === index ? 'primary' : 'secondary'}
            onClick={() => setSelectedPane(index)}
          >
            {child.props.title}
          </NewButton>
        ))}
      </div>
      {childrenArray.length && childrenArray[selectedPane]}
    </div>
  );
}

Tabs.Pane = Pane;

export default Tabs;
