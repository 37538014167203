export const ShareMethod = {
  linkCopied: 'link_copied',
  email: 'email',

  facebook: 'facebook',
  mobileShare: 'mobile_share',
  sms: 'sms',
  twitter: 'twitter',
  whatsApp: 'whatsapp',

  // indirect sharing
  discord: 'discord',
  googleChat: 'google_chat',
};
