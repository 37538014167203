import { useState } from 'react';
import { parseISO, addDays, isBefore } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import classNames from 'classnames';
import _ from 'lodash';
import { ONBOARDING_START_DATE_BUFFER_DAYS } from 'constants/onboarding_handoff_buffer';

import { Message, NewButton, Icon, Select, Input } from 'core-components';
import ModalWindow, {
  ModalButtonsFooter,
} from 'core-components/NewModalWindow/NewModalWindow';

import {
  getSubscriptionMetaData,
  dateToShortIsoString,
} from 'app/learner/LearnerAccountV2/utils';
import { US_TIMEZONES, FOREIGN_TIMEZONES } from 'constants/timezones';
import { DAYS_OF_THE_WEEK } from 'constants/dates';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';

import { START_HOUR, END_HOUR } from 'utils/enrollment';

import guessTimezoneValue from 'utils/guessTimezoneValue';
import LearnerPortalModal from '../LearnerPortalModal';

export const PRIORITY_LEVELS = {
  0: 'First',
  1: 'Second',
  2: 'Third',
};

const NUMBER_OF_AVAILS = 3;

const TableHeaderDate = ({ day }) => (
  <div className="flex flex-col items-center flex-1 flex-shrink-0 text-center">
    <div className="w-20 mr-2.5">
      <div className={classNames('font-graphik text-j-dark-600 text-sm')}>{day}</div>
    </div>
  </div>
);

const TimeButton = ({ selected, disabled, onClick, children, value }) => (
  <button
    className={classNames(
      disabled
        ? 'bg-j-gray-100 text-j-dark-300 cursor-auto'
        : selected
        ? 'bg-j-purple-200 font-medium text-j-dark-600 border border-solid border-j-purple-400'
        : 'bg-j-gray-200 text-j-dark-600',
      'rounded-md',
      'font-graphik text-xs tracking-normal',
      'w-20',
      'hover:shadow-none',
      'ignore-juni-globals',
      'py-1 px-2',
    )}
    onClick={disabled ? _.noop : onClick}
    value={value}
  >
    {children}
  </button>
);

const DateTimeSelectorModal = ({
  courses,
  setIsAvailabilityModalOpen,
  formValues,
  updateFormValue,
  activeSubject,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const courseNames = courses
    .filter(course =>
      formValues.metadataNew[activeSubject].courses.includes(course.name),
    )
    .map(course => course.displayName)
    .join(', ');

  const availabilities = _.map(_.range(START_HOUR, END_HOUR), hour => {
    const items = _.map(_.range(0, DAYS_OF_THE_WEEK.length), day => {
      // arbitrarily selected a month/year in which the 1st is a Monday
      // the js Date() constructor conveniently handles the case where END_HOUR overflows (>24)
      // need to use Date.UTC, otherwise the Date constructor uses the local timezone
      const currentDatetimeUTC = new Date(
        Date.UTC('2021', '10', day + 1, hour, 0, 0),
      );
      const currentTime = utcToZonedTime(currentDatetimeUTC, formValues.timezone);
      const selected = selectedDates.includes(currentDatetimeUTC.toISOString());
      const disabled = !selected && selectedDates.length === NUMBER_OF_AVAILS;
      return (
        <div
          key={format(currentTime, 'yyyy-mm-dd hh:mm')}
          className="flex flex-1 mr-2.5 justify-center"
        >
          <TimeButton
            selected={selected}
            disabled={disabled}
            value={currentDatetimeUTC.toISOString()}
            onClick={event => {
              const date = event.target.value;
              if (selectedDates.includes(date)) {
                setSelectedDates(
                  selectedDates.filter(selectedDate => selectedDate !== date),
                );
              } else {
                setSelectedDates([...selectedDates, date]);
              }
            }}
          >
            {format(currentTime, 'h:mm a')}
          </TimeButton>
        </div>
      );
    });
    return <div className="flex mb-2">{items}</div>;
  });

  return (
    <ModalWindow
      isOpen
      closeModal={() => {
        setIsAvailabilityModalOpen(false);
      }}
      title="Schedule Preferences"
      description={courseNames}
      renderFooter={() => (
        <ModalButtonsFooter
          primary={
            <NewButton
              onClick={() => {
                updateFormValue(
                  {
                    ...formValues.metadataNew,
                    [activeSubject]: {
                      ...formValues.metadataNew[activeSubject],
                      availabilities: selectedDates,
                    },
                  },
                  'metadataNew',
                );
                setIsAvailabilityModalOpen(false);
              }}
              disabled={selectedDates.length < NUMBER_OF_AVAILS}
            >
              <div className="font-medium">Confirm</div>
            </NewButton>
          }
          secondary={
            <NewButton
              variant="secondary"
              onClick={() => {
                setIsAvailabilityModalOpen(false);
              }}
            >
              <div className="font-medium">Cancel</div>
            </NewButton>
          }
        />
      )}
    >
      <div className="flex flex-col w-full">
        <div className={classNames('flex flex-col w-full overflow-x-auto')}>
          <Message
            className="mb-4"
            status="info"
            description={`Select 3 times on a weekly basis (${selectedDates.length}/3).`}
          ></Message>
          <div className={classNames('flex mb-3')}>
            {DAYS_OF_THE_WEEK.map(day => (
              <TableHeaderDate key={day} day={day} />
            ))}
          </div>
          <div className="flex flex-col">{availabilities}</div>
        </div>
      </div>
    </ModalWindow>
  );
};

const ScheduleSelectorModal = ({
  coursesQuery,
  formValues,
  formState,
  updateFormState,
  updateFormValue,
}) => {
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [activeSubject, setActiveSubject] = useState(undefined);
  const [timezoneIssue, setTimezoneIssue] = useState(false);
  const firstAvailableDate = new Date(new Date().setHours(0, 0, 0, 0));
  firstAvailableDate.setDate(
    firstAvailableDate.getDate() + ONBOARDING_START_DATE_BUFFER_DAYS,
  );
  const firstAvailableDateString = dateToShortIsoString(firstAvailableDate) ?? '';

  const courses = coursesQuery?.data?.getCourses;

  const {
    currentStudent,
    metadataNew,
    subscription,
    startingDate,
    timezone,
  } = formValues;

  const studentCurrentCourses = currentStudent.hasMultipleTracks
    ? currentStudent.tracks
    : [currentStudent.track];

  // e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
  const courseMetadataObj = getSubscriptionMetaData(subscription);

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const changedSubscriptions = _.filter(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );

  const nextButtonEnabled =
    startingDate &&
    !isBefore(new Date(startingDate), addDays(new Date(), 3)) &&
    timezone &&
    _.every(
      changedSubscriptions,
      ([, metadata]) => metadata.availabilities !== undefined,
    );

  return (
    <>
      {isAvailabilityModalOpen ? (
        <DateTimeSelectorModal
          setIsAvailabilityModalOpen={setIsAvailabilityModalOpen}
          formValues={formValues}
          updateFormValue={updateFormValue}
          activeSubject={activeSubject}
          courses={courses}
        />
      ) : (
        <LearnerPortalModal
          title={formState.updateFlow}
          formState={formState}
          updateFormState={updateFormState}
          renderPrimaryButton={
            <NewButton
              disabled={!nextButtonEnabled}
              onClick={() => {
                const modalName = 'payment_check';
                updateFormState(modalName, 'modal');
              }}
            >
              <div className="font-medium">Next</div>
            </NewButton>
          }
        >
          <div className="flex flex-col w-full gap-2">
            <div className="text-j-dark-600 font-graphik text-sm font-medium">
              Timezone
            </div>
            <div className="flex flex-col w-full mb-2">
              <Select
                placeholder="Select a timezone"
                options={US_TIMEZONES.concat(FOREIGN_TIMEZONES).map(tz => ({
                  value: tz.value,
                  label: tz.displayName,
                }))}
                selected={timezone}
                onChange={option => {
                  setTimezoneIssue(false);
                  updateFormValue(option, 'timezone');
                  if (!formValues.currentStudent.timezone) {
                    updateFormValue(
                      {
                        ...formValues.currentStudent,
                        timezone: option,
                      },
                      'currentStudent',
                    );
                  }
                }}
                size="small"
                fullWidth
              />
            </div>
            <div className="text-j-dark-600 text-sm font-medium">
              Schedule Preferences
            </div>
            <div className="flex flex-col">
              {_.map(changedSubscriptions, ([key, newState]) => (
                <div className="flex flex-col" key={key}>
                  <div className="flex flex-row justify-between mb-1 text-sm text-j-dark-600">
                    <div className="flex items-center">
                      {courses
                        .filter(course => newState.courses.includes(course.name))
                        .map(course => course.displayName)
                        .join(', ')}
                    </div>
                    <div className="flex">
                      {newState.availabilities ? (
                        <NewButton
                          size="small"
                          className="text-j-dark-600 p-0 hover:bg-opacity-20 font-light bg-opacity-0"
                          onClick={() => {
                            setActiveSubject(key);
                            setIsAvailabilityModalOpen(true);
                          }}
                          disabled={!formValues.timezone}
                        >
                          Reselect
                        </NewButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="flex w-full flex-row">
                    {newState.availabilities ? (
                      <div className="flex flex-row w-full mb-3 text-j-dark-600 bg-j-gray-200 p-2 justify-between items-center border rounded-md">
                        {Object.entries(PRIORITY_LEVELS).map(([priority, label]) => (
                          <div className="flex flex-col p-1">
                            <div className="flex text-sm pb-1 pr-1">{`${label} option`}</div>
                            <div className="flex bg-white border rounded-md p-3">
                              {format(
                                utcToZonedTime(
                                  parseISO(newState.availabilities[priority]),
                                  formValues.timezone,
                                ),
                                'EEE, h:mm a',
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="pt-1 mb-2 w-full">
                        <NewButton
                          onClick={() => {
                            setTimezoneIssue(false);
                            if (formValues.timezone) {
                              setActiveSubject(key);
                              setIsAvailabilityModalOpen(true);
                            } else {
                              setTimezoneIssue(true);
                            }
                          }}
                          size="medium"
                          className="p-2 w-full hover:bg-opacity-0 bg-opacity-0 text-j-dark-200 border border-solid hover:border-j-dark-300 border-j-dark-200"
                        >
                          <div className="flex justify-between w-full">
                            <div className="flex font-normal">
                              Add schedule options
                            </div>
                            <div className="flex">
                              <Icon.Calendar2 />
                            </div>
                          </div>
                        </NewButton>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {timezoneIssue && (
              <Message status="error" className="my-2">
                Please select a timezone.
              </Message>
            )}
            <div className="text-j-dark-600 text-sm font-medium">Starting Date</div>
            <Input
              size="small"
              type="date"
              value={startingDate}
              name="startingDate"
              fullWidth
              onChange={e => {
                try {
                  updateFormValue(
                    format(
                      utcToZonedTime(
                        e.target.value,
                        timezone || guessTimezoneValue(),
                      ),
                      'yyyy-MM-dd',
                    ),
                    'startingDate',
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
              valid={
                !startingDate
                  ? undefined
                  : utcToZonedTime(
                      startingDate,
                      timezone || guessTimezoneValue(),
                    ).getTime() < firstAvailableDate.getTime()
                  ? false
                  : undefined
              }
              min={firstAvailableDateString}
              message={
                !startingDate ||
                utcToZonedTime(
                  startingDate,
                  timezone || guessTimezoneValue(),
                ).getTime() < firstAvailableDate.getTime()
                  ? `We need at least ${ONBOARDING_START_DATE_BUFFER_DAYS} days from today to set up your classes.`
                  : undefined
              }
            />
          </div>
        </LearnerPortalModal>
      )}
    </>
  );
};

export default ScheduleSelectorModal;
