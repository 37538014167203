import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BASE_PATH } from './constants';
import { STEP_PATHS } from '../steps/constants';
import { getFirstIncompleteStep, isOnValidStep } from '../steps/utils';
import useSignupData from './useSignupData';

const useValidPathGuard = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { signupData } = useSignupData();

  useEffect(() => {
    if (signupData && !isOnValidStep(signupData, pathname)) {
      const nextStep = getFirstIncompleteStep(signupData);
      const nextPath = `/${BASE_PATH}/${STEP_PATHS[nextStep]}${search}`;
      history.replace(nextPath);
    }
  }, [history, pathname, search, signupData]);
};

export default useValidPathGuard;
