import React, { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prompt } from 'react-router-dom';
import { noop, isEqual } from 'lodash';

import { Message, ReactSelectField } from 'components/ui';
import { Icon, NewButton } from 'core-components';
import MarkdownText from 'components/MarkdownText';
import { TUTORING_SUBJECT_LOGGING_OPTIONS } from 'constants/tutoring';
import { CheckboxLabel, Checkmark, Instructions, TextAreaStyles } from '../styles';

type CourseOptionsType = {
  label: string;
  value?: string;
  options?: {
    value: string;
    label: string;
  }[];
}[];

interface TextEditorProps {
  checkboxLabel: string;
  instructions: string;
  placeholder: string;
  requireSubjectsCoveredLogging?: boolean;
  requireCoursesCoveredLogging?: boolean;
  initialText?: string;
  initialIsCheckboxSelected?: boolean;
  initialSubjectsCoveredSelected?: string[];
  initialCoursesCoveredSelected?: string[];
  clearTextOnDisabled?: boolean;
  submit: (
    text: string,
    isDisabled: boolean,
    subjectsCovered?: string[],
    coursesCovered?: string[],
  ) => Promise<void>;
  cancellable?: boolean;
  onCancel?: () => void;
  editing?: boolean;
  isEditingValueChange?: boolean;
  courseOptions: CourseOptionsType;
}

const TextEditor: FC<TextEditorProps> = ({
  checkboxLabel,
  instructions,
  placeholder,
  requireSubjectsCoveredLogging = false,
  requireCoursesCoveredLogging = false,
  initialText = '',
  initialIsCheckboxSelected = false,
  initialSubjectsCoveredSelected = [],
  initialCoursesCoveredSelected = [],
  clearTextOnDisabled = false,
  submit,
  cancellable = false,
  onCancel = noop,
  editing = false,
  isEditingValueChange = false,
  courseOptions,
}) => {
  const [text, setText] = useState(initialText);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(
    initialIsCheckboxSelected,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [subjectsCovered, setSubjectsCovered] = useState<string[]>(
    initialSubjectsCoveredSelected,
  );
  const [coursesCovered, setCoursesCovered] = useState<string[]>(
    initialCoursesCoveredSelected,
  );

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const onSubjectsCoveredChange = (value: (string | undefined)[]) => {
    const filteredValue = value.filter((v): v is string => !!v); // to appease typescript
    setSubjectsCovered(filteredValue);
  };

  const onCoursesCoveredChange = (value: (string | undefined)[]) => {
    const filteredValue = value.filter((v): v is string => !!v); // to appease typescript
    setCoursesCovered(filteredValue);
  };

  const onCheckboxChange = () => {
    if (!isCheckboxSelected && clearTextOnDisabled) {
      setText('');
      setSubjectsCovered([]);
      setCoursesCovered([]);
    }
    setIsCheckboxSelected(!isCheckboxSelected);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMsg(undefined);
    try {
      await submit(text, isCheckboxSelected, subjectsCovered, coursesCovered);
    } catch (err) {
      console.error(err);
      const error = err as any;
      if (error?.response?.data?.error) {
        setErrorMsg(error?.response?.data?.error);
      }
      setIsSubmitting(false);
    }
  };

  const isFormChanged =
    text !== initialText ||
    !isEqual(subjectsCovered.sort(), initialSubjectsCoveredSelected.sort()) ||
    !isEqual(coursesCovered.sort(), initialCoursesCoveredSelected.sort()) ||
    isEditingValueChange ||
    isCheckboxSelected !== initialIsCheckboxSelected;
  const isSubmitDisabled = editing
    ? isSubmitting ||
      (!text && !isCheckboxSelected) ||
      (requireSubjectsCoveredLogging &&
        !isCheckboxSelected &&
        subjectsCovered.length === 0) ||
      (requireCoursesCoveredLogging &&
        !isCheckboxSelected &&
        coursesCovered.length === 0) ||
      !isFormChanged
    : isSubmitting ||
      (!text && !isCheckboxSelected) ||
      (requireSubjectsCoveredLogging &&
        !isCheckboxSelected &&
        subjectsCovered.length === 0) ||
      (requireCoursesCoveredLogging &&
        !isCheckboxSelected &&
        coursesCovered.length === 0);

  return (
    <TextAreaStyles className="font-graphik text-j-dark-600">
      <Instructions>
        <ReactMarkdown
          renderers={{
            link: ({ href, children }) => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
          }}
        >
          {instructions}
        </ReactMarkdown>
      </Instructions>
      <Prompt
        when={text.length > 0}
        message="You have unsaved changes; are you sure that you want to leave?"
      />
      {requireSubjectsCoveredLogging && !isCheckboxSelected && (
        <ReactSelectField
          className="w-full mb-4"
          label="Subjects Covered (required)"
          name="subjectsCovered"
          placeholder="Choose Subjects..."
          options={TUTORING_SUBJECT_LOGGING_OPTIONS}
          value={subjectsCovered}
          onChangeMulti={onSubjectsCoveredChange}
          isMulti
        />
      )}
      {requireCoursesCoveredLogging && !isCheckboxSelected && (
        <ReactSelectField
          className="w-full mb-4"
          label="Courses Covered (required)"
          name="coursesCovered"
          placeholder="Choose Courses..."
          options={courseOptions}
          value={coursesCovered}
          onChangeMulti={onCoursesCoveredChange}
          isMulti
        />
      )}
      <MarkdownText
        text={text}
        handleChange={handleTextChange}
        placeholder={placeholder}
        isDisabled={isCheckboxSelected}
        isEditable
      />
      <div className="flex flex-row justify-between pt-4">
        <CheckboxLabel className="flex items-center w-2/3">
          {checkboxLabel}
          <input
            type="checkbox"
            checked={isCheckboxSelected}
            onChange={onCheckboxChange}
          />
          <Checkmark />
        </CheckboxLabel>
        <div className="flex space-x-2">
          {cancellable && (
            <NewButton variant="secondary" onClick={onCancel}>
              Cancel
            </NewButton>
          )}
          <NewButton disabled={isSubmitDisabled} onClick={handleSubmit}>
            {editing ? 'Update' : 'Submit'}
            <Icon.ChevronRight orientation="right" className="ml-2" />
          </NewButton>
        </div>
      </div>
      {errorMsg && <Message status="error">Oops! {errorMsg}</Message>}
    </TextAreaStyles>
  );
};

export default TextEditor;
