import React from 'react';
import { useSignupData } from 'app/signup_2023/hooks';
import { format } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import { capitalize } from 'lodash';
import { ErrorableLoading } from 'components/ui';
import { subtitleTextStyle } from '../../constants';

const ParentStudentInfo = () => {
  const { signupData, error, loading } = useSignupData();
  const parent = signupData?.parent;
  const student = signupData?.students?.[0];

  if (!signupData || error || loading) {
    return <ErrorableLoading error={error} />;
  }
  const parentInfo = [
    {
      label: 'Name',
      value: `${parent?.firstName || ''} ${parent?.lastName || ''}`.trim(),
    },
    {
      label: 'Email',
      value: parent?.email ?? '--',
    },
    {
      label: 'Phone Number',
      value: parent?.phone
        ? parsePhoneNumber(parent?.phone, 'US').formatInternational()
        : '--',
    },
  ];
  const studentInfo = [
    {
      label: 'Name',
      value: `${student?.firstName || ''} ${student?.lastName || ''}`.trim(),
    },
    {
      label: 'Birthdate',
      value: student?.birthdate
        ? format(new Date(student?.birthdate), "MM'/'dd'/'yyyy")
        : '--',
    },
    {
      label: 'Gender',
      value: capitalize(student?.gender ?? '--'),
    },
    {
      label: 'Learning Preferences',
      value: student?.learningPreferences ?? '--',
    },
  ];

  return (
    <>
      <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
        <h2 className={subtitleTextStyle}>Parent Details</h2>
        <div className="space-y-4">
          {parentInfo.map(({ label, value }) => (
            <div key={label}>
              <div className="text-j-dark-400 pb-1">{label}:</div>
              <div className="text-j-dark-600 font-medium">{value}</div>
            </div>
          ))}
        </div>
      </section>
      <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
        <h2 className={subtitleTextStyle}>Child Details</h2>
        <div className="space-y-4">
          {studentInfo.map(({ label, value }) => (
            <div key={label}>
              <div className="text-j-dark-400 pb-1">{label}:</div>
              <div className="text-j-dark-600 font-medium">{value}</div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ParentStudentInfo;
