import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { NewButton, NewCard } from 'core-components';
import { QueryResult } from '@apollo/client';
import { LoadStudentsByParentIdsQuery, StripeSubscription } from 'generated/graphql';
import { CurrentSubscriptionDetails } from 'app/learner/LearnerAccountV2/components';
import {
  getStudentNamesFromStudents,
  getStudentsFromSubscription,
} from 'app/learner/LearnerAccountV2/utils';

interface SubscriptionCardProps {
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  resetForm: () => void;
  subscription: StripeSubscription;
}

const SubscriptionCard = ({
  subscription,
  studentsQuery,
  updateFormState,
  updateFormValue,
  resetForm,
}: SubscriptionCardProps) => {
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const matchingStudents = getStudentsFromSubscription(subscription, students);
  const studentNames = getStudentNamesFromStudents(matchingStudents);

  const handleClick = () => {
    resetForm();
    const modalName = 'subscription_update';
    JuniAnalytics.track(`subscription_card_button_clicked`, {
      funnel: 'subscription_upgrade_downgrade',
      destinationModal: modalName,
    });

    updateFormState(modalName, 'modal');
    updateFormValue(subscription, 'subscription');
    if (matchingStudents.length === 1) {
      updateFormValue(matchingStudents[0], 'currentStudent');
    }
  };

  return (
    <NewCard
      padding="0"
      shadow="none"
      className="border border-solid border-j-dark-100"
    >
      <div className="flex flex-row text-base items-center px-6 py-4 justify-between">
        <div className="font-bold">{studentNames}</div>
        {subscription.status !== 'canceled' && (
          <div>
            <NewButton size="xsmall" variant="secondary" onClick={handleClick}>
              Manage
            </NewButton>
          </div>
        )}
      </div>
      <NewCard.Divider />
      <div className="px-6 py-4">
        <CurrentSubscriptionDetails subscription={subscription} />
      </div>
    </NewCard>
  );
};

export default SubscriptionCard;
