import JuniSpinner from 'components/JuniSpinner';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { omit } from 'ramda';

import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';

import { COURSE_FORMAT } from 'constants/signup_sessions';
import { FeatureFlag, isEnabled } from 'app/feature_flags/feature-flag';
import Onboarding from './Onboarding';
import CheckoutFlow from './CheckoutFlow';
import ExistingUserCheckoutFlow from './ExistingUserCheckoutFlow';

import SignupSessionContext from './hooks/useSignupContext/SignupSessionContext';
import NavRouterContext from './hooks/useNavRouter/NavStateContext';
import useSignupSession from './hooks/useSignupSession';

import {
  SessionFlags,
  SignupData,
  SignupSessionProps,
  SubmitStepOptions,
} from './types';
import ReferralsPage from './SignupLandingPages';
import GuestAccountsPage from './SignupLandingPages/pages/GuestAccountsPage';
import defaultRouter from './navigation/machines/defaultNavigation';
import { findStudentById, shouldSkipCourseFrequencyPage } from './lib';
import { checkIsBootcampSignup } from './lib/checkIsBootcampSignup';
import earlierSchedulingNav from './navigation/machines/earlierScheduling';

const SignupSession: FC<SignupSessionProps> = ({ match, history, location }) => {
  const { params } = match;

  // if changing the DCF navigation, refer to https://www.notion.so/junilearning/Guide-to-DCF-Navigation-fe2c5809e19f4dc48a8ef1736f712c4a first!
  const navRouter = isEnabled(FeatureFlag.EarlyScheduleCheckout)
    ? earlierSchedulingNav
    : defaultRouter;

  const { signupData, setSignupData, sessionIsLoading } = useSignupSession(
    params.signupSessionId,
  );

  if (
    match.path.includes(':signupSessionId') &&
    signupData._id &&
    signupData._id !== params.signupSessionId
  ) {
    history.replace(
      `${match.path.replace(':signupSessionId?', '')}${signupData._id}${
        location.search
      }`,
    );
  }

  const setSignupSession = async (
    newData: Partial<SignupData>,
    options?: SubmitStepOptions,
  ) => {
    if (!signupData._id) {
      console.error('error in submitStep: signupData._id not available yet');
      return;
    }
    const signupService = makeSignupSessionsService(signupData._id);
    const data: Partial<SignupData> = {
      ...signupData,
      ...newData,
      lastSeenUrl: window.location.href,
    };

    if (!options?.skipSignupDataUpdate) {
      setSignupData(data);
    }

    if (Object.keys(data).length > 0) {
      try {
        return signupService.updateSession(
          // omit readonly keys
          omit(['_id', 'closeLeadId'], data),
        );
      } catch (e) {
        console.error('Error updating signup session', e);
      }
    }
  };

  if (sessionIsLoading || !navRouter) return <JuniSpinner size={80} />;

  /*
    Hard-coding the activeStudent to the 0th index. As of this release,
    the dcf will only support signing up one student at a time, but I would 
    like to keep this flexible in case we want to support multiple students in the future.
  */
  const activeStudentId = signupData.students?.[0]?._id;
  const activeStudent = findStudentById(activeStudentId, signupData);

  const flags: SessionFlags = {
    collectScheduleEarly: navRouter.id === earlierSchedulingNav.id,
    isBootcampSignup: checkIsBootcampSignup(activeStudent?.bundle),
    isPrivateOneOnOne: !checkIsBootcampSignup(activeStudent?.bundle), // NOTE: for now, 1:1 is just the default as long as isBootcampSignup is false
    shouldSkipCourseFrequency: shouldSkipCourseFrequencyPage(
      activeStudent?.bundle?.selections ?? {},
    ),
    multiSubject: !shouldSkipCourseFrequencyPage(
      activeStudent?.bundle?.selections ?? {},
    ),
    isOnDemandSignup: activeStudent?.bundle?.bundleName === COURSE_FORMAT.onDemand,
  };

  return (
    // todo this should go away when the whole app is converted to graphik
    <div className="font-graphik">
      <SignupSessionContext.Provider
        value={{
          activeStudentId,
          signupData,
          setSignupSession,
          flags,
        }}
      >
        <NavRouterContext.Provider
          value={{
            router: navRouter,
            multiSubjectSignup: !flags.shouldSkipCourseFrequency,
          }}
        >
          <Switch>
            <Route
              path="/signup2"
              render={props => <ExistingUserCheckoutFlow flags={flags} {...props} />}
            />
            <Route
              path="/referral/:referralCode?"
              render={() => <ReferralsPage />}
            />
            <Route
              path="/signup/guest/:invitationLookupId"
              render={() => <GuestAccountsPage />}
            />
            <Route
              path="/signup/:signupSessionId"
              render={props => <CheckoutFlow {...props} />}
            />
            <Route
              path="/onboarding/:signupSessionId"
              render={props => <Onboarding {...props} />}
            />
          </Switch>
        </NavRouterContext.Provider>
      </SignupSessionContext.Provider>
    </div>
  );
};

export default SignupSession;
