import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import juniAxios from 'services/axios';

import CoppaDirectNotice from 'components/CoppaDirectNotice';
import { PARENT_SMS_CONSENT_LABEL_TEXT } from 'components/SmsConsentCheckbox/constants';
import { NewButton, Icon, Input } from 'core-components';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import JuniSpinner from 'components/JuniSpinner';
import { useTouchedFields } from 'utils/useTouchedFields';

import { MIN_PASSWORD_LENGTH, CONFIG_HEADERS } from '../../constants';

interface Props {
  isRefreshingInvite: boolean;
  inviteCode: string;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

const StudentSignup: React.FC<Props> = ({
  isRefreshingInvite,
  inviteCode,
  isLoading,
  firstName,
  lastName,
  email,
}) => {
  const [password, setPassword] = useState('');
  const [smsConsentChecked, setSmsConsentChecked] = useState(false);
  const [tosIsChecked, setTosIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isFieldTouched, addToTouchedFields } = useTouchedFields();

  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');

    if (!tosIsChecked) {
      setErrorMessage(
        'You must agree to the Juni Terms of Use and Privacy Policy in order to sign up.',
      );
      return;
    }
    if (password.length < 8) {
      setErrorMessage('Your password must be at least 8 characters.');
      return;
    }
    try {
      const signupRes = await juniAxios.post(
        `/auth/signup`,
        {
          firstName,
          lastName,
          password,
          inviteCode,
          smsConsent: smsConsentChecked,
          userType: 'parent',
        },
        CONFIG_HEADERS,
      );

      if (signupRes?.data?.parent?.userId) {
        JuniAnalytics.identify({ userId: signupRes?.data?.parent?.userId });
      }

      if (signupRes.data.parent) {
        history.push('/learner/login/');
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
        setErrorMessage(err.toString());
      }
    }
  };

  return (
    <div id="valid-parent" className="max-w-2xl m-auto py-4 sm:py-8">
      <header className="text-center">
        <h1 className="text-j-dark-600 m-0 text-2xl font-medium">
          Welcome to Juni!
          <br />
          Set up your Learner Portal Account.
        </h1>
        <p className="text-j-dark-400">
          Choose a password you and your child can share. You can set up classes
          after logging in.
        </p>
      </header>

      <form className="pt-4 space-y-2.5" onSubmit={e => handleSubmit(e)}>
        {errorMessage && (
          <p className="bg-j-pink-700 text-white rounded p-3 my-4 flex leading-4 text-sm items-center">
            <Icon.Error />
            <span className="ml-2">{errorMessage}</span>
          </p>
        )}

        <label className="block">
          <div className="text-sm text-j-dark-600 font-medium">Email</div>
          <Input
            type="email"
            disabled
            fullWidth
            readOnly
            size="small"
            value={email}
          />
        </label>
        <label className="block">
          <div className="text-sm text-j-dark-600 font-medium">Password</div>
          <div className="text-sm text-j-dark-400">
            Must be at least {MIN_PASSWORD_LENGTH} characters long
          </div>
          <Input
            type="password"
            disabled={isLoading}
            fullWidth
            message={
              !isFieldTouched('password') || password.length >= MIN_PASSWORD_LENGTH
                ? undefined
                : `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
            }
            minLength={MIN_PASSWORD_LENGTH}
            placeholder="Set up your password"
            required
            size="small"
            valid={
              !isFieldTouched('password')
                ? undefined
                : password.length >= MIN_PASSWORD_LENGTH
            }
            value={password}
            onBlur={() => addToTouchedFields('password')}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        {isRefreshingInvite && <JuniSpinner size={80} />}
        <div className="overflow-y-scroll border border-solid border-j-dark-200 rounded-lg h-52 bg-j-gray-100 text-j-dark-400 text-xs px-6 py-4 box-border">
          <CoppaDirectNotice />
        </div>
        <div className="text-sm text-j-dark-500">
          <input
            id="tos"
            type="checkbox"
            checked={tosIsChecked}
            onChange={() => setTosIsChecked(!tosIsChecked)}
          />

          <label htmlFor="tos">
            I consent to the{' '}
            <a
              href="https://junilearning.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
              className="tos"
            >
              Juni Learning Terms of Use and Privacy Policy
            </a>{' '}
            and the collection, use, and disclosure of my child’s personal
            information as described in the Direct Notice to Parents.
          </label>
        </div>
        <div className="text-sm text-j-dark-500">
          <input
            id="sms-consent"
            type="checkbox"
            checked={smsConsentChecked}
            onChange={() => setSmsConsentChecked(!smsConsentChecked)}
          />
          <label htmlFor="tos">{PARENT_SMS_CONSENT_LABEL_TEXT}</label>
        </div>

        <NewButton fullWidth type="submit" variant="primary">
          Submit
        </NewButton>
      </form>
    </div>
  );
};

export default StudentSignup;
