const TermsOfUse = () => (
  <p className="text-xs text-blue-gray-500 mt-0">
    By clicking Continue, you consent to our{' '}
    <a
      target="_blank"
      rel="noreferrer"
      href="https://junilearning.com/docs/Juni_Learning_Terms_of_Use.pdf"
    >
      Terms of Use
    </a>{' '}
    and{' '}
    <a
      href="https://junilearning.com/docs/Juni_Learning_Privacy_Policy.pdf"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
    , including consent to receive SMS from Juni Learning.
  </p>
);

export default TermsOfUse;
