/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component, useContext } from 'react';
import UserContext from 'modules/UserContext';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AceEditor from 'react-ace';
import { noop } from 'lodash';
import classNames from 'classnames';

import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/ext-language_tools';

import AlertBanner from 'components/AlertBanner';
import {
  JideSidebar,
  Footer,
  SyncIndicator,
  ToolSelectButton,
} from 'components/jide';

import { NewButton as Button, Icon, Panel } from 'core-components';
import JideSpecialInstructions from './jide_special_instructions';

import './jide_code_editor.css';

export const JideCodeEditorFooter = props => {
  const ENABLED_TABS = [
    JideSidebar.TAB_FILES,
    JideSidebar.TAB_USERS,
    // context: https://www.notion.so/junilearning/Code-Editor-Version-History-is-broken-19b4029ae84c47d897416b6e82e93e46
    // JideSidebar.TAB_VERSIONS,
    JideSidebar.TAB_NETWORK,
  ];

  const {
    isDisabled,
    setSidebarTab,
    activeSidebarTab,
    toggleFullScreenMode,
  } = props;

  const onClickToolSelect = tabName => (!isDisabled ? setSidebarTab(tabName) : noop);
  const isSelected = tabName => activeSidebarTab === tabName;

  const runButtonInactive =
    props.codeRunning ||
    props.connectionStatus === 'disconnected' ||
    props.saveStatus === 'syncing' ||
    !(props.fileType && props.fileType.includes('code')) ||
    props.startInProgress ||
    props.isPreview;
  const stopButtonActive =
    props.connectionStatus !== 'disconnected' &&
    props.fileType &&
    props.fileType.includes('code') &&
    props.codeRunning &&
    !props.stopInProgress &&
    !props.isPreview;

  const isTabEnabled = tab => ENABLED_TABS.includes(tab);

  const isStudentOrTeacher = ['student', 'teacher'].includes(props.jideUser.type);
  const isTeacherOrAdmin = ['teacher', 'admin'].includes(props.jideUser.type);

  const showFiles = isTabEnabled(JideSidebar.TAB_FILES) && !props.isPythonTurtle;
  const showUsers =
    isTabEnabled(JideSidebar.TAB_USERS) &&
    isStudentOrTeacher &&
    !props.isPythonTurtle;
  const showVersions =
    isTabEnabled(JideSidebar.TAB_VERSIONS) &&
    (props.hasEditPermissions || isTeacherOrAdmin);
  const showNetwork =
    isTabEnabled(JideSidebar.TAB_NETWORK) &&
    isStudentOrTeacher &&
    !props.isPythonTurtle;

  return (
    <Footer className="bg-j-dark-800 flex justify-between" borderColor="j-dark-400">
      <div className="flex items-center">
        {showFiles && (
          <ToolSelectButton
            darkMode
            title={JideSidebar.TAB_FILES}
            isDisabled={isDisabled}
            onClick={() => onClickToolSelect(JideSidebar.TAB_FILES)}
            selected={isSelected(JideSidebar.TAB_FILES)}
          >
            <Icon.Writing style={{ width: '24px', height: '24px' }} />
          </ToolSelectButton>
        )}
        {showUsers && (
          <ToolSelectButton
            darkMode
            title={JideSidebar.TAB_USERS}
            isDisabled={isDisabled}
            onClick={() => onClickToolSelect(JideSidebar.TAB_USERS)}
            selected={isSelected(JideSidebar.TAB_USERS)}
            className="ml-1"
          >
            <Icon.User className="border border-solid border-current rounded-md p-0.5" />
          </ToolSelectButton>
        )}{' '}
        {showNetwork && (
          <ToolSelectButton
            darkMode
            title={JideSidebar.TAB_NETWORK}
            isDisabled={isDisabled}
            onClick={() => onClickToolSelect(JideSidebar.TAB_NETWORK)}
            selected={isSelected(JideSidebar.TAB_NETWORK)}
            className="ml-1"
          >
            <Icon.Network />
          </ToolSelectButton>
        )}
        {showVersions && (
          <ToolSelectButton
            darkMode
            title={JideSidebar.TAB_VERSIONS}
            isDisabled={isDisabled}
            onClick={() => onClickToolSelect(JideSidebar.TAB_VERSIONS)}
            selected={isSelected(JideSidebar.TAB_VERSIONS)}
            className="ml-1"
          >
            <Icon.VersionHistory />
          </ToolSelectButton>
        )}
        {!props.fullScreenMode && (
          <ToolSelectButton
            darkMode
            title="Full Screen Mode"
            isDisabled={isDisabled}
            onClick={toggleFullScreenMode}
            className="ml-1"
          >
            <Icon.FullScreen />
          </ToolSelectButton>
        )}
      </div>
      <div className="flex items-center">
        <>
          <SyncIndicator
            connectionStatus={props.connectionStatus}
            saveStatus={
              props.restoreInProgress
                ? 'restoring'
                : props.isSyncing
                ? 'syncing'
                : props.saveStatus
            }
            onClickSave={() => props.syncCodeDefault()}
            isPublic={!props.hasEditPermissions}
            darkMode
          />
          <div className="ml-1.5">
            <Button
              onClick={() => {
                if (!runButtonInactive) {
                  props.runCode();
                } else if (stopButtonActive) {
                  props.stopCode();
                }
              }}
              disabled={
                (runButtonInactive && !props.codeRunning) ||
                activeSidebarTab === JideSidebar.TAB_VERSIONS
              }
              intent={runButtonInactive && stopButtonActive ? 'error' : 'default'}
            >
              {runButtonInactive && stopButtonActive ? 'Stop' : 'Run'}
            </Button>
          </div>
        </>
      </div>
    </Footer>
  );
};

const JideCodeEditor = props => {
  const { user } = useContext(UserContext);
  if (props.specialInstructions) {
    return (
      <div className="jce">
        <JideSpecialInstructions specialInstructions={props.specialInstructions} />
      </div>
    );
  }
  return (
    <div className="jce">
      {!props.newHorizons &&
        (props.activeSidebarTab !== JideSidebar.TAB_VERSIONS ? (
          <JideCodeEditorBar {...props} />
        ) : (
          <JideFileRestoreEditorBar {...props} />
        ))}

      <div className="jce-main">
        <JideCodeEditorFileViewer {...props} user={user} />
      </div>
      {props.newHorizons && <JideCodeEditorFooter {...props} />}
    </div>
  );
};

const JideCodeEditorStatusButton = props => {
  const syncStatus = props.isSyncing
    ? 'syncing'
    : !props.hasEditPermissions
    ? 'locked'
    : props.saveStatus;

  let derivedStatus = props.connectionStatus;

  if (props.restoreInProgress) {
    derivedStatus = 'restoring';
  } else if (props.syncingForeverError) {
    derivedStatus = 'hardReset';
  } else if (props.connectionStatus === 'connected') {
    derivedStatus = syncStatus;
  }

  const iconLookup = {
    disconnected: {
      icon: 'times',
      title: 'Disconnected \nPlease Refresh',
      modal: true,
    },
    connecting: {
      spinning: 'pulse',
      icon: 'spinner',
      title: 'Connecting...',
      modal: true,
    },
    locked: { icon: 'lock', title: 'Read-Only Mode' },
    syncing: {
      spinning: 'spin',
      icon: 'sync',
      title: 'Syncing...',
      modal: true,
    },
    restoring: {
      spinning: 'spin',
      icon: 'sync',
      title: 'Restoring...',
      modal: true,
    },
    hardReset: {
      spinning: 'spin',
      icon: 'sync',
      title: 'Still Syncing... Click here to Force Refresh',
      modal: true,
    },
    unsaved: { icon: 'save', title: 'Unsaved Edits \nClick to Sync' },
    saved: { icon: 'check', title: 'Saved!' },
    error: { icon: 'exclamation', title: 'Syncing Failed' },
  };
  const p =
    derivedStatus in iconLookup
      ? iconLookup[derivedStatus]
      : { icon: 'question', title: 'Unknown Error' };

  /** TODO: Add  locked and hard reset state */
  const statusLookup = {
    disconnected: {
      icon: <Icon.Error />,
      title: 'Disconnected',
      description: 'Refresh to reconnect',
    },
    connecting: {
      icon: <Icon.Refresh className="w-4 h-4 animate-spin" />,
      title: 'Connecting...',
    },
    syncing: {
      icon: <Icon.Refresh className="w-4 h-4 animate-spin" />,
      title: 'Syncing...',
    },
    restoring: {
      icon: <Icon.Refresh className="w-4 h-4 animate-spin" />,
      title: 'Restoring...',
    },
    hardReset: {
      icon: <Icon.Refresh className="w-4 h-4 animate-spin" />,
      title: 'Still Syncing... Click here to Force Refresh',
    },
    // TODO: update locked icon once we get that
    locked: { icon: <Icon.Lock />, title: 'Read-Only Mode' },
  };

  const statusInfo = statusLookup[derivedStatus];
  const isOffline = derivedStatus === 'disconnected';

  // TODO: Figure out how to display editor error message and center this modal once that's resolved
  const SyncIndicatorModal = styled.div.attrs(({ isOffline }) => ({
    className: classNames(
      'absolute',
      'block',
      'flex',
      'w-80 h-16',
      'bg-j-dark-700',
      'rounded-lg',
      'py-2 pr-4 pl-3',
      'box-border',
      'z-50',
      'border-solid border',
      isOffline ? 'border-j-pink-700' : 'border-j-dark-300',
      { 'items-center justify-center': !isOffline },
    ),
  }))`
    top: 15%;
  `;

  if (props.newHorizons) {
    if (!statusInfo || derivedStatus === 'locked') {
      return null;
    }
    return (
      <SyncIndicatorModal isOffline={isOffline}>
        <div
          className={classNames(
            'mr-2',
            isOffline || statusInfo.description ? 'text-sm' : 'text-base',
            isOffline ? 'text-j-pink-700' : 'text-j-dark-300',
          )}
          style={{ marginTop: '2px' }}
        >
          {statusInfo.icon}
        </div>
        <div className="flex flex-col">
          <div
            className={classNames(
              isOffline ? 'text-j-pink-700' : 'text-j-dark-300',
              isOffline || statusInfo.description ? 'text-sm' : 'text-base',
            )}
          >
            {statusInfo.title}
          </div>
          {statusInfo.description && isOffline && (
            <div className="text-j-dark-300 mt-1 text-sm">
              {statusInfo.description}
            </div>
          )}
        </div>
        {isOffline && (
          <div className="ml-auto">
            <Button intent="error" onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </div>
        )}
      </SyncIndicatorModal>
    );
  }

  return (
    <button
      className={`jce-status-button ${props.defaultClassNames}${
        p.spinning ? ' jce-spinning' : ''
      }${p.modal ? ' jce-modal' : ''}${
        derivedStatus === 'hardReset' ? ' jce-hardreset' : ''
      }`}
      title={p.title}
      onClick={() => {
        if (derivedStatus === 'hardReset') {
          props.hardReset('status_button');
          return;
        }
        if (
          props.connectionStatus === 'connected' &&
          derivedStatus !== 'syncing' &&
          derivedStatus !== 'restoring'
        ) {
          props.syncCodeDefault();
        }
      }}
    >
      <div>
        <FontAwesomeIcon
          icon={['fas', p.icon]}
          spin={p.spinning === 'spin'}
          pulse={p.spinning === 'pulse'}
        />
        {props.showText ? (
          <div className="jce-status-button-text">{p.title}</div>
        ) : null}
      </div>
    </button>
  );
};

const JideFileRestoreEditorBar = props => {
  const isPreview = props.filesVersions && props.filename in props.filesVersions;

  return (
    <div className="jce-bar">
      <div className="jce-bar-left" />
      <div className="jce-bar-right">
        {props.hasEditPermissions ? (
          <button
            className={`jce-controls jce-restore ${
              props.restoreInProgress || !isPreview ? 'jce-inactive' : 'jce-active'
            }`}
            onClick={() => {
              if (!props.restoreInProgress && isPreview) {
                props.restoreFile();
              }
            }}
          >
            <div>
              Restore &nbsp;
              <FontAwesomeIcon icon={['fas', 'history']} />
            </div>
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const JideCodeEditorBar = props => {
  const runButtonInactive =
    props.codeRunning ||
    props.connectionStatus === 'disconnected' ||
    props.saveStatus === 'syncing' ||
    !(props.fileType && props.fileType.includes('code')) ||
    props.startInProgress ||
    props.isPreview;

  const stopButtonActive =
    props.connectionStatus !== 'disconnected' &&
    props.fileType &&
    props.fileType.includes('code') &&
    props.codeRunning &&
    !props.stopInProgress &&
    /** TODO: Clarify this line/logic
     *  Is preview is never passed into here in codeEditorProps
     */
    !props.isPreview;
  return (
    <div className="jce-bar">
      <div className="jce-bar-left" />
      <div className="jce-bar-right">
        <JideCodeEditorStatusButton {...props} defaultClassNames="jce-controls" />

        <button
          className={`jce-controls jce-run ${
            runButtonInactive ? 'jce-inactive' : 'jce-active'
          }${props.startInProgress && !props.codeRunning ? ' jce-in-progress' : ''}`}
          onClick={() => {
            if (!runButtonInactive) {
              props.runCode();
            }
          }}
        >
          <div>
            Run&nbsp;
            {props.startInProgress && !props.codeRunning ? (
              <FontAwesomeIcon icon={['fas', 'play']} transform="shrink-4" spin />
            ) : (
              <FontAwesomeIcon icon={['fas', 'play']} transform="shrink-4" />
            )}
          </div>
        </button>

        <button
          className={`jce-controls jce-stop ${
            props.codeRunning ? 'jce-active' : 'jce-inactive'
          }${props.stopInProgress && props.codeRunning ? ' jce-in-progress' : ''}`}
          onClick={() => {
            if (stopButtonActive) {
              props.stopCode();
            }
          }}
        >
          <div>
            Stop&nbsp;
            {props.stopInProgress && props.codeRunning ? (
              <FontAwesomeIcon icon={['fas', 'stop']} transform="shrink-4" spin />
            ) : (
              <FontAwesomeIcon icon={['fas', 'stop']} transform="shrink-4" />
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

class JideCodeEditorFileViewer extends Component {
  componentDidUpdate(prevProps) {
    if (this.refs.reactAce && this.refs.reactAce.editor) {
      const { editor } = this.refs.reactAce;

      if (
        prevProps.sidebarExpanded !== this.props.sidebarExpanded ||
        prevProps.fullScreenMode !== this.props.fullScreenMode
      ) {
        editor.resize();
      }

      if (
        prevProps.isSyncing &&
        !this.props.isSyncing &&
        prevProps.code !== this.props.code
      ) {
        // UndoManager isn't dirty yet, but will is updated in this execution step
        // As such, we'll clear the undo manager in the next event loop
        setTimeout(() => {
          editor.getSession().getUndoManager().reset();
        }, 0);
      }
    }
  }

  getRenderer() {
    if (
      this.props.files &&
      this.props.files[this.props.filename] &&
      this.props.files[this.props.filename].isText
    ) {
      return 'code';
    }

    if (
      this.props.filesVersions &&
      this.props.filesVersions[this.props.filename] &&
      this.props.filesVersions[this.props.filename].isText
    ) {
      return 'code';
    }

    switch (this.props.fileType) {
      case 'audio':
      case 'image':
      case 'video':
        return this.props.fileType;
      default:
        return 'unknown';
    }
  }

  render() {
    let filenameSuffix =
      (this.props.filename && this.props.filename.split('.').pop()) || '';
    if (filenameSuffix === 'mov') {
      filenameSuffix = 'mp4';
    }

    const isPreview =
      this.props.filesVersions && this.props.filename in this.props.filesVersions;
    const renderer = this.getRenderer();
    let mode =
      this.props.fileType && this.props.fileType.includes('code')
        ? this.props.fileType.split('/')[1]
        : 'text';
    if (['c', 'cpp', 'h'].includes(mode)) {
      mode = 'c_cpp';
    }

    const isRestorable =
      !this.props.restoreInProgress &&
      // copy + paste from JideFileRestoreEditorBar which also calls this isPreview
      this.props.filesVersions &&
      this.props.filename in this.props.filesVersions;

    const isReadOnly =
      !this.props.hasEditPermissions ||
      !this.props.canEdit ||
      this.props.connectionStatus !== 'connected';

    return (
      <div className={`jce-file-viewer ${this.props.newHorizons ? 'relative' : ''}`}>
        <JideCodeEditorStatusButton {...this.props} showText />
        {isPreview ? (
          this.props.newHorizons ? (
            <div className="px-3 my-2 w-full box-border">
              <Panel
                backgroundColor="j-blue-500"
                padding="3"
                className="flex text-white text-sm font-graphik"
              >
                <div className="flex">
                  <Icon.Info className="mr-2" style={{ marginTop: '0.15rem' }} />
                  Editing is disabled. You are currently previewing an older version.
                </div>
                {this.props.hasEditPermissions && (
                  <div
                    onClick={() => {
                      if (isRestorable) {
                        this.props.restoreFile();
                      }
                    }}
                    className="cursor-pointer font-medium ml-auto"
                  >
                    Restore Version
                  </div>
                )}
              </Panel>
            </div>
          ) : (
            <AlertBanner
              style={{ width: '100%', marginBottom: '1em' }}
              className="alert-banner-warning"
              content={
                <div>
                  <strong>Editing is disabled.</strong>
                  <br />
                  You are currently previewing an older version.
                </div>
              }
            />
          )
        ) : null}

        {this.props.connectionStatus === 'connecting' ? (
          ''
        ) : !this.props.fileType || !this.props.filename || !this.props.files ? (
          'No Files Found or Error Loading Files'
        ) : renderer === 'code' ? (
          <div
            className={classNames(`jce-ace`, {
              'new-horizons': this.props.newHorizons,
              relative: this.props.newHorizons && this.props.fullScreenMode,
            })}
          >
            {this.props.fullScreenMode && (
              <div className="absolute right-3 top-3 z-10">
                <Button
                  renderIconLeft={props => <Icon.FullScreen {...props} />}
                  onClick={this.props.toggleFullScreenMode}
                >
                  <div className="ml-2 text-sm">Exit Fullscreen</div>
                </Button>
              </div>
            )}
            <AceEditor
              key={this.props.filename}
              ref="reactAce"
              mode={mode}
              theme="dracula"
              fontSize={this.props.defaultFontSize}
              showPrintMargin={false}
              readOnly={isReadOnly}
              tabSize={2}
              onChange={this.props.aceOnChange}
              width="inherit"
              height="100%"
              editorProps={{ $blockScrolling: true }}
              wrapEnabled
              value={
                this.props.isCodeHidden && this.props.user?.userType !== 'admin'
                  ? 'This code is private. If you are the owner, visit the project page, click share, and turn off the Hide Code switch.'
                  : this.props.code
              }
              focus={this.props.isActive}
              displayIndentGuides
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
              }}
            />
          </div>
        ) : renderer === 'video' ? (
          <video
            controls
            autoPlay
            loop
            muted
            key={this.props.filename}
            ref="reactVideo"
          >
            <source
              src={this.props.constructStaticFileUrl(this.props.filename)}
              type={`video/${filenameSuffix}`}
            />
          </video>
        ) : renderer === 'audio' ? (
          <audio controls key={this.props.filename} ref="reactAudio">
            <source
              src={this.props.constructStaticFileUrl(this.props.filename)}
              type={`audio/${filenameSuffix}`}
            />
          </audio>
        ) : renderer === 'image' ? (
          <img
            src={this.props.constructStaticFileUrl(this.props.filename)}
            key={this.props.filename}
            ref="reactImage"
            alt=""
          />
        ) : (
          <div className="jce-file-viewer jfv-text">
            {`File Type <${this.props.fileType}> Cannot Be Read`}
            <br />
            <br />
            {`If this is a code file,
            this might be because you added a special character (like an emoji)
            to your code. Please email support@learnwithjuni.com with your name
            and the project name, and we'll help you get this fixed!`}
          </div>
        )}
      </div>
    );
  }
}

JideCodeEditor.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  hasEditPermissions: PropTypes.bool,
  isSyncing: PropTypes.bool,
  saveStatus: PropTypes.string,
  connectionStatus: PropTypes.string,
  codeRunning: PropTypes.bool,
  fileType: PropTypes.string,
  startInProgress: PropTypes.bool,
  stopInProgress: PropTypes.bool,
  sidebarExpanded: PropTypes.bool,
  fullScreenMode: PropTypes.bool,
  filename: PropTypes.string,
  files: PropTypes.shape({}),
  filesVersions: PropTypes.shape({}),
  canEdit: PropTypes.bool,
  code: PropTypes.string,
  syncCodeDefault: PropTypes.func.isRequired,
  runCode: PropTypes.func.isRequired,
  stopCode: PropTypes.func.isRequired,
  aceOnChange: PropTypes.func.isRequired,
  selectFile: PropTypes.func.isRequired,
  constructStaticFileUrl: PropTypes.func.isRequired,
  defaultFontSize: PropTypes.number,
  activeSidebarTab: PropTypes.string,
  restoreInProgress: PropTypes.bool.isRequired,
  restoreFile: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
  isActive: PropTypes.bool,
  isCustomProject: PropTypes.bool,
  /* new horizons restyling */
  setSidebarTab: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  newHorizons: PropTypes.bool,
  isCodeHidden: PropTypes.bool,
};
export default JideCodeEditor;
