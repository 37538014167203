import giftCardParent from './GiftCardV2.svg';
import giftCardInstructor from './GiftCardInstructor.svg';

export type UserType = 'parent' | 'teacher' | 'admin';

export const REFERRAL_REWARD = {
  parent: {
    title: 'Gift credits & get credits toward your kid’s sessions',
    description:
      'Refer friends, family and followers to Juni and they’ll get $50 off their first paid month of membership.\n You’ll get $100 too!',
    image: giftCardParent,
    rewardType: 'credit',
    facebookShareText:
      'Juni offers real-world courses in subjects like coding, math and investing taught by vetted instructors. Use my referral link for $50 off the first month.',
    twitterShareText:
      'My family has been loving Juni Learning. They offer real-world courses in subjects like coding, math and investing taught by vetted instructors. You can use my referral link for $50 off your first month!',
  },
  teacher: {
    title: 'Earn a bonus when you refer parents to Juni',
    description:
      "Refer friends, family and followers to Juni and you'll get $100 Amazon gift card.\n They'll get $50 credits toward classes!",
    image: giftCardInstructor,
    rewardType: 'Amazon gift card',
    facebookShareText:
      'Juni offers real-world courses in subjects like coding, math and investing. Use my referral code for $50 off my class or any Juni course!',
    twitterShareText:
      'Juni offers real-world courses in subjects like coding, math and investing. Use my referral code for $50 off my class or any Juni course!',
  },
  admin: {
    title: 'Earn a bonus when you refer parents to Juni',
    description:
      "Refer friends, family and followers to Juni and you'll get $100 Amazon gift card.\n They'll get $50 credits toward classes!",
    image: giftCardInstructor,
    rewardType: 'Amazon gift card',
    facebookShareText:
      'Juni offers real-world courses in subjects like coding, math and investing. Use my referral code for $50 off my class or any Juni course!',
    twitterShareText:
      'Juni offers real-world courses in subjects like coding, math and investing. Use my referral code for $50 off my class or any Juni course!',
  },
};

export const STATUS_MAP_STYLES = {
  pending: {
    text_color: 'text-j-dark-400',
    bg_color: 'bg-j-gray-200',
    text: 'INVITED',
  },
  registered: {
    text_color: 'text-j-yellow-500',
    bg_color: 'bg-j-yellow-200',
    text: 'SIGNED UP',
  },
  awaiting_credit: {
    text_color: 'text-j-orange-500',
    bg_color: 'bg-j-yellow-200',
    text: 'AWARD IN PROGRESS',
  },
  credit_granted: {
    text_color: 'text-j-green-700',
    bg_color: 'bg-j-green-100',
    text: 'GRANTED',
  },
};
