const FEATURE_FLAG_PREFIX = 'REACT_APP_FEATURE_';

type EnvVarValue = string | undefined;

/**
 * **DO NOT USE DIRECTLY IN CODE**
 */
export const _FEATURE_FLAGS = Object.keys(process.env).reduce(
  (flags: { [key: string]: EnvVarValue }, key) => {
    if (key.startsWith(FEATURE_FLAG_PREFIX)) {
      flags[key] = process.env[key];
    }
    return flags;
  },
  {},
);

/**
 * Flags for working on features incrementally
 * Enable through your local .env file
 */
export enum FeatureFlag {
  IMTWithGraphQL = 'IMT_WITH_GRAPHQL_ENDPOINT',
  AvailabilityWithGraphQL = 'AVAILABILITY_WITH_GRAPHQL_ENDPOINT',
  TimeTurnerClassScheduling = 'TIME_TURNER_CLASS_SCHEDULING',
  UpgradeFlowV2 = 'UPGRADE_V2',
  EarlyScheduleCheckout = 'EARLY_SCHEDULE_CHECKOUT',
}

export function isEnabled(flag: FeatureFlag): boolean {
  const flagKey = `${FEATURE_FLAG_PREFIX}${flag}`;
  const flagValue: EnvVarValue = _FEATURE_FLAGS[flagKey];

  return (
    flagValue !== undefined &&
    typeof flagValue === 'string' &&
    ['true', '1'].includes(flagValue.toLowerCase())
  );
}
