import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const ReferralHeaderButton: FC = () => (
  <div className="flex items-center justify-center">
    <Link
      title="Open referral to new tab"
      target="_blank"
      to="/teacher/refer"
      className="lg:w-full w-4 rounded-lg bg-j-green-400 hover:bg-j-green-500 focus:ring focus:ring-j-green-200 lg:text-sm text-xs text-center leading-4 lg:leading-6 text-white font-bold lg:px-4 px-2 py-2 lg:mr-0 mr-4 no-underline"
    >
      <span className="lg:block hidden">Get $100</span>
      <span className="lg:hidden block">$</span>
    </Link>
  </div>
);

export default ReferralHeaderButton;
