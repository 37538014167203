import { Message } from 'core-components';
import { TEST_SIGNUP_TAG } from 'constants/test_signup_cleanup';

const TestSignupWarning = ({ email }: { email: string }) => {
  const showWarning =
    (email.includes('@learnwithjuni') || email.includes('@junilearning')) &&
    !email.includes(TEST_SIGNUP_TAG);
  return showWarning ? (
    <Message status="warning">
      If this is a test signup, please add {TEST_SIGNUP_TAG} to your email
    </Message>
  ) : null;
};

export default TestSignupWarning;
