import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  GetCoursesReturnFragment,
  Maybe,
  StripeCoupon,
  useLoadDefaultPaymentInformationByParentIdQuery,
  useSelfServeUpdateSubscriptionMutation,
} from 'generated/graphql';
import {
  ASYNC_PLAN_PRODUCT,
  CORE_PRICES,
  CORE_PRODUCT_METADATA_KEYS,
  formatPrice,
  METADATA_DISPLAY_ORDER,
} from 'constants/subscription_plans';

import {
  Badge,
  Icon,
  Message,
  NewButton,
  NewCard,
  NewModalWindow,
} from 'core-components';
import { timezoneToDisplayName } from 'constants/timezones';
import { getShortDisplayNameFromCourses } from 'utils/courses';
import SpinnerV2 from 'components/SpinnerV2';
import {
  getCoreSubjectList,
  getDiscountDollarWithCoupon,
  getPlanMonthlyPrice,
  getPlansDisplayName,
  getSubscriptionTotalPriceBeforeDiscount,
  getTotalCoreFrequency,
} from 'app/learner/LearnerAccountV2/utils';
import { format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { SubscriptionMetaDataWithCourse } from '../../../types';
import SelectedClassTimePreferences from './SelectedClassTimePreference';

interface Props {
  subscriptionId: string;
  currentSubscription: SubscriptionMetaDataWithCourse;
  timezone: string;
  courses: GetCoursesReturnFragment[];
  coupon: Maybe<StripeCoupon> | undefined;
  parentId: string;
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updatedSubscription: SubscriptionMetaDataWithCourse;
  startingDate: string;
  studentId: string;
}

const ConfirmationSummary: FC<Props> = ({
  subscriptionId,
  currentSubscription,
  timezone,
  courses: courseData,
  coupon,
  parentId,
  setIsConfirmationOpen,
  updatedSubscription,
  startingDate,
  studentId,
}) => {
  const history = useHistory();
  const [isPostSubmissionModalOpen, setIsPostSubmissionModalOpen] = useState(false);
  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: parentId },
  });
  const card = cardQuery?.data?.defaultPaymentInformationByParentId;

  const [
    updateMySubscription,
    { loading, error },
  ] = useSelfServeUpdateSubscriptionMutation();

  const totalCoreFrequency = getTotalCoreFrequency(updatedSubscription);
  const coreSubjectList = getCoreSubjectList(updatedSubscription);
  const subtotal = getSubscriptionTotalPriceBeforeDiscount(updatedSubscription);
  const discount = getDiscountDollarWithCoupon(coupon, subtotal);
  const total = subtotal - discount;

  const handleSubmit = async () => {
    try {
      await updateMySubscription({
        variables: {
          input: {
            subscriptionId,
            studentId,
            updatedSubscription,
            timezone,
            couponId: coupon?.id,
            startingDate,
          },
        },
      });
      setIsPostSubmissionModalOpen(true);
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    setIsConfirmationOpen(false);
    history.push(`/learner/account`);
  };

  const changedSubjects = Object.keys(updatedSubscription).filter(
    subjectKey =>
      !Object.keys(currentSubscription).includes(subjectKey) ||
      updatedSubscription[subjectKey]?.weeklyFrequency !==
        currentSubscription[subjectKey]?.weeklyFrequency,
  );

  return (
    <>
      <div className="w-full py-6">
        <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
          <header className="pb-4">
            <h2 className="flex justify-between items-center flex-wrap m-0 font-medium text-j-dark-600 text-xl">
              New Subscription Details
              <span className="text-j-dark-300 text-sm font-normal">
                {timezoneToDisplayName(timezone)}
              </span>
            </h2>
            {!(
              changedSubjects.length === 1 &&
              changedSubjects.includes(ASYNC_PLAN_PRODUCT.key)
            ) && (
              <div className="text-j-dark-400 text-sm font-normal">
                Effective Date:{' '}
                <span className="font-medium">
                  {format(parseISO(startingDate), 'MMMM dd, yyyy')}
                </span>
              </div>
            )}
          </header>

          <div className="space-y-4">
            {totalCoreFrequency > 0 && (
              <div className="w-full flex flex-row justify-between text-j-dark-400">
                <div className="font-medium">
                  Core Monthly Subscription {totalCoreFrequency}x per week (
                  {coreSubjectList})
                </div>
                <div>{formatPrice(CORE_PRICES[totalCoreFrequency])}/mo</div>
              </div>
            )}
            {Object.keys(updatedSubscription)
              .sort(
                (a, b) =>
                  METADATA_DISPLAY_ORDER.indexOf(a) -
                  METADATA_DISPLAY_ORDER.indexOf(b),
              )
              .map(subjectKey => {
                const {
                  weeklyFrequency,
                  courses,
                  timePreferences,
                } = updatedSubscription[subjectKey];
                const existingSubject = subjectKey in currentSubscription;
                const price = getPlanMonthlyPrice(subjectKey, weeklyFrequency);
                const notCorePricingSubject = !CORE_PRODUCT_METADATA_KEYS.includes(
                  subjectKey,
                );

                return (
                  <div
                    key={subjectKey}
                    className={`flex flex-col gap-1 ${
                      CORE_PRODUCT_METADATA_KEYS.includes(subjectKey) && 'pl-6'
                    }`}
                  >
                    <div className="w-full flex flex-row justify-between text-j-dark-400">
                      <div>
                        <div className="flex flex-row items-center gap-1 font-medium">
                          {existingSubject &&
                            weeklyFrequency !==
                              currentSubscription[subjectKey].weeklyFrequency && (
                              <Badge
                                backgroundColor="j-orange-100"
                                color="j-orange-600"
                                size="regular"
                              >
                                Updated
                              </Badge>
                            )}
                          {!existingSubject && (
                            <Badge
                              backgroundColor="j-green-100"
                              color="j-green-600"
                              size="regular"
                            >
                              New
                            </Badge>
                          )}
                          {getPlansDisplayName(subjectKey, weeklyFrequency)}
                        </div>
                        {courses && courses.length > 0 && (
                          <div className="text-sm">
                            {courses.length > 1 ? 'Courses: ' : 'Course: '}
                            {getShortDisplayNameFromCourses(courseData, courses)}
                          </div>
                        )}
                      </div>
                      <div>
                        {notCorePricingSubject && (
                          <>
                            {/* if price returns 0 hide show no application badge */}
                            {price === 0 ? (
                              <Badge
                                backgroundColor="j-yellow-200"
                                color="j-yellow-500"
                              >
                                N/A
                              </Badge>
                            ) : (
                              `${formatPrice(price)}/mo`
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {timePreferences && (
                      <SelectedClassTimePreferences
                        timePreferences={timePreferences}
                        existingSubject={existingSubject}
                        timezone={timezone}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </section>
        <section className="border-0 border-solid border-j-purple-200 pt-6 text-j-dark-600">
          <header className="mb-4">
            <h2 className="flex justify-between items-center flex-wrap m-0 font-medium text-xl">
              Payment Summary
            </h2>
            <div className="text-j-dark-400 text-sm font-normal">
              We will use your primary payment method:{' '}
              <span className="font-medium">
                {card?.brand
                  ? card.brand.charAt(0).toUpperCase() + card.brand.slice(1)
                  : ''}{' '}
                ending in {card?.last4}
              </span>
            </div>
          </header>

          <div className="flex flex-col gap-1.5">
            {coupon && (
              <>
                <div className="flex flex-row justify-between font-medium text-j-dark-300">
                  <label>Subtotal</label> <span>{formatPrice(subtotal)}/mo</span>
                </div>
                <div className="flex flex-row justify-between">
                  <label>Discount ({coupon.id})</label>{' '}
                  <span>–{formatPrice(discount)}/mo</span>
                </div>
              </>
            )}
            <div className="flex flex-row justify-between font-medium">
              <label>Total</label>
              <div>
                {formatPrice(total)}
                {!coupon?.id || (coupon?.id && coupon?.duration !== 'once')
                  ? '/mo'
                  : ''}
              </div>
            </div>
          </div>
        </section>
      </div>
      <NewCard.Divider />
      <div className="flex flex-col pt-6 gap-4">
        {error && (
          <Message status="error">
            Oops, something went wrong! Please{' '}
            <a href="https://junilearning.com/contact/">contact Juni Support</a> if
            the error persists.
          </Message>
        )}
        <div className="flex justify-between w-full">
          <NewButton
            variant="secondary"
            onClick={() => setIsConfirmationOpen(false)}
          >
            Edit
          </NewButton>
          <NewButton intent="success" onClick={handleSubmit}>
            {loading ? <SpinnerV2 /> : 'Submit'}
          </NewButton>
        </div>
      </div>
      <NewModalWindow isOpen={isPostSubmissionModalOpen} closeModal={closeModal}>
        <div className="p-6 flex flex-col items-center w-full gap-4 text-j-dark-600">
          <Icon.CheckboxCircle className="h-20 w-20 text-j-green-600" />
          <span className="text-2xl font-medium">Request Submitted</span>
          <div className="text-center">
            {changedSubjects.includes(ASYNC_PLAN_PRODUCT.key) ? (
              changedSubjects.length === 1 ? (
                <>
                  <div className="text-base text-j-dark-600">
                    You now have Juni's On Demand Library available to you at your
                    fingertips!
                  </div>
                  <NewButton
                    fullWidth
                    intent="success"
                    size="large"
                    onClick={async () => {
                      history.push(`/learner/${studentId}/on_demand`);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    Start using Juni's On Demand Library
                  </NewButton>
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-2 text-base text-j-dark-600">
                    <div>
                      We have added Juni's On Demand Library to your subscription!
                    </div>
                    <NewButton
                      onClick={async () => {
                        history.push(`/learner/${studentId}/on_demand`);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      Click here to start using Juni's On Demand Library
                    </NewButton>
                    <div className="pt-4">
                      For the remaining updates, we will need to take a few days to
                      process your request. Once that is completed, you will see your
                      updated subscription in 'My Accounts'.
                    </div>
                  </div>
                </>
              )
            ) : (
              "We will take a few days to process your request. Once that is completed you will see your updated subscription in 'My Accounts'."
            )}
          </div>
        </div>
      </NewModalWindow>
    </>
  );
};

export default ConfirmationSummary;
