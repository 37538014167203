import React, { FC, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { Icon, Input, NewButton, Toggle } from 'core-components';
import juniAxios from 'services/axios';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import navStates from 'app/signup_session/navigation/states';
import CoppaDirectNotice from 'components/CoppaDirectNotice';
import SpinnerV2 from 'components/SpinnerV2';
import { formatInputPhone } from 'utils/phone';
import { useTouchedFields } from 'utils/useTouchedFields';
import { userLogin } from 'services/login';
import { SignupSessionProps } from 'app/signup_session/types';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';

import SMSNotification from 'images/signup_sessions/sms-notification.svg';
import { useUserContext } from 'modules/UserContext';
import { Redirect } from 'react-router-dom';

const MIN_PASSWORD_LENGTH = 8;

const CreateAccount: FC<SignupSessionProps> = () => {
  const { getNextPage, hasNextPage } = useNavRouter();
  const { signupData, setSignupSession, flags } = useSignupContext();
  const { loadUserData } = useUserContext();
  const { isFieldTouched, addToTouchedFields } = useTouchedFields();
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(signupData.phone ?? '');
  const [smsConsent, setSmsConsent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const email = signupData.email ?? '';

  if (
    hasNextPage(navStates.onboarding.createAccount, ROUTE_EVENT.LOAD, {
      signupData,
    })
  ) {
    return (
      <Redirect
        to={getNextPage(navStates.onboarding.createAccount, ROUTE_EVENT.LOAD, {
          signupData,
        })}
      />
    );
  }

  const formCanBeSubmitted = () => password.length > 0 && phoneNumber.length > 0;

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      const userType = 'parent';
      const res = await juniAxios.post('/auth/signup', {
        firstName: signupData.firstName,
        lastName: signupData.lastName,
        password,
        inviteCode: signupData.invitationCode,
        smsConsent,
        userType,
      });
      if (res?.data?.parent?.userId) {
        JuniAnalytics.identify({ userId: res?.data?.parent?.userId });
      }
      await setSignupSession(
        { accountCreated: true },
        { skipSignupDataUpdate: true },
      );
      if (loadUserData) {
        await userLogin(email, password, userType);
        await loadUserData();
      }
    } catch (err) {
      console.error(err);
      const DEFAULT_ERROR = 'An error occurred while submitting your request.';
      setErrorMessage((err as any).response?.data?.message ?? DEFAULT_ERROR);
      setIsLoading(false);
    } finally {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-white max-w-lg mx-auto p-8 rounded-xl shadow-1">
      <header className="text-center">
        <h1 className="text-j-dark-600 m-0 text-2xl font-medium">
          Welcome to Juni!
          <br />
          Set up your Learner Portal Account.
        </h1>
        <p className="text-j-dark-400">
          Choose a password you and your child can share.
          {!flags.isBootcampSignup && <> You can set up classes after logging in.</>}
        </p>
      </header>
      {errorMessage && (
        <p className="bg-j-pink-700 text-white rounded p-3 my-4 flex leading-4 text-sm">
          <Icon.Error />
          <span className="ml-2">{errorMessage}</span>
        </p>
      )}
      <div className="space-y-6">
        <label className="block">
          <div className="text-sm text-j-dark-600 font-medium mb-2">Email</div>
          <Input
            type="email"
            disabled
            fullWidth
            readOnly
            size="small"
            value={signupData.email}
          />
        </label>
        <label className="block">
          <div className="text-sm text-j-dark-600 font-medium">Password</div>
          <div className="text-sm text-j-dark-400 mb-2">
            Must be at least {MIN_PASSWORD_LENGTH} characters long
          </div>
          <Input
            type="password"
            disabled={isLoading}
            fullWidth
            message={
              !isFieldTouched('password') || password.length >= MIN_PASSWORD_LENGTH
                ? undefined
                : `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
            }
            minLength={MIN_PASSWORD_LENGTH}
            placeholder="Set up your password"
            required
            size="small"
            valid={
              !isFieldTouched('password')
                ? undefined
                : password.length >= MIN_PASSWORD_LENGTH
            }
            value={password}
            onBlur={() => addToTouchedFields('password')}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <label className="block">
          <div className="text-sm text-j-dark-600 font-medium">Phone Number</div>
          <Input
            type="tel"
            disabled={isLoading}
            fullWidth
            message={
              !isFieldTouched('phone') || isValidPhoneNumber(phoneNumber, 'US')
                ? undefined
                : `Please enter a valid phone number`
            }
            placeholder="(000) 000-0000"
            required
            size="small"
            valid={
              !isFieldTouched('phone')
                ? undefined
                : isValidPhoneNumber(phoneNumber, 'US')
            }
            value={phoneNumber}
            onBlur={() => addToTouchedFields('phone')}
            onChange={e => {
              const next = e.target.value;
              setPhoneNumber(current => formatInputPhone(current, next));
              setErrorMessage('');
            }}
          />
        </label>
        <div className="flex flex-col md:flex-row rounded-lg bg-j-gray-200 p-6 md:p-3 text-center md:text-left items-center md:items-start">
          <div className="transform translate-y-2 px-2">
            <img src={SMSNotification} alt="SMS Notification" />
          </div>
          <div className="flex-1 px-2">
            <strong className="text-sm font-medium text-j-dark-600 block pb-2">
              Phone SMS Notifications
            </strong>
            <div className="text-sm text-j-dark-400">
              Text me important reminders, such as for my upcoming classes or when my
              student is missing from their scheduled class.
            </div>
          </div>
          <div className="pt-3 md:pt-0">
            <Toggle
              label="Allow"
              disabled={isLoading}
              isChecked={smsConsent}
              onChange={() => {
                setSmsConsent(prevState => !prevState);
              }}
              hideLabelOnDesktop
            />
          </div>
        </div>
        <div className="overflow-y-scroll border border-solid border-j-dark-200 rounded-lg h-52 bg-j-gray-100 text-j-dark-400 text-xs px-6 py-4 box-border">
          <CoppaDirectNotice />
        </div>
        <NewButton
          disabled={!formCanBeSubmitted() || isLoading}
          onClick={handleSubmit} // using click handler because using Enter to submit form causes it to navitage incorrectly
          fullWidth
          type="button"
        >
          {isLoading ? (
            <div className="-m-1">
              <SpinnerV2 size={16} />
            </div>
          ) : (
            'Continue'
          )}
        </NewButton>
        <div>
          <small className="text-j-dark-400 text-xs">
            By creating an account, you have read the COPPA Direct Notice and agree
            to our{' '}
            <a
              href="https://junilearning.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline font-medium text-j-blue-400"
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href="https://junilearning.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline font-medium text-j-blue-400"
            >
              Privacy Policy
            </a>
            .{' '}
          </small>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
