import React, { useState } from 'react';
import { Arrow } from 'components/Icons';
import { Card, NewButton as Button } from 'core-components';
import { SUBJECTS } from 'constants/subjects';
import { IconRenderProps } from 'core-components/NewButton/Button';
import { useSignupData } from 'app/signup_2023/hooks';
import { ErrorableLoading, ReactSelectField } from 'components/ui';
import { PRODUCT_METADATA } from 'app/signup_2023/constants/products';
import {
  SignupProduct,
  StarterCourseFragment,
  StarterSubjectFragment,
  useGetStarterCurriculumQuery,
} from 'generated/graphql';
import { headerTextStyles, labelTextStyles } from '../../constants';

interface LocalState {
  courseName: string;
}

interface CourseSelectionProps {
  subjects: StarterSubjectFragment[];
  courses: StarterCourseFragment[];
}

const CourseSelection = ({ subjects, courses }: CourseSelectionProps) => {
  const { signupData, updateSignup, loading, goToPrevStep } = useSignupData();
  const activeStudent = signupData?.students?.[0];
  const activeEnrollment = activeStudent?.enrollments?.[0];

  const [localState, setLocalState] = useState<LocalState>({
    courseName: activeEnrollment?.courseName || '',
  });

  const onLocalStateChange = (newData: Partial<typeof localState>) => {
    setLocalState({ ...localState, ...newData });
  };

  // formats localState for persistence
  const localStateToPartialEnrollment = () => ({
    courseName: localState.courseName,
    productName: SignupProduct.PrivateBulkCs,
    subjectId: subjects.find(s => s.name === SUBJECTS.COMPUTER_SCIENCE)?._id || null,
    courseId: courses.find(s => s.name === localState.courseName)?._id || null,
  });

  const isValid = () => {
    const enrollment = localStateToPartialEnrollment();
    return !!(enrollment.subjectId && enrollment.courseId);
  };

  const onSubmit = async () => {
    if (signupData && !loading && isValid) {
      const enrollments = [localStateToPartialEnrollment()];
      updateSignup({ students: [{ ...activeStudent, enrollments }] });
    }
  };

  return (
    <Card
      className="w-full mx-auto border-none sm:max-w-screen-xs sm:rounded-lg"
      hideOverflow={false}
      noRounding
    >
      <h1 className={headerTextStyles}>Select a course for your child</h1>
      <div className="flex flex-col space-y-2">
        <label className={labelTextStyles}>Course Selection</label>
        <ReactSelectField
          options={courses.map(c => ({ value: c.name, label: c.displayName }))}
          value={localState.courseName}
          onChange={item => onLocalStateChange({ courseName: item as string })}
          placeholder="Select a course..."
        />
      </div>
      <div className="w-full flex flex-row space-x-4 pt-4">
        <Button
          className="h-12 w-1/3"
          variant="secondary"
          onClick={goToPrevStep}
          renderIconLeft={(props: IconRenderProps) => (
            <Arrow {...props} orientation="left" />
          )}
        >
          Back
        </Button>
        <Button
          className="h-12 w-2/3"
          onClick={onSubmit}
          disabled={loading || !isValid()}
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};

const Wrapper = () => {
  const { data, error, loading } = useGetStarterCurriculumQuery({
    variables: {
      input: { names: PRODUCT_METADATA[SignupProduct.PrivateBulkCs].courseNames },
    },
  });

  if (loading || error) {
    return <ErrorableLoading error={error} pageError />;
  }

  const { getSubjects: subjects, getCourses: courses } = data || {};
  if (!subjects || !courses) {
    return (
      <ErrorableLoading
        error="Unable to fetch curriculum, please try again."
        pageError
      />
    );
  }

  return <CourseSelection subjects={subjects} courses={courses} />;
};

export default Wrapper;
