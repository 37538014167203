import CoppaDirectNotice from 'components/CoppaDirectNotice';

const Coppa = () => (
  <>
    <div className="overflow-y-scroll border border-solid border-j-dark-200 rounded-lg h-52 bg-j-gray-100 text-j-dark-400 text-xs px-6 py-4 box-border">
      <CoppaDirectNotice />
    </div>
    <div>
      <small className="text-j-dark-400 text-xs">
        By continuing, you confirm you have read the COPPA Direct Notice and agree to
        our{' '}
        <a
          href="https://junilearning.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline font-medium text-j-blue-400"
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          href="https://junilearning.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline font-medium text-j-blue-400"
        >
          Privacy Policy
        </a>
        .
      </small>
    </div>
  </>
);

export default Coppa;
