import React, { FC, useState } from 'react';
import { differenceInYears, format, isValid, subYears } from 'date-fns';
import isBetween from 'utils/isBetween';
import { Input, NewButton, NewModalWindow } from 'core-components';
import WarningBlock from 'app/signup_session/components/WarningBlock';
import { AGES_TAUGHT, AGES_SELECTOR } from 'constants/student_ages';
import {
  useUpdateStudentBirthdayMutation,
  Student,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import { UPDATE_FLOWS } from 'app/learner/LearnerAccountV2/LearnerAccount';
import { DEFAULT_TIMEZONE } from 'constants/timezones';
import { zonedTimeToUtc } from 'date-fns-tz';
import { QueryResult } from '@apollo/client';

interface Props {
  student: Partial<Student>;
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  resetForm: () => void;
  isRefillBirthdayModalOpen: boolean;
  setIsRefillBirthdayModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
}

const FillBirthDateModal: FC<Props> = ({
  student,
  updateFormState,
  updateFormValue,
  resetForm,
  isRefillBirthdayModalOpen,
  setIsRefillBirthdayModalOpen,
  studentsQuery,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [birthDate, setBirthDate] = useState(
    student?.birthDate ? format(new Date(student.birthDate), 'MM/dd/yyyy') : '',
  );
  const [ageIsOutsideOfRange, setAgeIsOutsideOfRange] = useState(false);

  const [updateBirthday] = useUpdateStudentBirthdayMutation();

  const handleAgeChange = () => {
    setAgeIsOutsideOfRange(false);
    const studentAge = differenceInYears(new Date(), new Date(birthDate));
    if (!isBetween(AGES_TAUGHT.min, AGES_TAUGHT.max, studentAge)) {
      setAgeIsOutsideOfRange(true);
    }
  };

  const studentAge = birthDate
    ? differenceInYears(new Date(), new Date(birthDate))
    : undefined;

  const handleSubmit = async () => {
    if (isLoading || !birthDate || !isValid(new Date(birthDate))) return;
    setIsLoading(true);

    try {
      await updateBirthday({
        variables: {
          input: {
            _id: student._id,
            birthDate: zonedTimeToUtc(birthDate, DEFAULT_TIMEZONE).toISOString(), // all our birthday is saved in Pacific time zone
            age: studentAge?.toString(),
          },
        },
      });
      setIsLoading(false);
      setIsRefillBirthdayModalOpen(false);
      resetForm();

      updateFormState('course_select', 'modal');
      updateFormState(UPDATE_FLOWS.REENGAGE_CHURN, 'updateFlow');
      updateFormValue(student, 'currentStudent');
      studentsQuery.refetch();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return (
    <NewModalWindow
      isOpen={isRefillBirthdayModalOpen}
      closeModal={() => {
        setIsRefillBirthdayModalOpen(false);
      }}
      title={`Update details for ${student.firstName}`}
      contentPadding="6"
      renderFooter={() => (
        <div className="text-right w-full">
          <NewButton
            variant="primary"
            type="button"
            onClick={() => handleSubmit()}
            disabled={isLoading || !birthDate || !isValid(new Date(birthDate))}
          >
            {isLoading ? (
              <div className="h-5 flex items-center">
                <SpinnerV2 />
              </div>
            ) : (
              'Next'
            )}
          </NewButton>
        </div>
      )}
    >
      <div className="flex flex-col items-start space-y-4">
        <div className="text-j-dark-600">
          Oops, it seems like we don't have {student.firstName}'s birthday on file.
          Knowing {student.firstName}'s age help us better find you a Juni Instructor
          who's great for your child's experience level and age. Please provide the
          month and year of your child's birthday.
        </div>

        <Input
          type="month"
          fullWidth
          value={birthDate}
          onBlur={() => handleAgeChange()}
          min={format(subYears(new Date(), AGES_SELECTOR.max), 'yyyy-MM')}
          max={format(subYears(new Date(), AGES_SELECTOR.min), 'yyyy-MM')}
          onChange={e => {
            setBirthDate(e.target.value);
          }}
        />
        {ageIsOutsideOfRange && (
          <WarningBlock>
            Our courses are designed for kids ages 7-18. We may contact you to
            discuss your child's enrollment.
          </WarningBlock>
        )}
      </div>
    </NewModalWindow>
  );
};

export default FillBirthDateModal;
