import { useEffect, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { BillingDetails, SetupIntent, SetupIntentResult } from '@stripe/stripe-js';

type ConfirmParams = Pick<BillingDetails, 'name' | 'email' | 'phone'>;

/**
 * This hook has two purposes:
 * 1. It immediately fetches a setupIntent via clientSecret.
 * 2. It exposes a function to confirm a setupIntent.
 *
 * Only use inside a StripeElements context. Search for `withStripeElements`.
 */
const useSetupIntent = (clientSecret?: string) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [setupIntentResult, setSetupIntentResult] = useState<SetupIntentResult>();

  useEffect(() => {
    let mounted = true;
    const fetchSetupIntent = async (clientSecret: string) => {
      const result = await stripe?.retrieveSetupIntent(clientSecret);
      setSetupIntentResult(result);
    };
    if (stripe && clientSecret && mounted) {
      fetchSetupIntent(clientSecret);
    }
    return () => {
      mounted = false;
    };
  }, [stripe, clientSecret]);

  const confirmSetupIntent = async (
    billing_details: ConfirmParams,
    onSuccess: (status: SetupIntent.Status) => void,
  ) => {
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    setLoading(true);

    const redirect = 'if_required';
    const confirmParams = { payment_method_data: { billing_details } };
    const result = await stripe.confirmSetup({ elements, redirect, confirmParams });
    setSetupIntentResult(result);
    setLoading(false);

    const status = result.setupIntent?.status;
    if (status === 'succeeded') {
      onSuccess(status);
    }
  };

  return { setupIntentResult, loading, confirmSetupIntent };
};

export default useSetupIntent;
