import React from 'react';
import { Toggle } from 'core-components';
import SMSNotification from 'images/signup_sessions/sms-notification.svg';

interface Props {
  loading: boolean;
  smsConsent: boolean;
  toggleConsent: () => void;
}

const SmsConsentInput = ({ loading, smsConsent, toggleConsent: toggle }: Props) => (
  <div className="flex flex-col md:flex-row rounded-lg bg-j-gray-200 p-6 md:p-3 text-center md:text-left items-center md:items-start">
    <div className="transform translate-y-2 px-2">
      <img src={SMSNotification} alt="SMS Notification" />
    </div>
    <div className="flex-1 px-2">
      <strong className="text-sm font-medium text-j-dark-600 block pb-2">
        Phone SMS Notifications
      </strong>
      <div className="text-sm text-j-dark-400">
        Text me important reminders, such as for my upcoming classes or when my
        student is missing from their scheduled class.
      </div>
    </div>
    <div className="pt-3 md:pt-0">
      <Toggle disabled={loading} isChecked={smsConsent} onChange={toggle} />
    </div>
  </div>
);

export default SmsConsentInput;
