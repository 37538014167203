import React, { FC, useState, useRef, useEffect } from 'react';
import {
  Popover,
  NewButton,
  Icon,
  Divider,
  Toggle,
  Message,
  NewModalWindow,
} from 'core-components';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {
  useUpdateProjectShareSettingsMutation,
  UpdateProjectShareSettingsUpdateParamsInputType,
  UpdateProjectShareSettingsUsermediaReturnType,
  useMarkOnDemandModuleSectionAsCompleteMutation,
} from 'generated/graphql';
import { JRSModalPreviewV2 } from 'components/jide';
import UsernameCreator from 'components/UsernameCreatorV2';
import RingSpinner from 'components/RingSpinner';
import JideSocialShare from 'components/jide/JideWidgets/JideSocialShare';
import projectAddedToProfile from 'images/project-added-to-profile.svg';
import projectNotAddedToProfile from 'images/project-not-added-to-profile.svg';

import { environmentsWithCodeEditor } from 'services/juni_ide';
import { checkIfSectionCanBeMarkedAsComplete } from 'utils/course_progress';
import JideNextSection from '../JideNextWidget/JideNextSection';

const DEBOUNCE_TIME = 500;

// TODO: Add banner if this project was featured on Juniverse

const JideShareV2ProfileBanner: FC<{ profileLink?: string }> = ({ profileLink }) =>
  profileLink ? (
    <div className="flex items-center py-1 px-4 bg-j-green-100 rounded-lg font-graphik space-x-3">
      <div className="mt-3 -mb-3">
        <img src={projectAddedToProfile} alt="Project Added to Profile" />
      </div>
      <div
        className="flex flex-col space-y-1"
        data-cy="jide-share-btn-profile-banner"
      >
        <div className="text-j-green-700 font-medium">
          Project is added to your Profile!
        </div>
        <div className="text-j-dark-400">Control your display settings below</div>
        <NavLink
          to={profileLink}
          target="_blank"
          className="bg-j-green-500 py-1 px-3 rounded-lg font-medium text-white mr-auto no-underline"
        >
          View your Profile
        </NavLink>
      </div>
    </div>
  ) : (
    <div className="flex items-center py-2 px-4 bg-j-gray-200 rounded-lg font-graphik space-x-3 no-underline">
      <div className="mt-3 -mb-3">
        <img src={projectNotAddedToProfile} alt="Project Not Added to Profile" />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-j-dark-600 font-medium">
          This project isn't on your profile
        </div>
        <div className="text-j-dark-400">
          Add this to your profile to get more views and possibly featured on the
          Juniverse!
        </div>
      </div>
    </div>
  );

interface JideShareV2Props {
  toggleSiblingWidgets: () => void;
  setRecordingMode: (val: boolean) => void;
  studentId?: string;
  instructorUserId?: string;
  username?: string;
  courseId: string;
  moduleId: string;
  projectId: string;
  isCustomProject: boolean;
  defaultProjectTitle: string;
  setIsPublicBadge: (val: boolean | undefined | null) => void;
  environmentType: string;
  schedulingFormat: string;
  // Note: The ModuleSection type in generated does not match the
  // format in the idLookup. One of the main differences are in the idLookup
  // the id field is labeled as _id. This is a temporary typing here until
  // we are no longer reliant on the idLookup
  nextSection?: React.ComponentProps<typeof JideNextSection>['nextSection'];
  idLookup: { [key: string]: any };
}

const JideShareV2: FC<JideShareV2Props> = ({
  toggleSiblingWidgets,
  setRecordingMode,
  studentId,
  instructorUserId,
  username,
  courseId,
  moduleId,
  projectId,
  isCustomProject,
  defaultProjectTitle,
  setIsPublicBadge,
  environmentType,
  // TODO: uncomment once we surface the 'Next' button for on demand courses
  schedulingFormat,
  nextSection,
  idLookup,
}) => {
  const syncLastInvokedAtRef = useRef<number>(0);
  const projectTitleInputRef = useRef<HTMLInputElement>(null);
  const savedStatusTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [updateProjectShareSettings] = useUpdateProjectShareSettingsMutation();
  const [markSectionAsComplete] = useMarkOnDemandModuleSectionAsCompleteMutation();

  const [open, setOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [showUsernameCreator, setShowUsernameCreator] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isDetailExpanded, setIsDetailExpanded] = useState(false);
  const [isTitleInputFocused, setIsTitleInputFocused] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [usermedia, setUsermedia] = useState<
    UpdateProjectShareSettingsUsermediaReturnType[]
  >([]);
  const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(false);

  const [projectPageLink, setProjectPageLink] = useState<string>('');

  const defaultLocalState = {
    title: defaultProjectTitle,
    description: undefined,
    isPublic: false,
    attachedUsermedia: [],
    isCodeHidden: false,
    projectType: environmentType,
  };
  const [dbState, setDbState] = useState<
    UpdateProjectShareSettingsUpdateParamsInputType | undefined | null
  >();
  const [localState, setLocalState] = useState<
    UpdateProjectShareSettingsUpdateParamsInputType
  >(defaultLocalState);
  const localStateRef = useRef<UpdateProjectShareSettingsUpdateParamsInputType>(
    defaultLocalState,
  );

  const isOnDemandCourse = schedulingFormat === 'on_demand';
  const courseName = idLookup[courseId]?.displayName;

  const syncForRealsies = async (queryOnly?: boolean) => {
    if (syncLastInvokedAtRef.current > Date.now() - DEBOUNCE_TIME) return;

    if (!queryOnly && _.isEqual(dbState, localStateRef.current)) {
      setErrorMsg('');
      setIsFetching(false);
      setIsSyncing(false);
      return;
    }

    setIsSyncing(true);
    try {
      const { data } = await updateProjectShareSettings({
        variables: {
          input: {
            queryParams: {
              studentId,
              instructorUserId,
              courseId,
              moduleId,
              projectId,
              playgroundProjectType: environmentType,
              isCustomProject,
            },
            updateParams:
              !queryOnly && localStateRef.current
                ? {
                    title: localStateRef.current.title,
                    description: localStateRef.current.description,
                    isPublic: localStateRef.current.isPublic,
                    attachedUsermedia: localStateRef.current.attachedUsermedia,
                    isCodeHidden: localStateRef.current.isCodeHidden,
                    projectType: localStateRef.current.projectType,
                  }
                : undefined,
          },
        },
      });
      const dbStateReturned = data?.updateProjectShareSettings?.studentProject;
      const usermediaReturned = data?.updateProjectShareSettings?.usermedia;
      const projectPageLinkReturned =
        data?.updateProjectShareSettings.projectPageLink;

      setDbState(dbStateReturned);
      setLocalState(dbStateReturned || defaultLocalState);
      localStateRef.current = dbStateReturned || defaultLocalState;

      if (!queryOnly) {
        setShowSaveSuccess(true);
        if (savedStatusTimeoutRef.current)
          clearTimeout(savedStatusTimeoutRef.current);
        savedStatusTimeoutRef.current = setTimeout(
          () => setShowSaveSuccess(false),
          2000,
        );
      }

      if (usermediaReturned) setUsermedia(usermediaReturned);
      if (projectPageLinkReturned) setProjectPageLink(projectPageLinkReturned);
      setErrorMsg('');
    } catch (err) {
      console.error(err);
      setErrorMsg(String(err).replace('Error: ', ''));
    }
    setIsFetching(false);
    setIsSyncing(false);
  };
  const sync = (newLocalState?: UpdateProjectShareSettingsUpdateParamsInputType) => {
    if (newLocalState) {
      setLocalState(newLocalState);
      localStateRef.current = newLocalState;
    }
    syncLastInvokedAtRef.current = Date.now();
    setTimeout(() => syncForRealsies(!newLocalState), DEBOUNCE_TIME);
  };

  const togglePopover = () => {
    if (studentId && isOnDemandCourse) {
      const markSectionAsCompleteParams = {
        studentId,
        moduleId,
        sectionId: projectId,
        courseCurriculumId: courseId,
      };

      const sectionShouldBeMarkedAsComplete = checkIfSectionCanBeMarkedAsComplete({
        ...markSectionAsCompleteParams,
        idLookup,
      });
      if (sectionShouldBeMarkedAsComplete) {
        markSectionAsComplete({
          variables: {
            input: {
              ...markSectionAsCompleteParams,
            },
          },
        });
      }
    }

    if (!open && !username) {
      setShowUsernameCreator(true);
      return;
    }
    if (!open) {
      setIsFetching(true);
      sync({
        ...localState,
        isPublic: (isOnDemandCourse && !!username) || localState.isPublic,
      });
    }
    toggleSiblingWidgets();
    setOpen(!open);
  };
  const toggleIsDetailExpanded = () => {
    setIsDetailExpanded(!isDetailExpanded);
  };
  const usernameCreatorCallback = async () => {
    window.location.reload();
  };

  const toggleIsPublic = () => {
    if (isSyncing) return;
    sync({ ...localState, isPublic: !localState.isPublic });
  };
  const toggleIsCodeHidden = () => {
    if (isSyncing) return;
    sync({ ...localState, isCodeHidden: !localState.isCodeHidden });
  };

  const titleInputOnChange = (e: any) => {
    if (isSyncing) return;
    const newLocalState = { ...localState, title: e.target.value };
    setLocalState(newLocalState);
    localStateRef.current = newLocalState;
  };
  const titleInputBlurOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      projectTitleInputRef.current?.blur();
    }
  };
  const titleInputOnFocus = () => {
    setIsTitleInputFocused(true);
  };
  const titleInputOnBlur = () => {
    setIsTitleInputFocused(false);
    sync({ ...localState, title: projectTitleInputRef.current?.value });
  };

  const attachRecording = (usermediaId: string) => {
    if (isSyncing) return;
    const isAttached = localState.attachedUsermedia?.includes(usermediaId);
    sync({ ...localState, attachedUsermedia: isAttached ? [] : [usermediaId] });
  };

  useEffect(() => setIsPublicBadge(isFetching ? undefined : localState?.isPublic), [
    isFetching,
    localState?.isPublic,
    setIsPublicBadge,
  ]);

  useEffect(() => {
    setIsFetching(true);
    sync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordings = usermedia.map(u => (
    <JRSModalPreviewV2
      key={u._id}
      onClick={() => {
        attachRecording(u._id);
      }}
      selected={localState.attachedUsermedia?.includes(u._id)}
      src={u.s3Url}
      title={u.title}
      createdAt={u.createdAt}
      originalLength={u.originalLength}
    />
  ));

  return (
    <div>
      <NewButton
        variant="secondary"
        size="xsmall"
        renderIconLeft={props => <Icon.ArrowUpRight {...props} />}
        onClick={togglePopover}
        data-cy="jide-share-btn"
        className="px-1"
      >
        <div className="mr-1">{isOnDemandCourse ? 'Next' : 'Share'}</div>
        <Icon.Info height={16} width={16} />
      </NewButton>

      <UsernameCreator
        isOpen={showUsernameCreator}
        closeModal={() => setShowUsernameCreator(false)}
        studentId={studentId}
        instructorUserId={instructorUserId}
        onSuccessCallback={usernameCreatorCallback}
      />

      {open && (
        <Popover
          className={classNames(
            'absolute bottom-20 right-2 flex flex-col w-96 px-4 pt-3',
            'space-y-2 text-sm border border-solid border-j-dark-100 overflow-y-auto',
          )}
          style={{ maxHeight: 'calc(100vh - 180px)' }}
          onClose={togglePopover}
        >
          <div className="flex text-j-dark-600 items-center h-8">
            <div className="flex text-lg font-semibold mr-2">Share project</div>
            <div className="flex flex-grow">
              {isSyncing && !errorMsg && (
                <div
                  className={classNames(
                    'bg-j-blue-100 mt-1 py-1 px-3',
                    'rounded-lg font-medium text-j-blue-600 text-xs',
                  )}
                >
                  Syncing...
                </div>
              )}
              {showSaveSuccess && !isSyncing && !errorMsg && (
                <div
                  className={classNames(
                    'bg-j-green-100 mt-1 py-1 px-3',
                    'rounded-lg font-medium text-j-green-600 text-xs',
                  )}
                >
                  Saved ✓
                </div>
              )}
            </div>

            <div
              onClick={() => {
                setOpen(false);
                setHelpOpen(true);
              }}
              className="flex cursor-pointer items-center mr-2 font-graphik bg-j-dark-600 rounded-full text-white p-1 pl-2"
            >
              <div className="mr-1 font-medium text-xs">What's new</div>
              <div className="flex bg-white text-j-dark-600 rounded-full items-center">
                <Icon.QuestionCircle height={20} width={20} />
              </div>
            </div>

            <div
              className="flex flex-shrink-0 cursor-pointer"
              onClick={togglePopover}
            >
              <Icon.Times height={14} width={14} />
            </div>
          </div>
          <div className="text-j-dark-600">
            {isOnDemandCourse &&
              (nextSection ? (
                <div>Your progress is updated. Move on to the next section!</div>
              ) : (
                <div>
                  Congratulations, you’ve completed {courseName}! Find your next
                  course in the{' '}
                  <a
                    href={`/learner/${studentId}/on_demand`}
                    className="text-j-dark-600 font-semibold no-underline"
                  >
                    On Demand Library!
                  </a>
                </div>
              ))}
          </div>
          <Divider />

          <div
            className={classNames('flex py-10 items-center justify-center', {
              hidden: !isFetching,
            })}
          >
            <RingSpinner />
          </div>

          <div className={classNames('space-y-2', { hidden: isFetching })}>
            {errorMsg ? (
              <Message status="error" fullWidth>
                {errorMsg}
              </Message>
            ) : (
              <div className="py-1">
                <JideShareV2ProfileBanner
                  profileLink={
                    localState.isPublic ? `/juniverse/users/${username}` : undefined
                  }
                />
              </div>
            )}

            <div className="flex items-center">
              <div className="mr-2" data-cy="jide-share-btn-add-to-profile">
                <Toggle
                  isChecked={!!localState.isPublic}
                  onChange={toggleIsPublic}
                />
              </div>
              <div className="font-semibold text-j-dark-600">
                Add to your Profile
              </div>
            </div>

            {!!localState.isPublic && (
              <div className="flex flex-col space-y-2 pb-2">
                {environmentsWithCodeEditor.includes(environmentType) && (
                  <div className="flex items-center">
                    <div className="mr-2">
                      <Toggle
                        isChecked={!!localState.isCodeHidden}
                        onChange={toggleIsCodeHidden}
                      />
                    </div>
                    <div className="font-semibold text-j-dark-600">Hide Code</div>
                  </div>
                )}

                <div
                  className="font-semibold text-j-blue-400 cursor-pointer pt-2"
                  onClick={toggleIsDetailExpanded}
                >
                  {isDetailExpanded ? (
                    <>
                      Hide project details <Icon.CaretDown />
                    </>
                  ) : (
                    <>
                      Show project details <Icon.CaretUp />
                    </>
                  )}
                </div>

                {isDetailExpanded && (
                  <>
                    <div className="font-graphik text-xs font-medium text-j-dark-600 pt-2">
                      PROJECT NAME
                    </div>

                    <input
                      ref={projectTitleInputRef}
                      className={classNames(
                        'text-sm py-2 px-3 text-j-dark-500',
                        'rounded-lg border-solid border mb-2',
                        {
                          'border-j-dark-100': !isTitleInputFocused,
                          'border-j-dark-300': isTitleInputFocused,
                        },
                      )}
                      type="text"
                      value={localState.title || ''}
                      onChange={titleInputOnChange}
                      spellCheck={false}
                      onKeyDown={titleInputBlurOnEnter}
                      onFocus={titleInputOnFocus}
                      onBlur={titleInputOnBlur}
                    />

                    <div className="font-graphik text-xs font-medium text-j-dark-600 pt-2">
                      ATTACH RECORDING
                    </div>

                    <div className="flex flex-col w-full">
                      <div className="flex overflow-x-auto overflow-y-hidden space-x-2">
                        {recordings}
                      </div>
                    </div>

                    <div
                      className={classNames(
                        'flex cursor-pointer items-center justify-center p-3 text-j-blue-400',
                        'font-semibold border border-dashed rounded-lg border-j-dark-200',
                        'hover:bg-juni-blue-50',
                      )}
                      onClick={() => {
                        togglePopover();
                        setRecordingMode(true);
                      }}
                    >
                      + Create New Recording (optional)
                    </div>
                  </>
                )}
              </div>
            )}
            {projectPageLink && (
              <>
                <Divider />
                <JideSocialShare
                  shareLink={projectPageLink}
                  shareCopy="Check out this project I made on Juni!"
                  shareContent="juniverse_project_link"
                />
              </>
            )}
            {nextSection && (
              <>
                <hr className="border-0 bg-j-purple-100 h-px my-0 mx-0" />{' '}
                <JideNextSection nextSection={nextSection} />
              </>
            )}
          </div>
        </Popover>
      )}

      {helpOpen && (
        <NewModalWindow
          closeModal={() => {
            setOpen(true);
            setHelpOpen(false);
          }}
          isOpen
          showCloseIcon
          title="How to share your project"
          contentMaxHeight="60vh"
          contentPadding="0"
        >
          <iframe
            title="helpVideo"
            src="https://player.vimeo.com/video/660768244?h=12f0609c2c&autoplay=1&title=0&byline=0&portrait=0"
            width="675"
            height="380"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </NewModalWindow>
      )}
    </div>
  );
};

export default JideShareV2;
