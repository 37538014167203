import React, { FC, useEffect } from 'react';
import usePrevious from 'hooks/usePrevious';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import useIsValidCoupon from '../hooks/useValidCoupon';
import updateSelectedDiscountData from '../lib/updateSelectedDiscountData';

const DiscountCodeBanner: FC<{
  urlDiscountCode: string;
}> = ({ urlDiscountCode }) => {
  const { setSignupSession, signupData, flags } = useSignupContext();
  const { isCouponValid, discountDataObj } = useIsValidCoupon(
    urlDiscountCode,
    flags?.isPrivateOneOnOne,
    flags?.isOnDemandSignup,
  );
  const discountCodeString = discountDataObj?.discountCode;
  const prevDiscountCodeString = usePrevious(discountCodeString);

  // apply coupon code directly to signup session if there is discount code in url
  // if there is an existing referrral code applied, the promo code in url will take prioirty
  useEffect(() => {
    let applyCoupon = false;
    if (discountCodeString && prevDiscountCodeString !== discountCodeString) {
      const newDiscountCodes = signupData.discountCodes?.find(
        code => code.discountCode === discountCodeString,
      )
        ? updateSelectedDiscountData(discountDataObj, signupData.discountCodes)
        : updateSelectedDiscountData(discountDataObj, [
            ...(signupData?.discountCodes ?? []),
            { ...discountDataObj, isSelected: true },
          ]);
      if (!applyCoupon) {
        setSignupSession?.({ discountCodes: newDiscountCodes });
      }
    }
    return () => {
      applyCoupon = true;
    };
  }, [
    discountDataObj,
    discountCodeString,
    prevDiscountCodeString,
    setSignupSession,
    signupData.discountCodes,
  ]);

  if (!isCouponValid) {
    return null;
  }

  return (
    <div className="w-full bg-j-green-600 text-white text-center py-4">
      Coupon code <span className="uppercase font-semibold">{urlDiscountCode}</span>{' '}
      will be added at checkout.
    </div>
  );
};
export default DiscountCodeBanner;
