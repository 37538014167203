import React, { FC, useState } from 'react';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { Icon, Message, NewButton, TextArea } from 'core-components';
import { QueryResult } from '@apollo/client';
import {
  RequestNewInstructorInsteadOfChurningInput,
  LoadStudentsByParentIdsQuery,
  useRequestNewInstructorInsteadOfChurningMutation,
} from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import LearnerPortalModal from '../LearnerPortalModal';
import { FormState, FormValues } from '../types';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
}

const OptionToChangeInstructorModal: FC<Props> = ({
  formState,
  formValues,
  updateFormState,
  studentsQuery,
}) => {
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [
    createStudentHandoff,
    { loading, error },
  ] = useRequestNewInstructorInsteadOfChurningMutation();

  const { subscription } = formValues;
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );

  const handleCreateTransitionTicket = async () => {
    if (loading) return;
    const createStudentHandoffInput: RequestNewInstructorInsteadOfChurningInput = {
      studentId: currentStudents[0]._id,
    };
    if (notes) {
      createStudentHandoffInput.notes = notes;
    }
    try {
      await createStudentHandoff({
        variables: {
          input: createStudentHandoffInput,
        },
      });
      updateFormState('switch_instructor_confirmation', 'modal');
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.toString());
      }
    }
  };

  return (
    <LearnerPortalModal
      formState={formState}
      title=""
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          variant="secondary"
          onClick={() => {
            const nextModalName = 'cancel_optional_discount';
            JuniAnalytics.track(`${formState.modal}_button_clicked`, {
              funnel: 'subscription_upgrade_downgrade',
              sourceModal: formState.modal,
              destinationModal: nextModalName,
              actionFlow: formState.updateFlow,
            });
            updateFormState(nextModalName, 'modal');
          }}
        >
          No thanks, finish pausing/canceling
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            const modalName = 'cancel_check';
            updateFormState(modalName, 'modal');
          }}
          variant="secondary"
          renderIconLeft={props => <Icon.ChevronLeft {...props} />}
        >
          Back
        </NewButton>
      }
    >
      <div className="flex flex-col items-center text-j-dark-600 leading-5 gap-2 text-center w-full">
        <div className="font-semibold text-2xl py-3">Can we make it right?</div>
        <Icon.Refresh height={50} width={50} />

        <div className="text-j-dark-400 text-base leading-8">
          Is your instructor's teaching style not a good fit for your child?
          <br />
          We are happy to find you the right fit at no cost.
        </div>

        <div className="flex flex-col items-center my-3 w-full gap-3">
          {error && (
            <Message status="error" className="mb-2">
              We are unable to complete your request, please refresh the page and try
              again, or contact Juni HQ if the error persists.
            </Message>
          )}
          <div className="flex flex-col w-2/3">
            <TextArea
              onChange={e => setNotes(e.target.value)}
              value={notes}
              size="xsmall"
              fullWidth
              placeholder="Any comments you would like to provide regarding your request (e.g course, time, or instructor preferences)."
            />
          </div>
          <NewButton className="w-2/3" onClick={handleCreateTransitionTicket}>
            {loading ? (
              <SpinnerV2 />
            ) : (
              <div className="font-medium">
                Click here to request a New Instructor!
              </div>
            )}
          </NewButton>
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default OptionToChangeInstructorModal;
