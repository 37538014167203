/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLatexHtmlStr } from 'utils/latex';
import {
  createMathAnswerAttempt,
  submitMathAnswerAttempt,
} from 'services/api/math_answer_attempt';

import './jide_math_problem.css';

import ActionButton from 'components/ActionButton';
import { Arrow, CheckmarkIcon, XIcon } from 'components/Icons';
import { Message, TextArea, NewButton as Button } from 'core-components';
import ActivityInstructions from '../ActivityInstructions';

// Note - modalViewMode means instructor mode which is utilized when viewing from the Instructor app
const JideMathProblem = props => {
  if (props.newHorizons) {
    return (
      <div className="font-graphik">
        <ActivityInstructions
          horizontalPadding="0"
          instructions={getLatexHtmlStr(props.problem.text)}
        />
        {props.problem.hasExpectedSolution && <JideMathAnswerForm {...props} />}
        {!props.problem.hasExpectedSolution && (
          <Message status="info" className="mt-5">
            {props.modalViewMode
              ? 'If the student has attempted to provide an answer for this problem, you should see it on the whiteboard to the left with a box drawn around it.'
              : 'Put a box around your answer(s) and your instructor will check it!'}
          </Message>
        )}
      </div>
    );
  }

  return (
    <div className="jide-math-problem">
      <div className="scrollable-content">
        <div
          className="problem-instructions"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getLatexHtmlStr(props.problem.text),
          }}
        />
      </div>
      {props.problem.hasExpectedSolution && <JideMathAnswerForm {...props} />}
      {!props.problem.hasExpectedSolution && (
        <div className="problem-answer">
          <div className="label-and-correctness">
            <label>{props.modalViewMode ? "Student's" : 'Your'} Answer</label>
          </div>
          <div style={{ margin: '12px 0' }}>
            {props.modalViewMode
              ? 'If the student has attempted to provide an answer for this problem, you should see it on the whiteboard to the left with a box drawn around it.'
              : 'Put a box around your answer(s) and your instructor will check it!'}
          </div>
        </div>
      )}
    </div>
  );
};
JideMathProblem.defaultProps = {
  answerAttempt: null,
  studentId: '', // should only use default value when in trial mode
  modalViewMode: false,
  newHorizons: false,
};
JideMathProblem.propTypes = {
  problem: PropTypes.shape({}).isRequired,
  answerAttempt: PropTypes.shape({}),
  onSubmitAnswer: PropTypes.func.isRequired,
  moduleSectionId: PropTypes.string.isRequired,
  studentId: PropTypes.string,
  modalViewMode: PropTypes.bool,
  newHorizons: PropTypes.bool,
};

class JideMathAnswerForm extends Component {
  state = {
    answer: this.props.answerAttempt?.value || '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.problem._id !== this.props.problem._id) {
      this.setState({
        answer: this.props.answerAttempt?.value || '',
      });
      return;
    }
    if (
      (!prevProps.answerAttempt && this.props.answerAttempt) ||
      (prevProps.answerAttempt &&
        this.props.answerAttempt &&
        prevProps.answerAttempt.value !== this.props.answerAttempt.value)
    ) {
      this.setState({ answer: this.props.answerAttempt.value });
    }
  }

  submitAnswer = async () => {
    const {
      answerAttempt,
      moduleSectionId,
      problem,
      studentId,
      modalViewMode,
    } = this.props;
    if (modalViewMode) return;
    const { answer } = this.state;
    const trimmedAnswerToLower = answer.replace(/\s/g, '').toLowerCase();
    const solutions = problem.hasExpectedSolution
      ? problem.solutions.map(s => s.solutionText.replace(/\s/g, '').toLowerCase())
      : [];
    const isCorrect = solutions.includes(trimmedAnswerToLower);
    if (studentId) {
      if (answerAttempt) {
        return submitMathAnswerAttempt(answerAttempt._id, answer, isCorrect)
          .then(answerAttempt => {
            this.props.onSubmitAnswer(answerAttempt);
            return answerAttempt;
          })
          .catch(err => {
            console.error(err);
          });
      }
      return createMathAnswerAttempt(
        studentId,
        problem._id,
        moduleSectionId,
        answer,
        isCorrect,
      )
        .then(answerAttempt => {
          this.props.onSubmitAnswer(answerAttempt);
          return answerAttempt;
        })
        .catch(err => {
          console.error(err);
        });
    }
    // no studentId prop means IDE is in trial mode, so no saving to db will occur
    this.props.onSubmitAnswer({
      _id: answerAttempt
        ? answerAttempt._id
        : `local-answer-attempt-${moduleSectionId}-${problem._id}`,
      problemId: problem._id,
      moduleSectionId,
      value: answer,
      wasCorrect: isCorrect,
    });
  };
  handleAnswerChange = e => {
    this.setState({ answer: e.currentTarget.value });
  };

  render() {
    const { answerAttempt, problem, modalViewMode, newHorizons } = this.props;
    const { answer } = this.state;
    const submitDisabled =
      !answer ||
      answer.length === 0 ||
      answer === (answerAttempt ? answerAttempt.value : '');
    const solutions = problem.hasExpectedSolution
      ? problem.solutions.map(s => s.solutionText.replace(/\s/g, '').toLowerCase())
      : [];
    const showCorrectness = answerAttempt && answerAttempt.value === answer;
    const trimmedAnswerAttemptToLower = answerAttempt
      ? answerAttempt.value.replace(/\s/g, '').toLowerCase()
      : '';
    const isCorrect = solutions.includes(trimmedAnswerAttemptToLower);
    const textareaDisabled = modalViewMode || (answerAttempt && isCorrect);
    const wasCorrect = answerAttempt ? answerAttempt.wasCorrect : false;
    const wasCorrectForPriorVersion = wasCorrect && !isCorrect;

    if (newHorizons) {
      let message;

      if (showCorrectness) {
        message = isCorrect ? 'Correct' : 'Incorrect';
      }

      return (
        <>
          {wasCorrectForPriorVersion && (
            <Message status="info" className="mt-6">
              Note: This problem has been updated since you last submitted an answer.
              Your answer was correct for an older version of this problem.
            </Message>
          )}
          <div className="mt-6">
            <label
              htmlFor="answer"
              className="mt-6 text-sm leading-6 font-semibold text-j-dark-600"
            >
              Answer
            </label>
          </div>
          <div className="mt-2">
            <TextArea
              id="answer"
              value={answer}
              onChange={this.handleAnswerChange}
              placeholder="Write your answer..."
              disabled={textareaDisabled}
              rows={2}
              valid={showCorrectness ? isCorrect : undefined}
              message={message}
              fullWidth
            />
          </div>
          {!modalViewMode && (!answerAttempt || (answerAttempt && !isCorrect)) && (
            <div className="mt-4 flex justify-end">
              <Button onClick={this.submitAnswer} disabled={submitDisabled}>
                Submit
              </Button>
            </div>
          )}
        </>
      );
    }

    return (
      <div
        className={`problem-answer ${answerAttempt ? 'has-answer-attempt' : ''} ${
          answerAttempt && isCorrect ? 'correct' : 'incorrect'
        }`}
      >
        <div className="label-and-correctness">
          <label>
            {modalViewMode ? "Student's" : 'Your'} Answer {showCorrectness && '—'}
          </label>
          {showCorrectness &&
            (isCorrect ? (
              <div className="correctness-tag correct">
                <CheckmarkIcon />
                Correct!
              </div>
            ) : (
              <div className="correctness-tag incorrect">
                <XIcon />
                Incorrect!
              </div>
            ))}
        </div>
        {wasCorrectForPriorVersion && (
          <div className="problem-changed-msg">
            Note: This problem has been updated since you last submitted an answer.
            Your answer was correct for an older version of this problem.
          </div>
        )}
        <textarea
          className={`${showCorrectness ? 'show-correctness' : ''}`}
          rows={2}
          value={answer}
          onChange={this.handleAnswerChange}
          disabled={textareaDisabled}
        />
        {!modalViewMode && (!answerAttempt || (answerAttempt && !isCorrect)) && (
          <ActionButton
            icon={<Arrow orientation="right" />}
            onClick={this.submitAnswer}
            disabled={submitDisabled}
          >
            {answerAttempt ? 'Resubmit' : 'Submit'}
          </ActionButton>
        )}
      </div>
    );
  }
}
JideMathAnswerForm.defaultProps = {
  answerAttempt: null,
  modalViewMode: false,
  newHorizons: false,
};
JideMathAnswerForm.propTypes = {
  problem: PropTypes.shape({}).isRequired,
  answerAttempt: PropTypes.shape({}),
  onSubmitAnswer: PropTypes.func.isRequired,
  moduleSectionId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  modalViewMode: PropTypes.bool,
  newHorizons: PropTypes.bool,
};
export default JideMathProblem;
