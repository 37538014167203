declare const window: Window & { dataLayer?: Record<string, unknown>[] };

const gtmDataLayer = window.dataLayer || [];

export const gtmDataLayerPush = (event: Record<string, unknown>) => {
  gtmDataLayer.push(event);
};

export enum GtmAnalyticsEvent {
  SignUp = 'sign_up',
}
