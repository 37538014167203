import React, { FC } from 'react';
import { chunk } from 'lodash';
import { BundleSelection, SubjectName } from 'app/signup_session/types';
import { findStudentById, updateBundleDataByStudent } from 'app/signup_session/lib';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import SchedulingSelection from 'components/SchedulingSelection/SchedulingSelection';
import { DateTime } from 'components/SchedulingSelection/types';

const DraggableScheduleSections: FC<{ subjects: BundleSelection[] }> = ({
  subjects,
}) => {
  const { signupData, activeStudentId, setSignupSession } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData)!;
  return (
    <div className="space-y-8">
      {subjects.map(({ subject, weeklyClasses, schedulingPreferences }) => {
        const prefChunks: DateTime[][] = chunk(schedulingPreferences, 3);
        return prefChunks.map((selections, i) => {
          const title = `${subject}, Class ${
            i + 1
          } of ${weeklyClasses} (${weeklyClasses} class${
            weeklyClasses === 1 ? '' : 'es'
          } / week)`;
          return (
            <div key={title}>
              <h3 className="text-sm text-j-dark-600 font-medium m-0 pb-2">
                {title}
              </h3>
              <SchedulingSelection
                timeZone={student?.timezone}
                onSave={list => {
                  // each class has 3 options, the index should be the group index * 3
                  const spliceIndex = i * 3;
                  const newList = [...(schedulingPreferences as DateTime[])];
                  // we replace the new preferences order only in their respective group of 3
                  newList.splice(spliceIndex, 3, ...list);

                  setSignupSession(
                    updateBundleDataByStudent(
                      activeStudentId,
                      subject as SubjectName,
                      {
                        ...(student?.bundle?.selections?.[subject as SubjectName] ??
                          {}),
                        schedulingPreferences: newList,
                      },
                      signupData,
                    ),
                  );
                }}
                prefIndex={i}
                selections={selections}
                title={title}
              />
            </div>
          );
        });
      })}
    </div>
  );
};

export default DraggableScheduleSections;
