import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Auth from 'modules/Auth';

interface Props {
  userType: string;
}

// TO DO: refactor to route or app file
const Home: FC<Props> = ({ userType }) => {
  const location = useLocation();

  if (Auth.isUserAuthenticated() && userType.length !== 0) {
    if (userType === 'teacher' || userType === 'admin') {
      return <Redirect to="/teacher" />;
    }
    if (userType === 'parent') {
      return <Redirect to="/learner" />;
    }
  }

  if (window.location.pathname !== '/') {
    if (
      !(
        location.pathname.startsWith('/learner/login') ||
        location.pathname.startsWith('/teacher/login')
      )
    ) {
      if (
        location.pathname.startsWith('/admin/') ||
        location.pathname.startsWith('/teacher/')
      ) {
        return (
          <Redirect
            to={`/teacher/login?redirectTo=${location.pathname}${location.search}`}
          />
        );
      }
      return (
        <Redirect
          to={`/learner/login?redirectTo=${location.pathname}${location.search}`}
        />
      );
    }
  }

  return <Redirect to="/learner/login" />;
};

export default Home;
