import React from 'react';
import { useUpdateIsOptedOutOfInstructorAutoSubMutation } from 'generated/graphql';
import { Toggle } from 'core-components';

interface AutoSubCheckboxProps {
  student: {
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
    isOptedOutOfInstructorAutoSub?: boolean | null;
  };
  reloadData: any;
  setMutationErrorMsg: any;
}

const AutoSubCheckbox: React.FC<AutoSubCheckboxProps> = ({
  student,
  reloadData,
  setMutationErrorMsg,
}) => {
  const [
    updateIsOptedOutOfInstructorAutoSubMutation,
    { loading },
  ] = useUpdateIsOptedOutOfInstructorAutoSubMutation();

  async function onChange() {
    if (loading) return;
    const newValue = Boolean(!student.isOptedOutOfInstructorAutoSub);
    try {
      await updateIsOptedOutOfInstructorAutoSubMutation({
        variables: {
          input: {
            studentId: student._id,
            isOptedOutOfInstructorAutoSub: newValue,
          },
        },
      });
      setMutationErrorMsg('');
    } catch (err) {
      if (err instanceof Error) {
        setMutationErrorMsg(err.message);
      } else {
        setMutationErrorMsg('An Unknown Error Occurred!');
      }
      console.error(err);
    }
    reloadData();
  }

  return (
    <Toggle
      label={`${student.firstName} ${student.lastName}`}
      onChange={onChange}
      disabled={false}
      isChecked={!student.isOptedOutOfInstructorAutoSub}
      renderLeft={false}
    />
  );
};

export default AutoSubCheckbox;
