import { AcuityAppointment, AdditionalClass, Parent, Student } from 'models';

export interface MyCalendarProps {
  parent: Parent;
  student: Student;
  impersonationMode?: boolean;
}

export interface ScheduleCardProps {
  type: string;
  student: Student;
  parent: Parent;
  classes: (AcuityAppointment | AdditionalClass)[];
  // I'm so sorry for this. Literally just here to pass class information down
  // into the LearnerClassSchedulerWidgetV2 Actually one of the most
  // regrettable things I've done If you run into this comment and you're
  // baffled or enraged about this type, definitely bother me about it. I would
  // love to put this field out of its misery.
  // - June
  upcomingSessions?: AcuityAppointment[];
  refreshUpcomingSessions?: () => void;
  refreshAdditionalClasses?: () => void;
  instructorNameLookup?: Record<string, string>;
}

export interface ScheduleCardRowProps {
  type: string;
  session?: AcuityAppointment | AdditionalClass | AdditionalClass[];
  student?: Student;
  parent?: Parent;
  instructorName?: string;
  onClickSchedule?: (classId: string | number) => void;
  onClickCancelImmediateUpcomingClass?: (acuityAppointmentId: number) => void;
}

export function isAdditionalClass(
  appt: AcuityAppointment | AdditionalClass | AdditionalClass[],
): appt is AdditionalClass {
  return Object.prototype.hasOwnProperty.call(
    appt as AdditionalClass,
    'isMakeupClass',
  );
}

export function isClassPack(
  appt: AcuityAppointment | AdditionalClass | AdditionalClass[],
): appt is AdditionalClass[] {
  const arr = appt as AdditionalClass[];
  const additionalClass = arr.length > 0 ? arr[0] : undefined;
  return (
    (additionalClass &&
      Object.prototype.hasOwnProperty.call(additionalClass, 'isMakeupClass') &&
      !additionalClass.isMakeupClass) ||
    false
  );
}
