import { FC, useState } from 'react';
import {
  COUPON_DISALLOW_LIST,
  REFERRAL_COOKIE_NAME,
  REFERRAL_DISCOUNT_DATA,
} from 'constants/signup_sessions';

import discountCodeService from 'services/signupSessions/discountCodeService';

import { NewButton as Button, Icon, Input } from 'core-components';
import { useCookies } from 'react-cookie';

import classNames from 'classnames';

import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';
import { DiscountData } from '../types';
import useCheckoutContext from '../hooks/useCheckoutContext';
import useSignupContext from '../hooks/useSignupContext';
import { makeReferralCookieParams } from '../hooks/useReferralCode/useReferralCode';
import Radio from './Radio';
import DiscountedExplainerText from './DiscountedExplainerText';

const newDiscountInitialData = {
  discount: 0,
  discountCode: '',
  isSelected: false,
};

const DiscountCodeEditor: FC<{
  closeCouponEditor: () => void;
  addValidatedDiscountToSignupSession: (newDiscount: DiscountData) => void;
}> = ({ closeCouponEditor, addValidatedDiscountToSignupSession }) => {
  const { signupData, setSignupSession, flags } = useSignupContext();
  const {
    discountIsValid,
    setDiscountIsValid,
    setReferralCodeValid,
  } = useCheckoutContext();
  const [newDiscount, setNewDiscount] = useState<DiscountData>(
    newDiscountInitialData,
  );
  const [, setCookie] = useCookies([REFERRAL_COOKIE_NAME]);
  const setReferralCodeCookie = (newCode: string) =>
    setCookie(...makeReferralCookieParams('juni_referral_code', newCode));

  const setReferrerNameCookie = (name: string) =>
    setCookie(...makeReferralCookieParams('juni_referrer_name', name));

  const { discountCode } = newDiscount;

  const returnReferralCodeIfValid = async (referralCode: string) => {
    try {
      if (referralCode) {
        const res = await discountCodeService.validateReferralCode(referralCode);
        return res.data?.data?.[0];
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onDiscountSelect = (discountCode: string) =>
    setSignupSession({
      discountCodes:
        signupData.discountCodes?.map(code =>
          code.discountCode === discountCode
            ? { ...code, isSelected: true }
            : { ...code, isSelected: false },
        ) ?? [],
    });

  const handleResetDiscount = () => {
    setDiscountIsValid?.(true);
    setNewDiscount?.(newDiscountInitialData);
  };

  const handleValidateDiscountCode = async () => {
    if (!discountCode) return;
    // trim extra whitespace from code otherwise we can fail validations
    const trimmedDiscountCode = discountCode?.trim();
    const validReferralCode = await returnReferralCodeIfValid(trimmedDiscountCode);

    if (validReferralCode) {
      setDiscountIsValid?.(true);
      setReferralCodeValid?.(true);
      setReferralCodeCookie(validReferralCode.code);
      setReferrerNameCookie(validReferralCode.name);
      addValidatedDiscountToSignupSession({
        ...REFERRAL_DISCOUNT_DATA,
        discountCode: validReferralCode.code,
      });
      handleResetDiscount();
      closeCouponEditor();
      return;
    }
    setReferralCodeValid?.(false);

    try {
      if (COUPON_DISALLOW_LIST.includes(trimmedDiscountCode)) {
        setDiscountIsValid?.(false);
        return;
      }

      const res = await discountCodeService.validateCouponCode(trimmedDiscountCode);

      // coupon doesn't exist in stripe
      if (res.data.error) {
        setNewDiscount(newDiscountInitialData);

        if (
          trimmedDiscountCode &&
          trimmedDiscountCode !==
            signupData?.discountCodes?.find(code => code.isReferral)?.discountCode
        ) {
          setDiscountIsValid?.(false);
        }

        return;
      }
      const stripeResponse = res.data.data;
      // Coupon exists in Stripe but is no longer valid (e.g. expired)
      if (stripeResponse.valid === false) {
        setDiscountIsValid?.(false);
        const expired =
          stripeResponse.redeem_by && stripeResponse.redeem_by - Date.now() < 0;
        const resMaxRedeemedExceeded =
          stripeResponse.times_redeemed >= stripeResponse.max_redemptions;
        if (expired) {
          setNewDiscount({
            ...newDiscount,
            maxRedeemedExceeded: false,
            expired: true,
          });
          return;
        }
        if (resMaxRedeemedExceeded) {
          setNewDiscount({
            ...newDiscount,
            expired: false,
            maxRedeemedExceeded: true,
          });
        }
        return;
      }

      const couponAppliesToSpecificProduct =
        stripeResponse?.applies_to?.products?.length > 0;

      if (couponAppliesToSpecificProduct) {
        const isValidAsyncCoupon = stripeResponse?.applies_to?.products?.includes(
          ASYNC_PLAN_PRODUCT.productId,
        );
        const isValidPrivateCoupon = stripeResponse?.applies_to?.products?.some(
          (productId: string) => productId.startsWith('prod_core_'),
        );
        // the order of the below condition matter as there is a current bug with isOnDemandSignup and isPrivateOneOnOne sign up flags both marked as true
        if (flags?.isOnDemandSignup && !isValidAsyncCoupon) {
          throw new Error(
            'This coupon cannot be redeemed for On Demand Subscription',
          );
        }
        if (
          flags?.isPrivateOneOnOne &&
          !flags?.isOnDemandSignup &&
          !isValidPrivateCoupon
        ) {
          throw new Error('This coupon cannot be redeemed for private 1:1 classes.');
        }
      }

      addValidatedDiscountToSignupSession({
        discountCode: trimmedDiscountCode,
        discount: stripeResponse.amount_off ?? stripeResponse.percent_off,
        discountType: stripeResponse.amount_off ? 'amount_off' : 'percent_off',
        duration: stripeResponse.duration,
        durationInMonths: stripeResponse.duration_in_months,
      });
      handleResetDiscount();
      // closeCouponEditor();
    } catch (e) {
      setDiscountIsValid?.(false);
    }
  };

  return (
    <div data-cy="coupon-code-editor" className={classNames('flex flex-col')}>
      <div className="flex justify-between items-center">
        <h3 className="font-medium text-j-dark-600 my-0">Enter Coupon Code</h3>
        <Button
          variant="minimal"
          icon
          onClick={closeCouponEditor}
          className="text-j-dark-600"
        >
          <Icon.Times width={14} height={14} />
        </Button>
      </div>
      <p className="text-sm text-j-dark-300 my-0 pb-3">
        You can only use one coupon code per purchase. If you have a referral code,
        please enter it here.
      </p>
      {signupData.discountCodes?.map((discount, index) => (
        <div
          key={discount.discountCode}
          role="button"
          tabIndex={index}
          className={classNames(
            'flex items-center space-x-2 mb-2 cursor-pointer rounded-lg px-3 py-2 min-h-8',
            {
              'bg-j-purple-100 border-2 border-j-purple-600 border-solid':
                discount.isSelected,
              'border border-j-gray-300 border-solid': !discount.isSelected,
            },
          )}
          onClick={() => {
            if (discount.discountCode) {
              onDiscountSelect(discount.discountCode);
              closeCouponEditor();
            }
          }}
        >
          <div>
            <Radio selected={discount.isSelected} />
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <span className="font-medium text-j-dark-600 text-xs">
              {discount.discountCode}
            </span>
            <span
              data-cy={`discount-explainer-text-${discount.discountCode}`}
              className="text-xs w-full text-right text-j-dark-400"
            >
              <DiscountedExplainerText
                selectedDiscount={discount}
                isBootcampSignup={flags.isBootcampSignup}
              />
            </span>
          </div>
        </div>
      ))}
      <div className="py-3">
        <label
          htmlFor="coupon-code"
          className="w-full flex flex-row justify-between pb-2 font-medium text-xs"
        >
          <span className="text-j-dark-600 uppercase">New Code</span>
          <span className="text-j-dark-400 uppercase">Case Sensitive</span>
        </label>
        <Input
          id="coupon-code"
          fullWidth
          valid={discountIsValid ? undefined : false}
          placeholder="Enter the code"
          message={
            discountIsValid
              ? ''
              : newDiscount.expired || newDiscount.maxRedeemedExceeded
              ? `This coupon is no longer valid`
              : `This coupon is invalid for the selected product`
          }
          onChange={e => {
            if (discountCode !== undefined) {
              handleResetDiscount();
            }
            setNewDiscount?.({
              ...newDiscount,
              discountCode: e.target.value,
            });
          }}
          value={newDiscount.discountCode ?? ''}
        />
      </div>
      <Button
        variant="primary"
        onClick={handleValidateDiscountCode}
        disabled={newDiscount.discountCode === '' || !discountIsValid}
      >
        <span data-cy="add-coupon">Redeem coupon</span>
      </Button>
    </div>
  );
};

export default DiscountCodeEditor;
