import React, { useState } from 'react';
import { NewButton, NewCard, Select } from 'core-components';
import { CLImg } from 'app/admin/curriculum/CourseListing/CourseListingComponents';

import { GetCoursesReturnFragment } from 'generated/graphql';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import emptyState from 'images/empty_state.png';
import { SUBJECT_TO_METADATA_KEY, SUBJECT_TYPE } from 'constants/subjects';
import _ from 'lodash';
import { JUNI_LINKS } from 'constants/links';
import { getLearnerEnrollableCourses } from 'app/learner/LearnerAccountV2/utils';
import { SubscriptionMetaDataWithCourse } from '../../../types';

export interface Props {
  updatedSubscription: SubscriptionMetaDataWithCourse;
  courses: GetCoursesReturnFragment[];
  setSelectCourse: React.Dispatch<
    React.SetStateAction<GetCoursesReturnFragment | undefined>
  >;
}

const SelectCourse: React.FC<Props> = ({
  updatedSubscription,
  courses,
  setSelectCourse,
}) => {
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>(
    undefined,
  );

  const subjectOptions = _.uniqBy(
    courses.map(course => ({
      value: course.subject.name,
      label: course.subject.displayName,
    })),
    s => s.value,
  ).filter(subject => {
    const subjectFrequencies = Object.keys(updatedSubscription);
    return !subjectFrequencies.includes(
      SUBJECT_TO_METADATA_KEY[subject.value as SUBJECT_TYPE],
    );
  });

  const currentCourses = Object.values(updatedSubscription).flatMap(
    state => state.courses,
  );

  const enrollableCourses = getLearnerEnrollableCourses(courses);

  return (
    <div className="w-full">
      <label className="text-j-dark-600 text-sm font-medium pb-2">
        New Course Subject:
      </label>
      <Select
        size="small"
        selected={selectedSubject}
        placeholder="Subject"
        options={subjectOptions}
        fullWidth
        onChange={e => setSelectedSubject(e)}
      />
      {selectedSubject ? (
        <div className="mx-auto my-4 grid gap-2">
          {enrollableCourses
            .filter(
              course =>
                course.subject.name === selectedSubject &&
                !currentCourses.includes(course.name),
            )
            .map(course => (
              <NewCard key={course.displayName} padding="2" shadow="2" className="">
                <div className="flex flex-row justify-between items-center w-full">
                  <div className="flex flex-row gap-3 items-center justify-center">
                    <div className="sm:block hidden">
                      <CLImg course={course} />
                    </div>
                    <div className="flex flex-col max-w-xs w-full text-j-dark-700 ">
                      <span className="font-medium sm:text-base text-sm">
                        {course.displayName}
                      </span>
                      {course.minRecommendedAge && course.maxRecommendedAge && (
                        <span className="sm:text-sm text-xs">
                          recommended ages {course.minRecommendedAge}-
                          {course.maxRecommendedAge}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="w-full max-w-max">
                      <NewButton onClick={() => setSelectCourse(course)}>
                        Add Course
                      </NewButton>
                      <div className="w-full sm:block hidden">
                        <NewButton
                          variant="minimal"
                          fullWidth
                          size="xsmall"
                          onClick={() => {
                            JuniAnalytics.track(
                              'upgrade_add_course_details_button_clicked',
                              {
                                course,
                              },
                            );
                            window.open(
                              `${JUNI_LINKS.MARKETING}/courses/${course.slug}`,
                              '_blank',
                            );
                          }}
                        >
                          Details
                        </NewButton>
                      </div>
                    </div>
                  </div>
                </div>
              </NewCard>
            ))}
        </div>
      ) : (
        <div className="py-12 flex flex-col items-center gap-4">
          <div className="font-medium text-j-dark-600 md:text-8 md:leading-12 text-xl text-center">
            Please select the subject of course you'd like add!
          </div>
          <img
            className="w-60 h-60"
            src={emptyState}
            alt="No results based on filter selected"
          />
        </div>
      )}
    </div>
  );
};

export default SelectCourse;
