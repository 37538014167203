import * as yup from 'yup';
import { SignupFragment as Signup } from 'generated/graphql';

// TODO: should do some planning around validation,
// this is a minimal starting point
const completedSignupSchema = yup.object({
  parent: yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
    phone: yup.string(),
  }),
});

export const toBillingDetails = (signup: Signup) => {
  const {
    parent: { firstName, lastName, email, phone },
  } = completedSignupSchema.validateSync(signup);
  const name = `${firstName} ${lastName}`;
  return { name, email, phone };
};
