import { SignupFragment as Signup, SignupProduct } from 'generated/graphql';
import { isTutoringCourseRequiresMoreInfo } from '../utils/tutoring_courses';

export const STEP_NAMES = {
  parentInfo: 'parentInfo',
  studentInfo: 'studentInfo',
  courseSelection: 'courseSelection',
  scheduling: 'scheduling',
  payment: 'payment',
  createAccount: 'createAccount',
  confirmation: 'confirmation',
} as const;

export type StepName = keyof typeof STEP_NAMES;

interface Step {
  name: StepName;
  path: string;
  label: string;
  /**
   * required fields have been filled, next step is 'unlocked'
   */
  isComplete: (signup: Signup) => boolean;
  /**
   * overrides user access to a step. used to prevent going back to pre-payment steps.
   */
  isPreventAccess: (signup: Signup) => boolean;
  determinePrevStep: (signup: Signup) => StepName | undefined;
  determineNextStep: (signup: Signup) => StepName | undefined;
}

export const STEPS: Record<StepName, Step> = {
  [STEP_NAMES.parentInfo]: {
    name: STEP_NAMES.parentInfo,
    path: 'parent-info',
    label: 'Parent Info',
    isComplete: ({ parent }: Signup) =>
      !!(parent?.email && parent.firstName && parent.lastName && parent.phone),
    isPreventAccess: ({ payment }: Signup) =>
      payment.setupIntentStatus === 'succeeded',
    determinePrevStep: () => undefined,
    determineNextStep: () => 'studentInfo',
  },
  [STEP_NAMES.studentInfo]: {
    name: STEP_NAMES.studentInfo,
    path: 'student-info',
    label: 'Student Info',
    isComplete: ({ students }: Signup) => {
      const student = students?.[0];
      return !!(
        student?.firstName &&
        student.lastName &&
        student.birthdate &&
        student.gender &&
        student.learningPreferences
      );
    },
    isPreventAccess: ({ payment }: Signup) =>
      payment.setupIntentStatus === 'succeeded',
    determinePrevStep: () => 'parentInfo',
    determineNextStep: () => 'courseSelection',
  },
  [STEP_NAMES.courseSelection]: {
    name: STEP_NAMES.courseSelection,
    path: 'course-selection',
    label: 'Course Selection',
    isComplete: ({ students }: Signup) => {
      const enrollment = students?.[0]?.enrollments?.[0];
      if (!enrollment?.productName) return false;
      switch (enrollment.productName) {
        case SignupProduct.PrivateTutoring: {
          const requiresMoreTutoringInfo = enrollment.tutoringCourse
            ? isTutoringCourseRequiresMoreInfo(enrollment.tutoringCourse)
            : false;
          return (
            !!enrollment.tutoringCourse &&
            (!requiresMoreTutoringInfo || !!enrollment.tutoringDetails)
          );
        }
        case SignupProduct.PrivateBulkCs: {
          return !!(enrollment.subjectId && enrollment.courseId);
        }
        default:
          return false;
      }
    },
    isPreventAccess: ({ payment }: Signup) =>
      payment.setupIntentStatus === 'succeeded',
    determinePrevStep: () => 'studentInfo',
    determineNextStep: () => 'scheduling',
  },
  [STEP_NAMES.scheduling]: {
    name: STEP_NAMES.scheduling,
    path: 'scheduling',
    label: 'Scheduling Preferences',
    isComplete: ({ students }: Signup) => {
      const student = students?.[0];
      const enrollment = student?.enrollments?.[0];
      const dates = enrollment?.schedulingPreferences?.[0]?.dates;
      return !!(
        student?.timezone &&
        dates?.length === 3 &&
        enrollment?.earliestStartingDate
      );
    },
    isPreventAccess: ({ payment }: Signup) =>
      payment.setupIntentStatus === 'succeeded',
    determinePrevStep: () => 'courseSelection',
    determineNextStep: () => 'payment',
  },
  [STEP_NAMES.payment]: {
    name: STEP_NAMES.payment,
    path: 'payment',
    label: 'Payment',
    isComplete: ({ payment: { setupIntentStatus } }) =>
      setupIntentStatus === 'succeeded',
    isPreventAccess: ({ payment }: Signup) =>
      payment.setupIntentStatus === 'succeeded',
    determinePrevStep: () => 'scheduling',
    determineNextStep: () => 'createAccount',
  },
  [STEP_NAMES.createAccount]: {
    name: STEP_NAMES.createAccount,
    path: 'create-account',
    label: 'Create Account',
    isComplete: ({ userId }) => !!userId,
    isPreventAccess: ({ userId }: Signup) => !!userId,
    determinePrevStep: () => 'payment',
    determineNextStep: () => 'confirmation',
  },
  [STEP_NAMES.confirmation]: {
    name: STEP_NAMES.confirmation,
    path: 'confirmation',
    label: 'Confirmation',
    isComplete: () => false,
    isPreventAccess: () => false,
    determinePrevStep: () => 'createAccount',
    determineNextStep: () => undefined,
  },
} as const;

export const STEP_PATHS = Object.fromEntries(
  Object.entries(STEPS).map(([k, v]) => [k, v.path]),
);
export type StepPath = typeof STEP_PATHS[StepName];

export const INITIAL_STEP = STEP_NAMES.parentInfo;
