import React, { FC, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

// RFD: https://www.notion.so/junilearning/Offline-Whiteboards-93dc909732f741a1add9f801051be43d#a4ba8963854f4c7cbc8a47901af54658

const UnsavedChangesPrompt: FC<{ unsavedChanges: boolean }> = ({
  unsavedChanges,
}) => {
  // We need a window alert for navigating off-site
  // https://stackoverflow.com/questions/10919790/addeventlistener-not-working-with-onbeforeunload/25763325#25763325
  useEffect(() => {
    const handler = (event: Event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault?.();
      // Chrome requires returnValue to be set.
      event.returnValue = true;
      return '';
    };

    if (unsavedChanges) {
      window.addEventListener('beforeunload', handler);
    }
    return () => window.removeEventListener('beforeunload', handler);
  }, [unsavedChanges]);

  // And then we need a Prompt for internal navigation
  return (
    <>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {/* Component JSX */}
    </>
  );
};

export default UnsavedChangesPrompt;
