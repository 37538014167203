import React from 'react';
import SchedulingSelection from './SchedulingSelection';
import { LocalState } from '../Scheduling';
import { TimeSlotSettings } from './SchedulingSelection/SchedulingTimeSelection';
import { labelTextStyles } from '../../constants';

const getLabel = (
  classIndex: number,
  numWeeklyClasses: number,
  subject?: string,
) => {
  const classXofN =
    numWeeklyClasses > 1 ? `, Class ${classIndex + 1} of ${numWeeklyClasses}` : '';
  const perWeek = `(${numWeeklyClasses}x per week)`;
  return `${subject ? `${subject}` : ''}${classXofN} ${perWeek}`;
};

interface Props {
  localState: LocalState;
  onLocalChange: (input: Partial<LocalState>) => void;
  subjectDisplayName?: string;
  customTimeSlotSettings?: TimeSlotSettings;
}

const DraggableScheduleSections = ({
  localState,
  onLocalChange,
  subjectDisplayName,
  customTimeSlotSettings,
}: Props) => (
  <div className="space-y-8">
    {[localState].map(({ numWeeklyClasses, schedulingPreference, timezone }) => {
      const dates = schedulingPreference.dates || [];
      const key = getLabel(0, numWeeklyClasses, subjectDisplayName);

      return (
        <div key={key} className="flex flex-col gap-2">
          <div className={labelTextStyles}>
            Top three weekly class time preferences
          </div>
          <SchedulingSelection
            timezone={timezone}
            onSave={list => {
              const dates = list.map(d => d.datetime).filter(d => d) as string[];
              onLocalChange({ schedulingPreference: { dates } });
            }}
            prefIndex={0}
            selections={dates.map(d => ({ datetime: d }))}
            title={`your child's weekly ${subjectDisplayName || ''} class`}
            customTimeSlotSettings={customTimeSlotSettings}
          />
        </div>
      );
    })}
  </div>
);

export default DraggableScheduleSections;
