import React from 'react';
import classNames from 'classnames';
import './ring_spinner.css';

type Props = {
  size?: number; // pixels
} & React.HTMLAttributes<HTMLDivElement>;
export default function Ring({ size = 60, className, style }: Props) {
  const circles = [...Array(4)].map((_, i) => (
    <div
      key={`ring_spinner_${['a', 'b', 'c', 'd'][i]}`}
      style={{
        borderColor: `#E0DDEF transparent transparent transparent`,
        width: size * 0.8,
        height: size * 0.8,
        margin: size * 0.1,
        borderWidth: size * 0.1,
      }}
    />
  ));

  return (
    <div className={classNames('flex items-center justify-center', className)}>
      <div className="ring-spinner" style={{ width: size, height: size, ...style }}>
        {circles}
      </div>
    </div>
  );
}
