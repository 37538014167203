import React, { useState } from 'react';
import { ErrorableLoading } from 'components/ui';
import { NewCard } from 'core-components';
import { QueryResult } from '@apollo/client';
import {
  LoadDefaultPaymentInformationByParentIdQuery,
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import PortalSection from 'components/PortalSection';
import { StripeWrapper } from 'components/StripeWrapper';
import { Parent } from 'models';
import PaymentUpdateModal from '../PaymentsSection/PaymentUpdateModal';
import { InactiveStudentCard, SubscriptionCard } from './components';

interface SubscriptionSectionProps {
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  resetForm: () => void;
  parent: Parent;
}

const SubscriptionSection = ({
  subscriptionsQuery,
  studentsQuery,
  updateFormValue,
  updateFormState,
  cardQuery,
  resetForm,
  parent,
}: SubscriptionSectionProps) => {
  const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState(false);
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];

  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];

  const activeStudentIds: string[] = subscriptions
    .filter(subscription => subscription.status !== 'canceled')
    .reduce(
      (ids: string[], subscription) =>
        ids.concat(...(subscription?.metadata?.studentIds?.split(',') || [])),
      [],
    );

  const inactiveStudents = students.filter(
    student => !activeStudentIds.includes(student._id.toString()),
  );

  const {
    loading: subscriptionsLoading,
    error: subscriptionsError,
  } = subscriptionsQuery;
  return (
    <>
      <PortalSection name="My Subscriptions">
        {subscriptionsLoading || subscriptionsError ? (
          <ErrorableLoading error={subscriptionsError} />
        ) : (
          <div className="flex flex-col gap-4">
            <NewCard padding="0">
              <div className="text-xl font-semibold px-6 py-4">
                Active Subscriptions
              </div>
              <NewCard.Divider />
              <div className="p-6 flex flex-col gap-4">
                {subscriptions
                  .filter(subscription => subscription.status !== 'canceled')
                  .map(subscription => (
                    <SubscriptionCard
                      key={subscription.id}
                      subscription={subscription}
                      studentsQuery={studentsQuery}
                      updateFormValue={updateFormValue}
                      updateFormState={updateFormState}
                      resetForm={resetForm}
                    />
                  ))}
              </div>
            </NewCard>

            <NewCard padding="0">
              <div className="px-6 py-4 gap-1">
                <div className="text-xl font-semibold">Inactive Subscriptions</div>
                <div className="text-j-dark-300 text-s italic">
                  Re-enroll a past student or enroll an existing sibling to Juni!
                </div>
              </div>
              <NewCard.Divider />
              <div className="p-6 flex flex-col gap-4">
                {inactiveStudents.map(student => (
                  <InactiveStudentCard
                    key={student._id}
                    student={student}
                    updateFormValue={updateFormValue}
                    updateFormState={updateFormState}
                    resetForm={resetForm}
                    cardQuery={cardQuery}
                    studentsQuery={studentsQuery}
                    setIsEditPaymentModalOpen={setIsEditPaymentModalOpen}
                  />
                ))}
              </div>
            </NewCard>
          </div>
        )}
      </PortalSection>
      {isEditPaymentModalOpen && (
        <StripeWrapper>
          <PaymentUpdateModal
            cardQuery={cardQuery}
            parent={parent}
            isEditPaymentModalOpen={isEditPaymentModalOpen}
            setIsEditPaymentModalOpen={setIsEditPaymentModalOpen}
          />
        </StripeWrapper>
      )}
    </>
  );
};

export default SubscriptionSection;
