export const TUTORING_SUBJECT_LOGGING_OPTIONS = [
  { value: 'cs', label: 'Computer Science' },
  { value: 'math', label: 'Mathematics' },
  { value: 'english', label: 'English' },
  { value: 'biology', label: 'Biology' },
  { value: 'chemistry', label: 'Chemistry' },
  { value: 'physics', label: 'Physics' },
  { value: 'history', label: 'History' },
  { value: 'art', label: 'Art' },
  { value: 'other', label: 'Other' },
];

export const tutoringSubjectValueToLabel = (value: string) => {
  const matchingOption = TUTORING_SUBJECT_LOGGING_OPTIONS.find(
    option => value === option.value,
  );
  return matchingOption ? matchingOption.label : 'Unknown';
};
