import PortalSection from 'components/PortalSection';
import {
  useGetCommunityEventsByStartDatesLazyQuery,
  Student,
} from 'generated/graphql';
import { NavLink } from 'react-router-dom';
import { getMyClubsLink } from 'app/clubs/helpers';

import ClubsBanner from 'components/clubs/MyClubsZeroState/assets/guest-header.jpg';
import SpaceShuttle from 'components/clubs/MyClubsZeroState/assets/space-shuttle.png';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { add, format } from 'date-fns';
import RingSpinner from 'components/RingSpinner';

import { MediaCard } from 'core-components';
import { DEFAULT_TIMEZONE } from 'constants/timezones';

const NUM_DAYS_OF_EVENTS_TO_SHOW = 14;

const Announcements = ({ student }: { student: Student }) => {
  // Leaving this commented out as a TODO for generic announcements once admin UI is done

  // const {
  //   data: announcementsData,
  //   loading: announcementsLoading,
  // } = useGetAnnouncementsQuery();
  // const announcements = announcementsData?.queryAnnouncements?.items || [];

  const timezone = moment.tz.guess() || DEFAULT_TIMEZONE; // TODO: replace moment

  const [
    getEvents,
    { data: eventsData, loading: eventsLoading },
  ] = useGetCommunityEventsByStartDatesLazyQuery();

  const events =
    eventsData?.getCommunityEventsByStartDates?.items.filter(
      event => !event.archivedAt,
    ) || [];

  useEffect(() => {
    const startDates = new Array(NUM_DAYS_OF_EVENTS_TO_SHOW)
      .fill('')
      .map((_, index) =>
        format(add(new Date(), { days: index }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      );
    getEvents({
      variables: {
        startDates,
        timezone,
      },
    });
  }, [getEvents, timezone]);

  const challengesUrl = `/learner/${student._id}/challenges_home`;
  const clubsCalendarUrl =
    `${getMyClubsLink({ studentId: student._id })}/calendar` || '';
  return (
    <PortalSection name="Announcements">
      <div className="mb-5">
        <NavLink className="no-underline" to={challengesUrl}>
          <MediaCard>
            <MediaCard.Image src={SpaceShuttle} height="1/4" alt="People together" />
            <MediaCard.Content>
              <div className="text-j-dark-600 font-graphik font-medium text-sm leading-relaxed">
                Explore Open Challenges
              </div>
              <div className="text-j-dark-400 font-graphik text-sm leading-normal mt-2">
                Check out ongoing competitions and past winners!
              </div>
            </MediaCard.Content>
          </MediaCard>
        </NavLink>
      </div>

      <div>
        {eventsLoading ? (
          <RingSpinner />
        ) : events.length > 0 ? (
          <NavLink className="no-underline" to={clubsCalendarUrl}>
            <MediaCard>
              <MediaCard.Image
                src={ClubsBanner}
                height="1/4"
                alt="People together"
              />
              <MediaCard.Content>
                <div className="text-j-dark-600 font-graphik font-medium text-sm leading-relaxed">
                  Attend a Club Event
                </div>
                <div className="text-j-dark-400 font-graphik text-sm leading-normal mt-2">
                  {`There are ${events.length} upcoming events!`}
                </div>
              </MediaCard.Content>
            </MediaCard>
          </NavLink>
        ) : null}
      </div>
    </PortalSection>
  );
};

export default Announcements;
