import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ScreenSizes, useWindowSize, WindowSize } from 'hooks/useWindowSize';
import { Icon, NewButton, NewModalWindow } from 'core-components';
import { timezoneToDisplayName } from 'constants/timezones';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { ordinal } from 'utils/numberToOrdinal';
import { DateTime, SchedulingSelectionsProps } from './types';
import SchedulingOption from './SchedulingOption';
import SchedulingTimeSelection from './SchedulingTimeSelection';

const SchedulingSelection: FC<SchedulingSelectionsProps> = ({
  selections,
  title,
  timeZone = guessTimezoneValue(),
  onSave,
  numSelectionsRequired = 3,
}) => {
  const windowSize: WindowSize = useWindowSize();
  const [prefList, setPrefList] = useState<DateTime[]>([]);
  const [tempPrefList, setTempPrefList] = useState<DateTime[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const isEmpty = !selections.find(selection => selection.datetime);
  const optionTitles = Array.from({ length: prefList.length }, (_, i) => i + 1).map(
    position => `${ordinal(position)} option`,
  );
  const containerStyles = classNames('rounded-lg px-4', {
    'border border-solid border-j-purple-200': isEmpty,
    'pt-4 pb-3 bg-j-gray-200': !isEmpty,
  });

  useEffect(() => {
    setPrefList(selections);
  }, [selections]);

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;
    if (destination.index === source.index) return;

    const newList = prefList.filter((_: any, idx: number) => idx !== source.index);

    newList.splice(destination.index, 0, prefList[source.index]);

    setPrefList(newList);
    onSave(newList);
  };

  const draggingDirection =
    windowSize.size === ScreenSizes.desktop && prefList.length === 3
      ? 'horizontal'
      : 'vertical';

  const setScheduleModal = () => {
    setModalOpen(false);
    if (tempPrefList) {
      setTempPrefList([]);
      setPrefList(tempPrefList);
      onSave(tempPrefList);
    }
  };

  return (
    <div className={containerStyles}>
      {isEmpty ? (
        <p className="text-base text-j-dark-200 leading-11 m-0 py-2 flex justify-between items-center">
          <span>Add schedule options</span>
          <button
            className="w-5 h-5 rounded-full border border-solid border-j-dark-400 text-j-dark-400 bg-white p-0 m-0 flex justify-center items-center"
            onClick={() => setModalOpen(true)}
            data-cy="open-schedule-modal"
          >
            <Icon.Plus className="w-3 h-3" />
          </button>
        </p>
      ) : (
        <div className="relative">
          <header
            className={`flex flex-col space-y-14 absolute top-0 left-0 w-full z-0 ${
              prefList.length === 3 &&
              `lg:flex-row lg:space-x-3 lg:space-y-0 lg:static`
            }`}
          >
            {optionTitles.map(title => (
              <h3
                className={`text-sm text-j-dark-600 leading-3 w-1/3 m-0 font-normal pb-3 whitespace-nowrap ${
                  prefList.length === 3 && `lg:pb-1`
                }`}
                key={title}
              >
                {title}
              </h3>
            ))}
          </header>
          <div
            className={`pt-5 relative z-10 ${prefList.length === 3 && `lg:pt-0`}`}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={title} direction={draggingDirection}>
                {provided => (
                  <div
                    className={`flex flex-col space-y-10 ${
                      prefList.length === 3 &&
                      `lg:flex-row lg:space-x-3 lg:space-y-0`
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {prefList.map((preference, index) => (
                      <SchedulingOption
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${preference.datetime}-${index}`}
                        date={preference.datetime}
                        timezone={timeZone}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div
            className={`flex justify-between items-center pt-4 ${
              prefList.length === 3 && `lg:flex-row`
            }`}
          >
            <label className="text-j-dark-400 text-sm">
              {/* TODO: Add checkbox and logic to apply these selections to all options. */}
              {/* Use these choices for all courses */}
            </label>
            <NewButton
              variant="minimal"
              className="text-j-blue-400"
              onClick={() => setModalOpen(true)}
            >
              Reselect
            </NewButton>
          </div>
        </div>
      )}
      <NewModalWindow
        title="Schedule Options"
        description={timezoneToDisplayName(timeZone)}
        closeModal={() => {
          setModalOpen(false);
          setTempPrefList([]);
        }}
        isOpen={modalOpen}
        showCloseIcon
        renderFooter={() => (
          <div className="text-right w-full">
            <NewButton
              onClick={setScheduleModal}
              disabled={
                tempPrefList.filter(date => date.datetime).length !==
                numSelectionsRequired
              }
            >
              Continue
            </NewButton>
          </div>
        )}
      >
        <SchedulingTimeSelection
          title={title}
          timezone={timeZone}
          selectedDates={selections}
          onChangeSelections={newSelections => {
            if (newSelections) {
              setTempPrefList(newSelections);
            }
          }}
          numSelectionsRequired={numSelectionsRequired}
        />
      </NewModalWindow>
    </div>
  );
};

export default SchedulingSelection;
