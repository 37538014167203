import React, { FC, useEffect, useState } from 'react';
import { sampleSize } from 'lodash';
import { mongoIDToDateStr } from 'utils/timeUtils';
import { StudentProject } from 'generated/graphql';
import ProjectCard from '../ProjectCard/ProjectCard';

interface Props {
  data: StudentProject[];
}
const NUM_TRENDING_PROJ_TO_DISPLAY = 6;

const TrendingProjectsList: FC<Props> = ({ data }) => {
  const [trendingProjects, setTrendingProjectsToDisplay] = useState<
    StudentProject[]
  >([]);

  useEffect(() => {
    const projectsList = sampleSize(data, NUM_TRENDING_PROJ_TO_DISPLAY)
      .map(project => ({
        ...project,
        date: mongoIDToDateStr(project._id),
      }))
      .sort((p1, p2) => new Date(p2?.date).getTime() - new Date(p1?.date).getTime());

    setTrendingProjectsToDisplay(projectsList as StudentProject[]);
  }, [data]);

  return (
    <section className="max-w-screen-xl w-full flex flex-col gap-6 md:gap-10">
      <div className="text-j-dark-600 text-3xl md:text-5xl font-semibold">
        Trending Projects
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 md:gap-6">
        {trendingProjects.map(project => (
          <div key={project._id}>
            <ProjectCard data={project} size="medium" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrendingProjectsList;
