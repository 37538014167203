import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ReactNode } from 'react';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || '';

/**
 * @param clientSecret required when using a PaymentElement
 */
export const StripeWrapper = ({
  clientSecret,
  children,
}: {
  clientSecret?: string;
  children?: ReactNode | ReactNode[];
}) =>
  window.Stripe ? (
    <Elements stripe={loadStripe(STRIPE_API_KEY)} options={{ clientSecret }}>
      {children}
    </Elements>
  ) : null;
