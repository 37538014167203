interface MarkOndemandSectionAsCompleteParams {
  studentId: string;
  sectionId: string;
  moduleId: string;
  courseCurriculumId: string;
  idLookup: { [key: string]: any };
}

export const checkIfSectionCanBeMarkedAsComplete = ({
  studentId,
  moduleId,
  sectionId,
  courseCurriculumId,
  idLookup,
}: MarkOndemandSectionAsCompleteParams) => {
  const studentInfo = idLookup[studentId];
  const completed = studentInfo?.completed || {};
  const isComplete = completed[sectionId];

  const sectionInfo = idLookup[courseCurriculumId]?.children
    ?.find((module: any) => module.id === moduleId)
    ?.children?.find((section: any) => section.id === sectionId);

  const isCustomProject = sectionInfo?.properties?.isCustomProject;

  return !isComplete && !isCustomProject && courseCurriculumId !== 'playground';
};
