import React, { useState } from 'react';
import { QueryResult } from '@apollo/client';
import _ from 'lodash';

import {
  LoadPaymentHistoryByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  useGetCoursesQuery,
  useLoadAvailabilitiesByStudentIdsQuery,
  useLoadDefaultPaymentInformationByParentIdQuery,
  useLoadPaymentHistoryByParentIdQuery,
  useLoadProductsByStripeIdsQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
  useLoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import { Parent } from 'models';

import { NewCard, NewButton, Icon } from 'core-components';
import { StripeWrapper } from 'components/StripeWrapper';
import { FormStateModal } from './SubscriptionSection/modals';
import SubscriptionsCard from './SubscriptionSection';
import PaymentSection from './PaymentsSection';
import AccountInformationSection from './AccountInformationSection';
import { FormState, FormValues } from './SubscriptionSection/modals/types';
import { UPDATE_FLOWS } from '../LearnerAccount';
import PaymentUpdateModal from './PaymentsSection/PaymentUpdateModal';

interface AccountDetailsSectionProps {
  parent: Parent;
  isGuest: boolean;
}

// we should break this up into different parts for upgrade and downgrade, so we can type it better
const DEFAULT_FORM_VALUES: FormValues = {
  // general fields
  subscription: undefined,
  isAsyncAdded: false,
  // upgrade fields
  currentStudent: undefined,
  couponId: undefined,
  // downgrade fields
  finalSession: undefined,
  cancellationReasons: [],
  otherNotes: undefined,
  additionalNotes: undefined,
  selectedCoursesToRemove: undefined,
  classFrequenciesToReduce: undefined,
  followupDate: undefined,
  effectiveDate: undefined,
  metadataNew: undefined,
};

const DEFAULT_FORM_STATE: FormState = {
  modal: '',
  updateFlow: '',
  downgradeOption: '',
};

// tempory move to depericate
const DEFAULT_STUDENT_VALUES = {
  _id: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  gender: '',
  email: '',
  age: '',
  track: '',
  notes: '',
  parentId: '',
  completedTracks: [],
  hasMultipleTracks: false,
  receivesSessionNotes: false,
  tracks: [],
  isJuniExplorer: false,
  username: '',
};

const AccountDetailsSection = ({ parent, isGuest }: AccountDetailsSectionProps) => {
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState(false);

  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: parent._id },
    skip: isGuest,
  });
  const card = cardQuery?.data?.defaultPaymentInformationByParentId || {};

  const subscriptionsQuery = useLoadStripeSubscriptionsByParentIdQuery({
    variables: { id: parent._id },
    skip: isGuest,
  });

  const paymentsQuery: QueryResult<LoadPaymentHistoryByParentIdQuery> = useLoadPaymentHistoryByParentIdQuery(
    {
      variables: { id: parent._id },
      skip: isGuest,
    },
  );

  const studentsQuery: QueryResult<LoadStudentsByParentIdsQuery> = useLoadStudentsByParentIdsQuery(
    {
      variables: { ids: [parent._id.toString()] },
    },
  );

  const updateFormState = (newValue: any, field: string) => {
    setFormState(values => ({ ...values, [field]: newValue }));
  };

  const updateFormValue = (newValue: any, field: string) => {
    setFormValues(values => ({ ...values, [field]: newValue }));
  };

  const resetForm = () => {
    setFormState(DEFAULT_FORM_STATE);
    setFormValues(DEFAULT_FORM_VALUES);
  };

  const coursesQuery = useGetCoursesQuery({
    skip: isGuest,
  });
  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];
  const availabilityQuery = useLoadAvailabilitiesByStudentIdsQuery({
    variables: {
      ids:
        studentsQuery.data?.studentsByParentIds?.items.map(studentObj =>
          studentObj._id.toString(),
        ) || [],
    },
    skip:
      !studentsQuery.data ||
      !studentsQuery.data?.studentsByParentIds?.items.length ||
      isGuest,
  });

  const productIds = subscriptions.reduce(
    (productIds, subscription) =>
      productIds.concat(
        subscription.items.data.map(
          subscriptionItem => subscriptionItem.plan.product || '',
        ),
      ),
    [] as string[],
  );
  const productsQuery = useLoadProductsByStripeIdsQuery({
    variables: { ids: _.uniq(productIds) },
    skip: subscriptions.length === 0,
  });
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="flex flex-col w-full lg:w-1/2">
          <AccountInformationSection isGuest={isGuest} parent={parent} />
          {!isGuest && (
            <PaymentSection
              cardQuery={cardQuery}
              paymentsQuery={paymentsQuery}
              parent={parent}
            />
          )}
        </div>
        <div className="w-full lg:w-1/2">
          <NewCard padding="0" className="bg-j-dark-600 mb-6">
            <div className="p-6 flex flex-row justify-between items-center">
              <div className="flex flex-col gap-2 text-white">
                <div className="font-semibold">Enroll another child or sibling</div>
                <div className="text-j-dark-200 mr-4">
                  Add another child or sibling to your Juni Account for private 1:1,
                  tutoring, and on-demand courses!
                </div>
              </div>
              <NewButton
                className="px-3 py-2"
                renderIconLeft={props => <Icon.Plus {...props} />}
                size="xsmall"
                onClick={() => {
                  if (_.isEmpty(card)) {
                    return setIsEditPaymentModalOpen(true);
                  }
                  resetForm();
                  const updateFlow = UPDATE_FLOWS.NEW_STUDENT;

                  updateFormState('student_create', 'modal');
                  updateFormState(updateFlow, 'updateFlow');
                  updateFormValue(DEFAULT_STUDENT_VALUES, 'currentStudent');
                }}
              >
                Enroll Now
              </NewButton>
            </div>
          </NewCard>
          {!isGuest && (
            <SubscriptionsCard
              subscriptionsQuery={subscriptionsQuery}
              studentsQuery={studentsQuery}
              cardQuery={cardQuery}
              updateFormValue={updateFormValue}
              updateFormState={updateFormState}
              parent={parent}
              resetForm={resetForm}
            />
          )}
        </div>
      </div>
      <FormStateModal
        cardQuery={cardQuery}
        subscriptionsQuery={subscriptionsQuery}
        paymentsQuery={paymentsQuery}
        productsQuery={productsQuery}
        availabilityQuery={availabilityQuery}
        studentsQuery={studentsQuery}
        coursesQuery={coursesQuery}
        parent={parent}
        formState={formState}
        formValues={formValues}
        updateFormState={updateFormState}
        updateFormValue={updateFormValue}
        resetForm={resetForm}
      />
      {isEditPaymentModalOpen && (
        <StripeWrapper>
          <PaymentUpdateModal
            cardQuery={cardQuery}
            parent={parent}
            isEditPaymentModalOpen={isEditPaymentModalOpen}
            setIsEditPaymentModalOpen={setIsEditPaymentModalOpen}
          />
        </StripeWrapper>
      )}
    </>
  );
};

export default AccountDetailsSection;
