import React, { FC, useEffect, useState } from 'react';
import { sample } from 'lodash';
import { StudentProject } from 'generated/graphql';
import headerBG from '../../images/circle-op-20.svg';
import ProjectCard from '../ProjectCard/ProjectCard';

interface Props {
  data: {
    title: string;
    description: string;
  };
  projects: StudentProject[] | undefined;
}

const HeroHeader: FC<Props> = ({ data, projects }) => {
  const [heroProjectToDisplay, setHeroProjectToDisplay] = useState<
    StudentProject | undefined
  >(undefined);

  useEffect(() => {
    setHeroProjectToDisplay(sample(projects));
  }, [projects]);

  console.log(projects);
  return (
    <div
      className="bg-j-purple-600 bg-no-repeat flex justify-center"
      style={{
        backgroundImage: `url(${headerBG})`,
        backgroundPosition: '-200px 120px',
        backgroundSize: '273px',
      }}
    >
      <header className="max-w-screen-xl w-full pt-16 h-96 pr-12 lg:px-18 2xl:px-0">
        <div className="w-full px-6 xl:px-0 py-0 flex h-full flex-col items-center lg:flex-row lg:justify-between gap-x-4 gap-y-8">
          <div className="w-full lg:h-full max-w-2xl flex flex-col justify-center items-center">
            <div
              title="Title"
              className="whitespace-pre-line font-bold text-center lg:text-left text-4xl md:text-5xl lg:text-6xl md:leading-normal text-white pb-6 w-full"
            >
              {data?.title}
            </div>
            <div title="Description" className="text-white w-full">
              {data?.description}
            </div>
          </div>
          <div className="w-full max-w-xl xl:max-w-2xl relative">
            {heroProjectToDisplay && (
              <div
                title="Featured project"
                className="absolute top-0 lg:-top-40 left-0 w-full z-1"
              >
                <ProjectCard data={heroProjectToDisplay} size="large" />
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeroHeader;
