import React, { FC } from 'react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { format } from 'date-fns';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { ordinal } from 'utils/numberToOrdinal';

interface SchedulingSummaryProps {
  index: number;
  datetime?: string;
  timezone?: string;
}

const SchedulingSummary: FC<SchedulingSummaryProps> = ({
  datetime,
  index,
  timezone,
}) => {
  const date = datetime
    ? utcToZonedTime(
        zonedTimeToUtc(new Date(datetime), guessTimezoneValue()),
        timezone ?? guessTimezoneValue(),
      )
    : undefined;

  return (
    <p className="m-0 text-j-dark-400 text-sm">
      <span className="w-32 inline-block">{ordinal(index + 1)} option:</span>
      {date ? (
        <span className="text-j-dark-600">
          {format(date, `eee, LLL d 'at' haaa`)}
        </span>
      ) : (
        <span className="text-j-dark-600">--</span>
      )}
    </p>
  );
};

export default SchedulingSummary;
