import styled from 'styled-components/macro';
import { XIcon } from 'components/Icons';

const X_ICON_SIZE = '12px';
const X_ICON_PADDING = '8px';

const CloseButton = styled.button.attrs({
  className: 'text-j-dark-600 stroke-current',
})`
  background: none;
  display: block;
  margin-left: auto;
  margin-bottom: auto;
  padding: ${X_ICON_PADDING};
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);

  path {
    stroke: rgba(41, 37, 99, 1);
  }
  :hover {
    background-color: #d33838;
    box-shadow: none;
    path {
      stroke: #ffebeb;
    }
  }
`;

const CloseButtonIcon = styled(XIcon)`
  height: ${X_ICON_SIZE};
  width: ${X_ICON_SIZE};
  display: block;
  padding: 0;
`;

export { CloseButton, CloseButtonIcon };
