import React, { FC } from 'react';
import classNames from 'classnames';
import { StudentProject } from 'generated/graphql';
import { mongoIDToDateStr } from 'utils/timeUtils';
import { parseISO, formatDistanceToNowStrict } from 'date-fns';
import { Sizes } from 'core-components/NewButton/types';
import { NavLink } from 'react-router-dom';
import { NewCard, Icon } from 'core-components';
import { projectTypeToRenderInfo } from 'constants/project_types';
import _ from 'lodash';

interface Props {
  data: StudentProject;
  size?: Sizes;
}

const ProjectCard: FC<Props> = ({ data, size = 'medium' }) => {
  const { thumbnailUrl, title, description, projectType, viewCount, _id } = data;
  // project Icon
  const projectTypeKey = projectType?.includes('TURTLE')
    ? 'pythonTurtle'
    : _.lowerCase(projectType || 'unknown').replace(/ /g, '_');

  const { icon, iconBgColor } = projectTypeToRenderInfo(projectTypeKey);

  const createdAt = mongoIDToDateStr(_id);
  const timeAgo = createdAt
    ? `${formatDistanceToNowStrict(parseISO(createdAt))} ago`
    : '';
  const titleStyles = classNames('text-j-dark-600 line-clamp-1 whitespace-normal', {
    'text-xl leading-8': size === 'large',
    'text-base leading-6': size === 'medium',
  });

  const userName = data?.creator?.username?.toUpperCase();
  const showIcon = size !== 'large' && projectType;

  return (
    <NavLink
      to={`/juniverse/projects/${String(_id)}`}
      target="_blank"
      className="no-underline w-full"
    >
      <NewCard
        shadow="none"
        padding={size === 'large' ? '6' : '5'}
        className="font-graphik text-sm"
      >
        <header className="flex w-full">
          {size !== 'large' && projectType && (
            <div
              className={`w-12 h-12 flex justify-center items-center rounded-lg text-white bg-${
                iconBgColor || 'j-purple-600'
              }`}
            >
              {React.createElement(icon, { className: 'w-8 h-8' })}
            </div>
          )}
          <div
            className={`flex justify-between flex-wrap items-center flex-grow ${
              showIcon && 'pl-3'
            }`}
          >
            <div className={titleStyles} style={{ maxWidth: 'calc(100% - 95px)' }}>
              {title}
            </div>
            <span className="text-sm text-j-dark-300 whitespace-no-wrap">
              {timeAgo}
            </span>
            {size !== 'large' && userName && (
              <div className="w-full text-j-dark-300">@{userName}</div>
            )}
          </div>
        </header>

        {size === 'large' && (
          <div className="w-full text-base leading-6 text-j-dark-600 mt-4 mb-6 line-clamp-3">
            {description}
          </div>
        )}

        {!(size === 'small') && (
          <div
            className={`bg-j-dark-100 rounded-lg overflow-hidden relative w-full mb-5 ${
              size === 'large' ? 'max-w-2xl lg:max-h-80 max-h-72' : 'h-48 mt-5'
            }`}
          >
            {thumbnailUrl && (
              <img src={thumbnailUrl} className="w-full" alt={title ?? ''} />
            )}
          </div>
        )}

        <div
          className={`text-j-dark-300 text-xs flex flex-row justify-start items-center gap-2 ${
            size === 'small' && 'pt-4'
          }`}
          title="View Count"
        >
          <Icon.Eye className="w-5 h-5" />
          <div>{viewCount?.toLocaleString() || 0}</div>
        </div>
      </NewCard>
    </NavLink>
  );
};

export default ProjectCard;
