import React, { FC, FormEvent, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import {
  SignupSessionProps,
  SubjectName,
  SignupData,
} from 'app/signup_session/types';
import { COMPUTER_SCIENCE, MATH } from 'constants/signup_sessions';
import { gtmDataLayerPush, GtmAnalyticsEvent } from 'gtm';

import { Card } from 'core-components';

import makeCheckoutService from 'services/signupSessions/checkoutService';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';

import { ValidationError } from 'app/signup_session/components/InputField';

import CheckoutContext from 'app/signup_session/hooks/useCheckoutContext/CheckoutContext';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import PAYMENT_ERROR_COPY from 'constants/stripe_error_copy';

import derivePlanIdFromBundleData from 'app/signup_session/lib/derivePlanIdFromBundleData';

import guessTimezoneValue from 'utils/guessTimezoneValue';

import updateBundleWithOnboarding from 'app/signup_session/lib/updateBundleWithOnboarding';

import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import navStates from 'app/signup_session/navigation/states';
import { NAV_VERSIONS, ORDINALS } from 'app/signup_session/navigation/constants';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import { getCourseFormatFromBundleName } from 'app/signup_session/lib/getCourseFormatFromBundleName';
import {
  makePriceBeforeCoupon,
  studentIsReadyForOnboarding,
} from 'app/signup_session/lib';
import * as R from 'ramda';
import { differenceInYears } from 'date-fns';
import PaymentForm from './PaymentForm';

const defaultErrorMessage =
  'An error occurred while processing your card. Try again in a little bit.';

const selectCourseByAge = (
  selectedSubject: SubjectName,
  selectedCourse: string,
  studentAge: number,
): string => {
  let newCourse = selectedCourse;
  if (selectedSubject === COMPUTER_SCIENCE) {
    newCourse = studentAge < 9 ? 'scratch_level_1' : 'python_level_1_v2';
  }

  if (selectedSubject === MATH) {
    // selected Elementary School
    if (
      selectedCourse === 'math_foundations_a' ||
      selectedCourse === 'math_applications_a'
    ) {
      newCourse = studentAge < 9 ? 'math_foundations_a' : 'math_applications_a';
    }
    // selected Middle School
    if (selectedCourse === 'pre_algebra_a_v2' || selectedCourse === 'algebra_1_a') {
      newCourse = studentAge < 13 ? 'pre_algebra_a_v2' : 'algebra_1_a';
    }
    // selected High School
    if (selectedCourse === 'geometry_a' || selectedCourse === 'algebra_2_a') {
      newCourse = studentAge < 16 ? 'geometry_a' : 'algebra_2_a';
    }
  }
  return newCourse;
};

const Checkout: FC<SignupSessionProps> = ({ history, location }) => {
  const { signupData, setSignupSession, flags } = useSignupContext();
  const activeStudent = signupData.students?.[0];
  const bundleSelections = getStudentBundleSelections(activeStudent?.bundle);
  const { getNextPage, hasNextPage, routerType } = useNavRouter();

  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);

  const [discountIsValid, setDiscountIsValid] = useState(true);
  const [referralCodeValid, setReferralCodeValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [emailExists, setEmailExists] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    {
      field: string;
      message: string;
    }[]
  >([]);
  const [stripeError, setStripeError] = useState('');

  const resetErrors = () => {
    setStripeError('');
    setErrorMessage('');
    setEmailExists(false);
    setValidationErrors([]);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (hasNextPage(navStates.signup.checkout, ROUTE_EVENT.LOAD, { signupData })) {
    return (
      <Redirect
        to={getNextPage(navStates.signup.checkout, ROUTE_EVENT.LOAD, { signupData })}
      />
    );
  }

  const sendConfirmationEmail = async (
    last4?: string,
  ): Promise<Partial<SignupData>> => {
    try {
      const res = await makeCheckoutService().sendConfirmationEmail(
        signupData._id!,
        last4,
      );
      return {
        confirmationSent: res?.data?.result === 'ok',
      };
    } catch (err) {
      return {};
    }
  };

  const calculateChargedPrice = () => {
    const bootcampPrice = bundleSelections[0]?.price
      ? bundleSelections[0].price / 100
      : undefined;
    const subscriptionPrice = activeStudent?.bundle
      ? makePriceBeforeCoupon(activeStudent.bundle.selections)
      : undefined;
    return bootcampPrice ?? subscriptionPrice;
  };

  const handleSubmit = async (e: FormEvent) => {
    if (paymentIsProcessing) return;
    setPaymentIsProcessing(true);
    e.preventDefault();

    await setSignupSession?.({
      students: (signupData.students ?? []).map(student => {
        // update selected course by age for Math and CS
        const studentAge = student.birthdate
          ? differenceInYears(new Date(), new Date(student.birthdate))
          : undefined;

        const selectedSubject = Object.keys(
          student.bundle?.selections || {},
        )[0] as SubjectName;

        const selectedCourse =
          student.bundle?.selections[selectedSubject]?.courseName;
        const newCourse =
          // when we are coming from course explorer the user has already specified a course
          selectedCourse &&
          signupData?.coursePlacement?.method !== 'course-explorer' &&
          studentAge
            ? selectCourseByAge(selectedSubject, selectedCourse, studentAge)
            : selectedCourse;

        return {
          ...student,
          timezone: student.timezone ?? guessTimezoneValue(),
          bundle: {
            ...student.bundle!,
            selections: {
              ...(student.bundle?.selections || {}),
              [selectedSubject]: {
                ...(student.bundle?.selections?.[selectedSubject] || {}),
                courseName: newCourse,
              },
            },
            stripePlanId: derivePlanIdFromBundleData(student.bundle!),
          },
        };
      }),
    });

    if (!stripe || !elements || !signupData) {
      setPaymentIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setPaymentIsProcessing(false);
      return;
    }

    // TO REFACTOR: createPaymentMethod should NOT be used,
    // instead we should use payment intent or setup intent.
    // However, due to legacy reasons switching from createToken(), a legacy function.
    // We are updating to payment Method to better support apple and google pay.
    // To refactor to use proper payment and setup intent instead.
    const {
      paymentMethod,
      error: paymentMethodError,
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    // set local stripe error, if it exists
    setStripeError(paymentMethodError?.message ?? '');
    // if the response doesn't have a complete token, return out
    if (!paymentMethod) {
      setPaymentIsProcessing(false);
      return;
    }

    setPaymentIsProcessing(true);

    try {
      const checkoutRes = await makeCheckoutService().handleCheckout(
        {
          signupSessionId: signupData._id!,
          paymentMethodId: paymentMethod.id,
        },
        flags.isBootcampSignup,
      );

      if (checkoutRes.data.validationErrors) {
        setValidationErrors(checkoutRes.data.validationErrors);
        return;
      }

      if (!checkoutRes?.data?.success) {
        setPaymentIsProcessing(false);
        setErrorMessage(defaultErrorMessage);
        return;
      }

      if (checkoutRes.data.success === true && checkoutRes.data.data) {
        resetErrors();
        const paymentMethod = checkoutRes.data?.data?.paymentMethod;
        // update session with response from payment handler
        const setSignupRes = await setSignupSession({
          mongoParentId: checkoutRes.data?.data?.parent._id,
          submittedPayment: true,
          stripeData: {
            stripeCustomerId: paymentMethod?.customer,
            last4: paymentMethod?.card?.last4,
          },
          invitationCode: checkoutRes.data?.data?.inviteCode,
          students:
            signupData.students
              ?.map(student => ({
                ...student,
                mongoStudentId: checkoutRes.data.data?.students.find(
                  (_student: any) =>
                    _student.firstName?.trim() === student.firstName?.trim() &&
                    _student.lastName?.trim() === student.lastName?.trim(),
                )?._id,
              }))
              .map(student => ({
                ...student,
                bundle: updateBundleWithOnboarding(
                  student.bundle!,
                  checkoutRes.data.data?.onboardingTickets ?? [],
                ),
              })) ?? signupData.students,
        });

        if (flags.isBootcampSignup || flags.isOnDemandSignup) {
          const sendConfirmationRes = await sendConfirmationEmail();
          setSignupSession({
            ...setSignupRes?.data?.data, // the signupData in state won't be updated from the above call yet, so we use the data retured by the API as its up to date
            ...sendConfirmationRes,
            completedEnrollment: true,
          });
        }

        // we've already collected all onboarding data also so send confirmation
        // and update onboarding tickts now
        if (flags.collectScheduleEarly) {
          const checkoutService = makeCheckoutService();

          const updatedActiveStudent = setSignupRes?.data?.data.students?.[0];

          try {
            if (updatedActiveStudent?.mongoStudentId) {
              const payload = {
                birthDate: updatedActiveStudent.birthdate?.toString(),
                notes: updatedActiveStudent?.learningStyle
                  ? `[LEARNING STYLE - POPULATED FROM DCF] ${updatedActiveStudent.learningStyle}`
                  : undefined,
                gender: updatedActiveStudent.gender,
              };
              await checkoutService.updateStudent(
                updatedActiveStudent.mongoStudentId,
                payload,
              );
            }

            const confirmationSentResult = await sendConfirmationEmail();

            if (
              updatedActiveStudent &&
              studentIsReadyForOnboarding(updatedActiveStudent) &&
              signupData._id
            ) {
              await checkoutService.syncSignupToOnboarding(
                signupData._id,
                updatedActiveStudent,
                signupData.coursePlacement?.method === 'course-explorer',
              );
            }

            if (
              signupData.students &&
              studentIsReadyForOnboarding(updatedActiveStudent)
            ) {
              setSignupSession({
                ...setSignupRes?.data?.data,
                ...confirmationSentResult,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - typescript error occur due to partial data
                students: setSignupRes?.data?.data.students.map(studentData => ({
                  ...studentData,
                  bundle: {
                    ...studentData.bundle!,
                    selections: R.map(
                      R.assoc('onboardingTicketStatus', 'ready_to_onboard'),
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore - typescript error occurs due to partial data
                    )(studentData?.bundle?.selections ?? {}),
                  },
                })),
              });
            } else {
              setSignupSession({
                ...setSignupRes?.data?.data,
                ...confirmationSentResult,
              });
            }
            // BEGIN: Track 'sign_up' event to GTM
            try {
              // get subject for signup GTM analytics event
              const student = signupData.students
                ? signupData.students[0] || {}
                : {};
              const subjects = Object.keys(student.bundle?.selections || {});
              const subject =
                subjects.length > 0 ? subjects[0] : 'Unspecified Subject';

              // get selected coupon for signup GTM analytics event
              const selectedDiscount = signupData.discountCodes?.find(
                code => code.isSelected,
              );

              // get course name for signup GTM analytics event
              let courseName = '';
              if (subject && subject !== 'Unspecified Subject') {
                const selections = getStudentBundleSelections(student.bundle);
                const selection = selections[0] || {};
                courseName = selection?.courseName || '';
              }
              const coursePlacement = signupData.coursePlacement?.method;
              const signupSessionId = signupData._id;
              gtmDataLayerPush({ ecommerce: null });
              gtmDataLayerPush({
                event: GtmAnalyticsEvent.SignUp,
                email: signupData.email,
                origin: `DCF${
                  coursePlacement === 'course-explorer'
                    ? ' (from course detail page)'
                    : ''
                }`,
                signupSessionId,
                ecommerce: {
                  currency: 'USD',
                  coupon: selectedDiscount?.discountCode || undefined,
                  value: 275,
                  items: [
                    {
                      item_id: 'prod_core_1',
                      item_name: '1x / week Private 1:1 Subscription',
                      item_variant: `${subject} - ${courseName}`,
                      price: 275,
                      quantity: 1,
                    },
                  ],
                },
                userdata: {
                  email: signupData.email,
                  first_name: signupData.firstName,
                  stripe_id: paymentMethod?.customer,
                },
              });
            } catch (err) {
              console.error('Failed to track sign_up event to GTM');
            }
            // END: Track 'sign_up' event to GTM
          } catch (err) {
            setErrorMessage(
              'An error occurred. Please try again later, or email support@learnwithjuni.com for help.',
            );
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }

        /**
         * Directly assigning stripeCustomerId as we don't have an updated signupData
         */
        JuniAnalytics.track('SubmitCheckoutForm', {
          signupSessionId: signupData._id,
          mongoParentId: signupData.mongoParentId,
          stripeCustomerId: checkoutRes.data?.data?.paymentMethod?.customer,
          bundleNames: signupData.students?.map(x => x.bundle?.bundleName),
          closeLeadId: signupData.closeLeadId,
          invitationLookupId: signupData.invitationLookupId,
          discountCodes: signupData.discountCodes,
          ordinal: ORDINALS[routerType][navStates.signup.checkout],
          funnelVersion: NAV_VERSIONS[routerType],
          funnelName: 'dcf',
          isMultiSubjectSignup: flags.multiSubject,
          selectedCourses: getStudentBundleSelections(activeStudent?.bundle)
            .map(selection => selection?.courseName)
            .filter(courseName => courseName),
          courseFormat: getCourseFormatFromBundleName(
            activeStudent?.bundle?.bundleName,
          ),
          value: calculateChargedPrice(),
        });

        window.rdt?.('track', 'SignUp');
        window.qp?.('track', 'CompleteRegistration');
        window.ttq?.track('Subscribe');
        window.lintrk?.('track', { conversion_id: 5692052 });
        try {
          if (window?.fpr) {
            window?.fpr('referral', {
              email: signupData.email || '',
              uid: checkoutRes.data?.data?.paymentMethod?.customer,
            });
          }
        } catch (err) {
          console.log('Failed to track signup with FirstPromoter. Error:', err);
        }
        history.push(
          getNextPage(navStates.signup.checkout, ROUTE_EVENT.SUBMIT, { signupData }),
        );
      }
    } catch (e) {
      setPaymentIsProcessing(false);
      if (!e?.response?.data) {
        console.error(e);
        setErrorMessage(defaultErrorMessage);
        return;
      }
      const errorData = e.response.data;

      if (errorData.validationErrors) {
        setValidationErrors(errorData.validationErrors);
        return;
      }

      if (e.response.status === 409) {
        setEmailExists(true);
        return;
      }
      setEmailExists(false);
      if (errorData.error) {
        if (errorData.error === 'unknown') {
          setErrorMessage(defaultErrorMessage);
          return;
        }

        if (errorData.field === 'Stripe') {
          const stripeError = errorData.error as StripeError;

          setStripeError(
            stripeError.code && PAYMENT_ERROR_COPY[stripeError.code]?.copy
              ? PAYMENT_ERROR_COPY[stripeError.code].copy
              : stripeError.message ?? defaultErrorMessage,
          );

          return;
        }

        setErrorMessage(errorData.error);
        return;
      }

      setErrorMessage(defaultErrorMessage);
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        discountIsValid,
        setDiscountIsValid,
        referralCodeValid,
        setReferralCodeValid,
      }}
    >
      <div className="flex flex-col-reverse">
        <form onSubmit={handleSubmit} className="flex items-center justify-center">
          <Card
            borderWidth="0"
            className="w-full sm:max-w-screen-xs sm:w-3/5 sm:rounded-lg"
            noRounding
          >
            <div>
              {validationErrors.map(error => (
                <ValidationError key={error.field}>{error.message}</ValidationError>
              ))}
              <PaymentForm
                stripeError={stripeError}
                errorMessage={errorMessage}
                emailExists={emailExists}
                paymentIsProcessing={paymentIsProcessing}
                onBack={() =>
                  history.push(
                    getNextPage(navStates.signup.checkout, ROUTE_EVENT.BACK, {
                      signupData,
                      search: location.search,
                      shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
                    }),
                  )
                }
              />
            </div>
          </Card>
        </form>
      </div>
    </CheckoutContext.Provider>
  );
};
export default Checkout;
