import React, { FC, useState } from 'react';
import _ from 'lodash';

import { Badge, Divider, Icon, NewButton } from 'core-components';
import { GetCoursesReturnFragment } from 'generated/graphql';
import { SUBJECT_TO_METADATA_KEY, SUBJECT_TYPE } from 'constants/subjects';

import {
  ASYNC_PLAN_PRODUCT,
  METADATA_DISPLAY_ORDER,
  METADATA_KEY,
} from 'constants/subscription_plans';
import SubjectRow from './SubjectRow';
import { SubscriptionMetaDataWithCourse } from '../../../types';
import AddCourseModal from '../AddCourseModal';
import AddTutoringModal from '../TutoringModals/AddTutoringModal';

interface Props {
  updatedSubscription: SubscriptionMetaDataWithCourse;
  timezone: string;
  currentSubscription: SubscriptionMetaDataWithCourse;
  setUpdatedSubscription: React.Dispatch<
    React.SetStateAction<SubscriptionMetaDataWithCourse>
  >;
  hasAsyncProduct: boolean;
  courses: GetCoursesReturnFragment[];
  isAsyncAdded: boolean;
}

const CourseAdjustmentSection: FC<Props> = ({
  updatedSubscription,
  timezone,
  currentSubscription,
  setUpdatedSubscription,
  hasAsyncProduct,
  courses,
  isAsyncAdded,
}) => {
  const [isAddingSubject, setIsAddingSubject] = useState(false);
  const [isAddTutoringModalOpen, setIsAddTutoringModalOpen] = useState(false);

  const availableSubjects = _.uniq(
    courses?.map(
      course => SUBJECT_TO_METADATA_KEY[course.subject.name as SUBJECT_TYPE],
    ),
  );
  const hasAllSubject = availableSubjects.every(
    subject => updatedSubscription[subject],
  );
  const showAddAsyncButton = !hasAsyncProduct && !isAsyncAdded;
  const hasTutoringSubscription = METADATA_KEY.tutoring in updatedSubscription;
  const showAddTutoringButton = !hasTutoringSubscription;

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex flex-col">
        {/* Display Subject Row */}
        {Object.keys(updatedSubscription)
          .sort(
            (a, b) =>
              METADATA_DISPLAY_ORDER.indexOf(a) - METADATA_DISPLAY_ORDER.indexOf(b),
          )
          .map((subjectKey, i) => {
            const subjectSubscriptionDetails = updatedSubscription[subjectKey];
            if (subjectKey === 'on_demand') {
              return (
                <div key={`subject-row-${subjectKey}`}>
                  <div className="flex flex-row w-full justify-between items-center">
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row items-center gap-1 font-medium text-sm sm:text-base">
                        {isAsyncAdded && (
                          <Badge
                            backgroundColor="j-green-100"
                            color="j-green-600"
                            size="regular"
                          >
                            New
                          </Badge>
                        )}{' '}
                        {ASYNC_PLAN_PRODUCT.displayName}
                      </div>
                      {isAsyncAdded && (
                        <div className="text-sm text-j-dark-300">
                          Unlimited access to 400+ hours of self study courses in
                          math, coding and more from Juni’s on demand library
                        </div>
                      )}
                    </div>

                    <NewButton
                      variant="secondary"
                      size="small"
                      disabled={hasAsyncProduct}
                      onClick={() => {
                        setUpdatedSubscription(
                          _.omit(updatedSubscription, 'on_demand'),
                        );
                      }}
                      icon
                    >
                      <Icon.Trash />
                    </NewButton>
                  </div>
                  <Divider className="my-6" />
                </div>
              );
            }

            return (
              <div key={`subject-row-${subjectKey}`}>
                <SubjectRow
                  subjectKey={subjectKey}
                  subjectSubscriptionDetails={subjectSubscriptionDetails}
                  courses={courses}
                  updatedSubscription={updatedSubscription}
                  setUpdatedSubscription={setUpdatedSubscription}
                  currentSubscription={currentSubscription}
                  timezone={timezone}
                  i={i}
                />
                <Divider className="my-6" />
              </div>
            );
          })}
      </div>

      <div className="flex sm:flex-row flex-col gap-2">
        {!hasAllSubject && (
          <NewButton
            variant="secondary"
            onClick={() => {
              setIsAddingSubject(true);
            }}
            renderIconLeft={props => <Icon.Plus {...props} />}
          >
            Add a New 1:1 Course
          </NewButton>
        )}
        {showAddTutoringButton && (
          <NewButton
            variant="secondary"
            onClick={() => setIsAddTutoringModalOpen(true)}
            renderIconLeft={props => <Icon.Plus {...props} />}
          >
            Add Tutoring
          </NewButton>
        )}
        {showAddAsyncButton && (
          <NewButton
            variant="secondary"
            onClick={() => {
              setUpdatedSubscription({
                ...updatedSubscription,
                on_demand: {
                  weeklyFrequency: undefined,
                  courses: undefined,
                },
              });
            }}
            renderIconLeft={props => <Icon.Plus {...props} />}
          >
            Add Juni’s On Demand Unlimited
          </NewButton>
        )}
      </div>

      {courses && (
        <AddCourseModal
          updatedSubscription={updatedSubscription}
          setUpdatedSubscription={setUpdatedSubscription}
          courses={courses}
          isAddingSubject={isAddingSubject}
          setIsAddingSubject={setIsAddingSubject}
          timezone={timezone}
        />
      )}
      <AddTutoringModal
        updatedSubscription={updatedSubscription}
        setUpdatedSubscription={setUpdatedSubscription}
        isAddTutoringModalOpen={isAddTutoringModalOpen}
        setIsAddTutoringModalOpen={setIsAddTutoringModalOpen}
        timezone={timezone}
      />
    </div>
  );
};

export default CourseAdjustmentSection;
