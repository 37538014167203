import React, { useEffect, useState, FC } from 'react';
import {
  Parent,
  Student,
  useStudentCertificateParamsQuery,
} from 'generated/graphql';
import { getAggregatedStudentRecentProjects } from 'services/learner/student_recent_projects';
import { NavLink } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import RingSpinner from 'components/RingSpinner';
import certificateZeroState from 'images/certificate-zero-state.png';

import { getParentsUpcomingSessions } from 'services/learner/sessions';
import { getLearnerSessionActionType, sessionHasEnded } from 'utils/acuity';
import { Divider, NewCard, NewButton, Panel, Icon } from 'core-components';
import ProjectCardV2 from 'components/ProjectCardV2';
import { COURSE_TYPES, COURSE_TYPE_SUBJECTS } from 'constants/subjects';
import CompletedCourseBadge from 'app/learner/CompletedCourseBadge';
import { getCourseByName, getStudentCoursesFromIdLookup } from 'utils/courses';
import { cleanCourseDisplayNameForLearnerAudience } from 'utils/cleanCourseDisplayNameForLearnerAudience';

interface MyCoursesCardProps {
  student: Student;
  idLookup: any;
  isLoadingIdLookup: boolean;
}
const MyCoursesCard: FC<MyCoursesCardProps> = ({
  student,
  idLookup,
  isLoadingIdLookup,
}) => {
  const { activeCourses } = getStudentCoursesFromIdLookup(
    student,
    idLookup,
    isLoadingIdLookup,
  );

  return (
    <NewCard squareBorder="bottom">
      <div className="flex flex-col">
        <div className="font-semibold text-sm mb-3 text-j-dark-600">My Courses</div>
        {isLoadingIdLookup ? (
          <RingSpinner />
        ) : (
          <>
            {activeCourses.length > 0 ? (
              activeCourses.slice(0, 2).map(course => {
                const courseName = course?.properties?.name;
                const courseType = course?.properties?.courseType;
                return course.displayName && courseName && courseType ? (
                  <NavLink
                    key={course.displayName}
                    className="no-underline"
                    to={`/learner/${student._id}/my_courses/${courseName}`}
                  >
                    <Panel
                      backgroundColor="transparent"
                      borderColor="j-dark-100"
                      padding="4"
                      className="border font-semibold justify-between items-center text-sm text-j-dark-600 mb-2"
                    >
                      <span>{course.displayName.split(':')[0]}</span>
                      <Icon.ArrowRight className="flex sm:hidden lg:flex" />
                    </Panel>
                  </NavLink>
                ) : null;
              })
            ) : (
              <div className="text-sm mb-2 text-j-dark-600">
                Your Juni courses will appear here!
              </div>
            )}
            <div className="mb-2">
              <NavLink
                className="no-underline"
                to={`/learner/${student._id}/session_notes`}
              >
                <NewButton fullWidth>View Session Notes</NewButton>
              </NavLink>
            </div>
            <NavLink className="no-underline" to={`/learner/${student._id}/roadmap`}>
              <NewButton variant="secondary" fullWidth>
                Course Roadmap
              </NewButton>
            </NavLink>
          </>
        )}
      </div>
    </NewCard>
  );
};

const UpcomingScheduleCard = ({
  parent,
  student,
}: {
  parent: Parent;
  student: Student;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [upcomingSessions, setUpcomingSessions] = useState<any[]>([]);

  useEffect(() => {
    async function fetchUpcomingSessions() {
      setIsLoading(true);

      try {
        const parentUpcomingSessions = await getParentsUpcomingSessions(
          parent.email,
        );
        if (!parentUpcomingSessions) {
          throw new Error('Error fetching Upcoming Sessions');
        }

        // Borrowed from src/app/learner/MyCalendar/MyCalendar.tsx
        const curStudentUpcomingSessions = parentUpcomingSessions.filter(
          (session: any) => {
            const sessionActionType = getLearnerSessionActionType(
              session,
              student.firstName,
              parent.hasMultipleChildren,
            );
            return !sessionHasEnded(session) && sessionActionType ? session : null;
          },
        );

        setUpcomingSessions(curStudentUpcomingSessions);
      } catch (error) {
        console.error('Error fetching Upcoming Sessions');
      }

      setIsLoading(false);
    }
    fetchUpcomingSessions();
  }, [parent.email, parent.hasMultipleChildren, student]);

  return (
    <NewCard squareBorder="all">
      <div className="font-semibold text-sm mb-3 text-j-dark-600">
        Upcoming Schedule
      </div>
      {isLoading ? (
        <RingSpinner />
      ) : upcomingSessions.length > 0 ? (
        <>
          {upcomingSessions.slice(0, 3).map(session => (
            <NavLink
              key={JSON.stringify(session)}
              className="no-underline"
              to={`/learner/${student._id}/calendar`}
            >
              <Panel
                backgroundColor="transparent"
                borderColor="j-dark-100"
                padding="4"
                className="flex border justify-between items-center text-sm text-j-dark-600 mb-2"
              >
                <div className="flex flex-col flex-grow-1">
                  <span className="font-semibold mb-1">
                    {session.datetime
                      ? format(
                          parseISO(session.datetime),
                          "EEE, MMM d 'at' h:mm aaa",
                        )
                      : '--'}
                  </span>
                  <span className="text-xs text-j-dark-400">
                    {session.type.replace('Juni Learning - ', '')}
                  </span>
                </div>
                <div className="flex items-center">
                  <Icon.ArrowRight className="flex sm:hidden lg:flex" />
                </div>
              </Panel>
            </NavLink>
          ))}
          <NavLink className="no-underline" to={`/learner/${student._id}/calendar`}>
            <NewButton variant="secondary" fullWidth>
              View Calendar
            </NewButton>
          </NavLink>
        </>
      ) : (
        <div className="text-sm mb-2 text-j-dark-600">
          Your upcoming class schedule will appear here!
        </div>
      )}
    </NewCard>
  );
};

interface RecentProjectsCardProps {
  student: Student;
  idLookup: any;
  isLoadingIdLookup: boolean;
}
const RecentProjectsCard: FC<RecentProjectsCardProps> = ({
  student,
  idLookup,
  isLoadingIdLookup,
}) => {
  const studentId: string = student._id;
  const [recentProjectIds, setRecentProjectIds] = useState([]);
  const [loadingRecentProjectIds, setLoadingRecentProjectIds] = useState(false);

  useEffect(() => {
    async function fetchStudentProjects() {
      setLoadingRecentProjectIds(true);
      try {
        const recentProjects = await getAggregatedStudentRecentProjects(studentId);
        setRecentProjectIds(recentProjects);
      } catch (error) {
        console.error('failed to load recent projects');
      }
      setLoadingRecentProjectIds(false);
    }
    fetchStudentProjects();
  }, [studentId]);

  return (
    <NewCard squareBorder="all">
      <div className="font-semibold text-sm mb-3 text-j-dark-600">
        Recent Projects
      </div>
      {loadingRecentProjectIds || isLoadingIdLookup ? (
        <RingSpinner />
      ) : recentProjectIds && recentProjectIds.length ? (
        <>
          {recentProjectIds.slice(0, 3).map(projectId => (
            <ProjectCardV2
              key={projectId}
              projectId={projectId}
              student={student}
              idLookup={idLookup}
            />
          ))}
        </>
      ) : (
        <div className="text-sm mb-2 text-j-dark-600">
          Your recent projects will appear here!
        </div>
      )}
    </NewCard>
  );
};

interface MyCertificatesCardProps {
  student: Student;
  idLookup: any;
  isLoadingIdLookup: boolean;
}
const MyCertificatesCard: FC<MyCertificatesCardProps> = ({
  student,
  idLookup,
  isLoadingIdLookup,
}) => {
  const { data, loading } = useStudentCertificateParamsQuery({
    variables: { studentId: student._id },
  });
  const isLoadingData = loading || isLoadingIdLookup;
  const items = data?.studentCertificateParams?.items || [];
  const completedCourseNames = items.map(i => i.courseName);

  return (
    <NewCard squareBorder="top">
      <div className="font-semibold text-sm mb-3 text-j-dark-600">
        Completed Courses
      </div>
      {isLoadingData ? (
        <RingSpinner />
      ) : (
        <>
          {completedCourseNames.length > 0 ? (
            completedCourseNames.map((courseName, index) => {
              const course = getCourseByName(
                idLookup[student._id].children,
                courseName!,
              ) || {
                properties: {},
              };
              const courseType = course.properties
                .courseType as typeof COURSE_TYPES[keyof typeof COURSE_TYPES];
              const subject = COURSE_TYPE_SUBJECTS[courseType];

              return course.displayName ? (
                <NavLink
                  key={JSON.stringify(course)}
                  className="no-underline"
                  to={`/course-certificate/${items[index].params}`}
                  target="_blank"
                >
                  <Panel
                    backgroundColor="transparent"
                    borderColor="j-dark-100"
                    padding="4"
                    className="flex border justify-between items-center text-sm text-j-dark-600 mb-2"
                  >
                    <div className="flex flex-col mr-3 flex-shrink-0">
                      <div className="flex items-center justify-center rounded-lg w-8 h-8">
                        <CompletedCourseBadge subject={subject} height={40} />
                      </div>
                    </div>
                    <div className="flex flex-col flex-grow">
                      <span className="text-j-dark-300 font-graphik uppercase text-2xs font-semibold">
                        {cleanCourseDisplayNameForLearnerAudience(
                          course.displayName.split(':')[0] || '',
                        )}
                      </span>
                      <span className="font-semibold">
                        {cleanCourseDisplayNameForLearnerAudience(
                          course.displayName.split(':')[1] || '',
                        )}
                      </span>
                    </div>
                    <Icon.ArrowRight className="flex sm:hidden lg:flex" />
                  </Panel>
                </NavLink>
              ) : null;
            })
          ) : (
            <div className="text-sm mb-2 text-j-dark-600">
              <div className="mt-8 flex justify-center">
                <img src={certificateZeroState} alt="Certificate" />
              </div>
              <div className="mt-4 text-center text-lg font-medium">
                Certificates
              </div>
              <div className="mt-2 text-j-dark-300 text-center">
                When you complete a course, a certificate will appear here.
              </div>
            </div>
          )}
        </>
      )}
    </NewCard>
  );
};

interface CurriculumSummaryProps {
  parent: Parent;
  student: Student;
  idLookup: any;
  isLoadingIdLookup: boolean;
}
const CurriculumSummary: FC<CurriculumSummaryProps> = ({
  parent,
  student,
  idLookup,
  isLoadingIdLookup,
}) => (
  <>
    <MyCoursesCard
      student={student}
      idLookup={idLookup}
      isLoadingIdLookup={isLoadingIdLookup}
    />
    <Divider />
    <UpcomingScheduleCard parent={parent} student={student} />
    <Divider />
    <RecentProjectsCard
      student={student}
      idLookup={idLookup}
      isLoadingIdLookup={isLoadingIdLookup}
    />
    <Divider />
    <MyCertificatesCard
      student={student}
      idLookup={idLookup}
      isLoadingIdLookup={isLoadingIdLookup}
    />
  </>
);

export default CurriculumSummary;
