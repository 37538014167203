export const CONFIG_HEADERS = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

export const REFETCH_INVITE_INTERVAL = 60 * 1000; // 60 seconds

export const MIN_PASSWORD_LENGTH = 8;

export const BIO_PLACEHOLDER_TEXT =
  '[Your FIRST name] is studying [MAJOR and minor] at [School Name]. [Preferred Pronoun] has earned [insert academic achievements {ex: Dean’s list, academic honors, valedictorian status, etc.}]. Previously, [Preferred Pronoun] worked as [most relevant CS/mathematics/English/STEM teaching work experience/internship experience. Additionally, [Preferred Pronoun] has experience in [SPECIFIC COURSE WORK] coursework. Outside of school, [your FIRST Name]’s three favorite hobbies/activities are ____, ____, and ____.';
