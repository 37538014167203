/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { addProjectToStudentRecentProjects } from 'services/learner/student_recent_projects';

import './juni_ide.css';
import {
  JideEnvironmentBar,
  JideNavBar,
  JideTabBar,
  JideRecStudio,
} from 'components/jide';
import JuniSpinner from 'components/JuniSpinner';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

class JuniIDE extends Component {
  state = {
    activeNav: {},
    openTabs: [],
    activeTabId: null,
    trialCourses: [],
    isLoading: true, // see getDerivedStateFromProps
    shiftKeyIsPressed: false,
    recordingMode: false,
    recordingTabId: null,
  };
  navBarSections = ['student', 'course', 'module', 'project'];
  inTrialMode = this.props.onlyTrialCourses.length > 0;

  async componentDidMount() {
    this._isMounted = true;
    const { initNav } = this.props;
    if (initNav && initNav.project) {
      this.openNewTab(initNav);
    }

    if (!this.eventListenersSet) {
      window.addEventListener('keydown', this.onKeyDown);
      window.addEventListener('keyup', this.onKeyUp);
    }
    this.eventListenersSet = true;
  }

  componentDidUpdate(prevProps) {
    if (this._isMounted && prevProps.jideUser._id !== this.props.jideUser._id) {
      this.setState(
        {
          activeNav: {},
          openTabs: [],
          activeTabId: null,
          trialCourses: [],
          isLoading: null,
          shiftKeyIsPressed: false,
        },
        this.componentDidMount,
      );
    }

    if (prevProps.isLoadingIdLookup && !this.props.isLoadingIdLookup) {
      if (this.props.onlyTrialCourses.length > 0) {
        this.setUpTrialCourses();
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    this.eventListenersSet = false;
  }
  static getDerivedStateFromProps(props) {
    const isLoading = !!props.isLoadingIdLookup;
    return { isLoading };
  }

  onKeyDown = e => {
    if (e.shiftKey && !this.state.shiftKeyIsPressed) {
      this.setState({ shiftKeyIsPressed: true });
    }
  };
  onKeyUp = e => {
    if (!e.shiftKey && this.state.shiftKeyIsPressed) {
      this.setState({ shiftKeyIsPressed: false });
    }
  };

  setUpTrialCourses = () => {
    // do once initial idlookuptable load is complete?
    // finish trial stuff
    // replace idlookup usage in child components
    // test

    const courses = Object.keys(this.props.idLookup)
      .map(id => this.props.idLookup[id])
      .filter(o => o.type === 'course');
    const trialCourses = courses.filter(course =>
      this.props.onlyTrialCourses.includes(course.properties.name),
    );
    if (trialCourses.length === 1) {
      const activeNav = {
        course: trialCourses[0].id,
      };
      let tabAutoFilled = false;
      const modules = trialCourses[0].children;
      if (modules && modules.length > 0) {
        activeNav.module = modules[0].id;
        const sections = modules[0].children;
        if (sections && sections.length > 0) {
          activeNav.project = sections[0].id;
          tabAutoFilled = true;
        }
      }
      if (this._isMounted) {
        this.setState({ trialCourses, activeNav }, () => {
          if (tabAutoFilled) {
            this.openNewTab(activeNav);
          }
        });
      }
    } else if (this._isMounted) {
      this.setState({ trialCourses });
    }
  };

  trackLearnerProjectOpening = projectId => {
    const openedProject = this.props.idLookup[projectId];
    const projectCourse = this.props.idLookup[openedProject.properties.courseId];
    const { student } = this.props;
    if (student && student._id) {
      JuniAnalytics.track('learner_project_opened_jide', {
        studentId: student._id,
        projectId,
        displayName: openedProject.displayName,
        isSupplemental: openedProject.properties.isSupplemental,
        isCustomProject: !!openedProject.properties.isCustomProject,
        courseId: openedProject.properties.courseId,
        moduleId: openedProject.properties.moduleId,
        courseType: projectCourse.properties.courseType,
        sectionType: openedProject.properties.sectionType
          ? openedProject.properties.sectionType
          : 'project',
      });
    }
  };

  openNewTab = newNav => {
    if (!newNav) return;
    // create tabId that is studentId_projectId
    const firstSectionType = this.navBarSections[0];
    const lastSectionType = this.navBarSections[this.navBarSections.length - 1];
    const newTabId = `${newNav[firstSectionType]}_${newNav[lastSectionType]}`;

    const alreadyOpenTab = this.state.openTabs.find(tab => tab.tabId === newTabId);
    if (alreadyOpenTab) {
      // if tab for that student and project is already open, switch to that tab
      this.onTabClickHandler(alreadyOpenTab.tabId);
    } else {
      if (this.props.learnerAnalyticsEnabled) {
        this.trackLearnerProjectOpening(newNav.project);
      }
      // add new tab info to openTabs and click the tab
      const newTab = {
        tabId: newTabId,
        tabNav: { ...newNav },
      };
      if (this._isMounted) {
        this.setState(
          prevState => ({ openTabs: [newTab, ...prevState.openTabs] }),
          () => {
            this.onTabClickHandler(newTab.tabId);
          },
        );
      }
    }
  };

  onTabCloseHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const closedTabId = e.currentTarget.value;
    const newOpenTabs = this.state.openTabs.filter(tab => closedTabId !== tab.tabId);
    const closedTabIndex = this.state.openTabs.findIndex(
      tab => closedTabId === tab.tabId,
    );
    let newActiveTabId = this.state.activeTabId;
    if (closedTabId === this.state.activeTabId) {
      newActiveTabId =
        closedTabIndex > 0
          ? newOpenTabs[closedTabIndex - 1].tabId
          : newOpenTabs.length > 0
          ? newOpenTabs[0].tabId
          : null;
    }
    if (this._isMounted) {
      this.setState({ openTabs: newOpenTabs }, () => {
        this.onTabClickHandler(newActiveTabId);
      });
    }
  };

  onTabClickHandler = inputTabId => {
    let tabNav = {};
    if (inputTabId) {
      const clickedTab = this.state.openTabs.find(tab => tab.tabId === inputTabId);
      tabNav = clickedTab ? clickedTab.tabNav : this.state.activeNav;
      if (this.props.saveRecentProjectHistory) {
        // sets to either code, math, english, or playground.
        const { courseType } =
          tabNav.course === 'playground'
            ? { courseType: 'playground' }
            : this.props.idLookup[tabNav.course].properties;
        addProjectToStudentRecentProjects(
          tabNav.student,
          tabNav.course,
          courseType,
          tabNav.project,
        );
      }
    } else {
      tabNav = { student: this.state.activeNav.student };
    }
    if (this._isMounted) {
      const { location } = this.props;
      // update or set deep linked project id in url
      let index = location.pathname.indexOf('/project/');
      if (index !== -1) {
        index += '/project/'.length;
        const newLocation = location.pathname.substring(0, index) + tabNav.project;
        if (newLocation !== this.props.location.pathname) {
          this.props.history.replace(newLocation);
        }
      }
      this.setState({ activeTabId: inputTabId, activeNav: tabNav });
    }
  };

  setRecordingMode = val => {
    this.setState({ recordingMode: !!val });
  };
  onTabStartedRecording = () => {
    this.setState(prevState => ({ recordingTabId: prevState.activeTabId }));
  };
  onTabStoppedRecording = () => {
    if (this.state.recordingTabId) {
      const clickedTab = this.state.openTabs.find(
        tab => tab.tabId === this.state.recordingTabId,
      );
      if (clickedTab) this.onTabClickHandler(this.state.recordingTabId);
    }

    this.setState({ recordingTabId: null });
  };

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="jide">
          <div className="spinner-container">
            <JuniSpinner size={150} />
          </div>
        </div>
      );
    }

    return (
      <div className="jide">
        {!this.props.modalViewMode && !this.props.playgroundProject && (
          <JideNavBar
            jideUser={this.props.jideUser}
            isLoading={this.state.isLoading}
            courseTypes={this.props.courseTypes}
            activeNav={this.state.activeNav}
            trialCourses={this.state.trialCourses}
            onlyTrialCourses={this.props.onlyTrialCourses}
            navBarSections={this.navBarSections}
            includeTrialModules={this.props.includeTrialModules}
            idLookup={this.props.idLookup}
            reloadIdLookup={this.props.reloadIdLookup}
            openNewTab={this.openNewTab}
          />
        )}
        {(!this.props.modalViewMode ||
          ['teacher', 'student'].includes(this.props.jideUser.type)) && (
          <JideTabBar
            activeNav={this.state.activeNav}
            activeTabId={this.state.activeTabId}
            recordingTabId={this.state.recordingTabId}
            openTabs={this.state.openTabs}
            navBarSections={this.navBarSections}
            idLookup={this.props.idLookup}
            onTabClick={this.onTabClickHandler}
            onTabClose={this.onTabCloseHandler}
            modalViewMode={this.props.modalViewMode}
            playgroundProject={this.props.playgroundProject}
            returnToPlayground={this.props.returnToPlayground}
            updateProjectName={this.props.updateProjectName}
          />
        )}
        <JideEnvironmentBar
          jideUser={this.props.jideUser}
          idLookup={this.props.idLookup}
          openTabs={this.state.openTabs}
          activeTabId={this.state.activeTabId}
          isLoading={this.state.isLoading}
          modalViewMode={this.props.modalViewMode}
          shiftKeyIsPressed={this.state.shiftKeyIsPressed}
          showcaseViewMode={this.props.showcaseViewMode}
          activeNav={this.state.activeNav}
          learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
          setJuniverseEnvType={this.props.setJuniverseEnvType}
          playgroundProject={this.props.playgroundProject}
          updateProjectName={this.props.updateProjectName}
          juniverseProjectData={this.props.juniverseProjectData}
          recordingMode={this.state.recordingMode}
          setRecordingMode={this.setRecordingMode}
          // feature flagging New Horizons restyling
          newHorizons={
            queryString.parse(this.props.location.search).newHorizons === 'true'
          }
          encryptedStudentAndProject={this.props.encryptedStudentAndProject}
          isCodeHidden={this.props.isCodeHidden}
        />
        {this.state.recordingMode && (
          <JideRecStudio
            jideUser={this.props.jideUser}
            idLookup={this.props.idLookup}
            activeNav={this.state.activeNav}
            recordingMode={this.state.recordingMode}
            setRecordingMode={this.setRecordingMode}
            onTabStartedRecording={this.onTabStartedRecording}
            onTabStoppedRecording={this.onTabStoppedRecording}
            setHideNavBar={this.props.setHideNavBar}
          />
        )}
      </div>
    );
  }
}
JuniIDE.defaultProps = {
  initNav: {},
  includeTrialModules: false,
  learnerAnalyticsEnabled: false,
  onlyTrialCourses: [],
  saveRecentProjectHistory: false,
  modalViewMode: false,
  showcaseViewMode: false,
  isCodeHidden: false,
};
JuniIDE.propTypes = {
  // to tell juniverse project container which environment it is for display purposes
  setJuniverseEnvType: PropTypes.func,
  // Array type so that we can support multiple course types in a single JuniIDE component
  courseTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  initNav: PropTypes.shape({}),
  includeTrialModules: PropTypes.bool,
  learnerAnalyticsEnabled: PropTypes.bool,
  onlyTrialCourses: PropTypes.arrayOf(PropTypes.string),
  saveRecentProjectHistory: PropTypes.bool,
  modalViewMode: PropTypes.bool,
  showcaseViewMode: PropTypes.bool,
  isLoadingIdLookup: PropTypes.bool,
  idLookup: PropTypes.shape({}).isRequired,
  reloadIdLookup: PropTypes.func,
  playgroundProject: PropTypes.shape({
    projectType: PropTypes.string,
    userType: PropTypes.string,
  }),
  returnToPlayground: PropTypes.func,
  updateProjectName: PropTypes.func,
  setHideNavBar: PropTypes.func,
  encryptedStudentAndProject: PropTypes.string,
  isCodeHidden: PropTypes.bool,
};
export default withRouter(JuniIDE);
