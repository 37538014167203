import React, { FC } from 'react';
import { NewButton } from 'core-components';
import { IconRenderProps } from 'core-components/NewButton/Button';
import { Arrow } from 'components/Icons';
import { Intents } from 'core-components/NewButton/types';

interface Action {
  text?: string;
  disabled?: boolean;
  handler: () => void;
  intent?: Intents;
}

interface Props {
  back?: Action;
  next?: Action;
}

const SignupFlowFooter: FC<Props> = ({ back, next }) => (
  <footer className="w-full flex flex-row space-x-4 py-4">
    {back && (
      <NewButton
        className="h-12 w-1/3"
        variant="secondary"
        disabled={back.disabled}
        onClick={back.handler}
        renderIconLeft={(props: IconRenderProps) => (
          <Arrow {...props} orientation="left" />
        )}
      >
        {back.text ?? 'Back'}
      </NewButton>
    )}
    {next && (
      <NewButton
        variant="primary"
        intent={next?.intent || 'default'}
        className={!back ? 'w-full' : 'h-12 w-2/3'}
        disabled={next.disabled}
        onClick={next.handler}
      >
        {next.text ?? 'Continue'}
      </NewButton>
    )}
  </footer>
);

export default SignupFlowFooter;
