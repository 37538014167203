import React from 'react';
import useSignupCoupon from 'app/signup_2023/hooks/useSignupCoupon';
import { useSignupData } from 'app/signup_2023/hooks';
import { STEP_COUPONS } from 'app/signup_2023/steps/coupons';

export const SHOW_INVALID_COUPONS = false;

const DiscountCodeBanner = () => {
  const {
    clientData: { currentStep },
  } = useSignupData();
  const { couponId, coupon, error, loading } = useSignupCoupon();
  if (loading) {
    return null;
  }

  // some steps should never show a coupon
  const isShowCouponBanner =
    currentStep && STEP_COUPONS[currentStep].isShowCouponBanner;
  if (!isShowCouponBanner) return null;

  if (SHOW_INVALID_COUPONS && (error || coupon?.valid === false)) {
    return (
      <div className="w-full py-4 text-center text-white bg-j-orange-600">
        Coupon code <span className="uppercase font-semibold">{couponId}</span> is
        not valid.
      </div>
    );
  }

  if (coupon?.valid) {
    return (
      <div className="w-full py-4 text-center text-white bg-j-green-600">
        Coupon code <span className="uppercase font-semibold">{couponId}</span> will
        be added at checkout.
      </div>
    );
  }

  return null;
};

export default DiscountCodeBanner;
