import React, { FC, useEffect, useState } from 'react';
import { getInstructorByUserId } from 'services/instructor/instructor_by_user_id';
import { logErrorToSentry } from 'services/sentry';
import { getStudentById } from 'services/students';
import { Icon } from 'core-components';
import { Instructor, Student } from 'models';
import { StudentProject, useGetUserProfileQuery } from 'generated/graphql';
import { Link } from 'react-router-dom';
import JuniSpinner from 'components/JuniSpinner';
import otter_square from 'images/otter_square.svg';
import astronaut from 'images/astronaut.svg';

// TODO: Uncomment when students can display profile pictures
// import { getInstructorProfileInfo } from 'services/api/instructor_profile';

interface Props {
  studentId?: string;
  instructorId?: string;
  username?: string;
  publicProjects?: StudentProject[];
  // profileImage?: string; // TODO: Use this when students can add profile image too
}

const JuniverseProjectCreator: FC<Props> = ({
  studentId,
  instructorId,
  username,
  publicProjects,
}) => {
  const [student, setStudent] = useState<Student | undefined>(undefined);
  const [instructor, setInstructor] = useState<Instructor | undefined>(undefined);
  const [profilePic, setProfilePic] = useState('');
  const {
    data: userProfileData,
    loading: userProfileLoading,
  } = useGetUserProfileQuery({
    variables: { username: username! },
  });
  const userImage = instructorId ? otter_square : astronaut;

  useEffect(() => {
    const getStudent = async () => {
      const student = await getStudentById(studentId).catch(error => {
        logErrorToSentry(error);
      });

      if (student) {
        setStudent(student);
      }
    };

    const getInstructor = async () => {
      const instructor = await getInstructorByUserId(instructorId).catch(error => {
        logErrorToSentry(error);
      });

      if (instructor) {
        // TODO: These lines will fetch the instructor image and set it if available
        // Should be used when students can set them too
        // const image = await getInstructorProfileInfo({
        //   instructorProfileId: '',
        //   email: instructor?.data?.email,
        // });
        // setProfilePic(image?.profilePic || userImage || '');
        setInstructor(instructor.data);
      }
    };

    if (studentId) {
      getStudent();
    }

    if (instructorId) {
      getInstructor();
    }

    setProfilePic(userImage);
  }, [studentId, instructorId, userImage]);

  const posts = publicProjects?.length || 0;
  const views =
    publicProjects?.reduce(
      (total, current) => (current?.viewCount || 0) + total,
      0,
    ) || 0;
  const userFullName =
    (student && [student?.firstName, student?.lastName].join(' ')) ||
    (instructor && [instructor?.firstName, instructor?.lastName].join(' '));
  const bio = userProfileData?.profileByUsername?.profile?.bio;
  const imageStyles = profilePic
    ? {
        backgroundImage: `url('${profilePic}')`,
      }
    : {};
  const SectionLoader = () => (
    <div className="flex justify-center items-center w-10 h-10 m-auto relative pl-20 pt-10">
      <JuniSpinner size={40} />
    </div>
  );

  return (
    <div className="bg-white rounded-lg lg:mb-4 mb-6 shadow-1">
      <div className="p-6">
        {userProfileLoading ? (
          <SectionLoader />
        ) : (
          <div className="flex flex-col items-center">
            <div
              className="w-20 h-20 rounded-full border border-solid border-j-purple-400 bg-j-purple-200 flex justify-center items-center overflow-hidden bg-contain bg-center"
              style={imageStyles}
            >
              {!profilePic && <Icon.User className="w-6 h-6 text-j-purple-600" />}
            </div>
            <h2 className="text-j-dark-600 text-xl leading-8 m-0 pt-4 font-normal">
              {userFullName}
            </h2>
            {username && (
              <h3 className="text-j-dark-600 text-base m-0 font-normal">
                @{username}
              </h3>
            )}
            {bio && (
              <p className="text-j-dark-400 line-clamp-3 m-0 pt-4 text-sm text-center">
                {bio}
              </p>
            )}
            {username && (
              <Link
                className="btn primary w-full mt-4 block"
                to={`/juniverse/users/${encodeURIComponent(username || '')}`}
              >
                View my profile
              </Link>
            )}
          </div>
        )}
      </div>

      {(userProfileLoading || !!posts || !!views) && (
        <div className="p-6 border-0 border-t border-solid border-j-gray-300">
          {userProfileLoading ? (
            <SectionLoader />
          ) : (
            <>
              {!!posts && (
                <p className="flex justify-between text-j-dark-400 text-base m-0 pb-4">
                  Published Projects <span className="text-j-dark-600">{posts}</span>
                </p>
              )}
              {!!views && (
                <p className="flex justify-between text-j-dark-400 text-base m-0">
                  Project Views <span className="text-j-dark-600">{views}</span>
                </p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default JuniverseProjectCreator;
