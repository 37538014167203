/* eslint-disable jsx-a11y/no-autofocus */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StatusIcon from 'components/StatusIcon';
import { getQualifiedDisplayName } from 'utils/jideNavUtils';
import {
  Caret,
  CheckmarkIcon,
  Chevron,
  LinkIconV2,
  CodeIcon,
  PaperPlusIcon,
  MathIcon,
  EnglishIcon,
  InvestingIcon,
} from 'components/Icons';
import './jide_nav.css';

import { COURSE_TYPES } from 'constants/subjects';
import { useCourseNameToBasicMetadata } from 'hooks/course';
import { CourseSchedulingFormat } from 'generated/graphql';

function getModuleSectionDisplayType(courseTypes, curCourse, curModuleSection) {
  const courseTypeToDefaultSectionDisplayType = {
    [COURSE_TYPES.COMPUTER_SCIENCE]: 'Project',
    [COURSE_TYPES.MATHEMATICS]: 'Concept / Problem Set / Activity',
    [COURSE_TYPES.ENGLISH]: 'Concept / Project / Activity',
    [COURSE_TYPES.INVESTING]: 'Concept / Project / Activity',
  };

  let moduleSectionDisplayType =
    courseTypes.length === 1
      ? courseTypeToDefaultSectionDisplayType[courseTypes[0]] || 'Module Section'
      : 'Module Section';

  if (courseTypes.length > 1 && curCourse) {
    moduleSectionDisplayType =
      courseTypeToDefaultSectionDisplayType[curCourse.properties.courseType];
  }

  if (
    curCourse &&
    curModuleSection &&
    [
      COURSE_TYPES.MATHEMATICS,
      COURSE_TYPES.ENGLISH,
      COURSE_TYPES.INVESTING,
    ].includes(curCourse.properties.courseType)
  ) {
    moduleSectionDisplayType = {
      problem_set: 'Problem Set',
      reading_project: 'Reading Project',
      writing_project: 'Writing Project',
      concept: 'Concept',
      activity: 'Activity',
      question_set: 'Questions',
    }[curModuleSection.properties.sectionType];
  }

  return moduleSectionDisplayType;
}

const courseTypeToIcon = {
  [COURSE_TYPES.COMPUTER_SCIENCE]: <CodeIcon />,
  [COURSE_TYPES.MATHEMATICS]: <MathIcon />,
  [COURSE_TYPES.ENGLISH]: <EnglishIcon />,
  [COURSE_TYPES.INVESTING]: <InvestingIcon />,
};

const JideNavOption = ({
  opt,
  sectionType,
  isSelected,
  onOptionClick,
  defaultIcon,
  activeNav,
  modalViewMode,
  closeDropdowns,
  jideUser,
  qualifiedDisplayName,
}) => {
  const getStatus = () => {
    switch (true) {
      case !opt.isUnlocked:
        return 'locked';
      case opt.isCompleted:
        return 'completed';
      case opt.isInProgress:
        return 'in_progress';
      default:
        return '';
    }
  };
  const status = getStatus();
  const statusClass = status.replace(/_/g, '-');
  const linkToHomeUrl =
    sectionType === 'course'
      ? `/learner/${activeNav.student}/my_courses/${opt.properties.name}`
      : sectionType === 'module' && opt.courseHasVideos
      ? `/learner/${activeNav.student}/my_courses/module/${opt.id}`
      : '';

  const isDisabled =
    !opt.isUnlocked && (sectionType === 'project' || !opt.courseHasVideos);

  return (
    <div
      className={`jide-nav-dd-option${isSelected ? ' selected' : ''} ${statusClass}${
        isDisabled ? ' disabled' : ''
      }`}
      onClick={() => {
        onOptionClick(sectionType, opt);
      }}
    >
      <div className="jide-nav-dd-option-left">{defaultIcon}</div>
      <div className={`jide-nav-dd-option-middle ${statusClass}`}>
        <span>{qualifiedDisplayName || opt.displayName}</span>
        <StatusIcon status={status} />
      </div>
      <div className="jide-nav-dd-option-right">
        {!modalViewMode &&
          jideUser &&
          jideUser.type === 'student' &&
          sectionType === 'module' &&
          linkToHomeUrl && (
            <div
              className="course-home-link"
              onClick={e => {
                e.stopPropagation();
                closeDropdowns();
              }}
            >
              <Link to={linkToHomeUrl}>
                <LinkIconV2 />
              </Link>
            </div>
          )}
        {(isSelected || opt.isModalAction) && <Chevron orientation="right" />}
      </div>
    </div>
  );
};

class JideNav extends PureComponent {
  render() {
    // set width depending on section type
    let navWidth = '250px';
    const widthLookup = {
      student: '250px',
      course: '300px',
      module: '300px',
      project: '300px',
      concept: '300px',
      activity: '300px',
      problem_set: '300px',
    };
    if (this.props.sectionType in widthLookup) {
      navWidth = widthLookup[this.props.sectionType];
    }

    // get id of object to put in header section
    // check newNav first then activeNav
    const id =
      Object.keys(this.props.newNav).length > 0
        ? this.props.newNav[this.props.sectionType]
        : this.props.activeNav[this.props.sectionType];

    // create dropdown divs from the dropdownoptions
    let dropDownOptionsComponents = [];
    const curCourse =
      this.props.idLookup[this.props.newNav.course] ||
      this.props.idLookup[this.props.activeNav.course];
    if (this.props.expanded[this.props.sectionType]) {
      if (this.props.sectionType !== 'student') {
        let options = this.props.getOptions(this.props.sectionType) || [];

        if (
          curCourse &&
          curCourse.properties.courseType === COURSE_TYPES.COMPUTER_SCIENCE &&
          this.props.sectionType === 'project'
        ) {
          const coreOptions = options.filter(
            project =>
              !project.properties.isSupplemental &&
              !project.properties.isCustomProject,
          );
          const supplementalOptions = options.filter(
            project => project.properties.isSupplemental,
          );
          const customProjectOptions = options.filter(
            project =>
              project.properties.isCustomProject &&
              project.properties.studentId === this.props.activeNav.student,
          );
          const coreOptionsComponents = coreOptions.map(o => (
            <JideNavOption
              key={o.id}
              {...this.props}
              opt={o}
              isSelected={Object.values(this.props.newNav).includes(o.id)}
              sectionType={this.props.sectionType}
              onOptionClick={this.props.onOptionClick}
              defaultIcon={<CodeIcon />}
            />
          ));
          const supplementalOptionsComponents = supplementalOptions.map(o => (
            <JideNavOption
              key={o.id}
              {...this.props}
              opt={o}
              isSelected={Object.values(this.props.newNav).includes(o.id)}
              sectionType={this.props.sectionType}
              onOptionClick={this.props.onOptionClick}
              defaultIcon={<PaperPlusIcon />}
            />
          ));
          if (supplementalOptions.length > 0) {
            supplementalOptionsComponents.unshift(
              <div
                key="jide-nav-dd-subheader-supplemental-projects"
                className="jide-nav-dd-option subheader"
              >
                <div className="jide-nav-dd-option-left">
                  <PaperPlusIcon />
                </div>
                <div className="jide-nav-dd-option-middle">
                  Supplemental Projects
                </div>
              </div>,
            );
          }
          const customProjectsOptionsComponents = customProjectOptions.map(o => (
            <JideNavOption
              key={o.id}
              {...this.props}
              opt={o}
              isSelected={Object.values(this.props.newNav).includes(o.id)}
              sectionType={this.props.sectionType}
              onOptionClick={this.props.onOptionClick}
              defaultIcon={<PaperPlusIcon />}
            />
          ));
          customProjectsOptionsComponents.unshift(
            <div
              key="jide-nav-dd-subheader-custom-projects"
              className="jide-nav-dd-option subheader"
            >
              <div className="jide-nav-dd-option-left">
                <CodeIcon />
              </div>
              <div className="jide-nav-dd-option-middle">Custom Projects</div>
            </div>,
          );

          customProjectsOptionsComponents.push(
            <JideNavOption
              key="create-custom-project"
              {...this.props}
              opt={{
                displayName: 'Create a new custom project',
                isUnlocked: true,
                isModalAction: true,
              }}
              isSelected={false}
              sectionType={this.props.sectionType}
              onOptionClick={() => {
                this.props.closeDropdowns();
                this.props.onCreateCustomProjectClick();
              }}
              defaultIcon={<PaperPlusIcon />}
            />,
          );

          dropDownOptionsComponents = [].concat(
            coreOptionsComponents,
            supplementalOptionsComponents,
          );

          // only add custom projects to courses that can have them
          if (curCourse.properties.canHaveCustomProjects) {
            dropDownOptionsComponents.push(customProjectsOptionsComponents);
          }
        } else {
          const defaultIcon =
            this.props.courseTypes.length === 1
              ? courseTypeToIcon[this.props.courseTypes]
              : curCourse && this.props.sectionType !== 'course'
              ? courseTypeToIcon[curCourse.properties.courseType]
              : undefined;

          let numInProgress;
          if (this.props.sectionType === 'course') {
            const inProgress = options.filter(o => !o.isCompleted);
            const completed = options.filter(o => o.isCompleted);
            options = inProgress.concat(completed);
            numInProgress = inProgress.length;
          }

          dropDownOptionsComponents = options.map(o => (
            <JideNavOption
              key={o.id}
              {...this.props}
              opt={o}
              isSelected={Object.values(this.props.newNav).includes(o.id)}
              sectionType={this.props.sectionType}
              onOptionClick={this.props.onOptionClick}
              defaultIcon={defaultIcon || courseTypeToIcon[o.properties.courseType]}
              qualifiedDisplayName={getQualifiedDisplayName(
                o.displayName,
                this.props.idLookup[o.properties?.moduleId]?.properties?.name,
                o.properties?.sectionType,
              )}
            />
          ));
          if (
            this.props.sectionType === 'course' &&
            dropDownOptionsComponents.length - numInProgress > 0
          ) {
            dropDownOptionsComponents.splice(
              numInProgress,
              0,
              <div
                key="jide-nav-dd-subheader-type-completed"
                className="jide-nav-dd-option subheader"
              >
                <div className="jide-nav-dd-option-left">
                  <CheckmarkIcon />
                </div>
                <div className="jide-nav-dd-option-middle">Completed</div>
              </div>,
            );
          }
        }

        const subheaderDisplay =
          this.props.asyncCourseNames.includes(curCourse?.properties?.name) &&
          this.props.sectionType === 'project'
            ? `Pathways` // use 'Pathways' subheader instead of 'Projects' for self-taught courses like python_level_1_async_a
            : curCourse?.properties?.courseType === COURSE_TYPES.MATHEMATICS &&
              this.props.sectionType === 'project'
            ? 'Concepts, Problem Sets & Activities'
            : `${this.props.sectionType}s`;
        dropDownOptionsComponents.unshift(
          <div
            key="jide-nav-dd-subheader-type"
            className="jide-nav-dd-option subheader"
          >
            <div className="jide-nav-dd-option-left">
              {this.props.courseTypes.length === 1
                ? courseTypeToIcon[this.props.courseTypes]
                : curCourse
                ? courseTypeToIcon[curCourse.properties.courseType]
                : undefined}
            </div>
            <div className="jide-nav-dd-option-middle">{subheaderDisplay}</div>
          </div>,
        );
      }
    }

    const qualifiedDisplayName = getQualifiedDisplayName(
      this.props.idLookup[id]?.displayName,
      this.props.idLookup[this.props.activeNav.module]?.properties?.name,
      this.props.sectionType === 'project'
        ? this.props.idLookup[this.props.activeNav.project]?.properties?.sectionType
        : '',
    );

    const curModuleSection = this.props.expanded.project
      ? null
      : this.props.idLookup[this.props.activeNav.project];

    const moduleSectionDisplayType = getModuleSectionDisplayType(
      this.props.courseTypes,
      curCourse,
      curModuleSection,
    );

    return (
      <div className="jide-nav" style={{ width: navWidth }}>
        <div
          className="jide-nav-header"
          onClick={() => {
            if (this.props.sectionType !== 'course') {
              this.props.onHeaderClick(this.props.sectionType);
            }
          }}
          title={qualifiedDisplayName}
        >
          <div className="jide-nav-left">
            <div className="jide-nav-left-upper">
              {this.props.sectionType === 'project'
                ? moduleSectionDisplayType.toUpperCase()
                : this.props.sectionType.toUpperCase()}
            </div>
            <div className="jide-nav-left-lower">{qualifiedDisplayName}</div>
          </div>
          <div className="jide-nav-right">
            <button className="jide-nav-caret">
              {this.props.sectionType !== 'course' && <Caret />}
            </button>
          </div>
        </div>
        <div
          className={`jide-nav-dd${
            this.props.expanded[this.props.sectionType] ? ' jide-nav-expanded' : ''
          }`}
          style={{ width: navWidth }}
        >
          {dropDownOptionsComponents}
        </div>
      </div>
    );
  }
}

JideNav.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  sectionType: PropTypes.string.isRequired,
  courseTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  idLookup: PropTypes.shape({}).isRequired,
  activeNav: PropTypes.shape({
    student: PropTypes.string,
    course: PropTypes.string,
    module: PropTypes.string,
    project: PropTypes.string,
  }).isRequired,
  newNav: PropTypes.shape({
    student: PropTypes.string,
    course: PropTypes.string,
    module: PropTypes.string,
    project: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.shape({
    student: PropTypes.bool,
    course: PropTypes.bool,
    module: PropTypes.bool,
    project: PropTypes.bool,
  }).isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  getStudentOptions: PropTypes.func.isRequired,
  closeDropdowns: PropTypes.func.isRequired,
  onCreateCustomProjectClick: PropTypes.func.isRequired,
  asyncCourseNames: PropTypes.arrayOf(PropTypes.string),
};

const JideNavWrapper = props => {
  const { coursesWithBasicMetadata } = useCourseNameToBasicMetadata();
  const asyncCourseNames = coursesWithBasicMetadata
    .filter(course => course.schedulingFormat === CourseSchedulingFormat.OnDemand)
    .map(course => course.name);
  return <JideNav asyncCourseNames={asyncCourseNames} {...props} />;
};
export default JideNavWrapper;
