import { useState, useEffect } from 'react';
import { COUPON_DISALLOW_LIST } from 'constants/signup_sessions';
import discountCodeService from 'services/signupSessions/discountCodeService';
import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';

import { DiscountData } from '../types';

const useIsValidCoupon = (
  couponCode: string,
  isPrivateOneOnOne?: boolean,
  isOnDemandSignup?: boolean,
) => {
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [discountDataObj, setDiscountDataObj] = useState<DiscountData | undefined>(
    undefined,
  );

  useEffect(() => {
    let isCouponCheck = false;

    const checkCoupon = async () => {
      // trim extra whitespace from code otherwise we can fail validations
      const trimmedDiscountCode = couponCode?.trim();

      if (COUPON_DISALLOW_LIST.includes(trimmedDiscountCode)) {
        setIsCouponValid(false);
        return;
      }

      const getStripeCouponData = async (couponCode: string) => {
        try {
          if (!couponCode) {
            return;
          }
          const res = await discountCodeService.validateCouponCode(
            trimmedDiscountCode,
          );
          return res.data?.data;
        } catch (e) {
          console.error(e);
        }
      };

      const validCouponCode = await getStripeCouponData(trimmedDiscountCode);

      if (!isCouponCheck) {
        // Coupon exists in Stripe but is no longer valid (e.g. expired)
        if (!validCouponCode || validCouponCode?.valid === false) {
          setIsCouponValid(false);
          return;
        }

        const couponAppliesToSpecificProduct =
          validCouponCode?.applies_to?.products?.length > 0;

        if (couponAppliesToSpecificProduct) {
          const isValidAsyncCoupon = validCouponCode?.applies_to?.products?.includes(
            ASYNC_PLAN_PRODUCT.productId,
          );
          const isValidPrivateCoupon = validCouponCode?.applies_to?.products?.some(
            (productId: string) => productId.startsWith('prod_core_'),
          );
          // the order of the below condition matter as there is a current bug with isOnDemandSignup and isPrivateOneOnOne sign up flags both marked as true
          if (isOnDemandSignup && !isValidAsyncCoupon) {
            setIsCouponValid(false);
            return;
          }
          if (isPrivateOneOnOne && !isOnDemandSignup && !isValidPrivateCoupon) {
            setIsCouponValid(false);
            return;
          }
        }

        if (validCouponCode) {
          setIsCouponValid(true);
          const newDiscountDataObj: DiscountData = {
            discountCode: trimmedDiscountCode,
            discount: validCouponCode.amount_off ?? validCouponCode.percent_off,
            discountType: validCouponCode.amount_off ? 'amount_off' : 'percent_off',
            duration: validCouponCode.duration,
            durationInMonths: validCouponCode.duration_in_months,
          };
          setDiscountDataObj(newDiscountDataObj);
        }
      }
    };

    checkCoupon();

    return () => {
      isCouponCheck = true;
    };
  }, [couponCode, isOnDemandSignup, isPrivateOneOnOne]);

  return { isCouponValid, discountDataObj };
};

export default useIsValidCoupon;
