import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'modules/UserContext';
import { SIGNUP_ID_COOKIE_NAME } from './constants';

/**
 * Two scenarios can pass this guard:
 * 1. user is not logged in
 * 2. a signupIdCookie is present
 *
 * Scenario two lets us auto-log-in newly signed up users as we send them to
 * the final confirmation step.
 *
 * `user` should never be in a loading state, see `isLoadingUser` in App.js.
 */
const useLoggedInUserGuard = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const [cookies] = useCookies([SIGNUP_ID_COOKIE_NAME]);
  const signupIdCookie = cookies[SIGNUP_ID_COOKIE_NAME];

  const isAllowed = !user || !!signupIdCookie;

  useEffect(() => {
    if (!isAllowed) {
      history.replace('/');
    }
  }, [history, isAllowed]);

  return { isAllowed };
};

export default useLoggedInUserGuard;
