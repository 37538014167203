import React, { FC } from 'react';
import { useSignupData } from 'app/signup_2023/hooks';
import { format } from 'date-fns';
import classNames from 'classnames';
import { timezoneToDisplayName } from 'constants/timezones';
import { flatten, keyBy } from 'lodash';
import { SignupProduct, useGetStarterCurriculumQuery } from 'generated/graphql';
import { PRODUCT_METADATA } from 'app/signup_2023/constants/products';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { ErrorableLoading } from 'components/ui';
import { subtitleTextStyle } from '../../constants';

interface TimeSlotsProps {
  index: number;
  datetime?: string;
  timezone?: string;
}

const TimeSlot: FC<TimeSlotsProps> = ({ datetime, index, timezone }) => {
  const date = datetime
    ? utcToZonedTime(
        zonedTimeToUtc(new Date(datetime), guessTimezoneValue()),
        timezone ?? guessTimezoneValue(),
      )
    : undefined;

  return (
    <div className="text-j-dark-400 text-sm">
      <span className="w-32 inline-block">
        {index === 0 && 'First'}
        {index === 1 && 'Second'}
        {index === 2 && 'Third'} option:
      </span>
      <span className="text-j-dark-600">
        {date ? format(date, `eee, LLL d 'at' haaa`) : '--'}
      </span>
    </div>
  );
};

interface CompleteEnrollment {
  schedulingPreferences: { dates: string[] }[];
  numWeeklyClasses: number;
  subjectId?: string;
}

const Scheduling = () => {
  const { signupData, error, loading } = useSignupData();
  const { data: starterCurriculum } = useGetStarterCurriculumQuery();
  const subjectsById = keyBy(starterCurriculum?.getSubjects, '_id');
  if (!signupData || error || loading) {
    return <ErrorableLoading error={error} />;
  }
  const timezone = signupData.students?.[0]?.timezone || '';
  // todo: validate that these fields are populated upstream somewhere
  const enrollments = (signupData.students?.flatMap(s => s?.enrollments) ||
    []) as CompleteEnrollment[];

  const getTitle = (topic: string, numWeeklyClasses: number, i: number) =>
    `${topic}, Class ${i + 1} of ${numWeeklyClasses} (${numWeeklyClasses} class${
      numWeeklyClasses === 1 ? '' : 'es'
    } / week)`;

  return (
    <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
      <h2
        className={classNames(
          'flex justify-between items-center flex-wrap',
          subtitleTextStyle,
        )}
      >
        Class Schedule Preferences
        {timezone && (
          <span className="text-j-dark-300 text-sm font-normal">
            {timezoneToDisplayName(timezone)}
          </span>
        )}
      </h2>

      <div className="space-y-4">
        {flatten(
          enrollments.map(({ schedulingPreferences, numWeeklyClasses, subjectId }) =>
            schedulingPreferences?.map(({ dates }, i) => {
              const topic =
                (subjectId && subjectsById[subjectId]?.displayName) ||
                PRODUCT_METADATA[SignupProduct.PrivateTutoring].displayName;
              const title = getTitle(topic, numWeeklyClasses, i);
              return (
                <div key={title} className="bg-j-gray-200 rounded-lg p-4">
                  <h3 className="text-sm text-j-dark-600 font-medium m-0 pb-3">
                    {title}
                  </h3>
                  <div className="space-y-2">
                    {dates?.map((date, index) => (
                      <TimeSlot
                        key={date}
                        index={index}
                        datetime={date}
                        timezone={timezone}
                      />
                    ))}
                  </div>
                </div>
              );
            }),
          ),
        )}
      </div>
    </section>
  );
};

export default Scheduling;
