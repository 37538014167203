import { getHydratedProductMetadata } from 'app/signup_2023/constants/products';
import { useSignupData } from 'app/signup_2023/hooks';
import useSignupCoupon from 'app/signup_2023/hooks/useSignupCoupon';
import { ErrorableLoading } from 'components/ui';
import { Coupon, useGetStarterCurriculumQuery } from 'generated/graphql';
import CouponCard from './CouponCard';
import {
  getDiscountedPrice,
  getDiscountDurationText,
  getProductSummary,
} from './utils';

const DiscountedPrice = ({ productPrice }: { productPrice: number }) => (
  <span className="relative text-j-dark-300">
    ${productPrice}
    <span className="absolute top-2.5 left-0 w-full h-px bg-red-600"></span>
  </span>
);

const RecurringPricing = ({ price, coupon }: { price: number; coupon?: Coupon }) => {
  const discountedPrice = getDiscountedPrice(price, coupon);

  return (
    <div>
      <span className="font-medium">
        {coupon && <DiscountedPrice productPrice={price} />}{' '}
        {`$${discountedPrice}/mo${
          coupon ? getDiscountDurationText(price, coupon) : ''
        }`}
      </span>
      . Cancel anytime.
    </div>
  );
};

const ProductSummary = () => {
  const { signupData, loading: loadingSignup } = useSignupData();
  const activeStudent = signupData?.students?.[0];
  const activeEnrollment = activeStudent?.enrollments?.[0];
  const { productName, courseId, numWeeklyClasses } = activeEnrollment || {};
  const { coupon, loading: loadingCoupon } = useSignupCoupon();

  const {
    data,
    error: errorCurriculum,
    loading: loadingCurriculum,
  } = useGetStarterCurriculumQuery({
    variables: { input: { ids: [courseId] } },
    skip: !courseId,
  });
  const course = data?.getCourses?.find(c => c._id === courseId);

  if (loadingCurriculum || loadingSignup || loadingCoupon || errorCurriculum) {
    return <ErrorableLoading error={errorCurriculum} />;
  }

  const { getCourses: courses } = data || {};
  if (courseId && !courses) {
    return (
      <ErrorableLoading error="Unable to fetch curriculum, please try again." />
    );
  }

  if (!productName) {
    return (
      <ErrorableLoading error="Unrecognized product, please contact support." />
    );
  }
  const product = getHydratedProductMetadata(productName, course?.name);
  const productSummary = getProductSummary(product, numWeeklyClasses || 1, course);

  return (
    <div className="flex flex-col gap-2 text-j-dark-600 text-sm">
      {coupon && <CouponCard coupon={coupon} />}
      <div>{productSummary}</div>
      {product.isSubscription ? (
        <RecurringPricing price={product.price} coupon={coupon} />
      ) : (
        <div className="font-medium">{`$${product.price}`}</div>
      )}
    </div>
  );
};

export default ProductSummary;
