import { FC } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import * as R from 'ramda';

import heroImg from 'images/guest_accounts/valid_link_hero_image.png';
import memberCardIcon from 'images/guest_accounts/member_card.svg';

import useGuestAccountLookup from 'app/signup_session/hooks/useGuestAccountLookup';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';

import JuniSpinner from 'components/JuniSpinner';
import { NewButton as Button } from 'core-components';
import { MARKETING_SITE_LINKS } from 'constants/links';
import { SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import CTASection from '../components/CTASection';
import SignupStepsSection from '../components/SignupStepsSection';

const GuestAccountsPage: FC = () => {
  const { signupData, setSignupSession } = useSignupContext();
  const match = useRouteMatch<{ invitationLookupId: string }>();
  const { invitationLookupId } = match.params;
  const { isLoading, isValid, guestAccount } = useGuestAccountLookup(
    invitationLookupId,
  );

  if (isLoading) return <JuniSpinner size={80} />;
  if (!isLoading && !isValid)
    return <Redirect to="/signup?subjects=Computer+Science" />;

  const onClaim = async () => {
    const zonedBirthdate = guestAccount?.birthDate
      ? new Date(guestAccount.birthDate)
      : undefined;
    await setSignupSession({
      invitationLookupId,
      students: [
        {
          ...(signupData.students?.[0] ?? {}),
          ...R.omit(['age'], guestAccount!),
          birthdate: zonedBirthdate
            ? new Date(
                zonedBirthdate.getUTCFullYear(),
                zonedBirthdate.getUTCMonth(),
                zonedBirthdate.getUTCDate(),
              )
            : undefined,
        },
      ],
    });
    window.location.href = `${MARKETING_SITE_LINKS.TUITION_PAGE}${window.location.search}`;
  };

  return (
    <div className="min-h-screen flex flex-col">
      <HeroSection
        content={{
          headline: 'Embark on your learning journey with Juni',
          image: heroImg,
        }}
      />
      <CTASection
        content={{
          headline: `We're excited to help you become a Juni Member! 
          Make sure you’re with a parent to get started.`,
          body: (
            <>
              <strong>Juni Learning</strong> is a membership-driven learning
              experience for kids ages 7-18. Discover your passions with personalized
              1:1 instruction in{' '}
              {Object.values(SUBJECT_DISPLAY_NAMES)
                .map(
                  (subject: string, index: number) =>
                    `${
                      index === Object.values(SUBJECT_DISPLAY_NAMES).length - 1
                        ? 'or '
                        : ''
                    }${subject}`,
                )
                .join(', ')}
              . You'll also get full access to our platform and student community
              (including clubs)!
            </>
          ),
          image: memberCardIcon,
          bgClass: 'bg-juni-green-200',
          callToActionButtons: [
            <Button
              key="start-button"
              className={`  
                w-2/3 
                md:w-auto 
                md:px-10 
                rounded-full 
                border-none
              `}
              onClick={onClaim}
              variant="primary"
              size="large"
            >
              Sign up Now
            </Button>,
          ],
        }}
      />
      <SignupStepsSection
        content={{
          iconColor: 'juni-green-100',
          floatingSection: `Be sure to complete this with a parent!`,
          callToAction: (
            <Button
              onClick={onClaim}
              variant="primary"
              className={`  
                w-2/3 
                md:w-auto 
                md:px-10 
                rounded-full 
                border-none
              `}
              size="large"
            >
              Sign Up Now
            </Button>
          ),
        }}
      />
      <Footer />
    </div>
  );
};

export default GuestAccountsPage;
