import React, { FC, useState } from 'react';
import _ from 'lodash';
import { addDays } from 'date-fns';
import moment, { Moment } from 'moment';

import SpinnerV2 from 'components/SpinnerV2';
import { DatePickerField, ErrorableLoading, ReactSelectField } from 'components/ui';
import { Message, NewButton, TextArea } from 'core-components';

import { useDowngradeStripeSubscriptionMutation } from 'generated/graphql';
import { Course, Student } from 'models';
import { getStudentNamesFromStudents } from 'app/learner/LearnerAccountV2/utils';
import { UPDATE_FLOWS } from 'app/learner/LearnerAccountV2/LearnerAccount';
import { FormState, FormValues } from '../types';
import LearnerPortalModal from '../LearnerPortalModal';
import { DatePickerContainer, selectFieldStyles } from '../styles';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  updateFormValue: (newValue: any, field: any) => void;
  studentsQuery: any;
  coursesQuery: any;
}

const ReduceCourseForm: FC<Props> = ({
  formState,
  formValues,
  updateFormState,
  updateFormValue,
  studentsQuery,
  coursesQuery,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [err, setError] = useState<string | undefined>(undefined);
  const [downgradeSubscription] = useDowngradeStripeSubscriptionMutation();

  const {
    subscription,
    selectedCoursesToRemove,
    classFrequenciesToReduce,
    effectiveDate,
    additionalNotes,
  } = formValues;
  const { updateFlow } = formState;

  const courses = coursesQuery?.data?.getCourses;
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];

  const currentStudents = students.filter((student: Student) =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );

  const studentNames = getStudentNamesFromStudents(currentStudents);

  const currentCourses = currentStudents[0].hasMultipleTracks
    ? currentStudents[0].tracks
    : [currentStudents[0].track];

  const courseOptions = courses
    .filter((course: Course) => currentCourses.includes(course.name))
    .map((course: Course) => ({
      value: course.name,
      label: course.displayName,
    }));

  const handleDowngradeSubmit = async () => {
    if (isSubmitting) return;

    if (
      selectedCoursesToRemove &&
      selectedCoursesToRemove.length === courseOptions.length
    ) {
      setError(
        "If you'd like to remove all courses, please pause or cancel your subscription",
      );
      return;
    }

    setError(undefined);
    setIsSubmitting(true);
    const downgradeSubscriptionInput = {
      subscriptionId: subscription?.id || '',
      downgradeOption: _.findKey(UPDATE_FLOWS, key => key === updateFlow),
      coursesToRemove: selectedCoursesToRemove,
      classFrequenciesToReduce,
      effectiveDate,
      additionalNotes,
    };
    try {
      await downgradeSubscription({
        variables: {
          input: downgradeSubscriptionInput,
        },
      });
      updateFormState('downgrade_confirmation', 'modal');
    } catch (err) {
      if (err instanceof Error) {
        setSubmitError(err.toString());
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormComplete =
    effectiveDate && selectedCoursesToRemove && selectedCoursesToRemove.length;

  return (
    <LearnerPortalModal
      updateFormState={updateFormState}
      formState={formState}
      title="Downgrade Subscription"
      renderPrimaryButton={
        <NewButton onClick={handleDowngradeSubmit} disabled={!isFormComplete}>
          {isSubmitting ? <SpinnerV2 /> : <div className="font-medium">Submit</div>}
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            window.open(`mailto:support@learnwithjuni.com`, '_blank');
          }}
          variant="secondary"
        >
          Email Support
        </NewButton>
      }
    >
      <div className="flex flex-col text-sm text-j-dark-600 gap-2">
        {err && (
          <div className="w-full">
            <ErrorableLoading error={err} />
          </div>
        )}
        <ReactSelectField
          className="w-full"
          label={`Please indicate which course(s) you would like to remove for ${studentNames}`}
          name="selectedCoursesToRemove"
          placeholder="Choose a course..."
          value={formValues.selectedCoursesToRemove}
          onChange={value => updateFormValue([value], 'selectedCoursesToRemove')}
          onChangeMulti={updateFormValue}
          options={courseOptions}
          isMulti
          styles={selectFieldStyles}
        />
        <DatePickerContainer className="flex flex-row">
          <DatePickerField
            placeholderText="MM/DD/YYYY"
            css={`
              input {
                height: 42px;
                color: #292563;
                font-size: 13px;
                ::placeholder {
                  color: #f7fafc;
                }
              }
            `}
            className="full-width"
            label="Effective Downgrade Date:"
            dateFormat="MM/DD/YY"
            value={
              formValues.effectiveDate ? moment(formValues.effectiveDate) : undefined
            }
            name="effectiveDate"
            minDate={moment(addDays(new Date(), 1))}
            onDateChange={(newDate: Moment | undefined) => {
              if (!newDate || newDate < moment(new Date())) {
                updateFormValue(undefined, 'effectiveDate');
              } else {
                updateFormValue(newDate.format('MM/DD/YY'), 'effectiveDate');
              }
            }}
          />
        </DatePickerContainer>
        <div>
          Anything else you’d like us to know as we process this request? We take all
          your feedback to heart and use it to improve our service.
        </div>
        <TextArea
          placeholder="Leave your feedback here..."
          value={formValues.additionalNotes}
          name="additionalNotes"
          fullWidth
          onChange={e => updateFormValue(e.target.value, 'additionalNotes')}
        />
        <div className="font-bold mb-1">Pricing</div>
        <div>
          {`Since ${studentNames} is already taking classes with us, please reference our `}
          <a
            href="https://junilearning.com/tuition"
            target="_blank"
            rel="noopener noreferrer"
          >
            tuition page
          </a>{' '}
          {` to understand the updated monthly cost.`}
        </div>
        {submitError && (
          <Message status="error">
            Error downgrading your subscription. Please contact Juni HQ if this error
            persists.
          </Message>
        )}
      </div>
    </LearnerPortalModal>
  );
};

export default ReduceCourseForm;
