import React from 'react';
import { format } from 'date-fns';
import { Draggable } from 'react-beautiful-dnd';
import { Icon } from 'core-components';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { utcToZonedTime } from 'date-fns-tz';

interface Props {
  date?: string;
  timezone: string;
  index: number;
}

const SchedulingOption = ({ date, timezone, index }: Props) => {
  const guessedTz = guessTimezoneValue();
  const formatted = date
    ? format(utcToZonedTime(date, timezone || guessedTz), 'eeee, h:mm a')
    : '--';

  return (
    <Draggable draggableId={`${date}-${index}`} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          className="w-full lg:w-44 bg-white p-1 rounded-lg flex items-center hover:shadow-2"
          {...provided.draggableProps}
        >
          <div
            {...provided.dragHandleProps}
            className="w-8 h-8 rounded-md flex items-center justify-center hover:bg-j-purple-100"
          >
            <Icon.DragHandle className="text-j-dark-400 w-2 h-3" />
          </div>
          <p className="pl-2 text-j-dark-600 text-sm leading-6 m-0">{formatted}</p>
        </div>
      )}
    </Draggable>
  );
};

export default SchedulingOption;
