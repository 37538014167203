import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import juniAxios from 'services/axios';
import moment from 'moment-timezone';
import queryString from 'query-string';
import styled, { ServerStyleSheet } from 'styled-components';
import _ from 'lodash';

import {
  ACUITY_APPOINTMENT_TYPES_TO_LOG_SUBJECTS_COVERED_IN_SESSION_NOTES,
  ACUITY_APPOINTMENT_TYPES_TO_LOG_COURSES_COVERED_IN_SESSION_NOTES,
} from 'constants/acuity';
import { tutoringSubjectValueToLabel } from 'constants/tutoring';

import {
  ModuleSectionProgress,
  Student,
  StudentSession,
  TeacherSession,
} from 'models';

import { deleteTeacherSessionLog } from 'services/learner/teacher_sessions';
import { formatToDefaultDatetimeWithTimezone } from 'utils/dates';
import { cleanCourseDisplayNameForLearnerAudience } from 'utils/cleanCourseDisplayNameForLearnerAudience';
import { SearchSelectProps } from 'components/SearchSelect';
import { getInstructorByUserId } from 'services/instructor/instructor_by_user_id';

import { SUBJECT_TYPE } from 'constants/subjects';
import { NewCard } from 'core-components';
import useIsMounted from 'hooks/useIsMounted';
import {
  useGetPostSessionDashboardEmailHtmlMutation,
  useSendPostSessionEmailMutation,
  GetCoursesForCourseSelectionReturnFragment,
  useGetCoursesForCourseSelectionQuery,
} from 'generated/graphql';
import { renderToStaticMarkup } from 'react-dom/server';
import MarkdownText from 'components/MarkdownText';
import {
  getInstructionsAndPlaceholder,
  SESSION_STATE_DESCRIPTION,
} from './constants';

import {
  SessionFeedback,
  NoteText,
  NoteHeader,
  TextEditor,
  CourseProgress,
  NotesFromHQ,
} from './subcomponents';

interface SessionNoteProps extends RouteComponentProps {
  getTeacherSessions: () => Promise<void>;
  teacherSession?: TeacherSession;
  session: StudentSession;
  subjectName: SUBJECT_TYPE;
  teacherMode: boolean;
  moduleSectionInfo: any;
  moduleSectionProgresses: ModuleSectionProgress[];
  instructorNameLookup: Record<string, string>;
  idLookup: any;
  userId: string;
  userIsAdmin: boolean;
  student: Student;
}

const StatusBar = styled.div<any>`
  padding: 16px 0px;
  > div {
    border-left: ${props =>
      props.teacherMode && props.isIncomplete && '4px solid #ea606f'};
  }
`;

// This function takes the HTML of the email created on the back-end and
// adds in the session notes formatted with front-end Markdown component
// (both the generated HTML and the associated styled-components styles).
const insertSessionNoteMarkdownIntoEmail = (emailHtml: string, notes: string) => {
  const sheet = new ServerStyleSheet();

  const html = emailHtml.replace(
    '<!-- Session Notes Content -->',
    renderToStaticMarkup(
      sheet.collectStyles(<MarkdownText text={notes} displayAsPlainText={false} />),
    ),
  );
  const css = sheet.getStyleTags();
  return html.replace('<!-- Session Notes Styles -->', css);
};

const SessionNote: FC<SessionNoteProps> = ({
  getTeacherSessions,
  teacherSession,
  session,
  subjectName,
  teacherMode,
  moduleSectionInfo,
  moduleSectionProgresses,
  instructorNameLookup,
  idLookup,
  userId,
  userIsAdmin,
  student,
  location,
}) => {
  const isMounted = useIsMounted();
  const [editing, setEditing] = useState(false);
  const [teacherSessionInstructor, setTeacherSessionInstructor] = useState<
    SearchSelectProps['value'] | undefined
  >();
  const [
    initialTeacherSessionInstructor,
    setInitialTeacherSessionInstructor,
  ] = useState<SearchSelectProps['value'] | undefined>();

  const [getEmailHtml] = useGetPostSessionDashboardEmailHtmlMutation();
  const [sendPostSessionEmail] = useSendPostSessionEmailMutation();

  const requireSubjectsCoveredLogging = ACUITY_APPOINTMENT_TYPES_TO_LOG_SUBJECTS_COVERED_IN_SESSION_NOTES.includes(
    session.appointmentTypeID,
  );
  const requireCoursesCoveredLogging = ACUITY_APPOINTMENT_TYPES_TO_LOG_COURSES_COVERED_IN_SESSION_NOTES.includes(
    session.appointmentTypeID,
  );

  // Grab all courses from Courses collection if we require Courses Covered Logging
  const { data } = useGetCoursesForCourseSelectionQuery({
    variables: {
      input: { schedulingFormat: 'private' },
    },
    skip: !requireCoursesCoveredLogging,
  });

  const { courseOptions, courseLookup } = useMemo(() => {
    if (requireCoursesCoveredLogging) {
      // Extract courses from data and sort alphabetically
      const allCourses: GetCoursesForCourseSelectionReturnFragment[] = [
        ...(data?.getCourses || []),
      ].sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Map student's active and completed course names to course objects
      const activeCourseNames = (student?.hasMultipleTracks
        ? student.tracks || []
        : [student.track]
      ).filter(x => !!x);
      const completedCourseNames = student?.completedTracks?.filter(x => !!x) || [];
      const courseNameToCourse = _.keyBy(allCourses, 'name');
      const mapCourseNamesToCourses = (courseNames: string[]) =>
        courseNames
          .map(courseName => courseNameToCourse[courseName])
          .filter(x => !!x);
      const activeCourses = mapCourseNamesToCourses(activeCourseNames);
      const completedCourses = mapCourseNamesToCourses(completedCourseNames);

      // dedupe the list of allCourses so we don't show the same course twice in the dropdown
      const activeAndCompletedCourseIds = [
        ...activeCourses,
        ...completedCourses,
      ].map(course => String(course._id));
      const allOtherCourses = allCourses.filter(
        course => !activeAndCompletedCourseIds.includes(String(course._id)),
      );

      // Convert all courses to options that can be fed into ReactSelectField
      const coursesToOptions = (
        courses: GetCoursesForCourseSelectionReturnFragment[],
      ) => courses.map(course => ({ value: course._id, label: course.displayName }));
      const courseOptions = [
        { label: 'ACTIVE COURSES', options: coursesToOptions(activeCourses) },
        { label: 'COMPLETED COURSES', options: coursesToOptions(completedCourses) },
        {
          label: 'ALL OTHER COURSES (ALPHABETICAL)',
          options: coursesToOptions(allOtherCourses),
        },
      ];

      // Finally create a lookup table for all courses
      const courseLookup = _.keyBy(allCourses, '_id');

      return { courseOptions, courseLookup };
    }
    return { courseOptions: [], courseLookup: {} };
  }, [data?.getCourses, requireCoursesCoveredLogging, student]);

  const subjectsCovered = teacherSession?.subjectsCovered || [];
  const coursesCovered = teacherSession?.coursesCovered || [];

  const coursesCoveredDisplayNames = coursesCovered.map(courseId => {
    // If being viewed in the learner portal, don't surface courses for which we can't find the display name
    const displayName =
      courseLookup[courseId]?.displayName ||
      (teacherMode ? `unknown courseId: ${courseId}` : '');

    if (!teacherMode) {
      return cleanCourseDisplayNameForLearnerAudience(displayName);
    }
    return displayName;
  });

  const curTeacherUserId = teacherSession?.teacherId;
  useEffect(() => {
    const instructorUserId = curTeacherUserId;
    const getInstructor = async () => {
      try {
        const { data } = await getInstructorByUserId(instructorUserId);
        const selectedTeacherOpt = {
          value: data._id,
          label: `${data.firstName} ${data.lastName}`,
        };
        setInitialTeacherSessionInstructor(selectedTeacherOpt);
        setTeacherSessionInstructor(selectedTeacherOpt);
      } catch (error) {
        console.error(error);
      }
    };
    if (teacherMode && instructorUserId) {
      getInstructor();
    } else {
      setTeacherSessionInstructor(undefined);
      setInitialTeacherSessionInstructor(undefined);
    }
  }, [curTeacherUserId, teacherMode]);

  const sendEmail = async (notes: string) => {
    // several sessions do not have a teacher session object (eg - bootcamps)
    // skip sending the email in this case
    if (!teacherSession) return;

    const emailHtml = await getEmailHtml({
      variables: { input: { teacherSessionId: teacherSession._id } },
    });

    const html =
      emailHtml.data?.getPostSessionDashboardEmailHtml.dashboardPreviewEmail.html;

    if (html) {
      const htmlWithSessionNote = insertSessionNoteMarkdownIntoEmail(html, notes);

      await sendPostSessionEmail({
        variables: {
          input: {
            html: htmlWithSessionNote,
            teacherSessionId: teacherSession._id,
          },
        },
      });
    }
  };

  const saveNotes = async (
    notes: string,
    sessionWasMissed: boolean,
    subjectsCovered?: string[],
    coursesCovered?: string[],
  ) => {
    const { search } = location;
    const params = queryString.parse(search);
    const studentId = params.id;
    const url = editing
      ? `/api_teacher/teacher_session_notes/${teacherSession?._id}`
      : '/api_teacher/teacher_session_notes';
    const method = editing ? 'patch' : 'post';
    const instructorUserId = editing ? teacherSessionInstructor?.value : userId;
    const data: any = {
      notes,
      wasMissed: sessionWasMissed,
      teacherId: instructorUserId,
    };
    if (subjectsCovered) {
      data.subjectsCovered = subjectsCovered;
    }
    if (coursesCovered) {
      data.coursesCovered = coursesCovered;
    }

    await juniAxios[method](
      url,
      editing
        ? data
        : {
            studentId,
            acuityId: teacherSession?.acuityId,
            ...data,
          },
    );

    // At this point the submission is successful.
    // However, hold (await) the editor in a loading/pending state.
    // Errors are swallowed here because:
    // 1) Emails failures should be silent (separate BE/admin process to resend those)
    // 2) Refetching isn't an error in saving notes and should be handled elsewhere.  But
    //    awaiting refetch finishing should also give the UI a clean update upon closing the editor.
    await Promise.all([
      editing || sessionWasMissed
        ? Promise.resolve()
        : sendEmail(notes).catch((error: any) =>
            console.error('Error sending session notes email', {
              error,
              teacherSession,
            }),
          ),
      getTeacherSessions().catch((error: any) =>
        console.error('Error refetching teacher sessions email', error),
      ),
    ]);

    if (editing && isMounted()) {
      setEditing(false);
    }
  };

  const deleteSessionNote = async () => {
    if (
      window.confirm(
        'Are you sure you wish to delete this missed log? This action cannot be undone.',
      )
    ) {
      try {
        await deleteTeacherSessionLog(teacherSession?.acuityId);
        await getTeacherSessions();
      } catch (error) {
        console.log(`Failed to delete missed log. Error: ${error}`);
      }
    }
  };

  const sessionType = session.appointmentTypeData.displayName || '';

  const sessionDatetime = formatToDefaultDatetimeWithTimezone(
    session.datetime,
    !teacherMode ? session.timezone : '',
  );
  const instructorName = teacherSession
    ? instructorNameLookup[teacherSession.teacherId]
    : '';

  const isUpcoming = moment(session.datetime).isAfter(moment());
  const isIncomplete =
    !isUpcoming &&
    (!teacherSession || (!teacherSession.completed && !teacherSession.wasMissed));
  const numDaysSinceLogged = moment().diff(
    moment(teacherSession?.end || ''),
    'days',
  );

  const { instructions, placeholder } = getInstructionsAndPlaceholder(subjectName);

  const showSessionNotesEditor =
    teacherMode &&
    teacherSession?.end &&
    // initially show for sessions not completed, otherwise show in edit mode
    (!teacherSession.completed || editing);

  return (
    <StatusBar teacherMode={teacherMode} isIncomplete={isIncomplete}>
      <NewCard>
        <NoteHeader
          deleteSessionNote={deleteSessionNote}
          isUpcoming={isUpcoming}
          sessionType={sessionType}
          teacherMode={teacherMode}
          teacherSession={teacherSession}
          userIsAdmin={userIsAdmin}
          editing={editing}
          onEditClick={() => {
            setEditing(prev => !prev);
            setTeacherSessionInstructor(initialTeacherSessionInstructor);
          }}
          sessionNotesInstructor={teacherSessionInstructor}
          setSessionNotesInstructor={setTeacherSessionInstructor}
          instructorName={instructorName}
          sessionDatetime={sessionDatetime}
        />
        <NewCard.Divider />
        <div className="flex flex-col gap-12 pt-6">
          {/* Session Notes */}
          {!editing && (
            <div>
              {!teacherSession?.wasMissed && subjectsCovered.length > 0 && (
                <div className="text-sm text-j-dark-400 mb-4">
                  {`Subjects Covered: ${subjectsCovered
                    .map(s => tutoringSubjectValueToLabel(s))
                    .join(', ')}`}
                </div>
              )}
              {!teacherSession?.wasMissed && coursesCovered.length > 0 && (
                <div className="text-sm text-j-dark-400 mb-4">
                  {`Courses Covered: ${coursesCoveredDisplayNames.join(', ')}`}
                </div>
              )}
              <NoteText
                isUpcoming={isUpcoming}
                teacherMode={teacherMode}
                teacherSession={teacherSession}
              />
            </div>
          )}
          {/* Session Notes Editor */}
          {showSessionNotesEditor && (
            <TextEditor
              checkboxLabel={SESSION_STATE_DESCRIPTION.missed}
              instructions={instructions}
              placeholder={placeholder}
              requireSubjectsCoveredLogging={requireSubjectsCoveredLogging}
              requireCoursesCoveredLogging={requireCoursesCoveredLogging}
              initialText={teacherSession.notes || ''}
              initialIsCheckboxSelected={teacherSession.wasMissed}
              initialSubjectsCoveredSelected={subjectsCovered}
              initialCoursesCoveredSelected={coursesCovered}
              clearTextOnDisabled
              submit={saveNotes}
              cancellable={editing}
              onCancel={() => setEditing(false)}
              editing={editing}
              isEditingValueChange={
                initialTeacherSessionInstructor?.value !==
                teacherSessionInstructor?.value
              }
              courseOptions={courseOptions}
            />
          )}
          {/* Notes From HQ  & Progress */}
          {teacherSession?.end && (
            <>
              <CourseProgress
                teacherSession={teacherSession}
                idLookup={idLookup}
                sessionSubject={subjectName}
                student={student}
                moduleSectionProgresses={moduleSectionProgresses}
                moduleSectionInfo={moduleSectionInfo}
                teacherMode={teacherMode}
              />
              <NotesFromHQ teacherSession={teacherSession} />
            </>
          )}
          {/* Session Feedback */}
          {!teacherMode && teacherSession && (
            <SessionFeedback
              teacherSession={teacherSession}
              numDaysSinceLogged={numDaysSinceLogged}
              instructorName={instructorName}
            />
          )}
        </div>
      </NewCard>
    </StatusBar>
  );
};

export default SessionNote;
