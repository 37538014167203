// sync with juni-app-backend/server/app/signup_flow/constants/tutoring_courses.ts
export const TUTORING_COURSE_OPTIONS = [
  {
    label: 'Coding',
    options: [
      { label: 'Scratch', value: 'scratch' },
      { label: 'Python', value: 'python' },
      { label: 'Java', value: 'java' },
      { label: 'Javascript', value: 'javascript' },
      { label: 'C++', value: 'c_plus_plus' },
      { label: 'AP Computer Science', value: 'ap_cs' },
    ],
  },
  {
    label: 'Math',
    options: [
      {
        label: 'Early Elementary Math: Numbers, Operations, and Measurement',
        value: 'math_foundations',
      },
      {
        label: 'Late Elementary Math: Multiplication, Division, and Geometry',
        value: 'math_applications',
      },
      { label: 'Pre-Algebra', value: 'pre_algebra' },
      { label: 'Algebra 1', value: 'algebra_1' },
      { label: 'Algebra 2', value: 'algebra_2' },
      { label: 'Geometry', value: 'geometry' },
      { label: 'Pre-Calculus & Trigonometry', value: 'pre_calc_trig' },
      { label: 'AP Calculus', value: 'ap_calculus' },
    ],
  },
  {
    label: 'Test Prep',
    options: [
      { label: 'SAT', value: 'sat' },
      { label: 'ACT', value: 'act' },
    ],
  },
];
