import React, { useState } from 'react';
import { Arrow } from 'components/Icons';
import { Card, NewButton as Button } from 'core-components';
import { IconRenderProps } from 'core-components/NewButton/Button';
import { useSignupData } from 'app/signup_2023/hooks';
import { ReactSelectField } from 'components/ui';
import InputField from 'app/signup_session/components/InputField';
import { TUTORING_COURSE_OPTIONS } from 'app/signup_2023/constants/tutoring_courses';
import { isTutoringCourseRequiresMoreInfo } from 'app/signup_2023/utils/tutoring_courses';
import { SignupProduct } from 'generated/graphql';
import { headerTextStyles, labelTextStyles } from '../../constants';

interface LocalState {
  tutoringCourse: string;
  tutoringDetails: string;
}

const TutoringCourseSelection = () => {
  const { signupData, updateSignup, loading, goToPrevStep } = useSignupData();
  const activeStudent = signupData?.students?.[0];
  const activeEnrollment = activeStudent?.enrollments?.[0];

  const [localState, setLocalState] = useState<LocalState>({
    tutoringCourse: activeEnrollment?.tutoringCourse || '',
    tutoringDetails: activeEnrollment?.tutoringDetails || '',
  });

  const onLocalStateChange = (newData: Partial<typeof localState>) => {
    setLocalState({ ...localState, ...newData });
  };

  // formats localState for persistence
  const localStateToPartialEnrollment = () => ({
    ...localState,
    productName: SignupProduct.PrivateTutoring,
  });

  const isValid = () => {
    const { tutoringCourse, tutoringDetails } = localState;
    const requiresMoreTutoringInfo = isTutoringCourseRequiresMoreInfo(
      tutoringCourse,
    );
    return !!tutoringCourse && (!requiresMoreTutoringInfo || !!tutoringDetails);
  };

  const onSubmit = async () => {
    const enrollments = [localStateToPartialEnrollment()];
    if (signupData && !loading && isValid) {
      updateSignup({ students: [{ ...activeStudent, enrollments }] });
    }
  };

  return (
    <Card
      className="w-full mx-auto border-none sm:max-w-screen-xs sm:rounded-lg"
      hideOverflow={false}
      noRounding
    >
      <h1 className={headerTextStyles}>
        In what subject does your child need tutoring?
      </h1>
      <p className="text-j-dark-300 m-0 text-base">
        You can request tutoring for additional subjects after the first session.
      </p>

      <div className="flex flex-col space-y-2">
        <label className={labelTextStyles}>Tutoring Subject</label>
        <ReactSelectField
          options={TUTORING_COURSE_OPTIONS}
          value={localState.tutoringCourse}
          onChange={item => onLocalStateChange({ tutoringCourse: item as string })}
          placeholder="Select a subject..."
        />
        <InputField
          labelTextStyles={labelTextStyles}
          value={localState.tutoringDetails}
          type="textarea"
          label={`${
            isTutoringCourseRequiresMoreInfo(localState.tutoringCourse)
              ? 'Level of Experience'
              : 'Anything else we should know?'
          }`}
          helperText={
            isTutoringCourseRequiresMoreInfo(localState.tutoringCourse)
              ? 'Please include what experience your child has with coding, including months of experience, platforms used, and topics covered.'
              : "Please include any other details to help us best understand your child's tutoring needs."
          }
          onChange={e => onLocalStateChange({ tutoringDetails: e.target.value })}
        />
      </div>
      <div className="w-full flex flex-row space-x-4 pt-4">
        <Button
          className="h-12 w-1/3"
          variant="secondary"
          onClick={goToPrevStep}
          renderIconLeft={(props: IconRenderProps) => (
            <Arrow {...props} orientation="left" />
          )}
        >
          Back
        </Button>
        <Button
          className="h-12 w-2/3"
          onClick={onSubmit}
          disabled={loading || !isValid()}
        >
          Continue
        </Button>
      </div>
    </Card>
  );
};

export default TutoringCourseSelection;
