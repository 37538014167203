import {
  addWeeks,
  differenceInWeeks,
  isBefore,
  startOfDay,
  subWeeks,
} from 'date-fns';
import { DAYS_NEEDED_TO_ONBOARD } from './constants';

/**
 * Add or subtract weeks from `input` to meet this condition:
 *
 * `input` >= `comparedTo` and `input` === 0 weeks ahead of `comparedTo`
 *
 * Note: day and time should not change.
 */
const getFirstDayAfterDate = (input: Date, comparedTo: Date) => {
  const deltaWeeks = Math.abs(differenceInWeeks(input, comparedTo));
  if (!isBefore(input, comparedTo) && deltaWeeks === 0) {
    return input;
  }

  if (isBefore(input, comparedTo)) {
    return addWeeks(input, deltaWeeks + 1);
  }
  return subWeeks(input, deltaWeeks);
};

/**
 * Update each selected time slot as necessary to be >= `earliestStartingDate`.
 * each `date` represents a user's desired day, hour, and minute. so:
 * 1. zero out seconds and ms
 * 2. ensure it's the first instance of that day that's >= `earliestStartingDate`
 */
export const adjustStartingDates = (dates: string[], earliestStartingDate: Date) =>
  dates
    .filter(date => !!date)
    .map(date => {
      const preferredSlot = new Date(new Date(date).setSeconds(0, 0));
      const newDate = getFirstDayAfterDate(preferredSlot, earliestStartingDate);
      return newDate.toJSON();
    });

// Compare dates, ignore times
export const validateStartingDate = (
  selectedDate: Date,
  firstAvailableDate: Date,
) => {
  const isError = isBefore(startOfDay(selectedDate), startOfDay(firstAvailableDate));
  return isError
    ? `We need at least ${DAYS_NEEDED_TO_ONBOARD} days from today to set up your classes.`
    : '';
};
