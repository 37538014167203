import React, { FC, useState } from 'react';

import CardHeader from 'components/CardHeader';
import { NotesIcon } from 'components/Icons';
import { Icon } from 'core-components';
import { GetSubjectsReturnFragment } from 'generated/graphql';
import { FilterDropdownWrapper, SessionTypeList } from './styles';

interface FilterDropdownProps {
  subjects: GetSubjectsReturnFragment[];
  handleClick: (subjectName?: string) => void;
}

interface SessionNotesFilterProps extends FilterDropdownProps {
  studentFirstName?: string;
  selectedSubjectName?: string;
}

const FilterDropdown: FC<FilterDropdownProps> = ({ subjects, handleClick }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <FilterDropdownWrapper
      dropdownOpen={dropdownOpen}
      onClick={() => {
        setDropdownOpen(prevDropdownOpen => !prevDropdownOpen);
      }}
    >
      <Icon.Filter className="h-4 w-4 p-1.5" />
      {dropdownOpen && (
        <SessionTypeList>
          <li key="dropdown-all-session">
            <button onClick={() => handleClick(undefined)}>All Sessions</button>
          </li>
          {subjects.map(subject => (
            <li key={`dropdown-${subject?.name}`}>
              <button onClick={() => handleClick(subject?.name)}>
                {subject.displayName} Sessions
              </button>
            </li>
          ))}
        </SessionTypeList>
      )}
    </FilterDropdownWrapper>
  );
};

const SessionNotesFilterHeader: FC<SessionNotesFilterProps> = ({
  subjects,
  handleClick,
  studentFirstName,
  selectedSubjectName,
}) => {
  const title = [
    studentFirstName ? `${studentFirstName}'s past` : 'Past',
    selectedSubjectName
      ? subjects.find(subject => subject.name === selectedSubjectName)?.displayName
      : '',
    'session notes',
  ]
    .filter(string => string)
    .join(' ');
  return (
    <CardHeader icon={<NotesIcon />} title={title}>
      {subjects.length > 1 && (
        <FilterDropdown subjects={subjects} handleClick={handleClick} />
      )}
    </CardHeader>
  );
};

export default SessionNotesFilterHeader;
