import React, { FC } from 'react';
import { Icon, NewButton } from 'core-components';
import { QueryResult } from '@apollo/client';
import { LoadStudentsByParentIdsQuery } from 'generated/graphql';
import { Link } from 'react-router-dom';
import { FormState } from '../types';
import LearnerPortalModal from '../LearnerPortalModal';

interface Props {
  formState: FormState;
  updateFormState: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
}

const ConfirmRequestToChangeInstructor: FC<Props> = ({
  formState,
  updateFormState,
  studentsQuery,
}) => {
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const studentId = students[0]._id;
  return (
    <LearnerPortalModal
      title="Update Instructor Request Confirmation"
      updateFormState={updateFormState}
      formState={formState}
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            updateFormState('', 'modal');
          }}
        >
          <div className="font-medium">Close</div>
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 leading-6 gap-2 items-center">
        <Icon.CheckboxCircle className="text-j-green-600 h-28 w-28" />
        <div className="font-medium">We have received your request!</div>
        <div className="text-j-dark-400 text-center">
          A Juni Support Agent will reach out in the next few days on more details.
          To check progress on your request, please visit "Transition Requests" in
          the{' '}
          <Link
            to={`/learner/${studentId}/parent_corner`}
            className="text-j-dark-500 font-medium"
          >
            Parent Corner
          </Link>
          .
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default ConfirmRequestToChangeInstructor;
