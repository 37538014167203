import React, { useContext, useEffect, useState } from 'react';
import { format, add } from 'date-fns';
import moment from 'moment-timezone';
import PortalSection from 'components/PortalSection';
import {
  JuniClubType,
  useGetClubInvitationsQuery,
  useGetJuniClubsByIdsQuery,
  useGetCommunityEventsByStartDatesLazyQuery,
} from 'generated/graphql';
import styled from 'styled-components/macro';
import useClubStore, { ClubStoreType } from 'app/clubs/stores/ClubStore';
import UserContext from 'modules/UserContext';
import UsernameCreator from 'components/UsernameCreator';
import {
  ClubInvitesSection,
  ClubNotificationsSection,
  ConductModal,
  MyClubsZeroState,
} from 'components/clubs';
import _ from 'lodash';
import { MAX_CLUB_MEMBERSHIPS_GUEST } from 'constants/clubs';
import { MsgType } from 'app/clubs/MyClubsTypes';
import { usePubnubFetchMessagesRaw } from 'app/clubs/stores/ClubStoreHelpers';
import JuniSpinner from 'components/JuniSpinner';
import { Card } from 'core-components';
import { Link } from 'react-router-dom';
import { Chevron } from 'components/Icons';
import { DEFAULT_TIMEZONE } from 'constants/timezones';
import ClubsBanner from '../MyClubsZeroState/assets/guest-header.jpg';
import EventsNotificationCard from '../EventsNotificationCard';

const TOTAL_CARD_SLOTS = 3;
const NUM_DAYS_OF_EVENTS_TO_SHOW = 14;

interface ClubSectionProps {
  studentId?: string;
  instructorUserId?: string;
  existingUsername?: string;
  isVertical?: boolean;
  myClubsPage?: boolean;
  isBanned?: boolean;
}

const ClubCardsContainerSingleCard = styled.div.attrs({ className: 'flex' })``;
const ClubCardsContainerHorizontal = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(3, 1.33fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 2fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(1, 4fr);
  }
`;
const ClubCardsContainerVertical = styled.div<any>`
  > * {
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
`;

const ClubCardsContainer: React.FC<{
  isVertical?: boolean;
  children?: React.ReactNode;
  isSingleCard?: boolean;
}> = ({ isVertical, children, isSingleCard }) => {
  if (isSingleCard) {
    return <ClubCardsContainerSingleCard>{children}</ClubCardsContainerSingleCard>;
  }
  return isVertical ? (
    <ClubCardsContainerVertical>{children}</ClubCardsContainerVertical>
  ) : (
    <ClubCardsContainerHorizontal>{children}</ClubCardsContainerHorizontal>
  );
};

const NoNewNotificationsClubPage = () => (
  <div className="flex flex-col flex-grow items-center border-1.5 border-solid bg-j-gray-100 border-j-purple-300 rounded-lg px-3 py-10">
    <div className="mb-4 text-xl font-bold text-j-dark-400">
      No new notifications!
    </div>

    <div className="text-blue-gray-400">
      Notifications for your Clubs will appear here.
    </div>
  </div>
);
const NoNewNotifications = () => (
  <Card
    className="flex-1"
    boxShadow
    borderWidth="2"
    padding="6"
    bannerImgSrc={ClubsBanner}
    contentClassName="flex flex-col justify-center"
    backgroundColor="eggshell-blue"
    borderColor="juni-blue-100"
  >
    <h3 className="text-center p-0 m-0 text-blue-gray-600">
      Join another Juni Club!
    </h3>
    <div className="text-center text-sm">
      Find another Club that's chatting about a topic you're interested in.
    </div>
    <div className="flex flex-row justify-center items-center pt-8 pb-1">
      <Link to="my_clubs" className="no-underline">
        <span className="font-bold text-center text-juni-blue-500 cursor-pointer">
          Browse Clubs
        </span>
      </Link>
      <Chevron className="w-2/4" orientation="right" />
    </div>
  </Card>
);

const ClubSection: React.FC<ClubSectionProps> = ({
  studentId,
  instructorUserId,
  existingUsername,
  isVertical,
  myClubsPage,
  isBanned,
}) => {
  const timezone = moment.tz.guess() || DEFAULT_TIMEZONE;

  const [
    getEvents,
    { data: eventsData, loading: loadingEvents },
  ] = useGetCommunityEventsByStartDatesLazyQuery();

  const events = eventsData?.getCommunityEventsByStartDates?.items || [];
  const totalNumEvents = events.filter(event => !event.archivedAt).length;

  useEffect(() => {
    const startDates = new Array(NUM_DAYS_OF_EVENTS_TO_SHOW)
      .fill('')
      .map((item, index) =>
        format(add(new Date(), { days: index }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      );
    getEvents({
      variables: {
        startDates,
        timezone,
      },
    });
  }, [getEvents, timezone]);

  const { data: clubInvites, loading: loadingInvites } = useGetClubInvitationsQuery({
    fetchPolicy: 'no-cache',
  });
  const invites = clubInvites?.getClubInvitations?.items || [];
  const { data: clubsData, loading: loadingInviteClubs } = useGetJuniClubsByIdsQuery(
    {
      skip: !clubInvites,
      variables: {
        ids:
          clubInvites?.getClubInvitations?.items?.map(invite => invite.juniClubId) ||
          [],
      },
    },
  );
  const clubsFromInvites = clubsData?.getJuniClubsByIds?.items || [];

  const filteredInvites = invites.filter(invite => {
    const juniClub = clubsFromInvites?.find(club => club._id === invite.juniClubId);
    const inviteValid = !invite?.acceptedAt && !invite?.rejectedAt;
    const matchesStudent =
      inviteValid && studentId && invite?.inviteeStudentId?.toString() === studentId;
    const matchesParent =
      inviteValid &&
      studentId &&
      !invite?.inviteeStudentId &&
      invite?.inviteeParentId;
    const matchesInstructor =
      inviteValid && invite?.inviteeInstructorUserId === instructorUserId;
    return juniClub && (matchesStudent || matchesParent || matchesInstructor);
  });

  // Below variables exist to manage the username state: which we force someone to set if accepting an invite.
  const [username, setUsername] = useState(existingUsername);
  const [selectedClubToJoin, setSelectedClubToJoin] = useState<JuniClubType>();

  const juniClubs = useClubStore((state: ClubStoreType) => state.juniClubs);
  const { user } = useContext(UserContext);
  const canJoin = !(
    user?.isGuest && _.keys(juniClubs).length >= MAX_CLUB_MEMBERSHIPS_GUEST
  );
  useEffect(() => {
    setUsername(existingUsername);
  }, [existingUsername]);

  // below is the notifications/message fetching logic for notification cards
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [previewMessagesByClub, setPreviewMessagesByClub] = useState<
    Record<string, MsgType[]>
  >({});

  const unreadMessages = useClubStore(state => state.unreadMessages);
  const isClubsLoading = useClubStore(state => state.isClubsLoading);
  const juniClubsArray = _.values(juniClubs);
  const unreadMessagesByClub: Record<string, number> = {};
  juniClubsArray.forEach(jc => {
    unreadMessagesByClub[jc._id] = _.sum(_.values(unreadMessages[jc._id]));
  });
  const pubnubFetchMessagesRaw = usePubnubFetchMessagesRaw();

  useEffect(() => {
    if (isClubsLoading) {
      return;
    }
    const getTopUnreadChannel = (juniClub: JuniClubType): string[] => {
      const unreadByChannel = unreadMessages[juniClub._id] || {};
      const channels: string[] = juniClub.channels
        ? juniClub.channels
            .filter(c => !!c.displayName)
            .map(c => c.displayName || '')
        : [];

      const topUnreadChannel = channels
        .sort((a, b) => (unreadByChannel[a] > unreadByChannel[b] ? -1 : 1))
        .slice(0, 1)
        .filter(c => unreadByChannel[c] >= 1)
        .map(c => `${juniClub._id}.${c}`);

      return topUnreadChannel;
    };

    const fetchPreviewMessages = async (): Promise<Record<string, MsgType[]>> => {
      const currentClientUUID = studentId || instructorUserId;
      if (!currentClientUUID) return {};

      const topUnreadClubs = juniClubsArray
        .sort((a, b) =>
          unreadMessagesByClub[a._id] > unreadMessagesByClub[b._id] ? -1 : 1,
        )
        .slice(0, Math.min(juniClubsArray.length, 3));
      const topUnreadChannels = topUnreadClubs.map(club =>
        getTopUnreadChannel(club),
      );

      const messages = _.flatten(
        await Promise.all(
          topUnreadChannels.map(cs =>
            pubnubFetchMessagesRaw({ currentClientUUID, channels: cs, count: 2 }),
          ),
        ),
      );

      const messagesByClub = _.groupBy(messages, 'juniClubId');
      return messagesByClub;
    };

    fetchPreviewMessages().then(messagesByClub => {
      setPreviewMessagesByClub(messagesByClub);
      setLoadingNotifications(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClubsLoading]);

  const isLoading =
    loadingInviteClubs || loadingInvites || loadingNotifications || loadingEvents;

  // Rendering logic for home.
  const invitesToShow = filteredInvites.slice(
    0,
    Math.min(invites.length, TOTAL_CARD_SLOTS - 1),
  );
  // Rendering logic for my clubs
  const notificationClubsToShow = _.keys(previewMessagesByClub).slice(
    0,
    TOTAL_CARD_SLOTS - invitesToShow.length - (totalNumEvents > 0 ? 1 : 0),
  );
  const showZeroState =
    invitesToShow.length + notificationClubsToShow.length < TOTAL_CARD_SLOTS &&
    totalNumEvents === 0;

  const showLargeZeroState =
    invitesToShow.length === 0 &&
    notificationClubsToShow.length === 0 &&
    totalNumEvents === 0;
  return !isBanned ? (
    <PortalSection name={myClubsPage ? 'Notifications' : 'My Clubs'}>
      {isLoading ? (
        <Card className="bg-juni-blue-50">
          <div className="flex flex-col justify-center items-center">
            <JuniSpinner size={60} />
            <h3 className="m-0 mt-5"> Loading new messages...</h3>
          </div>
        </Card>
      ) : (
        <ClubCardsContainer
          isVertical={isVertical}
          isSingleCard={showLargeZeroState}
        >
          <UsernameCreator
            studentId={studentId}
            instructorUserId={instructorUserId}
            isOpen={!username && !!selectedClubToJoin}
            closeModal={() => {
              setSelectedClubToJoin(undefined);
            }}
            usernameCallback={setUsername}
          />
          {selectedClubToJoin && (
            <ConductModal
              studentId={studentId}
              instructorUserId={instructorUserId}
              juniClub={selectedClubToJoin}
              canJoin={canJoin}
              requiresRefresh={username !== existingUsername}
              isOpen={!!username}
              handleClose={() => {
                setSelectedClubToJoin(undefined);
                if (username !== existingUsername) {
                  window.location.reload();
                }
              }}
            />
          )}
          {totalNumEvents > 0 && (
            <EventsNotificationCard
              numEvents={totalNumEvents}
              studentId={studentId}
              isOnMyClubsPage={myClubsPage}
            />
          )}
          <ClubInvitesSection
            studentId={studentId}
            instructorUserId={instructorUserId}
            setSelectedClubToJoin={setSelectedClubToJoin}
            invites={invitesToShow}
            clubs={clubsFromInvites}
          />
          <ClubNotificationsSection
            studentId={studentId}
            instructorUserId={instructorUserId}
            previewMessagesByClub={previewMessagesByClub}
            unreadMessagesByClub={unreadMessagesByClub}
            juniClubs={juniClubs}
            juniClubIds={notificationClubsToShow}
          />
          {!myClubsPage && showZeroState && !showLargeZeroState && (
            <NoNewNotifications />
          )}
          {!myClubsPage && showZeroState && showLargeZeroState && (
            <MyClubsZeroState />
          )}
          {myClubsPage &&
            showZeroState &&
            invitesToShow.length + notificationClubsToShow.length === 0 && (
              <NoNewNotificationsClubPage />
            )}
        </ClubCardsContainer>
      )}
    </PortalSection>
  ) : null;
};

export default ClubSection;
