import React, { FC } from 'react';
import { GetCoursesReturnFragment } from 'generated/graphql';
import humanizeTimeAgo from 'utils/dates/humanizeTimeAgo';
import { splitDisplayName } from 'utils/courses';
import { Icon } from 'core-components';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import IconComponent from 'core-components/Icon/IconComponent';
import { sumBy } from 'lodash';
import { JUNI_LINKS } from 'constants/links';

const isBootcamp = (course: GetCoursesReturnFragment) =>
  course.schedulingFormat === 'group';

export const CLImg: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => {
  const { iconName, primaryColor } = course;
  const courseExplorerLink = `${JUNI_LINKS.MARKETING}/courses/${course.slug}`;

  return (
    <a
      href={courseExplorerLink}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        'flex relative items-center justify-center h-20 rounded-lg',
        `text-white bg-${primaryColor}`,
        {
          'w-36': course.headerImage?.original,
          'w-20': !course.headerImage?.original,
        },
      )}
    >
      {course.headerImage?.original ? (
        <>
          <img
            src={course.headerImage?.original}
            alt="course header img"
            loading="lazy"
            className="object-cover w-36 h-20 rounded-lg"
          />
          <div
            className={classNames(
              'flex absolute items-center justify-center w-6 h-6 rounded-lg',
              `top-1 right-1 text-white bg-${primaryColor}`,
            )}
          >
            <IconComponent iconName={iconName} size={14} />
          </div>
        </>
      ) : (
        <IconComponent iconName={iconName} size={34} />
      )}
    </a>
  );
};

export const CLName: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => {
  const { supertitle, title, subtitle } = splitDisplayName(course.displayName);
  const courseExplorerLink = `${JUNI_LINKS.MARKETING}/courses/${course.slug}`;
  return (
    <a
      href={courseExplorerLink}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-col no-underline"
    >
      <div className="font-graphik leading-4 text-j-dark-300">{supertitle}</div>
      <div className="text-lg mt-1 mb-2 leading-5">{title}</div>
      <div className="font-graphik text-2xs leading-3 uppercase text-j-dark-400 tracking-wide">
        {subtitle}
      </div>
    </a>
  );
};

export const CLType: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
  <div className="flex">{course.schedulingFormat}</div>
);

// TODO: add icon and color fields to subject
export const CLSubject: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
  <div className="flex">{course.subject.displayName}</div>
);

// TODO: add icons/something visual for these types
export const CLStatus: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
  <div
    className={classNames(
      'flex justify-center items-center rounded-xl',
      'px-2 py-1 text-white font-graphik text-xs tracking-wide',
      {
        'bg-j-green-400': course.isAcceptingEnrollment,
        'bg-red-500': !course.isAcceptingEnrollment,
      },
    )}
  >
    {course.isAcceptingEnrollment ? 'Open' : 'Closed'}
    {course.isAcceptingEnrollment ? (
      <Icon.Check height={10} width={10} style={{ marginLeft: '4px' }} />
    ) : (
      <Icon.Times height={10} width={10} style={{ marginLeft: '4px' }} />
    )}
  </div>
);

export const CLAges: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
  <div className="flex whitespace-nowrap">
    {`${course.minRecommendedAge || '?'}-${course.maxRecommendedAge || '?'} yo`}
  </div>
);

export const CLDetails: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
  <div
    className={classNames(
      'flex flex-col font-graphik text-2xs text-j-dark-300',
      'w-40 leading-3 space-y-1 break-words',
    )}
  >
    <div>{course.name}</div>
    <div>{course._id}</div>
    <div>{course.slug}</div>
    <div>{course.abbreviation}</div>
  </div>
);

export const CLUpdated: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
  <div
    title={new Date(course.updatedAt).toISOString()}
    className="flex flex-col whitespace-nowrap text-xs"
  >
    <div>{new Date(course.updatedAt).toDateString()}</div>
    <div className="italic text-j-dark-400">
      {humanizeTimeAgo(course.updatedAt)?.replace(/ minutes| minute/gi, 'm')}
    </div>
  </div>
);
export const CLOfferings: FC<{ course: GetCoursesReturnFragment }> = ({ course }) =>
  isBootcamp(course) ? <div>{course.offerings.length}</div> : <div>n/a</div>;

export const CLOpenings: FC<{ course: GetCoursesReturnFragment }> = ({ course }) =>
  isBootcamp(course) ? (
    <div>{sumBy(course.offerings, offering => offering.numSeatsAvailable)}</div>
  ) : (
    <div>n/a</div>
  );

export const CLEnrollments: FC<{ course: GetCoursesReturnFragment }> = ({
  course,
}) =>
  isBootcamp(course) ? (
    <div>
      {sumBy(
        course.offerings,
        offering => offering.totalSeats - offering.numSeatsAvailable,
      )}
    </div>
  ) : (
    <div>{course.statistics?.totalEnrollments}</div>
  );

export const CLCourseEditor: FC<{ course: GetCoursesReturnFragment }> = ({
  course,
}) => {
  // if bootcamp, return a link to a bootcamp editor
  if (isBootcamp(course)) {
    return (
      <NavLink
        to={`update_course/${course._id}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          'flex w-16 h-16 justify-center items-center',
          'rounded-lg bg-j-purple-100 hover:bg-j-dark-100',
        )}
      >
        <Icon.ArrowRight />
      </NavLink>
    );
  }
  // handle default juni courses
  const hasCurriculumEditor = !!course.curriculumId && !!course.curriculumCollection;
  if (!hasCurriculumEditor) return null;

  const CURRICULUM_COLLECTION_TO_EDITOR_PATH = {
    CurriculumCourse: 'computer_science_curriculum_editor',
    MathCurriculumCourse: 'mathematics_curriculum_editor',
    EnglishCurriculumCourse: 'english_curriculum_editor',
    InvestingCurriculumCourse: 'investing_curriculum_editor',
  };

  const editorName = course.curriculumCollection
    ? CURRICULUM_COLLECTION_TO_EDITOR_PATH[course.curriculumCollection]
    : undefined;
  if (!editorName) return null;

  const linkParams =
    editorName === CURRICULUM_COLLECTION_TO_EDITOR_PATH.CurriculumCourse
      ? '' // CS Editor doesn't have deep linking into courses
      : `/course?courseId=${course.curriculumId}`;
  const link = `/admin/${editorName}${linkParams}`;

  return (
    <NavLink
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        'flex w-16 h-16 justify-center items-center',
        'rounded-lg bg-j-purple-100 hover:bg-j-dark-100',
      )}
    >
      <IconComponent iconName={course.subject.iconName} />
    </NavLink>
  );
};

// TODO: add document editor
// export const CLEditListing: FC<{ course: GetCoursesReturnFragment }> = ({ course }) => (
//   <div>
//     <Icon.PencilTool />
//   </div>
// );
