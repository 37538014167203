import React from 'react';
import { Divider, NewCard as Card } from 'core-components';
import { useGetMyRecentStudentOnboardingAndHandoffProgressesQuery } from 'generated/graphql';
import { Student } from 'models';
import SpinnerV2 from 'components/SpinnerV2';
import {
  STATUS,
  STATUS_TO_PROGRESS,
  STEP,
} from 'constants/onboarding_and_handoff_statuses';
import { utcToZonedTime, format } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'constants/timezones';
import ProgressStepper from './component';

interface Props {
  student: Student;
}

const onboardingHandoffStatusToMessage = (status: STATUS) => {
  if (status in STATUS_TO_PROGRESS) {
    return STATUS_TO_PROGRESS[status];
  }
  return null;
};

const OnboardingHandoffRequestCard: React.FC<Props> = ({ student }) => {
  const {
    data,
    loading,
    error,
  } = useGetMyRecentStudentOnboardingAndHandoffProgressesQuery();
  const onboardingProgresses = data?.getMyRecentStudentOnboardingAndHandoffProgresses?.onboardingProgresses
    .filter(ticket => ticket.studentId === student._id)
    .sort(
      (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    );
  const handoffProgresses = data?.getMyRecentStudentOnboardingAndHandoffProgresses?.handoffProgresses
    .filter(ticket => ticket.studentId === student._id)
    .sort(
      (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    );

  const hasOnboardingProgressTickets =
    onboardingProgresses && onboardingProgresses.length > 0;
  const hasHandoffProgressesTickets =
    handoffProgresses && handoffProgresses.length > 0;

  if (!hasOnboardingProgressTickets && !hasHandoffProgressesTickets) {
    return null;
  }

  return (
    <>
      {loading ? (
        <SpinnerV2 />
      ) : (
        <Card shadow="1">
          <div className="mb-4 text-xl font-medium text-j-dark-600">
            {hasOnboardingProgressTickets && 'New Class'}
            {hasOnboardingProgressTickets && hasHandoffProgressesTickets && ' and '}
            {hasHandoffProgressesTickets && 'Instructor Transition'} Requests
          </div>
          <Card.Divider />
          <div className="pt-6">
            {error && <>There has been an error loading the data</>}
            {onboardingProgresses?.map((ticket, i) => {
              const status = onboardingHandoffStatusToMessage(
                ticket.status as STATUS,
              );
              if (!status) {
                return null;
              }
              return (
                <>
                  {i !== 0 && <Divider className="my-4" />}
                  <div className="flex flex-col gap-2 text-base">
                    <div className="font-medium text-j-dark-600">
                      Setup New {ticket.subject} Class -{' '}
                      {ticket.status === STATUS.CLOSED
                        ? 'Completed'
                        : ticket.status === STATUS.CANCELED
                        ? 'Canceled'
                        : 'In Progress'}
                    </div>
                    <div className="text-j-dark-300 text-sm">{status?.message}</div>
                    {ticket.status !== STATUS.CANCELED && (
                      <div className="w-full flex justify-center">
                        <ProgressStepper
                          steps={[
                            STEP.REQUEST_RECEIVED,
                            STEP.PAIRING_INSTRUCTOR,
                            STEP.INSTRUCTOR_CONFIRMED,
                            STEP.COMPLETED,
                          ]}
                          currentStep={status?.step}
                          firstStepString={format(
                            utcToZonedTime(ticket.createdAt, DEFAULT_TIMEZONE),
                            'MMM dd, yyyy',
                          )}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            {handoffProgresses?.map((ticket, i) => {
              const status = onboardingHandoffStatusToMessage(
                ticket.status as STATUS,
              );
              if (!status) {
                return null;
              }
              return (
                <>
                  {!(i === 0 && !hasOnboardingProgressTickets) && (
                    <Divider className="my-4" />
                  )}
                  <div className="flex flex-col gap-2 text-base">
                    <div className="font-medium text-j-dark-600">
                      Update {ticket.subject} Class Instructor -{' '}
                      {ticket.status === STATUS.CLOSED
                        ? 'Completed'
                        : ticket.status === STATUS.CANCELED
                        ? 'Canceled'
                        : 'In Progress'}
                    </div>
                    <div className="text-j-dark-300 text-sm">{status?.message}</div>
                    {ticket.status !== STATUS.CANCELED && (
                      <div className="w-full flex justify-center">
                        <ProgressStepper
                          steps={[
                            STEP.REQUEST_RECEIVED,
                            STEP.PAIRING_INSTRUCTOR,
                            STEP.INSTRUCTOR_CONFIRMED,
                            STEP.COMPLETED,
                          ]}
                          currentStep={status?.step}
                          firstStepString={format(
                            utcToZonedTime(ticket.createdAt, DEFAULT_TIMEZONE),
                            'MMM dd, yyyy',
                          )}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </Card>
      )}
    </>
  );
};

export default OnboardingHandoffRequestCard;
