import React, { FC, useState } from 'react';
import { timezoneToDisplayName } from 'constants/timezones';
import { NewButton, NewModalWindow, TextArea } from 'core-components';
import { DateTime } from 'components/SchedulingSelection/types';
import SchedulingTimeSelection from 'components/SchedulingSelection/SchedulingTimeSelection';
import { METADATA_KEY } from 'constants/subscription_plans';
import { SubscriptionMetaDataWithCourse } from '../../../types';

interface Props {
  updatedSubscription: SubscriptionMetaDataWithCourse;
  setUpdatedSubscription: React.Dispatch<
    React.SetStateAction<SubscriptionMetaDataWithCourse>
  >;
  isAddTutoringModalOpen: boolean;
  setIsAddTutoringModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  timezone: string;
}

const AddTutoringModal: FC<Props> = ({
  updatedSubscription,
  setUpdatedSubscription,
  isAddTutoringModalOpen,
  setIsAddTutoringModalOpen,
  timezone,
}) => {
  const [tutoringNotes, setTutoringNotes] = useState<undefined | string>(undefined);
  const [classTimePreferences, setClassTimePreferences] = useState<DateTime[][]>([
    [],
  ]);
  const [isTutoringSubjectProvided, setIsTutoringSubjectProvided] = useState(false);
  const setScheduleModal = () => {
    setUpdatedSubscription({
      ...updatedSubscription,
      [METADATA_KEY.tutoring]: {
        weeklyFrequency: 1,
        courses: undefined,
        timePreferences: classTimePreferences,
        notes: tutoringNotes,
      },
    });
    setIsAddTutoringModalOpen(false);
    closeModal();
  };

  const closeModal = () => {
    setClassTimePreferences([[]]);
    setIsTutoringSubjectProvided(false);
    setTutoringNotes(undefined);
    setIsAddTutoringModalOpen(false);
  };

  return !isTutoringSubjectProvided ? (
    <NewModalWindow
      title="Add Tutoring"
      closeModal={closeModal}
      isOpen={isAddTutoringModalOpen}
      renderFooter={() => (
        <div className="text-right w-full">
          <NewButton
            onClick={() => {
              setIsTutoringSubjectProvided(true);
            }}
            disabled={!tutoringNotes}
          >
            Continue
          </NewButton>
        </div>
      )}
    >
      <div className="flex flex-col w-full gap-2">
        <label className="text-j-dark-700 font-base">
          What subjects would you like the tutoring sessions to cover?
        </label>
        <TextArea
          onChange={e => setTutoringNotes(e.target.value)}
          value={tutoringNotes}
          size="xsmall"
          fullWidth
          placeholder="Please include any subjects, as well as more specific topics that you’d like our instructors to work on with your child."
        />
      </div>
    </NewModalWindow>
  ) : (
    <NewModalWindow
      title="Schedule Options"
      description={timezoneToDisplayName(timezone)}
      closeModal={closeModal}
      isOpen={isAddTutoringModalOpen}
      showCloseIcon
      renderFooter={() => (
        <div className="text-right w-full">
          <NewButton
            onClick={setScheduleModal}
            disabled={
              classTimePreferences[0].filter(date => date.datetime).length !== 3
            }
          >
            Continue
          </NewButton>
        </div>
      )}
    >
      <SchedulingTimeSelection
        title="Tutoring Time Preference"
        timezone={timezone}
        selectedDates={classTimePreferences[0]}
        onChangeSelections={newSelections => {
          if (newSelections) {
            setClassTimePreferences([newSelections]);
          }
        }}
      />
    </NewModalWindow>
  );
};
export default AddTutoringModal;
