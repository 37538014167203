import React from 'react';
import { Icon as CoreIcon } from 'core-components';
import { useGetSubjectsQuery } from 'generated/graphql';

interface Props {
  subject: string;
  className?: string;
}

const SubjectIcon = ({ subject: subjectName, className = 'h-5 w-5' }: Props) => {
  const { data } = useGetSubjectsQuery();
  const iconName = data?.getSubjects?.find(subject => subject.name === subjectName)
    ?.iconName;

  const Icon =
    iconName && iconName in CoreIcon
      ? CoreIcon[iconName as keyof typeof CoreIcon]
      : null;

  if (!Icon) {
    return <div className={className} />;
  }

  return <Icon className={className} />;
};

export default SubjectIcon;
