import React from 'react';
import { JuniLogo } from 'components/brand-assets';
import { NewButton as Button } from 'core-components';
import classNames from 'classnames';
import useReferralCTA from 'app/signup_session/hooks/useReferralCTA';
import { DCF_DEFAULT_ENTRY_POINT } from 'constants/links';

interface NavBarReferralEntryButtonProps {
  /**
   * If a referral code is not supplied, the button will link directly to the /pricing page.
   */
  referralCode?: string;
}

const NavBarReferralEntryButton: React.FC<NavBarReferralEntryButtonProps> = ({
  referralCode,
}) => {
  const { isLoading, onClaim } = useReferralCTA(referralCode);

  if (!!referralCode && isLoading) return null;

  return (
    <Button
      intent="success"
      onClick={() =>
        onClaim({
          componentName: 'nav_bar_trial_button',
          referralCode,
        })
      }
      href={referralCode ? undefined : DCF_DEFAULT_ENTRY_POINT}
    >
      {referralCode ? 'Get $50 Off' : 'Get Started'}
    </Button>
  );
};

interface NavBarProps {
  /**
   * For the Free Trial CTA.  Pass this through if the page is a referral entry point.
   */
  referralCode?: React.ComponentProps<
    typeof NavBarReferralEntryButton
  >['referralCode'];
  /**
   * True to be positioned stick on top.
   * Default false.
   */
  sticky?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ referralCode, sticky = false }) => (
  <div
    className={classNames('flex justify-between p-4 xl:px-18 bg-j-purple-600', {
      'sticky top-0 z-20': sticky,
    })}
  >
    <a href="https://www.junilearning.com">
      <JuniLogo color="light" />
    </a>
    <NavBarReferralEntryButton referralCode={referralCode} />
  </div>
);

export default NavBar;
