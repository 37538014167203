import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { JuniLogo } from 'components/brand-assets';
import useNavStore from 'app/miscellaneous/stores/NavStore';
import { Icon } from 'core-components';
import RingSpinner from 'components/RingSpinner';
import { splitDisplayName, getStudentCoursesFromIdLookup } from 'utils/courses';
import { useCourseNameToBasicMetadata } from 'hooks/course';
import IconComponent from 'core-components/Icon/IconComponent';
import { cleanCourseDisplayNameForLearnerAudience } from 'utils/cleanCourseDisplayNameForLearnerAudience';
import {
  NavOption,
  NavDropdownItem,
  DropdownHeader,
  DropdownSeparator,
  MobileHamburger,
  ReferButton,
} from './NavV2Components';
import { iconSize } from './styles';

// In this file you can modularly build different layouts using components from NavV2Components.tsx

// NavV2 Base Component
// This base component will take care of the responsive styling and adding the Juni Logo
//     on the left. All you need to do is add the mainOptions and rightSide components
//     that you want to be rendered
interface NavV2BaseProps {
  mainOptions: React.ReactNode[];
  rightSide: React.ReactNode[];
  juniIconPath?: string;
}
export const NavV2Base: FC<NavV2BaseProps> = ({
  mainOptions,
  rightSide,
  juniIconPath,
}) => (
  <div className="flex flex-1 px-6 xl:px-18">
    <div className="flex flex-shrink-0 mr-12">
      <NavLink to={juniIconPath || '/'} className="flex flex-shrink-0">
        <JuniLogo color="light" className="-ml-1.5" />
      </NavLink>
    </div>

    <div className="hidden flex-1 sm:flex">{mainOptions}</div>

    <div className="flex flex-shrink-0 ml-auto">{rightSide}</div>

    <div className="flex flex-shrink-0 ml-8 sm:hidden">
      <MobileHamburger>{mainOptions}</MobileHamburger>
    </div>
  </div>
);

// NavV2 Learner Layout
// This component specifies the layout for the Learner Nav
// Notice that it builds out the nav using Option components for direct links
//     and NavDropdownItem components inside Option components for dropdowns.
interface NavV2LearnerProps {
  onSelectStudent?: (studentId: string) => void;
  isClubsEnabled?: boolean | null;
  isGuest?: boolean | null;
  idLookup: any;
  isLoadingIdLookup: boolean;
}
export const NavV2Learner: FC<NavV2LearnerProps> = ({
  onSelectStudent = () => null,
  isClubsEnabled,
  isGuest,
  idLookup,
  isLoadingIdLookup,
}) => {
  // Tailwind breakpoint for 'sm'
  const MOBILE_WIDTH = 640;

  const { courseNameToBasicMetadata } = useCourseNameToBasicMetadata();
  const navWidth = useNavStore(state => state.navWidth);
  const navOrderedStudents = useNavStore(state => state.navOrderedStudents) || [];
  const activeStudent = navOrderedStudents[0];
  const baseRoute = `/learner/${activeStudent?._id}`;

  // Use full last name only if window is larger than 1280px, which corresponds to
  //     Tailwind's 'xl' size
  const activeStudentName =
    navWidth >= 1280
      ? `${activeStudent?.firstName} ${activeStudent?.lastName}`
      : `${activeStudent?.firstName} ${activeStudent?.lastName[0]}`;

  const { activeCourses, completedCourses } = getStudentCoursesFromIdLookup(
    activeStudent,
    idLookup,
    isLoadingIdLookup,
  );

  const courseToDropdownElem = (curriculumCourse: any) => {
    const courseName = curriculumCourse?.properties?.name;
    const course = courseNameToBasicMetadata(courseName);
    const { supertitle, title, subtitle } = splitDisplayName(
      cleanCourseDisplayNameForLearnerAudience(course.displayName || ''),
    );
    const iconElem = <IconComponent iconName={course.iconName} />;
    return (
      <NavDropdownItem
        key={course._id}
        icon={iconElem}
        supertitle={supertitle}
        title={title}
        subtitle={subtitle}
        path={`${baseRoute}/my_courses/${courseName}`}
      />
    );
  };

  const courseExplorerElem = (
    <NavDropdownItem
      key="course_explorer"
      icon={<Icon.Plus {...iconSize(16)} />}
      supertitle="Explore Courses"
      title="Browse All Juni Courses"
      path="https://junilearning.com/courses"
      newTab
      isSpecial
    />
  );

  const activeCoursesElems: JSX.Element[] = [
    ...activeCourses.map(courseToDropdownElem),
    courseExplorerElem,
  ];
  const completedCoursesElems: JSX.Element[] = completedCourses.map(
    courseToDropdownElem,
  );

  // The main options in the nav, including Home, Courses, Clubs, Calendar, and Profile
  // Note the Courses is a dropdown since it includes NavDropdownItem components as children
  const mainOptions = (
    <>
      <NavOption
        icon={<Icon.Elements {...iconSize(20)} />}
        iconActive={<Icon.ElementsSolid {...iconSize(20)} />}
        title="Home"
        path={`${baseRoute}/home`}
      />
      <NavOption
        icon={<Icon.Book {...iconSize(20)} />}
        iconActive={<Icon.BookSolid {...iconSize(20)} />}
        title="Courses"
      >
        {/* loading spinner */}
        {isLoadingIdLookup ? <RingSpinner /> : <></>}

        {/* current courses */}
        {isLoadingIdLookup || !activeCoursesElems.length ? (
          <></>
        ) : (
          <DropdownHeader title="CURRENT COURSES" />
        )}
        {isLoadingIdLookup ? <></> : activeCoursesElems}
        <NavDropdownItem
          icon={<Icon.VideoQueue {...iconSize(16)} />}
          title="On Demand Library"
          path={`${baseRoute}/on_demand`}
        />

        {/* separator */}
        <DropdownSeparator />

        {/* roadmap and session notes */}
        <NavDropdownItem
          icon={<Icon.Map {...iconSize(16)} />}
          title="Roadmap"
          path={`${baseRoute}/roadmap`}
        />
        <NavDropdownItem
          icon={<Icon.Notebook {...iconSize(16)} />}
          title="Session Notes"
          path={`${baseRoute}/session_notes`}
        />

        {/* conditional separator */}
        {isLoadingIdLookup || !completedCoursesElems.length ? (
          <></>
        ) : (
          <DropdownSeparator />
        )}

        {/* completed courses */}
        {isLoadingIdLookup || !completedCoursesElems.length ? (
          <></>
        ) : (
          <DropdownHeader title="COMPLETED COURSES" />
        )}
        {isLoadingIdLookup ? <></> : completedCoursesElems}
      </NavOption>
      {isClubsEnabled ? (
        <NavOption
          icon={<Icon.Trophy {...iconSize(20)} />}
          iconActive={<Icon.TrophySolid {...iconSize(20)} />}
          title="Clubs"
          path={`${baseRoute}/my_clubs`}
        />
      ) : (
        <></>
      )}
      {!isGuest ? (
        <NavOption
          icon={<Icon.Calendar2 {...iconSize(20)} />}
          iconActive={<Icon.Calendar2Solid {...iconSize(20)} />}
          title="Calendar"
          path={`${baseRoute}/calendar`}
        />
      ) : (
        <></>
      )}

      <NavOption
        icon={<Icon.User {...iconSize(20)} />}
        iconActive={<Icon.UserSolid {...iconSize(20)} />}
        title="My Stuff"
      >
        <NavDropdownItem
          icon={<Icon.Turtle {...iconSize(16)} />}
          title="Playground"
          path={`${baseRoute}/playground`}
        />
        {activeStudent?.username ? (
          <NavDropdownItem
            icon={<Icon.User {...iconSize(16)} />}
            title="Profile"
            path={`/juniverse/users/${activeStudent?.username}`}
            newTab
          />
        ) : (
          <></>
        )}
      </NavOption>
      <NavOption
        icon={<Icon.Info {...iconSize(20)} />}
        iconActive={<Icon.InfoSolid {...iconSize(20)} />}
        title="Parent Corner"
        path={`${baseRoute}/parent_corner`}
      />
      {navWidth < MOBILE_WIDTH ? <ReferButton to={`${baseRoute}/refer`} /> : <></>}
    </>
  );

  // The right side of the nav, including the Give $50 button and the Student Selector
  const rightSide = (
    <>
      {navWidth >= MOBILE_WIDTH ? <ReferButton to={`${baseRoute}/refer`} /> : <></>}
      <NavOption
        icon={<Icon.UserCircle {...iconSize(24)} />}
        title={activeStudentName}
        isUserDropdown
      >
        <DropdownHeader title="ACCOUNT" isUserDropdown />
        {navOrderedStudents.map(student => (
          <NavDropdownItem
            key={student?._id}
            icon={<Icon.UserCircle {...iconSize(20)} />}
            title={`${student?.firstName} ${student?.lastName[0]}`}
            isSelected={activeStudent && activeStudent._id === student._id}
            onClick={() => onSelectStudent(student?._id)}
            showCheckForSelected
          />
        ))}
        <DropdownSeparator />
        <NavDropdownItem
          icon={<Icon.Info {...iconSize(16)} />}
          title="Parent Corner"
          path={`${baseRoute}/parent_corner`}
        />
        <NavDropdownItem
          icon={<Icon.Gear {...iconSize(16)} />}
          title="Account Settings"
          path={`${baseRoute}/account`}
        />
        <NavDropdownItem
          icon={<Icon.QuestionCircle {...iconSize(16)} />}
          title="Help"
          path={`${baseRoute}/help`}
        />
        <NavDropdownItem
          icon={<Icon.Logout {...iconSize(16)} />}
          title="Logout"
          path="/logout"
        />
      </NavOption>
    </>
  );

  return (
    <NavV2Base
      mainOptions={mainOptions.props.children}
      rightSide={rightSide.props.children}
      juniIconPath={`${baseRoute}/home`}
    />
  );
};
