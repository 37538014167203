import React from 'react';
import { ExpandableCard } from 'core-components';

import { StripeSubscription } from 'generated/graphql';
import { Student } from 'models';
import { CurrentSubscriptionDetails } from '../../components';

export interface Props {
  student: Student;
  subscription: StripeSubscription;
}

const CurrentSubscriptionExpandableCard: React.FC<Props> = ({
  student,
  subscription,
}) => (
  <ExpandableCard
    defaultExpanded
    renderHeaderContent={() => (
      <header>
        <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2">
          {student.firstName}'s Current Subscription
        </h1>
      </header>
    )}
  >
    <CurrentSubscriptionDetails subscription={subscription} />
  </ExpandableCard>
);

export default CurrentSubscriptionExpandableCard;
