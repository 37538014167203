import {
  INSTRUCTOR_ADMIN_PERMISSIONS,
  INSTRUCTOR_ADMIN_ROLES,
} from 'constants/user_permissions';

const allowAdminOrInstructorWithPermission = (
  userType,
  userPermissions = [],
  userRoles = [],
  pathname,
) => {
  if (userType === 'admin') {
    return true;
  }
  if (userType === 'teacher') {
    if (pathname.includes('/admin/instructor_achievements')) {
      return userPermissions.includes('instructor_achievements');
    }
    if (pathname.startsWith('/admin/juniverse_dashboard')) {
      return userRoles.includes('juniverse_moderator');
    }
    switch (pathname) {
      case '/admin':
        return (
          userPermissions.some(permission =>
            INSTRUCTOR_ADMIN_PERMISSIONS.includes(permission),
          ) || userRoles.some(role => INSTRUCTOR_ADMIN_ROLES.includes(role))
        );
      // jan2022: these cases will fall through to the
      // curriculum_developer permission check. Cases will
      // be removed as curriculum editors are unified
      case '/admin/mathematics_curriculum_editor':
      case '/admin/mathematics_curriculum_editor/course':
      case '/admin/english_curriculum_editor':
      case '/admin/english_curriculum_editor/course':
      case '/admin/investing_curriculum_editor':
      case '/admin/investing_curriculum_editor/course':
      case '/admin/computer_science_curriculum_editor':
        return userPermissions.some(p => p.includes('curriculum_developer'));

      case '/admin/post_session_emails':
        return userPermissions.includes('session_note_reviewer');
      default:
        return false;
    }
  }
  return false;
};

export default allowAdminOrInstructorWithPermission;
