import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useGetRecurringlyAvailableAppointmentSlotsQuery } from 'generated/graphql';
import {
  addDays,
  endOfDay,
  isBefore,
  startOfDay,
  startOfHour,
  subDays,
} from 'date-fns';
import classNames from 'classnames';

import { Icon, Message, NewButton as Button } from 'core-components';
import SpinnerV2 from 'components/SpinnerV2';
import { NUM_DAYS_TO_SHOW } from '../../constants';
import { TimeSlotPicker } from '../../components';

interface InstructorAvailabilityStepProps {
  instructorAcuityId: number;
  appointmentTypeID: number;
  timezone: string;
  selectedDatetime?: Date;
  setSelectedDatetime: Dispatch<SetStateAction<Date | undefined>>;
  isCustomTimeSelection: boolean;
  setIsCustomTimeSelection: Dispatch<SetStateAction<boolean>>;
}

const InstructorAvailabilityStep: FC<InstructorAvailabilityStepProps> = ({
  instructorAcuityId,
  appointmentTypeID,
  timezone,
  setSelectedDatetime,
  isCustomTimeSelection,
  setIsCustomTimeSelection,
}) => {
  const [startDate, setStartDate] = useState(startOfDay(addDays(new Date(), 1)));

  const { data, loading, error } = useGetRecurringlyAvailableAppointmentSlotsQuery({
    variables: {
      instructorAcuityId: `${instructorAcuityId}`,
      appointmentTypeId: appointmentTypeID,
      startDatetime: startOfHour(startDate).toString(),
      endDatetime: startOfHour(addDays(startDate, NUM_DAYS_TO_SHOW)).toString(),
      timezone,
    },
  });
  const availableSlots = data && data.recurringlyAvailableAppointmentSlots;

  // Callback for previous page button
  const previousAvailability = () => {
    setStartDate(subDays(startDate, NUM_DAYS_TO_SHOW));
  };

  // Callback for next page button
  const moreAvailability = () => {
    setStartDate(addDays(startDate, NUM_DAYS_TO_SHOW));
  };
  const previousAvailabilityDisabled = isBefore(
    subDays(startDate, NUM_DAYS_TO_SHOW),
    new Date(),
  );

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="mb-4 text-sm text-j-dark-400">
        Make sure that these times generally work on a weekly basis for your student.
        Our team will do our best to meet these preferences, and we may reach out to
        discuss as needed.
      </div>
      {/** Availability Calendar controls */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-j-dark-600 text-base font-medium">
          Pick a time within your current instructor's availability:
        </div>
        <div className="flex">
          <div className="mr-3">
            <Button
              onClick={previousAvailability}
              disabled={previousAvailabilityDisabled}
              variant="secondary"
              icon
            >
              <Icon.ArrowLeft />
            </Button>
          </div>
          <div className="mr-4">
            <Button onClick={moreAvailability} variant="secondary" icon>
              <Icon.ArrowRight />
            </Button>
          </div>
        </div>
      </div>

      {/** Availability table */}
      <div className={classNames('flex flex-col w-full overflow-x-auto')}>
        {error ? (
          <div className="p-5">
            <Message status="error">
              Unable to fetch instructor availability. Please contact Juni HQ if this
              error persists.
            </Message>
          </div>
        ) : loading ? (
          <SpinnerV2 size={48} />
        ) : !availableSlots || availableSlots?.length === 0 ? (
          <div className="p-5">
            <Message status="info">No available times for these dates.</Message>
          </div>
        ) : (
          <TimeSlotPicker
            availableSlots={availableSlots}
            timezone={timezone}
            setSelectedDatetime={time => setSelectedDatetime(time)}
            windowStart={startDate}
            windowEnd={endOfDay(addDays(startDate, NUM_DAYS_TO_SHOW))}
          />
        )}
      </div>
      {!error && !loading && (
        <div className="flex gap-2 text-j-dark-600">
          <input
            type="checkbox"
            checked={isCustomTimeSelection}
            onChange={() => setIsCustomTimeSelection(!isCustomTimeSelection)}
          />
          <div className="flex flex-col gap-1">
            <div className="text-sm font-medium">Need more time options?</div>
            <div className="text-xs">
              If your student's current instructor's availability does not match
              yours, select here to provide times that would work best for you. We
              will work with your current instructor first to find a match, and if
              none can be made we will match your student with another highly skilled
              instructor who has availability during your preferred time(s).
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstructorAvailabilityStep;
