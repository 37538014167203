import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { Parent, Student } from 'models';
import {
  useGetCoursesQuery,
  useLoadAvailabilitiesByStudentIdsQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
} from 'generated/graphql';
import JuniSpinner from 'components/JuniSpinner';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { Message } from 'core-components';
import { JUNI_EMAILS } from 'constants/contact_information';
import { hasAsync } from 'utils/stripe';
import { CurrentSubscriptionExpandableCard, ManageSubscriptionCard } from './Cards';

interface Props {
  parent: Parent;
  student: Student;
}

const Subscription: FC<Props> = ({ parent, student }) => {
  // load subscription information
  const params = new URLSearchParams(window.location.search);

  const {
    data: availabilityData,
    loading: loadingAvailiabilityData,
    error: errorAvailiabilityData,
  } = useLoadAvailabilitiesByStudentIdsQuery({
    variables: {
      ids: [student._id],
    },
  });
  const timezone =
    availabilityData?.availabilitiesByStudentIds?.items[0]?.timezone ||
    guessTimezoneValue();

  const subscriptionId = params.get('id');
  const {
    data: subscriptionData,
    loading: loadingSubscriptionData,
    error: errorSubscriptionData,
  } = useLoadStripeSubscriptionsByParentIdQuery({
    variables: { id: parent._id },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: courseData,
    loading: loadingCourseData,
    error: errorCourseData,
  } = useGetCoursesQuery();
  const courses = courseData?.getCourses;

  const subscriptions = subscriptionData?.stripeSubscriptionsByParentId?.items || [];
  const subscription = subscriptions.find(
    subscription => subscription.id === subscriptionId,
  );

  const hasAsyncProduct = hasAsync(subscription);

  if (!subscriptionId) {
    return <Redirect to={`/learner/${student._id}/account`} />;
  }

  if (errorSubscriptionData || errorCourseData || errorAvailiabilityData) {
    return (
      <div className="w-full flex justify-center">
        <div className="flex max-w-screen-2xl justify-center items-center m-6">
          <Message fullWidth status="error" outline>
            Sorry, we're having trouble loading your subscription details. Please
            refresh the page to try again or contact {JUNI_EMAILS} if the issue
            persists.
          </Message>
        </div>
      </div>
    );
  }

  if (loadingSubscriptionData || loadingCourseData || loadingAvailiabilityData) {
    return <JuniSpinner size={80} />;
  }

  return (
    <div className="w-full flex justify-center">
      {subscription && courses && (
        <div className="max-w-screen-2xl w-full flex flex-col lg:flex-row sm:p-5 p-4 gap-4 text-j-dark-600 font-graphik items-start justify-center">
          <div className="sm:flex flex-col lg:w-1/3 w-full">
            <CurrentSubscriptionExpandableCard
              student={student}
              subscription={subscription}
            />
          </div>
          <div className="lg:w-2/3 w-full">
            <ManageSubscriptionCard
              student={student}
              hasAsyncProduct={hasAsyncProduct}
              parentId={parent._id}
              courses={courses}
              timezone={timezone}
              subscription={subscription}
              // subscriptions={subscriptions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
