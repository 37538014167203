import React, { FC, useState } from 'react';
import { QueryResult } from '@apollo/client';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { NewButton, RadioGroup } from 'core-components';
import {
  LoadDefaultPaymentInformationByParentIdQuery,
  LoadProductsByStripeIdQuery,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';

import { checkIfStudentHasMoreThanOneWeeklyClassInSubject } from 'utils/stripe';
import { UPDATE_FLOWS } from 'app/learner/LearnerAccountV2/LearnerAccount';
import { getStudentNamesFromStudents } from 'app/learner/LearnerAccountV2/utils';
import LearnerPortalModal from '../LearnerPortalModal';
import { FormState, FormValues } from '../types';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  productsQuery: QueryResult<LoadProductsByStripeIdQuery>;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
}

const SubscriptionUpdateModal: FC<Props> = ({
  formState,
  formValues,
  updateFormState,
  studentsQuery,
  productsQuery,
  cardQuery,
}) => {
  const [updateFlow, setUpdateFlow] = useState('');
  const history = useHistory();
  const { subscription } = formValues;
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );
  const products = productsQuery?.data?.productsByStripeIds?.items || [];
  const studentHasMoreThanOneWeeklyClassInSubject = checkIfStudentHasMoreThanOneWeeklyClassInSubject(
    subscription,
    products,
  );
  const card = cardQuery?.data?.defaultPaymentInformationByParentId || {};

  return (
    <LearnerPortalModal
      title="Manage Subscription"
      updateFormState={updateFormState}
      formState={formState}
      renderPrimaryButton={
        currentStudents.length ? (
          <NewButton
            onClick={() => {
              let modalName = 'course_select';
              if (updateFlow === UPDATE_FLOWS.CANCEL) {
                modalName =
                  currentStudents[0].hasMultipleTracks ||
                  studentHasMoreThanOneWeeklyClassInSubject
                    ? 'downgrade_select'
                    : 'cancel_check';
              }

              JuniAnalytics.track(`${formState.modal}_button_clicked`, {
                funnel: 'subscription_upgrade_downgrade',
                sourceModal: formState.modal,
                destinationModal: modalName,
                actionFlow: formState.updateFlow,
              });
              if (updateFlow === UPDATE_FLOWS.NEW_CLASS) {
                history.push(
                  `/learner/${formValues.currentStudent?._id}/subscription?id=${formValues.subscription?.id}`,
                );
              }

              updateFormState(updateFlow, 'updateFlow');
              updateFormState(modalName, 'modal');
            }}
            disabled={updateFlow === ''}
          >
            <div className="font-medium">Next</div>
          </NewButton>
        ) : (
          <NewButton
            className="text-sm"
            onClick={() => {
              window.open(`mailto:support@learnwithjuni.com`, '_blank');
            }}
          >
            Email Support
          </NewButton>
        )
      }
    >
      {_.isEmpty(card) ? (
        <div className="my-2.5 text-base">
          To make changes to this subscription, please add a payment method.
        </div>
      ) : currentStudents.length === 0 ? (
        <div className="my-2.5 text-base">
          To make changes to this subscription, please reach out to{' '}
          <a href="mailto:support@learnwithjuni.com">support@learnwithjuni.com</a>.
        </div>
      ) : (
        <div className="flex flex-col text-j-dark-600">
          <div className="mb-2 text-sm font-medium flex">
            {`Select a change for ${getStudentNamesFromStudents(
              currentStudents,
            )}'s subscription.`}
          </div>
          <div className="flex flex-col space-y-2">
            <RadioGroup
              name="student"
              orientation="vertical"
              options={[
                {
                  label:
                    'I would like to add a new course or increase class frequency for this subscription.',
                  value: UPDATE_FLOWS.NEW_CLASS,
                },
                {
                  label:
                    'I would like to downgrade, pause, or cancel this subscription.',
                  value: UPDATE_FLOWS.CANCEL,
                },
              ]}
              onChange={selected => {
                setUpdateFlow(selected);
              }}
              selectedValue={updateFlow}
            />
          </div>
        </div>
      )}
    </LearnerPortalModal>
  );
};

export default SubscriptionUpdateModal;
