export enum STATUS {
  NEW = 'new',
  READY_TO_ONBOARD = 'ready_to_onboard',
  AVAILABILITY_NEEDED = 'availability_needed',
  WFR_FROM_PARENT = 'wfr_from_parent',
  PENDING_INSTRUCTOR_ASSIGNMENT = 'pending_instructor_assignment',
  PENDING_ASSIGNMENT_APPROVAL = 'pending_assignment_approval',
  INSTRUCTOR_ASSIGNED = 'instructor_assigned',
  PENDING_INSTRUCTOR_ACCEPTANCE = 'pending_instructor_acceptance',
  INSTRUCTOR_ACCEPTED = 'instructor_accepted',
  INTERNAL_STEPS_NEEDED = 'internal_steps_needed',
  CLOSED = 'closed',
  CANCELED = 'canceled',
}

export const STATUS_DISPLAY_NAME = {
  [STATUS.NEW]: 'New',
  [STATUS.READY_TO_ONBOARD]: 'Ready for Onboarding',
  [STATUS.AVAILABILITY_NEEDED]: 'Availability Needed',
  [STATUS.WFR_FROM_PARENT]: 'WFR Parent',
  [STATUS.PENDING_INSTRUCTOR_ASSIGNMENT]: 'Pending Instructor Assignment',
  [STATUS.PENDING_ASSIGNMENT_APPROVAL]: 'Pending Assignment Approval',
  [STATUS.INSTRUCTOR_ASSIGNED]: 'Instructor Assigned',
  [STATUS.PENDING_INSTRUCTOR_ACCEPTANCE]: 'WFR Instructor',
  [STATUS.INSTRUCTOR_ACCEPTED]: 'Instructor Accepted',
  [STATUS.INTERNAL_STEPS_NEEDED]: 'Internal Steps Needed',
  [STATUS.CLOSED]: 'Closed',
  [STATUS.CANCELED]: 'Canceled',
} as const;

export const STATUSES = Object.values(STATUS).map(status => ({
  value: status,
  displayName: STATUS_DISPLAY_NAME[status],
}));

export const ONBOARDING_STATUSES = [...STATUSES];

export const HANDOFF_STATUSES = [STATUSES[0], ...STATUSES.slice(2)];

export const STATUSES_BY_VALUE = STATUSES.reduce(
  (agg, cur) => ({
    ...agg,
    [cur.value]: cur,
  }),
  {},
);

export enum STEP {
  REQUEST_RECEIVED = 'Request Received',
  PAIRING_INSTRUCTOR = 'Pairing Instructor',
  INSTRUCTOR_CONFIRMED = 'Instructor Confirmed',
  COMPLETED = 'Completed',
}

export const STATUS_TO_PROGRESS: {
  [Key in STATUS]: { step: STEP; message: string };
} = {
  [STATUS.NEW]: {
    step: STEP.REQUEST_RECEIVED,
    message: 'We are reviewing the details of your request.',
  },
  [STATUS.READY_TO_ONBOARD]: {
    step: STEP.REQUEST_RECEIVED,
    message: 'We are reviewing the details to find an instructor match.',
  },
  [STATUS.AVAILABILITY_NEEDED]: {
    step: STEP.PAIRING_INSTRUCTOR,
    message:
      'Oops! We were not able to find a match for the provided class times, we will reach out for more availabilities.',
  },
  [STATUS.WFR_FROM_PARENT]: {
    step: STEP.PAIRING_INSTRUCTOR,
    message:
      'We are waiting to hear back from you! Please respond to the email from us.',
  },
  [STATUS.PENDING_INSTRUCTOR_ASSIGNMENT]: {
    step: STEP.PAIRING_INSTRUCTOR,
    message: 'We are searching for the perfect instructor!',
  },
  [STATUS.PENDING_ASSIGNMENT_APPROVAL]: {
    step: STEP.PAIRING_INSTRUCTOR,
    message: 'We are searching for the perfect instructor!',
  },
  [STATUS.INSTRUCTOR_ASSIGNED]: {
    step: STEP.PAIRING_INSTRUCTOR,
    message: 'We are searching for the perfect instructor!',
  },
  [STATUS.PENDING_INSTRUCTOR_ACCEPTANCE]: {
    step: STEP.PAIRING_INSTRUCTOR,
    message: 'We are searching for the perfect instructor!',
  },
  [STATUS.INSTRUCTOR_ACCEPTED]: {
    step: STEP.INSTRUCTOR_CONFIRMED,
    message: 'An instructor match has been found!',
  },
  [STATUS.INTERNAL_STEPS_NEEDED]: {
    step: STEP.INSTRUCTOR_CONFIRMED,
    message: 'We are just wrapping up the final details.',
  },
  [STATUS.CLOSED]: {
    step: STEP.COMPLETED,
    message: 'Request complete! Enjoy your class!',
  },
  [STATUS.CANCELED]: {
    step: STEP.COMPLETED,
    message:
      'We have canceled this request, if you think this is a mistake please contact us.',
  },
};
