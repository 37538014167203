import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Parent } from 'models';
import UserContext from 'modules/UserContext';
import AccountDetailsSection from './AccountDetailsSection';
import CodeOfConductSection from './CodeOfConductSection';
import PreferencesSection from './PreferencesSection';

export const UPDATE_FLOWS: { [key: string]: string } = {
  NEW_CLASS: 'New Course',
  NEW_STUDENT: 'New Student',
  REENGAGE_CHURN: 'New Subscription',
  REDUCE_COURSES: 'Reduce Courses',
  REDUCE_FREQUENCY: 'Reduce Class Frequency',
  CANCEL: 'Cancel Subscription',
};

interface LearnerAccountProps {
  parent: Parent;
}

const LearnerAccount = ({ parent }: LearnerAccountProps) => {
  const { user } = useContext(UserContext);
  const isGuest = user?.isGuest || parent.isGuest;

  const ACCOUNT_TABS: { [key: string]: string } = {
    ACCOUNT_DETAILS: 'Account Details',
    ...(!isGuest ? { PREFERENCES: 'Preferences' } : {}), // only show Preference tab for non-guests
    CODE_OF_CONDUCT: 'Code of Conduct',
  };

  const [activeTab, setActiveTab] = useState(ACCOUNT_TABS.ACCOUNT_DETAILS);

  let TabbedComponent;
  switch (activeTab) {
    case ACCOUNT_TABS.ACCOUNT_DETAILS: {
      TabbedComponent = <AccountDetailsSection parent={parent} isGuest={isGuest} />;
      break;
    }
    case ACCOUNT_TABS.PREFERENCES: {
      TabbedComponent = <PreferencesSection parent={parent} />;
      break;
    }
    case ACCOUNT_TABS.CODE_OF_CONDUCT: {
      TabbedComponent = <CodeOfConductSection />;
      break;
    }
    default: {
      TabbedComponent = <div></div>;
      break;
    }
  }

  return (
    <div className="flex flex-col sm:items-center sm:p-5 p-4 text-j-dark-600 font-graphik">
      <div className="flex flex-col max-w-7xl w-full">
        <div className="text-3xl font-semibold mb-4">My Account</div>
        <div className="flex shadow bg-white rounded-lg py-2 px-4 mb-4">
          {_.map(_.toPairs(ACCOUNT_TABS), ([key, displayName]) => (
            <div
              className={classNames(
                'w-full flex flex-1 rounded-lg cursor-pointer items-center justify-center p-2 sm:text-base text-sm text-center',
                {
                  'bg-j-purple-200 text-j-purple-600 font-semibold':
                    activeTab === displayName,
                  'text-j-dark-600': activeTab !== displayName,
                },
              )}
              key={key}
              onClick={() => setActiveTab(ACCOUNT_TABS[key])}
            >
              {displayName}
            </div>
          ))}
        </div>
        <div>{TabbedComponent}</div>
      </div>
    </div>
  );
};

export default LearnerAccount;
