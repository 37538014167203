import styled from 'styled-components/macro';

export const TextAreaStyles = styled.div<any>`
  align-items: stretch;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
  textarea {
    padding: 12px 16px;
    display: block;
    border-radius: 5px;
    border: 1px solid #e5edf4;
    font-family: 'Graphik', 'Open Sans', sans-serif;
    background: #f7fafc;
    color: #252350;
    font-size: 0.85rem;
    line-height: 1.8em;
    resize: vertical;
    margin: 0;
    width: 100%;
  }
  textarea::placeholder {
    color: #9492b1;
  }
  p,
  ul li {
    font-size: 0.85rem;
    line-height: 1.5em;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

export const SessionNoteCard = styled.div<any>`
  position: relative;
  border: 3px solid #e8f7ff;
  background: #fafdff;
  box-shadow: ${props =>
    props.teacherMode ? '0 8px 16px -4px hsl(208, 46%, 78%)' : 'var(--juni-shadow)'};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  line-height: 1.8em;
  padding: ${props => (props.noPadding ? '0' : '1rem 1.5rem 1.5rem')};
  margin-bottom: 1rem;

  * {
    box-sizing: border-box;
  }
  textarea {
    padding: 12px 16px;
    display: block;
    border-radius: 5px;
    border: 1px solid hsl(194, 39%, 85%);
    font-family: 'Open Sans', sans-serif;
    background: hsl(208, 100%, 98%);
    color: hsl(208, 100%, 11%);
    box-shadow: inset 0 0 10px hsl(208, 36%, 87%);
    font-size: 0.85rem;
    line-height: 1.8em;
    resize: vertical;
    margin: 0;
    width: 100%;
  }
  textarea::placeholder {
    color: hsl(208, 22%, 61%);
  }
  p,
  ul li {
    font-size: 0.85rem;
    color: hsl(201, 21%, 44%);
    font-weight: 400;
    line-height: 1.8em;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

export const CheckboxLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  line-height: initial;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

export const Checkmark = styled.span`
  box-sizing: content-box !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  border: 1px solid #5f5c8a;
  background: hsl(208, 100%, 98%);
  box-shadow: inset 0 0 10px hsl(208, 36%, 87%);

  :after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid hsl(208, 100%, 53%);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  input:checked ~ &:after {
    display: block;
  }
`;

export const Instructions = styled.div`
  ul {
    margin-left: 1.5rem;
    margin-bottom: 0;
    padding: 0;
  }
  ul li {
    padding-left: 0.5rem;
  }
  ul:not(:last-child) {
    margin-bottom: 1rem;
  }
  ul li:not(:last-child) {
    padding-bottom: 0.35rem;
  }
  > :last-child {
    padding-bottom: 1.375rem;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-self: flex-start;
  .icon {
    margin: 0 2px;
  }
  > span {
    padding-left: 6px;
  }
  > div:not(:last-child) {
    margin-right: 12px;
  }
`;

export const CheckboxAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  margin-top: 1.25rem;
  button {
    margin: 0 0 auto auto;
    background-color: hsl(201, 100%, 60%);
    border-radius: 0.5rem;
    .icon,
    :disabled .icon {
      background-color: transparent;
    }
    .icon polyline {
      stroke: #fff;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
