import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import { findStudentById } from 'app/signup_session/lib';
import classNames from 'classnames';
import { format } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';

const ParentStudentInfoSummary = () => {
  const { signupData, activeStudentId, flags } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData);

  const subtitleTextStyle = 'm-0 pb-4 font-medium text-j-dark-600 text-xl';
  return (
    <>
      <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
        <h2 className={classNames(subtitleTextStyle, 'm-0')}>Parent Info</h2>
        <div className="space-y-4">
          <div>
            <p className="m-0 text-j-dark-400 pb-1">Parent Full Name:</p>
            <p className="m-0 text-j-dark-600 font-medium">
              {`${signupData.firstName || ''} ${signupData.lastName || ''}`.trim()}
            </p>
          </div>
          <div>
            <p className="m-0 text-j-dark-400 pb-1">Parent Email:</p>
            <p className="m-0 text-j-dark-600 font-medium">
              {signupData.email ?? '--'}
            </p>
          </div>
          <div>
            <p className="m-0 text-j-dark-400 pb-1">Phone Number:</p>
            <p className="m-0 text-j-dark-600 font-medium">
              {signupData.phone
                ? parsePhoneNumber(signupData.phone).formatInternational()
                : '--'}
            </p>
          </div>
        </div>
      </section>
      <section className="border-0 border-b border-solid border-j-purple-200 pb-6">
        <h2 className={classNames(subtitleTextStyle, 'm-0')}>Student Info</h2>
        <div className="space-y-4">
          <div>
            <p className="m-0 text-j-dark-400 pb-1">Student Full Name:</p>
            <p className="m-0 text-j-dark-600 font-medium">
              {`${student?.firstName || ''} ${student?.lastName || ''}`.trim()}
            </p>
          </div>
          {flags.isPrivateOneOnOne && (
            <div>
              <p className="m-0 text-j-dark-400 pb-1">Student Birthdate:</p>
              <p className="m-0 text-j-dark-600 font-medium capitalize">
                {student?.birthdate
                  ? format(new Date(student?.birthdate), "MM'/'dd'/'yyyy")
                  : '--'}
              </p>
            </div>
          )}
          {flags.isPrivateOneOnOne && (
            <div>
              <p className="m-0 text-j-dark-400 pb-1">Student Gender:</p>
              <p className="m-0 text-j-dark-600 font-medium capitalize">
                {student?.gender ?? '--'}
              </p>
            </div>
          )}
          {flags.isPrivateOneOnOne && (
            <div>
              <p className="m-0 text-j-dark-400 pb-1">Learning preferences:</p>
              <p className="m-0 text-j-dark-600 font-medium">
                {student?.learningStyle ?? '--'}
              </p>
            </div>
          )}
          {(flags.isBootcampSignup || flags.isOnDemandSignup) && (
            <div>
              <p className="m-0 text-j-dark-400 pb-1">Timezone:</p>
              <p className="m-0 text-j-dark-600 font-medium capitalize">
                {student?.timezone ?? '--'}
              </p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ParentStudentInfoSummary;
