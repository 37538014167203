import React, { FC } from 'react';
import { NewButton } from 'core-components';

import LearnerPortalModal from '../LearnerPortalModal';

interface Props {
  parent: any;
  updateFormState: (newValue: any, field: string) => void;
  formState: any;
}

const DowngradeConfirmationModal: FC<Props> = ({
  parent,
  formState,
  updateFormState,
}) => (
  <LearnerPortalModal
    title="Downgrade Request Received"
    updateFormState={updateFormState}
    formState={formState}
    renderPrimaryButton={
      <NewButton
        onClick={() => {
          updateFormState('', 'modal');
        }}
      >
        <div className="font-medium">Close</div>
      </NewButton>
    }
  >
    <div className="flex flex-col text-j-dark-600 leading-6 gap-4">
      <div>We've received your downgrade request and here's what happens next:</div>
      <div className="text-j-dark-400">
        • We will review the information you submitted and follow up if we need any
        additional details to processs your request.
      </div>
      <div className="text-j-dark-400">
        {`• We've sent you an email at ${parent.email} to confirm your request. If you have any questions, you can email us at `}
        <a href="mailto:support@learnwithjuni.com">support@learnwithjuni.com</a>
        {' or give us a call at (650) 263-4306.'}
      </div>
    </div>
  </LearnerPortalModal>
);

export default DowngradeConfirmationModal;
