import { SignupFragment as Signup } from 'generated/graphql';
import { INITIAL_STEP, STEPS, StepName } from './constants';
import { BASE_PATH } from '../hooks/constants';

export const pathToStep = (path: string): StepName | undefined => {
  const pathChunks = path.split('/');
  const lastPath = pathChunks[pathChunks.length - 1];
  const name = Object.entries(STEPS).find(([, step]) => step.path === lastPath)?.[0];
  return name ? (name as StepName) : undefined;
};

export const isPaymentStepComplete = (signup: Signup) =>
  STEPS.payment.isComplete(signup);

interface StepStatus {
  name: StepName;
  isComplete: boolean;
  isPrevStepComplete: boolean;
  isPreventAccess: boolean;
}

/**
 * Walk STEPS to build a list of active steps in this flow
 * along with progress info. Active steps are every step that will be visited
 * based on current answers.
 */
export const getStepProgressSummaries = (signup: Signup) => {
  const stepStatuses: StepStatus[] = [];
  let prevStep;
  let step = STEPS[INITIAL_STEP];
  while (step) {
    stepStatuses.push({
      name: step.name,
      isComplete: step.isComplete(signup),
      isPrevStepComplete: prevStep ? prevStep.isComplete(signup) : true,
      isPreventAccess: step.isPreventAccess(signup),
    });

    const next = step.determineNextStep(signup);
    if (!next) {
      break;
    }
    prevStep = step;
    step = STEPS[next];
  }
  return stepStatuses;
};

/**
 * @returns All steps a user may access, based on a combination of flow rules
 * and signup data. Access is granted if all previous steps are complete,
 * and isPreventAccess is false.
 */
export const getValidSteps = (signup: Signup) => {
  const validSteps: StepStatus[] = [];
  let isAllPrevStepsComplete = true;
  getStepProgressSummaries(signup).forEach(s => {
    if (isAllPrevStepsComplete && !s.isPreventAccess) {
      validSteps.push(s);
    }
    if (!s.isComplete) {
      isAllPrevStepsComplete = false;
    }
  });
  return validSteps;
};

export const getCurrentStepIndex = (signup: Signup, currentStep: StepName) =>
  getValidSteps(signup).findIndex(s => s.name === currentStep);

export const getValidPaths = (signup: Signup) =>
  getValidSteps(signup).map(step => STEPS[step.name].path);

export const isOnValidStep = (signup: Signup, pathname: string) =>
  getValidPaths(signup).some(validPath => pathname === `/${BASE_PATH}/${validPath}`);

export const getFirstIncompleteStep = (signup: Signup): StepName => {
  const validSteps = getValidSteps(signup);
  const firstIncompleteStepIndex = validSteps.findIndex(s => !s.isComplete);
  return validSteps[Math.max(firstIncompleteStepIndex, 0)].name;
};

export const getLastCompleteStep = (signup: Signup): StepName => {
  const validSteps = getValidSteps(signup);
  const firstIncompleteStepIndex = validSteps.findIndex(s => !s.isComplete);
  const lastCompleteStepIndex =
    firstIncompleteStepIndex <= 0 ? 0 : firstIncompleteStepIndex - 1;
  return validSteps[lastCompleteStepIndex].name;
};
