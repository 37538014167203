import React, { FC, useEffect, useState } from 'react';
import { StudentWorkModalProps } from 'app/instructor/StudentProfile/components/StudentProfileModule/CustomProjectList/CustomProjectList';
import {
  StudentProject,
  useGetJuniverseUserProfileByUsernameQuery,
} from 'generated/graphql';
import { Link, RouteComponentProps } from 'react-router-dom';
import { recordProjectView, studentProjectLookupPublic } from 'services/juniverse';
import { humanizeTimeAgo } from 'utils/dates';
import classNames from 'classnames';
import { JuniLogo } from 'components/brand-assets';
import { Icon } from 'core-components';
import { JuniIDEWrapperPublic } from 'components/jide';
import { buildEmailLink } from 'utils/social_share/social_share';
import JuniSpinner from 'components/JuniSpinner';
import FitVid from 'components/FitVid';
import { ProjectTypes } from 'app/playground/models';
import SocialShareButton from 'app/learner/SocialShareButton';
import PublicPage from 'app/learner/PublicPage';
import { useUserContext } from 'modules/UserContext';
import Button from 'core-components/NewButton';
import { DCF_DEFAULT_ENTRY_POINT } from 'constants/links';
import { JuniverseNotFound } from '../JuniverseComponents';
import JuniverseProjectCreator from './JuniverseProjectCreator';
import JuniverseProjectCard from './JuniverseProjectCard';

interface RouterProps {
  studentProjectId: string;
}

type Props = RouteComponentProps<RouterProps>;

interface JuniverseProjectState {
  isLoading?: boolean;
  initNav?: StudentWorkModalProps;
  juniverseProject?: StudentProject;
  title?: string;
  username?: string;
  envType?: string;
  canCodeBePublic?: boolean;
  isPublic?: boolean;
  likes?: number;
  runs?: number;
  createdAt?: string;
  lastUpdatedAt?: string;
  courseName?: string;
  studentProjectId?: string;
  projectType?: ProjectTypes;
  projectVideos?: string[];
  userType?: string;
  originalUserType?: string;
  viewCount?: number;
}

const initialState: JuniverseProjectState = {
  isLoading: true,
  canCodeBePublic: false,
  isPublic: false,
  likes: 0,
  runs: 0,
  viewCount: 0,
};

const RECENT_PROJECTS_LIMIT = 2;

const JuniverseProject: FC<Props> = ({ match }) => {
  const [state, setState] = useState(initialState);
  const {
    data: juniverseProfileData,
    loading: juniverseProfileLoading,
  } = useGetJuniverseUserProfileByUsernameQuery({
    variables: { username: state?.username || '' },
  });
  const { user } = useUserContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const initialFetch = async () => {
      setState({ isLoading: true });
      const { studentProjectId } = match.params;

      if (studentProjectId) {
        const juniverseProject = await studentProjectLookupPublic(studentProjectId);

        if (juniverseProject) {
          const originalProject =
            juniverseProject.originalProject || juniverseProject;
          const initNav = {
            student: juniverseProject.studentId
              ? juniverseProject.studentId
              : juniverseProject.teacherId,
            course: juniverseProject.courseId,
            module: juniverseProject.moduleId,
            project: juniverseProject.projectId,
          };

          setState({
            initNav,
            juniverseProject,
            title: juniverseProject.title,
            username: juniverseProject.username,
            canCodeBePublic: juniverseProject.canCodeBePublic,
            isPublic: juniverseProject.isPublic,
            likes: juniverseProject.likes,
            runs: juniverseProject.runs,
            createdAt: juniverseProject.createdAt,
            lastUpdatedAt: juniverseProject.lastUpdatedAt,
            courseName: juniverseProject.courseName,
            studentProjectId,
            projectType: juniverseProject.projectType,
            userType: juniverseProject.teacherId ? 'instructor' : 'student',
            originalUserType: originalProject.teacherId ? 'instructor' : 'student',
            projectVideos: juniverseProject.attachedUsermediaUrls || [],
            viewCount: originalProject.viewCount || 0,
            isLoading: false,
          });

          recordProjectView(juniverseProject._id)
            .then(wasRecorded => {
              if (wasRecorded) {
                setState(prevState => ({
                  ...prevState,
                  viewCount: (prevState.viewCount || 0) + 1,
                }));
              }
            })
            .catch(err =>
              // all GraphQL errors are automatically logged to Sentry
              console.error(`Unable to record project view`, err),
            );
        }
      }
      setState(prevState => ({ ...prevState, isLoading: false }));
    };

    initialFetch();
  }, [match.params]);

  const jideCheck = () =>
    !state.isLoading &&
    state.isPublic &&
    state.initNav &&
    state.title &&
    state.username;

  const setJuniverseEnvType = (envType: string) => {
    setState(prevState => ({ ...prevState, envType }));
  };

  const lastUpdatedAgo = humanizeTimeAgo(state?.lastUpdatedAt || state?.createdAt);
  const projectVideo =
    state.projectVideos && !!state.projectVideos.length
      ? state.projectVideos[0]
      : undefined;

  const viewCountString = state.viewCount
    ? `${state.viewCount} view${state.viewCount > 1 ? 's' : ''}`
    : '';

  const profileLink = `/juniverse/users/${encodeURIComponent(state.username || '')}`;

  const containerStyles = classNames('jverse-container relative font-graphik', {
    scratch: !!state.envType && state.envType === 'scratch',
    java: !!state.envType && state.envType === 'java',
    whiteboard: !!state.envType && ['math', 'whiteboard'].includes(state.envType),
  });

  const recentProjects:
    | StudentProject[]
    | undefined = juniverseProfileData?.juniverseUserProfileByUsername?.publicProjects
    .filter(project => project._id !== state.juniverseProject?.originalProject?._id)
    .sort(
      (p1, p2) =>
        new Date(p2?.lastUpdatedAt || '').getTime() -
        new Date(p1?.lastUpdatedAt || '').getTime(),
    )
    .slice(0, RECENT_PROJECTS_LIMIT);

  const juniverseProjectLink = `https://app.junilearning.com${window.location.pathname}`;

  return (
    <section className={containerStyles}>
      <div className="absolute top-0 left-0 w-full h-80 bg-j-purple-600 z-0" />
      <div className="main-container relative">
        <nav className="flex justify-between items-center flex-wrap pt-4 pb-10">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://junilearning.com"
          >
            <JuniLogo color="light" className="pr-2" />
          </a>
          {(!user || (user && user.userType === 'guest')) && (
            <Button intent="success" size="large" href={DCF_DEFAULT_ENTRY_POINT}>
              Sign Up
            </Button>
          )}
          <ul className="flex w-full list-none p-0 pt-12 m-0 uppercase text-xs">
            <li>
              <a
                className="text-white whitespace-nowrap no-underline"
                href="/student-projects/"
              >
                Student Projects Home
              </a>
              <strong className="text-j-purple-300 px-2">/</strong>
            </li>
            <li>
              <Link
                className="text-white no-underline whitespace-nowrap"
                to={profileLink}
              >
                {state.username}
              </Link>
              {state.title && <strong className="text-j-purple-300 px-2">/</strong>}
            </li>
            {state.title && (
              <li>
                <h3 className="m-0 text-xs font-normal text-j-purple-300 line-clamp-1">
                  {state.title}
                </h3>
              </li>
            )}
          </ul>
        </nav>

        {/* SIDE SECTION */}
        <div className="flex pb-20 flex-col lg:flex-row">
          <aside className="lg:max-w-xs max-w-none pr-6 w-full order-last lg:order-none mt-6 lg:mt-0">
            <JuniverseProjectCreator
              studentId={state?.juniverseProject?.originalProject?.studentId || ''}
              instructorId={state?.juniverseProject?.originalProject?.teacherId}
              publicProjects={
                juniverseProfileData?.juniverseUserProfileByUsername
                  ?.publicProjects || []
              }
              username={state.username || ''}
              // TODO: Use this when students can add profile images
              // profileImage={juniverseProfileData?.profileByUsername?.profile?.profileImage?.url}
            />
            {!recentProjects?.length ||
              (juniverseProfileLoading && (
                <div className="p-6 bg-white rounded-lg">
                  <div className="flex justify-center items-center w-10 h-10 m-auto relative pl-20 pt-10">
                    <JuniSpinner size={40} />
                  </div>
                </div>
              ))}
            {!!recentProjects?.length && (
              <div className="p-6 bg-white rounded-lg">
                <h3 className="m-0 font-normal">Recent Projects</h3>
                <ul className="list-none m-0 p-0">
                  {recentProjects?.map(project => (
                    <li key={project._id}>
                      <JuniverseProjectCard project={project} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!!recentProjects?.length && !juniverseProfileLoading && (
              <Link
                className="text-j-dark-600 no-underline leading-10 rounded-lg w-full text-center mt-4 text-sm hidden md:block hover:underline"
                to={profileLink}
              >
                Explore more projects
              </Link>
            )}
          </aside>

          {/* MAIN SECTION */}
          <article className="w-full min-h">
            <div className="p-6 bg-white rounded-lg shadow-1">
              <header className="border-0 border-b border-j-dark-100 border-solid pb-8">
                <div className="flex justify-between">
                  <h1 className="m-0 pb-2 md:pb-0 text-j-dark-600 text-xl leading-6 md:text-3xl md:leading-10 font-graphik font-normal">
                    {state.juniverseProject?.title}
                  </h1>
                  <div className="hidden md:block relative z-10">
                    <SocialShareButton
                      url={juniverseProjectLink}
                      socialShareCopy="Check out my project I made with Juni Learning"
                      emailSubject="My project with Juni Learning!"
                      emailBody={`Check out my project I made with Juni Learning:\n\n${buildEmailLink(
                        juniverseProjectLink,
                      )}`}
                      shareContent="juniverse_project_link"
                    />
                  </div>
                </div>
                <ul className="flex list-none m-0 p-0 text-xs leading-5 text-j-dark-300 md:gap-6 gap-4">
                  {state.username && (
                    <li className="">
                      <Link
                        className="no-underline h-5 flex items-center whitespace-nowrap text-j-dark-300 hover:text-j-dark-600"
                        to={profileLink}
                      >
                        <Icon.User className="w-4 h-5 mr-1" />
                        {state.username}
                      </Link>
                    </li>
                  )}
                  {lastUpdatedAgo && (
                    <li className="h-5 flex items-center whitespace-nowrap">
                      <Icon.Calendar className="w-4 h-5 mr-1" />
                      {lastUpdatedAgo}
                    </li>
                  )}
                  {viewCountString && (
                    <li className="h-5 flex items-center whitespace-nowrap">
                      <Icon.Eye className="w-4 h-5 mr-1" />
                      {viewCountString}
                    </li>
                  )}
                </ul>
                <div className="md:hidden inline-block pt-4 relative z-10">
                  <SocialShareButton
                    url={juniverseProjectLink}
                    socialShareCopy="Check out my project I made with Juni Learning"
                    emailSubject="My project with Juni Learning!"
                    emailBody={`Check out my project I made with Juni Learning:\n\n${buildEmailLink(
                      juniverseProjectLink,
                    )}`}
                    /** TODO Figure out if this is the correct event name */
                    shareContent="juniverse_project_link"
                    dropdownPosition="bottom-left"
                  />
                </div>
              </header>
              {state.juniverseProject?.description && (
                <p className="my-0 leading-6 pt-8">
                  {state.juniverseProject?.description}
                </p>
              )}

              {projectVideo && (
                <div className="mt-8 border border-solid border-j-dark-100 rounded-lg overflow-hidden">
                  <FitVid
                    src={projectVideo}
                    controls
                    unlockAspectRatio
                    autoPlay={false}
                    loop={false}
                    muted={false}
                    noBorderRadius={false}
                  />
                </div>
              )}

              <div
                className={classNames(
                  'flex flex-col flex-1 rounded-lg overflow-hidden',
                  'mb-0 border border-j-dark-100 shadow-none mt-8',
                )}
                style={{ height: '550px' }}
              >
                {state.isLoading ? (
                  <JuniSpinner size={80} />
                ) : !jideCheck() ? (
                  <JuniverseNotFound />
                ) : (
                  <JuniIDEWrapperPublic
                    setJuniverseEnvType={setJuniverseEnvType}
                    initNav={state.initNav}
                    juniverseProjectData={{
                      studentProjectId: state.studentProjectId,
                      createdByUserType: state.userType,
                    }}
                    setHideNavBar={() => ({})}
                    playgroundProject={
                      state.initNav?.course === 'playground'
                        ? {
                            projectType: state.projectType,
                            userType: 'public',
                            projectId: state.initNav?.student,
                          }
                        : undefined
                    }
                    isCodeHidden={
                      state.juniverseProject
                        ? state.juniverseProject.isCodeHidden
                        : false
                    }
                  />
                )}
              </div>
            </div>
          </article>
        </div>
      </div>
      <PublicPage.JuniverseFooter />
    </section>
  );
};

export default JuniverseProject;
