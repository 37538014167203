import { SignupProduct } from 'generated/graphql';

const BULK_COURSES = {
  accelerated_computer_science_college_prep:
    'accelerated_computer_science_college_prep',
  accelerated_computer_science_college_prep_hs:
    'accelerated_computer_science_college_prep_hs',
  ai_machine_learning_deep_dive: 'ai_machine_learning_deep_dive',
  ai_machine_learning_deep_dive_hs: 'ai_machine_learning_deep_dive_hs',
} as const;

export const PRODUCT_METADATA = {
  [SignupProduct.PrivateTutoring]: {
    name: SignupProduct.PrivateTutoring,
    isAllowCoupons: true,
    isSubscription: true,
    courseNames: [] as string[],
    displayName: 'Tutoring',
    price: 275,
    customTimeSlotSettings: undefined,
  },
  // empowerly product
  [SignupProduct.PrivateBulkCs]: {
    name: SignupProduct.PrivateBulkCs,
    isAllowCoupons: false,
    isSubscription: false,
    courseNames: Object.values(BULK_COURSES) as string[],
    customTimeSlotSettings: {
      startHourInPT: 12,
      numDailySlots: 6,
    },
  },
} as const;

export type BulkSubproduct = keyof typeof BULK_COURSES;

// keyed by courseName
export const BULK_SUBPRODUCT_METADATA = {
  [BULK_COURSES.accelerated_computer_science_college_prep]: {
    // todo: look up course and join it like `${courseName} - ${sessionCount}`?
    displayName: 'Computer Science College Prep - 72 sessions',
    price: 4950,
  },
  [BULK_COURSES.accelerated_computer_science_college_prep_hs]: {
    displayName: 'Computer Science College Prep - 48 sessions',
    price: 3300,
  },
  [BULK_COURSES.ai_machine_learning_deep_dive]: {
    displayName: 'Machine Learning Deep Dive - 100 sessions',
    price: 7500,
  },
  [BULK_COURSES.ai_machine_learning_deep_dive_hs]: {
    displayName: 'Machine Learning Deep Dive - 60 sessions',
    price: 4500,
  },
};

/**
 * PRODUCT_METADATA for tutoring has all product-related info, but for BULK_CS,
 * some product related info is determined by which course is selected.
 * In that case, we merge values from PRODUCT_METADATA and BULK_SUBPRODUCT_METADATA
 * to determine all product metadata.
 */
export const getHydratedProductMetadata = (
  productName: SignupProduct,
  courseName?: string | null,
) => {
  if (productName === SignupProduct.PrivateBulkCs) {
    if (!BULK_COURSES[courseName as BulkSubproduct]) {
      throw new Error('must have a course selected');
    }
    return {
      ...PRODUCT_METADATA[productName],
      ...BULK_SUBPRODUCT_METADATA[
        courseName as keyof typeof BULK_SUBPRODUCT_METADATA
      ],
    };
  }

  return PRODUCT_METADATA[productName];
};

export type PRODUCT_METADATA_TYPE = typeof PRODUCT_METADATA[keyof typeof PRODUCT_METADATA];

export const getProductFromCourseName = (courseName?: string | null) => {
  if (!courseName) {
    return undefined;
  }
  return Object.entries(PRODUCT_METADATA).find(([, metadata]) =>
    metadata.courseNames.includes(courseName),
  )?.[0] as SignupProduct | undefined;
};
