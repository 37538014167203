import { Parent } from 'models';

import { QueryResult } from '@apollo/client';
import {
  LoadPaymentHistoryByParentIdQuery,
  LoadDefaultPaymentInformationByParentIdQuery,
} from 'generated/graphql';

import PortalSection from 'components/PortalSection';
import PaymentInformationCard from './PaymentInformationCard';
import PaymentHistoryCard from './PaymentHistoryCard';

interface PaymentSectionProps {
  parent: Parent;
  paymentsQuery: QueryResult<LoadPaymentHistoryByParentIdQuery>;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
}

const PaymentSection = ({
  parent,
  cardQuery,
  paymentsQuery,
}: PaymentSectionProps) => (
  <PortalSection name="Payments">
    <div className="flex flex-col gap-6 w-full">
      <PaymentInformationCard cardQuery={cardQuery} parent={parent} />
      <PaymentHistoryCard paymentsQuery={paymentsQuery} />
    </div>
  </PortalSection>
);

export default PaymentSection;
