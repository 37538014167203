import React, { FC, useState } from 'react';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import juniAxios from 'services/axios';
import Auth from 'modules/Auth';

import { parseError } from 'utils/errors';
import { Icon, NewButton } from 'core-components';
import AuthLayout from '../AuthLayout';

import './login.css';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

enum UserType {
  parent = 'parent',
  instructor = 'teacher',
  admin = 'admin',
}

interface Props {
  checkAuth: () => void;
  userType: UserType;
}

const Login: FC<Props> = ({ checkAuth, userType }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');

    juniAxios
      .post(
        `/auth/login`,
        {
          email,
          password,
          userType,
        },
        config,
      )
      .then(res => {
        Auth.authenticateUser(res.data.token);
        return checkAuth();
      })
      .then(() => {
        const { redirectTo } = queryString.parse(window.location.search);

        if (userType === 'teacher') {
          history.push(redirectTo?.toString() ?? '/teacher');
        } else {
          history.push(redirectTo?.toString() ?? '/learner');
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          console.error(err.response || err);
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage(parseError(err.response.data.message));
        }
      });
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center gap-y-5 w-80 xs:w-96" id="login">
        <div
          className="flex flex-wrap justify-center border border-solid rounded-full sm:p-1 p-0.5 sm:gap-4 gap-0.5 border-j-purple-600 mb-8"
          id="toggle"
        >
          <div
            className={`font-medium text-base leading-6 py-2 px-5 cursor-pointer rounded-full ${
              userType === UserType.parent
                ? `bg-j-purple-600 text-white`
                : `text-j-purple-600`
            }`}
            key="tab-parent"
            onClick={() => {
              history.push(`/learner/login${window.location.search}`);
            }}
          >
            Learner
          </div>

          <div
            className={`font-medium text-base md:leading-6 py-2 px-5 cursor-pointer rounded-full ${
              userType !== UserType.parent
                ? `bg-j-purple-600 text-white`
                : `text-j-purple-600`
            }`}
            key="tab-teacher"
            onClick={() => {
              history.push(`/teacher/login${window.location.search}`);
            }}
          >
            Instructor
          </div>
        </div>

        <div>
          <div className="text-2xl xs:text-3xl font-medium text-j-dark-800 text-center">
            Sign In
          </div>
          {userType === 'parent' ? (
            <div className="text-sm text-j-dark-800 text-center font-normal mt-3">
              New to Juni Learning?{' '}
              <Link
                to="/signup?subjects=Computer+Science"
                className="text-j-purple-500 no-underline"
              >
                Book your placement class here!
              </Link>
            </div>
          ) : (
            <div className="text-sm text-j-dark-800 text-center font-normal mt-3 cursor-pointer">
              Are you a learner?{' '}
              <span
                onClick={() => {
                  history.push(`/learner/login${window.location.search}`);
                }}
                className="text-j-purple-500 no-underline"
              >
                Click here to login as a learner!
              </span>
            </div>
          )}
        </div>

        <form
          className="flex items-center flex-col w-full gap-y-4"
          onSubmit={e => handleSubmit(e)}
        >
          {errorMessage && (
            <div className="form-error-wrap">
              <div className="form-error">{errorMessage}</div>
            </div>
          )}

          <div className={`form-field${errorMessage ? ' error' : ''}`}>
            <div
              className={`flex items-center justify-center px-4 ${
                errorMessage
                  ? 'bg-j-pink-600 text-white'
                  : 'bg-j-gray-300 text-j-gray-600'
              }`}
            >
              <Icon.Email className="w-4 h-4" />
            </div>
            <input
              type="text"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>

          <div className={`form-field${errorMessage ? ' error' : ''}`}>
            <div
              className={`flex items-center justify-center px-4 ${
                errorMessage
                  ? 'bg-j-pink-600 text-white'
                  : 'bg-j-gray-300 text-j-gray-600'
              }`}
            >
              <Icon.Lock className="w-4 h-4" />
            </div>
            <input
              type="password"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </div>

          <div className="w-full flex flex-col gap-4 items-center justify-between pt-4 sm:pt-6">
            <NewButton
              type="submit"
              variant="primary"
              fullWidth
              renderIconRight={props => <Icon.ArrowRight {...props} />}
            >
              Sign In
            </NewButton>
            <Link to="/forgot_password" className="no-underline">
              <div className="text-sm text-j-dark-500">Forgot your password?</div>
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
