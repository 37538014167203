import { Icon, NewButton } from 'core-components';
import { useHistory } from 'react-router-dom';
import { containerStyles } from './constants';

const listItemStyle =
  'rounded-lg bg-j-gray-200 py-4 px-6 text-sm text-j-dark-600 flex space-x-4 mt-3 items-center';

const GoToHomePage = () => {
  const history = useHistory();
  const navigateHome = async () => {
    history.push('/learner');
    window.scrollTo({ top: 0 });
  };

  return (
    <div className={`${containerStyles} items-center`}>
      <div className="text-j-green-400 mb-4">
        <Icon.Success height={78} width={78} />
      </div>
      <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2 text-center">
        You're all set! Leave the rest to us.
      </h1>
      <p className="text-j-dark-300 m-0 pb-2 text-base text-center">
        What happens next?
      </p>
      <ul className="list-none m-0 p-0 text-j-dark-600 w-full">
        <li className={listItemStyle}>
          <span className="w-6">
            <Icon.Email width="24" height="24" />
          </span>
          <span>
            We'll send you easy steps to get ready for class and a class schedule
            confirmation.
          </span>
        </li>
        <li className={listItemStyle}>
          <Icon.CalendarDates width="24" height="24" />
          <span>Meet your Instructor in the 1st class</span>
        </li>
      </ul>
      <div className="mt-4">
        <NewButton fullWidth intent="success" onClick={navigateHome}>
          Go to my Juni Homepage
        </NewButton>
      </div>
    </div>
  );
};

export default GoToHomePage;
