import React, { FormEvent, useState } from 'react';
import { differenceInYears, format } from 'date-fns';

import isBetween from 'utils/isBetween';
import { AGES_TAUGHT } from 'constants/student_ages';
import { NewButton as Button, Card } from 'core-components';
import { Arrow } from 'components/Icons';
import { IconRenderProps } from 'core-components/NewButton/Button';
import { useSignupData } from 'app/signup_2023/hooks';
import InputField from 'app/signup_session/components/InputField';
import WarningBlock from 'app/signup_session/components/WarningBlock';
import { GENDERS } from 'constants/genders';
import Radio from 'app/signup_session/components/Radio';
import { Gender } from 'generated/graphql';
import { getBirthDateValidationError } from './utils';
import { headerTextStyles, labelTextStyles } from '../constants';

export interface LocalState {
  firstName: string;
  lastName: string;
  birthdate: string;
  gender?: Gender;
  learningPreferences: string;
}

const StudentInfo = () => {
  const { signupData, updateSignup, loading, goToPrevStep } = useSignupData();
  const activeStudent = signupData?.students?.[0];

  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const addToTouchedFields = (keyName: string) =>
    setTouchedFields(
      touchedFields.includes(keyName)
        ? [...touchedFields]
        : [...touchedFields, keyName],
    );

  const [localState, setLocalState] = useState<LocalState>({
    firstName: activeStudent?.firstName || '',
    lastName: activeStudent?.lastName || '',
    birthdate: activeStudent?.birthdate
      ? format(new Date(activeStudent.birthdate), 'MM/dd/yyyy')
      : '',
    gender: activeStudent?.gender || undefined,
    learningPreferences: activeStudent?.learningPreferences || '',
  });

  const onLocalChange = (newData: Partial<typeof localState>) =>
    setLocalState({ ...localState, ...newData });

  const studentAge = localState.birthdate
    ? differenceInYears(new Date(), new Date(localState.birthdate))
    : undefined;
  const isAgeOutsideOfRange =
    touchedFields.includes('birthdate') &&
    studentAge !== undefined &&
    !isBetween(AGES_TAUGHT.min, AGES_TAUGHT.max, studentAge);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !signupData ||
      !localState.firstName ||
      !localState.lastName ||
      !localState.birthdate ||
      !localState.gender ||
      !localState.learningPreferences
    ) {
      return;
    }

    updateSignup({
      students: [
        {
          ...activeStudent,
          firstName: localState.firstName.trim(),
          lastName: localState.lastName.trim(),
          birthdate: new Date(localState.birthdate).toISOString(),
          gender: localState.gender,
          learningPreferences: localState.learningPreferences,
        },
      ],
    });
  };

  return (
    <div className="flex flex-col-reverse justify-center items-center">
      <Card
        borderWidth="0"
        className="w-full sm:w-3/5 sm:max-w-screen-xs sm:rounded-lg"
        noRounding
        hideOverflow={false}
      >
        <div>
          <h1 className={headerTextStyles}>Help us get to know your child</h1>
          <form noValidate onSubmit={onSubmit}>
            <InputField
              labelTextStyles={labelTextStyles}
              value={localState.firstName}
              id="firstName"
              label="First Name"
              onBlur={() => addToTouchedFields('firstName')}
              onChange={e => onLocalChange({ firstName: e.target.value })}
              validationError={
                touchedFields.includes('firstName') && !localState.firstName
                  ? 'This field is required'
                  : undefined
              }
            />
            <InputField
              labelTextStyles={labelTextStyles}
              value={localState.lastName}
              id="lastName"
              label="Last Name"
              onBlur={() => addToTouchedFields('lastName')}
              onChange={e => onLocalChange({ lastName: e.target.value })}
              validationError={
                touchedFields.includes('lastName') && !localState.lastName
                  ? 'This field is required'
                  : undefined
              }
            />
            <InputField
              labelTextStyles={labelTextStyles}
              value={localState.birthdate}
              type="date"
              id="birthdate"
              textRight="MM/DD/YYYY"
              label="Date of Birth"
              onBlur={() => addToTouchedFields('birthdate')}
              onChange={e =>
                onLocalChange({
                  birthdate: e.target.value,
                })
              }
              validationError={getBirthDateValidationError(
                localState.birthdate,
                touchedFields.includes('birthdate'),
              )}
            />
            {isAgeOutsideOfRange && (
              <WarningBlock>
                We currently don't teach kids outside of ages 7-18. Please check back
                later, as we're always expanding our offerings!
              </WarningBlock>
            )}
            <div className="my-2">
              <h2 className={labelTextStyles}>Gender</h2>
              <div>
                {GENDERS.map(gender => (
                  <button
                    type="button"
                    key={gender.value}
                    id="gender"
                    className="flex space-x-3 tracking-normal bg-transparent normal-case border-none text-j-dark-600 text-sm ignore-juni-globals"
                    onClick={() => onLocalChange({ gender: gender.value })}
                    onBlur={() => addToTouchedFields('gender')}
                  >
                    <Radio selected={gender.value === localState?.gender} />
                    <span>{gender.label}</span>
                  </button>
                ))}
              </div>
            </div>
            <InputField
              labelTextStyles={labelTextStyles}
              value={localState.learningPreferences}
              type="textarea"
              id="learningPreferences"
              label="Other Preferences"
              helperText="Please list any details about your child's learning style and personality and include any instructor preferences"
              onBlur={() => addToTouchedFields('learningPreferences')}
              onChange={e => onLocalChange({ learningPreferences: e.target.value })}
            />
            <div className="w-full flex flex-row space-x-4 py-4">
              <Button
                type="button"
                className="h-12 w-1/3"
                variant="secondary"
                onClick={() => goToPrevStep()}
                renderIconLeft={(props: IconRenderProps) => (
                  <Arrow {...props} orientation="left" />
                )}
              >
                Back
              </Button>
              <Button
                variant="primary"
                className="h-12 w-2/3"
                disabled={
                  !localState.firstName ||
                  !localState.lastName ||
                  !localState.birthdate ||
                  !localState.gender ||
                  !localState.learningPreferences ||
                  isAgeOutsideOfRange ||
                  loading
                }
                type="submit"
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default StudentInfo;
