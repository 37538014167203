import React, { FC } from 'react';
import { TeacherSession } from 'models';
import { Badge, Icon, NewButton } from 'core-components';
import {
  InstructorSearchSelect,
  SearchSelectProps,
  Value,
} from 'components/SearchSelect';

type Status = 'missed' | 'complete' | 'upcoming' | 'incomplete';

const getStatus = (isUpcoming: boolean, teacherSession?: TeacherSession): Status => {
  if (teacherSession?.completed) {
    return teacherSession.wasMissed ? 'missed' : 'complete';
  }
  return isUpcoming ? 'upcoming' : 'incomplete';
};

const getBadgeTextColor = (status: string) => {
  switch (status) {
    case 'complete':
      return 'j-green-600';
    case 'incomplete':
      return 'j-pink-600';
    case 'missed':
      return 'j-orange-700';
    default:
      return 'j-blue-600';
  }
};

const getBadgeBgColor = (status: string) => {
  switch (status) {
    case 'complete':
      return 'j-green-200';
    case 'incomplete':
      return 'j-pink-200';
    case 'missed':
      return 'j-yellow-200';
    default:
      return 'j-blue-200';
  }
};

const StatusTag: FC<{ status: string }> = ({ status }) => (
  <Badge
    backgroundColor={getBadgeBgColor(status)}
    color={getBadgeTextColor(status)}
    size="medium"
    renderIconRight={props => {
      switch (status) {
        case 'complete':
          return <Icon.Check {...props} />;
        case 'incomplete':
          return <Icon.Cross {...props} />;
        case 'missed':
          return <Icon.Warning {...props} />;
        default:
          return <Icon.MoreHoriz {...props} />;
      }
    }}
  >
    <span>{status}</span>
  </Badge>
);

interface NoteHeaderProps {
  deleteSessionNote: () => Promise<void>;
  isUpcoming: boolean;
  teacherMode: boolean;
  teacherSession?: TeacherSession;
  sessionType: string;
  userIsAdmin: boolean;
  editing?: boolean;
  onEditClick: () => void;
  instructorName: string;
  sessionDatetime: string;
  sessionNotesInstructor: Value | null | undefined;
  setSessionNotesInstructor: React.Dispatch<
    React.SetStateAction<Value | null | undefined>
  >;
}

const NoteHeader: FC<NoteHeaderProps> = ({
  deleteSessionNote,
  isUpcoming,
  teacherMode,
  teacherSession,
  sessionType,
  userIsAdmin,
  editing = false,
  onEditClick,
  instructorName,
  sessionDatetime,
  sessionNotesInstructor,
  setSessionNotesInstructor,
}) => {
  const searchCallback: SearchSelectProps['callback'] = option => {
    const { instructorId, name } = option.label;
    setSessionNotesInstructor({ value: instructorId, label: name });
  };

  return (
    <div className="flex flex-row items-center justify-between font-medium pb-1 mb-2">
      <div className="flex flex-col gap-2">
        <div className="font-graphik text-j-dark-600 font-medium text-xl">
          {sessionType}
        </div>
        <div className="text-j-dark-300 font-normal font-graphik text-base">
          {sessionDatetime}
          {editing ? (
            <>
              {' '}
              with...
              <InstructorSearchSelect
                callback={searchCallback}
                value={sessionNotesInstructor}
                showEmail={userIsAdmin}
              />
            </>
          ) : instructorName ? (
            ` with ${instructorName}`
          ) : (
            ''
          )}
        </div>
      </div>

      {/* Buttons & Satus Badge */}
      <div className="flex flex-row gap-2">
        {/* Show Edit when status is Complete or Missed (initial note already submitted and email sent) */}
        {teacherMode && teacherSession?.completed && (
          <NewButton
            variant="secondary"
            size="small"
            onClick={onEditClick}
            icon={!editing}
            title={!editing ? 'Edit Session Notes' : undefined}
          >
            {editing ? 'Cancel Editing' : <Icon.PencilTool width={16} />}
          </NewButton>
        )}
        {teacherSession?.wasMissed && userIsAdmin && (
          <div>
            <NewButton
              size="small"
              variant="secondary"
              onClick={deleteSessionNote}
              icon
              title="Delete missed session log"
            >
              <Icon.Trash width={16} />
            </NewButton>
          </div>
        )}
        {teacherMode && <StatusTag status={getStatus(isUpcoming, teacherSession)} />}
      </div>
    </div>
  );
};

export default NoteHeader;
