import useGuestAccountLookup from 'app/signup_session/hooks/useGuestAccountLookup';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import UserContext from 'modules/UserContext';
import React, { FC, FormEvent, useContext, useState } from 'react';
import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';
import { differenceInYears, format } from 'date-fns';
import isBetween from 'utils/isBetween';
import { AGES_TAUGHT } from 'constants/student_ages';
import { SignupSessionProps, UserType } from 'app/signup_session/types';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import navStates from 'app/signup_session/navigation/states';
import { NewButton as Button, Card } from 'core-components';
import { Arrow } from 'components/Icons';
import { IconRenderProps } from 'core-components/NewButton/Button';
import TimezoneSelect from 'app/signup_session/Onboarding/pages/SchedulingPreferences/components/TimezoneSelect';
import { Redirect } from 'react-router-dom';
import StudentForm from './Forms/StudentForm';

export interface LocalStudentState {
  name: string;
  birthdate: string;
  timezone: string;
}

const StudentInfo: FC<SignupSessionProps> = ({ history, location }) => {
  const { user } = useContext(UserContext);
  const { signupData, setSignupSession, flags } = useSignupContext();
  const { guestAccount } = useGuestAccountLookup(signupData.invitationLookupId);
  const { getNextPage, hasNextPage } = useNavRouter();

  const signupService = makeSignupSessionsService(signupData._id);
  const activeStudent = signupData.students?.[0];

  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const addToTouchedFields = (keyName: string) =>
    setTouchedFields(
      touchedFields.includes(keyName)
        ? [...touchedFields]
        : [...touchedFields, keyName],
    );

  const [existingUserType, setExistingUserType] = useState<UserType>(undefined);

  const existingFirstName =
    activeStudent?.firstName ?? (user?.isGuest ? user?.firstName : undefined) ?? '';
  const existingLastName =
    activeStudent?.lastName ?? (user?.isGuest ? user?.lastName : undefined) ?? '';

  const existingName =
    !existingFirstName && !existingLastName
      ? ''
      : `${existingFirstName} ${existingLastName}`.trim();

  const [localStudentState, setLocalStudentState] = useState<LocalStudentState>({
    name: existingName,
    birthdate:
      activeStudent?.birthdate !== undefined
        ? format(
            new Date(activeStudent.birthdate ?? guestAccount?.birthDate),
            'MM/dd/yyyy',
          )
        : '',
    timezone: activeStudent?.timezone ?? '',
  });

  if (
    hasNextPage(navStates.signup.studentInfo, ROUTE_EVENT.LOAD, {
      signupData,
      search: location.search,
      shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
    })
  ) {
    return (
      <Redirect
        to={getNextPage(navStates.signup.studentInfo, ROUTE_EVENT.LOAD, {
          signupData,
          search: location.search,
          shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
        })}
      />
    );
  }

  const onLocalStudentChange = (newData: Partial<typeof localStudentState>) =>
    setLocalStudentState({ ...localStudentState, ...newData });

  const studentAge = localStudentState.birthdate
    ? differenceInYears(new Date(), new Date(localStudentState.birthdate))
    : undefined;
  const ageIsOutsideOfRange =
    touchedFields.includes('birthdate') &&
    studentAge !== undefined &&
    !isBetween(AGES_TAUGHT.min, AGES_TAUGHT.max, studentAge);

  if (
    !localStudentState.birthdate &&
    guestAccount?.birthDate &&
    parseInt(guestAccount.age, 10) <= 18
  ) {
    setLocalStudentState({
      ...localStudentState,
      birthdate: format(new Date(guestAccount?.birthDate), 'MM/dd/yyyy'),
    });
  }
  if (
    !localStudentState.name &&
    (guestAccount?.firstName || guestAccount?.lastName)
  ) {
    setLocalStudentState({
      ...localStudentState,
      name: `${guestAccount.firstName} ${guestAccount.lastName}`.trim(),
    });
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!signupData.email) {
      return;
    }

    const fetchUserIfExists = async (): Promise<
      | {
          userType: UserType;
          invitationLookupId?: string;
          inviteeStudentId: string;
        }
      | undefined
    > => {
      if (!signupData.email) {
        return;
      }
      try {
        const existingUserResponse = await signupService.detectExistingUser(
          signupData.email,
        );

        if (!existingUserResponse?.data) {
          throw new Error('Missing response body');
        }

        const {
          userType,
          invitationLookupId,
          inviteeStudentId,
        } = existingUserResponse.data;

        return {
          userType,
          invitationLookupId,
          inviteeStudentId,
        };
      } catch (e) {
        console.error(e);
        alert(
          'We are having trouble connecting to our database. Please try again in a few moments.',
        );
      }
    };

    const existingUser = await fetchUserIfExists();

    setExistingUserType(existingUser?.userType);

    // if we the user type is set, and the user is not logged in as a guest
    if (
      (!signupData.invitationLookupId &&
        existingUser?.userType !== undefined &&
        !user?.isGuest) ||
      (existingUser?.userType && existingUser?.userType !== 'guest')
    ) {
      return;
    }
    const splitName = localStudentState.name.split(' ');
    const firstName = splitName[0].trim();
    const lastName = splitName.slice(1).join(' ').trim();
    setSignupSession({
      students: signupData.students?.map(student =>
        student._id === activeStudent?._id
          ? {
              ...student,
              _id: existingUser?.inviteeStudentId ?? student._id,
              firstName,
              lastName,
              birthdate: localStudentState.birthdate
                ? new Date(localStudentState.birthdate)
                : undefined,
              timezone: localStudentState.timezone,
            }
          : student,
      ),
    });

    history.push(
      getNextPage(navStates.signup.studentInfo, ROUTE_EVENT.SUBMIT, {
        signupData,
        search: location.search,
      }),
    );
  };

  const studentProps = {
    touchedFields,
    localStudentState,
    onLocalStudentChange,
    addToTouchedFields,
    ageIsOutsideOfRange,
    setTouchedFields,
    existingUserType,
    setExistingUserType,
  };

  return (
    <div className="flex flex-col-reverse justify-center items-center">
      <Card
        borderWidth="0"
        className="w-full sm:w-3/5 sm:max-w-screen-xs sm:rounded-lg"
        noRounding
        hideOverflow={false}
      >
        <div>
          {!flags.isBootcampSignup && !flags.isOnDemandSignup && (
            <h2 className="text-center">Get started!</h2>
          )}
          <form noValidate onSubmit={onSubmit}>
            <StudentForm {...studentProps} hideTitle />
            {(flags.isBootcampSignup || flags.isOnDemandSignup) && (
              <TimezoneSelect
                shouldUpdateOnboarding={false}
                handleSetTimezone={(timezone: string) =>
                  onLocalStudentChange({ timezone })
                }
              />
            )}
            <div className="w-full flex flex-row space-x-4 py-4">
              <Button
                className="h-12 w-1/3"
                variant="secondary"
                onClick={() =>
                  history.push(
                    getNextPage(navStates.signup.studentInfos, ROUTE_EVENT.BACK, {
                      signupData,
                      search: location.search,
                    }),
                  )
                }
                renderIconLeft={(props: IconRenderProps) => (
                  <Arrow {...props} orientation="left" />
                )}
              >
                Back
              </Button>
              <Button
                variant="primary"
                className="h-12 w-2/3"
                disabled={
                  (flags.isBootcampSignup || flags.isOnDemandSignup
                    ? !localStudentState.name
                    : !localStudentState.name || !localStudentState.birthdate) ||
                  ageIsOutsideOfRange ||
                  (user && !user?.roles?.includes('admin') && !user?.isGuest)
                }
                type="submit"
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default StudentInfo;
