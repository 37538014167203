import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import ImageUploadField, { ImageUploadCanvas } from 'components/ui/ImageUploadField';
import Overlay from 'components/Overlay';
import Button from 'core-components/NewButton';
import clickIcon from 'images/computer_click_icon.svg';
import Board from 'utils/whiteboard/board';
import { EDITING_TOOL_CONFIG } from 'utils/whiteboard/tool_util';
import { getPageCount, initWhiteboardInfo } from 'services/whiteboards';
import juniAxios from 'services/axios';
import { jDark, jBlue, jGreen, jPink, jOrange, jYellow } from 'theme/colors';
import { NewButton, Icon, Popover, Message } from 'core-components';
import {
  SyncIndicator,
  Footer,
  ToolControlsInsetDivider,
  LoadingSpinner,
  ToolSelectButton,
  ColorButton,
} from 'components/jide';
import MarkerSVG from './MarkerSVG';
import './whiteboard.css';
import DeprecatedSyncIndicator from './DeprecatedSyncIndicator';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import AlertBanner from '../AlertBanner';
import {
  Chevron,
  EraserIcon,
  GridIcon,
  LineToolIcon,
  ArrowToolIcon,
  RectToolIcon,
  EllipseToolIcon,
  PencilIcon,
  PlusIcon,
  TextIcon,
  ImageIcon,
  UndoIcon,
  RedoIcon,
} from '../Icons';
import Spinner from '../Spinner';

const apiUrl = process.env.REACT_APP_API_URL;

const MAX_PAGES = 5;

function getShapeToolIcon(shape) {
  switch (shape) {
    case 'line':
      return <LineToolIcon />;
    case 'arrow':
      return <ArrowToolIcon />;
    case 'rect':
      return <RectToolIcon />;
    case 'ellipse':
      return <EllipseToolIcon />;
    default:
      return <LineToolIcon />;
  }
}

const shapeTools = {
  shape_line: <Icon.ShapeLineTool />,
  shape_arrow: <Icon.ShapeArrowTool />,
  shape_rect: <Icon.ShapeRectTool />,
  shape_ellipse: <Icon.ShapeEllipseTool />,
};

// note that these colors were updated/changed with the New Horizons restyling
const colors = [
  jDark[600],
  jBlue[400],
  '#6BE4FF',
  jGreen[500],
  '#E64FFF',
  jPink[600],
  jOrange[500],
  jYellow[400],
];

/**
 * @deprecated Old version, replace with ColorButton
 */
const ColorSelectButton = ({ color, onClick, className }) => (
  <button
    value={color}
    onClick={onClick}
    className={`color-select-button ${className}`}
  >
    <div className="color-indicator outer" style={{ background: color }} />
    <div className="color-indicator inner">
      <div style={{ background: color }} />
    </div>
  </button>
);
ColorSelectButton.defaultProps = {
  className: '',
};
ColorSelectButton.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// dotted background pattern for New Horizons
const StyledWhiteboard = styled.div`
  ${({ newHorizons }) =>
    newHorizons &&
    `
    background-image: radial-gradient(circle at center, ${jDark[100]} 1px, transparent 0);
    background-size: 16px 16px;
  `}
`;

class Whiteboard extends React.Component {
  state = {
    tool: 'pencil',
    color: 'black',
    showColorSelect: false,
    showShapeSelect: false,
    showUploadDialog: false,
    renderTrigger: 0,
    isLoadingBoard: this.props.withSaving,
    failedToLoadBoard: false,
    isLoadingPageCount: this.props.withSaving,
    pageCountLoadError: false,
    wsServerIsDown: false,
    lostConnection: false,
    isOffline: false,
    anyUnacked: false,
  };

  constructor(props) {
    super(props);
    this.state.color = props.newHorizons ? jDark[600] : 'black';
  }

  rootBoardId = this.props.id.substring(0, this.props.id.indexOf('/page'));

  componentDidMount() {
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);

    // student- would work as well since they're both the same length
    // If we ever have additional prefixes, we will need to make this logic smarter. For now, it works for the intended cases.
    const prefixLength = 'teacher-'.length;

    const start = ['student-', 'teacher-'].includes(
      this.rootBoardId.substring(0, prefixLength),
    )
      ? prefixLength
      : 0;
    const end = this.rootBoardId.indexOf('/');

    const userId = this.rootBoardId.substring(start, end);

    getPageCount(this.rootBoardId)
      .then(data => {
        this.props
          .onFetchPageData(this.rootBoardId, data.numPages, this.props.pageIndex)
          .then(() => {
            this.setState({ isLoadingPageCount: false });
          });
      })
      .catch(err => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.errorMessage === 'Whiteboard info not found'
        ) {
          // need to initialize whiteboard data
          initWhiteboardInfo(this.rootBoardId, userId)
            .then(data => {
              this.props
                .onFetchPageData(this.rootBoardId, data.numPages)
                .then(() => {
                  this.setState({ isLoadingPageCount: false });
                });
            })
            .catch(err => {
              console.log('Failed to initialize whiteboard page count info');
              console.log(err);
              this.setState({
                isLoadingPageCount: false,
                pageCountLoadError: true,
              });
            });
        } else {
          console.log(err);
        }
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let stateChanged = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.state) {
      if (this.state[key] !== nextState[key]) {
        stateChanged = true;
      }
    }
    return (
      this.props.hidden !== nextProps.hidden ||
      this.props.numPages !== nextProps.numPages ||
      this.props.pageIndex !== nextProps.pageIndex ||
      this.props.shiftKeyIsPressed !== nextProps.shiftKeyIsPressed ||
      stateChanged
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shiftKeyIsPressed !== this.props.shiftKeyIsPressed &&
      this.whiteboard
    ) {
      this.whiteboard.setShiftKeyPressed(this.props.shiftKeyIsPressed);
    }
  }

  componentWillUnmount() {
    if (this.whiteboard) {
      this.whiteboard.disconnect();
    }
    if (this.props.withSaving) {
      window.removeEventListener('offline', this.handleOffline);
      window.removeEventListener('online', this.handleOnline);
    }
  }

  handleOffline = () => {
    this.setState({ isOffline: true });
  };
  handleOnline = () => {
    this.setState({ isOffline: false });
  };

  selectTool = nextTool => {
    this.setState({ tool: nextTool }, () => {
      if (nextTool.includes('shape_')) {
        this.toggleShowShapeSelect();
      }
      if (!nextTool.includes('image')) {
        this.whiteboard.tools.image.removeAllImageEditingHandles();
      }
    });
  };

  handleToolSelect = e => {
    this.selectTool(e.currentTarget.value);
  };
  handleNavToPrevPage = () => {
    if (this.props.pageIndex > 0) {
      this.props.prevPage(this.props.id);
    }
  };
  handleNavToNextPage = () => {
    if (this.props.pageIndex + 1 < this.props.numPages) {
      this.props.nextPage(this.props.id);
    }
  };
  handleAddPage = () => {
    if (this.props.numPages < MAX_PAGES) {
      this.props
        .addPage(this.props.id)
        .then(pageCount => {
          if (pageCount && this.whiteboard) {
            this.whiteboard.broadcast({
              type: 'page_count',
              pageCount,
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  handleUndo = () => {
    if (this.whiteboard) {
      this.whiteboard.undo();
    }
  };
  handleRedo = () => {
    if (this.whiteboard) {
      this.whiteboard.redo();
    }
  };
  toggleShowColorSelect = () => {
    this.setState(prevState => ({ showColorSelect: !prevState.showColorSelect }));
  };
  toggleShowShapeSelect = () => {
    this.setState(prevState => ({ showShapeSelect: !prevState.showShapeSelect }));
  };
  toggleShowUploadDialog = () => {
    this.setState(prevState => ({ showUploadDialog: !prevState.showUploadDialog }));
  };
  selectColor = color => {
    this.setState({
      color,
      showColorSelect: false,
    });
  };
  handleColorSelect = e => {
    this.selectColor(e.currentTarget.value);
  };
  handleClear = () => {
    if (this.whiteboard) {
      this.whiteboard.clear();
    }
  };
  triggerRender = () => {
    this.setState(prevState => ({ renderTrigger: prevState.renderTrigger + 1 }));
  };
  handlePageCountUpdate = pageCount => {
    this.props.onFetchPageData(this.rootBoardId, pageCount);
  };
  stateSetter = nextState => {
    this.setState(nextState);
  };

  /**
   * Returns size of imported image and its original aspect ratio from its uploaded metadata.
   */
  getImageImportBoundedSize = imageMetadata => {
    const {
      defaultComponentDimension,
      initialMaxComponentSize,
      minComponentSize,
    } = EDITING_TOOL_CONFIG;
    let width = imageMetadata.width || defaultComponentDimension;
    let height = imageMetadata.height || defaultComponentDimension;
    const aspectRatio = Math.round((height / width) * 100) / 100;
    if (width > initialMaxComponentSize) {
      width = initialMaxComponentSize;
      height = aspectRatio * width;
    }
    if (width < minComponentSize) {
      width = minComponentSize;
      height = aspectRatio * width;
    }
    if (height > initialMaxComponentSize) {
      height = initialMaxComponentSize;
      width = height / aspectRatio;
    }
    if (height < minComponentSize) {
      height = minComponentSize;
      width = height / aspectRatio;
    }
    return {
      height,
      width,
      aspectRatio,
    };
  };

  /**
   * Creates an image in a default location and size.
   */
  addImageToBoard = async (directS3ImageUrl, imageMetadata) => {
    const {
      defaultComponentPosition,
      defaultComponentDimension,
    } = EDITING_TOOL_CONFIG;
    const size = this.getImageImportBoundedSize(imageMetadata);
    this.handleToolSelect({ currentTarget: { value: 'image' } });
    const fileName = directS3ImageUrl.split('/').pop();
    const S3URL = `https://s3-us-west-1.amazonaws.com/${directS3ImageUrl.replace(
      'https://',
      '',
    )}`;
    const response = await juniAxios
      .get(`/api_whiteboards/validate_image/${encodeURIComponent(S3URL)}`, {})
      .then(res => res.data);
    if (response.error) {
      alert('Something went wrong. Please try again.');
      return;
    }
    if (!response.pass) {
      alert(
        'This image has been flagged by our internal moderation tool. If this message was received in error, please contact support@learnwithjuni.com',
      );
      return;
    }
    this.whiteboard.tools.image.createImage(
      {
        id: this.whiteboard.generateUniqueID('image'),
        x: defaultComponentPosition,
        y: defaultComponentPosition,
        width: size.width || defaultComponentDimension,
        height: size.height || defaultComponentDimension,
        aspectRatio: size.aspectRatio || 1,
        href: `${apiUrl}/api_whiteboards/images/${this.rootBoardId}/${fileName}`,
      },
      {
        updateEditHistory: true,
      },
    );
  };

  render() {
    const {
      id,
      isInModal,
      hidden,
      numPages,
      pageIndex,
      withSaving,
      handleWhiteboardEdit,
      juniverseProjectData,
      newHorizons = false,
      fullScreen = false,
      jideWidgets,
    } = this.props;
    const {
      tool,
      color,
      showColorSelect,
      showShapeSelect,
      showUploadDialog,
      isLoadingBoard,
      isLoadingPageCount,
      pageCountLoadError,
      failedToLoadBoard,
      wsServerIsDown,
      lostConnection,
      isOffline,
      anyUnacked,
    } = this.state;
    let undoDisabled = true;
    let redoDisabled = true;
    let clearDisabled = true;
    let uploadDisabled = true;
    const showImageTools = true;
    const disableEdits = (withSaving && isLoadingBoard) || failedToLoadBoard;
    const disableOnlineFeatures = wsServerIsDown || lostConnection || isOffline;
    if (this.whiteboard) {
      if (this.whiteboard.color !== color) {
        this.whiteboard.color = color;
      }
      if (this.whiteboard.curTool && this.whiteboard.curTool.name !== tool) {
        this.whiteboard.switchToTool(tool);
      }
      undoDisabled = disableEdits || !this.whiteboard.canUndo();
      redoDisabled = disableEdits || !this.whiteboard.canRedo();
      clearDisabled =
        disableEdits || disableOnlineFeatures || !this.whiteboard.canClear();
      uploadDisabled = disableEdits || disableOnlineFeatures;
    }

    const shape = tool.includes('shape_') ? tool.replace('shape_', '') : 'line';
    const curShapeIcon = getShapeToolIcon(shape);
    const pageCountsLoaded = !isLoadingPageCount && !pageCountLoadError;

    const isConnected = !lostConnection && !wsServerIsDown && !isOffline;
    const connectionStatus = isLoadingBoard
      ? 'connecting'
      : isConnected
      ? 'connected'
      : 'offline';

    const saveStatus = isConnected && !anyUnacked ? 'saved' : 'saving';

    return (
      <div
        className={classNames('whiteboard-container', {
          hidden,
          [`${tool.replace(/_/g, '-')}-tool`]: !!tool,
          'bg-j-gray-100': newHorizons,
        })}
      >
        {!newHorizons && (
          <div className="whiteboard-controls-container">
            <div
              className={`whiteboard-controls ${
                juniverseProjectData ? 'condense' : ''
              }`}
            >
              {juniverseProjectData ? (
                <div className="juniverse-heading-container">
                  <div className="heading-img-container">
                    <img src={clickIcon} alt="" />
                  </div>
                  <span id="view-work">
                    View this{' '}
                    {/* createdByUserType is now 'instructor'. 'teacher' is for legacy compatibility. */}
                    {(juniverseProjectData.createdByUserType === 'teacher'
                      ? 'instructor'
                      : juniverseProjectData.createdByUserType) || 'student'}
                    's work below
                  </span>
                </div>
              ) : (
                <>
                  <button
                    className={`whiteboard-control icon-button ${
                      tool === 'pencil' ? 'active' : ''
                    }`}
                    title="Pencil Tool"
                    onClick={this.handleToolSelect}
                    value="pencil"
                  >
                    <PencilIcon />
                  </button>
                  <button
                    className={`whiteboard-control icon-button ${
                      tool === 'eraser' ? 'active' : ''
                    }`}
                    title="Eraser Tool"
                    onClick={this.handleToolSelect}
                    value="eraser"
                  >
                    <EraserIcon />
                  </button>
                  <button
                    className={`whiteboard-control icon-button ${
                      tool === 'text' ? 'active' : ''
                    }`}
                    title="Text Tool"
                    onClick={this.handleToolSelect}
                    value="text"
                  >
                    <TextIcon />
                  </button>
                  {showImageTools && (
                    <button
                      className={`whiteboard-control icon-button ${
                        tool === 'image' ? 'active' : ''
                      }`}
                      title="Edit Image"
                      onClick={this.handleToolSelect}
                      value="image"
                    >
                      <ImageIcon />
                    </button>
                  )}
                  <button
                    className={`whiteboard-control icon-button stroke-only ${
                      tool === 'grid' ? 'active' : ''
                    }`}
                    title="Grid Tool"
                    onClick={this.handleToolSelect}
                    value="grid"
                  >
                    <GridIcon />
                  </button>
                  <div className="shape-select wb-control-select">
                    <button
                      className={`whiteboard-control icon-button stroke-only ${
                        tool.includes('shape_') ? 'active' : ''
                      }`}
                      title={`Shape Tool – ${
                        shape.charAt(0).toUpperCase() + shape.slice(1)
                      }`}
                      onClick={this.handleToolSelect}
                      value={`shape_${shape}`}
                    >
                      {curShapeIcon}
                    </button>
                    {showShapeSelect && (
                      <div className="select-dropdown">
                        <div
                          className="overlay"
                          onClick={this.toggleShowShapeSelect}
                        />
                        <div>
                          <button
                            className={`whiteboard-control icon-button stroke-only ${
                              tool === 'shape_line' ? 'active' : ''
                            }`}
                            title="Shape Tool – Line"
                            onClick={this.handleToolSelect}
                            value="shape_line"
                          >
                            <LineToolIcon />
                          </button>
                          <button
                            className={`whiteboard-control icon-button stroke-only ${
                              tool === 'shape_arrow' ? 'active' : ''
                            }`}
                            title="Shape Tool – Arrow"
                            onClick={this.handleToolSelect}
                            value="shape_arrow"
                          >
                            <ArrowToolIcon />
                          </button>
                          <button
                            className={`whiteboard-control icon-button stroke-only ${
                              tool === 'shape_rect' ? 'active' : ''
                            }`}
                            title="Shape Tool – Rectangle"
                            onClick={this.handleToolSelect}
                            value="shape_rect"
                          >
                            <RectToolIcon />
                          </button>
                          <button
                            className={`whiteboard-control icon-button stroke-only ${
                              tool === 'shape_ellipse' ? 'active' : ''
                            }`}
                            title="Shape Tool – Ellipse"
                            onClick={this.handleToolSelect}
                            value="shape_ellipse"
                          >
                            <EllipseToolIcon />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="color-select wb-control-select">
                    <ColorSelectButton
                      color={color}
                      onClick={this.toggleShowColorSelect}
                      className={`whiteboard-control icon-button ${
                        showColorSelect ? 'active' : ''
                      }`}
                    />
                    {showColorSelect && (
                      <div className="select-dropdown">
                        <div
                          className="overlay"
                          onClick={this.toggleShowColorSelect}
                        />
                        <div>
                          <ColorSelectButton
                            color="black"
                            onClick={this.handleColorSelect}
                            className={`${color === 'black' ? 'active' : ''}`}
                          />
                          <ColorSelectButton
                            color="orange"
                            onClick={this.handleColorSelect}
                            className={`${color === 'orange' ? 'active' : ''}`}
                          />
                          <ColorSelectButton
                            color="yellow"
                            onClick={this.handleColorSelect}
                            className={`${color === 'yellow' ? 'active' : ''}`}
                          />
                        </div>
                        <div>
                          <ColorSelectButton
                            color="lime"
                            onClick={this.handleColorSelect}
                            className={`${color === 'lime' ? 'active' : ''}`}
                          />
                          <ColorSelectButton
                            color="cyan"
                            onClick={this.handleColorSelect}
                            className={`${color === 'cyan' ? 'active' : ''}`}
                          />
                          <ColorSelectButton
                            color="blue"
                            onClick={this.handleColorSelect}
                            className={`${color === 'blue' ? 'active' : ''}`}
                          />
                        </div>
                        <div>
                          <ColorSelectButton
                            color="darkslateblue"
                            onClick={this.handleColorSelect}
                            className={`${
                              color === 'darkslateblue' ? 'active' : ''
                            }`}
                          />
                          <ColorSelectButton
                            color="magenta"
                            onClick={this.handleColorSelect}
                            className={`${color === 'magenta' ? 'active' : ''}`}
                          />
                          <ColorSelectButton
                            color="red"
                            onClick={this.handleColorSelect}
                            className={`${color === 'red' ? 'active' : ''}`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    className="whiteboard-control icon-button"
                    title="Undo"
                    onClick={this.handleUndo}
                    disabled={undoDisabled}
                  >
                    <UndoIcon />
                  </button>
                  <button
                    className="whiteboard-control icon-button"
                    title="Redo"
                    onClick={this.handleRedo}
                    disabled={redoDisabled}
                  >
                    <RedoIcon />
                  </button>
                  <div className="shape-select wb-control-select">
                    {showImageTools && (
                      <button
                        className="whiteboard-control"
                        title="Upload Image"
                        onClick={this.toggleShowUploadDialog}
                        disabled={uploadDisabled}
                      >
                        Upload
                      </button>
                    )}
                    {showUploadDialog && (
                      <div className="select-dropdown">
                        <div style={{ minWidth: '400px' }}>
                          <ImageUploadField
                            uploadDestination="WHITEBOARD_USER_IMAGES"
                            filename={this.rootBoardId} // Backend uses whiteboardId as image path. {whiteboardId}/image_{nano(12)}.{ext}
                            canCancel={false}
                            onChange={(imageUrl, name, meta) => {
                              this.toggleShowUploadDialog();
                              this.addImageToBoard(imageUrl, meta);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    className="whiteboard-control"
                    title="Clear whiteboard"
                    onClick={this.handleClear}
                    disabled={clearDisabled}
                  >
                    Clear
                  </button>

                  <div id="spacer" style={{ flexGrow: 1 }} />

                  <DeprecatedSyncIndicator
                    connected={!lostConnection && !wsServerIsDown && !isOffline}
                    anyUnacked={anyUnacked}
                  />
                  <UnsavedChangesPrompt unsavedChanges={anyUnacked} />

                  <div style={{ marginRight: '12px' }} />
                </>
              )}

              <div className="whiteboard-page-nav">
                <button
                  className="whiteboard-control icon-button"
                  title="Previous Whiteboard Page"
                  onClick={this.handleNavToPrevPage}
                  disabled={
                    isLoadingPageCount || pageCountLoadError || pageIndex === 0
                  }
                >
                  <Chevron orientation="left" />
                </button>
                <div className="whiteboard-control">
                  <b className="page-count-container">
                    {pageIndex + 1} /{' '}
                    {isLoadingPageCount ? (
                      <Spinner size={12} />
                    ) : pageCountLoadError ? (
                      '?'
                    ) : (
                      numPages
                    )}
                  </b>
                </div>
                <button
                  className="whiteboard-control icon-button"
                  title="Next Whiteboard Page"
                  onClick={this.handleNavToNextPage}
                  disabled={
                    isLoadingPageCount ||
                    pageCountLoadError ||
                    pageIndex === numPages - 1
                  }
                >
                  <Chevron orientation="right" />
                </button>
                {!juniverseProjectData && (
                  <button
                    className="whiteboard-control icon-button stroke-only"
                    title="Add a Whiteboard Page"
                    onClick={this.handleAddPage}
                    disabled={
                      isLoadingPageCount ||
                      pageCountLoadError ||
                      disableEdits ||
                      numPages >= MAX_PAGES
                    }
                  >
                    <PlusIcon />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {newHorizons && !!juniverseProjectData && (
          <div className="flex items-center p-4 border-0 border-solid border-j-dark-100 border-b">
            <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-j-purple-100 text-j-purple-500">
              <Icon.Eye />
            </div>
            <div className="ml-3 font-graphik text-j-dark-600 font-semibold">
              View this{' '}
              {/* createdByUserType is now 'instructor'. 'teacher' is for legacy compatibility. */}
              {(juniverseProjectData.createdByUserType === 'teacher'
                ? 'instructor'
                : juniverseProjectData.createdByUserType) || 'student'}
              's work below
            </div>
          </div>
        )}
        <StyledWhiteboard className="whiteboard" newHorizons={newHorizons}>
          <svg
            id="canvas"
            ref={node => {
              if (!this.whiteboard && node) {
                this.whiteboard = new Board(
                  node,
                  color,
                  tool,
                  this.triggerRender,
                  id,
                  withSaving,
                  apiUrl.includes('localhost') ? 'development' : 'production',
                  isInModal,
                  this.handlePageCountUpdate,
                  this.stateSetter,
                  handleWhiteboardEdit,
                  !!this.props.juniverseProjectData,
                );
              }
            }}
          />
          {/* Create triangle-shaped markers to be used in the arrow tool */}
          <MarkerSVG />
          {!newHorizons && (isLoadingBoard || failedToLoadBoard) && (
            <div className="overlay">
              {isLoadingBoard ? (
                <Spinner />
              ) : (
                <AlertBanner
                  className="ws-error-alert"
                  content={
                    <div>
                      There was a problem fetching your whiteboard. Please try
                      reopening this whiteboard.
                      <br />
                      <br />
                      If the error persists, please contact
                      support@learnwithjuni.com.
                    </div>
                  }
                />
              )}
            </div>
          )}
          {newHorizons && (isLoadingBoard || failedToLoadBoard) && (
            <Overlay bgColor="rgba(41, 37, 99, 0.32)">
              {isLoadingBoard ? (
                <LoadingSpinner />
              ) : (
                <Message
                  status="error"
                  description="Please try reopening this whiteboard. If the error persists, please contact support@learnwithjuni.com"
                  fullWidth={false}
                  outline
                >
                  There was a problem fetching your whiteboard
                </Message>
              )}
            </Overlay>
          )}
          {newHorizons && showUploadDialog && (
            <ImageUploadCanvas
              uploadDestination="WHITEBOARD_USER_IMAGES"
              filename={this.rootBoardId} // Backend uses whiteboardId as image path. {whiteboardId}/image_{nano(12)}.{ext}
              onFileUploaded={(imageUrl, meta) => {
                this.toggleShowUploadDialog();
                this.addImageToBoard(imageUrl, meta);
              }}
              className="absolute inset-4"
              accept="image/*" // TODO - CONFIRM WHAT THIS SHOULD BE
              onClose={this.toggleShowUploadDialog}
            />
          )}
        </StyledWhiteboard>
        {newHorizons && (
          <Footer className="flex">
            <div
              className={classNames(
                'flex flex-1 items-center',
                fullScreen ? 'justify-start' : 'justify-between',
              )}
            >
              <div className="flex items-center">
                {!juniverseProjectData && (
                  <Button
                    variant="secondary"
                    title="Add a Whiteboard Page"
                    size="small"
                    onClick={this.handleAddPage}
                    disabled={
                      !pageCountsLoaded || disableEdits || numPages >= MAX_PAGES
                    }
                    icon
                  >
                    <Icon.Plus />
                  </Button>
                )}
                <div
                  className={classNames('font-graphik text-j-dark-600 text-sm', {
                    'ml-4': !juniverseProjectData,
                  })}
                >
                  Page {pageIndex + 1}
                  {pageCountsLoaded && numPages > 1 && `/${numPages}`}
                </div>
                {pageCountsLoaded && numPages > 1 && (
                  <>
                    <ToolSelectButton
                      title="Previous Whiteboard Page"
                      onClick={this.handleNavToPrevPage}
                      className="ml-2"
                      disabled={pageIndex === 0}
                    >
                      <Icon.ArrowLeft />
                    </ToolSelectButton>
                    <ToolSelectButton
                      title="Next Whiteboard Page"
                      onClick={this.handleNavToNextPage}
                      className="ml-1"
                      disabled={pageIndex === numPages - 1}
                    >
                      <Icon.ArrowRight />
                    </ToolSelectButton>
                  </>
                )}
                {!juniverseProjectData && (
                  <>
                    <ToolControlsInsetDivider />
                    <ToolSelectButton
                      title="Pencil Tool"
                      selected={tool === 'pencil'}
                      onClick={() => this.selectTool('pencil')}
                    >
                      <Icon.PencilTool />
                    </ToolSelectButton>
                    <ToolSelectButton
                      title="Eraser Tool"
                      selected={tool === 'eraser'}
                      onClick={() => this.selectTool('eraser')}
                      className="ml-1"
                    >
                      <Icon.EraserTool />
                    </ToolSelectButton>
                    <ToolSelectButton
                      title="Text Tool"
                      selected={tool === 'text'}
                      onClick={() => this.selectTool('text')}
                      className="ml-1"
                    >
                      <Icon.TextTool />
                    </ToolSelectButton>
                    <ToolSelectButton
                      title="Grid Tool"
                      selected={tool === 'grid'}
                      onClick={() => this.selectTool('grid')}
                      className="ml-1"
                    >
                      <Icon.GridTool />
                    </ToolSelectButton>
                    <div className="ml-1 relative">
                      <ToolSelectButton
                        title={`Shape Tool - ${
                          shape.charAt(0).toUpperCase() + shape.slice(1)
                        }`}
                        selected={tool.startsWith('shape_')}
                        onClick={() => this.selectTool(`shape_${shape}`)}
                      >
                        {shapeTools[`shape_${shape}`]}
                      </ToolSelectButton>
                      {showShapeSelect && (
                        <Popover
                          className="absolute bottom-11 -left-1 flex items-center"
                          onClose={this.toggleShowShapeSelect}
                        >
                          {Object.entries(shapeTools).map(
                            ([shapeToolName, icon], index) => {
                              const selected = shapeToolName === `shape_${shape}`;

                              return (
                                <ToolSelectButton
                                  key={shapeToolName}
                                  selected={selected}
                                  onClick={() => this.selectTool(shapeToolName)}
                                  className={classNames({ 'ml-2': index > 0 })}
                                >
                                  {icon}
                                </ToolSelectButton>
                              );
                            },
                          )}
                        </Popover>
                      )}
                    </div>
                    <div className="ml-1 relative">
                      <ToolSelectButton
                        title="Color Tool"
                        onClick={this.toggleShowColorSelect}
                      >
                        <ColorButton color={color} />
                      </ToolSelectButton>
                      {showColorSelect && (
                        <Popover
                          className="absolute bottom-11 -left-1 flex items-center"
                          onClose={this.toggleShowColorSelect}
                        >
                          {colors.map((c, i) => (
                            <ToolSelectButton
                              key={color}
                              selected={c === color}
                              onClick={() => this.selectColor(c)}
                              className={classNames({ 'ml-2': i > 0 })}
                            >
                              <ColorButton color={c} />
                            </ToolSelectButton>
                          ))}
                        </Popover>
                      )}
                    </div>
                    <ToolControlsInsetDivider />
                    <ToolSelectButton
                      title="Upload Image"
                      onClick={this.toggleShowUploadDialog}
                      disabled={uploadDisabled}
                    >
                      <Icon.ImageUpload />
                    </ToolSelectButton>
                    <ToolSelectButton
                      title="Edit Image"
                      onClick={() => this.selectTool('image')}
                      className="ml-1"
                    >
                      <Icon.ImageEdit />
                    </ToolSelectButton>
                    <ToolControlsInsetDivider />
                    <ToolSelectButton
                      title="Undo"
                      onClick={this.handleUndo}
                      disabled={undoDisabled}
                    >
                      <Icon.Undo />
                    </ToolSelectButton>
                    <ToolSelectButton
                      title="Redo"
                      onClick={this.handleRedo}
                      className="ml-1"
                      disabled={redoDisabled}
                    >
                      <Icon.Redo />
                    </ToolSelectButton>
                    <div className="ml-1">
                      <NewButton
                        variant="minimal"
                        size="small"
                        title="Clear whiteboard"
                        onClick={this.handleClear}
                        disabled={clearDisabled}
                      >
                        Clear
                      </NewButton>
                    </div>
                  </>
                )}
              </div>
              {!juniverseProjectData && (
                <div className="flex">
                  {fullScreen && <ToolControlsInsetDivider />}
                  <SyncIndicator
                    connectionStatus={connectionStatus}
                    saveStatus={saveStatus}
                  />
                </div>
              )}
            </div>
            {!!jideWidgets && <div className="flex justify-end">{jideWidgets}</div>}
          </Footer>
        )}
      </div>
    );
  }
}
Whiteboard.defaultProps = {
  hidden: false,
  id: '',
  isInModal: false,
  withSaving: false,
  pageIndex: 0,
  numPages: 1,
  newHorizons: false,
  fullScreen: false,
};
Whiteboard.propTypes = {
  hidden: PropTypes.bool,
  id: PropTypes.string,
  isInModal: PropTypes.bool,
  withSaving: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  handleWhiteboardEdit: PropTypes.func.isRequired,
  pageIndex: PropTypes.number,
  numPages: PropTypes.number,
  onFetchPageData: PropTypes.func.isRequired,
  shiftKeyIsPressed: PropTypes.bool.isRequired,
  newHorizons: PropTypes.bool,
  fullScreen: PropTypes.bool,
};
export default Whiteboard;
