// TO DO: convert this to typescript and clean up these functions! Metadata usage needs to be confirmed.
import {
  CORE_PRICES,
  USACO_PRICES,
  JUNI_JR_PRICES,
  CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME,
  CORE_PRODUCT_METADATA_KEYS,
  ASYNC_PLAN_PRODUCT,
} from 'constants/subscription_plans';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';
import { getSubscriptionMetaData } from 'app/learner/LearnerAccountV2/utils';

import _ from 'lodash';

export const hasWeeklyClassFrequenciesInMetadata = subscription =>
  subscription.metadata &&
  subscription.metadata.version &&
  subscription.metadata.version >= 5;

export const getSubjectLabel = subjectMetadata =>
  Object.entries(subjectMetadata)
    .map(([subject, frequency]) =>
      frequency > 0
        ? `${CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[subject]} ${
            frequency < 1 ? '2x monthly' : `${frequency}x per week`
          }`
        : '',
    )
    .join(', ');

export const extractSubjectMetadata = metadata =>
  _.pickBy(
    _.pick(metadata, Object.keys(CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME)),
    _.truthy,
  );

// display name comes from metadata subject frequencies
export const getSubscriptionDisplayName = subscription => {
  if (hasWeeklyClassFrequenciesInMetadata(subscription)) {
    const subjectMetadata = extractSubjectMetadata(subscription.metadata);
    const subjectDisplayName = getSubjectLabel(subjectMetadata);
    return subjectDisplayName ? `Monthly Membership (${subjectDisplayName})` : '';
  }
  return subscription?.items.data
    .map(subscriptionItem => subscriptionItem.plan.name)
    .join(', ');
};

export const hasAsync = subscription =>
  !!subscription &&
  _.some(
    subscription.items.data,
    subscriptionItem =>
      subscriptionItem.plan.product === ASYNC_PLAN_PRODUCT.productId,
  );

export const getSubscriptionDisplayNameFromSelection = (
  courseOptions,
  selectedPlan,
) => {
  const firstCourseSubjectDisplayName = courseOptions[0]?.label?.replace(
    ' Courses',
    '',
  );
  const secondCourseSubjectDisplayName = courseOptions[1]?.label?.replace(
    ' Courses',
    '',
  );

  const frequencyDisplay =
    selectedPlan === 'prod_core_1'
      ? `1x ${firstCourseSubjectDisplayName} per week`
      : secondCourseSubjectDisplayName === undefined ||
        firstCourseSubjectDisplayName === secondCourseSubjectDisplayName
      ? `2x ${firstCourseSubjectDisplayName} per week`
      : `1x ${firstCourseSubjectDisplayName} per week, 1x ${secondCourseSubjectDisplayName} per week`;

  return `Monthly Membership (${frequencyDisplay})`;
};

export const checkIfStudentHasMoreThanOneWeeklyClassInSubject = (
  subscription,
  products,
) => {
  // subscription format v5+
  if (hasWeeklyClassFrequenciesInMetadata(subscription)) {
    const subjectMetadata = extractSubjectMetadata(subscription.metadata);
    // note: for the purposes of billing, we could USACO, Juni Jr, etc. as different options for downgrade
    return _.some(_.values(subjectMetadata), frequency => frequency > 1);
  }
  const subscriptionProducts = _.find(products, [
    'stripeId',
    subscription.plan?.product,
  ]);
  const subscriptionWeeklyFrequency = subscriptionProducts?.scheduleFrequency.map(
    schedule => schedule.weeklyFrequency,
  );
  return _.some(subscriptionWeeklyFrequency, frequency => frequency > 1);
};

export const extractItemsFromMetadataNew = metadataNew => {
  const results = [];
  // core
  if (
    _.some(metadataNew, (_unused, key) => CORE_PRODUCT_METADATA_KEYS.includes(key))
  ) {
    const frequency = _.reduce(
      _.toPairs(_.pick(metadataNew, CORE_PRODUCT_METADATA_KEYS)),
      (result, [, metadata]) => {
        if (metadata.frequency === 0.5) {
          // note: we may have any number of biweekly subscriptions
          // each subscription should be treated as its own product at the non-discounted core price
          results.push({
            key: 'core_weeklyFrequency',
            frequency: metadata.frequency,
            price: CORE_PRICES[String(metadata.frequency)],
          });
          return result + 0;
        }
        return result + metadata.frequency;
      },
      0,
    );
    // this check handles the case in which the customer has only biweekly classes
    if (frequency > 0) {
      results.push({
        key: 'core_weeklyFrequency',
        frequency,
        price: CORE_PRICES[frequency],
      });
    }
  }
  // usaco
  if (metadataNew.usaco_weeklyFrequency) {
    const { frequency } = metadataNew.usaco_weeklyFrequency;
    results.push({
      key: 'usaco_weeklyFrequency',
      frequency,
      price: USACO_PRICES[frequency],
    });
  }
  // juni jr
  if (metadataNew.juniJr_weeklyFrequency) {
    const { frequency } = metadataNew.juniJr_weeklyFrequency;
    results.push({
      key: 'juniJr_weeklyFrequency',
      frequency,
      price: JUNI_JR_PRICES[frequency],
    });
  }
  return results;
};

export const extractMetadataFromSubscription = (
  subscription,
  courses,
  studentCurrentCourses,
) => {
  const courseMetadataObj = getSubscriptionMetaData(subscription);
  return _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });
};

export const getDisplayNameFromMetadataNew = metadataNew =>
  _.map(
    _.toPairs(metadataNew),
    ([key, metadata]) =>
      `${CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[key]} ${
        metadata.frequency < 1 ? '2x per month' : `${metadata.frequency}x per week`
      }`,
  ).join(', ');

export const isSubscriptionForStudentId = (subscription, studentId) =>
  subscription.status !== 'canceled' &&
  subscription.metadata.studentIds &&
  subscription.metadata.studentIds.includes(studentId.toString());
