import React, { useEffect, useState } from 'react';
import juniAxios from 'services/axios';
import queryString from 'query-string';

import { NewButton, Icon, Input } from 'core-components';
import { useHistory, useLocation } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { useTouchedFields } from 'utils/useTouchedFields';
import { MIN_PASSWORD_LENGTH, CONFIG_HEADERS } from '../constants';
import AuthLayout from '../AuthLayout';

// TO DO: add a loading state, so we don't have a brief momemnt of invalid link
const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isFieldTouched, addToTouchedFields } = useTouchedFields();
  const history = useHistory();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { token } = params;

  useEffect(() => {
    const getTokenValid = async () => {
      try {
        const res = await juniAxios.get(`/auth/check_password_reset_token/${token}`);
        const { data } = res;
        if (data && data.length === 0) {
          setIsTokenValid(false);
        }
        if (!data.isValid) {
          // if the token has already been used, it will return with isValid: false
          setIsTokenValid(false);
        } else if (isAfter(new Date(data.expirationTimestamp), new Date())) {
          setEmail(data.email);
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (err) {
        console.log(err);
        setIsTokenValid(false);
      }
    };
    getTokenValid();
  }, [token]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (password.length < MIN_PASSWORD_LENGTH) {
      setErrorMessage(
        `Your password must be at least ${MIN_PASSWORD_LENGTH} characters.`,
      );
    } else {
      setErrorMessage('');

      juniAxios
        .post(
          `/auth/reset_password`,
          {
            email,
            password,
            token,
          },
          CONFIG_HEADERS,
        )
        .then(res => {
          console.log(res);
          if (res.data.userType === 'teacher' || res.data.userType === 'admin') {
            history.push('/teacher/login');
          } else if (res.data.userType === 'parent') {
            history.push('/learner/login');
          } else {
            history.push('/');
          }
        })
        .catch(err => {
          console.log(err.response);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  return (
    <AuthLayout>
      {isTokenValid ? (
        <form
          className="flex flex-col space-y-4 items-start"
          onSubmit={e => handleSubmit(e)}
        >
          <div className="text-xl sm:text-2xl lg:text-3xl font-medium text-j-dark-700">
            Create a New Password
          </div>

          <div className="text-sm w-full sm:w-96">
            After you create a new password, you will be taken to log in to your
            account.
          </div>

          <div className="form-error" style={{ textAlign: 'center' }}>
            {errorMessage}
          </div>

          <div className="flex flex-col w-full space-y-4">
            <label className="block">
              <div className="text-sm text-j-dark-600 font-medium mb-2">Email</div>
              <Input
                type="email"
                disabled
                fullWidth
                readOnly
                size="small"
                value={email}
              />
            </label>
            <label className="block">
              <div className="text-sm text-j-dark-600 font-medium">New Password</div>
              <div className="text-xs text-j-dark-400 mb-2">
                Must be at least {MIN_PASSWORD_LENGTH} characters long
              </div>
              <Input
                type="password"
                name="password"
                fullWidth
                message={
                  !isFieldTouched('password') ||
                  password.length >= MIN_PASSWORD_LENGTH
                    ? undefined
                    : `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
                }
                minLength={MIN_PASSWORD_LENGTH}
                placeholder="Set up your password"
                required
                size="small"
                valid={
                  !isFieldTouched('password')
                    ? undefined
                    : password.length >= MIN_PASSWORD_LENGTH
                }
                value={password}
                onBlur={() => addToTouchedFields('password')}
                onChange={e => setPassword(e.target.value)}
              />
            </label>
          </div>

          <NewButton
            type="submit"
            variant="primary"
            intent="info"
            renderIconRight={props => <Icon.ArrowRight {...props} />}
            fullWidth
          >
            Set Password
          </NewButton>
        </form>
      ) : (
        <div className="page-error">This password reset link is invalid.</div>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
