import React, { FC, useEffect, useState } from 'react';
import { JUNIVERSE_TAGS_WITH_COLORS } from 'constants/juniverse_tags';
import { mongoIDToDateStr } from 'utils/timeUtils';
import { StudentProject } from 'generated/graphql';
import ProjectCard from '../ProjectCard/ProjectCard';

interface Props {
  data: StudentProject[];
}

const MAX_PROJ_TO_DISPLAY = 12;

const ProjectsWeLove: FC<Props> = ({ data }) => {
  const [items, setItems] = useState(data);
  const [selectedTag, setSelectedTag] = useState('all');

  useEffect(() => {
    const filteredItems = data
      .filter(project =>
        selectedTag === 'all'
          ? project
          : project.juniverseTags?.includes(selectedTag),
      )
      .sort(
        (p1, p2) =>
          new Date(mongoIDToDateStr(p2._id)).getTime() -
          new Date(mongoIDToDateStr(p1._id)).getTime(),
      )
      .slice(0, MAX_PROJ_TO_DISPLAY);

    setItems(filteredItems);
  }, [data, selectedTag, setItems]);

  const allJuniverseTag = data.flatMap(project => project.juniverseTags);
  const uniqueJuniverseTags = [...new Set(allJuniverseTag)];

  return (
    <section className="max-w-screen-xl w-full flex flex-col gap-6 md:gap-10">
      <div className="text-j-dark-600 text-3xl md:text-5xl font-semibold">
        Projects we love
      </div>

      <nav className="w-full flex flex-wrap gap-1">
        {[{ key: 'all', name: 'All' }, ...JUNIVERSE_TAGS_WITH_COLORS]
          .filter(tag => {
            if (tag.key === 'all' || uniqueJuniverseTags.includes(tag.key)) {
              return true;
            }
            return false;
          })
          .map(tag => (
            <div
              key={tag.key}
              className={`px-4 leading-10 rounded-full text-base  cursor-pointer ${
                selectedTag === tag.key
                  ? 'text-white bg-j-purple-600 font-bold'
                  : 'text-j-purple-600 font-semibold'
              }`}
              role="presentation"
              onClick={() => setSelectedTag(tag.key)}
            >
              {tag.name}
            </div>
          ))}
      </nav>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 md:gap-6">
        {items?.map(project => (
          <div key={project._id}>
            <ProjectCard data={project} size="small" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProjectsWeLove;
