import React, { FC } from 'react';

import LinkCopier from 'components/LinkCopier';
import useReferralCTA from 'app/signup_session/hooks/useReferralCTA';
import { NewButton as Button } from 'core-components';
import classNames from 'classnames';
import giftCard from './assets/gift-card.svg';
import { JoinBannerContent, GiftCardImg } from '../StudentRecap/styles';
import { SIGNUP_STEPS } from '../StudentRecap/constants';

interface ReferralBannerProps {
  parentFirstName: string;
  showTrialButton: boolean;
  onTrialButtonClick: () => void;
}

const JoinBanner: FC<ReferralBannerProps & { referralCode: string }> = ({
  parentFirstName,
  showTrialButton,
  onTrialButtonClick,
  referralCode,
}) => (
  <div className="bg-j-dark-600 flex justify-center items-center">
    <JoinBannerContent>
      <GiftCardImg src={giftCard} alt="Referral Money" />
      <div className="flex flex-col max-w-lg">
        <div className="text-white text-2xl font-medium mb-11">
          {`Join ${parentFirstName}'s family on Juni! Get $50 off your first paid month when you use ${parentFirstName}'s referral code.`}
        </div>
        <div className="flex flex-col m-auto lg:m-0 w-full">
          <div className="text-sm text-j-purple-500 pb-3">Referral code:</div>
          <div className="pb-3">
            <LinkCopier referralCode={referralCode} variant="dark" width="full" />
          </div>
          {showTrialButton && (
            <Button intent="success" onClick={onTrialButtonClick}>
              Get $50 Off
            </Button>
          )}
        </div>
      </div>
    </JoinBannerContent>
  </div>
);

const TrialBanner: FC<ReferralBannerProps> = ({
  showTrialButton,
  onTrialButtonClick,
}) => (
  <div className="bg-white flex flex-col justify-center items-center ">
    <div className="max-w-5xl my-16 mx-6 md:my-20 md:mx-32">
      <div className="text-3xl text-j-dark-600 font-medium text-center mb-20">
        Get started in 3 easy steps
      </div>
      <div className="grid gap-6 md:grid-cols-3 md:mb-20">
        {SIGNUP_STEPS.map(step => (
          <div className="flex flex-col items-center text-center" key={step.icon}>
            <img src={step.icon} alt={step.icon} className="w-28 h-28 mb-10" />
            <div className="text-base text-j-dark-600 mb-16 md:mb-0">
              {step.label}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        {showTrialButton && (
          <Button intent="success" onClick={onTrialButtonClick}>
            Get $50 Off
          </Button>
        )}
      </div>
    </div>
  </div>
);

interface ReferralContentProps {
  parentFirstName: string;
  referralCode: string;
  className?: string;
}

const ReferralContent: FC<ReferralContentProps> = ({
  parentFirstName,
  referralCode,
  className,
}) => {
  const { isLoading, onClaim } = useReferralCTA(referralCode);

  return (
    <div className={classNames('flex flex-col font-graphik', className)}>
      <JoinBanner
        parentFirstName={parentFirstName}
        showTrialButton={!isLoading}
        onTrialButtonClick={() =>
          onClaim({ componentName: 'join_banner_trial_button', referralCode })
        }
        referralCode={referralCode}
      />
      <TrialBanner
        parentFirstName={parentFirstName}
        showTrialButton={!isLoading}
        onTrialButtonClick={() =>
          onClaim({ componentName: 'trial_banner_trial_button', referralCode })
        }
      />
    </div>
  );
};

export default ReferralContent;
