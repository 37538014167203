import React, { FC, useEffect, useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { format, add, startOfDay } from 'date-fns';
import moment from 'moment';
import { sortClubs, MyClubsQueryParams, getClubLink } from 'app/clubs/helpers';
import {
  JuniClubType,
  JuniCommunityEventType,
  useGetCommunityEventsByStartDatesLazyQuery,
  useGetJuniClubBySearchStringLazyQuery,
  useGetJuniClubsByIdsQuery,
  useGetJuniClubsByTagLazyQuery,
} from 'generated/graphql';
import { Button, Card } from 'core-components';
import UsernameCreator from 'components/UsernameCreator';
import PortalSection from 'components/PortalSection';
import {
  MyClubsAnnouncement,
  MyClubsTagSelector,
  ClubCard,
  MyClubsSearchBox,
  MyClubCard,
  SearchZeroState,
  ClubCreator,
  ClubCTABanner,
  ConductModal,
  ClubSection,
  MyClubsEventsCalendarCard,
  EventsCalendarZeroState,
} from 'components/clubs';
import {
  CLUB_DISPLAY_TAGS,
  MAX_CLUB_MEMBERSHIPS_GUEST,
  MAX_CLUB_MEMBERSHIPS_USER,
} from 'constants/clubs';
import UserContext from 'modules/UserContext';
import JuniSpinner from 'components/JuniSpinner';
import { DEFAULT_TIMEZONE } from 'constants/timezones';
import useClubStore, { ClubStoreType } from './stores/ClubStore';
import {
  MyClubsBackground,
  MyClubsContainer,
  Header,
  HeaderLeft,
  HeaderRight,
  Title,
  SubTitle,
  MyClubsContent,
  LeftColumn,
  YourClubs,
  MainColumn,
  MainColumnHeader,
  SearchBox,
  SearchAndTagResults,
  MyClubsTabButton,
  MyClubsFilterSection,
  EventsCalendarDatePickerWrapper,
  EventsCalendarPortalSectionWrapper,
  EventsCalendarCheckedField,
} from './styles/MyClubsStyles';

interface MyClubsProps {
  studentIdParam?: string;
  instructorUserIdParam?: string;
  username?: string;
  pathname: string;
  query?: MyClubsQueryParams;
}

const REQUESTABLE_MODAL_VIEWS = {
  CREATE: 'CREATE',
  JOIN: 'JOIN',
} as const;

const { CREATE, JOIN } = REQUESTABLE_MODAL_VIEWS;

const INITIAL_TAG = CLUB_DISPLAY_TAGS[0].value;

const NUM_DAYS_OF_EVENTS_TO_SHOW = 14;

// IMPORTANT: VARIABLE NAMING CONVENTIONS
// Since there's a bit of overlap between variables that are passed down as props or URL params
// and variables gotten from hooks, API calls, and derived from the ClubStore/Zustand, we have
// defined a naming convention to make things more clear and lessen the chance of race conditions, etc.
// ___Param -> a variable that has been passed down from App.js/LearnerApp.js/InstructorApp.js
//     and/or a URL parameter. These variables are typically the first to change and are good for
//     API calls to depend on
// ___State -> a variable that is taken directly from ClubStore/Zustand. These variables are typically
//     updated after API calls and certain logics in custom hooks, and are slower to change.
//     these are better for UI elements to depend on (things that don't mind re-rendering a couple times)

const MyClubs: FC<MyClubsProps> = ({
  studentIdParam,
  instructorUserIdParam,
  username: existingUsername,
  pathname,
  query = {},
}) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [username, setUsername] = useState(existingUsername);
  const [requestedModalView, setRequestedModalView] = useState<
    keyof typeof REQUESTABLE_MODAL_VIEWS | undefined
  >();
  const [selectedClubToJoin, setSelectedClubToJoin] = useState<JuniClubType>();
  const [searchString, setSearchString] = useState<string | null>(null);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>(INITIAL_TAG);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterByMyClubs, setFilterByMyClubs] = useState<boolean>(false);

  const timezone = moment.tz.guess() || DEFAULT_TIMEZONE;

  const [
    searchJuniClubs,
    { data: searchResultsData, loading: searchResultsDataLoading },
  ] = useGetJuniClubBySearchStringLazyQuery({ fetchPolicy: 'no-cache' });
  const [
    tagJuniClubs,
    { data: tagResultsData, loading: tagResultsDataLoading },
  ] = useGetJuniClubsByTagLazyQuery({ fetchPolicy: 'no-cache' });
  const [
    getCommunityEvents,
    { data: communityEventsData, loading: communityEventsLoading },
  ] = useGetCommunityEventsByStartDatesLazyQuery({ fetchPolicy: 'no-cache' });

  const searchResults = searchResultsData?.getJuniClubBySearchString.items || [];
  const tagResults = tagResultsData?.getJuniClubsByTag.items || [];
  const resultsTemp = selectedTag ? tagResults : searchResults;
  const results =
    user?.userType === 'admin'
      ? resultsTemp
      : resultsTemp.filter(
          jcFromSearch =>
            !['Admin Test Club', 'Juni Party Club'].includes(
              jcFromSearch.displayName,
            ),
        );

  const isClubsLoading = useClubStore(state => state.isClubsLoading);
  const juniClubsState = useClubStore((state: ClubStoreType) => state.juniClubs);
  const unreadMessages = useClubStore(state => state.unreadMessages);

  // if a joinClubId is supplied in the query string
  const { data: joinClubData, loading: joinClubLoading } = useGetJuniClubsByIdsQuery(
    {
      skip: !query.joinClubId,
      variables: {
        ids: [query.joinClubId],
      },
    },
  );
  const joinClub = joinClubData?.getJuniClubsByIds.items[0] || undefined;

  useEffect(() => {
    if (query.joinClubId) {
      // four states of a club to join:
      // don't know yet (loading), already joined, invalid club id, initiate join flow
      const memberOfJoinClub = !!juniClubsState[joinClub?._id];
      const initiateJoinFlow = !!joinClub && !isClubsLoading && !memberOfJoinClub;
      const invalidJoinClubId = !joinClub && !joinClubLoading;

      if (initiateJoinFlow) {
        setRequestedModalView('JOIN');
        setSelectedClubToJoin(joinClub);
      }

      if (initiateJoinFlow || invalidJoinClubId || memberOfJoinClub) {
        // strip joinClubId from the query string once we have an outcome
        // using replace to avoid a specific browser history entry
        // this will also prevent the logic of this effect from firing again
        const newQueryString = queryString.stringify(_.omit(query, 'joinClubId'));
        history.replace(newQueryString ? `${pathname}?${newQueryString}` : pathname);
      }

      if (memberOfJoinClub) {
        history.push(
          getClubLink({ studentId: studentIdParam, juniClubId: joinClub?._id }),
        );
      }
    }
  }, [
    joinClub,
    isClubsLoading,
    juniClubsState,
    history,
    pathname,
    query,
    joinClubLoading,
    studentIdParam,
  ]);

  const filterEvents = (event: JuniCommunityEventType) => {
    if (filterByMyClubs) {
      return _.keys(juniClubsState).some(id => event.juniClubIds.includes(id));
    }
    return true;
  };

  const communityEvents = (
    communityEventsData?.getCommunityEventsByStartDates?.items || []
  ).filter(event => !event.archivedAt);

  const filteredEvents = communityEvents.filter(event => filterEvents(event));

  const eventsByStartDate = _.groupBy(filteredEvents, event =>
    startOfDay(new Date(event?.startDateTime)),
  );

  const eventDates = _.keys(eventsByStartDate);

  const handleSearch = useCallback(() => {
    setSelectedTag(null);
    setSearchQuery(searchString || '');
    searchJuniClubs({
      variables: {
        searchString: searchString || '',
      },
    });
  }, [searchJuniClubs, searchString]);

  const handleTagSelect = useCallback(
    (tag: string) => {
      if (!tag) {
        setSelectedTag(null);
        searchJuniClubs({
          variables: {
            searchString: '',
          },
        });
        return;
      }
      setSelectedTag(tag);
      setSearchQuery(`#${tag}`);
      setSearchString(null);
      if (tag) {
        tagJuniClubs({
          variables: {
            tag,
          },
        });
      }
    },
    [tagJuniClubs, searchJuniClubs],
  );
  // TODO: due to caching it doesn't update the count of the club members
  // after you leave a club until you search again manually

  const handleOnChange = useCallback((e: any) => {
    setSearchString(e.target.value);
  }, []);
  const handleKeyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );
  const handleSelectedDateChange = useCallback(
    (date?: any) => {
      const weeklyDates = new Array(NUM_DAYS_OF_EVENTS_TO_SHOW)
        .fill('')
        .map((item, index) => add(new Date(), { days: index }).toISOString());
      const startDates = date ? [date.toISOString()] : weeklyDates;
      const selectedDate = date ? date.startOf('day').toDate() : date;
      setSelectedDate(selectedDate);
      getCommunityEvents({
        variables: {
          startDates,
          timezone,
        },
      });
    },
    [getCommunityEvents, timezone],
  );

  const handleFilterSelect = (value: string, name: string, checked: boolean) => {
    setFilterByMyClubs(checked);
  };

  useEffect(() => {
    handleTagSelect(INITIAL_TAG);
  }, [handleTagSelect]);

  useEffect(() => {
    setUsername(existingUsername);
  }, [existingUsername]);

  useEffect(() => {
    handleSelectedDateChange();
  }, [handleSelectedDateChange]);

  const guestCanJoin = _.keys(juniClubsState).length < MAX_CLUB_MEMBERSHIPS_GUEST;
  const userCanJoin = _.keys(juniClubsState).length < MAX_CLUB_MEMBERSHIPS_USER;
  const canJoin = (user?.isGuest && guestCanJoin) || (!user?.isGuest && userCanJoin);

  const showCalendar = pathname?.includes('calendar');

  const getBaseUrl = (pathname?: string) => {
    if (pathname?.includes('calendar')) {
      const urlArray = pathname.split('/');
      urlArray.pop();
      return urlArray.join('/');
    }
    return pathname;
  };
  const basePath = getBaseUrl(pathname) || '/';

  const selectedDateVal = selectedDate
    ? format(selectedDate || new Date(), 'MM/dd/yyyy')
    : undefined;

  return isClubsLoading ? (
    <JuniSpinner size={100} />
  ) : (
    <>
      <UsernameCreator
        studentId={studentIdParam}
        instructorUserId={instructorUserIdParam}
        usernameCallback={setUsername}
        isOpen={!username && !!requestedModalView}
        closeModal={() => {
          setRequestedModalView(undefined);
        }}
      />
      <ClubCreator
        studentId={studentIdParam}
        instructorUserId={instructorUserIdParam}
        requiresRefresh={username !== existingUsername}
        modalOpen={requestedModalView === CREATE && !!username}
        handleClose={() => {
          setRequestedModalView(undefined);
          if (username !== existingUsername) {
            window.location.reload();
          }
        }}
      />
      {selectedClubToJoin && (
        <ConductModal
          studentId={studentIdParam}
          instructorUserId={instructorUserIdParam}
          juniClub={selectedClubToJoin}
          canJoin={canJoin}
          requiresRefresh={username !== existingUsername}
          isOpen={requestedModalView === JOIN && !!username}
          handleClose={() => {
            setRequestedModalView(undefined);
            setSelectedClubToJoin(undefined);
            if (username !== existingUsername) {
              window.location.reload();
            }
          }}
        />
      )}
      {studentIdParam && user?.isGuest && (
        <ClubCTABanner studentId={studentIdParam} />
      )}
      <MyClubsBackground>
        <MyClubsContainer>
          <Header>
            <HeaderLeft>
              <Title>Juni Clubs</Title>
              <SubTitle>
                Discover and explore new passions with your friends!
              </SubTitle>
            </HeaderLeft>
            <HeaderRight>
              {!user?.isGuest && user?.userType !== 'parent' ? (
                <Button
                  width="80" // or leave this out if auto is fine
                  hasArrowIcon
                  onClick={() => {
                    setRequestedModalView(CREATE);
                  }}
                  data-cy="club-create-button"
                >
                  Start a New Club
                </Button>
              ) : null}
            </HeaderRight>
          </Header>

          <MyClubsAnnouncement />

          <MyClubsContent>
            <LeftColumn>
              <ClubSection
                studentId={studentIdParam}
                instructorUserId={instructorUserIdParam}
                existingUsername={existingUsername}
                isVertical
                myClubsPage
              />
              <PortalSection name="YOUR CLUBS">
                <YourClubs>
                  {sortClubs(_.values(juniClubsState)).map(juniClub => (
                    <MyClubCard
                      key={juniClub._id}
                      juniClub={juniClub}
                      studentId={studentIdParam}
                      notifications={_.sum(_.values(unreadMessages[juniClub._id]))}
                    />
                  ))}
                </YourClubs>
              </PortalSection>
            </LeftColumn>

            <MainColumn>
              <MainColumnHeader>
                <>
                  <MyClubsTabButton isActive={!showCalendar} to={basePath}>
                    <FontAwesomeIcon icon={faUserFriends} size="xs" />
                    <span>Browse Clubs</span>
                  </MyClubsTabButton>

                  <MyClubsTabButton
                    isActive={showCalendar}
                    to={`${basePath}/calendar`}
                  >
                    <FontAwesomeIcon icon={faCalendar} size="xs" />
                    <span>Calendar</span>
                  </MyClubsTabButton>
                </>
                {showCalendar ? (
                  <MyClubsFilterSection>
                    <EventsCalendarCheckedField
                      onChange={handleFilterSelect}
                      type="checkbox"
                      label="Only show events for my clubs"
                      className="text-xs md:text-sm rounded-2xl truncate h-10 box-border"
                    />
                    <EventsCalendarDatePickerWrapper>
                      <DatePicker
                        onChange={handleSelectedDateChange}
                        value={selectedDateVal}
                        selected={selectedDate ? moment(selectedDate) : null}
                        className="p-2.5 rounded-2xl border-solid border border-2 box-border h-10"
                        minDate={moment()}
                        placeholderText="Search by date"
                        isClearable
                      />
                    </EventsCalendarDatePickerWrapper>
                  </MyClubsFilterSection>
                ) : (
                  <MyClubsFilterSection>
                    <MyClubsTagSelector
                      selectedTag={selectedTag}
                      handleTagSelect={handleTagSelect}
                      clubTags={CLUB_DISPLAY_TAGS}
                    />
                    <SearchBox>
                      <MyClubsSearchBox
                        searchString={searchString}
                        handleOnChange={handleOnChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </SearchBox>
                  </MyClubsFilterSection>
                )}
              </MainColumnHeader>

              {showCalendar ? (
                communityEventsLoading ? (
                  <Card className="bg-juni-blue-50">
                    <div className="flex flex-col justify-center items-center">
                      <JuniSpinner size={60} />
                      <h3 className="m-0 mt-5"> Searching...</h3>
                    </div>
                  </Card>
                ) : (
                  <EventsCalendarPortalSectionWrapper>
                    {eventDates.length ? (
                      eventDates.map(eventDate => (
                        <PortalSection
                          name={format(new Date(eventDate), 'EEEE, MMM dd, yyyy')}
                          key={eventDate}
                        >
                          <MyClubsEventsCalendarCard
                            communityEvents={eventsByStartDate[eventDate]}
                            handleJoinClick={jc => {
                              setSelectedClubToJoin(jc);
                              setRequestedModalView(JOIN);
                            }}
                            studentId={studentIdParam}
                          />
                        </PortalSection>
                      ))
                    ) : (
                      <EventsCalendarZeroState
                        hasResultsWithoutFilter={
                          !filteredEvents?.length ? !!communityEvents.length : false
                        }
                        datesQuery={selectedDateVal || 'the next two weeks'}
                      />
                    )}
                  </EventsCalendarPortalSectionWrapper>
                )
              ) : results.length ? (
                <SearchAndTagResults>
                  {results.map(jcFromSearch => {
                    const isMember = jcFromSearch._id in juniClubsState;
                    const jc = isMember
                      ? juniClubsState[jcFromSearch._id]
                      : jcFromSearch;
                    return (
                      <ClubCard
                        key={jc._id}
                        studentId={studentIdParam}
                        juniClub={jc}
                        isMember={isMember}
                        memberCount={jcFromSearch?.memberCount}
                        handleJoinClick={() => {
                          setSelectedClubToJoin(jc);
                          setRequestedModalView(JOIN);
                        }}
                      />
                    );
                  })}
                </SearchAndTagResults>
              ) : searchResultsDataLoading || tagResultsDataLoading ? (
                <Card className="bg-juni-blue-50">
                  <div className="flex flex-col justify-center items-center">
                    <JuniSpinner size={60} />
                    <h3 className="m-0 mt-5"> Searching...</h3>
                  </div>
                </Card>
              ) : (
                <SearchZeroState searchQuery={searchQuery} />
              )}
            </MainColumn>
          </MyClubsContent>
        </MyClubsContainer>
      </MyClubsBackground>
    </>
  );
};

export default MyClubs;
