import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { ASYNC_PLAN_PRODUCT, formatPrice } from 'constants/subscription_plans';
import { Input, Message, NewButton } from 'core-components';
import { extractItemsFromMetadataNew } from 'utils/stripe';
import SpinnerV2 from 'components/SpinnerV2';
import { Maybe, StripeCoupon, StripeSubscription } from 'generated/graphql';
import {
  getCoupon,
  getDiscountDollarWithCoupon,
  getPlansDisplayName,
} from 'app/learner/LearnerAccountV2/utils';
import { FormValues } from '../../types';

interface Props {
  formValues: FormValues;
  metadataOld: {
    [x: string]: {
      frequency: number;
      courses: any[];
    };
  };
  coupon: Maybe<StripeCoupon> | undefined;
  setCoupon: Dispatch<SetStateAction<Maybe<StripeCoupon> | undefined>>;
  subscriptions: StripeSubscription[] | undefined;
  hasAsyncProduct: boolean;
}

const PricingSection: FC<Props> = ({
  formValues,
  metadataOld,
  coupon,
  setCoupon,
  subscriptions,
  hasAsyncProduct,
}) => {
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponInput, setCouponInput] = useState<string>('');
  const [couponError, setCouponError] = useState<string | undefined>(undefined);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);

  const { isAsyncAdded, metadataNew } = formValues;

  const metadataItems = extractItemsFromMetadataNew(metadataNew);
  if (isAsyncAdded || hasAsyncProduct) {
    const { key, price } = ASYNC_PLAN_PRODUCT;
    metadataItems.push({ key, price, frequency: null });
  }
  const totalPrice = metadataItems.reduce((total, item) => total + item.price, 0);
  const discount = getDiscountDollarWithCoupon(coupon, totalPrice);
  const showRoboticsPaymentMessage = _.difference(
    _.flatMap(_.values(metadataNew), value => value.courses),
    _.flatMap(_.values(metadataOld), value => value.courses),
  ).includes('robotics_1');

  return (
    <div className="flex flex-col gap-2">
      <div className="text-base font-medium">Pricing</div>
      <div className="flex flex-col">
        {metadataItems.map(({ key, frequency, price }) => {
          const displayName = getPlansDisplayName(key, frequency);
          return (
            <div className="w-full flex flex-row justify-between text-j-dark-400">
              <div>{displayName}</div>
              <div>{`${formatPrice(price)}/mo`}</div>
            </div>
          );
        })}
        {coupon && coupon.id && (
          <div className="flex flex-row justify-between text-j-dark-400">
            <div className="">
              {`${coupon.id} (${
                coupon.amount_off
                  ? `$${coupon?.amount_off / 100}`
                  : `${coupon.percent_off}%`
              } off ${
                coupon.duration === 'forever' || coupon.duration === 'once'
                  ? coupon.duration
                  : `for ${coupon.duration}`
              }) `}
            </div>
            <div>
              <div>
                {`-${formatPrice(discount)}${
                  coupon.duration !== 'once' ? '/mo' : ''
                }`}
              </div>
            </div>
          </div>
        )}
        <div className="w-full flex flex-row justify-between text-j-dark-600 ">
          <div>Total</div>
          <div>{`${formatPrice(totalPrice - discount)}${
            (coupon && !coupon.id) ||
            (coupon && coupon.id && coupon.duration !== 'once')
              ? '/mo'
              : ''
          }`}</div>
        </div>
      </div>
      <div className="w-full">
        {showCouponField ? (
          <div className="flex flex-row w-full">
            <div className="mr-2 w-full min-w-max">
              <Input
                size="xsmall"
                fullWidth
                placeholder="Coupon code (case sensitive)"
                name="coupon"
                value={couponInput}
                message={couponError}
                valid={couponError ? false : undefined}
                onChange={event => setCouponInput(event.target.value)}
              />
            </div>
            <div>
              <NewButton
                size="xsmall"
                disabled={!couponInput}
                onClick={async () => {
                  setIsLoadingCoupon(true);
                  if (subscriptions) {
                    const { coupon, couponError } = await getCoupon(
                      subscriptions,
                      couponInput,
                      formValues.currentStudent?._id.toString(),
                    );
                    if (coupon) {
                      setCoupon(coupon);
                      setShowCouponField(false);
                      setCouponError(undefined);
                    } else {
                      setCouponError(couponError);
                    }
                  }
                  setIsLoadingCoupon(false);
                }}
              >
                {isLoadingCoupon ? <SpinnerV2 /> : 'Apply'}
              </NewButton>
            </div>
          </div>
        ) : (
          <NewButton
            size="medium"
            variant="secondary"
            onClick={() => setShowCouponField(true)}
          >
            <span>{coupon && coupon.id ? 'Update Coupon' : 'Add Coupon'}</span>
          </NewButton>
        )}
      </div>
      {showRoboticsPaymentMessage && (
        <div className="w-full mt-4">
          <Message status="info">
            Please note, an additional charge of $95 will be added to the card on
            file to cover the cost of the robot. The robot is non-refundable.
          </Message>
        </div>
      )}
    </div>
  );
};

export default PricingSection;
