import React from 'react';
import { Card } from 'core-components';
import GuestEnrollButton from 'app/learner/components/GuestEnrollButton';
import student_wave from 'images/student_wave.svg';

const GuestAccountCTA = ({ studentId }: { studentId: string }) => (
  <Card borderColor="j-blue-200" className="flex flex-col items-center mt-4">
    <div className="flex flex-col items-center">
      <div className="w-2/3 flex flex-col items-center gap-4">
        <img src={student_wave} alt="student waving" />
        <div className="text-center text-blue-gray-500 text-xl font-bold">
          No classes scheduled yet
        </div>
        <div className="text-center text-blue-gray-500">
          Enroll in a Juni course to start weekly 1:1 classes with an instructor!
        </div>
        <div>
          <GuestEnrollButton studentId={studentId} />
        </div>
      </div>
    </div>
  </Card>
);

export default GuestAccountCTA;
