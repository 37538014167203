import { ChangeEvent, useState } from 'react';
import { format, addDays, parseISO, isValid, startOfDay } from 'date-fns';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { Card, Input } from 'core-components';
import SignupFlowFooter from 'app/signup_session/components/SignupFlowFooter';
import { useSignupData } from 'app/signup_2023/hooks';
import {
  SignupProduct,
  SignupSchedulingPreferenceInput,
  useGetStarterCurriculumQuery,
} from 'generated/graphql';
import { ErrorableLoading } from 'components/ui';
import { PRODUCT_METADATA } from 'app/signup_2023/constants/products';
import { DraggableScheduleSections, TimezoneSelect } from './components';
import { adjustStartingDates, validateStartingDate } from './utils';
import { DAYS_NEEDED_TO_ONBOARD } from './constants';
import { headerTextStyles, labelTextStyles } from '../constants';

export interface LocalState {
  timezone: string;
  numWeeklyClasses: number;
  schedulingPreference: SignupSchedulingPreferenceInput;
  earliestStartingDate: Date;
}

const Scheduling = ({ subjectDisplayName }: { subjectDisplayName?: string }) => {
  const { signupData, updateSignup, loading, goToPrevStep } = useSignupData();
  const activeStudent = signupData?.students?.[0];
  const activeEnrollment = activeStudent?.enrollments?.[0];
  const activeSchedulingPreference = activeEnrollment?.schedulingPreferences?.[0];
  const customTimeSlotSettings =
    (activeEnrollment?.productName &&
      PRODUCT_METADATA[activeEnrollment.productName]?.customTimeSlotSettings) ||
    undefined;

  const firstValidDateForJuni = addDays(
    startOfDay(new Date()),
    DAYS_NEEDED_TO_ONBOARD,
  );

  const [localState, setLocalState] = useState<LocalState>({
    timezone: activeStudent?.timezone || guessTimezoneValue(),
    numWeeklyClasses: 1,
    schedulingPreference:
      activeSchedulingPreference || ([] as SignupSchedulingPreferenceInput),
    earliestStartingDate: activeEnrollment?.earliestStartingDate
      ? new Date(activeEnrollment.earliestStartingDate)
      : firstValidDateForJuni,
  });

  const onLocalChange = (newData: Partial<LocalState>) =>
    setLocalState({ ...localState, ...newData });

  const [earliestStartingDateError, setEarliestStartingDateError] = useState(
    validateStartingDate(localState.earliestStartingDate, firstValidDateForJuni),
  );

  const isStepValid = () => {
    const { dates } = localState.schedulingPreference;
    const isDatesValid =
      dates?.length === 3 && dates.every(date => isValid(new Date(date)));
    return !!(
      localState.timezone &&
      isDatesValid &&
      localState.earliestStartingDate &&
      !earliestStartingDateError
    );
  };

  const onSubmit = async () => {
    if (loading || !isStepValid()) return;

    // adjust each schedulingPreference date to be the first instance
    // of that date >= earliestStartingDate
    const adjustedSchedulingPreference = {
      dates: adjustStartingDates(
        localState.schedulingPreference.dates || [],
        localState.earliestStartingDate,
      ),
    };

    const updatedEnrollment = {
      ...activeEnrollment,
      numWeeklyClasses: localState.numWeeklyClasses,
      earliestStartingDate: localState.earliestStartingDate.toISOString(),
      schedulingPreferences: [adjustedSchedulingPreference],
    };

    updateSignup({
      students: [
        {
          ...activeStudent,
          timezone: localState.timezone,
          enrollments: [updatedEnrollment],
        },
      ],
    });
  };

  return (
    <div className="flex flex-col-reverse justify-center items-center">
      <Card
        borderWidth="0"
        className="w-full sm:w-3/5 sm:max-w-screen-sm sm:rounded-lg"
        noRounding
        hideOverflow={false}
      >
        <header className="border-0 border-b border-solid border-j-purple-200 pb-4 mb-8">
          <h1 className={headerTextStyles}>Class Schedule Preferences</h1>
          <p className="text-j-dark-300 m-0 text-base">
            We'll match {activeStudent?.firstName || 'your student'} to an instructor
            who fits their schedule.
          </p>
        </header>
        <div className="space-y-8">
          <TimezoneSelect localState={localState} onLocalChange={onLocalChange} />
          <DraggableScheduleSections
            localState={localState}
            onLocalChange={onLocalChange}
            subjectDisplayName={subjectDisplayName}
            customTimeSlotSettings={customTimeSlotSettings}
          />
          <div>
            <label className="flex flex-col gap-2">
              <div className={labelTextStyles}>Starting Date</div>
              {earliestStartingDateError && (
                <div className="text-j-pink-700 text-sm">
                  {earliestStartingDateError}
                </div>
              )}
              <div className="text-j-dark-400 text-sm">
                We'll need about a week to get everything ready for you. After that,
                when you start is up to you!
              </div>
              <Input
                type="date"
                size="small"
                fullWidth
                value={format(localState.earliestStartingDate, 'yyyy-MM-dd')}
                min={format(firstValidDateForJuni, 'yyyy-MM-dd')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  try {
                    const value = parseISO(e.target.value);
                    if (!isValid(value)) {
                      return;
                    }

                    const result = validateStartingDate(
                      value,
                      firstValidDateForJuni,
                    );
                    setEarliestStartingDateError(result);
                    onLocalChange({ earliestStartingDate: value });
                  } catch (e) {
                    // noop
                  }
                }}
              />
            </label>
          </div>
          <SignupFlowFooter
            back={{ handler: () => goToPrevStep() }}
            next={{
              disabled: loading || !isStepValid(),
              handler: onSubmit,
            }}
          />
        </div>
      </Card>
    </div>
  );
};

const Wrapper = () => {
  const { signupData, loading: loadingSignup } = useSignupData();
  const activeStudent = signupData?.students?.[0];
  const activeEnrollment = activeStudent?.enrollments?.[0];
  const { productName, courseId } = activeEnrollment || {};

  const { data, error, loading: loadingCurriculum } = useGetStarterCurriculumQuery({
    variables: { input: { ids: [courseId] } },
    skip: !courseId,
  });

  if (loadingCurriculum || loadingSignup || error) {
    return <ErrorableLoading error={error} pageError />;
  }

  const { getCourses: courses } = data || {};
  if (courseId && !courses) {
    return (
      <ErrorableLoading
        error="Unable to fetch curriculum, please try again."
        pageError
      />
    );
  }

  const getDisplayName = () => {
    if (productName === SignupProduct.PrivateTutoring) {
      return PRODUCT_METADATA[SignupProduct.PrivateTutoring].displayName;
    }
    if (courseId && courses) {
      return courses.find(c => c._id === courseId)?.subject.displayName;
    }
    return undefined;
  };
  const subjectDisplayName = getDisplayName();

  return <Scheduling subjectDisplayName={subjectDisplayName} />;
};

export default Wrapper;
