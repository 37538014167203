import {
  DEFAULT_TIMEZONE,
  SHORT_DATETIME_FNS_FORMAT,
  SHORT_DATE_FNS_FORMAT,
  DEFAULT_DATETIME_FNS_FORMAT,
} from 'constants/timezones';
import { formatInTimeZone } from 'date-fns-tz';
import { Moment } from 'moment';

export const formatToShortDate = (datetime: string | Moment, timezone?: string) => {
  if (!datetime) {
    return 'Invalid date';
  }
  return formatInTimeZone(
    // to string ensures moments gets converted to string
    new Date(datetime.toString()),
    timezone || DEFAULT_TIMEZONE,
    SHORT_DATE_FNS_FORMAT,
  );
};

export const formatToShortDatetimeWithTimezone = (
  datetime: string | Moment,
  timezone?: string,
) => {
  if (!datetime) {
    return 'Invalid date';
  }
  return formatInTimeZone(
    // to string ensures moments gets converted to string
    new Date(datetime.toString()),
    timezone || DEFAULT_TIMEZONE,
    SHORT_DATETIME_FNS_FORMAT,
  );
};

export const formatToDefaultDatetimeWithTimezone = (
  datetime: string | Moment,
  timezone?: string,
) => {
  if (!datetime) {
    return 'Invalid date';
  }
  return formatInTimeZone(
    // to string ensures moments gets converted to string
    new Date(datetime.toString()),
    timezone || DEFAULT_TIMEZONE,
    DEFAULT_DATETIME_FNS_FORMAT,
  );
};
