import React, { FC } from 'react';
import juniAxios from 'services/axios';

import SearchSelect, { GenericSearchSelectProps, SearchSelectProps } from '.';

const ENDPOINT = `/api_instructor_db_search`;

interface InstructorSearchResult {
  instructorId: string;
  name: string;
  email?: string;
  school: string;
}

interface InstructorSearchSelectProps extends SearchSelectProps {
  showEmail?: boolean;
}

const searchInstructors = (showEmail: boolean) => async (query: string) => {
  const results = await juniAxios.get(`${ENDPOINT}/${query}`);
  return results.data.map((result: InstructorSearchResult) => {
    if (!showEmail) {
      delete result.email;
    }

    return {
      value: result.instructorId,
      label: result,
    };
  });
};

const InstructorOption: FC<InstructorSearchResult> = ({ name, school, email }) => (
  <>
    <strong>{name}</strong> - {school}
    {email && (
      <div>
        <em>{email}</em>
      </div>
    )}
  </>
);

const InstructorSearchSelect: FC<InstructorSearchSelectProps> = ({
  callback,
  value,
  showEmail = true,
}) => (
  <SearchSelect
    callback={callback}
    value={value}
    search={searchInstructors(showEmail)}
    Option={InstructorOption as GenericSearchSelectProps['Option']}
    placeholder="Search for an instructor..."
  />
);

export default InstructorSearchSelect;
