// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules#formatting_text_using_the_returned_tag
const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes: Record<Intl.LDMLPluralRule, string> = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
  zero: 'th',
  many: 'th',
};

export function ordinal(number: number) {
  const category = enOrdinalRules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
}
