import React, { FC } from 'react';

import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import findStudentById from 'app/signup_session/lib/findStudentById';
import makeCourseBundlesMap from 'app/signup_session/lib/makeCourseBundlesMap';
import parseDCFQuerystringParams from 'app/signup_session/lib/parseDCFQuerystringParams';
import { SignupSessionProps } from 'app/signup_session/types';
import { Redirect } from 'react-router-dom';
import JuniSpinner from 'components/JuniSpinner';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import navStates from 'app/signup_session/navigation/states';
import CourseFrequencyForm from './CourseFrequencyForm';

const CourseFrequencyPage: FC<SignupSessionProps> = ({
  match,
  location,
  history,
}) => {
  const { signupData, activeStudentId, flags } = useSignupContext();
  const { getNextPage, hasNextPage } = useNavRouter();
  const student = findStudentById(activeStudentId, signupData);

  const { subjects } = parseDCFQuerystringParams(student, window.location.search);

  const bundleData = student?.bundle?.bundleName
    ? makeCourseBundlesMap(student?.bundle?.bundleName, subjects)
    : undefined;

  if (!bundleData) return <JuniSpinner size={80} />;

  if (
    hasNextPage(navStates.signup.courseFrequency, ROUTE_EVENT.LOAD, {
      signupData,
      shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
      search: location.search,
    })
  ) {
    return (
      <Redirect
        to={getNextPage(navStates.signup.courseFrequency, ROUTE_EVENT.LOAD, {
          signupData,
          search: location.search,
          shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
        })}
      />
    );
  }

  return (
    <CourseFrequencyForm
      match={match}
      location={location}
      history={history}
      bundleData={bundleData!}
    />
  );
};

export default CourseFrequencyPage;
