import React, { FC, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { RouteComponentProps } from 'react-router-dom';
import * as R from 'ramda';
import { uniqBy } from 'lodash';

import { Chevron } from 'components/Icons';
import NoActivityCard from 'components/NoActivityCard';
import PortalSection from 'components/PortalSection';
import SpinnerV2 from 'components/SpinnerV2';

import {
  GetSubjectsReturnFragment,
  useGetSubjectsQuery,
  useLoadComputerScienceModuleSectionsQuery,
  useLoadTruncatedInstructorNamesQuery,
} from 'generated/graphql';

import { Student, StudentSession, TeacherSession } from 'models';

import noSessionNotes from 'images/no-session-notes.png';

import { getSubjectNameFromAppointmentTypeID } from 'utils/acuity';
import { SUBJECT_TYPE } from 'constants/subjects';

import { NewButton } from 'core-components';
import { consolidateModuleSectionInfo } from './utils';

import SessionNote from './SessionNote';
import SessionNotesFilterHeader from './SessionNotesFilterHeader';

interface SessionInfo {
  session: StudentSession;
  teacherSession?: TeacherSession;
  subjectName: SUBJECT_TYPE;
}

interface SessionNotesProps extends RouteComponentProps {
  courseInfo?: Record<string, { subject: string }>;
  nestedStructureCourses?: any[];
  moduleSectionProgresses: any[];
  showByDefault?: boolean;
  isLoadingCourseData?: boolean;
  teacherMode?: boolean;
  sessions: StudentSession[];
  teacherSessions: TeacherSession[];
  getTeacherSessions: () => Promise<void>;
  studentFirstName?: string;
  hasNameMatch?: (session: StudentSession) => boolean;
  instructorCalendarId?: string;
  userId: string;
  roles?: any;
  idLookup: any;
  student: Student;
  hideHeader?: boolean;
}

const SessionNotes: FC<SessionNotesProps> = ({
  courseInfo,
  nestedStructureCourses,
  moduleSectionProgresses,
  showByDefault,
  isLoadingCourseData,
  teacherMode,
  sessions,
  teacherSessions,
  getTeacherSessions,
  studentFirstName,
  hasNameMatch,
  instructorCalendarId,
  userId,
  roles,
  idLookup,
  history,
  location,
  match,
  hideHeader,
  student,
}) => {
  const DEFAULT_MAX_SHOWN = teacherMode ? 8 : 2;
  const VIEW_MORE_INCREMENT = teacherMode ? 4 : 2;
  const ZERO_STATE_DESCRIPTION = teacherMode
    ? 'The session notes for this student will appear here!'
    : 'The session notes written by your instructor will appear here!';
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedSubjectName, setSelectedSubjectName] = useState<string | undefined>(
    undefined,
  );
  const [maxShown, setMaxShown] = useState(DEFAULT_MAX_SHOWN);
  const [nextGroupIndex, setNextGroupIndex] = useState(1);

  const { data } = useGetSubjectsQuery();
  const subjects = data?.getSubjects || [];

  const startedComputerScienceModuleSectionIds = moduleSectionProgresses
    .filter(progress => progress.isCompleted || progress.isInProgress)
    .map(progress => progress.moduleSectionId);
  const {
    data: computerScienceModuleSectionData,
    loading: computerScienceModuleSectionsLoading,
  } = useLoadComputerScienceModuleSectionsQuery({
    variables: { ids: startedComputerScienceModuleSectionIds },
  });
  const computerScienceModuleSectionItems =
    computerScienceModuleSectionData?.computerScienceModuleSections.items || [];

  const uniqueTeacherIds = R.uniq(R.pluck('teacherId', teacherSessions));
  const {
    data: nameData,
    loading: truncatedInstructorNamesLoading,
  } = useLoadTruncatedInstructorNamesQuery({
    variables: { ids: uniqueTeacherIds, idFieldName: 'userId' },
  });
  const nameItems = nameData?.truncatedInstructorNames.items || [];
  const instructorNameLookup = R.mergeAll(
    nameItems.map(({ id, name }) => ({ [id]: name })),
  );

  const userIsAdmin = !!roles?.includes('admin');

  const ANIMATION_DURATION = [...Array(VIEW_MORE_INCREMENT)].reduce(
    (acc, _, i) => acc + 250 * (1 / (i + 0.5)),
    0,
  );

  const includeSessionWithNoTeacherSession = (session: StudentSession) =>
    teacherMode &&
    hasNameMatch?.(session) &&
    (userIsAdmin || session.calendarID.toString() === instructorCalendarId);

  const getSessionGroups = (
    sessions: StudentSession[],
    teacherSessions: TeacherSession[],
  ) => {
    const sessionData = [] as SessionInfo[];
    sessions.every(session => {
      const teacherSession = teacherSessions.find(
        teacherSession => session.id.toString() === teacherSession.acuityId,
      );
      if (teacherSession || includeSessionWithNoTeacherSession(session)) {
        const subjectName = getSubjectNameFromAppointmentTypeID(
          session.appointmentTypeID,
        );
        if (!selectedSubjectName || selectedSubjectName === subjectName) {
          if (sessionData.length >= DEFAULT_MAX_SHOWN) {
            if (sessionData.length >= maxShown + VIEW_MORE_INCREMENT) {
              return false;
            }
          }
          sessionData.push({ session, teacherSession, subjectName });
        }
      }
      return true;
    });
    return [
      sessionData.slice(0, DEFAULT_MAX_SHOWN),
      ...R.splitEvery(VIEW_MORE_INCREMENT, sessionData.slice(DEFAULT_MAX_SHOWN)),
    ];
  };

  const viewMore = () => {
    setIsLoadingMore(true);
    setNextGroupIndex(prevGroupIndex => prevGroupIndex + 1);
    setMaxShown(prevMaxShown => prevMaxShown + VIEW_MORE_INCREMENT);
  };

  const viewFewer = () => {
    setNextGroupIndex(1);
    setMaxShown(DEFAULT_MAX_SHOWN);
  };

  if (
    isLoadingCourseData ||
    computerScienceModuleSectionsLoading ||
    truncatedInstructorNamesLoading
  ) {
    return <SpinnerV2 />;
  }

  const sessionGroups = getSessionGroups(sessions, teacherSessions);

  const hasNoSessionNotes = sessionGroups[0].length === 0;
  const reachedTheEnd = sessionGroups.length <= nextGroupIndex;
  const showViewMoreButton = sessionGroups.length > 1;

  if (!showByDefault && hasNoSessionNotes) {
    return null;
  }

  const moduleSectionInfo = consolidateModuleSectionInfo({
    computerScienceModuleSectionItems,
    nestedStructureCourses,
    teacherMode,
  });

  const sharedSessionNoteProps = {
    teacherMode: teacherMode || false,
    moduleSectionProgresses,
    instructorNameLookup,
    getTeacherSessions,
    moduleSectionInfo,
    userId,
    userIsAdmin,
    idLookup,
    history,
    location,
    match,
  };

  const subjectFilterOptions = uniqBy(
    Object.values(courseInfo || {})
      .map(course => subjects.find(subject => subject.name === course.subject))
      .filter((subject): subject is GetSubjectsReturnFragment => Boolean(subject)),
    subject => subject.name,
  );

  return (
    <PortalSection name={!teacherMode && !hideHeader ? 'Session Notes' : ''}>
      <div>
        {teacherMode && (
          <SessionNotesFilterHeader
            subjects={subjectFilterOptions}
            handleClick={name => setSelectedSubjectName(name)}
            studentFirstName={studentFirstName}
            selectedSubjectName={selectedSubjectName}
          />
        )}
        {hasNoSessionNotes ? (
          <NoActivityCard
            title="No Session Notes"
            description={ZERO_STATE_DESCRIPTION}
            logo={noSessionNotes}
            instructorView={teacherMode}
            embedded={!teacherMode}
            actionButton={null}
            smallTitle
          />
        ) : (
          <>
            {sessionGroups.map((group, i) => (
              <AnimateHeight
                key={group[0].session.id}
                duration={ANIMATION_DURATION}
                height={i < nextGroupIndex ? 'auto' : 0}
                onAnimationEnd={() => setIsLoadingMore(false)}
                // These styles allow for overflow: hidden to be set
                // while preventing the box-shadow from being cut off.
                style={{
                  margin: '0 -20px',
                  padding: '0 20px',
                }}
              >
                {group.map(({ session, teacherSession, subjectName }) => (
                  <SessionNote
                    key={session.id}
                    teacherSession={teacherSession}
                    session={session}
                    subjectName={subjectName}
                    student={student}
                    {...sharedSessionNoteProps}
                  />
                ))}
              </AnimateHeight>
            ))}
          </>
        )}
      </div>
      <div className="flex justify-end">
        {showViewMoreButton && (
          <NewButton
            variant="secondary"
            onClick={reachedTheEnd ? viewFewer : viewMore}
            disabled={isLoadingMore}
          >
            <div className="button-text">
              {`View ${reachedTheEnd ? 'Fewer' : 'More'} Session Notes`}
            </div>
            {isLoadingMore ? (
              <SpinnerV2 size={12} />
            ) : (
              <Chevron orientation={reachedTheEnd ? 'flipped' : ''} />
            )}
          </NewButton>
        )}
      </div>
    </PortalSection>
  );
};

export default SessionNotes;
