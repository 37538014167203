import { Coupon } from 'generated/graphql';

const CouponCard = ({
  coupon: { id, amount_off, percent_off, duration, duration_in_months },
}: {
  coupon: Coupon;
}) => (
  <div className="p-2 rounded-lg border border-solid border-green-600 bg-green-50 bg-opacity-50">
    <div>Coupon Applied: {id}</div>
    <div>
      Discount: {amount_off ? `${amount_off}` : `${percent_off}%`} off{' '}
      {duration === 'forever'
        ? 'forever'
        : duration === 'once'
        ? 'once'
        : `for ${duration_in_months} months`}
    </div>
  </div>
);

export default CouponCard;
