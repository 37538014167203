import { PaymentElement } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';
import { Card, Message } from 'core-components';
import { useSignupData } from 'app/signup_2023/hooks';
import SignupFlowFooter from 'app/signup_session/components/SignupFlowFooter';
import { Loading } from 'components/ui';
import { Coppa, Intro, ProductSummary } from './components';
import { withStripeElements } from './withStripeElements';
import useSetupIntent from './useSetupIntent';
import { toBillingDetails } from './utils';

const Payment = () => {
  const {
    signupData,
    updateSignup,
    loading: loadingSignupData,
    goToPrevStep,
  } = useSignupData();
  const clientSecret = signupData?.payment?.setupIntentClientSecret || undefined;

  const {
    setupIntentResult,
    loading: loadingSetupIntent,
    confirmSetupIntent,
  } = useSetupIntent(clientSecret);

  const onSubmit = async () => {
    if (!signupData) {
      return;
    }

    if (signupData.payment.setupIntentStatus === 'succeeded') {
      // no data changes needed, just push to next step
      updateSignup({});
      return;
    }

    const onSuccess = (status: SetupIntent.Status) =>
      updateSignup({
        payment: { ...signupData.payment, setupIntentStatus: status },
      });

    confirmSetupIntent(toBillingDetails(signupData), onSuccess);
  };

  const loading = loadingSignupData || loadingSetupIntent;
  const setupIntentError = setupIntentResult?.error?.message;
  const setupIntentSucceeded = signupData?.payment.setupIntentStatus === 'succeeded';

  return (
    <div className="flex items-center justify-center">
      <Card
        borderWidth="0"
        className="flex items-center justify-center w-full sm:max-w-screen-xs sm:rounded-lg"
        noRounding
      >
        <div className="flex flex-col gap-4">
          <Intro />
          {!setupIntentSucceeded && (
            <div className="border border-solid border-blue-gray-50 p-3 rounded-lg">
              <PaymentElement options={{ readOnly: loading }} />
            </div>
          )}
          {setupIntentSucceeded && (
            <Message status="success">Payment method confirmed!</Message>
          )}
          {setupIntentError && (
            <Message status="error">
              <span data-cy="stripe-error">{setupIntentError}</span>
            </Message>
          )}
          <ProductSummary />
          <Coppa />
          <SignupFlowFooter
            back={{ disabled: loading, handler: goToPrevStep }}
            next={{
              disabled: loading,
              handler: onSubmit,
              intent: 'success',
              text: 'Submit',
            }}
          />
        </div>
      </Card>
    </div>
  );
};

const Wrapper = () => {
  const { signupData, loading } = useSignupData();
  const clientSecret = signupData?.payment?.setupIntentClientSecret || undefined;

  if (loading) return <Loading />;

  return withStripeElements(Payment)({ clientSecret });
};

export default Wrapper;
