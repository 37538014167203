import juniAxios from 'services/axios';

export async function getParentsUpcomingSessions(parentEmail) {
  return (await juniAxios.get(`/learner/sessions/upcoming/${parentEmail}`)).data
    .data;
}

export async function getParentsPastSessions(parentEmail, minDate) {
  return (
    await juniAxios.get(
      `/learner/sessions/past/${parentEmail}${
        minDate ? `?minDate=${encodeURIComponent(minDate)}` : ''
      }`,
    )
  ).data.data;
}
