import React, { FC, useState } from 'react';

import { GetCoursesReturnFragment, StripeSubscription } from 'generated/graphql';
import { Student } from 'models';
import { NewCard } from 'core-components';
import { ErrorableLoading } from 'components/ui';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';
import _ from 'lodash';
import { ONBOARDING_START_DATE_BUFFER_DAYS } from 'constants/onboarding_handoff_buffer';
import { SubscriptionMetaDataWithCourse } from '../types';
import { ConfirmationSummary, SubscriptionEditor } from './components';
import { dateToShortIsoString, getSubscriptionMetaData } from '../../utils';

interface Props {
  subscription: StripeSubscription;
  // subscriptions: StripeSubscription[];
  hasAsyncProduct: boolean;
  student: Student;
  parentId: string;
  courses: GetCoursesReturnFragment[];
  timezone: string;
}

const ManageSubscriptionCard: FC<Props> = ({
  subscription,
  // subscriptions,
  hasAsyncProduct,
  student,
  parentId,
  courses,
  timezone,
}) => {
  const studentCurrentCourses = student.hasMultipleTracks
    ? student.tracks
    : [student.track];
  const currentSubscriptionWithCourseAndPriceAttached: SubscriptionMetaDataWithCourse = _.mapValues(
    getSubscriptionMetaData(subscription),
    (frequencyStr, metadataKey) => {
      const subject = _.findKey(
        SUBJECT_TO_METADATA_KEY,
        value => value === metadataKey,
      );
      const enrolledCourses = courses
        ?.filter(
          course =>
            course.subject.name === subject &&
            studentCurrentCourses?.includes(course.name),
        )
        .map(course => course.name);
      return {
        weeklyFrequency: Number(frequencyStr),
        courses: enrolledCourses,
      };
    },
  );
  const currentSubscription: SubscriptionMetaDataWithCourse = hasAsyncProduct
    ? {
        ...currentSubscriptionWithCourseAndPriceAttached,
        on_demand: {
          weeklyFrequency: undefined,
          courses: undefined,
        },
      }
    : currentSubscriptionWithCourseAndPriceAttached;

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const [coupon, setCoupon] = useState<Maybe<StripeCoupon> | undefined>(
  //   subscription?.discount?.coupon,
  // );
  const coupon = subscription?.discount?.coupon;
  const firstAvailableDate = new Date(new Date().setHours(0, 0, 0, 0));
  firstAvailableDate.setDate(
    firstAvailableDate.getDate() + ONBOARDING_START_DATE_BUFFER_DAYS,
  );
  const firstAvailableDateString = dateToShortIsoString(firstAvailableDate) ?? '';
  const [startingDate, setStartingDate] = useState(firstAvailableDateString);

  const [updatedSubscription, setUpdatedSubscription] = useState<
    SubscriptionMetaDataWithCourse
  >(currentSubscription);

  // Temp Remove support on coupons
  // useEffect(() => {
  //   if (coupon) return;
  //   const siblingDiscount = getSiblingDiscount(
  //     subscriptions,
  //     student._id.toString(),
  //   );
  //   setCoupon(siblingDiscount);
  // }, [coupon, subscriptions, student._id]);

  return (
    <NewCard>
      <div className="w-full">
        <header className="pb-6 w-full">
          {isConfirmationOpen ? (
            <h2 className="text-j-dark-600 m-0 text-lg font-medium">
              Review {student.firstName}'s New Subscription
            </h2>
          ) : (
            <>
              <h2 className="text-j-dark-600 m-0 text-lg font-medium pb-2">
                Make Changes to {student.firstName}'s Subscription
              </h2>
              <div className="text-j-dark-300 m-0 sm:text-base text-sm">
                To adjust {student.firstName}'s courses, please adjust the
                preferences below.
              </div>
            </>
          )}
        </header>
        <NewCard.Divider />
        {currentSubscription ? (
          isConfirmationOpen ? (
            <ConfirmationSummary
              currentSubscription={currentSubscription}
              timezone={timezone}
              courses={courses}
              coupon={coupon}
              parentId={parentId}
              setIsConfirmationOpen={setIsConfirmationOpen}
              updatedSubscription={updatedSubscription}
              studentId={student._id}
              subscriptionId={subscription.id}
              startingDate={startingDate}
            />
          ) : (
            <SubscriptionEditor
              updatedSubscription={updatedSubscription}
              setUpdatedSubscription={setUpdatedSubscription}
              currentSubscription={currentSubscription}
              timezone={timezone}
              courses={courses}
              hasAsyncProduct={hasAsyncProduct}
              coupon={coupon}
              parentId={parentId}
              setIsConfirmationOpen={setIsConfirmationOpen}
              startingDate={startingDate}
              setStartingDate={setStartingDate}
              firstAvailableDate={firstAvailableDate}
              // setCoupon={setCoupon}
            />
          )
        ) : (
          <ErrorableLoading />
        )}
      </div>
    </NewCard>
  );
};

export default ManageSubscriptionCard;
