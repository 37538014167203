import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { QueryResult } from '@apollo/client';

import SpinnerV2 from 'components/SpinnerV2';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';
import { NewButton, Message } from 'core-components';
import { UPDATE_FLOWS } from 'app/learner/LearnerAccountV2/LearnerAccount';

import {
  CreateStripeSubscriptionInput,
  CreateStudentInput,
  GetCoursesQuery,
  LoadDefaultPaymentInformationByParentIdQuery,
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  Maybe,
  Parent,
  StripeCoupon,
  useCreateStripeSubscriptionMutation,
  useCreateStudentMutation,
} from 'generated/graphql';

import { hasAsync } from 'utils/stripe';

import {
  getSiblingDiscount,
  getSubscriptionMetaData,
} from 'app/learner/LearnerAccountV2/utils';
import {
  PaymentInformationSection,
  PlanDetailsSection,
  PricingSection,
  SchedulingPreferencesSection,
} from './components';
import LearnerPortalModal from '../LearnerPortalModal';

import { FormState, FormValues, MetadataNew } from '../types';

export interface Coupon {
  id: string;
  amount_off: number | null;
  duration: string;
  name: string;
  percent_off: number;
  valid: boolean;
}

interface PaymentCheckModalProps {
  parent: Parent;
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  updateFormValue: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
  coursesQuery: QueryResult<GetCoursesQuery>;
}

const PaymentCheckModal: FC<PaymentCheckModalProps> = ({
  formValues,
  formState,
  updateFormValue,
  coursesQuery,
  cardQuery,
  subscriptionsQuery,
  studentsQuery,
  updateFormState,
  parent,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [coupon, setCoupon] = useState<Maybe<StripeCoupon> | undefined>(undefined);

  const [createSubscription] = useCreateStripeSubscriptionMutation();
  const [createStudent] = useCreateStudentMutation();

  const {
    currentStudent,
    subscription,
    metadataNew,
    timezone,
    startingDate,
    isAsyncAdded,
  } = formValues;
  const { modal, updateFlow } = formState;

  const hasAsyncProduct = hasAsync(subscription);

  const courses = coursesQuery?.data?.getCourses;

  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items;

  useEffect(() => {
    if (coupon?.id) return;
    if (subscriptions) {
      const siblingDiscount = getSiblingDiscount(
        subscriptions,
        currentStudent?._id.toString(),
      );
      setCoupon(siblingDiscount);
    }
  }, [coupon?.id, subscriptions, currentStudent?._id]);

  const studentCurrentCourses = currentStudent?.hasMultipleTracks
    ? currentStudent?.tracks
    : [currentStudent?.track];

  // e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
  const courseMetadataObj = getSubscriptionMetaData(subscription);

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses?.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const subscriptionHasChanged = _.some(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );

  const handleSubmitUpgrade = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setSubmitError('');

    let studentId;
    if (currentStudent?._id) {
      studentId = currentStudent._id;
    } else {
      const createStudentInput = {
        parentId: parent._id,
        courses:
          metadataNew && Object.values(metadataNew).flatMap(data => data.courses),
        availabilities:
          metadataNew &&
          Object.values(metadataNew).flatMap(data => data.availabilities),
        ..._.pick(currentStudent, [
          'firstName',
          'lastName',
          'courses',
          'birthdate',
          'timezone',
          'gender',
        ]),
      };

      try {
        const response = await createStudent({
          variables: {
            input: createStudentInput as CreateStudentInput,
          },
        });
        studentId = response?.data?.createStudent?.student?._id;
        updateFormValue(
          {
            ...currentStudent,
            _id: studentId,
          },
          'currentStudent',
        );
      } catch (err) {
        if (err instanceof Error) {
          setSubmitError(err.toString());
        }
      }
    }

    if (studentId) {
      const createSubscriptionInput: CreateStripeSubscriptionInput = {
        subscriptionUpdateType:
          _.findKey(UPDATE_FLOWS, key => key === updateFlow) || '',
        studentId,
        metadataNew: metadataNew || ({} as MetadataNew),
        timezone,
        startingDate,
        addAsync: isAsyncAdded,
      };
      if (coupon) {
        createSubscriptionInput.couponId = coupon.id;
      }

      try {
        await createSubscription({
          variables: {
            input: createSubscriptionInput,
          },
        });
        await subscriptionsQuery.refetch();
        await studentsQuery.refetch();

        const modalName = 'payment_confirmation';
        JuniAnalytics.track(`${modal}_button_clicked`, {
          funnel: 'subscription_upgrade_downgrade',
          sourceModal: modal,
          destinationModal: modalName,
          actionFlow: updateFlow,
        });

        updateFormState('', 'updateFlow');
        updateFormState(modalName, 'modal');
      } catch (err) {
        if (err instanceof Error) {
          setSubmitError(err.toString());
        }
      }
    }
    setIsSubmitting(false);
  };

  return (
    <LearnerPortalModal
      title={formState.updateFlow}
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton onClick={handleSubmitUpgrade} disabled={isSubmitting}>
          {isSubmitting ? <SpinnerV2 /> : <div className="font-medium">Submit</div>}
        </NewButton>
      }
    >
      <div className="flex flex-col gap-6 text-j-dark-600 text-base w-full">
        <PlanDetailsSection
          courses={courses}
          metadataNew={metadataNew}
          formValues={formValues}
          hasAsyncProduct={hasAsyncProduct}
        />
        {
          // only show scheduling section if courses were added/updated
          subscriptionHasChanged && (
            <SchedulingPreferencesSection
              formValues={formValues}
              metadataOld={metadataOld}
              courses={courses}
            />
          )
        }
        <PricingSection
          coupon={coupon}
          setCoupon={setCoupon}
          formValues={formValues}
          metadataOld={metadataOld}
          subscriptions={subscriptions}
          hasAsyncProduct={hasAsyncProduct}
        />
        <PaymentInformationSection cardQuery={cardQuery} />
        {submitError && (
          <Message status="error">
            There was an error creating your subscription! Please send us an email at{' '}
            <a href="mailto:support@learnwithjuni.com">support@learnwithjuni.com</a>{' '}
            so that we can assist.
          </Message>
        )}
      </div>
    </LearnerPortalModal>
  );
};

export default PaymentCheckModal;
