import axios from 'axios';
import _ from 'lodash';

import ReferralCodeService from 'services/join/referral_codes';
import { getCouponDataFromStripe, validateCoupon } from 'services/payment';
import { REFERRED_ENROLLMENT_COUPON_STRIPE_ID } from 'constants/coupons';
import {
  GetCoursesReturnFragment,
  Maybe,
  StripeCoupon,
  StripeSubscription,
  Student,
} from 'generated/graphql';
import { JUNI_EMAILS } from 'constants/contact_information';
import {
  ASYNC_PLAN_PRODUCT,
  CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME,
  CORE_PRICES,
  CORE_PRODUCT_METADATA_KEYS,
  JUNI_JR_PRICES,
  METADATA_KEY,
  USACO_PRICES,
} from 'constants/subscription_plans';
import { COURSES_REQUIRING_INSTRUCTOR_APPROVAL } from 'utils/enrollment';
import { EMPOWERLY_COURSES } from 'constants/courses';
import { SubscriptionMetaDataWithCourse } from './ManageSubscription/types';

export const getStudentNamesFromStudents = (students: Student[]) => {
  if (students.length === 0) {
    return '';
  }
  if (students.length === 1) {
    return `${students[0].firstName}`;
  }
  if (students.length === 2) {
    return `${students[1].firstName} and ${students[0].firstName}`;
  }
  return `${students
    .slice(1)
    .map(student => student.firstName)
    .join(', ')}, and ${students[0].firstName}`;
};

export const getStudentsFromSubscription = (
  subscription: StripeSubscription,
  students: Student[],
) => {
  const studentIdMetadata = subscription.metadata?.studentIds;
  if (studentIdMetadata && students.length > 0) {
    const studentIds = studentIdMetadata.split(',');
    return students.filter(student => studentIds.includes(student._id));
  }
  return [];
};

// get referral coupon info from stripe or state if already fetched
const getReferralCouponStripeData = async () => {
  let referralCouponInfo;
  try {
    if (!referralCouponInfo) {
      referralCouponInfo = await getCouponDataFromStripe(
        REFERRED_ENROLLMENT_COUPON_STRIPE_ID,
      );
    }
  } catch (err) {
    alert(
      `Sorry, we're having trouble connecting to our payments provider to apply your referral discount at the moment! Please try again later or contact ${JUNI_EMAILS} if the issue persists.`,
    );
  }
  return referralCouponInfo;
};

const getReferralCodeInfoIfValid = async (referralCode: string) => {
  try {
    const matchingReferralCodes = await ReferralCodeService.list({
      code: referralCode,
    });
    if (
      !matchingReferralCodes ||
      !matchingReferralCodes.length ||
      !matchingReferralCodes[0].code
    )
      return;
    return matchingReferralCodes[0];
  } catch (err) {
    alert(
      `Sorry, we're having trouble validating your referral code. Please refresh the page to try again or contact ${JUNI_EMAILS} if the issue persists.`,
    );
  }
};

export const getReferralCodeCouponIfValid = async (referralCode: string) => {
  try {
    const validReferralCode = await getReferralCodeInfoIfValid(referralCode);
    if (!validReferralCode) return;

    // get stripe coupon data for the referral coupon so we can show accurate discount info in the cart
    const referralCouponInfo = await getReferralCouponStripeData();
    if (!referralCouponInfo) return;

    const referralCoupon = {
      ...validReferralCode,
      referrerFirstName: validReferralCode.name,
      ...referralCouponInfo,
      id: validReferralCode.code,
    };
    return referralCoupon;
  } catch (err) {
    alert(
      `Sorry, we're having trouble validating your referral code. Please refresh the page to try again or contact ${JUNI_EMAILS} if the issue persists.`,
    );
  }
};

export const getCoupon = async (
  subscriptions: StripeSubscription[],
  couponId: string,
  studentId: string,
) => {
  let coupon;
  let couponCodeExistsInStripe = true;
  let couponError = '';
  try {
    // if the customer attempts to enter the sibling discount manually, check that it is valid
    if (couponId === 'SIBLINGDISCOUNT') {
      coupon = getSiblingDiscount(subscriptions, studentId);
      if (!coupon) {
        couponError = 'This coupon is invalid';
      }
    } else {
      coupon = await validateCoupon(couponId);
    }
  } catch (err) {
    couponError = 'This coupon is invalid';
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 404) {
        couponCodeExistsInStripe = false;
      }
      if (err.response?.data.error) {
        couponError = err.response.data.error;
      }
    }
  }
  if (!couponCodeExistsInStripe) {
    coupon = await getReferralCodeCouponIfValid(couponId);
  }
  return { coupon, couponError };
};

const SIBLING_COUPON: StripeCoupon = {
  id: 'SIBLINGDISCOUNT',
  object: 'coupon',
  amount_off: null,
  duration: 'forever',
  percent_off: 10,
  valid: true,
};

// the way to think about sibling discounts is:
// "do I have an active sibling who is not using the sibling discount?"
export const getSiblingDiscount = (
  subscriptions: StripeSubscription[],
  studentId: string,
) => {
  const activeSubscriptions = subscriptions.filter(
    subscription => subscription.status !== 'canceled',
  );

  // if the studentId does not exist, we are in the process of creating a new student
  // apply the sibling discount if there is at least one other active subscription
  if (!studentId) {
    return activeSubscriptions.length > 0 ? SIBLING_COUPON : null;
  }

  // otherwise, find all sibling subscriptions
  const activeSiblingSubscriptions = subscriptions.filter(
    subscription =>
      subscription.status !== 'canceled' &&
      !(subscription?.metadata?.studentIds || '').includes(studentId),
  );

  // if there is at least one sibling paying full price, return the sibling discount
  return _.some(
    activeSiblingSubscriptions,
    subscription =>
      subscription.discount === null ||
      subscription.discount?.coupon?.id !== 'SIBLINGDISCOUNT',
  )
    ? SIBLING_COUPON
    : null;
};

// e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
export function getSubscriptionMetaData(subscription?: StripeSubscription) {
  return _.pickBy(
    subscription ? subscription.metadata : {}, // subscription does not exist for new students
    (value, key) =>
      value && Object.keys(CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME).includes(key),
  );
}

export function dateToShortIsoString(date: Date | string | undefined) {
  if (!date) return;
  return new Date(date).toISOString().split('T')[0];
}

export function getTotalCoreFrequency(
  subscriptionMetaData: SubscriptionMetaDataWithCourse,
) {
  return _.sum(
    Object.keys(subscriptionMetaData).map(subjectKey => {
      if (CORE_PRODUCT_METADATA_KEYS.includes(subjectKey)) {
        const { weeklyFrequency } = subscriptionMetaData[subjectKey];
        return weeklyFrequency || 0;
      }
      return 0;
    }),
  );
}

export function getCoreSubjectList(
  subscriptionMetaData: SubscriptionMetaDataWithCourse,
) {
  return Object.keys(subscriptionMetaData)
    .filter(subjectKey => CORE_PRODUCT_METADATA_KEYS.includes(subjectKey))
    .map(subjectKey => {
      const { weeklyFrequency } = subscriptionMetaData[subjectKey];
      return weeklyFrequency && weeklyFrequency > 0
        ? `${CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[subjectKey]} ${
            weeklyFrequency < 1 ? '2x monthly' : `${weeklyFrequency}x per week`
          }`
        : '';
    })
    .join(', ');
}

export function getSubscriptionTotalPriceBeforeDiscount(
  subscriptionMetaData: SubscriptionMetaDataWithCourse,
) {
  let totalPrice = 0;
  let totalCoreFrequency = 0;
  Object.keys(subscriptionMetaData).forEach(subjectKey => {
    if (CORE_PRODUCT_METADATA_KEYS.includes(subjectKey)) {
      const { weeklyFrequency } = subscriptionMetaData[subjectKey];
      totalCoreFrequency += weeklyFrequency || 0;
    } else {
      totalPrice += getPlanMonthlyPrice(
        subjectKey,
        subscriptionMetaData[subjectKey].weeklyFrequency,
      );
    }
  });
  return totalPrice + (totalCoreFrequency > 0 ? CORE_PRICES[totalCoreFrequency] : 0);
}

export function getDiscountDollarWithCoupon(
  coupon: Maybe<StripeCoupon> | undefined,
  total: number,
) {
  return coupon?.amount_off
    ? coupon?.amount_off / 100
    : coupon?.percent_off
    ? (coupon?.percent_off / 100) * total
    : 0;
}

export const getPlansDisplayName = (
  subjectKey: string,
  frequency: number | undefined,
) => {
  let displayName = '';
  if (subjectKey === ASYNC_PLAN_PRODUCT.key) {
    displayName = ASYNC_PLAN_PRODUCT.displayName;
  } else {
    const subjectCategory =
      subjectKey !== 'core_weeklyFrequency'
        ? CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[subjectKey]
        : '';
    const frequencyStr =
      frequency === 0.5 ? '2x per month' : `${frequency}x per week`;
    displayName = `${subjectCategory} ${frequencyStr}`;
  }
  return displayName;
};

export const getPlanMonthlyPrice = (
  subjectKey: string,
  frequency: number | undefined,
) => {
  if (subjectKey === ASYNC_PLAN_PRODUCT.key) {
    return ASYNC_PLAN_PRODUCT.price;
  }
  if (
    subjectKey === METADATA_KEY.usaco &&
    frequency &&
    frequency > 0 &&
    frequency <= 3
  ) {
    return USACO_PRICES[frequency.toString() as keyof typeof USACO_PRICES];
  }
  if (
    subjectKey === METADATA_KEY.juni_jr &&
    frequency &&
    frequency > 0 &&
    frequency <= 4
  ) {
    return JUNI_JR_PRICES[frequency.toString() as keyof typeof JUNI_JR_PRICES];
  }
  if (subjectKey === ASYNC_PLAN_PRODUCT.key) {
    return ASYNC_PLAN_PRODUCT.price;
  }
  if (
    CORE_PRODUCT_METADATA_KEYS.includes(subjectKey) &&
    frequency &&
    frequency > 0
  ) {
    return CORE_PRICES[frequency];
  }

  return 0;
};

export const getLearnerEnrollableCourses = (courses: GetCoursesReturnFragment[]) =>
  courses.filter(
    course =>
      course.isAcceptingEnrollment &&
      course.schedulingFormat === 'private' &&
      !COURSES_REQUIRING_INSTRUCTOR_APPROVAL.includes(course.name) &&
      !EMPOWERLY_COURSES.includes(course.name),
  );
