import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import JuniSpinner from 'components/JuniSpinner';
import { ErrorableLoading } from 'components/ui';
import noLearningTargetsImage from 'images/no_learning_targets.svg';
import { useLoadCourseRubricForLearnerQuery } from 'generated/graphql';
import { COURSE_CATALOG } from 'constants/course_catalog';

import { useCourseNameToBasicMetadata } from 'hooks/course';
import RingSpinner from 'components/RingSpinner';
import { cleanCourseDisplayNameForLearnerAudience } from 'utils/cleanCourseDisplayNameForLearnerAudience';
import Card from '../Card';
import Wrapper from '../Wrapper';
import CourseBanner from './CourseBanner';
import CourseRubric from './CourseRubric';
import ParagraphText from './ParagraphText';
import ProgressBadge from './ProgressBadge';

const ERROR_MESSAGE = `
  Oops! We couldn't load the Learning Targets for this course right now.
  If the problem persists, contact support@learnwithjuni.com.
`;

const LEVEL_MAP = new Map([
  ['Beginning', 'I am trying to get it! I need more practice with this skill.'],
  ['Developing', 'I almost have it! I just need a little practice with this skill.'],
  ['Proficient', 'I get it! I can use and apply this skill on my own.'],
  [
    'Expert',
    `I got it! I can apply this skill in creative ways, and I demonstrate my expertise by explaining this skill to my instructor in my own words.`,
  ],
]);

interface CourseDetailProps {
  roadmapLinkBase: string;
  courseName: string;
  subjectName: string;
  studentId: string;
  studentFirstName: string;
}

const CourseDetail: FC<CourseDetailProps> = ({
  roadmapLinkBase,
  courseName,
  subjectName,
  studentId,
  studentFirstName,
}) => {
  const { coursesWithBasicMetadata } = useCourseNameToBasicMetadata();
  const { loading, data, error } = useLoadCourseRubricForLearnerQuery({
    variables: { input: { courseName, subjectName, studentId } },
  });
  const rubricItems = data?.courseRubricForLearner?.rubric?.items || [];
  const progresses = data?.courseRubricForLearner?.progresses || [];
  if (loading) {
    return <JuniSpinner size={100} />;
  }
  const courseDisplayName = coursesWithBasicMetadata
    .filter(course => course.name === courseName)
    .pop()?.displayName;
  if (coursesWithBasicMetadata.length === 0) {
    // TODO: this should really be a loading state exported from the hook, not length===0
    return <RingSpinner className="mt-10" />;
  }
  if (!courseDisplayName) {
    return <Redirect to={roadmapLinkBase} />;
  }
  const cleanCourseDisplayName = cleanCourseDisplayNameForLearnerAudience(
    courseDisplayName,
  );
  const rubricProgress = progresses.reduce(
    (acc, { targetId, level }) => ({ ...acc, [targetId]: level }),
    {},
  );
  const supportString = `If you have any questions about Learning Targets${
    progresses.length > 0 ? ` or ${studentFirstName}'s progress` : ''
  }, please contact us at support@learnwithjuni.com and we will be happy to assist!`;
  return (
    <div className="h-full min-h-screen">
      <CourseBanner
        courseDisplayName={cleanCourseDisplayName}
        roadmapLinkBase={roadmapLinkBase}
        suggestedAge={COURSE_CATALOG[courseName]?.suggestedAge}
        description={COURSE_CATALOG[courseName]?.description}
      />
      <Wrapper>
        <div className="my-8">
          <Card headerText="Learning Targets Overview" defaultExpanded>
            <div className="px-6 py-2">
              {error ? (
                <ErrorableLoading error={ERROR_MESSAGE} />
              ) : rubricItems.length > 0 ? (
                <ParagraphText>
                  <p>
                    Below are the Learning Targets for the course{' '}
                    {cleanCourseDisplayName}. At Juni, we define successful learning
                    as the mastery of concrete skills, so our learning goals &mdash;
                    or targets &mdash; are expressed as active skills that students
                    learn, practice, and master in a given course. We describe each
                    student's progress towards mastery of the Learning Targets with
                    four levels of proficiency, with the target level being
                    "Proficient." We still include an "Expert" category beyond the
                    target level to reflect that learning is an ongoing, lifelong
                    pursuit.
                  </p>
                  <dl
                    className="grid gap-y-6 my-6"
                    style={{ gridTemplateColumns: 'max-content auto' }}
                  >
                    {[...LEVEL_MAP.entries()].map(([label, meaning], index) => (
                      <React.Fragment key={`level-${index + 1}`}>
                        <dt>
                          <ProgressBadge label={label} />
                        </dt>
                        <dd className="ml-8">{meaning}</dd>
                      </React.Fragment>
                    ))}
                  </dl>
                  <p>
                    Instructors update their students' progress towards proficiency
                    in the Learning Targets during Check-Ins, which are built into
                    our curriculum. During a Check-In session, a student works
                    through specific questions and projects independently to
                    demonstrate their proficiency in the Learning Targets while the
                    instructor observes and provides guidance as needed. These
                    sessions are meant to be low-pressure opportunities for
                    instructors to assess student progress and provide actionable
                    next steps a student can take to continue advancing towards
                    proficiency in the Learning Targets. There are typically 2-3
                    Check-Ins per course.
                  </p>
                  <p>{supportString}</p>
                </ParagraphText>
              ) : (
                <div className="text-center">
                  <img
                    className="p-4 w-5/6 max-w-xs"
                    src={noLearningTargetsImage}
                    alt=""
                  />
                  <div className="mt-2 mb-4">
                    The Learning Targets for this course will be available soon!
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
        {!error && rubricItems.length > 0 && (
          <CourseRubric
            levelLabels={[...LEVEL_MAP.keys()]}
            rubricItems={rubricItems}
            rubricProgress={rubricProgress}
          />
        )}
      </Wrapper>
    </div>
  );
};

export default CourseDetail;
