import React from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import * as Icon from '../Icon';

export interface OptionProps {
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
  value: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
  /**
   * If the option can be selected.
   * Use false to avoid holding the space for the right selected icon.
   * Default true.
   */
  selectable?: boolean;
  disabled?: boolean;
  iconLeft?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Option: React.FC<OptionProps> = ({
  ref,
  value,
  onClick,
  selected = false,
  selectable = true,
  disabled = false,
  iconLeft: IconLeft,
  children,
}) => (
  <div
    ref={ref}
    tabIndex={0}
    role="option"
    aria-disabled={disabled}
    aria-selected={selected}
    data-value={value}
    onClick={disabled || !onClick ? noop : onClick}
    className={classNames(
      'mt-2 first:mt-0 px-3 py-2',
      'box-border',
      'font-graphik text-base',
      'rounded-md',
      'border border-solid border-transparent',
      {
        'text-j-dark-600 hover:bg-j-gray-200 active:bg-j-gray-300 focus:border-j-blue-300':
          !selected && !disabled,
        'text-j-purple-800 bg-j-purple-100 font-semibold': selected && !disabled,
        'text-j-dark-200': disabled,
        'cursor-pointer': !disabled,
      },
    )}
  >
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        {!!IconLeft && (
          <div className="flex items-center justify-center w-6 h-6 mr-3">
            <IconLeft />
          </div>
        )}
        <div>{children}</div>
      </div>
      {/* hold the space even when not selected when the option is selectable */}
      {selectable && (
        <div className="ml-8 h-4 w-4">
          {selected && <Icon.Check className="w-full h-full" />}
        </div>
      )}
    </div>
  </div>
);

export default Option;
