import { ErrorableLoading } from 'components/ui';

import { Divider, NewCard } from 'core-components';
import moment from 'moment';

import { QueryResult } from '@apollo/client';
import { LoadPaymentHistoryByParentIdQuery } from 'generated/graphql';
import { formatPrice } from 'constants/subscription_plans';
import { Fragment } from 'react';

interface PaymentHistoryCardProps {
  paymentsQuery: QueryResult<LoadPaymentHistoryByParentIdQuery>;
}

const PaymentHistoryCard = ({ paymentsQuery }: PaymentHistoryCardProps) => {
  const payments = paymentsQuery?.data?.paymentHistoryByParentId?.items || [];
  const { loading: paymentsLoading, error: paymentsError } = paymentsQuery;
  return (
    <NewCard padding="0">
      <div className="text-xl font-semibold px-6 py-4">Payment History</div>
      <NewCard.Divider />
      <div className="m-6 border rounded-lg border-j-dark-100 border-solid">
        {paymentsLoading || paymentsError ? (
          <ErrorableLoading error={paymentsError} />
        ) : payments.length > 0 ? (
          payments.map((payment, index: number) => {
            const description = payment.description
              ? payment.description
              : 'Monthly payment';
            const amount = (payment.amount - (payment.amount_refunded || 0)) / 100;
            if (payment.paid && amount !== 0) {
              const isLastCard = index === payments.length - 1;
              return (
                <Fragment key={payment.id}>
                  <NewCard className="" padding="0" shadow="none">
                    <div className="flex p-4 flex-row justify-between">
                      <div className="flex flex-col gap-2">
                        <div>
                          {moment.unix(payment.created).format('MMMM D, YYYY')}
                        </div>
                        <div className="text-j-dark-300">{description}</div>
                      </div>
                      <div className="items-center py-4">{formatPrice(amount)}</div>
                    </div>
                  </NewCard>
                  {!isLastCard && <Divider />}
                </Fragment>
              );
            }
            return <div key={payment.id}></div>;
          })
        ) : (
          <div>No payments found.</div>
        )}
      </div>
    </NewCard>
  );
};

export default PaymentHistoryCard;
