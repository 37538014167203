import React, { FC } from 'react';
import _ from 'lodash';
import { NewCard } from 'core-components';
import { getDisplayNameFromMetadataNew } from 'utils/stripe';
import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';
import { GetCoursesReturnFragment } from 'generated/graphql';
import { FormValues, MetadataNew } from '../../types';

interface Props {
  courses: Array<GetCoursesReturnFragment> | undefined;
  metadataNew: MetadataNew | undefined;
  formValues: FormValues;
  hasAsyncProduct: boolean;
}

const PlanDetailsSection: FC<Props> = ({
  courses,
  metadataNew,
  formValues: { isAsyncAdded },
  hasAsyncProduct,
}) => (
  <div className="flex flex-col gap-2">
    <div className="text-base font-medium">Plan Details</div>
    <NewCard
      className="border border-j-dark-100 border-solid"
      padding="0"
      shadow="none"
    >
      <div className="flex p-4 flex-col">
        <div className="text-j-dark-300">Plan</div>
        {getDisplayNameFromMetadataNew(metadataNew)
          .split(', ')
          .map(entry => (
            <div>{entry}</div>
          ))}
        {(isAsyncAdded || hasAsyncProduct) && (
          <div>{ASYNC_PLAN_PRODUCT.displayName}</div>
        )}
      </div>
      <NewCard.Divider />
      <div className="flex p-4 flex-col">
        <div className="text-j-dark-300">Courses</div>
        <div className="items-center flex-col">
          {courses &&
            courses
              .filter(course =>
                _.flatMap(_.values(metadataNew), value => value.courses).includes(
                  course.name,
                ),
              )
              .map(course => <div key={course.name}>{course.displayName}</div>)}
          {(isAsyncAdded || hasAsyncProduct) && (
            <div>{ASYNC_PLAN_PRODUCT.description}</div>
          )}
        </div>
      </div>
    </NewCard>
  </div>
);

export default PlanDetailsSection;
