import React from 'react';
import { Course, ParentStudentInfo, Scheduling } from './components';
import { containerStyles } from '../constants';

const ReviewYourAnswers = () => (
  <div className={`${containerStyles} gap-6`}>
    <header className="border-0 border-b border-solid border-j-purple-200 pb-6">
      <h1 className="text-j-dark-600 m-0 text-3xl font-medium">
        Review your answers
      </h1>
    </header>
    <ParentStudentInfo />
    <Scheduling />
    <Course />
  </div>
);

export default ReviewYourAnswers;
