import React, { FC } from 'react';
import _ from 'lodash';
import { addDays, setDay } from 'date-fns';
import { GetCoursesReturnFragment, Maybe, StripeCoupon } from 'generated/graphql';
import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';
import { NewButton, NewCard } from 'core-components';
import { DateTime } from 'components/SchedulingSelection/types';
import { SubscriptionMetaDataWithCourse } from '../../types';
import CourseAdjustmentSection from './CourseAdjustmentSection/CourseAdjustmentSection';
import StartingDateInput from './CourseAdjustmentSection/StartingDateInput';
import NewSubscriptionPricingSection from './NewSubscriptionPricingSection';

function adjustStartingDates(
  timePreferences: Array<DateTime[]>,
  startingDate: string,
) {
  return timePreferences.map(datetimes =>
    datetimes.map(selectedDateTime => {
      const start = new Date(startingDate); // this date is our starting point
      const original = new Date(selectedDateTime.datetime!); // from this we retain just the weekday (eg. Tuesday) and hours:minutes
      const newDate = setDay(start, original.getDay()); // note: setDay does not mutate the original date
      newDate.setHours(original.getHours(), original.getMinutes(), 0, 0);
      return {
        datetime:
          newDate.getTime() < start.getTime()
            ? addDays(newDate, 7).toJSON()
            : newDate.toJSON(),
      };
    }),
  );
}

interface SubscriptionEditorProps {
  updatedSubscription: SubscriptionMetaDataWithCourse;
  setUpdatedSubscription: React.Dispatch<
    React.SetStateAction<SubscriptionMetaDataWithCourse>
  >;
  currentSubscription: SubscriptionMetaDataWithCourse;
  timezone: string;
  courses: GetCoursesReturnFragment[];
  hasAsyncProduct: boolean;
  coupon: Maybe<StripeCoupon> | undefined;
  parentId: string;
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  startingDate: string;
  setStartingDate: React.Dispatch<React.SetStateAction<string>>;
  firstAvailableDate: Date;
  // setCoupon: React.Dispatch<React.SetStateAction<Maybe<StripeCoupon> | undefined>>;
}

const SubscriptionEditor: FC<SubscriptionEditorProps> = ({
  updatedSubscription,
  setUpdatedSubscription,
  currentSubscription,
  timezone,
  courses: courseData,
  hasAsyncProduct,
  coupon,
  setIsConfirmationOpen,
  startingDate,
  setStartingDate,
  firstAvailableDate,
  // setCoupon,
}) => {
  const isAsyncAdded = _.xor(
    Object.keys(currentSubscription),
    Object.keys(updatedSubscription),
  ).includes(ASYNC_PLAN_PRODUCT.key);

  const hasSubscriptionChanged = !_.isEqual(
    currentSubscription,
    updatedSubscription,
  );

  const isAllTimesSelected = Object.entries(updatedSubscription)
    .map(([subjectKey, details]) => {
      if (subjectKey === ASYNC_PLAN_PRODUCT.key) {
        return true;
      }
      if (
        details?.weeklyFrequency === currentSubscription[subjectKey]?.weeklyFrequency
      ) {
        return true;
      }
      if (details?.timePreferences && details?.weeklyFrequency) {
        const increasedFrequency = Math.round(
          details?.weeklyFrequency -
            (currentSubscription[subjectKey]?.weeklyFrequency ?? 0),
        );
        // max out at 15 so it's easier for parents
        const numSelectionsRequired =
          increasedFrequency < 5 ? increasedFrequency * 3 : 15;
        if (
          details?.weeklyFrequency !==
            currentSubscription[subjectKey]?.weeklyFrequency &&
          details?.timePreferences[0].length === numSelectionsRequired
        ) {
          return true;
        }
      }

      return false;
    })
    .every(isTimeSelected => isTimeSelected);

  return (
    <div className="pt-6 space-y-6 w-full">
      <CourseAdjustmentSection
        updatedSubscription={updatedSubscription}
        setUpdatedSubscription={setUpdatedSubscription}
        timezone={timezone}
        currentSubscription={currentSubscription}
        hasAsyncProduct={hasAsyncProduct}
        courses={courseData}
        isAsyncAdded={isAsyncAdded}
      />
      {/* Show Input Date only if there is change in courses */}
      {!_.isEqual(currentSubscription, _.omit(updatedSubscription, 'on_demand')) && (
        <StartingDateInput
          timezone={timezone}
          startingDate={startingDate}
          setStartingDate={setStartingDate}
          firstAvailableDate={firstAvailableDate}
          isAsyncAdded={isAsyncAdded}
        />
      )}

      <NewCard.Divider />

      {hasSubscriptionChanged && (
        <>
          <NewSubscriptionPricingSection
            updatedSubscription={updatedSubscription}
            currentSubscriptionWithCourseAttached={currentSubscription}
            coupon={coupon}
            courseData={courseData}
            // setCoupon={setCoupon}
          />
          <NewCard.Divider />
        </>
      )}

      <div className="flex justify-end">
        <NewButton
          disabled={!hasSubscriptionChanged || !isAllTimesSelected}
          onClick={() => {
            // adjust preference time to happen after specified starting date
            const updatedSubscriptionWithAdjustedTime: SubscriptionMetaDataWithCourse = _.mapValues(
              updatedSubscription,
              subjectSubscriptionData => {
                if (subjectSubscriptionData.timePreferences) {
                  return {
                    ...subjectSubscriptionData,
                    timePreferences: adjustStartingDates(
                      subjectSubscriptionData.timePreferences,
                      startingDate,
                    ),
                  };
                }
                return subjectSubscriptionData;
              },
            );
            setUpdatedSubscription(updatedSubscriptionWithAdjustedTime);
            setIsConfirmationOpen(true);
          }}
        >
          Review
        </NewButton>
      </div>
    </div>
  );
};

export default SubscriptionEditor;
