import juniAxios from 'services/axios';

import {
  DiscountData,
  OnboardingTicketStatus,
  PaymentBodyStudent,
  MongoStudentSchema,
  MongoParentSchema,
  OnboardingTicket,
  Address,
  StudentData,
  SubjectName,
  Gender,
} from 'app/signup_session/types';
import makeOnboardingTicketNote from 'app/signup_session/lib/makeOnboardingTicketNote';
import sumWeeklyClasses from 'app/signup_session/lib/sumWeeklyClasses';

export interface PaymentBody {
  signupSessionId: string;
  parentFirstName: string;
  parentLastName: string;
  email: string;
  phone: string;
  stripeToken: {
    id: string;
  };
  address: Address;
  tos: boolean;
  students: PaymentBodyStudent[];
  discountCodes: Array<DiscountData>;
}

const updateOnboardingTicket = async (
  ticketId: string,
  body: {
    potentialFirstClassDatetimes?: { datetime?: string }[];
    notes?: string;
    timezone?: string;
    status?: OnboardingTicketStatus;
    courses?: Array<{ name: string }>;
    gender?: Gender;
  },
) =>
  juniAxios.patch<OnboardingTicket>(`/join/signup/onboarding_ticket/${ticketId}`, {
    ...body,
    setLogDueDate: !!body.potentialFirstClassDatetimes,
  });

const syncSignupToOnboarding = async (
  signupSessionId: string,
  studentData: Partial<StudentData>,
  isFromCourseExplorer: boolean,
) =>
  Promise.all(
    Object.keys(studentData.bundle?.selections ?? {}).map(subject => {
      const selection = studentData?.bundle?.selections[subject as SubjectName];
      if (selection?.onboardingTicketId) {
        return updateOnboardingTicket(selection.onboardingTicketId, {
          potentialFirstClassDatetimes: selection.schedulingPreferences,
          status: 'ready_to_onboard',
          courses: selection.courseName
            ? [{ name: selection.courseName }]
            : undefined,
          timezone: studentData.timezone,
          gender: studentData.gender,
          notes: makeOnboardingTicketNote({
            _id: signupSessionId,
            planFrequency: `${
              sumWeeklyClasses(studentData?.bundle?.selections ?? {}) * 4
            } Sessions/Month`,
            subject: subject as SubjectName,
            learningStyle: studentData.learningStyle,
            isFromCourseExplorer,
          }),
        });
      }
      return undefined;
    }) ?? [],
  );

const makeCheckoutService = () => ({
  handleCheckout: async (
    body: { paymentMethodId: string; signupSessionId: string },
    isBootcampSignup?: boolean,
  ) =>
    // todo change back to /payment route
    juniAxios.post<{
      success: boolean;
      message?: string;
      error?: string;
      field?: string;
      validationErrors?: Array<{ field: string; message: string }>;
      data?: {
        students: MongoStudentSchema[];
        parent: MongoParentSchema;
        onboardingTickets: OnboardingTicket[];
        paymentMethod: {
          customer: string;
          card?: { last4: string };
        };
        subscriptions: [
          {
            id: string;
          },
        ];
        stripeCharge: { id: string };
        inviteCode: string;
      };
    }>(
      isBootcampSignup ? `/join/signup/payment_bootcamp` : `/join/signup/payment_v2`,
      {
        ...body,
        origin: 'dcf',
      },
    ),
  updateParent: async (parentId: string, body: { address: string }) =>
    juniAxios.patch<Partial<MongoParentSchema>>(
      `/join/signup/parent/${parentId}`,
      body,
    ),
  updateOnboardingTicket,
  updateStudent: async (
    studentId: string,
    body: {
      birthDate?: string;
      gender?: Gender;
      track?: string;
      hasMultipleTracks?: boolean;
      tracks?: string[];
      notes?: string;
    },
  ) =>
    juniAxios.patch<Partial<MongoStudentSchema>>(
      `/join/signup/student/${studentId}`,
      body,
    ),
  sendConfirmationEmail: async (signupSessionId: string, last4?: string) =>
    juniAxios.post(`/auth/send_membership_confirmation_email`, {
      signupSessionId,
      last4,
    }),
  syncSignupToOnboarding,
});

export default makeCheckoutService;
