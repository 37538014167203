import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || '';

/**
 * @param clientSecret required when using a PaymentElement
 */
interface WithStripeElementsProps {
  clientSecret?: string;
}

export const withStripeElements = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>,
): React.FC<P & WithStripeElementsProps> => ({
  clientSecret,
  ...props
}: WithStripeElementsProps) => (
  <Elements stripe={loadStripe(STRIPE_API_KEY)} options={{ clientSecret }}>
    <Component {...(props as P)} />
  </Elements>
);
