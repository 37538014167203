import { FC } from 'react';
import { useScheduleAdditionalClassMutation } from 'generated/graphql';
import { AcuityAppointment } from 'models';
import { SingleClassSchedulerModal } from '../components';

import './learner_class_scheduler_widget.css';

import { LearnerClassSchedulerBaseProps } from '../types';

export interface LearnerClassSchedulerWidgetV2Props
  extends LearnerClassSchedulerBaseProps {
  additionalClassId?: string;
  isMakeupClass: boolean;
  refreshAdditionalClasses: () => void;
  refreshUpcomingSessions: () => void;
  // This field is bad, and the pile-o-components that require it should be
  // refactored to get this data from elsewhere.
  // - June
  upcomingClasses?: AcuityAppointment[];
}

export const LearnerClassSchedulerWidgetV2: FC<LearnerClassSchedulerWidgetV2Props> = props => {
  const [scheduleAdditionalClass, results] = useScheduleAdditionalClassMutation();
  const description = `${props.isMakeupClass ? 'Makeup' : 'Class Pack'} Class for ${
    props.studentFirstName
  }`;
  const confirmationMessage = `You are about to schedule a ${
    props.isMakeupClass ? 'makeup' : 'class pack'
  } class for ${props.studentFirstName} on:`;
  const handleSubmit = async (selectedDatetime: Date) => {
    if (props.additionalClassId && selectedDatetime) {
      await scheduleAdditionalClass({
        variables: {
          input: {
            additionalClassId: props.additionalClassId,
            selectedAppointmentSlot: selectedDatetime.toISOString(),
          },
        },
      });
    }
  };
  const refresh = async () => {
    await Promise.all([
      props.refreshUpcomingSessions,
      props.refreshAdditionalClasses,
    ]);
  };
  return (
    <SingleClassSchedulerModal
      onSubmit={handleSubmit}
      schedulingResults={results}
      modalDescription={description}
      confirmationMessage={confirmationMessage}
      refreshParentState={refresh}
      {...props}
    />
  );
};
